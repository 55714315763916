import React, { createContext, useState, ReactNode, useContext } from 'react';

interface UltraTableContextType {
  updateLog: any[];
  setUpdateLog: (term: any) => void;
}

const UltraTableContext = createContext<UltraTableContextType | undefined>(
  undefined,
);

const UltraTableProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [updateLog, setUpdateLog] = useState([]);

  return (
    <UltraTableContext.Provider value={{ updateLog, setUpdateLog }}>
      {children}
    </UltraTableContext.Provider>
  );
};

export const useUltraTable = (): UltraTableContextType => {
  const context = useContext(UltraTableContext);
  if (context === undefined) {
    throw new Error('useSearch must be used within a SearchProvider');
  }
  return context;
};

export default UltraTableProvider;
