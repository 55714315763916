// PortalFetchWrapper is a wrapper component that fetches authorized funds and passes them to the wrapped component.
// It uses react-query for data fetching and handles loading and error states.

import { useQuery } from '@tanstack/react-query';
import { ReactElement } from 'react-markdown/lib/react-markdown';
import { positionLoadConfigs } from './PositionUpdatePortal.page';
import RaptorLoading from '../../../feedback/RaptorLoading';
import NoDataMessage from '../../../feedback/NoDataMessage.component';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { applyFundFilter } from '../../../../redux/pages/actions';

interface PortalFetchWrapperProps {
  component: ReactElement;
}

export const PortalFetchWrapper = <T extends object>(
  props: PortalFetchWrapperProps & T,
) => {
  const dispatch = useDispatch();
  const { component } = props;
  const [isReady, setIsReady] = useState(false); // Track readiness state

  const { data, isPending, error } = useQuery({
    queryKey: ['positionLoadConfigs'],
    queryFn: positionLoadConfigs,
  });

  console.log(data);

  // If we use the useFilterFunds we get invalid number of hooks error
  useEffect(() => {
    if (data?.data?.authorised_funds && !data.data.authorise_all_funds) {
      const authorisedFunds =
        data.data.authorised_funds.length === 0
          ? ['no-funds']
          : data.data.authorised_funds;

      dispatch(applyFundFilter(authorisedFunds, '', ''));
      setIsReady(true); // Mark ready after dispatch
    } else {
      setIsReady(true); // Mark ready after dispatch
    }

    return () => {
      dispatch(applyFundFilter([], '', ''));
    };
  }, [data, dispatch]);

  if (isPending || !isReady) {
    return (
      <RaptorLoading
        centerWrap
        messages={[
          'Loading Potential Portal Funds',
          'This may take a few seconds...',
        ]}
      />
    );
  }

  if (error) {
    return <NoDataMessage message={'Error Retrieving Data'} />;
  }

  if (!data?.data?.authorised_funds) {
    return <NoDataMessage message={'No Authorised Funds Available'} />;
  }

  const authConfig = data.data;

  // Pass authFunds along with other props to the component
  return <>{React.cloneElement(component, { authConfig, ...props })}</>;
};
