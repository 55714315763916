import { FC } from 'react';
import { mainColors } from '../../../../../styling/theme';
import { CustomColumn } from '../../../../../types/components/tables/tableTypes';
import { DataObject, Status } from '../../../../../types/redux/data/dataTypes';
import {
  percentageToTwoDecimalPlacesNoHundred,
  toTwoDecimalPlaces,
} from '../../../../../utilities/numberFormatters';
import GridItem from '../../../../layout/GridComponents/GridItem';
import CustomTable from '../../../../tables/CustomTable';
import GenericStatusCell from '../../../../tables/GenericStatusCell';
import { Typography } from '@mui/material';
import { specifyStatus } from '../../../../../utilities/generalMappings';

export interface StressTestsData {
  headerRow: boolean;
  stressScenarioType: string | null;
  name: string | null;
  pL: number | null;
  pLstatus: Status | null;
  exAnteVolatility: number | null;
  exAnteVar: number | null;
  numSds: number | null;
  numVar: number | null;
  exPostVolatility: number | null;
  exPostVar: number | null;
}

interface StressTestTableProps {
  data: DataObject;
}

export const buildStressColumns = (): CustomColumn<StressTestsData>[] => {
  return [
    {
      title: 'Stress/Scenario Type',
      field: 'stressScenarioType',
      headerStyle: {
        textAlign: 'center',
      },
      cellStyle: (rowData, rowDataValues) =>
        rowDataValues.headerRow
          ? {
              padding: '2rem',
              fontWeight: 700,
              color: mainColors.mainBlue,
              textAlign: 'center',
            }
          : {
              padding: 0,
            },
    },
    {
      title: 'Name',
      field: 'name',
      cellStyle: {
        padding: 0,
      },
      headerStyle: {},
    },
    {
      title: 'P/L',
      field: 'pL',
      cellStyle: {
        textAlign: 'center',
        padding: 0,
      },
      headerStyle: {
        textAlign: 'center',
      },
      pdfRenderType: 'StatusWithPercentage',
      renderMethod: {
        methodName: 'percentageToTwoDecimalPlaces',
        params: ['pL'],
      },
      render: (rowData) =>
        rowData.headerRow ? (
          ''
        ) : (
          <GenericStatusCell
            height={'5rem'}
            status={rowData.pLstatus ? rowData.pLstatus : specifyStatus('Pass')}
            innerText={percentageToTwoDecimalPlacesNoHundred(
              rowData.pL ? rowData.pL : 0,
            )}
          />
        ),
    },
    {
      title: 'Ex-Ante Volatility',
      field: 'exAnteVolatility',
      cellStyle: {
        textAlign: 'center',
        padding: 0,
      },
      headerStyle: {
        textAlign: 'center',
      },
      render: (rowData) =>
        rowData.headerRow
          ? ''
          : percentageToTwoDecimalPlacesNoHundred(
              rowData.exAnteVolatility as number,
            ),
    },
    {
      title: 'Ex-Ante VaR',
      field: 'exAnteVar',
      cellStyle: {
        textAlign: 'center',
        padding: 0,
      },
      headerStyle: {
        textAlign: 'center',
      },
      render: (rowData) =>
        rowData.headerRow
          ? ''
          : percentageToTwoDecimalPlacesNoHundred(rowData.exAnteVar as number),
    },
    {
      title: '#SDs',
      field: 'numSds',
      cellStyle: {
        textAlign: 'center',
        padding: 0,
      },
      headerStyle: {
        textAlign: 'center',
      },
      render: (rowData) =>
        rowData.headerRow ? '' : toTwoDecimalPlaces(rowData.numSds as number),
    },
    {
      title: '#VaR',
      field: 'numVar',
      cellStyle: {
        textAlign: 'center',
        padding: 0,
      },
      headerStyle: {
        textAlign: 'center',
      },
      render: (rowData) =>
        rowData.headerRow ? '' : toTwoDecimalPlaces(rowData.numVar as number),
    },
    {
      title: 'Ex-Post Volatility',
      field: 'exPostVolatility',
      cellStyle: {
        textAlign: 'center',
        padding: 0,
      },
      headerStyle: {
        textAlign: 'center',
      },
      render: (rowData) =>
        rowData.headerRow
          ? ''
          : percentageToTwoDecimalPlacesNoHundred(
              rowData.exPostVolatility as number,
            ),
    },
    {
      title: 'Ex-Post VaR',
      field: 'exPostVar',
      cellStyle: {
        textAlign: 'center',
        padding: 0,
      },
      headerStyle: {
        textAlign: 'center',
      },
      render: (rowData) =>
        rowData.headerRow
          ? ''
          : percentageToTwoDecimalPlacesNoHundred(rowData.exPostVar as number),
    },
  ];
};

export const sectorNameMap: { [key: string]: string } = {
  risk_sector_stress_tests: 'Risk Sector Stress',
  historical_stress_tests: 'Historical Stress',
  relative_stress_tests: 'Relative Stress',
};

export function buildStressTestData(inputData: DataObject) {
  if (!inputData.data.length) return [];
  const positions = inputData.data[0].positions;
  if (typeof positions === 'string') return [];

  const riskSectorData = inputData.data[0].risk_sector_stress_tests;
  const historicalData = inputData.data[0].historical_stress_tests;
  const nav = inputData.data[0].nav;
  const returnArr: StressTestsData[] = [];

  // Add the Risk Sectors Header
  returnArr.push({
    headerRow: true,
    stressScenarioType: 'Risk Sector Stress',
    name: null,
    pL: null,
    pLstatus: null,
    exAnteVolatility: null,
    exAnteVar: null,
    numSds: null,
    numVar: null,
    exPostVolatility: null,
    exPostVar: null,
  });
  // Now add all of the risk sector tests.
  riskSectorData.forEach((element: any) => {
    const currentTestName = element[0];
    const values = element[1];
    const pLvalue = (values[0] / nav) * 100;
    // / nav

    returnArr.push({
      headerRow: false,
      stressScenarioType: null,
      name: currentTestName,
      pL: pLvalue,
      pLstatus:
        pLvalue < 0 && Math.abs(pLvalue) >= 5
          ? Status.Alert
          : pLvalue < 0 && Math.abs(pLvalue) >= 10
            ? Status.Fail
            : Status.Pass, // come back to this
      exAnteVolatility: (values[1] / nav) * 100,
      exAnteVar: (values[2] / nav) * 100,
      numSds: values[3],
      numVar: values[4],
      exPostVolatility: (values[5] / nav) * 100,
      exPostVar: (values[6] / nav) * 100,
    });
  });
  // Add the Historical Header
  returnArr.push({
    headerRow: true,
    stressScenarioType: 'Historical Stress',
    name: null,
    pL: null,
    pLstatus: null,
    exAnteVolatility: null,
    exAnteVar: null,
    numSds: null,
    numVar: null,
    exPostVolatility: null,
    exPostVar: null,
  });
  // Now add all of the risk sector tests.
  historicalData.forEach((element: any) => {
    const currentTestName = element[0];
    const values = element[1];
    const pLvalue = (values[0] / nav) * 100;
    // / nav

    returnArr.push({
      headerRow: false,
      stressScenarioType: null,
      name: currentTestName,
      pL: pLvalue,
      pLstatus:
        pLvalue < 0 && Math.abs(pLvalue) >= 5
          ? Status.Alert
          : pLvalue < 0 && Math.abs(pLvalue) >= 10
            ? Status.Fail
            : Status.Pass, // come back to this
      exAnteVolatility: (values[1] / nav) * 100,
      exAnteVar: (values[2] / nav) * 100,
      numSds: values[3],
      numVar: values[4],
      exPostVolatility: (values[5] / nav) * 100,
      exPostVar: (values[6] / nav) * 100,
    });
  });

  return returnArr;
  return [];
}
const StressTestTable: FC<StressTestTableProps> = (props) => {
  const columns = buildStressColumns();

  const tableData = buildStressTestData(props.data);
  return (
    <GridItem card xs={12}>
      {tableData.length ? (
        <CustomTable<StressTestsData>
          pdfNoClearFirstRow
          options={{
            paging: false,
            exportButton: true,
          }}
          showToolbar={true}
          data={tableData}
          columns={columns}
          csvFields={[
            'stressScenarioType',
            'name',
            'pL',
            'pLstatus',
            'exAnteVolatility',
            'exAnteVar',
            'numSds',
            'numVar',
            'exPostVolatility',
            'exPostVar',
          ]}
        />
      ) : (
        <Typography variant="h1" style={{ textAlign: 'center' }}>
          No collateral positions
        </Typography>
      )}
    </GridItem>
  );
};

export default StressTestTable;
