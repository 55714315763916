import { CustomColumn } from '../../../../types/components/tables/tableTypes';
import { DataObject } from '../../../../types/redux/data/dataTypes';
import {
  formatESGWithUnit,
  percentageToNdecialPlaces,
} from '../../../../utilities/numberFormatters';
import GridItem from '../../../layout/GridComponents/GridItem';
import CustomTable from '../../../tables/CustomTable';
import {
  IndicatorCoverageRatioMonitorTableData,
  IndicatorCoverageRatioMonitorUnderlyingTableData,
} from './IndictaorCoverageRatioMonitor.component';

interface SecondLevelTableProps {
  data: DataObject;
  rowData: IndicatorCoverageRatioMonitorTableData;
}

// The standard columns for the table (Full Set)
const standardColumns = (
  unit: string,
): CustomColumn<IndicatorCoverageRatioMonitorUnderlyingTableData>[] => [
  {
    title: 'Position Name',
    field: 'position_name',
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: {
      textAlign: 'center',
    },
  },
  {
    title: 'ISIN',
    field: 'isin',
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: {
      textAlign: 'center',
    },
  },
  {
    title: 'Gross Exposure %',
    field: 'gross_exposure_percentage',
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: {
      textAlign: 'center',
    },
    render: (rowData) =>
      percentageToNdecialPlaces(rowData.gross_exposure_percentage, 2),
  },
  {
    title: 'Indicator Value',
    field: 'value',
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: {
      textAlign: 'center',
    },
    render: (rowData) => formatESGWithUnit(rowData.value, unit),
  },
];

// Build the columns using the columns in the configs
const buildColumns = (
  data: DataObject,
  columns: CustomColumn<IndicatorCoverageRatioMonitorUnderlyingTableData>[],
): CustomColumn<IndicatorCoverageRatioMonitorUnderlyingTableData>[] => {
  if (!data.data[0].configs || !data.data[0].configs.position_columns) {
    return columns;
  } else {
    const returnColumns: CustomColumn<IndicatorCoverageRatioMonitorUnderlyingTableData>[] =
      [];
    data.data[0].configs.position_columns.forEach((column: string) => {
      columns.forEach(
        (
          col: CustomColumn<IndicatorCoverageRatioMonitorUnderlyingTableData>,
        ) => {
          if (col.field === column) {
            returnColumns.push(col);
          }
        },
      );
    });
    return returnColumns;
  }
};

const IndicatorCoverageRatioSecondLevelTable: React.FC<
  SecondLevelTableProps
> = ({ data, rowData }) => {
  return (
    <GridItem xs={12} card>
      <CustomTable<IndicatorCoverageRatioMonitorUnderlyingTableData>
        columns={buildColumns(data, standardColumns(rowData.unit))}
        showToolbar
        data={rowData.underlying}
        title={'Underlying Positions'}
        options={{
          draggable: false,
          sorting: false,
          paging: false,
          search: true,
          exportButton: true,
        }}
      />
    </GridItem>
  );
};

export default IndicatorCoverageRatioSecondLevelTable;
