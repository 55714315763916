import dayjs from 'dayjs';

export default function formatDate(
  d: Date | null,
  preventFutureDates = true,
): string | null {
  if (!d || (preventFutureDates && d > new Date())) {
    return null;
  }
  let month = `${d.getMonth() + 1}`;
  let day = `${d.getDate()}`;

  const year = d.getFullYear();

  if (month.length < 2) month = `0${month}`;
  if (day.length < 2) day = `0${day}`;

  return [year, month, day].join('-');
}

export function formatDateForCheckingState(d: Date | null) {
  return formatDate(d);
}

/**
 *
 * @returns today's date (the date the function is called)
 * formatted in YYYY-MM-DD
 */
export function todayFormatted() {
  return formatDateForCheckingState(new Date());
}
type DateString = string | null;
export function createDateAndOneMonthAgo(): [DateString, DateString] {
  const d = new Date();
  d.setMonth(d.getMonth() - 1);
  return [formatDate(d), formatDate(new Date())];
}

export function dateOneMonthAgo() {
  const d = new Date();
  d.setMonth(d.getMonth() - 1);
  return d;
}

export function dateNMonthsAgo(n: number) {
  const d = new Date();
  d.setMonth(d.getMonth() - n);
  return d;
}

export function dateIsToday(date: Date | string | null) {
  // check against undefined
  if (!date) return true;
  if (typeof date === 'string') return true;
  const today = new Date();
  return date.toDateString() === today.toDateString();
}

export function formattedDateIsToday(date: string | null | Date) {
  if (!date) return false;
  return date === formatDateForCheckingState(new Date());
}

export function createDateFromFormattedString(date: string | null) {
  if (!date) {
    return new Date();
  } else {
    return new Date(Date.parse(date));
  }
}
