import React from 'react';
import { useSelector } from 'react-redux';
import useFetchData from '../../../../hooks/useFetchData';
import { GeneralOverviewPageRenderedComponentProps } from '../../../../types/components/componentTypes';
import GeneralComponentErrorShield from '../../../general/GeneralComponentErrorShield';
import AifmdOverviewTable from './table/table.component';
import { createSectionByIdSelector } from '../../../../redux/pages/selectors';

const AifmdOverview: React.FC<GeneralOverviewPageRenderedComponentProps> = (
  props,
) => {
  const sectionDetails = useSelector(createSectionByIdSelector(props.section));
  const fundTypes = sectionDetails?.fundTypes;

  const schemas = sectionDetails?.fundTypes.length
    ? `?schemas=${sectionDetails?.fundTypes}`
    : '';
  const reportsList = useFetchData({
    url: `reports_list` + schemas,
    keyName: `${fundTypes}_overview_reports_list`,
    makeFetch: true,
  });
  const overviewData = useFetchData({
    url: `schema_overview_monitor/${props.positionDate}` + schemas,
    keyName: `${fundTypes}_overview_data_${props.positionDate}`,
    makeFetch: props.positionDate ? true : false,
  });

  return overviewData ? (
    <GeneralComponentErrorShield dataObjects={[overviewData, reportsList]}>
      <AifmdOverviewTable reports={reportsList} inputData={overviewData} />
    </GeneralComponentErrorShield>
  ) : null;
};

export default AifmdOverview;
