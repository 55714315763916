import React, { useEffect } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import GridItem from '../../../../layout/GridComponents/GridItem';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Label,
  Rectangle,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { greys, mainColors } from '../../../../../styling/theme';
import { percentageToNdecialPlaces } from '../../../../../utilities/numberFormatters';
import clsx from 'clsx';
import { useDispatch } from 'react-redux';
import usePngFromRecharts from '../../../../../hooks/usePngFromRecharts';
import { addComponentToPdfExport } from '../../../../../redux/pdfExport/actions';
import { PdfComponentType } from '../../../../../types/redux/pdfExports/pdfExportsStore';
import FileSaver from 'file-saver';
import ExportButton from '../../../../feedback/ExportButton';
import ChartDownloadButton from '../../../../buttons/ChartDownloadButton';
import { countryCodeToName } from '../../../../../utilities/generalMappings';

interface SubFundCumulativeBreakdownChartProps {
  data: any;
  selectedBreakdown: string;
}

interface SubFundCumulativeBreakdownChartData {
  name: string;
  value: number;
}

const useStyles = makeStyles(() => ({
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '1rem 2.5rem 1rem 1.5rem',
  },
  cardTitle: {
    color: mainColors.mainBlue,
    fontSize: '2.2rem',
    fontWeight: 400,
    margin: '1rem 1.5rem',
  },
  customTooltipContainer: {
    backgroundColor: 'white',
    padding: '1rem 1.5rem',
    borderRadius: '0.5rem',
    border: `1px solid ${mainColors.mainBlue}`,
    width: '20rem',
    color: mainColors.Pass,
  },
  tooltipName: {
    fontSize: '2rem',
    fontWeight: 500,
  },
  tooltipValue: {
    fontSize: '1.6rem',
    fontWeight: 600,
  },
  tooltipValueBelowZero: {
    color: mainColors.mainBlue,
  },
  exportButtons: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const CustomTooltip = ({ active, payload, label }: any) => {
  const classes = useStyles();
  if (active && payload && payload.length) {
    return (
      <div className={classes.customTooltipContainer}>
        <div>
          <div className={classes.tooltipName}>{label}</div>
          <hr />
          {payload[0].value >= 0 ? (
            <div className={classes.tooltipValue}>
              {percentageToNdecialPlaces(payload[0].value / 100, 2)}
            </div>
          ) : (
            <div
              className={clsx(
                classes.tooltipValue,
                classes.tooltipValueBelowZero,
              )}
            >
              {percentageToNdecialPlaces(payload[0].value / 100, 2)}
            </div>
          )}
        </div>
      </div>
    );
  } else {
    return <></>;
  }
};

const CustomBar = (props: any) => {
  let fill = props.fill;

  if (props.value >= 0) {
    fill = mainColors.Pass;
  } else {
    fill = mainColors.mainBlue;
  }

  return <Rectangle {...props} fill={fill} />;
};

const buildChartData = (
  inputData: any,
  selectedBreakdown: string,
): SubFundCumulativeBreakdownChartData[] => {
  if (!inputData.data || !inputData.data.length) {
    return [];
  } else {
    const returnData: SubFundCumulativeBreakdownChartData[] = [];

    inputData.data[0].drop_down_breakdown[selectedBreakdown].map(
      (sector: any) => {
        returnData.push({
          name: sector.identifier,
          value: sector.aggregate_pct_pl,
        });
      },
    );

    return returnData;
  }
};

const SubFundCumulativeBreakdownChart: React.FC<
  SubFundCumulativeBreakdownChartProps
> = ({ data, selectedBreakdown }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const builtChartData = buildChartData(data, selectedBreakdown);

  const id = `Sub-Fund-Cumulative-Breakdown-pl-by-${selectedBreakdown}`;
  const title = `Sub-Fund Cumulative Breakdown - P/L By ${
    selectedBreakdown.charAt(0).toUpperCase() + selectedBreakdown.slice(1)
  }`; // capitalise first letter

  const { ref, handleDownload } = usePngFromRecharts();

  useEffect(() => {
    dispatch(
      addComponentToPdfExport({
        identifier: id,
        handler: handleDownload,
        type: PdfComponentType.LINE_CHART,
        title: title,
      }),
    );
  }, [ref, selectedBreakdown]);

  const saveImage = async () => {
    const imageData = await handleDownload();
    if (imageData) {
      FileSaver.saveAs(imageData.data, `${id}.png`);
    }
  };

  return (
    <GridItem xs={12} xl={selectedBreakdown === 'country' ? 6 : 12} card>
      <div className={classes.toolbar}>
        <h2 className={classes.cardTitle}>{title}</h2>
        <div className={classes.exportButtons}>
          <ExportButton
            fileName={`${id}.csv`}
            exportData={builtChartData}
            fields={Object.keys(builtChartData[0])}
            fieldsMap={[
              { key: 'name', label: selectedBreakdown },
              { key: 'value', label: 'Total P/L %' },
            ]}
          />
          <ChartDownloadButton handler={saveImage} />
        </div>
      </div>
      <ResponsiveContainer width="100%" height={500}>
        <BarChart
          width={500}
          height={400}
          data={builtChartData}
          margin={{
            top: 20,
            right: 50,
            left: 30,
            bottom: 20,
          }}
          ref={ref}
        >
          <CartesianGrid strokeDasharray="5 5" />
          <XAxis
            dataKey="name"
            interval={0}
            angle={-25}
            textAnchor="end"
            height={70}
            tick={{
              stroke: mainColors.mainBlue,
              strokeWidth: 1,
              fontSize: '1.3rem',
            }}
            tickLine={true}
            tickFormatter={(tickItem: string) => countryCodeToName(tickItem)}
          />
          <YAxis
            width={70}
            tickFormatter={(tickItem) => {
              return percentageToNdecialPlaces(tickItem / 100, 1);
            }}
            tick={{
              stroke: mainColors.mainBlue,
              strokeWidth: 1,
              fontSize: '1.3rem',
            }}
            tickLine={true}
          >
            <Label
              style={{
                textAnchor: 'middle',
                fontSize: '200%',
                fontWeight: 400,
                color: mainColors.mainBlue,
              }}
              angle={270}
              value={'Total P/L %'}
              position={'insideLeft'}
            />
          </YAxis>
          <Tooltip content={<CustomTooltip />} />
          <ReferenceLine y={0} stroke={greys.grey600} />
          <Bar dataKey="value" shape={CustomBar} />
        </BarChart>
      </ResponsiveContainer>
    </GridItem>
  );
};

export default SubFundCumulativeBreakdownChart;
