import { KidsStore } from '../../types/redux/kids/KidsStore';
import {
  ALLOW_USER_TO_NAVIGATE_AWAY_ACTION,
  DONT_ALLOW_USER_TO_NAVIGATE_AWAY_ACTION,
  KidAction,
  TOGGLE_CAN_USER_NAVIGATE_AWAY_ACTION,
  TOGGLE_KID_FILTER_ACTION,
  TURN_OFF_ALL_FILTERS_ACTION,
} from './actions';

const kidDefaultState: KidsStore = {
  kidsFilterState: {
    manco: false,
    fund_manager: false,
    sub_fund: false,
    share_class: false,
  },
  canUserNavigateAway: true,
};

function kidsReducer(
  state: KidsStore = kidDefaultState,
  action: KidAction,
): KidsStore {
  switch (action.type) {
    case TOGGLE_KID_FILTER_ACTION:
      const updatedKidsFilterState = state.kidsFilterState;
      updatedKidsFilterState[action.payload] =
        !updatedKidsFilterState[action.payload];
      return {
        ...state,
        kidsFilterState: updatedKidsFilterState,
      };
    case TURN_OFF_ALL_FILTERS_ACTION:
      return {
        ...state,
        kidsFilterState: kidDefaultState.kidsFilterState,
      };
    case TOGGLE_CAN_USER_NAVIGATE_AWAY_ACTION:
      return {
        ...state,
        canUserNavigateAway: !state.canUserNavigateAway,
      };
    case ALLOW_USER_TO_NAVIGATE_AWAY_ACTION:
      return {
        ...state,
        canUserNavigateAway: true,
      };
    case DONT_ALLOW_USER_TO_NAVIGATE_AWAY_ACTION:
      return {
        ...state,
        canUserNavigateAway: false,
      };
    default:
      return state;
  }
}

export default kidsReducer;
