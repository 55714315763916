import React, { FC, useEffect } from 'react';
import { FundInfoComponentProps } from '../../../general/GeneralFundInfoPage';
import useTitleBarSelect from '../../../../hooks/useTitleBarSelect';
import NonCisTable, { ExposureType } from './table/NonCisTable.component';
import useFetchData from '../../../../hooks/useFetchData';
import { formatDateForCheckingState } from '../../../../utilities/dateFormatters';
import GeneralComponentErrorShield from '../../../general/GeneralComponentErrorShield';
import NonCisMaps from './maps/NonCisMaps.component';
import { useDispatch } from 'react-redux';
import { Select } from '../../../../types/redux/pages/PagesStore';
import {
  addAdditionalTitleBarComponents,
  removeTitleBarComponent,
} from '../../../../redux/pages/actions';
import {
  APPEND_NEW_SELECT_TO_TITLEBAR,
  REMOVE_SELECT_FROM_TITLEBAR,
} from '../../../../types/redux/pages/pageTypes';

const AlternativeNonCis: FC<FundInfoComponentProps> = ({
  fundId,
  positionDate,
}) => {
  const dispatch = useDispatch();

  // Add the select components to the title bar
  useEffect(() => {
    const selectsToAdd: Select[] = [
      {
        titleBarTitle: 'Type: ',
        titleBarKey: 'exposureType',
        values: [
          {
            label: 'Net Exposure, Base Currency',
            value: 'netExposureBaseCurrency',
          },
          {
            label: 'Net Exposure, % NAV',
            value: 'netExposurePcNav',
          },
        ],
        displayOnOverviewPage: false,
        aValueIsAlwaysSelected: true,
        currentValue: 'netExposureBaseCurrency',
      },
      {
        titleBarTitle: 'Visualisation: ',
        titleBarKey: 'selectVisualisation',
        values: [
          {
            label: 'Position Table',
            value: 'positionTable',
          },
          {
            label: 'Exposure Graphics',
            value: 'exposureGraphics',
          },
        ],
        displayOnOverviewPage: false,
        aValueIsAlwaysSelected: true,
        currentValue: 'positionTable',
      },
    ];

    dispatch(
      addAdditionalTitleBarComponents(
        selectsToAdd,
        APPEND_NEW_SELECT_TO_TITLEBAR,
      ),
    );

    return () => {
      dispatch(
        removeTitleBarComponent('exposureType', REMOVE_SELECT_FROM_TITLEBAR),
      );
      dispatch(
        removeTitleBarComponent(
          'selectVisualisation',
          REMOVE_SELECT_FROM_TITLEBAR,
        ),
      );
    };
  }, []);

  const exposureType = useTitleBarSelect('exposureType');
  const selectedVisualisation = useTitleBarSelect('selectVisualisation');

  const url = positionDate
    ? `stored_report_data_agg/${fundId}/alternative_noncis/${positionDate}`
    : `most_recently_stored_report_data_agg/${fundId}/alternative_noncis`;

  const cisData = useFetchData({
    keyName: `nonCisData_${fundId}_${
      positionDate || formatDateForCheckingState(new Date())
    }`,
    url: url,
    makeFetch: true,
  });

  return (
    <GeneralComponentErrorShield
      dataObjects={[cisData]}
      customErrorMessage="No In-Scope or Available Data"
    >
      {selectedVisualisation === 'positionTable' ? (
        <NonCisTable
          inputData={cisData}
          selectedExposureType={
            (exposureType as ExposureType) || 'netExposureBaseCurrency'
          }
        />
      ) : (
        <NonCisMaps
          inputData={cisData}
          selectedExposureType={
            (exposureType as ExposureType) || 'netExposureBaseCurrency'
          }
        />
      )}
    </GeneralComponentErrorShield>
  );
};

export default AlternativeNonCis;
