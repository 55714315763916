import { FC } from 'react';
import { mainColors } from '../../../../styling/theme';
import { CustomColumn } from '../../../../types/components/tables/tableTypes';
import {
  addCommasToNumbersAndRound,
  percentageToTwoDecimalPlacesNoHundred,
  roundNumberToTwoDecimalPlaces,
} from '../../../../utilities/numberFormatters';
import GridItem from '../../../layout/GridComponents/GridItem';
import CustomTable from '../../../tables/CustomTable';
import { Typography } from '@mui/material';

interface marketLiquidityRiskTableProps {
  data: any;
  title: string;
}

interface IMarketLiquidityRiskData {
  asset: string;
  positionSize: number;
  marketPrice: number;
  forex: number;
  bau: number;
  bauPercentTotal: number;
  eBau: number;
  eBauPercentTotal: number;
  stressed: number;
  stressedPercentTotal: number;
}

function buildSubscriptionsRedemptionData(
  data: any,
  title: string,
): IMarketLiquidityRiskData[] {
  if (!data.data.length) return [];
  const tableData: IMarketLiquidityRiskData[] = [];
  const marketLiquidityData = data.data[0];
  const portfolioValues = marketLiquidityData.Portfolio;
  const nav = marketLiquidityData.nav;
  // Push the portfolio Values first
  tableData.push({
    asset: 'Portfolio',
    positionSize: 0,
    marketPrice: 0,
    forex: 0,
    bau: (portfolioValues[0] / nav) * 100,
    bauPercentTotal: 100,
    eBau: (portfolioValues[1] / nav) * 100,
    eBauPercentTotal: 100,
    stressed: (portfolioValues[2] / nav) * 100,
    stressedPercentTotal: 100,
  });
  Object.keys(marketLiquidityData.position_data).forEach((key: any) => {
    tableData.push({
      asset: marketLiquidityData.position_data[key][0],
      positionSize: marketLiquidityData.position_data[key][1],
      marketPrice: marketLiquidityData.position_data[key][2],
      forex: marketLiquidityData.position_data[key][3],
      bau: (marketLiquidityData.bau[key] / nav) * 100,
      bauPercentTotal:
        (marketLiquidityData.bau[key] / portfolioValues[0]) * 100,
      eBau: (marketLiquidityData.elevated[key] / nav) * 100,
      eBauPercentTotal:
        (marketLiquidityData.elevated[key] / portfolioValues[1]) * 100,
      stressed: (marketLiquidityData.stressed[key] / nav) * 100,
      stressedPercentTotal:
        (marketLiquidityData.elevated[key] / portfolioValues[1]) * 100,
    });
  });
  return tableData;
}

const detailColumns: CustomColumn<IMarketLiquidityRiskData>[] = [
  {
    title: 'Asset',
    field: 'asset',
    cellStyle: { textAlign: 'left' },
    headerStyle: { textAlign: 'center' },
    width: '20%',
    customSort: (a: IMarketLiquidityRiskData, b: IMarketLiquidityRiskData) => {
      if (a.asset === 'Portfolio') return 1;
      if (b.asset === 'Portfolio') return 1;
      return a.asset > b.asset ? 1 : -1;
    },
  },
  {
    title: 'Position Size',
    field: 'positionSize',
    cellStyle: { textAlign: 'center' },
    headerStyle: { textAlign: 'center' },
    render: (rowData) =>
      rowData.asset === 'Portfolio'
        ? ''
        : addCommasToNumbersAndRound(rowData.positionSize),
    defaultSort: 'desc',
    customSort: (a: IMarketLiquidityRiskData, b: IMarketLiquidityRiskData) => {
      if (a.asset === 'Portfolio') return 1;
      if (b.asset === 'Portfolio') return 1;
      return a.positionSize > b.positionSize ? 1 : -1;
    },
  },
  {
    title: 'Market Price',
    field: 'marketPrice',
    render: (rowData) =>
      rowData.marketPrice === 0
        ? ''
        : roundNumberToTwoDecimalPlaces(rowData.marketPrice),
    cellStyle: { textAlign: 'center' },
    headerStyle: { textAlign: 'center' },
    customSort: (a: IMarketLiquidityRiskData, b: IMarketLiquidityRiskData) => {
      if (a.asset === 'Portfolio') return 1;
      if (b.asset === 'Portfolio') return 1;
      return a.marketPrice > b.marketPrice ? 1 : -1;
    },
  },
  {
    title: 'Forex',
    field: 'forex',
    render: (rowData) => roundNumberToTwoDecimalPlaces(rowData.forex),
    cellStyle: { textAlign: 'center' },
    headerStyle: { textAlign: 'center' },
    customSort: (a: IMarketLiquidityRiskData, b: IMarketLiquidityRiskData) => {
      if (a.asset === 'Portfolio') return 1;
      if (b.asset === 'Portfolio') return 1;
      return a.forex > b.forex ? 1 : -1;
    },
  },
  {
    title: 'BAU',
    field: 'bau',
    render: (rowData) => percentageToTwoDecimalPlacesNoHundred(rowData.bau),
    cellStyle: { textAlign: 'center' },
    headerStyle: { textAlign: 'center' },
    customSort: (a: IMarketLiquidityRiskData, b: IMarketLiquidityRiskData) => {
      if (a.asset === 'Portfolio') return 1;
      if (b.asset === 'Portfolio') return 1;
      return a.bau > b.bau ? 1 : -1;
    },
  },
  {
    title: 'BAU % of Total',
    field: 'bauPercentTotal',
    render: (rowData) =>
      percentageToTwoDecimalPlacesNoHundred(rowData.bauPercentTotal),
    cellStyle: { textAlign: 'center' },
    headerStyle: { textAlign: 'center' },
    customSort: (a: IMarketLiquidityRiskData, b: IMarketLiquidityRiskData) => {
      if (a.asset === 'Portfolio') return 1;
      if (b.asset === 'Portfolio') return 1;
      return a.bauPercentTotal > b.bauPercentTotal ? 1 : -1;
    },
  },
  {
    title: 'E-BAU',
    field: 'eBau',
    render: (rowData) => percentageToTwoDecimalPlacesNoHundred(rowData.eBau),
    cellStyle: { textAlign: 'center' },
    headerStyle: { textAlign: 'center' },
    customSort: (a: IMarketLiquidityRiskData, b: IMarketLiquidityRiskData) => {
      if (a.asset === 'Portfolio') return 1;
      if (b.asset === 'Portfolio') return 1;
      return a.eBau > b.eBau ? 1 : -1;
    },
  },
  {
    title: 'E-BAU % of Total',
    field: 'eBauPercentTotal',
    render: (rowData) =>
      percentageToTwoDecimalPlacesNoHundred(rowData.eBauPercentTotal),
    cellStyle: { textAlign: 'center' },
    headerStyle: { textAlign: 'center' },
    customSort: (a: IMarketLiquidityRiskData, b: IMarketLiquidityRiskData) => {
      if (a.asset === 'Portfolio') return 1;
      if (b.asset === 'Portfolio') return 1;
      return a.eBauPercentTotal > b.eBauPercentTotal ? 1 : -1;
    },
  },
  {
    title: 'Stressed',
    field: 'stressed',
    render: (rowData) =>
      percentageToTwoDecimalPlacesNoHundred(rowData.stressed),
    cellStyle: { textAlign: 'center' },
    headerStyle: { textAlign: 'center' },
    customSort: (a: IMarketLiquidityRiskData, b: IMarketLiquidityRiskData) => {
      if (a.asset === 'Portfolio') return 1;
      if (b.asset === 'Portfolio') return 1;
      return a.stressed > b.stressed ? 1 : -1;
    },
  },
  {
    title: 'Stressed',
    field: 'stressedPercentTotal',
    render: (rowData) =>
      percentageToTwoDecimalPlacesNoHundred(rowData.stressedPercentTotal),
    cellStyle: { textAlign: 'center' },
    headerStyle: { textAlign: 'center' },
    customSort: (a: IMarketLiquidityRiskData, b: IMarketLiquidityRiskData) => {
      if (a.asset === 'Portfolio') return 1;
      if (b.asset === 'Portfolio') return 1;
      return a.stressedPercentTotal > b.stressedPercentTotal ? 1 : -1;
    },
  },
];

const MarketLiquidityRiskTable: FC<marketLiquidityRiskTableProps> = (props) => {
  // const theme = useTheme();
  const { data, title } = props;
  const tableData = buildSubscriptionsRedemptionData(data, title);
  // const { height, width } = useWindowSize()

  return (
    <GridItem xs={12} card style={{ padding: 8 }}>
      <Typography
        variant="h2"
        style={{
          marginLeft: '2rem',
          marginTop: '1rem',
          fontSize: '2rem',
          fontWeight: 400,
        }}
      >
        {title}
      </Typography>
      <CustomTable<IMarketLiquidityRiskData>
        pdfTitle={title}
        id={
          title === 'Redemption Risk'
            ? 'redemption_table'
            : 'subscription_table'
        }
        columns={detailColumns}
        showToolbar
        data={tableData}
        // toolbarComponents={{
        //     toolbarTitle: formattedNames[tableType],
        // }}
        options={{
          paging: false,
          search: false,
          exportButton: true,
          rowStyle: (rowData: any) => ({
            backgroundColor:
              rowData.asset === 'Portfolio' ? mainColors.jonquil : undefined,
          }),
        }}
      />
    </GridItem>
  );
};

export default MarketLiquidityRiskTable;
