import React, { FC } from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { TransitionProps } from '@mui/material/transitions';
import Slide from '@mui/material/Slide';
import useSnackbar from '../../../../../hooks/useSnackbar';
import axios from 'axios';
import client from '../../../../../utilities/requestClient';

interface PublishDocumentDialogBoxProps {
  setDialogIsShowing: (value: boolean) => void;
  fundId: string;
  shareClass: string;
  documentLanguage: string;
  fundName: string;
  updateVersionNumber: (
    fundId: string,
    shareClass: string,
    documentLanguage: string,
  ) => void;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const PublishDocumentDialogBox: FC<PublishDocumentDialogBoxProps> = (props) => {
  // const classes = useStyles();
  const {
    setDialogIsShowing,
    fundId,
    shareClass,
    documentLanguage,
    fundName,
    updateVersionNumber,
  } = props;
  const requestClient = client();

  const { showAsyncSnackbar } = useSnackbar();
  // Define a function for publishing changes
  async function publishChanges() {
    // Save the data to the DB
    await showAsyncSnackbar({
      startMessage: 'Publishing Latest Document.',
      successMessage: 'Document Published.',
      failureMessage: 'An Error occured while publishing.',
      promiseToResolve: requestClient
        .get(
          `kiid_generator_publish_latest_kiid/${fundId}/${shareClass}/${documentLanguage}`,
        )
        .then(() => {
          updateVersionNumber(fundId, shareClass, documentLanguage);
          setDialogIsShowing(false);
        }),
    });
  }

  return (
    <Dialog
      open={true}
      TransitionComponent={Transition}
      keepMounted
      // onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
      maxWidth="md"
      fullWidth={true}
      PaperProps={{ style: { overflowY: 'visible' } }}
    >
      <DialogTitle id="publish-header-text" textAlign="center">
        Publish Document?
      </DialogTitle>
      <DialogContentText id="publish-subheader-text" textAlign="center">
        Update the version number of this document to indicate the current
        version is published.
      </DialogContentText>
      <DialogContentText
        id="publish-fund-text"
        textAlign="center"
      >{`${fundName} - ${shareClass}`}</DialogContentText>
      <DialogActions>
        <Button onClick={() => setDialogIsShowing(false)}>Cancel</Button>
        <Button onClick={publishChanges}>Confirm</Button>
      </DialogActions>
    </Dialog>
  );
};

export default PublishDocumentDialogBox;
