// Internally at RS there is a system for categorising assets:
/**
 * * 1: Equity
 * * 2: Commodity
 * * 3: Foreign Exchange
 * * 4: Fixed Income
 * * 5: Cash
 * * 6: Alternative
 * * 7: Other
 *
 * This category provider function returns the type. It receives a string, and takes the first index of the string, and returns
 * the category depeneding on what the number is
 */

export default function categoryProvider(index: number | string) {
  switch (index.toString()) {
    case '1':
      return ['equity', 'Equity'];
    case '2':
      return ['commodity', 'Commodity'];
    case '3':
      return ['foreign_exchange', 'Foreign Exchange'];
    case '4':
      return ['fixed_income', 'Fixed Income'];
    case '5':
      return ['cash', 'Cash'];
    case '6':
      return ['alternative', 'Alternative'];
    case '7':
      return ['other', 'Other'];
    default:
      return 'not found';
  }
}

export function categoryProviderByNumber(
  number: number | string,
  instrument_id_provided = 0,
  isAdepa = false,
) {
  const index = number.toString()[0];
  switch (index) {
    case '1':
      return ['equity', 'Equity'];
    case '2':
      return ['commodity', 'Commodity'];
    case '3':
      return ['foreign_exchange', 'Foreign Exchange'];
    case '4':
      return ['fixed_income', 'Fixed Income'];
    case '5':
      return ['cash', 'Cash'];
    case '6':
      const primaryWord = isAdepa ? 'CIS' : 'Alternative';
      const addition = instrument_id_provided === 1 ? 'Cash' : 'Derivative';
      return [
        [primaryWord.toLowerCase(), addition.toLowerCase()].join('_'),
        [primaryWord, addition].join(' / '),
      ];
    case '7':
      return ['other', 'Other'];
    default:
      return 'not found';
  }
}
//instrument id - 1 ? cash : derivative
