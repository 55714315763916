import React, { ReactText } from 'react';
import { flexify } from '../../../../../../utilities/cssMixins';
import { Map } from '../../../../sharedComponents/overview/components/DetailMap.component';
import {
  EquityMapTableData,
  countryTableColumns,
  generateEquityMapData,
} from './map.data';
import RaptorLoading from '../../../../../feedback/RaptorLoading';
import { addCommasToNumbersAndRound } from '../../../../../../utilities/numberFormatters';
import CustomTable from '../../../../../tables/CustomTable';
import { DataObject } from '../../../../../../types/redux/data/dataTypes';
import GridItem from '../../../../../layout/GridComponents/GridItem';

export interface EquityMapProps {
  selectedEquityPage: string;
  inputData: DataObject;
}

const EquityMap = (props: EquityMapProps) => {
  const [mapData, highestValue, tableData] = generateEquityMapData(
    props.inputData.data,
    props.selectedEquityPage as 'grossCountryExposure' | 'netCountryExposure',
  );

  return (
    <>
      <GridItem card xs={12} lg={3}>
        <CustomTable<EquityMapTableData>
          columns={countryTableColumns.map((col) => ({
            ...col,
            cellStyle: { ...col.cellStyle, textAlign: 'center' },
            headerStyle: { ...col.headerStyle, textAlign: 'center' },
          }))}
          csvFields={['country', 'exposure']}
          options={{
            paging: false,
            exportButton: true,
            maxBodyHeight: 'calc(100vh - 20rem)',
          }}
          toolbarComponents={{
            toolbarTitle: 'Country Exposure',
          }}
          showToolbar
          data={tableData as EquityMapTableData[]}
        />
      </GridItem>
      <GridItem
        xs={12}
        lg={9}
        cardStyle={{
          width: '100%',
          height: 'calc(100vh - 15rem)',
          ...flexify('center', 'center'),
        }}
        card
      >
        <div style={{ height: 700, width: 1000 }}>
          {!props.inputData.isFetching ? (
            <Map
              tooltipLabelFormatter={(val: ReactText) => val as string}
              tooltipValueFormatter={(val: ReactText) =>
                addCommasToNumbersAndRound(val)
              }
              val={highestValue}
              data={mapData}
            />
          ) : (
            <div
              style={{
                height: '80rem',
                width: '100%',
                ...flexify('center', 'center'),
              }}
            >
              <RaptorLoading />
            </div>
          )}
        </div>
      </GridItem>
    </>
  );
};

export default EquityMap;
