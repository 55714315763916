import React from 'react';
import GridItem from '../../../../layout/GridComponents/GridItem';
import CustomTable from '../../../../tables/CustomTable';
import { CustomColumn } from '../../../../../types/components/tables/tableTypes';
import {
  addCommasToNumbersAndRound,
  percentageToNdecialPlaces,
} from '../../../../../utilities/numberFormatters';
import SecondLevelWrapper from './SecondLevelWrapper.component';
import { SecondLevelTableData } from './SecondLevelTable.component';
import { countryCodeToName } from '../../../../../utilities/generalMappings';

interface SubFundCumulativeBreakdownTableProps {
  data: any;
  selectedBreakdown: string;
}

export interface SubFundCumulativeBreakdownTableData {
  name: string;
  currencyPctPL: number;
  assetPctPL: number;
  totalPctPL: number;
  underlyingAssets: SecondLevelTableData[];
}

addCommasToNumbersAndRound;

const detailColumns: CustomColumn<SubFundCumulativeBreakdownTableData>[] = [
  {
    title: 'Name',
    field: 'name',
    width: '25%',
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: {
      textAlign: 'center',
    },
    render: (rowData: SubFundCumulativeBreakdownTableData) =>
      countryCodeToName(rowData.name),
  },
  {
    title: 'Asset PL %',
    field: 'assetPctPL',
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: {
      textAlign: 'center',
    },
    render: (rowData: SubFundCumulativeBreakdownTableData) =>
      percentageToNdecialPlaces(rowData.assetPctPL / 100, 2),
  },
  {
    title: 'Currency PL %',
    field: 'currencyPctPL',
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: {
      textAlign: 'center',
    },
    render: (rowData: SubFundCumulativeBreakdownTableData) =>
      percentageToNdecialPlaces(rowData.currencyPctPL / 100, 2),
  },
  {
    title: 'Total PL %',
    field: 'totalPctPL',
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: {
      textAlign: 'center',
    },
    render: (rowData: SubFundCumulativeBreakdownTableData) =>
      percentageToNdecialPlaces(rowData.totalPctPL / 100, 2),
  },
];

const buildTableData = (
  inputData: any,
  selectedBreakdown: string | null,
): SubFundCumulativeBreakdownTableData[] => {
  if (!inputData.data || !inputData.data.length) {
    return [];
  } else {
    const returnData: SubFundCumulativeBreakdownTableData[] = [];
    const key = selectedBreakdown ?? 'sector';
    inputData.data[0].drop_down_breakdown[key].map((sector: any) => {
      returnData.push({
        name: sector.identifier,
        currencyPctPL: sector.aggregate_currency_pl_pct,
        assetPctPL: sector.aggregate_asset_pl_pct,
        totalPctPL: sector.aggregate_pct_pl,
        underlyingAssets: sector.extra_info.map((asset: any) => {
          return {
            assetName: asset[0],
            assetClass: asset[1],
            assetType: asset[2],
            currency: asset[3],
            size: asset[4],
            totalPl: asset[6],
            totalPlPc: asset[7],
            assetPl: asset[8],
            assetPlPc: asset[9],
            currencyPl: asset[10],
            currencyPlPc: asset[11],
          };
        }),
      });
    });
    return returnData;
  }
};

const PortfolioBreakdownTable: React.FC<
  SubFundCumulativeBreakdownTableProps
> = ({ data, selectedBreakdown }) => {
  const builtTableData = buildTableData(data, selectedBreakdown);

  const title = `Sub-Fund Cumulative Breakdown - Returns By ${
    selectedBreakdown.charAt(0).toUpperCase() + selectedBreakdown.slice(1)
  }`; // capitalise first letter

  return (
    <GridItem xs={12} card>
      <CustomTable<SubFundCumulativeBreakdownTableData>
        columns={detailColumns}
        showToolbar
        data={builtTableData}
        title={title}
        options={{
          paging: false,
          search: true,
          exportButton: true,
        }}
        detailPanel={SecondLevelWrapper()}
      />
    </GridItem>
  );
};

export default PortfolioBreakdownTable;
