import React, { CSSProperties, ReactElement, ReactNode } from 'react';
import GridItem from '../layout/GridComponents/GridItem';
import { Link } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';

const useOverviewCardStyles = makeStyles(() => ({
  root: {
    alignSelf: 'stretch',
    cursor: 'pointer',
    transition: 'all 0.3s',
    '&:hover': {
      transform: 'scale(0.98)',
    },
  },
  link: {
    textDecoration: 'none',
  },
}));

interface OverviewCardProps {
  backgroundColor?: string;
  clickHandler?: () => void;
  additionalCardStyles?: CSSProperties;
  toVal: string;
  children: ReactNode;
}

const OverviewCard: React.FC<OverviewCardProps> = (
  props,
): ReactElement | null => {
  const classes = useOverviewCardStyles();
  return (
    <GridItem
      classes={{
        root: classes.root,
      }}
      cardStyle={{
        padding: '1rem',
        height: '100%',
        overflow: 'hidden',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        backgroundColor: props.backgroundColor || 'white',
        ...props.additionalCardStyles,
      }}
      onClick={props.clickHandler}
      card
      xs={2}
    >
      <Link to={props.toVal} className={classes.link}>
        {props.children}
      </Link>
    </GridItem>
  );
};

export default OverviewCard;
/*
      xs={12}
      sm={6}
      md={4}
      lg={3}
      xl={2}
      */
