import { UnfoldLess } from '@mui/icons-material';
import PrincipleAdverseImpactSecondLevelTable from './PrincipleAdverseImpactSecondLevelTable.component';
import { PrincipleAdverseImpactTableData } from './PrincipleAdverseImpactTable.component';

const PrincipleAdverseImpactSecondLevelTableWrapper = (
  positions: any[],
  nav: number,
  rules: { [index: string]: any },
) => {
  const getArrayOfCodesFromCodesObject = (codes: any): string[] => {
    let arrayOfCodes: string[] = [];
    Object.keys(codes).forEach((key) => {
      arrayOfCodes = arrayOfCodes.concat(codes[key]);
    });
    return arrayOfCodes;
  };

  return [
    (rowData: PrincipleAdverseImpactTableData) => {
      const render = () => (
        <PrincipleAdverseImpactSecondLevelTable
          rules={rules}
          inScopeCodes={getArrayOfCodesFromCodesObject(rowData.codes)}
          inScopePositions={rowData.positions}
          positions={positions}
          nav={nav}
        />
      );

      return {
        disabled: !rowData.positions.length,
        openIcon: UnfoldLess,
        render,
      };
    },
  ];
};

export default PrincipleAdverseImpactSecondLevelTableWrapper;
