import React from 'react';
import GridItem from '../../../../../layout/GridComponents/GridItem';
import CustomTable from '../../../../../tables/CustomTable';
import { CustomColumn } from '../../../../../../types/components/tables/tableTypes';
import { percentageToNdecialPlaces } from '../../../../../../utilities/numberFormatters';
import { DataObject } from '../../../../../../types/redux/data/dataTypes';

interface FundVsBenchmarkTableProps {
  data: DataObject;
  benchmark: string;
}

interface FundVsBenchmarkTableData {
  date: string;
  fundPerformance: number;
  benchmark: number;
}

const buildColumns = (
  benchmark: string,
): CustomColumn<FundVsBenchmarkTableData>[] => [
  {
    title: 'Date',
    field: 'date',
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: {
      textAlign: 'center',
    },
  },
  {
    title: 'Fund Performance',
    field: 'fundPerformance',
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: {
      textAlign: 'center',
    },
    render: (rowData: FundVsBenchmarkTableData) =>
      percentageToNdecialPlaces(rowData.fundPerformance / 100, 2),
  },
  {
    title: `${benchmark} Performance`,
    field: 'benchmark',
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: {
      textAlign: 'center',
    },
    render: (rowData: FundVsBenchmarkTableData) =>
      percentageToNdecialPlaces(rowData.benchmark / 100, 2),
  },
];

const buildTableData = (inputData: any): FundVsBenchmarkTableData[] => {
  if (!inputData.data || !inputData.data.length) {
    return [];
  } else {
    const returnData: any[] = [];
    inputData.data[0].fund_vs_benchmark.map((date: any) => {
      returnData.push({
        date: date.Date,
        fundPerformance: date.running_pl_Fund,
        benchmark: date.running_pl_Benchmark,
      });
    });
    return returnData.sort(
      (a: FundVsBenchmarkTableData, b: FundVsBenchmarkTableData) =>
        a.date.localeCompare(b.date, undefined, {
          numeric: true,
          sensitivity: 'base',
        }),
    );
  }
};

const FundVsBenchmarkTable: React.FC<FundVsBenchmarkTableProps> = ({
  data,
  benchmark,
}) => {
  const builtTableData = buildTableData(data);

  const columns = buildColumns(benchmark);

  return (
    <GridItem xs={12} card>
      <CustomTable<FundVsBenchmarkTableData>
        columns={columns}
        showToolbar
        data={builtTableData}
        title={'Sector Comparison'}
        options={{
          paging: false,
          search: true,
          exportButton: true,
        }}
      />
    </GridItem>
  );
};

export default FundVsBenchmarkTable;
