import makeStyles from '@mui/styles/makeStyles';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import useFetchData from '../../../../hooks/useFetchData';
import {
  createSectionByIdSelector,
  createSpecificFundNameAndIdSelectorByFundType,
} from '../../../../redux/pages/selectors';
import { mainColors } from '../../../../styling/theme';
import { CustomColumn } from '../../../../types/components/tables/tableTypes';
import { Status } from '../../../../types/redux/data/dataTypes';
import {
  percentageToTwoDecimalPlaces,
  toTwoDecimalPlaces,
} from '../../../../utilities/numberFormatters';
import RaptorLoading from '../../../feedback/RaptorLoading';
import { FundInfoComponentProps } from '../../../general/GeneralFundInfoPage';
import GridItem from '../../../layout/GridComponents/GridItem';
import CustomTable from '../../../tables/CustomTable';
import GenericStatusCell from '../../../tables/GenericStatusCell';
import Detail from './detail/Detail.component';
import { UnfoldLess, UnfoldMore } from '@mui/icons-material';

export interface PeStressTestsData {
  headerRow: boolean;
  stressScenarioType: string | null;
  name: string | null;
  pL: number | null;
  pLstatus: Status | null;
  exAnteVolatility: number | null;
  exAnteVar: number | null;
  numSds: number | null;
  numVar: number | null;
  exPostVolatility: number | null;
  exPostVar: number | null;
  historicalRow: boolean | null;
}

const useStyles = makeStyles(() => ({
  displayNone: {
    display: 'none',
  },
}));

export const buildPeStressColumns = (): CustomColumn<PeStressTestsData>[] => {
  return [
    {
      title: 'Stress/Scenario Type',
      field: 'stressScenarioType',
      headerStyle: {
        textAlign: 'center',
      },
      cellStyle: (rowData, rowDataValues) =>
        rowDataValues.headerRow
          ? {
              padding: '2rem',
              fontWeight: 700,
              color: mainColors.mainBlue,
              textAlign: 'center',
            }
          : {
              padding: 0,
            },
    },
    {
      title: 'Name',
      field: 'name',
      cellStyle: {
        padding: 0,
      },
      headerStyle: {},
    },
    {
      title: 'P/L',
      field: 'pL',
      cellStyle: {
        textAlign: 'center',
        padding: 0,
      },
      headerStyle: {
        textAlign: 'center',
      },
      pdfRenderType: 'StatusWithPercentage',
      renderMethod: {
        methodName: 'percentageToTwoDecimalPlaces',
        params: ['pL'],
      },
      render: (rowData) => {
        const statusCell = rowData.headerRow ? (
          ''
        ) : (
          <GenericStatusCell
            height={'5rem'}
            status={rowData.pLstatus!}
            innerText={percentageToTwoDecimalPlaces(rowData.pL!)}
          />
        );
        return statusCell;
      },
    },
    {
      title: 'Ex-Ante Volatility',
      field: 'exAnteVolatility',
      cellStyle: {
        textAlign: 'center',
        padding: 0,
      },
      headerStyle: {
        textAlign: 'center',
      },
      render: (rowData) =>
        rowData.headerRow
          ? ''
          : percentageToTwoDecimalPlaces(rowData.exAnteVolatility as number),
    },
    {
      title: 'Ex-Ante Quantile Risk',
      field: 'exAnteVar',
      cellStyle: {
        textAlign: 'center',
        padding: 0,
      },
      headerStyle: {
        textAlign: 'center',
      },
      render: (rowData) =>
        rowData.headerRow
          ? ''
          : percentageToTwoDecimalPlaces(rowData.exAnteVar as number),
    },
    {
      title: '#SDs',
      field: 'numSds',
      cellStyle: {
        textAlign: 'center',
        padding: 0,
      },
      headerStyle: {
        textAlign: 'center',
      },
      render: (rowData) =>
        rowData.headerRow ? '' : toTwoDecimalPlaces(rowData.numSds as number),
    },
    {
      title: '#Quantile Risk',
      field: 'numVar',
      cellStyle: {
        textAlign: 'center',
        padding: 0,
      },
      headerStyle: {
        textAlign: 'center',
      },
      render: (rowData) =>
        rowData.headerRow ? '' : toTwoDecimalPlaces(rowData.numVar as number),
    },
    {
      title: 'Ex-Post Volatility',
      field: 'exPostVolatility',
      cellStyle: {
        textAlign: 'center',
        padding: 0,
      },
      headerStyle: {
        textAlign: 'center',
      },
      render: (rowData) =>
        rowData.headerRow
          ? ''
          : percentageToTwoDecimalPlaces(rowData.exPostVolatility as number),
    },
    {
      title: 'Ex-Post Quantile Risk',
      field: 'exPostVar',
      cellStyle: {
        textAlign: 'center',
        padding: 0,
      },
      headerStyle: {
        textAlign: 'center',
      },
      render: (rowData) =>
        rowData.headerRow
          ? ''
          : percentageToTwoDecimalPlaces(rowData.exPostVar as number),
    },
  ];
};

const sectorNameMap: { [key: string]: string } = {
  risk_sector_stress_tests: 'Risk Sector Stress',
  historical_stress_tests: 'Historical Stress',
  relative_stress_tests: 'Relative Stress',
};

export function buildPeStressTestData(
  inputData: any[],
  inputRestrictions: any[],
) {
  if (!inputData || !inputRestrictions) return [];
  try {
    const mainData = inputData[0];
    // const nav = inputData[0].nav;
    const restrictions = inputRestrictions[0];
    let riskSectorData;
    let historicalData;
    const returnArr: PeStressTestsData[] = [];

    [
      'historical_stress_tests',
      'risk_sector_stress_tests',
      'relative_stress_tests',
    ].forEach((itemKey) => {
      if (itemKey in mainData && mainData[itemKey].length) {
        // add header row
        returnArr.push({
          headerRow: true,
          stressScenarioType: sectorNameMap[itemKey],
          name: null,
          pL: null,
          pLstatus: null,
          exAnteVolatility: null,
          exAnteVar: null,
          numSds: null,
          numVar: null,
          exPostVolatility: null,
          exPostVar: null,
          historicalRow: null,
        });
        mainData[itemKey].forEach((el: any) => {
          const name = el[0];
          const dataValuesForOtherColumns = el[1];
          const pLvalue = dataValuesForOtherColumns[0];
          returnArr.push({
            headerRow: false,
            stressScenarioType: null,
            name,
            pL: pLvalue,
            pLstatus:
              // pLvalue < 0 && Math.abs(pLvalue) >= restrictions[name]
              pLvalue < 0 && Math.abs(pLvalue) >= 0.5
                ? Status.Alert
                : Status.Pass, // come back to this
            exAnteVolatility: dataValuesForOtherColumns[1], // / nav,
            exAnteVar: dataValuesForOtherColumns[2], // / nav,
            numSds: dataValuesForOtherColumns[3],
            numVar: dataValuesForOtherColumns[4],
            exPostVolatility: dataValuesForOtherColumns[5], // / nav,
            exPostVar: dataValuesForOtherColumns[6], // / nav,
            historicalRow: itemKey === 'historical_stress_tests',
          });
        });
      }
    });

    return returnArr;
  } catch (err) {
    console.error('Problem building stress data: ', err);
  }
  return [];
}

// Hack to get the pe stress tests data into the format used by the usual format used by the
const modifyStressTestsData = (data: any) => {
  // const historicalOrder = [
  //   "Sept2008",
  //   "Oct2008",
  //   "Apr2009",
  //   "July2011",
  //   "Aug2011",
  //   "March2020",
  //   "April2020",
  // ]
  const historicalOrder = [
    'Credit Crisis Sept08',
    'Credit Crisis Oct08',
    'EU Sov Debt Crisis Jul11',
    'EU Sov Debt Crisis Aug11',
    'Covid Sell-off Mar20',
  ];
  const riskSectorOrder = [
    'Equity Down 5 pc',
    'Equity Down 10 pc',
    'Fixed Income Down 5 pc',
    'Fixed Income Down 10 pc',
    'USD Down 5 pc',
    'USD Down 10 pc',
    'FlightToDollarSafety',
  ];
  const relativeOrder: any = [
    'Gulf War 1991',
    'Asian Crisis 1997',
    'Russian - LTCM 1998',
    'Brexit Referendum',
  ];
  // Create lists for historical and risk sector stressTestRestrictions
  const historicalStressTests: any = [];
  const riskSectorStressTests: any = [];
  const relativeStressTests: any = [];

  // Loop over each value in the order lists to add to the stress test lists
  historicalOrder.map((key: string) => {
    if (key in data) historicalStressTests.push([key, data[key]]);
  });
  riskSectorOrder.map((key: string) => {
    if (key in data) riskSectorStressTests.push([key, data[key]]);
  });
  relativeOrder.map((key: string) => {
    if (key in data) relativeStressTests.push([key, data[key]]);
  });
  return {
    historical_order: historicalOrder,
    historical_stress_tests: historicalStressTests,
    relative_order: relativeOrder,
    relative_stress_tests: relativeStressTests,
    risk_sector_order: riskSectorOrder,
    risk_sector_stress_tests: riskSectorStressTests,
  };
};

const PeStressScenario: FC<FundInfoComponentProps> = ({
  fundId,
  positionDate,
  section,
}) => {
  const sectionDetailsSelector = createSectionByIdSelector(section);
  const sectionDetails = useSelector(sectionDetailsSelector);
  const fundDetailsSelector = createSpecificFundNameAndIdSelectorByFundType(
    sectionDetails?.fundTypes || 'UCITS',
    fundId,
    sectionDetails?.rsResources || 'RISK,LIQUIDITY',
  );
  const fundDetails = useSelector(fundDetailsSelector);

  const stressTestData = useFetchData({
    url: `get_private_equity_stress_test_aggregate/${fundId}/${positionDate}`,
    keyName: `stress_test_agg_data_${fundId}_${positionDate}`,
    makeFetch: positionDate ? true : false,
  });

  const stressTestRestrictions = [
    {
      Apr2009: 0.1,
      Aug2011: 0.1,
      'Equity Down 5 pc': 0.1,
      'Equity Down 10 pc': 0.1,
      'Fixed Income Down 5 pc': 0.1,
      'Fixed Income Down 10 pc': 0.1,
      FlightToDollarSafety: 0.1,
      July2011: 0.1,
      Oct2008: 0.1,
      Sept2008: 0.1,
      'USD Down 5 pc': 0.1,
      'USD Down 10 pc': 0.1,
      March2020: 0.1,
      April2020: 0.1,
    },
  ];

  const columns = buildPeStressColumns();

  const dataArrived = stressTestData; // && stressTestRestrictions;
  const finishedLoading = dataArrived && !stressTestData.isFetching; //&&
  //!stressTestRestrictions.isFetching;
  const classes = useStyles();

  return dataArrived && fundDetails ? (
    finishedLoading ? (
      <GridItem card xs={12}>
        <CustomTable<PeStressTestsData>
          pdfNoClearFirstRow
          options={{
            paging: false,
            exportButton: true,
          }}
          title={'Private Equity Stress Tests'}
          showToolbar={true}
          data={buildPeStressTestData(
            [modifyStressTestsData(stressTestData.data[0])],
            stressTestRestrictions, //.data
          )}
          toolbarComponents={{
            toolbarTitle: fundDetails.name,
          }}
          columns={columns}
          csvFields={[
            'stressScenarioType',
            'name',
            'pL',
            'pLstatus',
            'exAnteVolatility',
            'exAnteVar',
            'numSds',
            'numVar',
            'exPostVolatility',
            'exPostVar',
          ]}
          detailPanel={[
            (rowData) => ({
              disabled: rowData.headerRow || !rowData.historicalRow,
              icon: () => (
                <UnfoldMore
                  className={
                    rowData.headerRow || !rowData.historicalRow
                      ? classes.displayNone
                      : undefined
                  }
                />
              ),
              openIcon: UnfoldLess,
              render: (rowData: PeStressTestsData) => {
                return (
                  <Detail
                    dataKey={rowData.name ? rowData.name : ''}
                    fundId={fundId}
                    positionDate={positionDate}
                  />
                );
              },
            }),
          ]}
        />
      </GridItem>
    ) : (
      <RaptorLoading centerWrap />
    )
  ) : null;
};

export default PeStressScenario;
