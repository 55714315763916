import React, { ReactElement } from 'react';
import useSnackbar from '../../../../../hooks/useSnackbar';
import { CustomColumn } from '../../../../../types/components/tables/tableTypes';
import {
  addCommasToNumbersAndRound,
  percentageToTwoDecimalPlaces,
  percentageToTwoDecimalPlacesNoHundred,
} from '../../../../../utilities/numberFormatters';
import CustomTable from '../../../../tables/CustomTable';
import varComponentWrapper, {
  VarComponentProps,
  VarTableData,
  VarWrapperProps,
} from '../VarComponentWrapper.component';

const generateVarTableColumns = (): CustomColumn<VarTableData>[] => [
  {
    field: 'number',
    title: '#',
    cellStyle: {
      textAlign: 'center',
    },
    width: 1,
    headerStyle: {
      textAlign: 'center',
    },
  },
  {
    field: 'portfolio',
    title: 'Portfolio',
    cellStyle: {
      textAlign: 'left',
    },
    headerStyle: {
      textAlign: 'left',
    },
  },
  {
    field: 'equity',
    title: 'Equity',
    cellStyle: {
      textAlign: 'left',
    },
    headerStyle: {
      textAlign: 'left',
    },
  },
  {
    field: 'commodity',
    title: 'Commodity',
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: {
      textAlign: 'center',
    },
  },
  {
    field: 'forex',
    title: 'Forex',
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: {
      textAlign: 'center',
    },
  },
  {
    field: 'fixedIncome',
    title: 'Fixed Income',
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: {
      textAlign: 'center',
    },
  },
  {
    field: 'cash',
    title: 'Cash',
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: {
      textAlign: 'center',
    },
  },
  {
    field: 'alternative',
    title: 'Alternative',
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: {
      textAlign: 'center',
    },
  },
  {
    field: 'total',
    title: 'Total',
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: {
      textAlign: 'center',
    },
    render: (rowData) => percentageToTwoDecimalPlacesNoHundred(rowData.total),
  },
];

// generate the columns
// most of the columns are rendered with percentages, so we map over the columns and add the render function

function VarNewMainTable({
  riskOutput,
  riskMetric,
  renderData,
  ...props
}: VarComponentProps & VarWrapperProps): ReactElement {
  let fn: (val: number) => string;
  switch (riskOutput) {
    case 'Cash':
      fn = addCommasToNumbersAndRound;
      break;
    case 'absolute':
      fn = percentageToTwoDecimalPlaces;
      break;
    case 'relative':
      fn = percentageToTwoDecimalPlaces;
      break;
    default:
      fn = (val: number) => val + '';
  }
  const columns = generateVarTableColumns().map(
    (col: CustomColumn<VarTableData>) => {
      if (col.field === 'number' || col.field === 'portfolio') {
        return col;
      } else {
        return {
          ...col,
          render: (rowData: VarTableData) =>
            fn(Number(rowData[col.field as keyof VarTableData])),
        };
      }
    },
  );

  const { showSnackbarMessage } = useSnackbar();

  return (
    <CustomTable<VarTableData>
      columns={columns}
      data={renderData}
      options={{
        paging: false,
        exportButton: true,
      }}
      showToolbar
      title="Value at Risk Exposure"
    />
  );
}

export default varComponentWrapper(VarNewMainTable);
