import React, { FC } from 'react';
import { CustomColumn } from '../../../../types/components/tables/tableTypes';
import {
  formatESGWithUnit,
  percentageToTwoDecimalPlaces,
} from '../../../../utilities/numberFormatters';
import GridItem from '../../../layout/GridComponents/GridItem';
import CustomTable from '../../../tables/CustomTable';
import { PEPageLinkButton } from '../../privateEquity/peOverview/PeOverview.component';
import ArticleScannerSecondLevelTableWrapper from './ArticleScannerSecondLevelTableWrapper.component';
import { specifyStatus } from '../../../../utilities/generalMappings';

interface ArticleScannerTableProps {
  data: any;
}

export interface ArticleScannerTableData {
  name: string;
  unalignedGrossExposure: string;
  unalignedNetExposure: string;
  status: string;
  positions: any[];
  limit: number | string;
}

const getPositionsDataGivenPositionIds = (data: any, positionIds: string[]) => {
  if (!data.data.length) return [];
  if (!('positions' in data.data[0])) return [];
  const positionData: any[] = [];
  data.data[0].positions.forEach((position: any) => {
    if (positionIds.includes(position.position_id)) {
      positionData.push(position);
    }
  });
  return positionData;
};

function buildArticleScannerTableData(data: any): ArticleScannerTableData[] {
  if (!data.data.length) return [];
  if (!('sfdr_page' in data.data[0])) return [];
  const articleData = data.data[0].sfdr_page;

  const tableData: ArticleScannerTableData[] = [
    {
      name: 'Article 6',
      unalignedGrossExposure: formatESGWithUnit(
        articleData['A_6'].unaligned_gross_exposure.split('%')[0],
        '%',
      ),
      unalignedNetExposure: formatESGWithUnit(
        articleData['A_6'].unaligned_net_exposure.split('%')[0],
        '%',
      ),
      status: articleData['A_6'].status,
      positions: getPositionsDataGivenPositionIds(
        data,
        articleData['A_6'].positions,
      ),
      limit:
        articleData['A_6'].limit !== undefined
          ? formatESGWithUnit(articleData['A_6'].limit, '%')
          : formatESGWithUnit(
              articleData.overview_information.limit.split('%')[0],
              '%',
            ),
    },
    {
      name: 'Article 8',
      unalignedGrossExposure: formatESGWithUnit(
        articleData['A_8'].unaligned_gross_exposure.split('%')[0],
        '%',
      ),
      unalignedNetExposure: formatESGWithUnit(
        articleData['A_8'].unaligned_net_exposure.split('%')[0],
        '%',
      ),
      status: articleData['A_8'].status,
      positions: getPositionsDataGivenPositionIds(
        data,
        articleData['A_8'].positions,
      ),
      limit:
        articleData['A_8'].limit !== undefined
          ? formatESGWithUnit(articleData['A_8'].limit, '%')
          : formatESGWithUnit(
              articleData.overview_information.limit.split('%')[0],
              '%',
            ),
    },
    {
      name: 'Article 9',
      unalignedGrossExposure: formatESGWithUnit(
        articleData['A_9'].unaligned_gross_exposure.split('%')[0],
        '%',
      ),
      unalignedNetExposure: formatESGWithUnit(
        articleData['A_9'].unaligned_net_exposure.split('%')[0],
        '%',
      ),
      status: articleData['A_9'].status,
      positions: getPositionsDataGivenPositionIds(
        data,
        articleData['A_9'].positions,
      ),
      limit:
        articleData['A_9'].limit !== undefined
          ? formatESGWithUnit(articleData['A_9'].limit, '%')
          : formatESGWithUnit(
              articleData.overview_information.limit.split('%')[0],
              '%',
            ),
    },
  ];

  return tableData;
}

const detailColumns: CustomColumn<ArticleScannerTableData>[] = [
  {
    title: 'Name',
    field: 'name',
    cellStyle: { textAlign: 'center' },
    headerStyle: { textAlign: 'center' },
  },
  {
    title: 'Unaligned Gross Exposure',
    field: 'unalignedGrossExposure',
    cellStyle: { textAlign: 'center' },
    headerStyle: { textAlign: 'center' },
    render: (rowData: ArticleScannerTableData) =>
      percentageToTwoDecimalPlaces(rowData.unalignedGrossExposure),
  },
  {
    title: 'Unaligned Net Exposure',
    field: 'unalignedNetExposure',
    cellStyle: { textAlign: 'center' },
    headerStyle: { textAlign: 'center' },
    render: (rowData: ArticleScannerTableData) =>
      percentageToTwoDecimalPlaces(rowData.unalignedNetExposure),
  },
  {
    title: 'Limit',
    field: 'limit',
    cellStyle: { textAlign: 'center' },
    headerStyle: { textAlign: 'center' },
    render: (rowData: ArticleScannerTableData) =>
      percentageToTwoDecimalPlaces(rowData.limit),
  },
  {
    title: 'Status',
    field: 'status',
    cellStyle: { textAlign: 'center' },
    headerStyle: { textAlign: 'center' },
    render: (rowData: ArticleScannerTableData) => (
      <PEPageLinkButton status={specifyStatus(rowData.status)} />
    ),
  },
];

const ArticleScannerTable: FC<ArticleScannerTableProps> = ({ data }) => {
  const tableData = buildArticleScannerTableData(data);

  return (
    <GridItem card xs={12}>
      <CustomTable<ArticleScannerTableData>
        columns={detailColumns}
        showToolbar={false}
        data={tableData}
        title={'Article Scanner Results'}
        options={{
          paging: false,
          search: false,
          exportButton: false,
        }}
        detailPanel={ArticleScannerSecondLevelTableWrapper(
          data.data.length ? data.data[0].nav : 0,
        )}
      />
    </GridItem>
  );
};

export default ArticleScannerTable;
