import makeStyles from '@mui/styles/makeStyles';
import { FC } from 'react';
import { CSVLink } from 'react-csv';
import { mainColors } from '../../../../styling/theme';
import { CustomColumn } from '../../../../types/components/tables/tableTypes';
import { DataObject } from '../../../../types/redux/data/dataTypes';
import { hexToRGBA } from '../../../../utilities/colorUtilities';
import { buildCsv } from '../../../../utilities/csvBuilder';
import { formatDateForCheckingState } from '../../../../utilities/dateFormatters';
import { addCommasToNumbersAndRound } from '../../../../utilities/numberFormatters';
import GridItem from '../../../layout/GridComponents/GridItem';
import CustomTable from '../../../tables/CustomTable';
import { PEPageLinkButton } from '../../privateEquity/peOverview/PeOverview.component';
import ShareClassTableWrapper from './subComponents/shareClassTable.component.tsx/ShareClassTableWrapper.component';
import { Button, Typography } from '@mui/material';
import { specifyStatus } from '../../../../utilities/generalMappings';

interface ShareClassHedgeSummaryProps {
  data: DataObject;
}

export interface ShareClassHedgeSummaryData {
  fundName: string;
  fundId: string;
  totalAggregateHedgeExposure: number;
  totalToHedgeExposure: number;
  aggExposureHedgeStatus: string;
  secondLevelData: any[];
}

const useDisplayOptionsButtonsStyles = makeStyles(() => ({
  container: {
    lineHeight: 25,
  },
  contained: {
    height: '3rem',
    background: 'white',
    borderRadius: 8,
  },
  rootExport: {
    marginRight: '0.4rem',
    marginTop: '1rem',
    display: 'inline-flex',
    backgroundColor: mainColors.pineGreen,
    '&:hover': {
      backgroundColor: hexToRGBA(mainColors.pineGreen, 0.5),
    },
  },
  label: {
    flexDirection: 'column',

    alignItems: 'start',
  },
}));

interface ExportButtonProps {
  dataToExport: any;
  positionDate?: string;
}

const ExportDataButton: FC<ExportButtonProps> = ({
  dataToExport,
  positionDate,
}) => {
  const classes = useDisplayOptionsButtonsStyles({ tableFilterOption: '' });

  const fields = [
    'fundId',
    'fundName',
    'hedgeExposureName',
    'exposureHedgeType',
    'aggregateHedgeExposure',
    'toHedgeExposure',
    'exposureHedgeRatio',
    'exposureHedgeStatus',
    'exposureHedgeStatusType',
  ];

  const csvData = buildCsv(fields, dataToExport);

  return (
    <CSVLink
      data={csvData}
      target="_blank"
      filename={`share_class_hedge_ratio_${
        positionDate ? positionDate : formatDateForCheckingState(new Date())
      }`}
    >
      <Button
        variant="contained"
        classes={{
          contained: classes.contained,
          root: classes.rootExport,
        }}
      >
        <Typography
          variant="h3"
          color="primary"
          style={{
            color: 'white',
          }}
        >
          {'Export to File'}
        </Typography>
      </Button>
    </CSVLink>
  );
};

function buildShareClassHedgeSummaryData(
  data: DataObject,
): ShareClassHedgeSummaryData[] {
  if (!data) return [];
  if (!data.data) return [];

  const shareClassData = data.data[0];
  // Create an object where the key is the fundId and value will be a ShareClassHedgeSummaryData object
  interface shareClassHedgeDict {
    [key: string]: ShareClassHedgeSummaryData;
  }
  const shareClassDict: shareClassHedgeDict = {};
  shareClassData.forEach((value: any) => {
    // Check if the fund is alread in the dict.
    if (value.fund_name in shareClassDict) {
      // Increment the exposure values.
      shareClassDict[value.fund_name].totalAggregateHedgeExposure =
        shareClassDict[value.fund_name].totalAggregateHedgeExposure +
        value.aggregate_hedge_exposure;
      shareClassDict[value.fund_name].totalToHedgeExposure =
        shareClassDict[value.fund_name].totalToHedgeExposure +
        value.to_hedge_exposure;
      shareClassDict[value.fund_name].aggExposureHedgeStatus =
        (value.exposure_hedge_status === 'Fail' ||
          value.exposure_hedge_status == 'Alert') &&
        shareClassDict[value.fund_name].aggExposureHedgeStatus !== 'Fail'
          ? value.exposure_hedge_status
          : shareClassDict[value.fund_name].aggExposureHedgeStatus;
      shareClassDict[value.fund_name].secondLevelData.push(value);
    } // Otherwise add it to the dictionary.
    else {
      shareClassDict[value.fund_name] = {
        fundName: value.fund_full_name,
        fundId: value.fund_name,
        totalAggregateHedgeExposure: value.aggregate_hedge_exposure,
        totalToHedgeExposure: value.to_hedge_exposure,
        aggExposureHedgeStatus: value.exposure_hedge_status,
        secondLevelData: [value],
      };
    }
  });

  const tableData: ShareClassHedgeSummaryData[] = Object.values(shareClassDict);

  return tableData;
}

interface csvExportData {
  fundId: string;
  fundName: string;
  hedgeExposureName: string;
  exposureHedgeType: string;

  positionDate: string;
  lastUpdatingDatetime: string;
  shareClassId: string;
  aggregateHedgeExposure: number;
  toHedgeExposure: number;
  exposureHedgeRatio: string;
  exposureHedgeStatus: string;
  exposureHedgeStatusType: string;
}

export function buildSedondLevelCSV(
  data: any,
  fundId: string,
  fundName: string,
): csvExportData[] {
  const tableData: csvExportData[] = [];
  data.forEach((value: any) => {
    tableData.push({
      fundId,
      fundName,
      exposureHedgeType: value.exposure_hedge_type,
      hedgeExposureName: value.hedge_exposure_name,
      positionDate: value.position_date,
      lastUpdatingDatetime: value.last_updating_datetime,
      shareClassId: value.share_class_id,
      aggregateHedgeExposure: value.aggregate_hedge_exposure,
      toHedgeExposure: value.to_hedge_exposure,
      exposureHedgeRatio: value.exposure_hedge_ratio,
      exposureHedgeStatus: value.exposure_hedge_status,
      exposureHedgeStatusType: value.exposure_hedge_status_type,
    });
  });
  return tableData;
}

function buildCSVExportData(data: ShareClassHedgeSummaryData[]) {
  let exportData: any[] = [];
  data.forEach((value: any) => {
    const secondLevel = buildSedondLevelCSV(
      value.secondLevelData,
      value.fundId,
      value.fundName,
    );
    exportData = exportData.concat(secondLevel);
  });
  return exportData;
}

const columns: CustomColumn<ShareClassHedgeSummaryData>[] = [
  {
    title: 'Internal Fund ID',
    field: 'fundId',
    cellStyle: { textAlign: 'center' },
    headerStyle: { textAlign: 'center' },
  },
  {
    title: 'Fund Name',
    field: 'fundName',
    cellStyle: { textAlign: 'center' },
    headerStyle: { textAlign: 'center' },
  },
  {
    title: 'Total Aggregate Hedge Exposure',
    field: 'totalAggregateHedgeExposure',
    cellStyle: { textAlign: 'center' },
    headerStyle: { textAlign: 'center' },
    render: (rowData: ShareClassHedgeSummaryData) =>
      addCommasToNumbersAndRound(rowData.totalAggregateHedgeExposure),
  },
  {
    title: 'Total To Hedge Exposure',
    field: 'totalToHedgeExposure',
    cellStyle: { textAlign: 'center' },
    headerStyle: { textAlign: 'center' },
    render: (rowData: ShareClassHedgeSummaryData) =>
      addCommasToNumbersAndRound(rowData.totalToHedgeExposure),
  },
  {
    title: 'Aggregate Exposure Hedge Status',
    field: 'aggExposureHedgeStatus',
    cellStyle: { textAlign: 'center' },
    headerStyle: { textAlign: 'center' },
    render: (rowData: ShareClassHedgeSummaryData) => (
      <PEPageLinkButton
        status={specifyStatus(rowData.aggExposureHedgeStatus)}
      />
    ),
    pdfRenderType: 'PageLinkButton',
  },
];

const ShareClassHedgeSummaryTable: FC<ShareClassHedgeSummaryProps> = ({
  data,
}) => {
  const tableData = buildShareClassHedgeSummaryData(data);
  const csvData = buildCSVExportData(tableData);

  return (
    <GridItem xs={12} card>
      <Typography
        variant="h2"
        style={{
          marginLeft: '2rem',
          marginTop: '1rem',
          fontSize: '3rem',
          fontWeight: 400,
        }}
      >
        Share Class Hedge Summary
      </Typography>
      <div style={{ marginLeft: '2rem' }}>
        <ExportDataButton dataToExport={csvData} />
      </div>
      <CustomTable<ShareClassHedgeSummaryData>
        options={{ paging: false, search: true, exportButton: true }}
        columns={columns}
        data={tableData}
        showToolbar={true}
        detailPanel={ShareClassTableWrapper()}
      />
    </GridItem>
  );
};

export default ShareClassHedgeSummaryTable;
