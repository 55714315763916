import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

// styling for the main positions table component
export const PostionUpdatePortalStyles = makeStyles<Theme>((theme) => ({
  tutorialBox: {
    padding: '1rem 2rem',
  },
  tutorialBoxTitle: {
    fontSize: '2.5rem',
    fontWeight: 'bold',
    lineHeight: '3rem',
  },
  tutorialBoxContent: {
    fontSize: '1.8rem',
    marginTop: '1rem',
  },
  tutorialBoxContent_warning: {
    fontSize: '1.8rem',
    marginTop: '1rem',
    color: 'red',
  },
}));
