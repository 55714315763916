import { CustomColumn } from '../../../../types/components/tables/tableTypes';
import { DataObject } from '../../../../types/redux/data/dataTypes';

export interface SubFundSummaryBasicInfoData {
  internalFundName: string;
  externalFundName: string;
  baseCurrency: string;
  complianceRules: string;
  globalExposureMetric: string;
}

export function generateColumnsForTable(columnParams: [string, string][]) {
  return columnParams.map((el) => ({
    field: el[0],
    title: el[1],
  }));
}

/**
 *
 * @param styleDictionary: An object containing keys that correspond to the fields of the columns.
 * If a column is included, you can speciy the row style and the header style in this object
 * For example, {
 * . positionDate: {
 *     cellStyle: {
 *      backgroundColor: 'red'
 *      },
 *      headerStyle: {
 *          color: 'blue'
 *      }
 * }
 * }
 * @param columns: 'the columns that you're iterating over
 * @returns
 */

export function applyStylesToTableColumns<T extends object>(
  styleDictionary: Partial<
    Record<keyof T, { cellStyle?: any; headerStyle?: any }>
  >,
  columns: CustomColumn<T>[],
): CustomColumn<T>[] {
  return columns.map((col: CustomColumn<T>) => {
    if (
      col.field !== undefined &&
      typeof col.field === 'string' &&
      col.field in styleDictionary
    ) {
      return {
        ...col,
        ...styleDictionary[col.field as keyof T],
      };
    } else {
      return col;
    }
  });
}

export const subFundSummaryBasicInfoColumns =
  applyStylesToTableColumns<SubFundSummaryBasicInfoData>(
    {
      internalFundName: {
        headerStyle: { textAlign: 'center' },
        cellStyle: { textAlign: 'center' },
      },
      externalFundName: {
        headerStyle: { textAlign: 'center' },
        cellStyle: { textAlign: 'center' },
      },
      baseCurrency: {
        headerStyle: { textAlign: 'center' },
        cellStyle: { textAlign: 'center' },
      },
      complianceRules: {
        headerStyle: { textAlign: 'center' },
        cellStyle: { textAlign: 'center' },
      },
      globalExposureMetric: {
        headerStyle: { textAlign: 'center' },
        cellStyle: { textAlign: 'center' },
      },
    },
    generateColumnsForTable([
      ['internalFundName', 'Fund ID'],
      ['externalFundName', 'Fund Name'],
      ['baseCurrency', 'Currency'],
      ['complianceRules', 'Compliance Rules'],
      ['globalExposureMetric', 'Global Exposure Metric'],
    ]),
  );

export interface SubFundSummaryCounterpartyTable {
  counterparty: string;
  counterpartyType: string;
}

export interface SubFundSummaryRiskComplianceRulesTable {
  rule: string;
  value: number;
}

export interface SubFundSummaryStressTestTable {
  stressTest: string;
}

export const subFundSummaryRiskComplianceColumns =
  applyStylesToTableColumns<SubFundSummaryRiskComplianceRulesTable>(
    {
      rule: {
        headerStyle: { textAlign: 'center' },
        cellStyle: { textAlign: 'center' },
      },
      value: {
        headerStyle: { textAlign: 'center' },
        cellStyle: { textAlign: 'center' },
      },
    },
    generateColumnsForTable([
      ['rule', 'Rule'],
      ['value', 'Value'],
    ]),
  );

export const stressTestColumns =
  applyStylesToTableColumns<SubFundSummaryStressTestTable>(
    {
      stressTest: {
        headerStyle: { textAlign: 'center' },
        cellStyle: { textAlign: 'center' },
      },
    },
    generateColumnsForTable([['stressTest', 'Stress Test']]),
  );

export const subFundSummaryCounterpartyColumns = generateColumnsForTable([
  ['counterparty', 'Counterparty'],
  ['counterpartyType', 'Counterparty Type'],
]);

// ============ GENERATE DATA

export function generateData(
  data: DataObject,
  callback: any,
  ...theArgs: any[]
) {
  if (!data || data.isFetching) return [];
  return callback(data.data[0], ...theArgs);
}

export function generateBasicInfo(data: any) {
  const {
    base_currency: baseCurrency,
    external_fund_name: externalFundName,
    internal_fund_name: internalFundName,
    global_exposure_metric: globalExposureMetric,
    compliance_rules: complianceRules,
  } = data;
  return [
    {
      baseCurrency,
      externalFundName,
      internalFundName,
      globalExposureMetric,
      complianceRules,
    },
  ];
}

export function generateStressTestInfo(
  data: any,
  arg: 'historical' | 'relative' | 'risk_sector',
) {
  const { stress_test_data: stressTestData } = data;
  return stressTestData[arg].map((el: any) => ({
    stressTest: el,
  }));
}

export function generateRiskComplianceRulesInfo(data: any) {
  const { risk_compliance: rcRules } = data;
  const toReturn = rcRules.map((el: any) => ({
    rule: el[0],
    value: el[1],
  }));

  // get rid of header row
  toReturn.shift();
  return toReturn;
}

export function generateCounterpartyInfo(data: any) {
  const { counterparty_data: cpData } = data;
  const toReturn = cpData.map((el: any) => ({
    counterparty: el[0],
    counterpartyType: el[1],
  }));
  toReturn.shift();
  return toReturn;
}
