import { Status } from '../../../../../types/redux/data/dataTypes';
import { CustomColumn } from '../../../../../types/components/tables/tableTypes';
import {
  addCommasToNumbersAndRound,
  percentageToNdecialPlacesNoHundred,
  percentageToTwoDecimalPlaces,
  percentageToTwoDecimalPlacesNoHundred,
} from '../../../../../utilities/numberFormatters';
import makeStyles from '@mui/styles/makeStyles';
import { RaptorTheme } from '../../../../../styling/theme';
import RaptorStatusBox from '../../../../feedback/RaptorStatusBox.component';
import { Tooltip, Typography } from '@mui/material';
import { specifyStatus } from '../../../../../utilities/generalMappings';

interface RuleStatusExtended {
  overallStatus: Status;
  individualStatuses: IndividualRuleStatus[];
}

interface IndividualRuleStatus {
  status: Status;
  rule: string;
  ruleDescription: string;
}

export interface UcitsFixedData {
  scenario: number;
  nav: number;
  status: Status;
  rulesStatus: Status | RuleStatusExtended;
}
export interface SifRiskSpreadData {
  scenario: number;
  nav: number;
  riskSpreadStatus: Status;
  // don't know what asset is
  asset: any;
}

export interface RiaifRiskSpreadData {
  scenario: number;
  nav: number;
  riaifStatus: Status;
  // don't know what asset is
  rulesStatus: any;
}

export interface Act40RiskSpreadData {
  scenario: number;
  nav: number;
  act40Status: Status;
  // don't know what asset is
  rulesStatus: any;
}

export interface ProspectusFixedData {
  scenario: number | string;
  nav: number;
  status: Status;
  rulesStatus: Status | RuleStatusExtended;
}

export interface TimeSeriesFixedData {
  scenario: number | string;
  nav: number;
  var: number;
  correlation: number;
  ssd: number;
}

const useStatusStyles = makeStyles<RaptorTheme>((theme) => ({
  status: {
    '& div:not(:last-child)': {
      marginTop: '1rem',
      marginBottom: '1rem',
    },
    '& div': {
      marginRight: '1rem',
    },
  },
}));

export const RenderStatuses = ({
  ruleStatusValues,
}: {
  ruleStatusValues: string | RuleStatusExtended;
}) => {
  const classes = useStatusStyles();
  return typeof ruleStatusValues === 'string' ? (
    <RaptorStatusBox status={Status.Pass} />
  ) : (
    <div className={classes.status}>
      {ruleStatusValues.individualStatuses.map(
        (individual: IndividualRuleStatus, index: number) => (
          <RaptorStatusBox
            key={`${individual.rule} _${index}`}
            status={individual.status}
            message={individual.rule.split('_').join(' ')}
            tooltipValue={individual.ruleDescription}
          />
        ),
      )}
    </div>
  );
};

export const ucitsFixedColumns: CustomColumn<UcitsFixedData>[] = [
  {
    title: 'NAV Change',
    field: 'scenario',
    width: 5,
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: {
      textAlign: 'center',
    },
  },
  {
    title: 'NAV',
    field: 'nav',
    width: 160,
    cellStyle: {
      textAlign: 'right',
    },
    headerStyle: {
      textAlign: 'center',
    },
    render: (rowData) => addCommasToNumbersAndRound(rowData.nav),
  },
  {
    title: 'UCITS Status',
    field: 'ucitsStatus',
    width: 'calc((100% - 165px) / 2)',
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: {
      textAlign: 'center',
    },
    render: (rowData: UcitsFixedData) => (
      <RaptorStatusBox status={specifyStatus(rowData.status)} />
    ),
  },
  {
    title: 'Rules Status',
    field: 'rulesStatus',
    width: 'calc((100% - 165px) / 2)',
    headerStyle: {
      textAlign: 'center',
    },
    cellStyle: {
      textAlign: 'center',
    },
    render: (rowData) => (
      <RenderStatuses ruleStatusValues={rowData.rulesStatus} />
    ),
  },
];

export const SifRiskSpreadFixedColumns: CustomColumn<SifRiskSpreadData>[] = [
  {
    title: 'NAV Change',
    field: 'scenario',
    width: 5,
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: {
      textAlign: 'center',
    },
  },
  {
    title: 'NAV',
    field: 'nav',
    width: 160,
    cellStyle: {
      textAlign: 'right',
    },
    headerStyle: {
      textAlign: 'center',
    },
    render: (rowData) => addCommasToNumbersAndRound(rowData.nav),
  },
  {
    title: 'Risk Spread Status',
    field: 'riskSpreadStatus',
    width: 'calc((100% - 165px) / 2)',
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: {
      textAlign: 'center',
    },
    render: (rowData: SifRiskSpreadData) => (
      <RaptorStatusBox status={specifyStatus(rowData.riskSpreadStatus)} />
    ),
  },
  {
    title: 'Asset',
    field: 'asset',
    width: 'calc((100% - 165px) / 2)',
    headerStyle: {
      textAlign: 'center',
    },
    cellStyle: {
      textAlign: 'center',
    },
  },
];

export const riaifFixedColumns: CustomColumn<RiaifRiskSpreadData>[] = [
  {
    title: 'NAV Change',
    field: 'scenario',
    width: 5,
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: {
      textAlign: 'center',
    },
  },
  {
    title: 'NAV',
    field: 'nav',
    width: 160,
    cellStyle: {
      textAlign: 'right',
    },
    headerStyle: {
      textAlign: 'center',
    },
    render: (rowData) => addCommasToNumbersAndRound(rowData.nav),
  },
  {
    title: 'RIAIF Status',
    field: 'riaifStatus',
    width: 'calc((100% - 165px) / 2)',
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: {
      textAlign: 'center',
    },
    render: (rowData: RiaifRiskSpreadData) => (
      <RaptorStatusBox status={specifyStatus(rowData.riaifStatus)} />
    ),
  },
  {
    title: 'Rules Status',
    field: 'rulesStatus',
    width: 'calc((100% - 165px) / 2)',
    headerStyle: {
      textAlign: 'center',
    },
    cellStyle: {
      textAlign: 'center',
    },
    render: (rowData) => (
      <RenderStatuses ruleStatusValues={rowData.rulesStatus} />
    ),
  },
];

export const act40FixedColumns: CustomColumn<Act40RiskSpreadData>[] = [
  {
    title: 'NAV Change',
    field: 'scenario',
    width: 5,
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: {
      textAlign: 'center',
    },
  },
  {
    title: 'NAV',
    field: 'nav',
    width: 160,
    cellStyle: {
      textAlign: 'right',
    },
    headerStyle: {
      textAlign: 'center',
    },
    render: (rowData) => addCommasToNumbersAndRound(rowData.nav),
  },
  {
    title: '40 Act Status',
    field: 'act40Status',
    width: 'calc((100% - 165px) / 2)',
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: {
      textAlign: 'center',
    },
    render: (rowData: Act40RiskSpreadData) => (
      <RaptorStatusBox status={specifyStatus(rowData.act40Status)} />
    ),
  },
  {
    title: 'Rules Status',
    field: 'rulesStatus',
    width: 'calc((100% - 165px) / 2)',
    headerStyle: {
      textAlign: 'center',
    },
    cellStyle: {
      textAlign: 'center',
    },
    render: (rowData) => (
      <RenderStatuses ruleStatusValues={rowData.rulesStatus} />
    ),
  },
];

export const prospectusFixedColumns: CustomColumn<ProspectusFixedData>[] = [
  {
    title: 'NAV Change',
    field: 'scenario',
    width: 5,
    cellStyle: {
      textAlign: 'center',
    },
  },
  {
    title: 'NAV',
    field: 'nav',
    width: 160,
    render: (rowData) => addCommasToNumbersAndRound(rowData.nav),
    cellStyle: {
      textAlign: 'right',
    },
    headerStyle: {
      textAlign: 'center',
    },
  },
  {
    title: 'Risk Status',
    field: 'status',
    width: 'calc((100% - 165px) / 2)',
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: {
      textAlign: 'center',
    },
    render: (rowData: ProspectusFixedData) => (
      <RaptorStatusBox status={specifyStatus(rowData.status)} />
    ),
  },
  {
    title: 'Rules Status',
    field: 'rulesStatus',
    width: 'calc((100% - 165px) / 2)',
    headerStyle: {
      textAlign: 'center',
    },
    cellStyle: {
      textAlign: 'center',
    },
    render: (rowData) => (
      <RenderStatuses ruleStatusValues={rowData.rulesStatus} />
    ),
  },
];

export const timeSeriesFixedColumns: CustomColumn<TimeSeriesFixedData>[] = [
  {
    title: 'NAV Change',
    field: 'scenario',
    width: 5,
    headerStyle: {
      textAlign: 'center',
    },
    cellStyle: {
      textAlign: 'center',
    },
  },
  {
    title: 'NAV',
    field: 'nav',
    width: 160,
    headerStyle: {
      textAlign: 'center',
    },
    cellStyle: {
      textAlign: 'right',
    },
    render: (rowData) => addCommasToNumbersAndRound(rowData.nav),
  },
  {
    title: 'VaR',
    field: 'var',
    width: 'calc((100% - 165px) / 2)',
    render: (rowData) => percentageToTwoDecimalPlacesNoHundred(rowData.var),
    headerStyle: {
      textAlign: 'center',
    },
    cellStyle: {
      textAlign: 'center',
    },
  },
  {
    title: (
      <Tooltip
        title={
          <Typography variant="h3" style={{ color: 'white' }}>
            This is the estimated corrleation between the ex-ante portfolio and
            the ex-post, scenario impacted portfolio. As a normalised metric it
            only reflects changes in the structure of the portfolio and not the
            sizes of the underlying exposures.
          </Typography>
        }
      >
        <span>Correlation</span>
      </Tooltip>
    ),
    field: 'correlation',
    width: 'calc((100% - 165px) / 2)',
    render: (rowData) => percentageToTwoDecimalPlaces(rowData.correlation),
    headerStyle: {
      textAlign: 'center',
    },
    cellStyle: {
      textAlign: 'center',
    },
    // tooltip: 'test'
  },
  {
    title: (
      <Tooltip
        title={
          <Typography variant="h3" style={{ color: 'white' }}>
            This is the estimated Sum of Squared Difference between the ex-ante
            portfolio and the ex-post, scenario impacted portfolio. As a
            un-normalised metric it reflects changes in both the structure of
            the portfolio and the sizes of the underlying exposures.
          </Typography>
        }
      >
        <span>SSD</span>
      </Tooltip>
    ),
    field: 'ssd',
    headerStyle: {
      textAlign: 'center',
    },
    cellStyle: {
      textAlign: 'center',
    },
    render: (rowData) => percentageToTwoDecimalPlaces(rowData.ssd || '-'),
  },
];

function handleIndividualStatuses(
  overallStatus: Status,
  rules: string[],
  rulesDescriptions: any,
): RuleStatusExtended {
  const mappedStatuses: IndividualRuleStatus[] = rules.map((rule) => {
    return {
      rule: rule[0],
      status: specifyStatus(rule[1]),
      ruleDescription: getRuleDescription(rule[0], rulesDescriptions),
    };
  });

  return {
    overallStatus,
    individualStatuses: mappedStatuses,
  };
}

function getRuleDescription(ruleNumber: string, rulesDescriptions: any[]) {
  // Get the number from the rule string.
  const splitString = ruleNumber.split('_');
  const rule = parseInt(splitString[1]);
  // Return the rule description
  if (rule < rulesDescriptions.length - 1) {
    return rulesDescriptions[rule - 1].rule;
  }
  return '';
}

export function buildUcitsFixedData(
  data: any[],
  complianceChecklist: any,
  redemptionLevel: number | null,
) {
  if (!data || !data.length) return [];
  try {
    const sections = [
      'UCITS Investment Restrictions',
      'Control Rules',
      'Cash & Exposure',
      'Collective Investment Schemes',
      'Leverage',
      'Counterparty',
      'Specific',
    ];
    const compliance_data = complianceChecklist.data[0];

    const arr_of_data: any[] = [];

    let counter = 1;

    sections.forEach((section) => {
      compliance_data[section].forEach((item: any) => {
        arr_of_data.push({
          category: section,
          rule_number: counter,
          rule: item,
        });
        counter++;
      });
    });

    const dataForUse = [...data[0].ucits_list];
    // remove title row
    dataForUse.shift();
    return dataForUse.map((el, index) => {
      return {
        scenario: redemptionLevel
          ? percentageToNdecialPlacesNoHundred(index * redemptionLevel * -1, 0)
          : el[0],
        nav: el[1],
        status: specifyStatus(el[2]),
        rulesStatus:
          (Array.isArray(el[3]) && el[3][0] === 'N/A') ||
          (typeof el[3] === 'string' && el[3] === 'N/A')
            ? // el[3] === "N/A"
              specifyStatus('N/A')
            : handleIndividualStatuses(
                specifyStatus(el[2]),
                el[3],
                arr_of_data,
              ),
      };
    });
  } catch (err) {
    console.error(err);
    return [];
  }
}
export function buildRiaifFixedData(
  data: any[],
  complianceChecklist: any,
  redemptionLevel: number | null,
) {
  if (!data || !data.length) return [];
  try {
    const sections = [
      'RIAIF Investment Restrictions',
      // 'Control Rules',
      'Cash & Exposure',
      'Collective Investment Schemes',
      // 'Leverage',
      'Counterparty',
      // 'Property',
      // 'Specific',
    ];
    const compliance_data = complianceChecklist.data[0];

    const arr_of_data: any[] = [];

    let counter = 1;

    sections.forEach((section) => {
      compliance_data[section].forEach((item: any) => {
        arr_of_data.push({
          category: section,
          rule_number: counter,
          rule: item,
        });
        counter++;
      });
    });

    const dataForUse = [...data[0].riaif_list];
    // remove title row
    dataForUse.shift();
    return dataForUse.map((el, index) => {
      return {
        scenario: redemptionLevel
          ? percentageToNdecialPlacesNoHundred(index * redemptionLevel * -1, 0)
          : el[0],
        nav: el[1],
        riaifStatus: specifyStatus(el[2]),
        rulesStatus:
          (Array.isArray(el[3]) && el[3][0] === 'N/A') ||
          (typeof el[3] === 'string' && el[3] === 'N/A')
            ? // el[3] === "N/A"
              specifyStatus('N/A')
            : handleIndividualStatuses(
                specifyStatus(el[2]),
                el[3],
                arr_of_data,
              ),
      };
    });
  } catch (err) {
    console.error(err);
    return [];
  }
}

export function buildAct40FixedData(
  data: any[],
  complianceChecklist: any,
  redemptionLevel: number | null,
) {
  if (!data || !data.length) return [];
  try {
    const sections = [
      '40 ACT Investment Restrictions',
      // 'Control Rules',
      // 'Cash & Exposure',
      // 'Collective Investment Schemes',
      // 'Leverage',
      // 'Counterparty',
      // 'Property',
      // 'Specific',
    ];
    const compliance_data = complianceChecklist.data[0];

    const arr_of_data: any[] = [];

    let counter = 1;

    sections.forEach((section) => {
      compliance_data[section].forEach((item: any) => {
        arr_of_data.push({
          category: section,
          rule_number: counter,
          rule: item,
        });
        counter++;
      });
    });

    const dataForUse = [...data[0].act40_list];
    // remove title row
    dataForUse.shift();
    return dataForUse.map((el, index) => {
      return {
        scenario: redemptionLevel
          ? percentageToNdecialPlacesNoHundred(index * redemptionLevel * -1, 0)
          : el[0],
        nav: el[1],
        riaifStatus: specifyStatus(el[2]),
        rulesStatus:
          (Array.isArray(el[3]) && el[3][0] === 'N/A') ||
          (typeof el[3] === 'string' && el[3] === 'N/A')
            ? // el[3] === "N/A"
              specifyStatus('N/A')
            : handleIndividualStatuses(
                specifyStatus(el[2]),
                el[3],
                arr_of_data,
              ),
      };
    });
  } catch (err) {
    console.error(err);
    return [];
  }
}

export function buildSifRiskColumns(
  data: any[],
  redemptionLevel: number | null,
): SifRiskSpreadData[] {
  if (!data || !data.length) return [];
  try {
    const dataForUse = [...data[0].sif_list];
    // remove title row
    dataForUse.shift();
    return dataForUse.map((el, index) => {
      return {
        scenario: redemptionLevel
          ? percentageToNdecialPlacesNoHundred(index * redemptionLevel * -1, 0)
          : el[0],
        nav: el[1],
        riskSpreadStatus: specifyStatus(el[2]),
        asset: el[3],
      };
    });
  } catch (err) {
    console.error(err);
    return [];
  }
}

export function buildProspectusFixedData(
  data: any[],
  redemptionLevel: number | null,
) {
  if (!data || !data.length) return [];

  try {
    const dataForUse = [...data[0].risk_list];
    // remove title row
    dataForUse.shift();

    return dataForUse.map((el, index) => {
      return {
        scenario: redemptionLevel
          ? percentageToNdecialPlacesNoHundred(index * redemptionLevel * -1, 0)
          : el[0],
        nav: el[1],
        status: specifyStatus(el[2]),
        rulesStatus:
          el[3][0] === 'N/A'
            ? specifyStatus(el[3][0])
            : handleIndividualStatuses(specifyStatus(el[2]), el[3], []),
      };
    });
  } catch (err) {
    console.error(err);
    return [];
  }
}

export function buildTimeSeriesFixedData(
  data: any[],
  redemptionLevel: number | null,
) {
  if (!data || !data.length) return [];
  try {
    const dataForUse = [...data[0].dynamic_list];
    dataForUse.shift();
    return dataForUse.map((el, index) => {
      return {
        scenario: redemptionLevel
          ? percentageToNdecialPlacesNoHundred(index * redemptionLevel * -1, 0)
          : el[0],
        nav: el[1],
        var: el[2],
        correlation: el[3],
        ssd: el[4],
      };
    });
  } catch (err) {
    console.error(err);
    return [];
  }
}
