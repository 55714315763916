import React, { Dispatch, FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { turnOffAllFilters } from '../../../../../../redux/kids/actions';
import {
  toggleControlBarShowing,
  updateControlBarComponents,
} from '../../../../../../redux/ui/controlbar/actions';
import BackToOverviewButton from '../Buttons/BackToOverviewButton.component';
import FilterEditorColumnButton from '../Buttons/FilterEditorColumnButton.component';
import KiidsBarChartEditorTile from './BarChartEditorTile.component';
import PageBreakTile from './PageBreakTile.component';
import KiidsSrriChartEditor from './SrriChartEditorTile.component';
import KiidsTableEditorTile from './TableEditorTile.component';
import KiidsTextEditorTile from './TextEditorTile.component';
import { isEqual } from 'lodash';
import TemplateEditorTile from './TemplateEditorTile.component';
import BenchmarkTableEditorTile from './BenchmarkTableEditorTile.component';
import OCFTableEditorTile from './OCFTableEditorTile.component';

interface KiidsViewerColumnProps {
  data: any[];
  setKiidsContent: Dispatch<any>;
  kiidsContent: any[];
  setChangesMade: Dispatch<any>;
  setTemplateUpdates: Dispatch<any>;
  setSelectedKiid: Dispatch<any>;
  templateUpdates: any;
  selectedKiid: any;
}

interface KiidsViewerData {
  tileType: string;
  data: string;
  formatOptions: string;
  commentId: string | null;
  hasUnresolvedComments: boolean;
}

function buildKiidsViewerColumnData(data: any[]): KiidsViewerData[] {
  if (!data) return [];
  if (!data.length) return [];

  const kiidsData = data;
  const columnData: KiidsViewerData[] = [];
  kiidsData.forEach((value: any) => {
    columnData.push({
      tileType: value.tag,
      data: value.content,
      formatOptions: value.format_options,
      commentId: value.comment_id,
      hasUnresolvedComments: value.has_unresolved_comment,
    });
  });
  return columnData;
}

const KiidsEditorColumn: FC<KiidsViewerColumnProps> = (props) => {
  const kiidsData = buildKiidsViewerColumnData(props.data);
  const [filter, setFilter] = useState<string[]>([]);
  const [editorTileComponents, setEditorTileComponents] = useState<any>([]);
  const [wasFieldTypeChanged, setWasFieldTypeChanged] = useState<boolean>(true);
  const [wasNewFieldAdded, setWasNewFieldAdded] = useState<boolean>(false);
  const fieldTypes: string[] = [];
  kiidsData.forEach((element: any) => {
    fieldTypes.push(element.tileType);
  });
  const [fieldTypeCheck, setFieldTypeCheck] = useState<string[]>(fieldTypes);
  // Create a function for editing the filters.
  const updateFilter = (value: string) => {
    const filterValues = filter;
    // Check if the requested filter is already in the list.
    if (filterValues.includes(value)) {
      // In this case remove the filter from the list.
      const updatedFilterList = filterValues.filter(
        (value: string) => value !== value,
      );
      // set the new filter list.
      // setFilter(filter.filter((value: string) => value !== filterUpdate))
      setFilter(updatedFilterList);
    } else {
      // Otherwise append the new filter to the list.
      filterValues.push(value);
      setFilter(filterValues);
    }
  };
  const backToOverview = () => {
    props.setSelectedKiid({
      fundId: null,
      isin: null,
      lastEditedDate: null,
      fundName: null,
      version: null,
      documentLanguage: null,
    });
    props.setKiidsContent(null);
    props.setTemplateUpdates([]);
    dispatch(toggleControlBarShowing(false));
  };

  // Add elements to the control bar.
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      updateControlBarComponents([
        <BackToOverviewButton
          backToOverview={backToOverview}
          dontSetPosition
          key="back_to_overview"
        />,
        <FilterEditorColumnButton
          filterFunction={updateFilter}
          key="editor_column_filter"
          filter={filter}
        />,
      ]),
    );
    dispatch(toggleControlBarShowing(true));
    dispatch(turnOffAllFilters());
  }, []);
  useEffect(() => {
    const updatedFieldTypes: string[] = [];
    kiidsData.forEach((element: any) => {
      updatedFieldTypes.push(element.tileType);
      updatedFieldTypes.push('dummy'); // this isn't ideal but was needed in order to force render of the data
    });
    if (!isEqual(fieldTypeCheck, updatedFieldTypes)) {
      const components = kiidsData.map((value: any, index) => {
        if (!value.tileType) return null;
        if (
          (value.tileType.includes('text') ||
            value.tileType === 'section_header') &&
          value.data.startsWith('{{') &&
          value.data.endsWith('}}')
        ) {
          return (
            <>
              <TemplateEditorTile
                tileType={value.tileType}
                fundId={props.selectedKiid.fundId}
                text={value.data}
                key={`editor_tile_${index}`}
                index={index}
                setKiidsContent={props.setKiidsContent}
                kiidsContent={props.kiidsContent}
                setChangesMade={props.setChangesMade}
                setTemplateUpdates={props.setTemplateUpdates}
                templateUpdates={props.templateUpdates}
                shareClass={props.selectedKiid.isin}
                commentId={value.commentId}
                hasUnresolvedComments={value.hasUnresolvedComments}
                // setWasFieldTypeChanged={setWasFieldTypeChanged}
                // setWasNewFieldAdded={setWasNewFieldAdded}
                // setEditorTileComponents={setEditorTileComponents}
                // editorTileComponents={editorTileComponents}
              />
              {/* <KiidsTextEditorTile
                tileType={value.tileType}
                fundId={props.selectedKiid.fundId}
                text={value.data}
                key={`editor_tile_${index}`}
                index={index}
                setKiidsContent={props.setKiidsContent}
                kiidsContent={props.kiidsContent}
                setChangesMade={props.setChangesMade}
                setTemplateUpdates={props.setTemplateUpdates}
                templateUpdates={props.templateUpdates}
                shareClass={props.selectedKiid.isin}
                commentId={value.commentId}
                hasUnresolvedComments={value.hasUnresolvedComments}
                setWasFieldTypeChanged={setWasFieldTypeChanged}
                setWasNewFieldAdded={setWasNewFieldAdded}
                setEditorTileComponents={setEditorTileComponents}
                editorTileComponents={editorTileComponents}
              /> */}
              {/* <AddFieldButton
                index={index}
                setKiidsContent={props.setKiidsContent}
                kiidsContent={props.kiidsContent}
                setChangesMade={props.setChangesMade}
                setWasFieldTypeChanged={setWasFieldTypeChanged}
                setWasNewFieldAdded={setWasNewFieldAdded}
                setEditorTileComponents={setEditorTileComponents}
                editorTileComponents={editorTileComponents}
              /> */}
            </>
          );
        } else if (value.tileType.includes('text')) {
          return (
            <>
              <KiidsTextEditorTile
                tileType={value.tileType}
                fundId={props.selectedKiid.fundId}
                text={value.data}
                key={`editor_tile_${index}`}
                index={index}
                setKiidsContent={props.setKiidsContent}
                kiidsContent={props.kiidsContent}
                setChangesMade={props.setChangesMade}
                shareClass={props.selectedKiid.isin}
                commentId={value.commentId}
                hasUnresolvedComments={value.hasUnresolvedComments}
                setTemplateUpdates={props.setTemplateUpdates}
                templateUpdates={props.templateUpdates}
                setWasFieldTypeChanged={setWasFieldTypeChanged}
                setWasNewFieldAdded={setWasNewFieldAdded}
                setEditorTileComponents={setEditorTileComponents}
                editorTileComponents={editorTileComponents}
              />
              {/* <AddFieldButton
                index={index}
                setKiidsContent={props.setKiidsContent}
                kiidsContent={props.kiidsContent}
                setChangesMade={props.setChangesMade}
                setWasFieldTypeChanged={setWasFieldTypeChanged}
                setWasNewFieldAdded={setWasNewFieldAdded}
                setEditorTileComponents={setEditorTileComponents}
                editorTileComponents={editorTileComponents}
              /> */}
            </>
          );
          // } else if (value.tileType.includes('table')) {
        } else if (value.tileType === 'table_style4') {
          return (
            <>
              <KiidsTableEditorTile
                tileType={value.tileType}
                fundId={value.fundId}
                content={value.data}
                key={`editor_tile_${index}`}
                index={index}
                setKiidsContent={props.setKiidsContent}
                kiidsContent={props.kiidsContent}
                setChangesMade={props.setChangesMade}
                shareClass={props.selectedKiid.isin}
                commentId={value.commentId}
                hasUnresolvedComments={value.hasUnresolvedComments}
                setWasFieldTypeChanged={setWasFieldTypeChanged}
                setEditorTileComponents={setEditorTileComponents}
                editorTileComponents={editorTileComponents}
                setWasNewFieldAdded={setWasNewFieldAdded}
              />
              {/* <AddFieldButton
                index={index}
                setKiidsContent={props.setKiidsContent}
                kiidsContent={props.kiidsContent}
                setChangesMade={props.setChangesMade}
                setWasFieldTypeChanged={setWasFieldTypeChanged}
                setWasNewFieldAdded={setWasNewFieldAdded}
                setEditorTileComponents={setEditorTileComponents}
                editorTileComponents={editorTileComponents}
              /> */}
            </>
          );
        } else if (value.tileType === 'table_style5') {
          return (
            <>
              <KiidsTableEditorTile
                tileType={value.tileType}
                fundId={value.fundId}
                content={value.data}
                key={`editor_tile_${index}`}
                index={index}
                setKiidsContent={props.setKiidsContent}
                kiidsContent={props.kiidsContent}
                setChangesMade={props.setChangesMade}
                shareClass={props.selectedKiid.isin}
                commentId={value.commentId}
                hasUnresolvedComments={value.hasUnresolvedComments}
                setWasFieldTypeChanged={setWasFieldTypeChanged}
                setEditorTileComponents={setEditorTileComponents}
                editorTileComponents={editorTileComponents}
                setWasNewFieldAdded={setWasNewFieldAdded}
              />
              {/* <AddFieldButton
                index={index}
                setKiidsContent={props.setKiidsContent}
                kiidsContent={props.kiidsContent}
                setChangesMade={props.setChangesMade}
                setWasFieldTypeChanged={setWasFieldTypeChanged}
                setWasNewFieldAdded={setWasNewFieldAdded}
                setEditorTileComponents={setEditorTileComponents}
                editorTileComponents={editorTileComponents}
              /> */}
            </>
          );
        } else if (value.tileType === 'table_style6') {
          return (
            <>
              <KiidsTableEditorTile
                tileType={value.tileType}
                fundId={value.fundId}
                content={value.data}
                key={`editor_tile_${index}`}
                index={index}
                setKiidsContent={props.setKiidsContent}
                kiidsContent={props.kiidsContent}
                setChangesMade={props.setChangesMade}
                shareClass={props.selectedKiid.isin}
                commentId={value.commentId}
                hasUnresolvedComments={value.hasUnresolvedComments}
                setWasFieldTypeChanged={setWasFieldTypeChanged}
                setEditorTileComponents={setEditorTileComponents}
                editorTileComponents={editorTileComponents}
                setWasNewFieldAdded={setWasNewFieldAdded}
              />
              {/* <AddFieldButton
                index={index}
                setKiidsContent={props.setKiidsContent}
                kiidsContent={props.kiidsContent}
                setChangesMade={props.setChangesMade}
                setWasFieldTypeChanged={setWasFieldTypeChanged}
                setWasNewFieldAdded={setWasNewFieldAdded}
                setEditorTileComponents={setEditorTileComponents}
                editorTileComponents={editorTileComponents}
              /> */}
            </>
          );
        } else if (
          value.tileType.includes('table_style3') ||
          value.tileType.includes('table_style1')
        ) {
          return (
            <>
              <BenchmarkTableEditorTile
                tileType={value.tileType}
                fundId={value.fundId}
                content={value.data}
                key={`editor_tile_${index}`}
                index={index}
                setKiidsContent={props.setKiidsContent}
                kiidsContent={props.kiidsContent}
                setChangesMade={props.setChangesMade}
                shareClass={props.selectedKiid.isin}
                commentId={value.commentId}
                hasUnresolvedComments={value.hasUnresolvedComments}
                setWasFieldTypeChanged={setWasFieldTypeChanged}
                setEditorTileComponents={setEditorTileComponents}
                editorTileComponents={editorTileComponents}
                setWasNewFieldAdded={setWasNewFieldAdded}
              />
              {/* <AddFieldButton
                index={index}
                setKiidsContent={props.setKiidsContent}
                kiidsContent={props.kiidsContent}
                setChangesMade={props.setChangesMade}
                setWasFieldTypeChanged={setWasFieldTypeChanged}
                setWasNewFieldAdded={setWasNewFieldAdded}
                setEditorTileComponents={setEditorTileComponents}
                editorTileComponents={editorTileComponents}
              /> */}
            </>
          );
        } else if (value.tileType.includes('table_style2')) {
          return (
            <>
              <OCFTableEditorTile
                tileType={value.tileType}
                fundId={value.fundId}
                content={value.data}
                key={`editor_tile_${index}`}
                index={index}
                setKiidsContent={props.setKiidsContent}
                kiidsContent={props.kiidsContent}
                setChangesMade={props.setChangesMade}
                shareClass={props.selectedKiid.isin}
                commentId={value.commentId}
                hasUnresolvedComments={value.hasUnresolvedComments}
                setWasFieldTypeChanged={setWasFieldTypeChanged}
                setEditorTileComponents={setEditorTileComponents}
                editorTileComponents={editorTileComponents}
                setWasNewFieldAdded={setWasNewFieldAdded}
              />
              {/* <AddFieldButton
                index={index}
                setKiidsContent={props.setKiidsContent}
                kiidsContent={props.kiidsContent}
                setChangesMade={props.setChangesMade}
                setWasFieldTypeChanged={setWasFieldTypeChanged}
                setWasNewFieldAdded={setWasNewFieldAdded}
                setEditorTileComponents={setEditorTileComponents}
                editorTileComponents={editorTileComponents}
              /> */}
            </>
          );
        } else if (value.tileType.includes('table')) {
          return (
            <>
              <KiidsTableEditorTile
                tileType={value.tileType}
                fundId={value.fundId}
                content={value.data}
                key={`editor_tile_${index}`}
                index={index}
                setKiidsContent={props.setKiidsContent}
                kiidsContent={props.kiidsContent}
                setChangesMade={props.setChangesMade}
                shareClass={props.selectedKiid.isin}
                commentId={value.commentId}
                hasUnresolvedComments={value.hasUnresolvedComments}
                setWasFieldTypeChanged={setWasFieldTypeChanged}
                setEditorTileComponents={setEditorTileComponents}
                editorTileComponents={editorTileComponents}
                setWasNewFieldAdded={setWasNewFieldAdded}
              />
              {/* <AddFieldButton
                index={index}
                setKiidsContent={props.setKiidsContent}
                kiidsContent={props.kiidsContent}
                setChangesMade={props.setChangesMade}
                setWasFieldTypeChanged={setWasFieldTypeChanged}
                setWasNewFieldAdded={setWasNewFieldAdded}
                setEditorTileComponents={setEditorTileComponents}
                editorTileComponents={editorTileComponents}
              /> */}
            </>
          );
        } else if (value.tileType === 'srri_chart') {
          return (
            <>
              <KiidsSrriChartEditor
                tileType={value.tileType}
                fundId={value.fundId}
                content={value.data}
                key={`editor_tile_${index}`}
                index={index}
                setKiidsContent={props.setKiidsContent}
                kiidsContent={props.kiidsContent}
                shareClass={props.selectedKiid.isin}
                commentId={value.commentId}
                hasUnresolvedComments={value.hasUnresolvedComments}
                setChangesMade={props.setChangesMade}
                setWasFieldTypeChanged={setWasFieldTypeChanged}
                setEditorTileComponents={setEditorTileComponents}
                editorTileComponents={editorTileComponents}
                setWasNewFieldAdded={setWasNewFieldAdded}
              />
              {/* <AddFieldButton
                index={index}
                setKiidsContent={props.setKiidsContent}
                kiidsContent={props.kiidsContent}
                setChangesMade={props.setChangesMade}
                setWasFieldTypeChanged={setWasFieldTypeChanged}
                setWasNewFieldAdded={setWasNewFieldAdded}
                setEditorTileComponents={setEditorTileComponents}
                editorTileComponents={editorTileComponents}
              /> */}
            </>
          );
        } else if (value.tileType.includes('chart')) {
          return (
            <>
              <KiidsBarChartEditorTile
                fundId={value.fundId}
                content={value.data}
                key={`editor_tile_${index}`}
                index={index}
                setKiidsContent={props.setKiidsContent}
                kiidsContent={props.kiidsContent}
                setChangesMade={props.setChangesMade}
                // setEditorTileComponents={setEditorTileComponents}
                // editorTileComponents={editorTileComponents}
              />
              {/* <AddFieldButton
                index={index}
                setKiidsContent={props.setKiidsContent}
                kiidsContent={props.kiidsContent}
                setChangesMade={props.setChangesMade}
                setWasFieldTypeChanged={setWasFieldTypeChanged}
                setWasNewFieldAdded={setWasNewFieldAdded}
                setEditorTileComponents={setEditorTileComponents}
                editorTileComponents={editorTileComponents}

              /> */}
            </>
          );
        } else if (value.tileType.includes('page_break')) {
          return (
            <>
              <PageBreakTile
                tileType={value.tileType}
                fundId={value.fundId}
                key={`editor_tile_${index}`}
                index={index}
                setKiidsContent={props.setKiidsContent}
                kiidsContent={props.kiidsContent}
                setChangesMade={props.setChangesMade}
                shareClass={props.selectedKiid.isin}
                commentId={value.commentId}
                hasUnresolvedComments={value.hasUnresolvedComments}
                setWasFieldTypeChanged={setWasFieldTypeChanged}
                setEditorTileComponents={setEditorTileComponents}
                editorTileComponents={editorTileComponents}
                setWasNewFieldAdded={setWasNewFieldAdded}
              />
              {/* <AddFieldButton
                index={index}
                setKiidsContent={props.setKiidsContent}
                kiidsContent={props.kiidsContent}
                setChangesMade={props.setChangesMade}
                setWasFieldTypeChanged={setWasFieldTypeChanged}
                setWasNewFieldAdded={setWasNewFieldAdded}
                setEditorTileComponents={setEditorTileComponents}
                editorTileComponents={editorTileComponents}
              /> */}
            </>
          );
        } else {
          return (
            <>
              <KiidsTextEditorTile
                tileType={value.tileType}
                fundId={props.selectedKiid.fundId}
                text={value.data}
                key={`editor_tile_${index}`}
                index={index}
                setKiidsContent={props.setKiidsContent}
                kiidsContent={props.kiidsContent}
                setChangesMade={props.setChangesMade}
                shareClass={props.selectedKiid.isin}
                commentId={value.commentId}
                hasUnresolvedComments={value.hasUnresolvedComments}
                setTemplateUpdates={props.setTemplateUpdates}
                templateUpdates={props.templateUpdates}
                setWasFieldTypeChanged={setWasFieldTypeChanged}
                setWasNewFieldAdded={setWasNewFieldAdded}
                setEditorTileComponents={setEditorTileComponents}
                editorTileComponents={editorTileComponents}
              />
              {/* <AddFieldButton
                index={index}
                setKiidsContent={props.setKiidsContent}
                kiidsContent={props.kiidsContent}
                setChangesMade={props.setChangesMade}
                setWasFieldTypeChanged={setWasFieldTypeChanged}
                setWasNewFieldAdded={setWasNewFieldAdded}
                setEditorTileComponents={setEditorTileComponents}
                editorTileComponents={editorTileComponents}
              /> */}
            </>
          );
        }
      });
      // Add an AddFieldButton to the very start.
      // Index of -1 will indicate that it must be before the first field.
      // components.unshift(<AddFieldButton index={-1} setKiidsContent={props.setKiidsContent} kiidsContent={props.kiidsContent} setChangesMade={props.setChangesMade} setWasFieldTypeChanged={setWasFieldTypeChanged} setWasNewFieldAdded={setWasNewFieldAdded} setEditorTileComponents={setEditorTileComponents} editorTileComponents={editorTileComponents} />)
      if (wasNewFieldAdded) {
        /*
        This is a big hack, it is the only way I can find of forcing the column to re-render correctly once a new field is added. - DC 16/12/22.
        Bascially we add an additional empty tag (<></>) to the beginning of the components list. Then setEditorTileComponents is called,
        after which we immediately remove this button again. setEditorTileComponents is then called again below and this is sufficient for React to pick up
        the changes and force a correct re-render.
        */
        components.unshift(<></>);
        setEditorTileComponents(components);
        setWasNewFieldAdded(false);
        // editorTileComponents.shift();
      } else {
        setEditorTileComponents(components);
      }
      setFieldTypeCheck(updatedFieldTypes);
      if (components.length) {
        setWasFieldTypeChanged(false);
      }
    }
  }, [kiidsData, wasFieldTypeChanged]);

  return (
    <>
      {editorTileComponents.map((component: any) => {
        return component;
      })}
      {/* <EditorColumnContainer contents={editorTileComponents} /> */}
    </>
  );
};

export default KiidsEditorColumn;
