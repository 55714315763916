import makeStyles from '@mui/styles/makeStyles';
import { FC } from 'react';
import { useWindowSize } from 'react-use';
import { Cell, Pie, PieChart, ResponsiveContainer } from 'recharts';
import { RaptorTheme, greys, mainColors } from '../../../../styling/theme';
import { CustomColumn } from '../../../../types/components/tables/tableTypes';
import {
  addCommasToNumbersAndRound,
  percentageToTwoDecimalPlaces,
  roundNumberToNDecimalPlaces,
  roundNumberToTwoDecimalPlaces,
} from '../../../../utilities/numberFormatters';
import GridItem from '../../../layout/GridComponents/GridItem';
import CustomTable from '../../../tables/CustomTable';
import { Typography } from '@mui/material';

interface ParisAlignedPanelProps {
  data: any;
  fundId: string;
}

const useArticelOverviewPanelStyles = makeStyles<RaptorTheme>((theme) => ({
  componentsContainer: {
    // display: 'flex',
    // justifyContent: 'space-between',
    padding: 8,
  },
  chartContainer: {
    // width: '60%',
    // alignContent: 'left',
    marginTop: '2rem',
    // display: 'flex',
    // justifyContent: 'space-between',
  },
  tableContainer: {
    width: '40%',
    justifyContent: 'center',
  },
  basicInfoContainer: {
    // width: '40%',
    justifyContent: 'center',
  },
  fundInfoLine: {
    display: 'flex',
  },
  individualChart: {
    marginBottom: '1rem',
    height: '20rem',
    width: '20rem',
    justifyContent: 'center',
  },
}));

interface CoverageData {
  coverage: string;
  exposurePositions: string;
}

function buildCoverageData(data: any): CoverageData {
  const coverage: CoverageData = {
    coverage: '',
    exposurePositions: '',
  };
  if (!data.data.length) return coverage;
  if (!('sfdr_page' in data.data[0])) return coverage;
  const currentData = data.data[0].sfdr_page.overview_information.coverage;
  coverage.coverage = currentData.number_positions;
  coverage.exposurePositions = `${
    Number(currentData.exposure_positions.slice(0, -1)) * 100
  }%`;
  return coverage;
}

interface pieChartData {
  name: string;
  value: number;
}

function buildPieChartData(data: any): pieChartData[] {
  if (!data.data.length) return [];
  if (!('sfdr_page' in data.data[0])) return [];
  const currentData = data.data[0].sfdr_page.overview_information.coverage;

  // For Now use regex to get the coverage data
  const coverageData = currentData.number_positions.match(/\d+/g);
  if (!coverageData.length) return [];
  const chartData: pieChartData[] = [
    {
      name: 'Covered Positions',
      value: parseInt(coverageData[0]),
    },
    {
      name: 'Uncovered Positions',
      value: parseInt(coverageData[1]) - parseInt(coverageData[0]),
    },
  ];
  return chartData;
}

interface ConcerningPositionsData {
  isin: string;
  asset: string;
  exposure: number;
  grossExposure: number;
  grossExposurePc: number;
  marketPrice: number;
  environmental: number;
  esg: number;
  currency: string;
  totalCarbon: number;
  scope1: number;
  scope2: string;
}

const detailColumns: CustomColumn<ConcerningPositionsData>[] = [
  {
    title: 'Asset',
    field: 'asset',
    width: '25rem',
    cellStyle: { textAlign: 'center' },
    headerStyle: { textAlign: 'center' },
  },
  {
    title: 'Isin',
    field: 'isin',
    cellStyle: { textAlign: 'center' },
    headerStyle: { textAlign: 'center' },
    // render: (rowData: ConcerningPositionsData) => percentageToTwoDecimalPlaces(rowData.unalignedGrossExposure)
  },
  {
    title: 'Scope 1',
    field: 'scope1',
    cellStyle: { textAlign: 'center' },
    headerStyle: { textAlign: 'center' },
    render: (rowData: ConcerningPositionsData) =>
      `${roundNumberToTwoDecimalPlaces(rowData.scope1)}`,
  },
  {
    title: 'Scope 2',
    field: 'scope2',
    cellStyle: { textAlign: 'center' },
    headerStyle: { textAlign: 'center' },
    render: (rowData: ConcerningPositionsData) => rowData.scope2,
  },
  {
    title: 'Total Carbon',
    field: 'totalCarbon',
    cellStyle: { textAlign: 'center' },
    headerStyle: { textAlign: 'center' },
    render: (rowData: ConcerningPositionsData) =>
      `${roundNumberToTwoDecimalPlaces(rowData.totalCarbon)} tCO2`,
  },
  {
    title: 'Exposure',
    field: 'exposure',
    cellStyle: { textAlign: 'center' },
    headerStyle: { textAlign: 'center' },
    render: (rowData: ConcerningPositionsData) =>
      addCommasToNumbersAndRound(rowData.exposure),
  },
  {
    title: 'Gross Exposure',
    field: 'grossExposure',
    cellStyle: { textAlign: 'center' },
    headerStyle: { textAlign: 'center' },
    render: (rowData: ConcerningPositionsData) =>
      addCommasToNumbersAndRound(rowData.grossExposure),
  },
  {
    title: 'Gross Exposure',
    field: 'grossExposurePc',
    cellStyle: { textAlign: 'center' },
    headerStyle: { textAlign: 'center' },
    render: (rowData: ConcerningPositionsData) =>
      percentageToTwoDecimalPlaces(rowData.grossExposurePc),
  },
  {
    title: 'Market Price',
    field: 'marketPrice',
    cellStyle: { textAlign: 'center' },
    headerStyle: { textAlign: 'center' },
    render: (rowData: ConcerningPositionsData) =>
      roundNumberToNDecimalPlaces(rowData.marketPrice, 4),
    width: '8%',
  },
  {
    title: 'Currency',
    field: 'currency',
    cellStyle: { textAlign: 'center' },
    headerStyle: { textAlign: 'center' },
    width: '8%',
  },
  {
    title: 'Environmental',
    field: 'environmental',
    cellStyle: { textAlign: 'center' },
    headerStyle: { textAlign: 'center' },
    render: (rowData: ConcerningPositionsData) =>
      roundNumberToTwoDecimalPlaces(rowData.environmental),
    width: '8%',
  },
  {
    title: 'ESG',
    field: 'esg',
    cellStyle: { textAlign: 'center' },
    headerStyle: { textAlign: 'center' },
    render: (rowData: ConcerningPositionsData) =>
      roundNumberToTwoDecimalPlaces(rowData.esg),
    width: '8%',
  },
];

function buildTableData(data: any): ConcerningPositionsData[] {
  if (!data.data.length) return [];
  if (!('raptor_carbon_page' in data.data[0])) return [];
  const concernedData = data.data[0].raptor_carbon_page.contributers;
  const positions = data.data[0].positions;
  const tableData: ConcerningPositionsData[] = [];

  const nav = data.data[0].nav;

  // Get the esg version.
  const version = data.data[0].version;

  // Case for verison 2.
  if (version === 3) {
    // case for version 3.
    concernedData.forEach((position: any) => {
      const positionData = positions.filter((obj: any) => {
        return obj.position_id === position.position_id;
      });

      let scope2 = '--';
      let totalCarbon = 0;
      if (typeof positionData[0]['M-EE21-5'] == 'number') {
        totalCarbon += positionData[0]['M-EE21-5'];
      }
      if (
        typeof positionData[0]['M-EE21-6'] === 'number' &&
        typeof positionData[0]['M-EE21-7'] === 'number'
      ) {
        const scope2Number = Math.min(
          positionData[0]['M-EE21-6'],
          positionData[0]['M-EE21-7'],
        );
        scope2 = roundNumberToTwoDecimalPlaces(scope2Number);
        totalCarbon += scope2Number;
      }

      tableData.push({
        isin: positionData[0].isin,
        asset: positionData[0].gm_name_long,
        exposure: positionData[0].exposure * nav,
        grossExposure: positionData[0].gross_exposure * nav,
        grossExposurePc: positionData[0].gross_exposure,
        marketPrice: positionData[0].client_price,
        environmental: positionData[0].E_Score,
        esg: positionData[0].ESG_Score,
        currency: positionData[0].base_currency,
        totalCarbon: totalCarbon,
        scope1: positionData[0]['M-EE21-5'],
        scope2: scope2,
      });
    });
  } else {
    concernedData.forEach((value: any) => {
      tableData.push({
        isin: value.isin,
        asset: value.g_name,
        exposure: value.exposure * nav,
        grossExposure: value.gross_exposure * nav,
        grossExposurePc: value.gross_exposure,
        marketPrice: value.market_price,
        environmental: 'e' in value.esg_scores ? value.esg_scores.e : 0,
        esg: 'esg' in value.esg_scores ? value.esg_scores.esg : 0,
        currency: value.base_currency,
        totalCarbon: value.total_carbon,
        scope1: 0,
        scope2: '0',
      });
    });
  }

  return tableData;
}

interface ParisAlignedData {
  concernedExposure: number;
  concernedExposureValue: number;
  concernedPercentage: number;
}

function buildParisAlignedData(data: any): ParisAlignedData {
  const parisData = {
    concernedExposure: 0,
    concernedExposureValue: 0,
    concernedPercentage: 0,
  };
  if (!data.data.length) return parisData;
  if (!('zero_emissions' in data.data[0])) return parisData;
  const parisAlignment = data.data[0].zero_emissions[0];
  parisData.concernedExposure = parisAlignment.concerning_exposure;
  parisData.concernedExposureValue = parisAlignment.concerning_exposure_value;
  parisData.concernedPercentage = parisAlignment.concerning_percentage;
  return parisData;
}

const ParisAlignedPanel: FC<ParisAlignedPanelProps> = (props) => {
  const { data, fundId } = props;
  const classes = useArticelOverviewPanelStyles();
  const { height } = useWindowSize();
  const coverageData = buildCoverageData(data);
  const chartData = buildPieChartData(data);
  const tableData = buildTableData(data);
  const parisAlignedData = buildParisAlignedData(data);
  return (
    <>
      <GridItem
        card
        xs={4}
        cardStyle={{ height: '100%', minHeight: height * 0.62 }}
      >
        <div className={classes.componentsContainer}>
          <div className={classes.basicInfoContainer}>
            <Typography variant="h2" align="left">
              Zero Emission Exposure - Paris Aligned
            </Typography>
            <div className={classes.fundInfoLine}>
              <Typography variant="h3">NAV: </Typography>
              <Typography
                variant="h3"
                style={{ color: greys.grey600, marginLeft: '1rem' }}
              >
                {addCommasToNumbersAndRound(data.data[0]['nav'])}
              </Typography>
            </div>
            <div className={classes.fundInfoLine}>
              <Typography variant="h3">Concerning Exposure: </Typography>
              <Typography
                variant="h3"
                style={{ color: greys.grey600, marginLeft: '1rem' }}
              >
                {percentageToTwoDecimalPlaces(
                  parisAlignedData.concernedExposure,
                )}
              </Typography>
            </div>
            <div className={classes.fundInfoLine}>
              <Typography variant="h3">Concerned Exposure Value: </Typography>
              <Typography
                variant="h3"
                style={{ color: greys.grey600, marginLeft: '1rem' }}
              >
                {addCommasToNumbersAndRound(
                  parisAlignedData.concernedExposureValue,
                )}
              </Typography>
            </div>
            <div className={classes.fundInfoLine}>
              <Typography variant="h3">
                Concerned Percentage of Portfolio:{' '}
              </Typography>
              <Typography
                variant="h3"
                style={{ color: greys.grey600, marginLeft: '1rem' }}
              >
                {percentageToTwoDecimalPlaces(
                  parisAlignedData.concernedPercentage,
                )}
              </Typography>
            </div>
            <Typography variant="h2" align="left" style={{ marginTop: '2rem' }}>
              Coverage
            </Typography>
            <div className={classes.fundInfoLine}>
              <Typography
                variant="h3"
                style={{ color: mainColors.mainBlue }}
              >{`${coverageData.coverage} Positions`}</Typography>
            </div>
            <div className={classes.fundInfoLine}>
              <Typography
                variant="h3"
                style={{ color: mainColors.mainBlue }}
              >{`${coverageData.exposurePositions} of Exposure`}</Typography>
            </div>
          </div>
          <div className={classes.chartContainer}>
            <Typography variant="h2" align="left" style={{ marginLeft: '30%' }}>
              Portfolio Coverage
            </Typography>
            <ResponsiveContainer width={'100%'} height={height / 3}>
              <PieChart>
                <Pie
                  data={chartData}
                  labelLine={false}
                  isAnimationActive={false}
                  paddingAngle={1}
                  legendType="rect"
                  dataKey="value"
                  nameKey="name"
                  label={(dataPoint) => `${dataPoint.name}: ${dataPoint.value}`}
                >
                  <Cell fill={mainColors.mainBlue} />
                  <Cell fill={greys.grey400} />
                </Pie>
              </PieChart>
            </ResponsiveContainer>
          </div>
        </div>
      </GridItem>
      <GridItem
        card
        xs={8}
        cardStyle={{
          height: '100%',
          minHeight: height * 0.65,
          maxHeight: height * 0.65,
        }}
      >
        <CustomTable<ConcerningPositionsData>
          columns={detailColumns}
          showToolbar
          data={tableData}
          title={'Concerning Positions'}
          options={{
            paging: false,
            search: false,
            exportButton: true,
            maxBodyHeight: height * 0.58,
          }}
        />
      </GridItem>
    </>
  );
};

export default ParisAlignedPanel;
