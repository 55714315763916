import { ColumnFields } from '../types/column.types';
import { UltraTablePreset } from '../types/presets.types';

export const allPresetColumns: Record<string, string[]> = {
  alternativeExposure: [
    ColumnFields.INDEX,
    ColumnFields.NAME,
    ColumnFields.ISIN,
    ColumnFields.ASSET_TYPE,
    ColumnFields.CURRENCY,
    ColumnFields.FX,
    ColumnFields.BC_EXPOSURE,
    ColumnFields.BC_EXPOSURE_PCT,
    ColumnFields.GROSS_EXPOSURE,
    ColumnFields.GROSS_EXPOSURE_PCT,
    ColumnFields.COMMITMENT,
    ColumnFields.COMMITMENT_PCT,
  ],
  generalExposure: [
    ColumnFields.INDEX,
    ColumnFields.NAME,
    ColumnFields.ISIN,
    ColumnFields.INSTRUMENT_TITLE,
    ColumnFields.ASSET_TYPE,
    ColumnFields.CURRENCY,
    ColumnFields.FX,
    ColumnFields.BC_EXPOSURE,
    ColumnFields.BC_EXPOSURE_PCT,
    ColumnFields.GROSS_EXPOSURE,
    ColumnFields.GROSS_EXPOSURE_PCT,
    ColumnFields.COMMITMENT,
    ColumnFields.COMMITMENT_PCT,
  ],
  amlExposure: [
    ColumnFields.INDEX,
    ColumnFields.NAME,
    ColumnFields.ISIN,
    ColumnFields.ASSET_TYPE,
    ColumnFields.CURRENCY,
    ColumnFields.FX,
    ColumnFields.LC_EXPOSURE_PCT,
    ColumnFields.BC_EXPOSURE,
    ColumnFields.BC_EXPOSURE_PCT,
    ColumnFields.GROSS_EXPOSURE,
    ColumnFields.GROSS_EXPOSURE_PCT,
    ColumnFields.COUNTRY_CODE,
    ColumnFields.SECTOR_NAME,
    ColumnFields.IS_LISTED,
    ColumnFields.AML_EXPOSURE,
  ],
  countryExposure: [
    ColumnFields.INDEX,
    ColumnFields.NAME,
    ColumnFields.ISIN,
    ColumnFields.ASSET_TYPE,
    ColumnFields.CURRENCY,
    ColumnFields.FX,
    ColumnFields.BC_EXPOSURE,
    ColumnFields.GROSS_EXPOSURE,
    ColumnFields.GROSS_EXPOSURE_PCT,
    ColumnFields.COUNTRY_CODE_RISK_LEVEL,
    ColumnFields.COUNTRY_CODE,
  ],
  cashExposure: [
    ColumnFields.INDEX,
    ColumnFields.NAME,
    ColumnFields.ISIN,
    ColumnFields.ASSET_TYPE,
    ColumnFields.CURRENCY,
    ColumnFields.FX,
    ColumnFields.BC_EXPOSURE,
    ColumnFields.BC_EXPOSURE_PCT,
    ColumnFields.GROSS_EXPOSURE,
    ColumnFields.GROSS_EXPOSURE_PCT,
    ColumnFields.COMMITMENT,
    ColumnFields.COMMITMENT_PCT,
  ],
  commodityExposure: [
    ColumnFields.INDEX,
    ColumnFields.NAME,
    ColumnFields.ISIN,
    ColumnFields.ASSET_TYPE,
    ColumnFields.CURRENCY,
    ColumnFields.FX,
    ColumnFields.BC_EXPOSURE,
    ColumnFields.BC_EXPOSURE_PCT,
    ColumnFields.GROSS_EXPOSURE,
    ColumnFields.GROSS_EXPOSURE_PCT,
    ColumnFields.COMMITMENT,
    ColumnFields.COMMITMENT_PCT,
  ],
  equityExposure: [
    ColumnFields.INDEX,
    ColumnFields.NAME,
    ColumnFields.ISIN,
    ColumnFields.ASSET_TYPE,
    ColumnFields.SECTOR_NAME,
    ColumnFields.YIELD,
    ColumnFields.CURRENCY,
    ColumnFields.COUNTRY_CODE,
    ColumnFields.FX,
    ColumnFields.PRICE,
    ColumnFields.RISK_FACTOR,
    ColumnFields.NOTIONAL_AMOUNT,
    ColumnFields.OUTSTANDING_SECURITIES,
    ColumnFields.GROSS_EXPOSURE,
    ColumnFields.GROSS_EXPOSURE_PCT,
    ColumnFields.COMMITMENT,
    ColumnFields.COMMITMENT_PCT,
  ],
  fixedIncomeExposure: [
    ColumnFields.INDEX,
    ColumnFields.NAME,
    ColumnFields.ISIN,
    ColumnFields.ASSET_TYPE,
    ColumnFields.COUPON,
    ColumnFields.MATURITY_DATE,
    ColumnFields.CURRENCY,
    ColumnFields.COUNTRY_CODE,
    ColumnFields.TIME_TO_MATURITY,
    ColumnFields.YIELD,
    ColumnFields.PRICE,
    ColumnFields.NOTIONAL_AMOUNT,
    ColumnFields.OUTSTANDING_SECURITIES,
    ColumnFields.GROSS_EXPOSURE,
    ColumnFields.GROSS_EXPOSURE_PCT,
    ColumnFields.DURATION,
    ColumnFields.DV01,
    ColumnFields.CREDIT_RATING,
    ColumnFields.IS_CB,
  ],
  foreginExchangeExposure: [
    ColumnFields.INDEX,
    ColumnFields.NAME,
    ColumnFields.ISIN,
    ColumnFields.ASSET_TYPE,
    ColumnFields.CURRENCY,
    ColumnFields.FX,
    ColumnFields.BC_EXPOSURE,
    ColumnFields.BC_EXPOSURE_PCT,
    ColumnFields.GROSS_EXPOSURE,
    ColumnFields.GROSS_EXPOSURE_PCT,
    ColumnFields.COMMITMENT,
    ColumnFields.COMMITMENT_PCT,
  ],
};

export const AMLPreset: UltraTablePreset = {
  id: 'amlExposure',
  name: 'AML',
  columns: allPresetColumns.amlExposure,
};

export const AlternativePreset: UltraTablePreset = {
  id: 'alternative',
  name: 'Alternative',
  columns: allPresetColumns.alternativeExposure,
};

export const CashPreset: UltraTablePreset = {
  id: 'cash',
  name: 'Cash',
  columns: allPresetColumns.cashExposure,
};

export const CommodityPreset: UltraTablePreset = {
  id: 'commodity',
  name: 'Commodity',
  columns: allPresetColumns.commodityExposure,
};

export const CountryPreset: UltraTablePreset = {
  id: 'countryExposure',
  name: 'Country',
  columns: allPresetColumns.countryExposure,
};

export const EquityPreset: UltraTablePreset = {
  id: 'equity',
  name: 'Equity',
  columns: allPresetColumns.equityExposure,
};

export const FixedIncomePreset: UltraTablePreset = {
  id: 'fixed_income',
  name: 'Fixed Income',
  columns: allPresetColumns.fixedIncomeExposure,
};

export const ForeginExchangePreset: UltraTablePreset = {
  id: 'foregin_exchange',
  name: 'Foreign Exchange',
  columns: allPresetColumns.foreginExchangeExposure,
};

export const GeneralPreset: UltraTablePreset = {
  id: 'generalExposure',
  name: 'General',
  columns: allPresetColumns.generalExposure,
};

export const ALL_EXPOSURE_PRESETS = [
  AMLPreset,
  AlternativePreset,
  CashPreset,
  CommodityPreset,
  CountryPreset,
  EquityPreset,
  FixedIncomePreset,
  ForeginExchangePreset,
  GeneralPreset,
];
