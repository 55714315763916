import React, { CSSProperties, ReactElement, useEffect } from 'react';
import {
  Bar,
  BarChart,
  BarProps,
  CartesianGrid,
  LabelProps,
  Legend,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  XAxisProps,
  YAxis,
  YAxisProps,
} from 'recharts';
import ExportButton from '../feedback/ExportButton';
import { useDispatch } from 'react-redux';
import { addComponentToPdfExport } from '../../redux/pdfExport/actions';
import { PdfComponentType } from '../../types/redux/pdfExports/pdfExportsStore';
import usePngFromRecharts from '../../hooks/usePngFromRecharts';
import { AdaptedYAxis } from '../../types/components/charts/lineCharts';
import { Typography, useTheme } from '@mui/material';

export interface BarObject extends BarProps {
  dataKey: string;
  key: string; // it's better to have the key be passed down as a prop
  fill: string;
}
export interface XAxisObject extends XAxisProps {
  label: LabelProps;
}
interface GenericBarChartProps {
  legend?: boolean;
  legendFormatter?: any;
  legendWrapper?: CSSProperties;
  xAxes: XAxisObject[];
  // xAxes: XAxisProps[];
  height: string | number;
  width: string | number;
  id?: string;
  bars: BarObject[];
  data: object[];
  margin?: Partial<any>; // Changing this to optional as default is set
  loading: boolean;
  title: string;
  tooltipFormatter?: any;
  yAxisFormatter?: (val: string) => string;
  error?: number | null | string | Error;
  yAxisLabel?: LabelProps;
  loadingHeight?: number | string;
  minTickGap?: number;
  yAxes?: YAxisProps[];
  referenceLine?: boolean;
  dontVaryBarColor?: boolean;
  exportButton?: boolean;
  legendPositionHorizontal?: 'left' | 'center' | 'right';
  legendPositionVertical?: 'top' | 'middle' | 'bottom';
  legendLayout?: 'horizontal' | 'vertical';
  layout?: 'horizontal' | 'vertical';
  additionalYAxis?: AdaptedYAxis[];
  // Hack to force y axis to show negative values
  yaxisDomainAdjustmentLower?: number;
  // Allow adjustment of gaps between bars (Can be used for Histogram)
  barCategoryGap?: number;
  // Allow the tooltip to be removed
  removeTooltip?: boolean;
}

function GenericBarChart({
  data,
  height,
  width,
  bars,
  xAxes,
  title,
  ...props
}: GenericBarChartProps): ReactElement {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { ref, handleDownload } = usePngFromRecharts();

  useEffect(() => {
    dispatch(
      addComponentToPdfExport({
        identifier: props.id || 'no_id',
        handler: handleDownload,
        type: PdfComponentType.BAR_CHART,
        title,
      }),
    );
  }, [ref]);
  return (
    <div
      style={{
        fontSize: '1.4rem',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        height: '100%',
        width: '100%',
      }}
    >
      <div
        style={{
          position: 'relative',
          display: title || props.exportButton ? 'flex' : 'none',
          justifyContent: 'space-between',
          height: '5.6rem',
          alignItems: 'center',
          width: '100%',
          paddingLeft: '1.6rem',
          paddingRight: '.8rem',
        }}
      >
        <Typography
          variant="h1"
          style={{
            fontSize: '2.4rem',
            width: '100%',
            fontWeight: 300,
            textAlign: props.exportButton ? 'left' : 'center',
          }}
        >
          {title || ''}
        </Typography>
        {props.exportButton && (
          <ExportButton fields={Object.keys(data[0])} exportData={data} />
        )}
      </div>
      {data.length ? (
        <ResponsiveContainer width={width} height={height}>
          <BarChart
            ref={ref}
            data={data}
            layout={props.layout}
            // width={(width as number) * 0.8}
            // height={(height as number) * 0.8}
            margin={
              props.margin || { top: 50, bottom: 50, left: 100, right: 50 }
            }
            barCategoryGap={props.barCategoryGap}
          >
            <CartesianGrid strokeDasharray="3 3" />
            {xAxes.length && (
              <XAxis
                dataKey={xAxes[0].dataKey}
                tick={xAxes[0].tick}
                interval={xAxes[0].interval || 0}
                ticks={xAxes[0].ticks || []}
                domain={xAxes[0].domain}
                label={xAxes[0].label}
                tickFormatter={xAxes[0].tickFormatter}
                tickLine={xAxes[0].tickLine}
              />
            )}
            {props.yAxes ? (
              props.yAxes.map((yProps: YAxisProps) => (
                <YAxis key={yProps.dataKey! as string} {...yProps} />
              ))
            ) : (
              <YAxis
                tickFormatter={props.yAxisFormatter}
                domain={[
                  (dataMin: number) => {
                    return dataMin - Math.abs(dataMin * 0.1);
                  },
                  (dataMax: number) => {
                    return dataMax + dataMax * 0.1;
                  },
                ]}
                tick={{ fontSize: '1.4rem' }}
                label={{ ...props.yAxisLabel, fill: theme.palette.grey[700] }}
                scale="linear"
                allowDataOverflow={false}
              />
            )}
            {props.additionalYAxis ? (
              props.additionalYAxis.map((el: AdaptedYAxis, index: number) => {
                return (
                  <YAxis
                    key={title}
                    scale="linear"
                    domain={[
                      (dataMin: number) => {
                        return dataMin - dataMin * 0.1;
                      },
                      (dataMax: number) => dataMax + dataMax * 0.1,
                    ]}
                    {...el}
                  />
                );
              })
            ) : (
              <></>
            )}
            {props.referenceLine && (
              <ReferenceLine y={0} stroke="red" strokeDasharray="3 3" />
            )}
            {!props.removeTooltip && (
              <Tooltip
                cursor={{ fill: 'rgba(206, 206, 206, 0.2)' }}
                labelStyle={{ fontSize: '1.6rem' }}
                contentStyle={{ fontSize: '1.6rem' }}
                formatter={
                  props.tooltipFormatter
                    ? props.tooltipFormatter
                    : (value: any, name: any, props: any) => value
                }
              />
            )}
            {props.legend && (
              <Legend
                iconSize={16}
                formatter={props.legendFormatter}
                wrapperStyle={
                  props.legendWrapper ? props.legendWrapper : { bottom: 0 }
                }
                layout={props.legendLayout}
                align={props.legendPositionHorizontal}
                verticalAlign={props.legendPositionVertical}
              />
            )}
            {bars.map((barProps: BarObject, index: number) => (
              <Bar {...barProps} ref={undefined} key={barProps.key} />
            ))}
          </BarChart>
        </ResponsiveContainer>
      ) : (
        <Typography variant="subtitle2">No data to display</Typography>
      )}
    </div>
  );
}

export default GenericBarChart;
