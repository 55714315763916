import React, { ReactElement, useEffect, useMemo } from 'react';
import useFetchData from '../../../../../hooks/useFetchData';
import { ILawHistoricalData, buildHistoricalData } from './LawFirstLevelTable';
import GridItem from '../../../../layout/GridComponents/GridItem';
import CustomTable from '../../../../tables/CustomTable';
import {
  getMostRecentWeekday,
  getWeekdayXMonthsAgo,
} from '../../../../../utilities/dateUtilities';
import GeneralComponentErrorShield from '../../../../general/GeneralComponentErrorShield';
import { CustomColumn } from '../../../../../types/components/tables/tableTypes';
import { DatePicker } from '../../../../../types/redux/pages/PagesStore';
import { useAdditionalTitleBarDatePicker } from '../../../../../hooks/useAdditionalTitleBarComponents';

import {
  togglePositionDateShowing,
  updateTitleBarSelectValue,
} from '../../../../../redux/pages/actions';
import { useDispatch, useSelector } from 'react-redux';
import {
  activeSectionSelector,
  createActiveFundSelectorBySection,
} from '../../../../../redux/pages/selectors';
import { useLocation } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import { RaptorTheme } from '../../../../../styling/theme';
import dayjs from 'dayjs';

export interface HistoricalTableProps {
  activeFund: string;
  fetchUrl: string;
  reduxKey?: string;
}

export default function HistoricalTable(
  props: HistoricalTableProps,
): ReactElement | null {
  const startDateWeekday = getWeekdayXMonthsAgo(1);
  const endDateWeekday = getMostRecentWeekday();

  const startDatePicker: DatePicker = {
    titleBarKey: 'startDate',
    titleBarTitle: 'Select Start Date: ',
    currentValue: dayjs(startDateWeekday).format('YYYY-MM-DD'),
    displayOnOverviewPage: false,
  };
  const { pathname } = useLocation();

  const endDatePicker: DatePicker = {
    titleBarKey: 'endDate',
    titleBarTitle: 'Select End Date: ',
    currentValue: dayjs(endDateWeekday).format('YYYY-MM-DD'),
    displayOnOverviewPage: false,
  };
  const activeSection = useSelector(activeSectionSelector);
  const activeFund = useSelector(
    createActiveFundSelectorBySection(activeSection),
  );

  const startDateValue = useAdditionalTitleBarDatePicker(startDatePicker);
  const endDateValue = useAdditionalTitleBarDatePicker(endDatePicker);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(togglePositionDateShowing(false));
    return () => {
      dispatch(togglePositionDateShowing(true));
    };
  }, []);
  const historical = useFetchData({
    url: `${props.fetchUrl}/${props.activeFund}/${startDateValue}/${endDateValue}`,
    keyName: `${
      props.activeFund
    }_ucits-law_historical_${startDateValue}-${endDateValue}_${
      props.reduxKey ? props.reduxKey : ''
    }`,
    makeFetch: startDateValue !== null && endDateValue !== null,
  });

  const setBackToCurrent = () => {
    dispatch(updateTitleBarSelectValue('dataDisplay', 'current'));
  };

  const historicalDataExtracted = historical ? historical.data : [];

  // create historical columns
  const [historicalTableData, historicalColumns] = useMemo(() => {
    return buildHistoricalData(
      historicalDataExtracted,
      pathname,
      activeFund?.id || 'na',
      setBackToCurrent,
    );
  }, [historicalDataExtracted, pathname, activeFund, setBackToCurrent]);

  const columns = historicalColumns?.map((col: any) => ({
    ...col,
    cellStyle: {
      ...col.cellStyle,
      padding: 0,
    },
    pdfRenderType: 'PageLinkButton',
  }));

  return (
    <GeneralComponentErrorShield dataObjects={[historical]}>
      <GridItem xs={12} card>
        <CustomTable<ILawHistoricalData>
          showToolbar={true}
          columns={columns}
          data={historicalTableData}
          csvFields={
            historicalColumns?.length > 0
              ? columns.map(
                  (col: CustomColumn<ILawHistoricalData>) =>
                    col.field as string,
                )
              : undefined
          }
          toolbarComponents={{
            toolbarTitle: `${activeFund?.name} - Historical Data: ${startDateValue} to ${endDateValue}`,
          }}
          options={{
            paging: false,
            exportButton: true,
            search: true,
          }}
        />
      </GridItem>
    </GeneralComponentErrorShield>
  );
}
