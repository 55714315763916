import { scaleLinear } from 'd3-scale';
import { FC, ReactElement } from 'react';
import { mainColors } from '../../../../styling/theme';
import { CustomColumn } from '../../../../types/components/tables/tableTypes';
import { DataObject } from '../../../../types/redux/data/dataTypes';
import { flexify } from '../../../../utilities/cssMixins';
import { percentageToTwoDecimalPlaces } from '../../../../utilities/numberFormatters';
import GeneralCard from '../../../general/GeneralCard';
import CustomTable from '../../../tables/CustomTable';
import { Typography } from '@mui/material';

export interface FundCorrelationMatrixProps {
  dataForRender: DataObject;
  fundId: string;
}

interface CorrelationMatrixData {
  indexName: string;
  index1: number;
  index2: number;
  index3: number;
}

function buildFundCorrelationMatrixData(data: any): CorrelationMatrixData[] {
  if (!data) return [];
  if (!data.length) return [];
  if (!('correlation_matrix' in data[0])) return [];
  if (!('names' in data[0])) return [];
  const correlationMatrixData = data[0].correlation_matrix;
  const indexNames = data[0].names;
  const tableData: CorrelationMatrixData[] = [];

  correlationMatrixData.forEach((value: any, index: number) => {
    tableData.push({
      indexName: indexNames[index],
      index1: value[0],
      index2: value[1],
      index3: value[2],
    });
  });

  return tableData;
}

function getIndexNames(data: any) {
  if (!data) return [];
  if (!data.length) return [];
  if (!('names' in data[0])) return [];

  return data[0].names;
}

interface FundCorrelationMatrixCellProps {
  value: string;
  color: string;
}

function getGradinetColor(value: number) {
  const gradientFirstStep = scaleLinear()
    .domain([-1, -0.67])
    .range([
      mainColors.mainBlue_lighter as unknown as number,
      mainColors.jonquil as unknown as number,
    ]);
  const gradientSecondStep = scaleLinear()
    .domain([-0.67, -0.33])
    .range([
      mainColors.jonquil as unknown as number,
      mainColors.tertiaryGreen as unknown as number,
    ]);
  const gradientThirdStep = scaleLinear()
    .domain([-0.33, 0])
    .range([
      mainColors.tertiaryGreen as unknown as number,
      mainColors.Pass as unknown as number,
    ]);
  const gradientFourthStep = scaleLinear()
    .domain([0, 0.33])
    .range([
      mainColors.Pass as unknown as number,
      mainColors.Alert as unknown as number,
    ]);
  const gradientFifthStep = scaleLinear()
    .domain([0.33, 0.67])
    .range([
      mainColors.Pass as unknown as number,
      mainColors.Alert as unknown as number,
    ]);
  const gradientSixthStep = scaleLinear()
    .domain([0.67, 1])
    .range([
      mainColors.Alert as unknown as number,
      mainColors.Fail as unknown as number,
    ]);

  if (value <= -0.67) {
    return gradientFirstStep(value);
  } else if (value <= -0.33) {
    return gradientSecondStep(value);
  } else if (value <= 0) {
    return gradientThirdStep(value);
  } else if (value <= 0.33) {
    return gradientFourthStep(value);
  } else if (value <= 0.67) {
    return gradientFifthStep(value);
  } else {
    return gradientSixthStep(value);
  }
  // } else if (absValue <= 0.75) {
  //     return gradientFourthStep(absValue);
  // } else {
  //     return gradientFifthStep(absValue);
  // }
}

function FundCorrelationMatrixCell(
  props: FundCorrelationMatrixCellProps,
): ReactElement {
  const { value, color } = props;

  // const backgroundColor = getGradinetColor(value);

  return (
    <div
      style={{
        height: '8rem',
        // backgroundColor: getGradinetColor(value, minValue, maxValue, nav, percentValueOption) as unknown as string,
        backgroundColor: color,
        borderColor: 'white',
        borderStyle: 'solid',
        borderWidth: '0.5px',
        ...flexify('center', 'center'),
      }}
    >
      <Typography
        variant="subtitle1"
        style={{ color: 'white', fontSize: 16, fontWeight: 600 }}
      >
        {value}
      </Typography>
    </div>
    //   </Wrapper>
  );
}

function getCorrelationMatrixColumns(
  indexNames: string[],
  fundId: string,
): CustomColumn<CorrelationMatrixData>[] {
  const columns: CustomColumn<CorrelationMatrixData>[] = [
    {
      // title: 'Ebit Growth / EBIT',
      title: 'Index',
      field: 'indexName',
      headerStyle: {
        textAlign: 'center',
      },
      cellStyle: {
        textAlign: 'center',
      },
    },
    {
      title: indexNames[0],
      field: 'index1',
      pdfRenderType: 'HeatMapCell',
      render: (rowData: CorrelationMatrixData) => (
        <FundCorrelationMatrixCell
          value={percentageToTwoDecimalPlaces(
            fundId === 'mersenne004' ? rowData.index1 * -1 : rowData.index1,
          )}
          color={
            getGradinetColor(
              fundId === 'mersenne004' ? rowData.index1 * -1 : rowData.index1,
            ) as unknown as string
          }
        />
      ),
      headerStyle: {
        textAlign: 'center',
      },
      cellStyle: {
        textAlign: 'center',
        padding: 0,
      },
    },
    {
      title: indexNames[1],
      field: 'index2',
      pdfRenderType: 'HeatMapCell',
      render: (rowData: CorrelationMatrixData) => (
        <FundCorrelationMatrixCell
          value={percentageToTwoDecimalPlaces(
            fundId === 'mersenne004' ? rowData.index2 * -1 : rowData.index2,
          )}
          color={
            getGradinetColor(
              fundId === 'mersenne004' ? rowData.index2 * -1 : rowData.index2,
            ) as unknown as string
          }
        />
      ),
      headerStyle: {
        textAlign: 'center',
      },
      cellStyle: {
        textAlign: 'center',
        padding: 0,
      },
    },
    {
      title: indexNames[2],
      field: 'index3',
      pdfRenderType: 'HeatMapCell',
      render: (rowData: CorrelationMatrixData) => (
        <FundCorrelationMatrixCell
          value={percentageToTwoDecimalPlaces(
            fundId === 'mersenne004' ? rowData.index3 * -1 : rowData.index3,
          )}
          color={
            getGradinetColor(
              fundId === 'mersenne004' ? rowData.index3 * -1 : rowData.index3,
            ) as unknown as string
          }
        />
      ),
      headerStyle: {
        textAlign: 'center',
      },
      cellStyle: {
        textAlign: 'center',
        padding: 0,
      },
    },
  ];
  // indexNames.forEach((value: any, index: number) => {
  //     columns.push({
  //         title: value,
  //         field: value,
  //         pdfRenderType: 'PageLinkButton',
  //         render: (rowData: CorrelationMatrixData) => <FundCorrelationMatrixCell value={rowData[indexNumber]} color={getGradinetColor(rowData.indexValues[index]) as unknown as string} />,
  //         headerStyle: {
  //             textAlign: 'center',
  //         },
  //         cellStyle: {
  //             textAlign: 'center',
  //             padding: 0,
  //         },
  //     })
  // })
  return columns;
}

const FundCorrelationMatrix: FC<FundCorrelationMatrixProps> = (props) => {
  const { data } = props.dataForRender;
  const tableData = buildFundCorrelationMatrixData(data);
  const indexNames = getIndexNames(data);
  const columns = getCorrelationMatrixColumns(indexNames, props.fundId);
  // const { width, height } = useWindowSize();
  return (
    <GeneralCard cardStyle={{ flexGrow: 1 }}>
      <CustomTable<CorrelationMatrixData>
        pdfTitle="Fund Correlation Matrix"
        title="Fund Correlation Matrix"
        columns={columns}
        id={'fund_correlation_matrix'}
        pdfColumns={columns}
        showToolbar
        data={tableData}
        multipleTablePdfParameters={{
          specifiedWidth: 6,
          tablesInRow: 1,
        }}
        // toolbarComponents={{
        //     toolbarTitle: formattedNames[tableType],
        // }}
        options={{
          paging: false,
          search: false,
          exportButton: true,
          rowStyle: {
            marginLeft: 0,
          },
          sorting: false,
          draggable: false,
          toolbar: true,
        }}
        pdfSpecificYPosition={100}
        // pdfSpecificXPosition={5}
      />
    </GeneralCard>
  );
};

export default FundCorrelationMatrix;
