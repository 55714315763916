import React, { ReactElement, useCallback, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { RaptorTheme } from '../../../../styling/theme';
import GeneralInput from '../../../inputs/GeneralInput';
import useFundNotes from '../../../../hooks/useFundNotes';
import NotesTable from '../../utilities/NotesPage/NotesTable/NotesTable.component';
import { Note } from '../../../../sharedInterfaces/notes';
import { Slide, Typography } from '@mui/material';
import { Notes } from '@mui/icons-material';

export type NoteType = 'basic' | 'table';
export interface NotesProps {
  fundId: string;
  fundName: string;
  subject: string;
  topic: string;
  positionDate: string;
  noteType?: NoteType;
  otherUrlProvided?: string;
}

// the NoteData is the transformed note
// we receive it in its pure form from the server,
// then we transform it to look like this for consumption
export interface NoteData {
  positionDate: string;
  date: string;
  noteValue: string;
  noteType: NoteType;
}

const useNoteStyles = makeStyles<RaptorTheme>((theme) => ({
  outerContainer: {
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
    alignItems: 'center',
  },
  iconRoot: {
    color: theme.palette.primary.main,
  },
  innerContainer: {
    position: 'absolute',
    minWidth: '60rem',
    zIndex: 2000,
    right: '-1rem',
    top: '-1rem',
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    borderRadius: 8,
    paddingBottom: 0,
    boxShadow: theme.shadows[2],
  },
  fundTitle: {
    fontSize: '1.6rem',
    marginBottom: '1rem',
  },
  form: {
    width: '100%',
  },
  formAndTitle: {
    padding: '1rem',
    width: '100%',
    textAlign: 'left',
  },
}));

const NotesForm = React.memo(
  ({
    handler,
    fundName,
  }: {
    fundName: string;
    handler: (note: string) => void;
  }) => {
    const classes = useNoteStyles();
    const [input, setInput] = useState<string>('');
    const handleInput = (val: string | number) => {
      if (typeof val === 'number') setInput(val + '');
      else setInput(val);
    };

    const submit = (e: any) => {
      e.preventDefault();
      handler(input);
      setInput('');
    };

    return (
      <div className={classes.formAndTitle}>
        <Typography variant="h3" className={classes.fundTitle}>
          {fundName}
        </Typography>
        <form onSubmit={(e: any) => submit(e)} className={classes.form}>
          <GeneralInput
            width="100%"
            inputKey={'addInput'}
            inputTitle="Add Note"
            setInput={handleInput}
            inputValue={input}
            usePlaceholder
          />
        </form>
      </div>
    );
  },
);

export default function NotesComp({
  fundId,
  subject,
  topic,
  fundName,
  positionDate,
  otherUrlProvided,
}: NotesProps): ReactElement | null {
  const [rendered, setRendered] = useState(false);
  const { notes, loading, fetchFundNotes, postFundNotes } = useFundNotes({
    fundId,
    subject,
    topic,
    positionDate: positionDate || '',
    shouldCall: rendered,
    otherUrlProvided,
  });
  const tableData: NoteData[] = [];
  notes.forEach((note: Note) => {
    tableData.push({
      positionDate: note.fund_note_position_date,
      date: note.fund_note_timestamp,
      noteValue: note.fund_note_text,
      noteType: note.fund_note_text.includes('TABLE_NOTE:') ? 'table' : 'basic',
    });
  });
  const classes = useNoteStyles();

  const handleSubmit = useCallback(async (noteString: string) => {
    await postFundNotes(noteString);
    await fetchFundNotes();
  }, []);
  let timeout: any;
  const handleMouseOver = () => {
    timeout = setTimeout(() => {
      setRendered(true);
    }, 150);
  };

  const setLeave = () => {
    clearTimeout(timeout);
    setTimeout(() => {
      setRendered(false);
    }, 500);
  };
  return (
    <div>
      <div
        className={classes.outerContainer}
        onMouseEnter={handleMouseOver}
        onMouseLeave={setLeave}
        onClick={(e) => e.stopPropagation()}
      >
        <Notes classes={{ root: classes.iconRoot }} />
        <Slide in={rendered} direction={'left'} mountOnEnter unmountOnExit>
          <div className={classes.innerContainer}>
            <NotesForm handler={handleSubmit} fundName={fundName} />
            <NotesTable
              tableData={tableData}
              showToolbar
              title={`${fundName} - Notes`}
              loadingHeight="4rem"
            />
          </div>
        </Slide>
      </div>
    </div>
  );
}
