import { ContentState, Editor, EditorState } from 'draft-js';
import React, { Dispatch, FC } from 'react';
import GridItem from '../../../../../layout/GridComponents/GridItem';
import { stateToHTML } from 'draft-js-export-html';
import { greys } from '../../../../../../styling/theme';
import { Typography } from '@mui/material';

interface MetricEditorTileProps {
  delegatedActsElement: string;
  delegatedActsData: any[];
  setDelegatedActsData: Dispatch<any>;
  setHaveChangesBeenMade: Dispatch<any>;
  index: number;
}

//   type TagType = 'BOLD' | 'UNDERLINE';

//   interface formatButtonProps {
//     text: string;
//     inlineStyle: string;
//     handler: (e: React.MouseEvent, inlineStyle: string) => void;
//   }

//   const useButtonStyles = makeStyles(() => ({
//     button: {
//       backgroundColor: mainColors.mainBlue_lighter,
//       '&:hover, &:focus': {
//         backgroundColor: mainColors.mainBlue,
//         cursor: 'pointer',
//       },
//       border: 'none',
//       marginRight: '0.5rem',
//       maxHeight: '2rem',
//       marginTop: '1rem',
//     },
//   }));

const MetricEditorTile: FC<MetricEditorTileProps> = (props) => {
  // const data = localStorage.getItem(TEXT_EDITOR_ITEM);
  // const initialState = EditorState.createWithContent(ContentState.createFromText(props.text || ''));
  const {
    delegatedActsElement,
    delegatedActsData,
    setDelegatedActsData,
    index,
    setHaveChangesBeenMade,
  } = props;

  // Convert the data into an object.
  const dataJson = JSON.parse(delegatedActsElement);
  // const blocksFromHTML = convertFromHTML(props.text || '');
  // const contentState = ContentState.createFromBlockArray(
  //   blocksFromHTML.contentBlocks
  // );

  // Create 4 different editor states for each year of the data, explaination and actions taken (These are the only values that can be edited)
  const contentStateYearN = ContentState.createFromText(dataJson.impactYearN);
  const initialStateYearN = EditorState.createWithContent(contentStateYearN);
  const [editorStateYearN, setEditorStateYearN] =
    React.useState<EditorState>(initialStateYearN);

  const contentStateYearNMinus1 = ContentState.createFromText(
    dataJson.impactYearNMinus1,
  );
  const initialStateYearNMinus1 = EditorState.createWithContent(
    contentStateYearNMinus1,
  );
  const [editorStateYearNMinus1, setEditorStateYearNMinus1] =
    React.useState<EditorState>(initialStateYearNMinus1);

  const contentStateExplaination = ContentState.createFromText(
    dataJson.explaination,
  );
  const initialStateExplaination = EditorState.createWithContent(
    contentStateExplaination,
  );
  const [editorStateExplaination, setEditorStateExplaination] =
    React.useState<EditorState>(initialStateExplaination);

  const contentStateActionTaken = ContentState.createFromText(
    dataJson.actionsTaken,
  );
  const initialStateActionTaken = EditorState.createWithContent(
    contentStateActionTaken,
  );
  const [editorStateActionTaken, setEditorStateActionTaken] =
    React.useState<EditorState>(initialStateActionTaken);

  const handleTextChange = (
    updatedEditorState: EditorState,
    whichYear: 'yearN' | 'yearNMinus1' | 'explaination' | 'actionTaken',
  ) => {
    // Check which data is to be updated.
    if (whichYear === 'yearN') setEditorStateYearN(updatedEditorState);
    else if (whichYear === 'explaination')
      setEditorStateExplaination(updatedEditorState);
    else if (whichYear === 'actionTaken')
      setEditorStateActionTaken(updatedEditorState);
    else setEditorStateYearNMinus1(updatedEditorState);
    // Get The text from the editor state
    let text = stateToHTML(updatedEditorState.getCurrentContent());

    text = text
      .replaceAll('<p>', '')
      .replaceAll('</p>', '')
      .replaceAll('<strong>', '<b>')
      .replaceAll('</strong>', '</b>')
      .replaceAll('<em>', '<i>')
      .replaceAll('</em>', '</i>');
    // Now update the element in the overall data
    const allContent = delegatedActsData;
    // Check which data point of data needs to be updated dataJson
    if (whichYear === 'yearN') dataJson.impactYearN = text;
    else if (whichYear === 'explaination') dataJson.explaination = text;
    else if (whichYear === 'actionTaken') dataJson.actionsTaken = text;
    else dataJson.impactYearNMinus1 = text;
    // Check if the text has changes and if so indicate it
    if (allContent[index].content !== delegatedActsElement)
      setHaveChangesBeenMade(true);
    // Now replace in the overall array
    allContent[index].content = JSON.stringify(dataJson);
    // Update the overall data
    setDelegatedActsData(allContent);
  };
  // const refreshEditorState = () => {
  //   setEditorState(EditorState.createEmpty());
  //   // return editorState;
  // };

  // useEffect(() => {
  //   if (!props.text.length) {
  //     refreshEditorState();
  //   }
  // }, [props.text]);

  return (
    <GridItem
      xs={12}
      card
      style={{
        marginBottom: '1rem',
      }}
    >
      <div>
        <Typography
          variant="h3"
          style={{ fontSize: '1.5rem', color: greys.grey400 }}
        >
          Annex 1
        </Typography>
        <Typography
          variant="h3"
          style={{ marginLeft: '1rem', marginTop: '1rem' }}
        >
          {dataJson.RTSIndicator}
        </Typography>
        <Typography variant="h3" style={{ marginLeft: '1rem' }}>
          {dataJson.IRIndicator}
        </Typography>
        <div
          style={{
            padding: '1rem',
            fontSize: '1.5rem',
            minHeight: '5rem',
            display: 'flex',
          }}
        >
          {/* <div
                        style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        }}
                    >
                        <div style={{ display: 'flex' }}>
                        <CommentEditorButton
                            fundId={props.fundId}
                            shareClass={props.shareClass}
                            commentId={props.commentId}
                            kidIndex={props.index}
                            hasUnresolvedComments={props.hasUnresolvedComments}
                            setKiidsContent={props.setKiidsContent}
                            kiidsContent={props.kiidsContent}
                        />
                        </div>
                    </div> */}

          <div>
            <Typography variant="h3">Year N</Typography>

            <div
              style={{
                border: '0.5px solid',
                minHeight: '2rem',
                marginTop: '0.5rem',
                backgroundColor: 'white',
                minWidth: '10rem',
              }}
            >
              <Editor
                editorState={editorStateYearN}
                onChange={(updatedEditorState: EditorState) => {
                  handleTextChange(updatedEditorState, 'yearN');
                }}
                stripPastedStyles={true}
                //   handleKeyCommand={handleKeyCommand}
                //   customStyleMap={styleMap}
                //   readOnly={disabled}
                // blockRendererFn={mediaBlockRenderer}
              />
            </div>
          </div>
          <div
            style={{
              marginLeft: '1rem',
            }}
          >
            <Typography variant="h3">Year N - 1</Typography>

            <div
              style={{
                border: '0.5px solid',
                minHeight: '2rem',
                marginTop: '0.5rem',
                backgroundColor: 'white',
                minWidth: '10rem',
              }}
            >
              <Editor
                editorState={editorStateYearNMinus1}
                onChange={(updatedEditorState: EditorState) => {
                  handleTextChange(updatedEditorState, 'yearNMinus1');
                }}
                stripPastedStyles={true}
                //   handleKeyCommand={handleKeyCommand}
                //   customStyleMap={styleMap}
                //   readOnly={disabled}
                // blockRendererFn={mediaBlockRenderer}
              />
            </div>
          </div>
        </div>
        <Typography variant="h3" style={{ marginLeft: '1rem' }}>
          Explaination
        </Typography>
        <div
          style={{
            border: '0.5px solid',
            minHeight: '2rem',
            marginTop: '0.5rem',
            backgroundColor: 'white',
            marginBottom: '2rem',
            marginLeft: '1rem',
            marginRight: '1rem',
            fontSize: '1.5rem',
          }}
        >
          <Editor
            editorState={editorStateExplaination}
            onChange={(updatedEditorState: EditorState) => {
              handleTextChange(updatedEditorState, 'explaination');
            }}
            stripPastedStyles={true}
            //   handleKeyCommand={handleKeyCommand}
            //   customStyleMap={styleMap}
            //   readOnly={disabled}
            // blockRendererFn={mediaBlockRenderer}
          />
        </div>
        <Typography variant="h3" style={{ marginLeft: '1rem' }}>
          Actions Taken
        </Typography>

        <div
          style={{
            border: '0.5px solid',
            minHeight: '2rem',
            marginTop: '0.5rem',
            backgroundColor: 'white',
            marginBottom: '2rem',
            marginLeft: '1rem',
            marginRight: '1rem',
            fontSize: '1.5rem',
          }}
        >
          <Editor
            editorState={editorStateActionTaken}
            onChange={(updatedEditorState: EditorState) => {
              handleTextChange(updatedEditorState, 'actionTaken');
            }}
            stripPastedStyles={true}
            //   handleKeyCommand={handleKeyCommand}
            //   customStyleMap={styleMap}
            //   readOnly={disabled}
            // blockRendererFn={mediaBlockRenderer}
          />
        </div>
      </div>
    </GridItem>
  );
};

export default MetricEditorTile;
