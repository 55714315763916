import React, { ReactElement } from 'react';
import CustomTable from '../../../../tables/CustomTable';
import {
  InvestorBasedData,
  RedemptionLiquidityKeys,
  buildData,
  investorColumns,
} from './investorBased.data';
import GridItem from '../../../../layout/GridComponents/GridItem';

interface Props {
  inputKey: RedemptionLiquidityKeys;
  title: string;
  data: any;
  loading: boolean;
  section: string;
  redemptionLevel: number | null;
  pdfDontMoveToNewPage?: boolean;
}

function InvestorTable(props: Props): ReactElement {
  const areaKey =
    props.section === 'sif-raif'
      ? 'sif'
      : props.section === 'act40'
        ? 'act40'
        : 'ucits';
  const columns = investorColumns(areaKey);
  const data = buildData(
    props.data,
    props.inputKey,
    areaKey,
    props.redemptionLevel,
  );
  return (
    <GridItem xs={6} card>
      <CustomTable<InvestorBasedData>
        options={{
          paging: false,

          rowStyle: {
            padding: 0,
          },
        }}
        multipleTablePdfParameters={{
          specifiedWidth: 6,
          tablesInRow: 2,
        }}
        data={data}
        columns={columns}
        showToolbar={true}
        title={props.title}
        loading={props.loading}
        pdfDontMoveToNewPage={props.pdfDontMoveToNewPage}
      />
    </GridItem>
  );
}

export default InvestorTable;
