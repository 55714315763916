import makeStyles from '@mui/styles/makeStyles';
import { ReactElement, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { createActiveFundSelectorBySection } from '../../../../../redux/pages/selectors';
import { RaptorTheme, mainColors } from '../../../../../styling/theme';
import { CustomColumn } from '../../../../../types/components/tables/tableTypes';
import {
  toFourDecimalPlaces,
  toTwoDecimalPlaces,
} from '../../../../../utilities/numberFormatters';
import CustomTable from '../../../../tables/CustomTable';
import { LawSecondLevelData } from './SecondLevelTable';
import { Button, useTheme } from '@mui/material';
import { ArrowRight, TripOrigin } from '@mui/icons-material';
import client from '../../../../../utilities/requestClient';

export const generalThirdLevelDetail = [
  (rowData: LawSecondLevelData) => ({
    render: (rowData: LawSecondLevelData) => {
      return (
        <GeneralThirdLevelTable
          data={rowData.position_g_data.filter((asset: any) => {
            return asset.g_name === rowData.issuer;
          })}
          issuer={rowData.issuer}
          activePositionDate={rowData.positionDate}
          ruleNumber={rowData.ruleNumber}
        />
      );
    },
    disabled:
      rowData.headerRow ||
      !rowData.position_g_data.filter((asset: any) => {
        return asset.g_name === rowData.issuer;
      }).length,
    icon: rowData.headerRow ? TripOrigin : ArrowRight,
  }),
];

interface Props {
  data: LawSecondLevelData;
  issuer: string;
  activePositionDate: string;
  ruleNumber: number;
}

export interface ILawThirdLevelData {
  number: number;
  asset_name: string;
  isin: string;
  instrument_type: string;
  info: string;
  risk_factor: string;
  position: string;
  comparisonPosition?: string;
  exposure: number;
  comparisonExposure?: number;
  difference?: number;
}

export const noComparisonColumns: CustomColumn<ILawThirdLevelData>[] = [
  {
    title: '#',
    field: 'number',
    width: 1,
  },
  {
    title: 'Asset Name',
    field: 'asset_name',
    width: '20%',
  },
  {
    title: 'ISIN',
    field: 'isin',
    headerStyle: {
      textAlign: 'center',
    },
    cellStyle: {
      textAlign: 'center',
    },
  },
  {
    title: 'Instrument Type',
    field: 'instrument_type',
    headerStyle: {
      textAlign: 'center',
    },
    cellStyle: {
      textAlign: 'center',
    },
  },
  {
    title: 'Info',
    field: 'info',
    headerStyle: {
      textAlign: 'center',
    },
    cellStyle: {
      textAlign: 'center',
    },
  },
  {
    title: 'Risk Factor',
    field: 'risk_factor',
    headerStyle: {
      textAlign: 'center',
    },
    cellStyle: {
      textAlign: 'center',
    },
  },
  {
    title: 'Position',
    field: 'position',
    headerStyle: {
      textAlign: 'center',
    },
    cellStyle: {
      textAlign: 'center',
    },
  },
  {
    title: 'Exposure',
    field: 'exposure',
    render: (rowData) => toTwoDecimalPlaces(rowData.exposure),
    headerStyle: {
      textAlign: 'center',
    },
    cellStyle: {
      textAlign: 'center',
    },
  },
];

const withComparisonColumns = (
  firstDate: string,
  secondDate: string,
): CustomColumn<ILawThirdLevelData>[] => [
  ...noComparisonColumns,
  {
    field: 'comparisonPosition',
    title: `Position: ${secondDate}`,
    headerStyle: {
      textAlign: 'center',
    },
    cellStyle: {
      textAlign: 'center',
    },
  },
  {
    field: 'position',
    title: `Position: ${firstDate}`,
    headerStyle: {
      textAlign: 'center',
    },
    cellStyle: {
      textAlign: 'center',
    },
  },
  {
    field: 'comparisonExposure',
    title: `Exposure: ${secondDate}`,
    render: (rowData) => toFourDecimalPlaces(rowData.comparisonExposure!),
    headerStyle: {
      textAlign: 'center',
    },
    cellStyle: {
      textAlign: 'center',
    },
  },
  {
    field: 'exposure',
    render: (rowData) => toFourDecimalPlaces(rowData.exposure),
    title: `Exposure: ${firstDate}`,
    headerStyle: {
      textAlign: 'center',
    },
    cellStyle: {
      textAlign: 'center',
    },
  },
  // {
  //   field: 'difference',
  //   title: 'Difference',
  // },
];

export const tertiaryOptions = {
  paging: false,
  showTitle: false,
  search: false,
  toolbar: true,
  exportButton: true,
};

// lazily setting data to any, because what is returned from the server could either be
//
export function buildAssetData(
  data: any,
  currentData?: any,
): ILawThirdLevelData[] {
  try {
    return data.map((asset: any, index: number) => {
      let comparisonPosition;
      let comparisonExposure;
      let position;
      let exposure;

      // if we are comparing the activePositionDate with the currentPositionDate
      // we need to get the correct data
      if (currentData) {
        position = currentData.find(
          (el: any) => el.isin === asset.isin,
        )?.mr_position;
        comparisonPosition = asset.mr_position;
        exposure = currentData.find(
          (el: any) => el.isin === asset.isin,
        )?.exposure;
        comparisonExposure = asset.exposure;
      } else {
        position = asset.mr_position;
        exposure = asset.exposure;
      }
      return {
        number: index + 1,
        asset_name: asset.gm_name_long,
        isin: asset.isin,
        instrument_type: asset.instrument_id,
        info: asset.maturity_date,
        risk_factor: asset.risk_factor,
        position,
        exposure,
        comparisonPosition,
        comparisonExposure,
      };
    });
  } catch (err) {
    throw new Error('Problem creating error from data: ' + err);
  }
}

const useStyles = makeStyles<RaptorTheme, { alteredColor?: boolean }>(
  (theme) => ({
    minusButton: {
      color: (props) =>
        props.alteredColor ? mainColors.mainGold : theme.palette.grey[800],
      backgroundColor: 'white',
      minWidth: 0,
      padding: '4px 8px',
      textTransform: 'none',
      '&:hover': {
        backgroundColor: theme.palette.grey[100],
        color: theme.palette.primary.main,
      },
    },
  }),
);

export const PreviousPositionDateButton = ({
  currentPositionDate,
  activePositionDate,
  handler,
  ...props
}: {
  currentPositionDate: string;
  activePositionDate: string;
  handler: () => void;
  key: string;
  alteredColor?: boolean;
}) => {
  const classes = useStyles({
    alteredColor: props.alteredColor,
  });
  const isToday = currentPositionDate === activePositionDate;
  return (
    <Button onClick={handler} className={classes.minusButton}>
      {isToday
        ? 'Compare with previous position date'
        : `View today's position date (${activePositionDate})`}
    </Button>
  );
};

function GeneralThirdLevelTable({
  data,
  issuer,
  // activePositionDate is the position date the whole page is currently on
  activePositionDate,
  ruleNumber,
}: Props): ReactElement {
  // current position date is for looking at the inscope assets and comparing them
  const [currentPositionDate, setCurrentPositionDate] =
    useState(activePositionDate);
  // when the component is rendered, it is todays date
  const [showPriorData, setShowPriorData] = useState(false);
  const [dataForTable, setDataForTable] = useState<any>(data);
  const tableData = buildAssetData(
    dataForTable,
    activePositionDate !== currentPositionDate ? data : undefined,
  );
  const activeFund = useSelector(createActiveFundSelectorBySection('ucits'));
  // https://dev1.ucits.risksystem.com/get_t_minus_one_ucits_filtered_positions/mersenne001/MaxCyte%20Inc/2021-02-15

  const handleFetchingPriorData = async () => {
    if (!showPriorData) {
      setShowPriorData(true);
    } else {
      setShowPriorData(false);
    }
  };
  useEffect(() => {
    if (showPriorData) {
      (async () => {
        const requestClient = client();
        const priorDataFromServer = await requestClient.get(
          `get_t_minus_one_ucits_filtered_positions/${activeFund?.id}/${issuer}/${activePositionDate}`,
        );
        setDataForTable(priorDataFromServer.data);
        setCurrentPositionDate(
          priorDataFromServer.data[0].selected_position_date,
        );
      })();
    } else {
      setDataForTable(data);
      setCurrentPositionDate(activePositionDate);
    }
  }, [showPriorData]);
  const theme = useTheme();

  return (
    <CustomTable<ILawThirdLevelData>
      showToolbar={true}
      selectedPositionDate={activePositionDate}
      columns={
        currentPositionDate === activePositionDate
          ? noComparisonColumns
          : withComparisonColumns(activePositionDate, currentPositionDate)
      }
      toolbarComponents={{
        titleStyle: {
          color:
            currentPositionDate === activePositionDate
              ? theme.palette.primary.main
              : mainColors.mainGold,
        },
        otherComponents: [
          <PreviousPositionDateButton
            handler={handleFetchingPriorData}
            currentPositionDate={currentPositionDate}
            activePositionDate={activePositionDate}
            // we need the key because we'll be iterating over these components
            key={`${data.issuer}_detail_key`}
            alteredColor={currentPositionDate !== activePositionDate}
          />,
        ],
      }}
      noteExport={{
        fundId: activeFund?.id || 'unknown',
        subject: 'ucits_ucits-law',
        topic: `rule_${ruleNumber}`,
        positionDate: activePositionDate,
        shouldCall: false,
      }}
      data={tableData}
      title={`${issuer} - ${
        currentPositionDate === activePositionDate
          ? currentPositionDate
          : `comparing ${currentPositionDate} with ${activePositionDate}`
      }`}
      csvFields={tableData.length > 0 ? Object.keys(tableData[0]) : []}
      options={tertiaryOptions}
    />
  );
}

export default GeneralThirdLevelTable;
