import React, { Dispatch, FC, useEffect } from 'react';
import { CustomColumn } from '../../../../../../types/components/tables/tableTypes';
import GridItem from '../../../../../layout/GridComponents/GridItem';
import CustomTable from '../../../../../tables/CustomTable';
import { BASE_URL } from '../../../../../../utilities/requestClient';
import axios from 'axios';
import useSnackbar from '../../../../../../hooks/useSnackbar';
import {
  RaptorTheme,
  greys,
  mainColors,
} from '../../../../../../styling/theme';
import Icon from '@mui/material/Icon';
import makeStyles from '@mui/styles/makeStyles';
import Button from '@mui/material/Button';
import { useDispatch, useSelector } from 'react-redux';
import KiidsEditorOverviewSecondLevelTableWrapper from './KiidsEditorOverviewSecondLevelTableWrapper.component';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import { activePageSelector } from '../../../../../../redux/pages/selectors';
import GeneralSelect from '../../../../../selects/GeneralSelect';
import { Tooltip, Typography } from '@mui/material';

interface KiidsEditorOverviewprops {
  data: any[];
  setSelectedKiid: Dispatch<any>;
  setCanBePublished: Dispatch<any>;
  setViewTrackedChanges: Dispatch<any>;
  setViewReview: Dispatch<any>;
  setTrackedChangesVersions: Dispatch<any>;
}
export interface KiidsEditorOverviewTableData {
  fundId: string;
  fundUUID: string;
  fundName: string;
  numShareClasses: number;
  unresolvedComments: number;
  secondLevelData: KiidsEditorOverviewSecondLevelTableData[];
}
export interface KiidsEditorOverviewSecondLevelTableData {
  fundId: string;
  fundName: string;
  isin: string;
  publicationDate: string;
  lasteEditedDate: string;
  lastEditedBy: string;
  version: string;
  fund_uuid: string;
  kiid_uuid: string;
  unresolvedComments: number;
  versionNumbers: string[];
  index: number;
  documentLanguage: string;
}

function buildKiidsEditorOverviewTableData(
  data: any[],
  selectedLanguage: string,
): KiidsEditorOverviewTableData[] {
  if (!data) return [];
  if (!data.length) return [];
  if (!selectedLanguage) return [];
  if (selectedLanguage === '') return [];
  const kiidsData = data;
  const tableData: KiidsEditorOverviewTableData[] = [];
  // Create an object for storing the data.
  type DataStore = {
    [key: string]: KiidsEditorOverviewTableData;
  };
  const dataStore: DataStore = {};
  // Loop through the data and add to the data store.
  kiidsData
    .filter((value: any) => value.document_language === selectedLanguage)
    .forEach((value: any, index: number) => {
      // Build the second level data first
      const secondLevelData: KiidsEditorOverviewSecondLevelTableData = {
        fundId: value.fund_id_string,
        fundName: value.fund_name,
        isin: value.share_class,
        publicationDate: value.publication_timestamp,
        lasteEditedDate: value.last_edit_timestamp,
        lastEditedBy: value.last_edited_by_name,
        version: value.version,
        fund_uuid: value.fund_id,
        kiid_uuid: value.kiid_id,
        unresolvedComments: value.num_unresolved_comments,
        versionNumbers: value.version_numbers,
        index: index,
        documentLanguage: value.document_language,
      };
      // Check if this fund is NOT already in the data store
      if (!(value.fund_id_string in dataStore)) {
        secondLevelData.index = 0;
        // Create a new entry in the datastore
        dataStore[value.fund_id_string] = {
          fundId: value.fund_id_string,
          fundUUID: value.fund_id,
          fundName: value.fund_name,
          numShareClasses: 1,
          unresolvedComments: value.num_unresolved_comments,
          secondLevelData: [secondLevelData],
        };
      } else {
        // Otherwise just increment number of share classes and append to second level data.
        dataStore[value.fund_id_string].numShareClasses += 1;
        dataStore[value.fund_id_string].unresolvedComments +=
          value.num_unresolved_comments;
        secondLevelData.index =
          dataStore[value.fund_id_string].secondLevelData.length;
        dataStore[value.fund_id_string].secondLevelData.push(secondLevelData);
      }
    });
  // Get just the values form the datastore and return this.
  const dataStoreValues = Object.values(dataStore);

  return dataStoreValues;
}

const useStyles = makeStyles<RaptorTheme>((theme) => ({
  arrow: {
    '&:before': {
      border: '1px solid #E6E8ED',
    },
    color: mainColors.controlButtonBlue,
  },
  tooltip: {
    backgroundColor: mainColors.controlButtonBlue,
    border: '1px solid #E6E8ED',
    color: 'white',
  },
  pickerRoot: {
    backgroundColor: 'white',
    borderRadius: 8,
    boxShadow: theme.shadows[5],
    marginRight: '1.2rem',
    height: '5rem',
    width: '100%',
    marginBottom: '1.2rem',
  },
  selectRoot: {
    width: '100%',
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  generalSelectContainer: {
    width: '20%',
    marginLeft: '2rem',
    marginTop: '2rem',
  },
  downloadButtonContainer: {
    display: 'flex',
    width: '20%',
    height: '3rem',
    marginTop: '2rem',
  },
}));

function buildTableColumns(
  handleFileDownload: (fund_id?: string) => void,
): CustomColumn<KiidsEditorOverviewTableData>[] {
  const classes = useStyles();
  const dispatch = useDispatch();
  const detailColumns: CustomColumn<KiidsEditorOverviewTableData>[] = [
    {
      title: 'Fund ID',
      field: 'fundId',
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: { textAlign: 'center' },
      defaultSort: 'asc',
      render: (rowData: KiidsEditorOverviewTableData) => {
        return rowData.unresolvedComments > 0 ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <div>
              <Tooltip
                title={'Document containes unresolved comments.'}
                placement={'top'}
              >
                <Icon
                  sx={{
                    fontSize: 30,
                    color: mainColors.pumpkin,
                    '&:hover': {
                      cursor: 'pointer',
                    },
                  }}
                >
                  feedback
                </Icon>
              </Tooltip>
            </div>
            <div>{rowData.fundId}</div>
          </div>
        ) : (
          <div>{rowData.fundId}</div>
        );
      },
    },
    {
      title: 'Fund Name',
      field: 'fundName',
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: { textAlign: 'center' },
    },
    {
      title: '#Share Classes',
      field: 'numShareClasses',
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: { textAlign: 'center' },
    },
    {
      title: 'Download',
      field: '',
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: { textAlign: 'center' },
      render: (rowData: KiidsEditorOverviewTableData) => {
        return (
          <Tooltip
            title={
              <Typography variant="h3" style={{ color: 'white' }}>
                {`Download all documents for all share classes of ${rowData.fundName} into a zip file. This may take a few moments to complete.`}
              </Typography>
            }
            placement="top"
            style={{ fontSize: '10rem' }}
          >
            <Button
              style={{
                height: '3rem',
              }}
              variant="contained"
              onClick={() => handleFileDownload(rowData.fundId)}
              endIcon={<DownloadForOfflineIcon style={{ color: 'white' }} />}
            >
              <div style={{ color: 'white' }}>Download All Share Classes</div>
            </Button>
          </Tooltip>
        );
      },
    },
  ];
  return detailColumns;
}
const KiidsEditorOverview: FC<KiidsEditorOverviewprops> = (props) => {
  const [selectedLanguage, setSelectedLanguage] = React.useState<string>('');
  const [languageOptions, setLanguageOptions] = React.useState<string[]>([]);
  const [tableData, setTableData] = React.useState<
    KiidsEditorOverviewTableData[]
  >([]);
  useEffect(() => {
    if (props.data && props.data.length) {
      const languages = props.data.map((value: any) => value.document_language);
      const uniqueLanguages = [...new Set(languages)];
      setLanguageOptions(uniqueLanguages);
      setSelectedLanguage(uniqueLanguages[0]);
    }
  }, [props.data]);

  useEffect(() => {
    const filteredTableData = buildKiidsEditorOverviewTableData(
      props.data,
      selectedLanguage,
    );
    setTableData(filteredTableData);
  }, [selectedLanguage]);

  const requestClient = axios.create({
    withCredentials: true,
    baseURL: BASE_URL,
  });
  const { showAsyncSnackbar } = useSnackbar();

  async function handlePropogateChanges(fund_id: string, template_id: string) {
    // Save the data to the DB
    await showAsyncSnackbar({
      startMessage: 'Proprogating Changes.',
      successMessage: 'Changes Made.',
      failureMessage: 'An Error occured while making changes.',
      promiseToResolve: requestClient.get(
        `kiids_generator_propogate/${fund_id}/${template_id}`,
      ),
    });
  }

  function downloadFile(fundId?: string) {
    const mapForm = document.createElement('form');
    mapForm.target = '_self';
    mapForm.method = 'POST';
    const params = ['manco_id'];
    mapForm.action =
      BASE_URL +
      `risksystem_pdf_generator/get_all_kid_files/${
        fundId ? fundId + '_documents.zip' : 'kid_documents.zip'
      }`;
    // add the fund_id
    const mancoIdInput = document.createElement('input');
    mancoIdInput.type = 'text';
    mancoIdInput.name = 'manco_id';
    if (!props.data) return [];
    if (!props.data.length) return [];
    const kiidsData = props.data;
    mancoIdInput.value = kiidsData[0].manco_id || '';
    // Add to the form
    mapForm.appendChild(mancoIdInput);
    // If fund_id is provided then add to the params
    if (fundId) {
      params.push('fund_id');
      const fundIdInput = document.createElement('input');
      fundIdInput.type = 'text';
      fundIdInput.name = 'fund_id';
      fundIdInput.value = fundId;
      // Add to the form
      mapForm.appendChild(fundIdInput);
    }
    // If documentLanguage is provided then add to the params
    if (selectedLanguage) {
      params.push('document_language');
      const documentLanguageInput = document.createElement('input');
      documentLanguageInput.type = 'text';
      documentLanguageInput.name = 'document_language';
      documentLanguageInput.value = selectedLanguage;
      // Add to the form
      mapForm.appendChild(documentLanguageInput);
    }
    // add the params
    const paramsInput = document.createElement('input');
    paramsInput.type = 'text';
    paramsInput.name = 'params';
    paramsInput.value = JSON.stringify(params);
    // Add to the form
    mapForm.appendChild(paramsInput);

    // Add the form to the dom
    document.body.appendChild(mapForm);
    // submit the form
    mapForm.submit();
    // remove the form
    document.body.removeChild(mapForm);
  }

  const columns = buildTableColumns(downloadFile);

  const currentPage = useSelector(activePageSelector);

  const classes = useStyles();
  return (
    <GridItem xs={12} card>
      <div className={classes.headerContainer}>
        <div className={classes.generalSelectContainer}>
          <GeneralSelect
            labels={languageOptions}
            values={languageOptions}
            selected={selectedLanguage}
            setSelected={setSelectedLanguage}
            placeholderValue=""
            placeholderColor={greys.grey900}
            selectTitle={'Documents Language'}
            customStyles={{
              pickerRoot: classes.pickerRoot,
              selectRoot: classes.selectRoot,
            }}
          />
        </div>

        <Tooltip
          title={
            <Typography variant="h3" style={{ color: 'white' }}>
              Download all documents into a zip file. This may take a few
              moments to complete.
            </Typography>
          }
          placement="top"
          style={{ fontSize: '10rem' }}
        >
          <div className={classes.downloadButtonContainer}>
            <Button
              style={{
                marginLeft: 'auto',
                marginRight: '2rem',
              }}
              variant="contained"
              onClick={() => downloadFile()}
              endIcon={<DownloadForOfflineIcon style={{ color: 'white' }} />}
            >
              <div style={{ color: 'white' }}>Download All Documents</div>
            </Button>
          </div>
        </Tooltip>
      </div>
      <CustomTable<KiidsEditorOverviewTableData>
        title={
          currentPage?.includes('kiid')
            ? 'UCITS KIID Editor'
            : 'PRIIPs KID Editor'
        }
        showToolbar={true}
        id={`kiids_editor_overview_table`}
        loading={props.data && props.data.length ? false : true}
        data={tableData}
        options={{
          paging: false,
          search: true,
          exportButton: false,
          exportAllData: false,
          emptyRowsWhenPaging: false,
        }}
        detailPanel={KiidsEditorOverviewSecondLevelTableWrapper(
          props.setSelectedKiid,
          props.setCanBePublished,
          props.setViewTrackedChanges,
          props.setViewReview,
          props.setTrackedChangesVersions,
        )}
        columns={columns}
      />
    </GridItem>
  );
};

export default KiidsEditorOverview;
