import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { flexRender, Header, Table } from '@tanstack/react-table';
import { CSSProperties } from 'react';
import { ClassNameMap, makeStyles } from '@mui/styles';
import { Box, Tooltip } from '@mui/material';
import FilterButton from './FilterButton';
import { greys, mainColors } from '../../../../styling/theme';
import FilterColumn from './FilterColumn';
import '../styles/resizer.css';

const useHeaderStyles = makeStyles(() => ({
  headerContainer: {
    fontWeight: 600,
    fontSize: 'clamp(1.2rem, 0.85vw, 1.6rem)',
    color: mainColors.mainBlue,
    cursor: 'grab',
  },
  filterContainer: {
    display: 'flex',
    gap: '0.5rem',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  expander: {
    display: 'flex',
    position: 'absolute',
    top: 0,
    height: '100%',
    width: '1px',
    opacity: 0.5,
    cursor: 'col-resize',
    select: 'none',
    touch: 'none',
    background: greys.grey500,
    right: 0,
    justifyContent: 'center',
    alignItems: 'center',
    transform: 'translateX(1px)',
  },
  iconExpanding: { fill: 'blue' },

  filterIcon: {
    '&:hover': {
      color: mainColors.mainBlue,
    },
    cursor: 'pointer',
  },

  isExpanding: {
    background: 'blue !important',
  },
}));

export const DraggableTableHeader = <T,>({
  table,
  header,
  classes,
}: {
  table: Table<T>;
  header: Header<T, unknown>;
  classes: ClassNameMap;
}) => {
  const { attributes, isDragging, listeners, setNodeRef, transform } =
    useSortable({
      id: header.column.id,
    });

  const headerClasses = useHeaderStyles();

  // BUG: Header size not updating to state value
  const style: CSSProperties = {
    opacity: isDragging ? 0.8 : 1,
    position: 'relative',
    transform: CSS.Translate.toString(transform), // translate instead of transform to avoid squishing
    transition: 'width transform 0.2s ease-in-out',
    whiteSpace: 'nowrap',
    width: header.column.getSize(),
    zIndex: isDragging ? 1 : 0,
    textWrap: 'wrap',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };

  const title =
    header.column?.columnDef.meta?.short_title?.toString() ||
    header.column?.columnDef.header?.toString();

  const isEditable = header.column.columnDef.meta?.editable;

  return (
    <th
      colSpan={header.colSpan}
      ref={setNodeRef}
      style={style}
      className={classes.columnHead}
    >
      <div className={classes.columnHeadTitle}>
        <Tooltip
          classes={{ tooltip: classes.columnHeadTooltip }}
          title={title}
          placement="top"
        >
          <div
            className={headerClasses.headerContainer}
            {...attributes}
            {...listeners}
          >
            {header.isPlaceholder
              ? null
              : flexRender(
                  header.column.columnDef.meta?.short_title ||
                    header.column.columnDef.header,
                  header.getContext(),
                )}{' '}
          </div>
        </Tooltip>
        <div
          {...{
            onDoubleClick: () => header.column.resetSize(),
            onMouseDown: header.getResizeHandler(),
            onTouchStart: header.getResizeHandler(),
            className: `${table.options.columnResizeDirection} resizer ${
              headerClasses.expander
            } ${
              header.column.getIsResizing()
                ? `${headerClasses.isExpanding}`
                : ''
            }`,
          }}
        />

        <FilterButton header={header} isEditable={!!isEditable}>
          <Box padding={1}>
            {header.column.getCanFilter() ? (
              <FilterColumn column={header.column} />
            ) : null}
          </Box>
        </FilterButton>
      </div>
    </th>
  );
};
