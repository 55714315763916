import React, { ReactElement } from 'react';
import clsx from 'clsx';
import {
  InputBase,
  InputBaseProps,
  Theme,
  Typography,
  TypographyProps,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useTextFieldStyles = makeStyles<Theme, { bottomLabel?: boolean }>(
  (theme) => ({
    root: {
      border: `1px solid ${theme.palette.grey[100]}`,
      borderRadius: 4,
      padding: '0.25rem 0.5rem',
      '&:hover': {
        border: `1px solid ${theme.palette.primary.light}`,
      },
      color: theme.palette.grey[800],
    },
    focused: {
      border: `1px solid ${theme.palette.primary.main} !important`,
    },
    inputContainer: {
      display: 'flex',
      flexDirection: 'column',
    },
    labelRoot: {
      fontSize: (props) => (props.bottomLabel ? '0.75rem' : '0.875rem'),
      marginTop: (props) => (props.bottomLabel ? '0.25rem' : 0),
      lineHeight: 1,
    },
  }),
);

interface InputLabelProps extends TypographyProps {
  bottomLabel?: boolean;
  additionalClasses?: string;
}
export const InputLabel: React.FC<InputLabelProps> = ({
  children,
  bottomLabel,
  additionalClasses,
}) => {
  const classes = useTextFieldStyles({ bottomLabel });
  return (
    <Typography
      className={clsx(classes.labelRoot, additionalClasses)}
      variant="subtitle1"
    >
      {children}
    </Typography>
  );
};

interface Props extends InputBaseProps {
  label?: string;
  message?: {
    text: string;
    messageColor: TypographyProps['color'];
  };
}

// You can make use of the input base props to add icons to the input
function TextInput({ ...props }: Props): ReactElement {
  const classes = useTextFieldStyles({});
  return (
    <div className={classes.inputContainer}>
      {props.label && <InputLabel>{props.label}</InputLabel>}
      <InputBase classes={classes} {...props} />
      {props.message && (
        <InputLabel color={props.message.messageColor} bottomLabel>
          {props.message.text}
        </InputLabel>
      )}
    </div>
  );
}

export default TextInput;
