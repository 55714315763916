import React from 'react';
import CustomTable from '../../../../../tables/CustomTable';
import { CustomColumn } from '../../../../../../types/components/tables/tableTypes';
import GridItem from '../../../../../layout/GridComponents/GridItem';
import { GridProps } from '@mui/material';

export interface CounterpartyTableProps<T extends object> {
  data: T[];
  columns: CustomColumn<T>[];
  xl: GridProps['xl'];
  title: string;
  exportColumns: string[];
}

const CounterpartyTable: <T extends object>(
  p: CounterpartyTableProps<T>,
) => React.ReactElement = (props) => {
  return (
    <GridItem xs={12} xl={props.xl} card>
      <CustomTable
        columns={props.columns}
        data={props.data}
        csvFields={props.exportColumns}
        options={{
          paging: false,
          exportButton: true,
        }}
        toolbarComponents={{
          toolbarTitle: props.title,
        }}
        showToolbar={true}
      />
    </GridItem>
  );
};

export default CounterpartyTable;
