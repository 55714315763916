// https://codesandbox.io/p/sandbox/custom-prompt-of-react-router-v5-lpcsv?file=%2Fsrc%2FApp.js%3A9%2C16-9%2C23
// For reference => RF needed for React Router v6
// Ideally linked with Confirmation hook

import React, { useEffect, useState } from 'react';
import { Prompt } from 'react-router-dom';
import { Location } from 'history';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface RouteLeavingGuardProps {
  navigate: (path: string) => void;
  when: boolean;
  shouldBlockNavigation: (location: Location) => boolean;
  message?: string;
}

const RouteLeavingGuard: React.FC<RouteLeavingGuardProps> = ({
  navigate,
  when,
  shouldBlockNavigation,
  message,
}) => {
  const [modalVisible, updateModalVisible] = useState<boolean>(false);
  const [lastLocation, updateLastLocation] = useState<Location | undefined>();
  const [confirmedNavigation, updateConfirmedNavigation] =
    useState<boolean>(false);

  const showModal = (location: Location) => {
    updateModalVisible(true);
    updateLastLocation(location);
  };

  const closeModal = (cb?: () => void) => {
    updateModalVisible(false);
    if (cb) {
      cb();
    }
  };

  const handleBlockedNavigation = (nextLocation: Location): boolean => {
    if (!confirmedNavigation && shouldBlockNavigation(nextLocation)) {
      showModal(nextLocation);
      return false;
    }
    return true;
  };

  const handleConfirmNavigationClick = () => {
    closeModal(() => {
      if (lastLocation) {
        updateConfirmedNavigation(true);
      }
    });
  };

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      navigate(lastLocation.pathname);
      updateConfirmedNavigation(false);
    }
  }, [confirmedNavigation, lastLocation, navigate]);

  return (
    <>
      <Prompt when={when} message={handleBlockedNavigation} />
      <React.Fragment>
        <Dialog
          open={modalVisible}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => closeModal()}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle>Warning! You are about to leave the page!</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              {message ||
                'Are you sure you want to leave this page? Unsaved changes will be lost?'}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" onClick={handleConfirmNavigationClick}>
              Confirm
            </Button>
            <Button onClick={() => closeModal()}>Cancel</Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    </>
  );
};

export default RouteLeavingGuard;
