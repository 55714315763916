import { useEffect, useReducer } from 'react';
import { DataObject } from '../types/redux/data/dataTypes';
import client from '../utilities/requestClient';

const requestClient = client();
export const useUncachedDataFetch = (query: string | null) => {
  const initialState: DataObject = {
    isFetching: false,
    error: null,
    data: [],
  };

  const [state, dispatch] = useReducer(
    (state: DataObject, action: any): DataObject => {
      switch (action.type) {
        case 'FETCHING':
          return { ...initialState, isFetching: true };
        case 'FETCHED':
          return { ...initialState, isFetching: false, data: action.payload };
        case 'FETCH_ERROR':
          return { ...initialState, isFetching: false, error: action.payload };
        default:
          return state;
      }
    },
    initialState,
  );

  useEffect(() => {
    if (!query) return;

    const fetchData = async () => {
      dispatch({ type: 'FETCHING' });
      try {
        const response = await requestClient.get(query);
        dispatch({ type: 'FETCHED', payload: response.data });
      } catch (error) {
        dispatch({ type: 'FETCH_ERROR', payload: error });
      }
    };

    fetchData();
  }, [query]);

  return state;
};
