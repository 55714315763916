import { FC } from 'react';
import { EquityComponentProps } from '../table/table.component';
import {
  EquityExposureChartData,
  generateEquityBarChart,
  generateEquityBarChartTableColumns,
  generateEquityBarChartYAxes,
} from './barChart.data';
import { useWindowSize } from '../../../../../../hooks/useWindowSize';
import CustomTable from '../../../../../tables/CustomTable';
import { labelTruncator } from '../../../../../selects/GeneralSelect';
import GridItem from '../../../../../layout/GridComponents/GridItem';
import GenericBarChart from '../../../../../charts/GenericBarChart';
import { useTheme } from '@mui/material';

export type EquityBarChartProps = EquityComponentProps;

export const AngledTick = (props: any) => {
  const { x, y, stroke, payload } = props;

  const payloadVal = labelTruncator(payload.value);
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={16}
        fontSize={12}
        textAnchor="end"
        fill="#666"
        transform="rotate(-35)"
      >
        {payloadVal}
      </text>
    </g>
  );
};
interface AngledTickProps {
  fontSize?: number;
}
export const createAngledTick = (inputProps: AngledTickProps) => {
  return (props: any) => {
    const { x, y, stroke, payload } = props;

    const payloadVal = labelTruncator(payload.value);
    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={0}
          dy={16}
          fontSize={inputProps.fontSize || 14}
          textAnchor="end"
          fill="#666"
          transform="rotate(-35)"
        >
          {payloadVal}
        </text>
      </g>
    );
  };
};

const EquityBarChart: FC<EquityBarChartProps> = ({
  inputData,
  selectedEquityPage,
}) => {
  const { height, width } = useWindowSize();

  const chartData = generateEquityBarChart(inputData.data, selectedEquityPage);

  const theme = useTheme();

  return (
    <>
      <GridItem card xs={12} lg={3}>
        {chartData.length && (
          <CustomTable<EquityExposureChartData>
            columns={generateEquityBarChartTableColumns()}
            data={chartData}
            options={{
              paging: false,
              exportButton: true,
              maxBodyHeight: 'calc(100vh - 19.6rem)',
            }}
            csvFields={['name', 'exposure']}
            showToolbar
            toolbarComponents={{
              toolbarTitle: 'Exposure Data',
            }}
            title="Exposure Data"
            id="exposure_data_table"
          />
        )}
      </GridItem>
      <GridItem xs={12} lg={9} card>
        {chartData.length && (
          <GenericBarChart
            margin={{
              top: 0,
              bottom: 112,
              left: 150,
              right: 100,
            }}
            tooltipFormatter={(value: any, name: any) => [
              value + '%',
              name
                .split('Y')
                .map((word: string, index: number) =>
                  index === 0
                    ? word[0].toUpperCase() + word.substring(1)
                    : word,
                )
                .join(' Y'),
            ]}
            title="Sector Data"
            loading={inputData.isFetching}
            height={height - 204}
            width="100%"
            data={chartData}
            xAxes={[
              {
                interval: 0,
                dataKey: 'name',
                tick: AngledTick,

                label: {
                  position: 'bottom',
                  value: 'Asset',
                  offset: 80,
                  style: {
                    fill: theme.palette.grey[700],
                  },
                },
              },
            ]}
            yAxes={generateEquityBarChartYAxes(selectedEquityPage)}
            minTickGap={-100}
            yAxisLabel={{
              value: 'Exposure',
              angle: -90,
              position: 'left',
              style: {
                textAnchor: 'middle',
                fontSize: '1.8rem',
              },
            }}
            bars={
              selectedEquityPage === 'exposure'
                ? [
                    {
                      dataKey: 'exposure',
                      fill: theme.palette.primary.main,
                      key: 'equityExposureKey',
                      yAxisId: 'left',
                    },
                  ]
                : [
                    {
                      dataKey: 'exposure',
                      fill: theme.palette.primary.main,
                      key: 'equityExposureKey',
                      yAxisId: 'left',
                    },
                    {
                      dataKey: 'dividendYield',
                      fill: theme.palette.secondary.main,
                      key: 'equityDividendKey',
                      yAxisId: 'right',
                    },
                  ]
            }
          />
        )}
      </GridItem>
    </>
  );
};

export default EquityBarChart;
