import Cookies from 'js-cookie';
import {
  AUTHENTICATE,
  AUTHENTICATE_WITH_2FA,
  AUTHENTICATION_FAILED,
  AUTHENTICATION_IN_PROGRESS,
  AUTHENTICATION_WITH_2FA_IN_PROGRESS,
  AuthStore,
  AuthenticationAction,
  CANCEL_TWO_FACTOR_AUTHENTICATION_IN_PROGRESS,
  TWO_FACTOR_AUTHENTICATION_FAILED,
  UNAUTHENTICATE,
} from '../../types/redux/auth/authTypes';

export default function authReducer(
  state: AuthStore = {
    user_id: (() => {
      const localId = localStorage.getItem('user_id');
      if (localId) return localId;
      return null;
    })(),
    client_name: (() => {
      const localId = localStorage.getItem('client_name');
      if (localId) return localId;
      return null;
    })(),
    config_name: (() => {
      const localId = localStorage.getItem('config_name');
      if (localId) return localId;
      return null;
    })(),
    isAuthenticated: (() => {
      const local = localStorage.getItem('authenticated');

      let cookie;
      const regex = /^https:\/\/dev2\w*\.risksystem\.com.*/;
      if (regex.test(window.location.href)) {
        cookie = Cookies.get('dev_user_id');
      } else {
        cookie = Cookies.get('user_id');
      }

      if (!cookie) {
        window.localStorage.setItem('authenticated', 'false');
      } else if (local && JSON.parse(local)) {
        return true;
      }
      return false;
    })(),
    authenticationInProgress: false,
    authenticationError: null,
    isQRCodeRequired: false,
    isEmailTokenRequired: false,
    accessToken: null,
    authKey: null,
    qrCodeEmailSent: false,
  },
  action: AuthenticationAction,
): AuthStore {
  switch (action.type) {
    case AUTHENTICATE:
      window.localStorage.setItem('user_id', action.user_id);
      window.localStorage.setItem('client_name', action.client_name);
      window.localStorage.setItem('config_name', action.config_name);
      return {
        user_id: action.user_id,
        client_name: action.client_name,
        config_name: action.config_name,
        isAuthenticated: true,
        authenticationError: null,
        authenticationInProgress: false,
        isQRCodeRequired: false,
        isEmailTokenRequired: false,
        accessToken: null,
        authKey: null,
        qrCodeEmailSent: false,
      };
    case UNAUTHENTICATE:
      return {
        user_id: null,
        client_name: null,
        config_name: null,
        isAuthenticated: false,
        authenticationInProgress: false,
        authenticationError: null,
        isQRCodeRequired: false,
        isEmailTokenRequired: false,
        accessToken: null,
        authKey: null,
        qrCodeEmailSent: false,
      };
    case AUTHENTICATION_FAILED:
      return {
        user_id: null,
        client_name: null,
        config_name: null,
        isAuthenticated: false,
        authenticationInProgress: false,
        authenticationError: action.payload,
        isQRCodeRequired: false,
        isEmailTokenRequired: false,
        accessToken: null,
        authKey: null,
        qrCodeEmailSent: false,
      };
    case TWO_FACTOR_AUTHENTICATION_FAILED:
      return {
        user_id: action.user_id,
        client_name: action.client_name,
        config_name: action.config_name,
        isAuthenticated: false,
        authenticationInProgress: false,
        authenticationError: action.errorMessage,
        isQRCodeRequired: action.qrCodeRequired,
        isEmailTokenRequired: action.emailTokenRequired,
        accessToken: action.accessToken,
        authKey: action.authKey,
        qrCodeEmailSent: false,
      };
    case AUTHENTICATION_IN_PROGRESS:
      return {
        user_id: null,
        client_name: null,
        config_name: null,
        isAuthenticated: false,
        authenticationInProgress: true,
        authenticationError: null,
        isQRCodeRequired: false,
        isEmailTokenRequired: false,
        accessToken: null,
        authKey: null,
        qrCodeEmailSent: false,
      };
    case AUTHENTICATION_WITH_2FA_IN_PROGRESS:
      return {
        user_id: action.user_id,
        client_name: action.client_name,
        config_name: action.config_name,
        isAuthenticated: false,
        authenticationInProgress: true,
        authenticationError: null,
        isQRCodeRequired: action.qrCodeRequired,
        isEmailTokenRequired: action.emailTokenRequired,
        accessToken: action.accessToken,
        authKey: action.authKey,
        qrCodeEmailSent: false,
      };
    case CANCEL_TWO_FACTOR_AUTHENTICATION_IN_PROGRESS:
      return {
        user_id: null,
        client_name: null,
        config_name: null,
        isAuthenticated: null,
        authenticationInProgress: false,
        authenticationError: null,
        isQRCodeRequired: false,
        isEmailTokenRequired: false,
        accessToken: null,
        authKey: null,
        qrCodeEmailSent: false,
      };
    case AUTHENTICATE_WITH_2FA:
      return {
        user_id: action.user_id,
        client_name: action.client_name,
        config_name: action.config_name,
        isAuthenticated: false,
        authenticationInProgress: false,
        authenticationError: null,
        isQRCodeRequired: action.qrCodeRequired,
        isEmailTokenRequired: action.emailTokenRequired,
        accessToken: action.accessToken,
        authKey: action.authKey,
        qrCodeEmailSent: action.emailSent,
      };

    default:
      return state;
  }
}
