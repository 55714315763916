import React, { FC } from 'react';
import GridItem from '../../../layout/GridComponents/GridItem';
import CustomTable from '../../../tables/CustomTable';
import { CustomColumn } from '../../../../types/components/tables/tableTypes';
import { addCommasToNumbersAndRound } from '../../../../utilities/numberFormatters';
import DiscountCashflowOverviewGraph from './DiscountCashflowOverviewGraph.component';

interface DCFOverviewTableProps {
  data: any;
}

interface DCFOverviewTableData {
  asset: string;
  country: string;
  currency: string;
  ebit: number;
  enterpriseValue: number;
  industry: number;
  maturityDate: number;
  presentValuation: number;
  timeseries: any[];
}

const columns: CustomColumn<DCFOverviewTableData>[] = [
  {
    title: 'Asset',
    field: 'asset',
    cellStyle: { textAlign: 'center' },
    headerStyle: { textAlign: 'center' },
    // customSort: (rowData: any) => 1,
  },
  {
    title: 'Country',
    field: 'country',
    cellStyle: { textAlign: 'center' },
    headerStyle: { textAlign: 'center' },
  },
  {
    title: 'Currency',
    field: 'currency',
    cellStyle: { textAlign: 'center' },
    headerStyle: { textAlign: 'center' },
  },
  {
    title: 'Industry',
    field: 'industry',
    cellStyle: { textAlign: 'center' },
    headerStyle: { textAlign: 'center' },
  },
  {
    title: 'Maturity Date',
    field: 'maturityDate',
    cellStyle: { textAlign: 'center' },
    headerStyle: { textAlign: 'center' },
  },
  {
    title: 'EBIT',
    field: 'ebit',
    cellStyle: { textAlign: 'center' },
    headerStyle: { textAlign: 'center' },
    render: (rowData: DCFOverviewTableData) =>
      addCommasToNumbersAndRound(rowData.ebit),
  },
  {
    title: 'Enterprise Value',
    field: 'enterpriseValue',
    cellStyle: { textAlign: 'center' },
    headerStyle: { textAlign: 'center' },
    render: (rowData: DCFOverviewTableData) =>
      addCommasToNumbersAndRound(rowData.enterpriseValue),
  },

  {
    title: 'DCF Value',
    field: 'presentValuation',
    cellStyle: { textAlign: 'center' },
    headerStyle: { textAlign: 'center' },
    render: (rowData: DCFOverviewTableData) =>
      addCommasToNumbersAndRound(rowData.presentValuation),
  },
];

function buildTableData(data: any) {
  if (!data || !data.data || !data.data[0]) return [];
  if (!data.data.length) return [];
  // Get just the data for the selected position.
  const positionData = data.data[0];
  // Get the keys from the data.
  const positionKeys = Object.keys(positionData);
  const tableData: DCFOverviewTableData[] = [];
  for (const key of positionKeys) {
    if (key === 'Portfolio') continue;
    const currentPosition = positionData[key];
    tableData.push({
      asset: currentPosition['Asset'],
      country: currentPosition['Country'],
      currency: currentPosition['Currency'],
      ebit: currentPosition['EBIT'],
      enterpriseValue: currentPosition['Enterprise Value'],
      industry: currentPosition['Industry'],
      maturityDate: currentPosition['Maturity Date'],
      presentValuation: currentPosition['Present Valuation'],
      timeseries: currentPosition.timeseries,
    });
  }
  return tableData;
}

const DiscountCashFlowOverviewPositionsTable: FC<DCFOverviewTableProps> = (
  props,
) => {
  // const theme = useTheme();
  const { data } = props;
  // const tableData = isDefaultScenario ? buildSubscriptionsRedemptionData(data, selectedPosition, percentValueOption, nav) : buildDCFDataForComparison(data, selectedPosition, percentValueOption, nav, defaultData);
  // Allow user to switch between scenrio, change and both
  // const detailColumns = buildDetailColumns(
  //   props.setBarChartFilter,
  //   props.barChartValue,
  //   props.percentValueOption
  // );
  // const pdfColumns = buildDetailColumnsPDF(props.percentValueOption);
  // const nav = getNav(data);
  // const tableData = buildDCFConfigurationData(
  //   data,
  //   selectedPosition,
  //   props.percentValueOption,
  //   nav
  // );
  const tableData = buildTableData(data);

  return (
    <GridItem
      xs={12}
      card
      style={{
        padding: 8,
        alignSelf: 'stretch',
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        // minHeight: 600
      }}

      // cardStyle={{ minHeight: 700 }}
    >
      <CustomTable<DCFOverviewTableData>
        id={'dcf_overview_position_table'}
        title={'Positions Table'}
        columns={columns}
        showToolbar
        data={tableData}
        // toolbarComponents={{
        //     toolbarTitle: formattedNames[tableType],
        // }}
        options={{
          paging: false,
          search: false,
          exportButton: false,
          sorting: false,
          // header: false
        }}
        detailPanel={(rowData: DCFOverviewTableData) => {
          return (
            <DiscountCashflowOverviewGraph
              data={rowData.timeseries}
              selectedPosition={rowData.asset}
            />
          );
        }}
      />
    </GridItem>
  );
};

export default DiscountCashFlowOverviewPositionsTable;
