import React, { CSSProperties, useEffect } from 'react';
import OverviewCard from '../../../cards/OverviewCard';
import { mapStatusToColor } from '../../../../utilities/colorUtilities';
import { useLocation } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import { RaptorTheme, mainColors } from '../../../../styling/theme';
import clsx from 'clsx';
import { Status } from '../../../../types/redux/data/dataTypes';
import {
  activeSectionSelector,
  createSectionByIdSelector,
  currentFundSearchStringSelector,
} from '../../../../redux/pages/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { Select } from '../../../../types/redux/pages/PagesStore';
import useTitleBarSelect from '../../../../hooks/useTitleBarSelect';
import useFetchData from '../../../../hooks/useFetchData';
import { Typography } from '@mui/material';
import { specifyStatus } from '../../../../utilities/generalMappings';
import {
  addAdditionalTitleBarComponents,
  removeTitleBarComponent,
} from '../../../../redux/pages/actions';
import {
  APPEND_NEW_SELECT_TO_TITLEBAR,
  REMOVE_SELECT_FROM_TITLEBAR,
} from '../../../../types/redux/pages/pageTypes';

interface OverallStatusPageProps {
  // this is the key of the status from the overall data
  overviewDataPageKey: string;
  // additional values is if we want to add more info to the status cards
  additionalValues?: any;
  positionDate?: string;
}

const useStyles = makeStyles<RaptorTheme>((theme) => ({
  lowerText: {
    marginBottom: '1rem',
  },
  allText: {
    color: 'white',
  },
  status: { fontSize: '1.8rem', marginBottom: 0 },
  additionalVal: {
    marginTop: '.5rem',
  },
}));

const OverallStatusPage: React.FC<OverallStatusPageProps> = ({
  positionDate,
  overviewDataPageKey,
  ...props
}) => {
  const activeSection = useSelector(activeSectionSelector);
  const sectionDetails = useSelector(
    createSectionByIdSelector(activeSection || ''),
  );
  const fundTypes = sectionDetails?.fundTypes;

  const dispatch = useDispatch();
  const location = useLocation();
  const addedClasses = useStyles();

  const schemas = sectionDetails?.fundTypes.length
    ? `?schemas=${sectionDetails?.fundTypes}`
    : '';
  const overviewData = useFetchData({
    url: `schema_overview_monitor/${positionDate}` + schemas,
    keyName: `${fundTypes}_overview_data_${positionDate}`,
    makeFetch: positionDate ? true : false,
  });

  // Add the select components to the title bar
  useEffect(() => {
    const selectsToAdd: Select[] = [
      {
        titleBarTitle: 'Sort by: ',
        titleBarKey: 'statusSort_' + overviewDataPageKey,
        values: [
          {
            value: 'default',
            label: 'Default',
          },
          {
            value: 'status',
            label: 'Status',
          },
          {
            value: 'name',
            label: 'Name',
          },
        ],
        displayOnOverviewPage: true,
        aValueIsAlwaysSelected: true,
        currentValue: 'default',
      },
    ];

    dispatch(
      addAdditionalTitleBarComponents(
        selectsToAdd,
        APPEND_NEW_SELECT_TO_TITLEBAR,
      ),
    );

    return () => {
      dispatch(
        removeTitleBarComponent(
          'viewConfigurationHistorical',
          REMOVE_SELECT_FROM_TITLEBAR,
        ),
      );
    };
  }, []);

  const selectedSortingKey = useTitleBarSelect(
    'statusSort_' + overviewDataPageKey,
  );

  const currentFundSearchString = useSelector(currentFundSearchStringSelector);

  // hack to make AIF prospectus restrictions work
  if (sectionDetails?.fundTypes.includes('AIF')) {
    overviewDataPageKey = 'risk_compliance_status';
  }

  const Title = ({
    title,
    ...props
  }: {
    title: string;
    style?: CSSProperties;
  }) => (
    <Typography
      style={{ ...props.style }}
      className={addedClasses.allText}
      variant="subtitle2"
    >
      {title}
    </Typography>
  );
  const LowerText = ({ text, ...rest }: { text: string; status?: boolean }) => (
    <Typography
      className={clsx(
        addedClasses.lowerText,
        addedClasses.allText,
        rest.status && addedClasses.status,
      )}
      variant="h3"
    >
      {text}
    </Typography>
  );
  const sortMap: { [key: string]: number } = {
    Pass: 0,
    Alert: 1,
    Fail: 2,
  };
  const sortByStatus = (a: any, b: any) => {
    const aVal: Status = specifyStatus(
      a.monitor_data[overviewDataPageKey],
    ) as Status;
    const bVal: Status = specifyStatus(
      b.monitor_data[overviewDataPageKey],
    ) as Status;
    return sortMap[bVal] - sortMap[aVal];
  };

  const sortByName = (a: any, b: any) => {
    return a.fund_name_full.toUpperCase() < b.fund_name_full.toUpperCase()
      ? -1
      : 1;
  };

  let renderData: any = [];

  if (overviewData && overviewData.data.length) {
    renderData = overviewData.data[0];
  }
  const sortedData = ((sortingKey) => {
    if (!renderData) return [];
    if (sortingKey === 'default') return renderData;
    if (sortingKey === 'status') {
      return renderData.sort(sortByStatus);
    } else if (sortingKey === 'name') {
      return renderData.sort(sortByName);
    }
  })(selectedSortingKey);
  return sortedData?.length ? (
    <>
      {sortedData
        .filter((fund: any) =>
          fund.fund_name_full.includes(currentFundSearchString),
        )
        .map((fund: any) => {
          const status = specifyStatus(fund.monitor_data[overviewDataPageKey]);
          return (
            <OverviewCard
              toVal={location.pathname + '?fundId=' + fund.fund_name}
              key={fund.fund_name + '_overview_card_key'}
              backgroundColor={
                activeSection === 'sif-raif' &&
                overviewDataPageKey === 'Value_at_Risk'
                  ? mainColors.mainBlue
                  : mapStatusToColor(status)
              }
            >
              <div>
                <Title title="name" />
                <LowerText text={fund.fund_name_full} />
              </div>
              {activeSection === 'sif-raif' &&
              overviewDataPageKey === 'Value_at_Risk' ? (
                <></>
              ) : (
                <div>
                  <Title title="Status" />
                  <LowerText text={status} status />
                </div>
              )}
              {props.additionalValues?.map(
                (val: {
                  keyName: string;
                  keyTitle: string;
                  transformerFunc: any;
                }) => (
                  // pass it a key to find, and a transformer function
                  <div key={val.keyName}>
                    <Title style={{ marginTop: '1rem' }} title={val.keyTitle} />
                    <LowerText
                      text={
                        val.transformerFunc
                          ? val.transformerFunc(fund.monitor_data[val.keyName])
                          : fund.monitor_data[val.keyName]
                      }
                      status
                    />
                  </div>
                ),
              )}
            </OverviewCard>
          );
        })}
    </>
  ) : null;
};

export default OverallStatusPage;
