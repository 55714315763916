import React, { FC } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { greys, mainColors } from '../../../../../styling/theme';
import requestClient from '../../../../../utilities/requestClient';
import TokenService from '../../../../services/token-service';
import { BadgeOutlined } from '@mui/icons-material';
import { Modal, Tooltip } from '@mui/material';

interface Props {
  user: any;
  reloadUsers: () => void;
}

const useStyles = makeStyles(() => ({
  deleteUserButton: {
    width: 'fit-content',
    display: 'flex',
    flexDirection: 'row',
    gap: '1rem',
    padding: '0.5rem 1rem',
    borderRadius: '0.4rem',
    fontWeight: 400,
    border: `1px solid ${mainColors.mainBlue}`,
    color: mainColors.mainBlue,
    cursor: 'pointer',
    userSelect: 'none',
    '& svg': {
      fontSize: '1.6rem',
    },
    '&:hover': {
      backgroundColor: mainColors.mainBlue,
      color: 'white',
    },
    '&:active': {
      backgroundColor: mainColors.mainBlue_Darker,
      color: 'white',
    },
  },
  modalBackground: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalContentContainer: {
    backgroundColor: 'white',
    padding: '2rem',
    borderRadius: '0.4rem',
    border: `1px solid ${mainColors.mainBlue}`,
    width: '50rem',
  },
  modalTitle: {
    fontSize: '3rem',
    fontWeight: 600,
    color: mainColors.mainBlue,
  },
  modalContent: {
    fontSize: '2rem',
    fontWeight: 400,
    color: greys.grey700,
    display: 'flex',
    gap: '1rem',
    padding: '1rem 0',
  },
  username: {
    fontWeight: 600,
    color: greys.grey900,
  },
  modalActionContainer: {
    display: 'flex',
    gap: '1rem',
    alignItems: 'center',
    paddingTop: '1rem',
  },
  cancelButton: {
    all: 'unset',
    width: 'fit-content',
    display: 'flex',
    flexDirection: 'row',
    gap: '1rem',
    padding: '0.5rem 2rem',
    borderRadius: '0.4rem',
    fontSize: '2rem',
    fontWeight: 400,
    backgroundColor: mainColors.mainBlue,
    color: 'white',
    cursor: 'pointer',
    userSelect: 'none',
    '&:hover': {
      backgroundColor: mainColors.mainBlue_lighter,
    },
    '&:active': {
      backgroundColor: mainColors.mainBlue_Darker,
    },
  },
  deleteButton: {
    all: 'unset',
    width: 'fit-content',
    display: 'flex',
    flexDirection: 'row',
    gap: '1rem',
    padding: '0.5rem 2rem',
    borderRadius: '0.4rem',
    fontSize: '2rem',
    fontWeight: 400,
    backgroundColor: mainColors.Fail,
    color: 'white',
    cursor: 'pointer',
    userSelect: 'none',
    '&:hover': {
      backgroundColor: mainColors.Fail_darker,
    },
    '&:active': {
      backgroundColor: mainColors.Fail_even_darker,
    },
  },
  requestMessage: {
    fontSize: '1.4rem',
    fontWeight: 500,
    marginTop: '1rem',
  },
}));

const getAuthMessageColor = (authStatus: string): string => {
  switch (authStatus) {
    case 'success':
      return mainColors.Pass;
    case 'fail':
      return mainColors.Fail;
    default:
      return greys.grey600;
  }
};

const UserRoleConfirmation: FC<Props> = ({ user, reloadUsers }) => {
  const classes = useStyles();
  const client = requestClient();

  const [open, setOpen] = React.useState(false);

  const [requestStatus, setRequestStatus] = React.useState<string>('');
  const [requestMessage, setRequestMessage] = React.useState<string>('');

  const handleUpdateRole = () => {
    setRequestStatus('loading');
    setRequestMessage('Sending request...');
    client
      .put(
        `raptor/upgrade_user`,
        {
          user_id: user.user_id,
        },
        {
          headers: {
            Authorization: `Bearer ${TokenService.getLocalAccessToken()}`,
          },
        },
      )
      .then(() => {
        setRequestStatus('success');
        setRequestMessage('Updated Successfully.');
        reloadUsers();
        setTimeout(() => {
          setRequestStatus('idle');
          setRequestMessage('');
        }, 5000);
      })
      .catch(() => {
        setRequestStatus('fail');
        setRequestMessage('Unknown Error. Please contact RiskSystem support.');
      });
  };

  return (
    <>
      <Tooltip title="Upgrade To Administrator">
        <div onClick={() => setOpen(true)} className={classes.deleteUserButton}>
          <BadgeOutlined />
        </div>
      </Tooltip>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        className={classes.modalBackground}
      >
        <div className={classes.modalContentContainer}>
          <div className={classes.modalTitle}>Upgrade to administrator</div>
          <div className={classes.modalContent}>
            <div>User: </div>
            <div className={classes.username}>{user.user_name}</div>
          </div>
          <div className={classes.modalActionContainer}>
            <div
              className={classes.cancelButton}
              onClick={() => setOpen(false)}
            >
              Cancel
            </div>
            <div
              className={classes.deleteButton}
              onClick={() => handleUpdateRole()}
            >
              Upgrade User
            </div>
          </div>
          <div
            className={classes.requestMessage}
            style={{
              color: getAuthMessageColor(requestStatus),
            }}
          >
            {requestMessage}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default UserRoleConfirmation;
