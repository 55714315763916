import { GeneralOverviewPageRenderedComponentProps } from '../../../../types/components/componentTypes';
import React from 'react';
import EsgOverviewTable from './components/EsgOverviewTable.component';
import useFetchData from '../../../../hooks/useFetchData';
import GeneralComponentErrorShield from '../../../general/GeneralComponentErrorShield';
import { useSelector } from 'react-redux';
import { createSectionByIdSelector } from '../../../../redux/pages/selectors';
import { clientNameSelector } from '../../../../redux/auth/selectors';

const EsgOverview: React.FC<GeneralOverviewPageRenderedComponentProps> = ({
  positionDate,
  section,
}) => {
  const sectionDetails = useSelector(createSectionByIdSelector(section));
  const clientName = useSelector(clientNameSelector);
  const fundTypes = sectionDetails?.fundTypes;

  const esgOverviewData = useFetchData({
    url: `get_fund_esg_monitor_overview_data/${positionDate}`,
    keyName: `get_fund_esg_monitor_overview_data_${positionDate}`,
    makeFetch: positionDate ? true : false,
  });

  const schemas = sectionDetails?.fundTypes.length
    ? `?schemas=${sectionDetails?.fundTypes}`
    : '';
  const esgReportsList = useFetchData({
    url: 'reports_list/esg_report' + schemas,
    keyName: `${fundTypes}_esg_risk_report`,
    makeFetch: clientName?.includes('adepa') || false,
  });

  return (
    <GeneralComponentErrorShield
      dataObjects={[esgOverviewData]}
      customLoadingMessages={['Loading ESG Data...']}
      customErrorMessage={'No ESG Data Found'}
    >
      <EsgOverviewTable
        data={esgOverviewData}
        esgReportsList={esgReportsList}
      />
    </GeneralComponentErrorShield>
  );
};

export default EsgOverview;
