import React, { FC } from 'react';
import { CustomColumn } from '../../../../../../types/components/tables/tableTypes';
import { addCommasToNumbersAndRound } from '../../../../../../utilities/numberFormatters';
import CustomTable from '../../../../../tables/CustomTable';
import RaptorStatusBox from '../../../../../feedback/RaptorStatusBox.component';
import { greys } from '../../../../../../styling/theme';
import PositionsTableWrapper from '../positionsTable/PositionsTableWrapper.component';
import { specifyStatus } from '../../../../../../utilities/generalMappings';

interface ShareClassTableProps {
  fundId: string;
  data: any[];
}

export interface ShareClassTableData {
  exposureHedgeType: string;
  hedgeExposureName: string;
  positionDate: string;
  lastUpdatingDatetime: string;
  shareClassId: string;
  aggregateHedgeExposure: number;
  toHedgeExposure: number;
  exposureHedgeRatio: string;
  exposureHedgeStatus: string;
  exposureHedgeStatusType: string;
  hedge_positions_ids: string[];
}

export function buildShareClassHedgeSecondLevelTableData(
  data: any,
): ShareClassTableData[] {
  const tableData: ShareClassTableData[] = [];
  data.forEach((value: any) => {
    tableData.push({
      exposureHedgeType: value.exposure_hedge_type,
      hedgeExposureName: value.hedge_exposure_name,
      positionDate: value.position_date,
      lastUpdatingDatetime: value.last_updating_datetime,
      shareClassId: value.share_class_id,
      aggregateHedgeExposure: value.aggregate_hedge_exposure,
      toHedgeExposure: value.to_hedge_exposure,
      exposureHedgeRatio: value.exposure_hedge_ratio,
      exposureHedgeStatus: value.exposure_hedge_status,
      exposureHedgeStatusType: value.exposure_hedge_status_type,
      hedge_positions_ids: value.hedge_positions_ids,
    });
  });
  return tableData;
}

const columns: CustomColumn<ShareClassTableData>[] = [
  {
    title: 'Exposure Hedge Type',
    field: 'exposureHedgeType',
    cellStyle: { textAlign: 'center' },
    headerStyle: { textAlign: 'center' },
  },
  {
    title: 'Hedge Exposure Name',
    field: 'hedgeExposureName',
    cellStyle: { textAlign: 'center' },
    headerStyle: { textAlign: 'center' },
  },
  {
    title: 'Position Date',
    field: 'positionDate',
    cellStyle: { textAlign: 'center' },
    headerStyle: { textAlign: 'center' },
  },
  {
    title: 'Latest Update Timestamp',
    field: 'lastUpdatingDatetime',
    cellStyle: { textAlign: 'center' },
    headerStyle: { textAlign: 'center' },
  },
  {
    title: 'Share Class ID',
    field: 'shareClassId',
    cellStyle: { textAlign: 'center' },
    headerStyle: { textAlign: 'center' },
  },
  {
    title: 'Aggregate Hedge Exposure',
    field: 'aggregateHedgeExposure',
    cellStyle: { textAlign: 'center' },
    headerStyle: { textAlign: 'center' },
    render: (rowData: ShareClassTableData) =>
      addCommasToNumbersAndRound(rowData.aggregateHedgeExposure),
  },
  {
    title: 'To Hedge Exposure',
    field: 'toHedgeExposure',
    cellStyle: { textAlign: 'center' },
    headerStyle: { textAlign: 'center' },
    render: (rowData: ShareClassTableData) =>
      addCommasToNumbersAndRound(rowData.toHedgeExposure),
  },
  {
    title: 'Exposure Hedge Ratio',
    field: 'exposureHedgeRatio',
    cellStyle: { textAlign: 'center' },
    headerStyle: { textAlign: 'center' },
  },
  {
    title: 'Exposure Hedge Status',
    field: 'exposureHedgeStatus',
    cellStyle: { textAlign: 'center' },
    headerStyle: { textAlign: 'center' },
    render: (rowData: ShareClassTableData) => (
      <RaptorStatusBox status={specifyStatus(rowData.exposureHedgeStatus)} />
    ),
    pdfRenderType: 'PageLinkButton',
  },
  {
    title: 'Exposure Hedge Status Type',
    field: 'exposureHedgeStatusType',
    cellStyle: { textAlign: 'center' },
    headerStyle: { textAlign: 'center' },
  },
];

const ShareClassTable: FC<ShareClassTableProps> = ({ fundId, data }) => {
  const tableData = buildShareClassHedgeSecondLevelTableData(data);

  return (
    <div
      style={{
        border: `10px solid ${greys.grey100}`,
      }}
    >
      <CustomTable<ShareClassTableData>
        options={{
          paging: false,
          search: true,
          exportButton: true,
        }}
        columns={columns}
        data={tableData}
        showToolbar={true}
        detailPanel={PositionsTableWrapper(fundId)}
      />
    </div>
  );
};

export default ShareClassTable;
