import { FC, useState } from 'react';
import { CustomColumn } from '../../../../../types/components/tables/tableTypes';
import { percentageToTwoDecimalPlaces } from '../../../../../utilities/numberFormatters';
import CustomTable from '../../../../tables/CustomTable';
import { RaptorTheme, greys } from '../../../../../styling/theme';
import { useWindowSize } from '../../../../../hooks/useWindowSize';
import EnvironmentalChart from './EnvironmentalChart';
import SocialChart from './SocialChart';
import CorporateGovernanceChart from './CorporateGovernanceChart';
import ESGChart from './ESGChart';
import makeStyles from '@mui/styles/makeStyles';
import { Typography } from '@mui/material';

const useStyles = makeStyles<RaptorTheme>((theme) => ({
  summaryTable: {
    marginBottom: '2rem',
  },
  exposureCharts: {
    marginBottom: '2rem',
    marginTop: '2rem',
    display: 'flex',
    justifyContent: 'space-around',
  },
  individualChart: {
    marginBottom: '1rem',
    height: '20rem',
    width: '20rem',
    justifyContent: 'center',
  },
  individualChartSocial: {
    marginBottom: '1rem',
    height: '20rem',
    width: '31.5rem',
  },
  individualChartCorporate: {
    marginBottom: '1rem',
    marginLeft: '4rem',
    height: '20rem',
    width: '20rem',
    justifyContent: 'center',
  },
  pieChartContainer: {
    display: 'flex',
    width: '35%',
  },
  tableContainer: {
    width: '100%',
    marginTop: '2rem',
  },
  outerDiv: {
    display: 'flex',
  },
  pickerRoot: {
    backgroundColor: 'white',
    borderRadius: 8,
    boxShadow: theme.shadows[5],
    marginRight: '1.2rem',
    height: '5rem',
    width: '100%',
    marginBottom: '1.2rem',
  },
  selectRoot: {
    width: '100%',
  },
  generalSelectContainer: {
    float: 'right',
    width: '15%',
  },
}));

export interface IEsgExposureData {
  name: string;
  exposure: string | number;
  isin: string;
  environment: number | string;
  social: number | string;
  corporateGovernance: number | string;
  esg: number | string;
}

export interface IEsgWorstPositionsData {
  name: string;
  isin: string;
  environmental: number;
  social: number;
  corporateGovernance: number;
  esg: number;
}

export interface EsgExposurePanelProps {
  data: any;
  title: string;
}

function buildEsgExposureData(esgData: any): any {
  // Data is already formatted, just need to add gross and net as seprate values and add names

  const esgExposureData = [
    {
      esg: esgData.gross.esg,
      environmental: esgData.gross.e,
      social: esgData.gross.s,
      corporateGovernance: esgData.gross.g,
    },
  ];
  return esgExposureData;
}

function buildEsgExposureDetailColumns(): CustomColumn<IEsgExposureData>[] {
  const detailColumns: CustomColumn<IEsgExposureData>[] = [
    {
      title: 'Environmental',
      field: 'environmental',
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: { textAlign: 'center' },
    },
    {
      title: 'Social',
      field: 'social',
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: { textAlign: 'center' },
    },
    {
      title: 'Corporate Governance',
      field: 'corporateGovernance',
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: { textAlign: 'center' },
    },
    {
      title: 'ESG',
      field: 'esg',
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: { textAlign: 'center' },
    },
  ];
  return detailColumns;
}
function buildWorstPositionColumns(
  filterFunction: (value: any) => any,
  scoreType: string,
): CustomColumn<IEsgExposureData>[] {
  const detailColumns: CustomColumn<IEsgExposureData>[] = [
    {
      title: 'Issuer',
      field: 'name',
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: { textAlign: 'center' },
      customSort: () => {
        return 1;
      },
    },
    {
      title: 'ISIN',
      field: 'isin',
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: { textAlign: 'center' },
      customSort: () => {
        return 1;
      },
    },
    {
      title: 'Exposure',
      field: 'exposure',
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: { textAlign: 'center' },
      customSort: () => {
        return 1;
      },
    },
    {
      title: 'Environmental',
      field: 'environment',
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle:
        scoreType === 'Environmental'
          ? {
              textAlign: 'center',
              backgroundColor: greys.grey100,
              borderRadius: '10px',
              textDecoration: 'underline',
            }
          : { textAlign: 'center' },
      customSort: (a, b) => {
        filterFunction('Environmental');
        return a.environment > b.environment ? -1 : 1;
      },
      render: (rowData) => {
        if (typeof rowData.environment === 'number') {
          return rowData.environment < 0 ? '' : rowData.environment;
        } else {
          return rowData.environment;
        }
      },
    },
    {
      title: 'Social',
      field: 'social',
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle:
        scoreType === 'Social'
          ? {
              textAlign: 'center',
              backgroundColor: greys.grey100,
              borderRadius: '10px',
              textDecoration: 'underline',
            }
          : { textAlign: 'center' },
      customSort: (a, b) => {
        filterFunction('Social');
        return a.social > b.social ? -1 : 1;
      },
      render: (rowData) => {
        if (typeof rowData.social === 'number') {
          return rowData.social < 0 ? '' : rowData.social;
        } else {
          return rowData.social;
        }
      },
    },
    {
      title: 'Corporate Governance',
      field: 'corporateGovernance',
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle:
        scoreType === 'Corporate Governance'
          ? {
              textAlign: 'center',
              backgroundColor: greys.grey100,
              borderRadius: '10px',
              textDecoration: 'underline',
            }
          : { textAlign: 'center' },
      customSort: (a, b) => {
        filterFunction('Corporate Governance');
        return a.corporateGovernance > b.corporateGovernance ? -1 : 1;
      },
      render: (rowData) => {
        if (typeof rowData.corporateGovernance === 'number') {
          return rowData.corporateGovernance < 0
            ? ''
            : rowData.corporateGovernance;
        } else {
          return rowData.corporateGovernance;
        }
      },
    },
    {
      title: 'ESG',
      field: 'esg',
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle:
        scoreType === 'ESG'
          ? {
              textAlign: 'center',
              backgroundColor: greys.grey100,
              borderRadius: '10px',
              textDecoration: 'underline',
            }
          : { textAlign: 'center' },
      customSort: (a, b) => {
        filterFunction('ESG');
        return a.esg > b.esg ? -1 : 1;
      },
      render: (rowData) => {
        if (typeof rowData.esg === 'number') {
          return rowData.esg < 0 ? '' : rowData.esg;
        } else {
          return rowData.esg;
        }
      },
    },
  ];
  return detailColumns;
}

function buildEsgPositionsData(esgData: any): IEsgExposureData[] {
  if (!esgData) return [];
  if (!('positions' in esgData)) return [];

  const posititionsBreakdown = esgData.positions;

  const positionsData: IEsgExposureData[] = [];

  posititionsBreakdown.forEach((value: any) => {
    if (Object.keys(value.esg_scores).length) {
      positionsData.push({
        name: value.gm_name_long,
        isin: value.isin,
        exposure: percentageToTwoDecimalPlaces(value.exposure),
        environment: value.esg_scores.e,
        social: value.esg_scores.s,
        corporateGovernance: value.esg_scores.g,
        esg: value.esg_scores.esg,
      });
    }
  });

  return positionsData;
}

function getPageSizeOptions(numRows: number) {
  if (numRows > 50) {
    return [5, 10, 20, 50, numRows];
  } else if (numRows > 20) {
    return [5, 10, 20, numRows];
  } else if (numRows > 10) {
    return [5, 10, numRows];
  } else if (numRows > 5) {
    return [5, numRows];
  } else {
    return [numRows];
  }
}
const EsgPortfolioScoresTable: FC<EsgExposurePanelProps> = (props) => {
  // const theme = useTheme();
  const { data, title } = props;

  const classes = useStyles();
  const { width, height } = useWindowSize();
  const environmentalScore =
    'portfolio_scores' in data ? data.portfolio_scores.gross.e : 0;
  const socialScore =
    'portfolio_scores' in data ? data.portfolio_scores.gross.s : 0;
  const corporateGovernanceScore =
    'portfolio_scores' in data ? data.portfolio_scores.gross.g : 0;
  const esgScore =
    'portfolio_scores' in data ? data.portfolio_scores.gross.esg : 0;

  const [scoreType, setScoreType] = useState('ESG');

  const tableData = buildEsgPositionsData(data);
  tableData.sort((a: any, b: any) => (a.esg > b.esg ? -1 : 1));

  // Set up the select for chosing net or gross exposure
  const setSelected = (value: any) => {
    setScoreType(value);
  };
  const worstPositionColumns = buildWorstPositionColumns(
    setSelected,
    scoreType,
  );

  return (
    // <div>Im esg page</div>
    <div>
      <Typography
        variant="h1"
        style={{
          fontSize: '2.4rem',
          width: '100%',
          fontWeight: 500,
          textAlign: 'left',
        }}
      >
        {title}
      </Typography>
      <div className={classes.exposureCharts}>
        <div>
          <Typography
            variant="h1"
            align="center"
            style={{ marginBottom: '1rem' }}
          >
            Environmental
          </Typography>
          <div className={classes.individualChart}>
            <EnvironmentalChart value={environmentalScore} />
          </div>
          <Typography variant="h2" align="center">
            {environmentalScore}
          </Typography>
          <Typography variant="h2" align="center" style={{ opacity: 0.3 }}>
            / 100.00
          </Typography>
        </div>
        <div>
          <Typography
            variant="h1"
            align="center"
            style={{ marginBottom: '1rem' }}
          >
            Social
          </Typography>
          <div className={classes.individualChartSocial}>
            <SocialChart value={socialScore} />
          </div>
          <Typography variant="h2" align="center">
            {socialScore}
          </Typography>
          <Typography variant="h2" align="center" style={{ opacity: 0.3 }}>
            / 100.00
          </Typography>
        </div>
        <div>
          <Typography
            variant="h1"
            align="center"
            style={{ marginBottom: '1rem' }}
          >
            Corporate Governance
          </Typography>
          <div className={classes.individualChartCorporate}>
            <CorporateGovernanceChart value={corporateGovernanceScore} />
          </div>
          <Typography variant="h2" align="center">
            {corporateGovernanceScore}
          </Typography>
          <Typography variant="h2" align="center" style={{ opacity: 0.3 }}>
            / 100.00
          </Typography>
        </div>
        <div>
          <Typography
            variant="h1"
            align="center"
            style={{ marginBottom: '1rem' }}
          >
            ESG
          </Typography>
          <div className={classes.individualChart}>
            <ESGChart value={esgScore} />
            <Typography variant="h2" align="center">
              {esgScore}
            </Typography>
            <Typography variant="h2" align="center" style={{ opacity: 0.3 }}>
              / 100.00
            </Typography>
          </div>
        </div>
      </div>
      <div className={classes.outerDiv}>
        <div className={classes.tableContainer}>
          <CustomTable<IEsgExposureData>
            columns={worstPositionColumns}
            showToolbar={true}
            data={tableData}
            options={{
              paging: tableData.length > 5,
              search: false,
              exportButton: true,
              sorting: true,
              draggable: false,
              pageSize: 5,
              pageSizeOptions: getPageSizeOptions(tableData.length),
            }}
            title={`Portfolio Positions by ${scoreType} score`}
          />
        </div>
      </div>
    </div>
  );
};

export default EsgPortfolioScoresTable;
