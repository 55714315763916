import React, { FC } from 'react';
import RaptorSelect from '../../../../selects/RaptorSelect.component';
import makeStyles from '@mui/styles/makeStyles';
import { greys, mainColors } from '../../../../../styling/theme';
import { hexToRGBA } from '../../../../../utilities/colorUtilities';
import { Tooltip } from '@mui/material';

interface PriipsKidsFieldTypeSelectProps {
  initialType: string;
  // setPriipsKidsContent: Dispatch<any>;
  updateFieldType: (
    index: number,
    newFieldType: string,
    defualtText: string,
  ) => void;
  index: number;
}

const useStyles = makeStyles(() => ({
  selectContainer: {
    width: '100%',
    minWidth: '10rem',
    marginLeft: '10rem',
  },
  arrow: {
    '&:before': {
      border: '1px solid #8e8e8e',
    },
    color: greys.grey600,
  },
  tooltip: {
    backgroundColor: greys.grey600,
    border: '1px solid #8e8e8e',
    color: 'white',
  },
}));
type field =
  | 'Text Full Width'
  | 'Text Column'
  | 'Section Header'
  | 'Page Break';
const fieldTypes: string[] = [
  'Text Full Width',
  'Text Column',
  'Section Header',
  // "SRI Chart",
  // "Performance Scenarios Table",
  // "Costs Over Time Table",
  // "Composition of Costs Table",
  'Page Break',
];

const highlightColors: { [key in field]: string } = {
  'Text Full Width': mainColors.textFullWidth,
  'Text Column': mainColors.textColumn,
  'Section Header': mainColors.sectionHeader,
  // "SRI Chart": mainColors.sriChart,
  // "Performance Scenarios Table": mainColors.performanceScenariosTable,
  // "Costs Over Time Table": mainColors.costsOverTimeTable,
  // "Composition of Costs Table": mainColors.compositionOfCostsTable,
  'Page Break': mainColors.pageBreak,
};

const fieldTypeLookup: { [key in field]: string } = {
  'Text Full Width': 'text_full',
  'Text Column': 'text_col',
  'Section Header': 'section_header',
  // 'SRI Chart': 'srri_chart',
  // 'Performance Scenarios Table': 'table_style4',
  // 'Costs Over Time Table': 'table_style5',
  // 'Composition of Costs Table': 'table_style6',
  'Page Break': 'page_break',
};
const reverseFieldTypeLookup: { [key: string]: field } = {
  text_full: 'Text Full Width',
  text_col: 'Text Column',
  section_header: 'Section Header',
  // 'srri_chart': 'SRI Chart',
  // 'table_style4': 'Performance Scenarios Table',
  // 'table_style5': 'Costs Over Time Table',
  // 'table_style6': 'Composition of Costs Table',
  page_break: 'Page Break',
};
const defaultText: { [key in field]: string } = {
  'Text Full Width': '',
  'Text Column': '',
  'Section Header': '',
  // 'SRI Chart': '1',
  // 'Performance Scenarios Table': '[["","", ""], ["","",""], ["","",""],["","",""], ["", "",  ""],["","",""], ["", "",""],["","",""]]',
  // 'Costs Over Time Table': '[["","",""],["","",""]]',
  // 'Composition of Costs Table': '[["", "The impact of the costs you pay when entering your investment."],["","The impact of the costs of exiting your investment when it matures."],["", "The impact of the costs of us buying and selling underlying investments for the product."],["","The impact of the costs that we take each year for managing your investments." ],["","The impact of the performance fee. We take these from your investment if the product outperforms its high water mark, of (a) the highest previous net asset value per share of the series concerned at the end of a performance period; and (b) the initial issue price of the shares of that series."],["","The impact of carried interests. We do not charge carried interests to the Company."]]',
  'Page Break': '',
};

const PriipsKidsFieldTypeSelect: FC<PriipsKidsFieldTypeSelectProps> = (
  props,
) => {
  const { initialType, updateFieldType, index } = props;
  const [currentType, setCurrentType] = React.useState<string>(
    reverseFieldTypeLookup[initialType],
  );

  const classes = useStyles();
  function handleChoice(choice: string) {
    updateFieldType(
      index,
      fieldTypeLookup[choice as field],
      defaultText[choice as field],
    );
    setCurrentType(choice);
  }

  return (
    <Tooltip
      classes={{ arrow: classes.arrow, tooltip: classes.tooltip }}
      title="Change the field type. This will change the way the field is displayed in the document. Please refresh the document on the right to view changes before saving."
      placement={'top'}
      arrow
      enterNextDelay={2000}
      enterDelay={2000}
    >
      <div className={classes.selectContainer}>
        <RaptorSelect
          options={fieldTypes}
          handleChoice={handleChoice}
          // defaultOption={initialType}
          defaultOption={reverseFieldTypeLookup[initialType]}
          textColor="white"
          // backgroundColor={mainColors.mainBlue}
          // hoverColor={mainColors.mainBlue}
          backgroundColor={highlightColors[currentType as field]}
          hoverColor={hexToRGBA(highlightColors[currentType as field], 0.8)}
        />
      </div>
    </Tooltip>
  );
};

export default PriipsKidsFieldTypeSelect;
