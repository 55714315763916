import React, { useEffect } from 'react';
import GeneralComponentErrorShield from '../../../general/GeneralComponentErrorShield';
import useFetchData from '../../../../hooks/useFetchData';
import { GeneralOverviewPageRenderedComponentProps } from '../../../../types/components/componentTypes';
import useTitleBarInput from '../../../../hooks/useTitleBarInput';
import PositionsOversightTable from './subComponents/PositionsOversightTable.component';
import NoDataMessage from '../../../feedback/NoDataMessage.component';
import { Grid } from '@mui/material';
import { useDispatch } from 'react-redux';
import { Input } from '../../../../types/redux/pages/PagesStore';
import {
  addAdditionalTitleBarComponents,
  removeTitleBarComponent,
} from '../../../../redux/pages/actions';
import {
  APPEND_NEW_INPUT_TO_TITLEBAR,
  REMOVE_INPUT_FROM_TITLEBAR,
} from '../../../../types/redux/pages/pageTypes';

const PositionOversight: React.FC<
  GeneralOverviewPageRenderedComponentProps
> = ({ positionDate }) => {
  const dispatch = useDispatch();

  // Add the input components to the title bar
  useEffect(() => {
    const inputsToAdd: Input[] = [
      {
        displayOnOverviewPage: true,
        titleBarKey: 'lookbackDays',
        titleBarTitle: 'Lookback Days: ',
        currentValue: '1',
        defaultValue: '1',
        allowedValues: [...Array(1000).keys()].map((i) => String(i + 1)),
      },
    ];

    dispatch(
      addAdditionalTitleBarComponents(
        inputsToAdd,
        APPEND_NEW_INPUT_TO_TITLEBAR,
      ),
    );

    return () => {
      dispatch(
        removeTitleBarComponent('lookbackDays', REMOVE_INPUT_FROM_TITLEBAR),
      );
    };
  }, []);

  const lookbackDays = useTitleBarInput('lookbackDays');

  const mancoPositionComparisonData = useFetchData({
    url: `/manco_position_comparison/${lookbackDays}/${
      positionDate ? positionDate : ''
    }`,
    keyName: `manco_position_comparison_${lookbackDays}_${
      positionDate ? positionDate : ''
    }`,
    makeFetch: lookbackDays !== undefined,
  });

  return (
    <GeneralComponentErrorShield
      dataObjects={[mancoPositionComparisonData]}
      customLoadingMessages={[
        'Loading Positions...',
        'This May Take A Few Moments',
      ]}
      customErrorMessage="No Positions Found"
    >
      {mancoPositionComparisonData &&
      mancoPositionComparisonData.data &&
      mancoPositionComparisonData.data[0] &&
      mancoPositionComparisonData.data[0].length ? (
        <Grid container spacing={2} style={{ padding: 8 }}>
          <PositionsOversightTable
            originalData={mancoPositionComparisonData}
            positionDate={positionDate ? positionDate : ''}
          />
        </Grid>
      ) : (
        <NoDataMessage message="No Positions Found" />
      )}
    </GeneralComponentErrorShield>
  );
};

export default PositionOversight;
