import { Dispatch, FC, useState } from 'react';
import {
  RaptorTheme,
  greys,
  mainColors,
} from '../../../../../../styling/theme';
import makeStyles from '@mui/styles/makeStyles';
import Button from '@mui/material/Button';
import DifferenceIcon from '@mui/icons-material/Difference';
import GeneralSelect from '../../../../../selects/GeneralSelect';
import { Tooltip } from '@mui/material';

const useStyles = makeStyles((theme: RaptorTheme) => ({
  selectContainer: {
    width: '40rem',
    // maxHeight: '15rem',
    // minHeight: '1rem',
    height: '5rem',
    // marginRight: '3rem'
    zIndex: 10,
    // overflowY: 'auto'
  },
  addCommentButton: {
    display: 'flex',
    justifyContent: 'space-around',
    marginBottom: '0.5rem',
  },
  commentsContainer: {
    display: 'flex',
    marginBottom: '0.5rem',
  },
  arrow: {
    '&:before': {
      border: '1px solid black',
    },
    color: 'white',
    zIndex: 10,
  },
  topOfCommentsBox: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  userNameContainer: {
    marginRight: '2rem',
  },
  tooltip: {
    // backgroundColor: mainColors.mainBlue_lightAlt,
    backgroundColor: 'white',
    border: '1px solid black',
    color: 'black',
    maxWidth: 'none',
    pointerEvents: 'auto',
    zIndex: 10,
  },
  commentBox: {
    width: '50%',
    height: '2.5rem',
  },
  userNameBox: {
    width: '100%',
    height: '2.5rem',
  },
  fieldName: {
    fontSize: '1.5rem',
    fontWeight: 500,
    color: mainColors.mainBlue,
    display: 'flex',
    marginTop: '0.5rem',
  },
  fieldWithLabel: {
    display: 'flex',
  },
  commentDisplayBox: {
    width: '95%',
    border: `1px solid ${mainColors.controlButtonBlue}`,
    borderRadius: '5px',
    // marginLeft: '3rem',
    justifyContent: 'center',
  },
  commentDisplay: {
    marginLeft: '2rem',
    fontSize: '1.5rem',
    color: mainColors.mainBlue,
  },
  timestampDisplay: {
    marginLeft: '2rem',
  },
  noCommentsMessage: {
    fontSize: '1.5rem',
    marginLeft: '2rem',
    color: mainColors.mainBlue,
  },
  pickerRoot: {
    backgroundColor: 'white',
    borderRadius: 8,
    // boxShadow: theme.shadows[5],
    marginRight: '1.2rem',
    height: '5rem',
  },
}));

interface TrackedChangesSelectorProps {
  fundId: string | null;
  shareClass: string;
  versionNumbers: string[];
}

// const TrackedChangesSelector: FC<TrackedChangesSelectorProps> = (props) => {
//     const { fundId, shareClass, versions } = props;
//     const classes = useStyles();

//     return (

//     )
// }

interface TrackedChangesButtonProps {
  fundId: string | null;
  shareClass: string;
  fundName: string;
  lasteEditedDate: string;
  documentLanguage: string;
  setViewTrackedChanges: Dispatch<any>;
  setSelectedKiid: Dispatch<any>;
  setTrackedChangesVersions: Dispatch<any>;
  versionNumbers: string[];
  initialComparisonVersion: string;
  initialTargetVersion: string;
}

const TrackedChangesButton: FC<TrackedChangesButtonProps> = ({
  fundId,
  shareClass,
  fundName,
  lasteEditedDate,
  documentLanguage,
  setViewTrackedChanges,
  setSelectedKiid,
  setTrackedChangesVersions,
  versionNumbers,
  initialComparisonVersion,
  initialTargetVersion,
}) => {
  const classes = useStyles();

  const soretedVersionNumbers = versionNumbers.sort();
  const [targetVersion, setTargetVersion] = useState(initialTargetVersion);
  const [comparisonVersion, setComparisonVersion] = useState(
    initialComparisonVersion,
  );

  return (
    <div
      style={{
        display: 'flex',
        border: '1px solid black',
        borderRadius: '10px',
        padding: '5px',
      }}
    >
      <GeneralSelect
        labels={soretedVersionNumbers}
        values={soretedVersionNumbers}
        selected={comparisonVersion}
        setSelected={setComparisonVersion}
        placeholderValue="None Selected"
        placeholderColor={greys.grey900}
        selectTitle={'Comparison (Older)'}
        customStyles={{
          pickerRoot: classes.pickerRoot,
        }}
      />
      <GeneralSelect
        labels={soretedVersionNumbers}
        values={soretedVersionNumbers}
        selected={targetVersion}
        setSelected={setTargetVersion}
        placeholderValue="None Selected"
        placeholderColor={greys.grey900}
        selectTitle={'Target (Newer)'}
        customStyles={{
          pickerRoot: classes.pickerRoot,
        }}
      />
      <Tooltip
        classes={{ arrow: classes.arrow, tooltip: classes.tooltip }}
        title="Please choose two different version numbers to compare changes."
        placement="top"
        arrow
        disableHoverListener={targetVersion !== comparisonVersion}
      >
        <span>
          <Button
            variant="contained"
            // onClick={() => {
            //   return;
            // }}
            onClick={() => {
              setViewTrackedChanges(true);
              setSelectedKiid({
                fundId: fundId,
                isin: shareClass,
                fundName: fundName,
                lasteEditedDate: lasteEditedDate,
                version: targetVersion,
                documentLanguage: documentLanguage,
              });
              setTrackedChangesVersions({
                target: targetVersion,
                comparison: comparisonVersion,
              });
            }}
            startIcon={<DifferenceIcon />}
            disabled={targetVersion === comparisonVersion}
            style={{
              top: '25%',
              // position: 'absolute'
            }}
          >
            Run
          </Button>
        </span>
      </Tooltip>
    </div>
  );
};

export default TrackedChangesButton;
