import React from 'react';
import GeneralComponentErrorShield from '../../../general/GeneralComponentErrorShield';
import useFetchData from '../../../../hooks/useFetchData';
import { GeneralOverviewPageRenderedComponentProps } from '../../../../types/components/componentTypes';
import LiquidationTimeTable from './subComponents/LiquidationTimeTable.component';
import { useDispatch } from 'react-redux';
import { Grid } from '@mui/material';

// const defaultDropDownValues = [
//   { label: 'BAU', value: 'BAU' },
//   { label: 'Stressed', value: 'Stressed' },
//   { label: 'Super-Stressed', value: 'Super-Stressed' },
//   // { label: 'EU Sov Debt Crisis Jul11', value: 'euSovDebtCrisisJul11' },
//   // { label: 'Credit Crisis Sept08', value: 'creditCrisisSept2008' },
// ];

const LiquidationTime: React.FC<GeneralOverviewPageRenderedComponentProps> = (
  props,
) => {
  const dispatch = useDispatch();

  // const [scenariosOptions, setScenariosOptions] = useState<any>(null);
  // const [stressLevel, setStressLevel] = useState<any>(null);

  // useEffect(() => {
  //   setScenariosOptions(defaultDropDownValues);
  // });

  // useEffect(() => {
  //   if (scenariosOptions) {
  //     const selectsToAdd: Select[] = [
  //       {
  //         titleBarTitle: 'Select Scenario',
  //         titleBarKey: 'scenarioSelection',
  //         values: scenariosOptions,
  //         displayOnOverviewPage: true,
  //         aValueIsAlwaysSelected: true,
  //         currentValue: scenariosOptions[0].value,
  //       },
  //     ];
  //     dispatch(
  //       addAdditionalTitleBarComponents(
  //         selectsToAdd,
  //         APPEND_NEW_SELECT_TO_TITLEBAR
  //       )
  //     );
  //   }
  // }, [scenariosOptions]);

  // const chosenStressLevel = useTitleBarSelect('scenarioSelection');

  // useEffect(() => {
  //   chosenStressLevel ? setStressLevel(chosenStressLevel) : setStressLevel(defaultDropDownValues[0].value);
  // }, [chosenStressLevel])

  const mancoAssetLiquidityDataBAU = useFetchData({
    url: `/manco_level_asset_liquidity/BAU/${props.positionDate}`,
    keyName: `manco_level_asset_liquidity_BAU_${props.positionDate}`,
    makeFetch: props.positionDate !== undefined,
  });
  const mancoAssetLiquidityDataStressed = useFetchData({
    url: `/manco_level_asset_liquidity/Stressed/${props.positionDate}`,
    keyName: `manco_level_asset_liquidity_Stressed_${props.positionDate}`,
    makeFetch: props.positionDate !== undefined,
  });
  const mancoAssetLiquidityDataSuperStressed = useFetchData({
    url: `/manco_level_asset_liquidity/Super-Stressed/${props.positionDate}`,
    keyName: `manco_level_asset_liquidity_Super-Stressed_${props.positionDate}`,
    makeFetch: props.positionDate !== undefined,
  });

  return (
    <GeneralComponentErrorShield
      dataObjects={[
        mancoAssetLiquidityDataBAU,
        mancoAssetLiquidityDataStressed,
        mancoAssetLiquidityDataSuperStressed,
      ]}
      customLoadingMessages={[
        'Loading Liquidity Data...',
        'This May Take A Few Moments',
      ]}
      customErrorMessage="No Data Found"
    >
      <Grid container spacing={2} style={{ padding: 8 }}>
        <LiquidationTimeTable
          // stressLevel={stressLevel}
          // data={mancoAssetLiquidityData}
          bauData={mancoAssetLiquidityDataBAU}
          stressedData={mancoAssetLiquidityDataStressed}
          superStressedData={mancoAssetLiquidityDataSuperStressed}
        />
      </Grid>
    </GeneralComponentErrorShield>
  );
};

export default LiquidationTime;
