import makeStyles from '@mui/styles/makeStyles';
import fileDownload from 'js-file-download';
import React, { useState } from 'react';
import { greys, mainColors } from '../../../../../styling/theme';
import RaptorSelect from '../../../../selects/RaptorSelect.component';
import RaptorFileDownloadButton, {
  RaptorFileDownloadButtonStatus,
} from '../../../../feedback/RaptorFileDownloadButton.component';
import client, { BASE_URL } from '../../../../../utilities/requestClient';
import stringInject from '../../../../../utilities/stringInject';

const useStyles = makeStyles(() => ({
  exportItem: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    padding: '1rem',
    borderRadius: '0.4rem',
    border: `2px solid ${greys.grey100}`,
    backgroundColor: 'white',
    boxShadow: `0px 2px 5px ${greys.grey100}`,
    width: '50rem',
  },
  exportItemTitle: {
    color: mainColors.mainBlue,
    fontSize: '2rem',
    fontWeight: 600,
  },
  exportItemDescription: {
    color: mainColors.mainBlue,
    fontSize: '1.4rem',
    fontWeight: 400,
  },
  exportItemsOptionsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    marginTop: '1rem',
    marginBottom: '1rem',
  },
  exportItemsOptionsContainerTitle: {
    color: greys.grey300,
    fontSize: '1.3rem',
    fontWeight: 600,
    textDecoration: 'underline',
  },
  exportItemsOptionsContainerItem: {
    display: 'flex',
    flexDirection: 'column',
  },
  exportItemsOptionsContainerItemTitle: {
    color: mainColors.mainBlue,
    fontSize: '1.2rem',
    fontWeight: 600,
  },
  exportItemsOptionsContainerItemMessage: {
    color: mainColors.Fail,
    fontSize: '1rem',
    fontWeight: 600,
  },
  exportItemButton: {
    margin: '0.5rem 0',
    width: '20rem',
  },
}));

type OptionsType = {
  type: 'single-select';
  id: string;
  title: string;
  options?: string[];
};

function openInNewTab(url: string) {
  return async () => {
    const win = window.open(url, '_blank');
    if (win) {
      win.focus();
      return true;
    } else {
      return false;
    }
  };
}

interface ExportItemProps {
  title: string;
  description?: string;
  options?: OptionsType[];
  downloadUrl: string;
  fileName: string;
  openDownloadInNewTab?: boolean;
}

// There is a corruption issue occuring with file downloads
// When downloading from a new tab, it works fine
// This is not ideal as we can't manage the download status and show feedback to the user

const ExportItem: React.FC<ExportItemProps> = ({
  title,
  description,
  options,
  downloadUrl,
  fileName,
  openDownloadInNewTab,
}) => {
  const requestClient = client();
  const classes = useStyles();

  // initialise the download status state
  const [status, setStatus] = useState<RaptorFileDownloadButtonStatus>('idle');

  // initialise the options state, and the options messages state
  // this records user selections, and the messages to display if the user has not selected an option
  const [optionsState, setOptionsState] = useState<{
    [index: string]: string | null;
  }>(
    options
      ? options.reduce((acc, option) => ({ ...acc, [option.id]: null }), {})
      : {},
  );
  const [optionsMessages, setOptionsMessages] = useState<{
    [index: string]: string | null;
  }>(optionsState);

  // when the user clicks the download button, check if all options have been selected
  // if not, display a message that the option is required
  // if all options have been selected, send the request to the server, updating the download status
  // if the request is successful, download the file using the programmatically calculated file name
  function generateReport() {
    let downloadReady = true;

    Object.keys(optionsState).forEach((option) => {
      if (optionsState[option] === null) {
        setOptionsMessages((prevState) => ({
          ...prevState,
          [option]: 'Required',
        }));
        downloadReady = false;
      }
    });

    if (downloadReady) {
      setStatus('loading');
      if (openDownloadInNewTab) {
        const url = `${BASE_URL}${downloadUrl}`;
        const openReport = openInNewTab(stringInject(url, optionsState));
        openReport();
        setStatus('idle');
      } else {
        // download url and filename can be calculated using the stringInject function
        // Example:
        // stringInject("Hello {location}, my name is {name}", { location: "World", name: "Tom" }) => "Hello World, my name is Tom"
        requestClient
          .get(stringInject(downloadUrl, optionsState))
          .then((response) => {
            const document = response.data;
            fileDownload(document, stringInject(fileName, optionsState));
            setStatus('success');
          })
          .catch(() => {
            setStatus('error');
          })
          .finally(() => {
            // wait 5 seconds before resetting the download status
            setTimeout(() => {
              setStatus('idle');
            }, 5000);
          });
      }
    }
  }

  return (
    <div className={classes.exportItem}>
      <div className={classes.exportItemTitle}>{title}</div>
      {description && (
        <div className={classes.exportItemDescription}>{description}</div>
      )}
      {options && options.length > 0 ? (
        <div className={classes.exportItemsOptionsContainer}>
          <div className={classes.exportItemsOptionsContainerTitle}>
            Options:
          </div>
          {options?.map((option, index) => (
            <div
              key={index}
              className={classes.exportItemsOptionsContainerItem}
            >
              <div className={classes.exportItemsOptionsContainerItemTitle}>
                {option.title}
              </div>
              {option.type === 'single-select' && (
                <RaptorSelect
                  options={option.options || []}
                  handleChoice={(choice: any) => {
                    setOptionsState({ ...optionsState, [option.id]: choice });
                    setOptionsMessages({
                      ...optionsMessages,
                      [option.id]: null,
                    });
                  }}
                />
              )}
              <div className={classes.exportItemsOptionsContainerItemMessage}>
                {optionsMessages[option.id]}
              </div>
            </div>
          ))}
        </div>
      ) : null}
      <div className={classes.exportItemButton} onClick={generateReport}>
        <RaptorFileDownloadButton
          status={status}
          openDownloadInNewTab={openDownloadInNewTab}
        />
      </div>
    </div>
  );
};

export default ExportItem;
