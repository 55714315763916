import { LabelProps, YAxisProps } from 'recharts';
import { EquityServerData } from '../table/table.data';
import { CustomColumn } from '../../../../../../../types/components/tables/tableTypes';

export interface EquityChartData {
  name: string;
}

export interface EquityExposureChartData extends EquityChartData {
  exposure: number;
}

export interface EquityExDividendChartData extends EquityExposureChartData {
  dividendYield: number;
}

export interface EquityGicsComparisonData extends EquityChartData {
  indexExposure: number;
  portfolioExposure: number;
  difference: number;
}

export function generateEquityBarChart(
  inputData: EquityServerData[],
  selectedChart: string,
) {
  if (!inputData.length) return [];
  let data: any;

  const returnData: EquityExDividendChartData[] = [];
  switch (selectedChart) {
    case 'exposure':
    case 'exposureDividendYield':
      data = [...inputData[0].equity_sector_data];
      // remove header row
      data.shift();
      data.forEach((el: [string, number, number]) => {
        returnData.push({
          name: el[0],
          exposure: el[1],
          dividendYield: el[2],
        });
      });
      return returnData;
    case 'gics':
      data = [...inputData[0].gics_comparable_data];
      data.shift();
      return data.map((el: any) => {
        const [name, indexExposure, portfolioExposure, difference] = el;
        return {
          name,
          indexExposure,
          portfolioExposure,
          difference,
        };
      });
    default:
      return [];
  }
}

export function generateEquityBarChartTableColumns(): CustomColumn<EquityExposureChartData>[] {
  return [
    {
      title: 'Asset',
      field: 'name',
      width: '80%',
    },
    {
      title: 'Exposure',
      render: (rowData) => `${rowData.exposure}%`,
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
      },
      field: 'exposure',
    },
  ];
}

export function generateEquityBarChartYAxes(
  selectedChart: string,
): YAxisProps[] {
  const exposureLeftLabel: LabelProps = {
    position: 'left',
    angle: -90,
    value: 'Exposure (% of NAV)',
    style: {
      textAnchor: 'middle',
      fill: '#777777',
    },
  };
  switch (selectedChart) {
    case 'exposure':
      return [
        {
          yAxisId: 'left',
          label: exposureLeftLabel,
          dataKey: 'exposure',
          tick: {
            fontSize: 16,
          },
          unit: '%',
          type: 'number',
          scale: 'linear',
          domain: ['dataMin', (dataMax: number) => dataMax * 1.1],
        },
      ];
    case 'exposureDividendYield':
      return [
        {
          yAxisId: 'left',
          label: {
            ...exposureLeftLabel,
            style: {
              ...exposureLeftLabel.style,
              fill: '#777777',
            },
          },
          dataKey: 'exposure',
          unit: '%',
          type: 'number',
          scale: 'linear',
          domain: ['dataMin', (dataMax: number) => dataMax * 1.1],
        },
        {
          yAxisId: 'right',
          dataKey: 'dividendYield',
          type: 'number',
          label: {
            ...exposureLeftLabel,
            angle: 90,
            value: 'Dividend Yield',
            position: 'right',
            style: {
              ...exposureLeftLabel.style,
              fill: '#777777',
            },
          },

          scale: 'linear',
          orientation: 'right',
          domain: ['dataMin', (dataMax: number) => dataMax * 1.1],
        },
      ];
    default:
      return [];
  }
}
