import React, { Dispatch, FC, ReactElement, useEffect } from 'react';
import GridItem from '../../../../../layout/GridComponents/GridItem';
import {
  CompositeDecorator,
  ContentState,
  DraftEditorCommand,
  Editor,
  EditorState,
  RichUtils,
  convertFromHTML,
} from 'draft-js';
import makeStyles from '@mui/styles/makeStyles';
import { greys, mainColors } from '../../../../../../styling/theme';
import { stateToHTML } from 'draft-js-export-html';
import { connect } from 'react-redux';
import CommentEditorButton from '../Buttons/CommentEditorButton.component';
import { Typography } from '@mui/material';

interface EditorTileProps {
  tileType: string;
  fundId: string | null;
  text: string;
  testSpan?: 'column' | 'full';
  tableContents?: any;
  formatOptions?: string;
  index: number;
  setKiidsContent: Dispatch<any>;
  kiidsContent: any[];
  setChangesMade: Dispatch<any>;
  disabled?: boolean;
  shareClass: string;
  commentId: string;
  hasUnresolvedComments: boolean;
  setTemplateUpdates: Dispatch<any>;
  templateUpdates: any;
  setWasFieldTypeChanged: Dispatch<any>;
  setWasNewFieldAdded: Dispatch<any>;
  setEditorTileComponents: Dispatch<any>;
  editorTileComponents: any[];
}

type TagType = 'BOLD' | 'UNDERLINE';

interface formatButtonProps {
  text: string;
  inlineStyle: string;
  handler: (e: React.MouseEvent, inlineStyle: string) => void;
}

const useButtonStyles = makeStyles(() => ({
  button: {
    backgroundColor: mainColors.mainBlue_lighter,
    '&:hover, &:focus': {
      backgroundColor: mainColors.mainBlue,
      cursor: 'pointer',
    },
    border: 'none',
    marginRight: '0.5rem',
    maxHeight: '2rem',
    marginTop: '1rem',
  },
}));

// const TemplateSection = (props: JSX.IntrinsicAttributes & React.ClassAttributes<HTMLSpanElement> & React.HTMLAttributes<HTMLSpanElement>) => {
const TemplateSection = (props: any) => {
  return (
    <span
      {...props}
      style={{ background: mainColors.pineGreen, color: 'white' }}
    >
      {/* {props.decoratedText.replace('{{', '').replace('}}', '')} */}
      {props.decoratedText}
    </span>
  );
};

const compositeDecorator = new CompositeDecorator([
  {
    strategy: templateStrategy,
    component: TemplateSection,
  },
]);

function templateStrategy(contentBlock: any, callback: any, contentState: any) {
  findWithRegex(contentBlock, callback);
}

const TEMPLATE_REGEX = /\{\{.*\}\}/g;

function findWithRegex(
  contentBlock: { getText: () => any },
  callback: (arg0: number, arg1: number) => void,
) {
  const text = contentBlock.getText();
  let matchArr, start;
  while ((matchArr = TEMPLATE_REGEX.exec(text)) !== null) {
    start = matchArr.index;
    callback(start, start + matchArr[0].length);
  }
}

function FormatButton(props: formatButtonProps): ReactElement {
  const { text, inlineStyle, handler } = props;

  const classes = useButtonStyles();

  return (
    <button
      onMouseDown={(e) => handler(e, inlineStyle)}
      className={classes.button}
    >
      <Typography
        variant={'h3'}
        style={{ fontWeight: 300, fontSize: '1.5rem', color: 'white' }}
      >
        {text}
      </Typography>
    </button>
  );
}

const styleMap = {
  TEMPLATE: {
    backgroundColor: mainColors.pineGreen,
    color: 'white',
  },
};

const KiidsTextEditorTile: FC<EditorTileProps> = (props) => {
  // const data = localStorage.getItem(TEXT_EDITOR_ITEM);
  // const initialState = EditorState.createWithContent(ContentState.createFromText(props.text || ''));
  const {
    setKiidsContent,
    kiidsContent,
    index,
    setChangesMade,
    disabled,
    editorTileComponents,
    setEditorTileComponents,
  } = props;

  const blocksFromHTML = convertFromHTML(props.text || '');
  const contentState = ContentState.createFromBlockArray(
    blocksFromHTML.contentBlocks,
  );
  // const initialState = EditorState.createWithContent(contentState, compositeDecorator);
  const initialState = EditorState.createWithContent(contentState);
  // const [editorState, setEditorState] = React.useState<EditorState>(initialState);
  const [editorState, setEditorState] = React.useState<EditorState>(
    props.text.length ? initialState : EditorState.createEmpty(),
  );

  // setEditorState(EditorState.forceSelection(editorState, editorState.getSelection()));
  // useEffect(() => {
  //   // const content = editorState.getCurrentContent();
  //   // const newEditorState = EditorState.createWithContent(content,);
  //   if (index === 0) {
  //     console.log("Trying to force the rerender here");
  //   }
  //   // editorState.mustForceSelection();

  //   // setEditorState(newEditorState);
  //   setEditorState(
  //     EditorState.forceSelection(editorState, editorState.getSelection())
  //   )
  // }, [props.text]);

  // if (index === 0) {
  //   console.log("RENDERING THE TEXT TILE", index, props.text, editorState.getCurrentContent().getPlainText());
  // }
  // console.log("HERE IS THE INITIAL STATE", initialState);
  // setEditorState(initialState);
  // const [boldIsActive, setBoldIsActive] = React.useState<boolean>(false);
  // const [underlineIsActive, setunderlineIsActive] = React.useState<boolean>(false);
  // const [italicIsActive, setitalicIsActive] = React.useState<boolean>(false);
  // Case for when the field is a template.
  // useEffect(() => {
  //     if (props.text?.startsWith('{{') && props.text.endsWith('}}')) {
  //         console.log("Setting the background color", props.text, blocksFromHTML.contentBlocks.entries())
  //         setEditorState(RichUtils.toggleInlineStyle(editorState, 'TEMPLATE'));
  //     }
  // }, [props.text])

  const handleTextChange = (updatedEditorState: EditorState) => {
    setEditorState(updatedEditorState);
    let htmlText = stateToHTML(updatedEditorState.getCurrentContent());
    const style = updatedEditorState.getCurrentInlineStyle().toArray();

    htmlText = htmlText
      .replaceAll('<p>', '')
      .replaceAll('</p>', '')
      .replaceAll('<strong>', '<b>')
      .replaceAll('</strong>', '</b>')
      .replaceAll('<em>', '<i>')
      .replaceAll('</em>', '</i>');
    const allContent = kiidsContent;
    const currentContentAtIndex = kiidsContent[index];
    // Indicate if a change has been made to enable saving.
    if (currentContentAtIndex.content !== htmlText) {
      setChangesMade(true);
    }
    if (style.length) {
      style.forEach((style: string) => {
        htmlText = applyFromattingIndividually(htmlText, style as TagType);
      });
    }
    // Update the content at the specific index.
    currentContentAtIndex.content = htmlText;
    // Now replace in the overall array
    allContent[index] = currentContentAtIndex;

    setKiidsContent(allContent);
  };

  // This function will be used to apply tags such as <b>, <u> etc. to each individual word in a string.
  // This is to ensure that tracked changes will work correctly.
  // For example if the string '<b>This is an example</b> is received then the below will be returned
  // '<b>This</b> <b>is</b> <b>an</b> <b>example</b>'
  // Note => Make sure to test for multiple parts within tags in the same string and tags in the middle of words.
  type TagMapType = {
    [key in TagType]: string;
  };
  const TAG_MAP: TagMapType = {
    BOLD: 'b',
    UNDERLINE: 'u',
  };
  const applyFromattingIndividually = (text: string, tag: TagType): string => {
    // Get the tag to search for.
    const tagUsed = TAG_MAP[tag];
    // Build the regular expression
    // new RegExp('^$|^'+i+'|^([FG]?\\d{5}|\\d{5}[AB])$');
    const regex = new RegExp('(<' + tagUsed + '>.*?</' + tagUsed + '>)', 'g');
    // Now find all text enclosed by one of the tags.
    const matches = Array.from(text.matchAll(regex));
    // Ensure some tags were found. If not just return the text as is.
    if (!matches.length) return text;
    // Create a List for storing the before and after of adding the tags to each word.
    const changes: any[] = [];
    // Loop over the matches found.
    matches.forEach((match: any) => {
      // Get the text from the match.
      const matchText = match[0].trim();

      // split the text into individual words.
      const words = matchText.split(/\s+/);
      // Now join back into a single string with tags around each word.
      const textWithTags = words
        .join(`</${tagUsed}> <${tagUsed}>`)
        .replace(`<${tagUsed}></${tagUsed}>`, '');
      // Store the before and after.
      changes.push([matchText.trim(), textWithTags]);
    });
    // Now replace the original text with the new text.
    let textCopy = text;
    changes.forEach((change: any) => {
      textCopy = textCopy.replace(change[0], change[1]);
    });
    return textCopy;
  };

  const handleKeyCommand = (command: DraftEditorCommand) => {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      setEditorState(newState);
      return 'handled';
    }
    return 'not-handled';
  };

  const handleTogggleClick = (e: React.MouseEvent, inlineStyle: string) => {
    e.preventDefault();
    // handleTextChange(editorState);

    setEditorState(RichUtils.toggleInlineStyle(editorState, inlineStyle));
    let htmlText = stateToHTML(editorState.getCurrentContent());
    const style = editorState.getCurrentInlineStyle().toArray();

    htmlText = htmlText
      .replaceAll('<p>', '')
      .replaceAll('</p>', '')
      .replaceAll('<strong>', '<b>')
      .replaceAll('</strong>', '</b>')
      .replaceAll('<em>', '<i>')
      .replaceAll('</em>', '</i>');
  };

  const handleBlockClick = (e: React.MouseEvent, blockType: string) => {
    e.preventDefault();
    setEditorState(RichUtils.toggleBlockType(editorState, blockType));
  };

  const refreshEditorState = () => {
    setEditorState(EditorState.createEmpty());
    // return editorState;
  };

  useEffect(() => {
    if (!props.text.length) {
      refreshEditorState();
    }
  }, [props.text]);

  return (
    <GridItem
      xs={12}
      card
      style={{
        marginBottom: '1rem',
      }}
    >
      <div
        style={{
          padding: '1rem',
          fontSize: '1.5rem',
          minHeight: '10rem',
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <div
            style={{
              display: 'flex',
            }}
          >
            {/* <button onMouseDown={(e) => handleBlockClick(e, "unstyled")}>Normal</button> */}
            <FormatButton
              text={'bold'}
              inlineStyle={'BOLD'}
              handler={handleTogggleClick}
            />
            {/* <FormatButton text={'italic'} inlineStyle={'ITALIC'} handler={handleTogggleClick} /> */}
            <FormatButton
              text={'underline'}
              inlineStyle={'UNDERLINE'}
              handler={handleTogggleClick}
            />
            {/* <FormatButton text={'Toggle Template'} inlineStyle={'TEMPLATE'} handler={handleTogggleClick} /> */}

            {/* <button onMouseDown={(e) => handleTogggleClick(e, "BOLD")}>bold</button>
                <button onMouseDown={(e) => handleTogggleClick(e, "UNDERLINE")}>underline</button>
                <button onMouseDown={(e) => handleTogggleClick(e, "ITALIC")}>italic</button> */}
          </div>
          <div style={{ display: 'flex' }}>
            {/* <ChangeFieldTypeButton
              fieldType={props.tileType}
              setKiidsContent={props.setKiidsContent}
              kiidsContent={props.kiidsContent}
              index={props.index}
              setChangesMade={props.setChangesMade}
              setWasFieldTypeChanged={props.setWasFieldTypeChanged}
            /> */}
            <CommentEditorButton
              fundId={props.fundId}
              shareClass={props.shareClass}
              commentId={props.commentId}
              kidIndex={props.index}
              hasUnresolvedComments={props.hasUnresolvedComments}
              setKiidsContent={props.setKiidsContent}
              kiidsContent={props.kiidsContent}
            />
            {/* <RemoveFieldButton
              index={index}
              setKiidsContent={props.setKiidsContent}
              kiidsContent={props.kiidsContent}
              setChangesMade={props.setChangesMade}
              setWasFieldTypeChanged={props.setWasFieldTypeChanged}
              setWasNewFieldAdded={props.setWasNewFieldAdded}
              setEditorTileComponents={setEditorTileComponents}
              editorTileComponents={editorTileComponents}
            /> */}
          </div>
        </div>

        <div
          style={{
            border: '0.5px solid',
            minHeight: '8rem',
            marginTop: '0.5rem',
            backgroundColor: disabled ? greys.grey400 : 'white',
          }}
        >
          <Editor
            editorState={editorState}
            onChange={handleTextChange}
            handleKeyCommand={handleKeyCommand}
            customStyleMap={styleMap}
            readOnly={disabled}
            stripPastedStyles={true}
            // blockRendererFn={mediaBlockRenderer}
          />
        </div>
      </div>
    </GridItem>
  );
};

// This function is used in order to force the component to re-render when the redux state is updated.
function mapStateToProps(state: any, ownProps: EditorTileProps) {
  return {
    disabled:
      state.kids.kidsFilterState.share_class ||
      state.kids.kidsFilterState.manco ||
      state.kids.kidsFilterState.fund_manager ||
      state.kids.kidsFilterState.sub_fund,
  };
}

export default connect(mapStateToProps)(KiidsTextEditorTile);
