import React, { useState } from 'react';
import GeneralCard from '../../../general/GeneralCard';
import Chart from './Chart';
import { makeStyles } from '@mui/styles';
import { mainColors } from '../../../../styling/theme';

export interface TimeSeriesDataPoint {
  time: string;
  value: number;
}

export interface MarketsGraphProp {
  asset: string;
  price: number[];
  price_time: string[];
  return: number[];
  return_time: string[];
}

export interface MarketsGraphPropArray {
  market_graph_data: MarketsGraphProp[];
  which_asset: string;
}

const useStyles = makeStyles({
  cardHeader: {
    color: mainColors.mainBlue,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  chartContainer: {
    flexGrow: 1,
    height: '100%',
    width: '100%',
  },
  buttonContainer: {
    display: 'flex',
    gap: '1em',
    marginBottom: '1em',
  },
  button: {
    cursor: 'pointer',
    padding: '0.5em 1em',
    border: 'none',
    borderRadius: '4px',
    color: 'white',
    backgroundColor: mainColors.inactiveGrey,
    '&.active': {
      backgroundColor: mainColors.mainBlue,
    },
  },
});

function getFormattedDate(dateString: string | undefined) {
  if (dateString === undefined) {
    const today = new Date();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    return `${month}-${day}`;
  }
  const date = new Date(dateString);
  const day = date.getDate();
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  const month = months[date.getMonth()];
  return `${day} ${month}`;
}

const Graph: React.FC<MarketsGraphPropArray> = ({
  market_graph_data,
  which_asset,
}) => {
  const [selectedView, setSelectedView] = useState<string>('price');
  const classes = useStyles();
  const selectedAsset = market_graph_data.find(
    (asset) => asset.asset === which_asset,
  );

  const hasData = selectedAsset && selectedAsset.price_time.length > 0;
  const selected_day = getFormattedDate(
    selectedAsset?.price_time[selectedAsset?.price_time.length - 1],
  );

  const handleButtonClick = (view: string) => {
    setSelectedView(view);
  };

  return (
    <GeneralCard
      cardStyle={{
        height: '38rem',
        minWidth: '50em',
        padding: '0.7em 2em',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <div className={classes.cardHeader}>
        {hasData ? <h2>{which_asset}</h2> : <h2>No data available</h2>}
        <h2>{selected_day}</h2>
        <div className={classes.buttonContainer}>
          <button
            className={`${classes.button} ${selectedView === 'price' ? 'active' : ''}`}
            onClick={() => handleButtonClick('price')}
          >
            Price
          </button>
          <button
            className={`${classes.button} ${selectedView === 'return' ? 'active' : ''}`}
            onClick={() => handleButtonClick('return')}
          >
            Return
          </button>
        </div>
      </div>
      <div className={classes.chartContainer}>
        {hasData ? (
          selectedView === 'price' ? (
            <Chart
              graphData={{
                price_time: selectedAsset!.price_time,
                price: selectedAsset!.price,
                price_on: true,
              }}
            />
          ) : selectedView === 'return' ? (
            <Chart
              graphData={{
                price_time: selectedAsset!.price_time,
                price: selectedAsset!.return,
                price_on: false,
              }}
            />
          ) : null
        ) : (
          <div>No data available</div>
        )}
      </div>
      <h4 style={{ fontWeight: 'lighter', color: 'grey' }}>
        TradingView Lightweight Charts™ Copyright (с) 2023 TradingView
      </h4>
    </GeneralCard>
  );
};

export default Graph;
