import {
  ADD_FUNDS_DATA_FAILURE,
  ADD_FUNDS_DATA_START,
  ADD_FUNDS_DATA_SUCCESS,
  APPEND_NEW_DATE_PICKER_TO_TITLEBAR,
  APPEND_NEW_INFOTOGGLE_TO_TITLEBAR,
  APPEND_NEW_INPUT_TO_TITLEBAR,
  APPEND_NEW_SELECT_TO_TITLEBAR,
  FETCH_CLIENT_CONFIG_FAILURE,
  FETCH_CLIENT_CONFIG_START,
  FETCH_CLIENT_CONFIG_SUCCESS,
  IAddAdditionalTitlebarComponents,
  IAddFundsDataFailure,
  IAddFundsDataStart,
  IAddFundsDataSuccess,
  IFetchClientConfigFailure,
  IFetchClientConfigStart,
  IFetchClientConfigSuccess,
  IRemoveTitlebarComponent,
  ITogglePositionDateRangeShowing,
  ITogglePositionDateShowing,
  IToggleTitleBarLoading,
  IUpdateActiveDate,
  IUpdateActiveDateRangeEnd,
  IUpdateActiveDateRangeStart,
  IUpdateAvailableDates,
  IUpdateCurrentFundSearchString,
  IUpdateFilteredFunds,
  IUpdateFund,
  IUpdatePage,
  IUpdateSection,
  IUpdateSpecificTitlebarComponent,
  IUpdateSpecificTitlebarInput,
  IUpdateTitleBarSelect,
  REMOVE_DATE_PICKER_FROM_TITLEBAR,
  REMOVE_INFOTOGGLE_FROM_TITLEBAR,
  REMOVE_INPUT_FROM_TITLEBAR,
  REMOVE_SELECT_FROM_TITLEBAR,
  TOGGLE_OPENING_ANIMATION,
  TOGGLE_POSITION_DATE_RANGE_SHOWING,
  TOGGLE_POSITION_DATE_SHOWING,
  TOGGLE_TITLEBAR_LOADING,
  UPDATE_ACTIVE_DATE,
  UPDATE_ACTIVE_DATE_RANGE_END,
  UPDATE_ACTIVE_DATE_RANGE_START,
  UPDATE_ACTIVE_FUND,
  UPDATE_ACTIVE_PAGE,
  UPDATE_ACTIVE_SECTION,
  UPDATE_AVAILABLE_DATES,
  UPDATE_CURRENT_FUND_SEARCH_STRING,
  UPDATE_FILTERED_FUNDS,
  UPDATE_SPECIFIC_TITLEBAR_COMPONENT,
  UPDATE_SPECIFIC_TITLEBAR_INPUT,
  UPDATE_TITLE_BAR_SELECT,
} from '../../types/redux/pages/pageTypes';
import { makeGeneralDataFetch } from '../../utilities/requestClient';
import {
  ClientConfiguration,
  FundType,
} from '../../types/server-data/ClientConfig';
import {
  BaseTitleBarComponentsKeys,
  TitleBarComponent,
} from '../../types/redux/pages/PagesStore';
import { CancelTokenSource } from 'axios';

export function updateSection(section: string): IUpdateSection {
  return {
    type: UPDATE_ACTIVE_SECTION,
    payload: section,
  };
}

export function updatePage(
  pageId: string,
  pageArea: string,
  sectionId: string,
  explicitPageName: string | null = null,
): IUpdatePage {
  return {
    type: UPDATE_ACTIVE_PAGE,
    payload: {
      pageId,
      pageArea,
      sectionId,
      explicitPageName: explicitPageName || undefined,
    },
  };
}

export function updateFund(
  section: string,
  fundName: string,
  date: string,
): IUpdateFund {
  return {
    type: UPDATE_ACTIVE_FUND,
    payload: {
      section,
      fundName,
      date,
    },
  };
}

export function updateSpecificTitlebarInput(
  inputKey: string,
  inputVal: string | number,
): IUpdateSpecificTitlebarInput {
  return {
    type: UPDATE_SPECIFIC_TITLEBAR_INPUT,
    payload: {
      inputKey,
      newInputValue: inputVal,
    },
  };
}

export function updateCurrentFundSearchSTring(
  str: string,
): IUpdateCurrentFundSearchString {
  return {
    type: UPDATE_CURRENT_FUND_SEARCH_STRING,
    payload: str,
  };
}

export function updateSpecificTitleBarComponent(
  componentKey: string,
  newValue: string | number,
  componentType: BaseTitleBarComponentsKeys,
): IUpdateSpecificTitlebarComponent {
  return {
    type: UPDATE_SPECIFIC_TITLEBAR_COMPONENT,
    payload: {
      componentKey,
      newValue,
      componentType,
    },
  };
}

export function togglePositionDateShowing(
  showPositionDate: boolean,
): ITogglePositionDateShowing {
  return {
    type: TOGGLE_POSITION_DATE_SHOWING,
    payload: showPositionDate,
  };
}
export function togglePositionDateRangeShowing(
  showDateRange: boolean,
): ITogglePositionDateRangeShowing {
  return {
    type: TOGGLE_POSITION_DATE_RANGE_SHOWING,
    payload: showDateRange,
  };
}

export function startClientConfigFetch(): IFetchClientConfigStart {
  return {
    type: FETCH_CLIENT_CONFIG_START,
  };
}

export function clientConfigFetchSuccess({
  data,
}: {
  data: ClientConfiguration;
}): IFetchClientConfigSuccess {
  return {
    type: FETCH_CLIENT_CONFIG_SUCCESS,
    payload: data,
  };
}
export function toggleOpeningAnimation() {
  return {
    type: TOGGLE_OPENING_ANIMATION,
  };
}

export function clientConfigFailure({
  errorMessage,
}: {
  errorMessage: Error;
}): IFetchClientConfigFailure {
  return {
    type: FETCH_CLIENT_CONFIG_FAILURE,
    payload: errorMessage,
  };
}
export function fetchClientConfig(
  configName: string,
  cancelSource: CancelTokenSource,
) {
  return makeGeneralDataFetch({
    startAction: startClientConfigFetch,
    successAction: clientConfigFetchSuccess,
    failureAction: clientConfigFailure,
    cancelSource,
    props: {
      url: `en/${configName}.json`,
      returnLocalConfig:
        process.env.NODE_ENV !== 'production' ? `${configName}.json` : null,
    },
  });
}

export function applyFundFilter(
  filteredFunds: string[],
  fund: string,
  rsResource: string,
): IUpdateFilteredFunds {
  return {
    type: UPDATE_FILTERED_FUNDS,
    payload: {
      filteredFunds,
      fund,
      rsResource,
    },
  };
}

export function fetchFundsData(
  fundType: string,
  rsResource: string,
  url: string,
  cancelSource: CancelTokenSource,
) {
  return makeGeneralDataFetch({
    startAction: addFundsDataStart,
    successAction: addFundsDataSuccess,
    failureAction: addFundsDataFailure,
    cancelSource,
    props: {
      url,
      fundType,
      rsResource,
    },
  });
}

export function updateTitleBarSelectValue(
  selectKey: string,
  newValue: string,
): IUpdateTitleBarSelect {
  return {
    type: UPDATE_TITLE_BAR_SELECT,
    payload: {
      selectKey,
      newValue,
    },
  };
}

export type addAdditionalComponentsType =
  | APPEND_NEW_DATE_PICKER_TO_TITLEBAR
  | APPEND_NEW_INPUT_TO_TITLEBAR
  | APPEND_NEW_SELECT_TO_TITLEBAR
  | APPEND_NEW_INFOTOGGLE_TO_TITLEBAR;

export const addAdditionalTitleBarComponents = <T extends TitleBarComponent>(
  components: T[],
  type: addAdditionalComponentsType,
): IAddAdditionalTitlebarComponents<T, addAdditionalComponentsType> => {
  return {
    type,
    payload: {
      components,
    },
  };
};
export type removeTitleBarComponentType =
  | REMOVE_SELECT_FROM_TITLEBAR
  | REMOVE_INPUT_FROM_TITLEBAR
  | REMOVE_DATE_PICKER_FROM_TITLEBAR
  | REMOVE_INFOTOGGLE_FROM_TITLEBAR;
export const removeTitleBarComponent = (
  componentId: string,
  type: removeTitleBarComponentType,
): IRemoveTitlebarComponent<removeTitleBarComponentType> => ({
  type,
  payload: componentId,
});

export function toggleTitleBarLoading(
  isLoading: boolean,
): IToggleTitleBarLoading {
  return {
    type: TOGGLE_TITLEBAR_LOADING,
    payload: isLoading,
  };
}

export function updateActiveDate(date: string | null): IUpdateActiveDate {
  return {
    type: UPDATE_ACTIVE_DATE,
    payload: date || '',
  };
}

export function updateActiveDateRangeStart(
  date: string | null,
): IUpdateActiveDateRangeStart {
  return {
    type: UPDATE_ACTIVE_DATE_RANGE_START,
    payload: date || '',
  };
}

export function updateActiveDateRangeEnd(
  date: string | null,
): IUpdateActiveDateRangeEnd {
  return {
    type: UPDATE_ACTIVE_DATE_RANGE_END,
    payload: date || '',
  };
}

export function updateAvailableDates(
  dates: string[] | null,
): IUpdateAvailableDates {
  return {
    type: UPDATE_AVAILABLE_DATES,
    payload: dates || [],
  };
}

export function addFundsDataStart({
  fundType,
  rsResource,
}: {
  fundType: FundType;
  rsResource: string;
}): IAddFundsDataStart {
  return {
    type: ADD_FUNDS_DATA_START,
    payload: {
      fund: fundType,
      rsResource,
    },
  };
}

export function addFundsDataSuccess({
  data,
  fundType,
  rsResource,
}: {
  data: any;
  fundType: FundType;
  rsResource: string;
}): IAddFundsDataSuccess {
  return {
    type: ADD_FUNDS_DATA_SUCCESS,
    payload: {
      data,
      fund: fundType,
      rsResource,
    },
  };
}

export function addFundsDataFailure({
  errorMessage,
  fundType,
  rsResource,
}: {
  errorMessage: Error;
  fundType: FundType;
  rsResource: string;
}): IAddFundsDataFailure {
  return {
    type: ADD_FUNDS_DATA_FAILURE,
    payload: {
      error: errorMessage,
      fund: fundType,
      rsResource,
    },
  };
}
