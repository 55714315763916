import makeStyles from '@mui/styles/makeStyles';
import { FC } from 'react';
import { RaptorTheme, mainColors } from '../../../../styling/theme';
import { CustomColumn } from '../../../../types/components/tables/tableTypes';
import { addCommasToNumbersAndRound } from '../../../../utilities/numberFormatters';
import GridItem from '../../../layout/GridComponents/GridItem';
import CustomTable from '../../../tables/CustomTable';

export interface ICoverageRatioSecondLevelData {
  name: string;
  isin: string;
  position: number;
  currency: string;
  fxRate: number;
  riskFactor: number;
  usdIssue: number;
}

interface secondLevelTableProps {
  fundId: string;
  title: string;
  underlyingAssets: any;
}

function buildSecondLeveltableData(
  positionsBreakdown: any,
): ICoverageRatioSecondLevelData[] {
  // Check if there is positions data.
  if (!positionsBreakdown.length) return [];
  // reate a list for return data.
  const secondLevelTableData: ICoverageRatioSecondLevelData[] = [];
  positionsBreakdown.forEach((position: any) => {
    secondLevelTableData.push({
      name: position[0],
      isin: position[1],
      position: position[5],
      currency: position[2],
      fxRate: position[3],
      riskFactor: position[4],
      usdIssue: position[6],
    });
  });

  return secondLevelTableData;
}

export const useFailCellStyles = makeStyles<RaptorTheme>(
  (theme: RaptorTheme) => ({
    container: {
      height: '5rem',
      display: 'flex',
      alignItems: 'center',
      position: 'relative',
      justifyContent: 'center',
      backgroundColor: mainColors.Fail,
      overflow: 'hidden',
    },
    text: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      zIndex: 3,

      // color: (props) => props.height < 50 ? theme.palette.grey[400] : "white",
      color: 'white',
      // color: "#0f0f0f",
      fontSize: '1.4rem',
    },

    innerContainer: {
      position: 'absolute',
      overflow: 'hidden',
      bottom: 0,
      left: 0,
      zIndex: 1,
      width: '100%',
    },
  }),
);

const detailColumns: CustomColumn<ICoverageRatioSecondLevelData>[] = [
  { title: 'Issuer', field: 'name', width: '31%' },
  {
    title: 'ISIN',
    field: 'isin',
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: { textAlign: 'center' },
  },
  {
    title: 'Currency',
    field: 'currency',
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: { textAlign: 'center' },
  },
  {
    title: 'FX Rate',
    field: 'fxRate',
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: { textAlign: 'center' },
  },
  {
    title: 'Risk Factor',
    field: 'riskFactor',
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: { textAlign: 'center' },
  },
  {
    title: 'Position',
    field: 'position',
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: { textAlign: 'center' },
    render: (rowData: ICoverageRatioSecondLevelData) =>
      addCommasToNumbersAndRound(rowData.position),
  },
  {
    title: 'USD Issue',
    field: 'usdIssue',
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: { textAlign: 'center' },
    render: (rowData: ICoverageRatioSecondLevelData) =>
      addCommasToNumbersAndRound(rowData.usdIssue),
  },
];

// export const secondLevelOptions: Options = {
//     paging: false,
//     showTitle: false,
//     exportButton: true,
//     search: false,
//     toolbar: true,
//     rowStyle: (rowData: IEsgSecondLevelData) => ({
//         backgroundColor: hexToRGBA(mapStatusToColor(rowData.status as Status), 0.3),
//         fontWeight: rowData.issuer === 'Aggregate Information' ? 'bold' : 'normal',
//     }),
// };

const CoverageRatioSecondLevelTable: FC<secondLevelTableProps> = (props) => {
  // const theme = useTheme();
  const { fundId, title } = props;
  // const esgSecondLevelTableData = props.positionsBreakdown ? props.positionsBreakdown : buildEsgPositionData();
  const esgSecondLevelTableData = buildSecondLeveltableData(
    props.underlyingAssets,
  );
  return (
    // <div>Im esg page</div>
    <GridItem xs={12} card>
      <CustomTable<ICoverageRatioSecondLevelData>
        columns={detailColumns}
        showToolbar
        data={esgSecondLevelTableData}
        title={title}
        options={{
          paging: false,
          search: false,
          exportButton: true,
        }}
        // // options={{
        // //   headerStyle: {
        // //     color: 'white',
        // //     textAlign: 'left',
        // //     backgroundColor: theme.palette.grey[100],
        // //   },

        //   paging: false,
        //   exportButton: false,
        // }}
      />
    </GridItem>
  );
};

export default CoverageRatioSecondLevelTable;
