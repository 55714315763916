import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { clientNameSelector } from '../../../../redux/auth/selectors';
import useFetchData from '../../../../hooks/useFetchData';
import { GeneralOverviewPageRenderedComponentProps } from '../../../../types/components/componentTypes';
import GeneralComponentErrorShield from '../../../general/GeneralComponentErrorShield';
import LiquidityOverviewTable from './table';
import {
  activeSectionSelector,
  createSectionByIdSelector,
} from '../../../../redux/pages/selectors';

const LiquidityOverview: FC<GeneralOverviewPageRenderedComponentProps> = (
  props,
) => {
  const sectionDetails = useSelector(createSectionByIdSelector(props.section));
  const fundTypes = sectionDetails?.fundTypes;
  const clientName = useSelector(clientNameSelector);
  const currentSection = useSelector(activeSectionSelector);

  // only adepa have specific liquidy reports, for all other clients this should return empty array
  const schemas = sectionDetails?.fundTypes.length
    ? `?schemas=${sectionDetails?.fundTypes}`
    : '';

  const liquidityReports = useFetchData({
    keyName: `${fundTypes}_liquidity_reports`,
    url: `reports_list/daily_liquidity_risk_report` + schemas,
    makeFetch: true,
  });

  const overviewData = useFetchData({
    url: `schema_overview_monitor/${props.positionDate}` + schemas,
    keyName: `${fundTypes}_overview_data_${props.positionDate}`,
    makeFetch: props.positionDate ? true : false,
  });

  return (
    <GeneralComponentErrorShield
      dataObjects={[liquidityReports, overviewData!]}
    >
      <LiquidityOverviewTable
        section={currentSection || 'liquidity'}
        overviewData={overviewData}
        reportsList={liquidityReports}
        clientName={clientName || 'mersenne'}
      />
    </GeneralComponentErrorShield>
  );
};

export default LiquidityOverview;
