import * as React from 'react';
import { useDispatch } from 'react-redux';

import { FormEvent, useEffect, useState } from 'react';
import { greys, mainColors } from '../../../../styling/theme';
import { logOut } from '../../../../redux/auth/actions';
import RaptorWithRs from '../../../../images/RaptorWithRs';
import { zIndexes } from '../../../../styling/zIndexes';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { BASE_URL } from '../../../../utilities/requestClient';
import jwt_decode from 'jwt-decode';
import clsx from 'clsx';
import {
  validatePassword,
  validateUsername,
} from '../../../../utilities/accountDetailsValidation';
import { ClassNameMap, Tooltip } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Help, Visibility, VisibilityOff } from '@mui/icons-material';

const useStyles = makeStyles(() => ({
  mainContainer: {
    padding: '12vh 8vw',
    minWidth: 920,
    position: 'relative',
    height: '100%',
    zIndex: zIndexes.content,
  },
  raptorWithRs: {
    width: 200,
    marginBottom: '2rem',
  },
  pageTitle: {
    fontSize: '2.4rem',
    fontWeight: 500,
    marginBottom: '2rem',
    color: mainColors.mainBlue,
  },
  alreadyHaveAccount: {
    display: 'flex',
    gap: '0.5rem',
    alignItems: 'center',
    padding: '0 0 0.5rem 0',
    color: greys.grey600,
    fontWeight: 300,
    '& a': {
      color: mainColors.mainBlue,
      textDecoration: 'none',
    },
  },
  inputsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    marginTop: '1rem',
  },
  input: {
    padding: '1rem 0.5rem',
    borderRadius: '0.4rem',
    border: `1px solid ${greys.grey100}`,
    fontSize: '1.5rem',
    fontWeight: 400,
    color: greys.grey800,
    '&::placeholder': {
      color: greys.grey400,
    },
    width: '32rem',
  },
  input_password: {
    padding: '1rem 4rem 1rem 0.5rem',
    borderRadius: '0.4rem',
    border: `1px solid ${greys.grey100}`,
    fontSize: '1.5rem',
    fontWeight: 400,
    color: greys.grey800,
    '&::placeholder': {
      color: greys.grey400,
    },
    width: '32rem',
  },
  validationMessage: {
    fontSize: '1.2rem',
    fontWeight: 400,
    color: mainColors.Fail,
  },
  email: {
    fontSize: '1.6rem',
    fontWeight: 600,
    color: mainColors.mainBlue_lighter,
    padding: '0.5rem 0',
  },
  inputGroup: {
    padding: '1rem 0',
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
  },
  errorTitle: {
    fontSize: '1.6rem',
    fontWeight: 500,
    marginBottom: '1rem',
    color: mainColors.Fail,
  },
  description: {
    fontSize: '1.4rem',
    fontWeight: 400,
    marginBottom: '1rem',
    color: greys.grey600,
  },
  submitButton: {
    all: 'unset',
    marginTop: '1rem',
    width: '32rem',
    padding: '1rem 0',
    borderRadius: '0.4rem',
    fontSize: '1.5rem',
    fontWeight: 400,
    color: 'white',
    cursor: 'pointer',
    backgroundColor: mainColors.mainBlue,
    textAlign: 'center',
  },
  submitButton_inactive: {
    marginTop: '1rem',
    width: '32rem',
    padding: '1rem 0',
    borderRadius: '0.4rem',
    fontSize: '1.5rem',
    fontWeight: 400,
    color: 'white',
    backgroundColor: greys.grey300,
    textAlign: 'center',
  },
  newPasswordGroup: {
    display: 'flex',
    flexDirection: 'row',
    gap: '1rem',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  input_valid: {
    border: `1px solid ${mainColors.Pass}`,
    backgroundColor: mainColors.Pass_veryLight,
  },
  input_not_valid: {
    border: `1px solid ${mainColors.Fail}`,
    backgroundColor: mainColors.Fail_veryLight,
  },
  inputValidationMessageContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    cursor: 'pointer',
    gap: '0.5rem',
    '& svg': {
      fontSize: '1.6rem',
      color: greys.grey600,
    },
  },
  inputValidationMessage: {
    fontSize: '1.2rem',
    fontWeight: 400,
    color: mainColors.mainBlue,
  },
  inputValidationMessage_valid: {
    color: mainColors.Pass,
  },
  inputValidationMessage_not_valid: {
    color: mainColors.Fail,
  },
  tooltipContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.5rem',
  },
  tooltipTitle: {
    fontSize: '1.4rem',
    fontWeight: 600,
  },
  tooltipItem: {
    fontSize: '1rem',
  },
  passwordInput: {
    width: 'fit-content',
    position: 'relative',
  },
  showPasswordButton: {
    position: 'absolute',
    right: '1rem',
    top: '50%',
    transform: 'translateY(-40%)',
    cursor: 'pointer',
    '& svg': {
      fontSize: '2.5rem',
      color: greys.grey600,
    },
  },
  feedbackContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.5rem',
    marginTop: '1rem',
  },
  authMessage: {
    fontSize: '1.4rem',
    fontWeight: 600,
    color: greys.grey600,
    width: '32rem',
  },
}));

const usernameValidationStyles = (username: string, classes: ClassNameMap) => {
  if (username.length === 0) {
    return classes.input;
  } else if (username.length > 0 && validateUsername(username)) {
    return clsx(classes.input, classes.input_valid);
  } else {
    return clsx(classes.input, classes.input_not_valid);
  }
};

const passwordValidationStyles = (password: string, classes: ClassNameMap) => {
  if (password.length === 0) {
    return classes.input_password;
  } else if (password.length > 0 && validatePassword(password)) {
    return clsx(classes.input_password, classes.input_valid);
  } else {
    return clsx(classes.input_password, classes.input_not_valid);
  }
};

const confirmPasswordValidation = (
  password: string,
  confirmPassword: string,
  classes: ClassNameMap,
) => {
  if (confirmPassword.length === 0) {
    return classes.input;
  } else if (
    confirmPassword.length > 0 &&
    validatePassword(confirmPassword) &&
    password === confirmPassword
  ) {
    return clsx(classes.input, classes.input_valid);
  } else {
    return clsx(classes.input, classes.input_not_valid);
  }
};

const getAuthMessageColor = (authStatus: 'loading' | 'success' | 'error') => {
  switch (authStatus) {
    case 'loading':
      return greys.grey600;
    case 'success':
      return mainColors.Pass;
    case 'error':
      return mainColors.Fail;
  }
};

const Signup = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [validationMessage, setValidationMessage] = useState<string>('');

  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] =
    useState<boolean>(false);

  const [authMessage, setAuthMessage] = useState<string>('');
  const [authStatus, setAuthStatus] = useState<'loading' | 'error' | 'success'>(
    'error',
  );

  const [tokenValid, setTokenValid] = useState<
    'no_token' | 'invalid' | 'valid'
  >('no_token');
  const [userEmail, setUserEmail] = useState<string>('');
  const [userType, setUserType] = useState<string>('');
  const signupToken = location.search.split('token=')[1];

  // this function takes the user input for username and auto formats it with our desired format
  // currently this just means making all characters lowercase
  const handleUsernameInputEnforcement = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setUsername(event.target.value.toLowerCase());
  };

  useEffect(() => {
    dispatch(logOut());
    if (signupToken) {
      try {
        const decryptedToken: any = jwt_decode(signupToken);
        setUserEmail(decryptedToken.user_email);
        setUserType(decryptedToken.user_type);
        setTokenValid('valid');
      } catch (error) {
        setTokenValid('invalid');
      }
    } else {
      setTokenValid('no_token');
    }
  }, []);

  const handleSubmit = (e: FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    if (
      firstName.length === 0 ||
      lastName.length === 0 ||
      username.length === 0 ||
      password.length === 0
    ) {
      setValidationMessage('Please complete all fields');
      return;
    } else if (password !== confirmPassword) {
      setValidationMessage('Passwords do not match');
      return;
    } else if (!validateUsername(username)) {
      setValidationMessage('Username not valid');
      return;
    } else if (
      !validatePassword(password) ||
      !validatePassword(confirmPassword)
    ) {
      setValidationMessage('Password not valid');
      return;
    } else {
      setAuthStatus('loading');
      setValidationMessage('');
      axios
        .post(
          BASE_URL + 'raptor/create_user',
          {
            user_first_name: firstName,
            user_last_name: lastName,
            user_name: username,
            user_email: userEmail,
            user_type: userType,
            user_password: password,
          },
          {
            headers: {
              Authorization: 'Bearer ' + signupToken,
            },
          },
        )
        .then((response) => {
          if (response.status === 200) {
            setAuthStatus('success');
            setAuthMessage('Signup successful. Bringing you to login page...');
            setTimeout(() => {
              window.location.href = '/raptor/login';
            }, 1000);
          } else {
            setAuthStatus('error');
            setAuthMessage('Unknown Error. Please contact RiskSystem support.');
          }
        })
        .catch((error) => {
          if (error.response && error.response.status === 401) {
            setAuthStatus('error');
            setAuthMessage('Signup link expired. Please request a new one.');
          } else if (error.response && error.response.status === 403) {
            setAuthStatus('error');
            setAuthMessage(
              'Account limit reached. Please contact RiskSystem support.',
            );
          } else if (error.response && error.response.status === 409) {
            setAuthStatus('error');
            setAuthMessage('Username already exists. Please choose another.');
          } else if (error.response && error.response.status === 422) {
            setAuthStatus('error');
            setAuthMessage(
              'Username or password do not pass validation rules.',
            );
          } else {
            setAuthStatus('error');
            setAuthMessage('Unknown Error. Please contact RiskSystem support.');
          }
        });
    }
  };

  return (
    <div className={classes.mainContainer}>
      <RaptorWithRs className={classes.raptorWithRs} />
      <div>
        <div className={classes.pageTitle}>Signup</div>
        {tokenValid === 'valid' ? (
          <>
            <div className={classes.email}>{userEmail}</div>
            <div className={classes.alreadyHaveAccount}>
              <div>Already have an account?</div>
              <a href="/raptor/login">Login</a>
            </div>
            <div className={classes.description}>
              Please complete acccount signup below.
            </div>
            <form
              onSubmit={handleSubmit}
              autoComplete="off"
              className={classes.inputsContainer}
            >
              <div className={classes.inputGroup}>
                <input
                  id="first-name-input"
                  type="text"
                  placeholder="First Name"
                  autoComplete="off"
                  autoCorrect="off"
                  autoCapitalize="off"
                  spellCheck="false"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  className={classes.input}
                />
                <input
                  id="last-name-input"
                  type="text"
                  placeholder="Last Name"
                  autoComplete="off"
                  autoCorrect="off"
                  autoCapitalize="off"
                  spellCheck="false"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  className={classes.input}
                />
                <div className={classes.newPasswordGroup}>
                  <input
                    id="username-input"
                    type="text"
                    placeholder="Username"
                    autoComplete="off"
                    autoCorrect="off"
                    autoCapitalize="off"
                    spellCheck="false"
                    value={username}
                    onChange={(e) => handleUsernameInputEnforcement(e)}
                    className={usernameValidationStyles(username, classes)}
                  />
                  <div className={classes.inputValidationMessageContainer}>
                    <Tooltip
                      title={
                        <div className={classes.tooltipContainer}>
                          <div className={classes.tooltipTitle}>
                            We enforce the following username rules:
                          </div>
                          <div className={classes.tooltipItem}>
                            {' '}
                            - Must be between 1 and 30 characters long.
                          </div>
                          <div className={classes.tooltipItem}>
                            {' '}
                            - Can only contain lowercase letters, numbers,
                            underscores, hyphens and full stops.
                          </div>
                          <div className={classes.tooltipItem}>
                            {' '}
                            - Cannot contain more than one underscore, hyphen or
                            full stop in a row.
                          </div>
                          <div className={classes.tooltipItem}>
                            {' '}
                            - Cannot start or end with an underscore, hyphen or
                            full stop.
                          </div>
                          <br />
                          <div className={classes.tooltipTitle}>
                            Choose carefully, usernames cannot be changed.
                          </div>
                        </div>
                      }
                      placement="right-end"
                    >
                      <Help />
                    </Tooltip>
                    {username.length > 0 ? (
                      validateUsername(username) ? (
                        <div
                          className={clsx(
                            classes.inputValidationMessage,
                            classes.inputValidationMessage_valid,
                          )}
                        >
                          Username valid.
                        </div>
                      ) : (
                        <div
                          className={clsx(
                            classes.inputValidationMessage,
                            classes.inputValidationMessage_not_valid,
                          )}
                        >
                          Username not valid.
                        </div>
                      )
                    ) : null}
                  </div>
                </div>
              </div>
              <div className={classes.inputGroup}>
                <div className={classes.newPasswordGroup}>
                  <div className={classes.passwordInput}>
                    <input
                      id="password-input"
                      type={showPassword ? 'text' : 'password'}
                      placeholder="Password"
                      autoComplete="new-password"
                      autoCorrect="off"
                      autoCapitalize="off"
                      spellCheck="false"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      className={passwordValidationStyles(password, classes)}
                    />
                    <div
                      className={classes.showPasswordButton}
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </div>
                  </div>
                  <div className={classes.inputValidationMessageContainer}>
                    <Tooltip
                      title={
                        <div className={classes.tooltipContainer}>
                          <div className={classes.tooltipTitle}>
                            For security, we enforce the following password
                            rules:
                          </div>
                          <div className={classes.tooltipItem}>
                            {' '}
                            - Must be at least 12 characters long.
                          </div>
                          <div className={classes.tooltipItem}>
                            {' '}
                            - Must contain at least 1 uppercase letter.
                          </div>
                          <div className={classes.tooltipItem}>
                            {' '}
                            - Must contain at least 1 lowercase letter.
                          </div>
                          <div className={classes.tooltipItem}>
                            {' '}
                            - Must contain at least 1 number.
                          </div>
                          <div className={classes.tooltipItem}>
                            {' '}
                            - Must contain at least 1 special character.
                          </div>
                        </div>
                      }
                      placement="right-end"
                    >
                      <Help />
                    </Tooltip>
                    {password.length > 0 ? (
                      validatePassword(password) ? (
                        <div
                          className={clsx(
                            classes.inputValidationMessage,
                            classes.inputValidationMessage_valid,
                          )}
                        >
                          Password valid.
                        </div>
                      ) : (
                        <div
                          className={clsx(
                            classes.inputValidationMessage,
                            classes.inputValidationMessage_not_valid,
                          )}
                        >
                          Password not valid.
                        </div>
                      )
                    ) : null}
                  </div>
                </div>
                <div className={classes.newPasswordGroup}>
                  <div className={classes.passwordInput}>
                    <input
                      id="confirm-password-input"
                      type={showConfirmPassword ? 'text' : 'password'}
                      placeholder="Confirm Password"
                      autoComplete="new-password"
                      autoCorrect="off"
                      autoCapitalize="off"
                      spellCheck="false"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      className={confirmPasswordValidation(
                        password,
                        confirmPassword,
                        classes,
                      )}
                    />
                    <div
                      className={classes.showPasswordButton}
                      onClick={() =>
                        setShowConfirmPassword(!showConfirmPassword)
                      }
                    >
                      {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                    </div>
                  </div>
                  <div className={classes.inputValidationMessageContainer}>
                    <Tooltip
                      title={
                        <div className={classes.tooltipContainer}>
                          <div className={classes.tooltipTitle}>
                            For security, we enforce the following password
                            rules:
                          </div>
                          <div className={classes.tooltipItem}>
                            {' '}
                            - Must be at least 12 characters long.
                          </div>
                          <div className={classes.tooltipItem}>
                            {' '}
                            - Must contain at least 1 uppercase letter.
                          </div>
                          <div className={classes.tooltipItem}>
                            {' '}
                            - Must contain at least 1 lowercase letter.
                          </div>
                          <div className={classes.tooltipItem}>
                            {' '}
                            - Must contain at least 1 number.
                          </div>
                          <div className={classes.tooltipItem}>
                            {' '}
                            - Must contain at least 1 special character.
                          </div>
                        </div>
                      }
                      placement="right-end"
                    >
                      <Help />
                    </Tooltip>
                    {confirmPassword.length > 0 ? (
                      password === confirmPassword ? (
                        <div
                          className={clsx(
                            classes.inputValidationMessage,
                            classes.inputValidationMessage_valid,
                          )}
                        >
                          Passwords match.
                        </div>
                      ) : (
                        <div
                          className={clsx(
                            classes.inputValidationMessage,
                            classes.inputValidationMessage_not_valid,
                          )}
                        >
                          Passwords do not match.
                        </div>
                      )
                    ) : null}
                  </div>
                </div>
              </div>
              <div className={classes.validationMessage}>
                {validationMessage}
              </div>
              {firstName.length > 0 &&
              lastName.length > 0 &&
              username.length > 0 &&
              validateUsername(username) &&
              validatePassword(password) &&
              validatePassword(confirmPassword) &&
              password === confirmPassword ? (
                <button type="submit" className={classes.submitButton}>
                  Signup
                </button>
              ) : (
                <div className={classes.submitButton_inactive}>Signup</div>
              )}
              <div className={classes.feedbackContainer}>
                {authMessage && (
                  <div
                    className={classes.authMessage}
                    style={{
                      color: getAuthMessageColor(authStatus),
                    }}
                  >
                    {authMessage}
                  </div>
                )}
              </div>
            </form>
          </>
        ) : null}
        {tokenValid === 'no_token' ? (
          <>
            <div className={classes.errorTitle}>No signup token found.</div>
            <div className={classes.description}>
              If you have not received an email, please ask your system manager
              to send a signup invitation from their Raptor settings.
            </div>
          </>
        ) : null}
        {tokenValid === 'invalid' ? (
          <>
            <div className={classes.errorTitle}>Signup token is invalid.</div>
            <div className={classes.description}>
              Please ask your system manager to resend a signup invitation from
              their Raptor settings.
            </div>
          </>
        ) : null}
      </div>
    </div>
  );
};

export default Signup;
