import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchFundsData } from '../redux/pages/actions';
import { overviewFundsSelector } from '../redux/pages/selectors';
import axios from 'axios';
import { getFundsRoute } from '../routes/endpoints/general.routes';

export const useAppendOverviewFunds = (
  fundType?: string,
  rsResource?: string,
) => {
  const overviewFunds = useSelector(overviewFundsSelector);

  const cancelToken = axios.CancelToken;
  const source = cancelToken.source();

  const dispatch = useDispatch();
  useEffect(() => {
    if (fundType && rsResource) {
      if (!overviewFunds || !overviewFunds[`${fundType}_${rsResource}`]) {
        dispatch(
          fetchFundsData(
            fundType,
            rsResource,
            getFundsRoute(fundType, rsResource),
            source,
          ),
        );
      }
    }
  }, [fundType, rsResource, overviewFunds]);

  return overviewFunds && fundType
    ? overviewFunds[`${fundType}_${rsResource}`]?.requestedData
    : null;
};
