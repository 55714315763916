import React, { ReactElement } from 'react';
import {
  IRiskComparisonTablePositionChangesData,
  csvPosition,
  positionChangesColumns,
} from './data/positionChangesTable.data';
import {
  IRiskComparisonTableExposureChanges,
  csvExposureChanges,
  useExposureChangesColumns,
} from './data/exposureChangesTable.data';
import {
  IRiskComparisonTableVarChanges,
  csvVarChanges,
  useVarChangesColumns,
} from './data/varChangesTable.data';
import buildComparisonData from './data/buildComparisonTable';
import { CustomColumn } from '../../../../types/components/tables/tableTypes';
import GridItem from '../../../layout/GridComponents/GridItem';
import CustomTable from '../../../tables/CustomTable';
import useActivefund from '../../../../hooks/useActiveFund';

type IRiskComparisonType =
  | IRiskComparisonTablePositionChangesData
  | IRiskComparisonTableExposureChanges
  | IRiskComparisonTableVarChanges;

interface Props {
  referenceDate: string;
  comparisonDate: string;
  selectedSection: string;
  data: any;
  classes: any;
}

function RiskComparisonTable({
  data,
  referenceDate,
  comparisonDate,
  selectedSection,
}: Props): ReactElement {
  // const { exposureAnalysis, riskAnalysis, positionChanges } = data;

  const tableData = data
    ? buildComparisonData(selectedSection, data, referenceDate, comparisonDate)
    : [];

  const exposureColumns = useExposureChangesColumns(
    referenceDate,
    comparisonDate,
  );

  const varColumns = useVarChangesColumns(referenceDate, comparisonDate);

  const tableColumns: CustomColumn<any>[] = (() => {
    switch (selectedSection) {
      case 'positionChanges':
        return positionChangesColumns;
      case 'exposureChanges':
        return exposureColumns;
      case 'varChanges':
        return varColumns;
      default:
        return positionChangesColumns;
    }
  })();
  const activeFundDetails = useActivefund();

  return (
    <GridItem xs={12} card>
      <CustomTable<IRiskComparisonType>
        options={{
          paging: false,
          draggable: false,
          search: true,
          exportButton: true,
        }}
        columns={tableColumns}
        toolbarComponents={{
          toolbarTitle: activeFundDetails?.name,
        }}
        csvFields={(() => {
          switch (selectedSection) {
            case 'positionChanges':
              return csvPosition;
            case 'exposureChanges':
              return csvExposureChanges;
            case 'varChanges':
              return csvVarChanges;
            default:
              return [];
          }
        })()}
        data={tableData}
        showToolbar={true}
        loading={!tableData.length}
      />
    </GridItem>
  );
}

export default React.memo(RiskComparisonTable);
