import { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import useFetchData from '../../../../hooks/useFetchData';
import useTitleBarSelect from '../../../../hooks/useTitleBarSelect';
import {
  addAdditionalTitleBarComponents,
  toggleTitleBarLoading,
} from '../../../../redux/pages/actions';
import { Select } from '../../../../types/redux/pages/PagesStore';
import { APPEND_NEW_SELECT_TO_TITLEBAR } from '../../../../types/redux/pages/pageTypes';
import GeneralComponentErrorShield from '../../../general/GeneralComponentErrorShield';
import { FundInfoComponentProps } from '../../../general/GeneralFundInfoPage';
import DiscountCashflowConfigurationLineChart from './DCFConfigurationLineChart.component';
import DiscountCashflowConfigurationSummaryTable from './DCFConfigurationSummaryTable.component';
import DiscountCashflowConfigurationTable from './DCFConfigurationTable.component';
import { Grid } from '@mui/material';

const DiscountCashflowConfiguration: FC<FundInfoComponentProps> = ({
  fundId,
  positionDate,
}) => {
  // Create variable for deciding what is shown in the bar chart.
  const [barChartFilter, setBarChartFilter] = useState<string>('FCF');

  const [dropdownOptions, setDropdownOptions] = useState<any>(null);
  // Will be used to toggle between dollar values and percent of NAV.
  const [percentValueOption, setPercentValueOption] =
    useState<string>('percent');
  // Hardcoding in teh NAV for now, in future will be taken from the route.
  const nav = 10000000;
  const peData = useFetchData({
    url: `static_dcf_page/${fundId}/${positionDate}`,
    keyName: `static_dcf_page_${fundId}_${positionDate}`,
    makeFetch: positionDate && fundId ? true : false,
  });

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(toggleTitleBarLoading(true));
    if (peData && !peData.isFetching) {
      // Get the position Names
      const positions = peData.data[0];
      const positionNames = positions.map((item: any) => item.name);
      // Filter out Portfolio.
      const filteredPositionNames = positionNames.filter(
        (item: any) => item !== 'Portfolio',
      );
      // filteredPositionNames.sort((a: any, b: any) => parseInt(a.replace('pe_dummy', '')) > parseInt(b.replace('pe_dummy', '')));
      filteredPositionNames.sort(
        (a: any, b: any) =>
          a.replace('pe_dummy', '') - b.replace('pe_dummy', ''),
      );
      // Add Portfolio to start
      filteredPositionNames.unshift('Portfolio');
      // only update if positions have changed.
      if (
        dropdownOptions &&
        filteredPositionNames.join(',') === dropdownOptions.join(',')
      ) {
        dispatch(toggleTitleBarLoading(false));
      } else {
        setDropdownOptions(filteredPositionNames);
      }
    }
  }, [peData]);

  // add a dropdown for the scenarios
  useEffect(() => {
    if (dropdownOptions) {
      const selectsToAdd: Select[] = [
        {
          titleBarTitle: 'Select Position',
          titleBarKey: 'selectPosition',
          values: dropdownOptions.map((item: any) => ({
            label: item,
            value: item,
          })),
          displayOnOverviewPage: false,
          aValueIsAlwaysSelected: true,
          currentValue: 'Portfolio',
        },
      ];
      dispatch(
        addAdditionalTitleBarComponents(
          selectsToAdd,
          APPEND_NEW_SELECT_TO_TITLEBAR,
        ),
      );
      dispatch(toggleTitleBarLoading(false));
    }
  }, [dropdownOptions]);

  const selectedPosition = useTitleBarSelect('selectPosition');

  return (
    <Grid container>
      <GeneralComponentErrorShield
        dataObjects={[peData]}
        customLoadingMessages={['Loading Private Equity Data...']}
        customErrorMessage="No Data Available."
      >
        <DiscountCashflowConfigurationSummaryTable
          data={peData}
          selectedPosition={selectedPosition}
        />
        <DiscountCashflowConfigurationTable
          data={peData}
          selectedPosition={selectedPosition}
          setBarChartFilter={setBarChartFilter}
          barChartValue={barChartFilter}
          percentValueOption={percentValueOption}
          setPercentValueOption={setPercentValueOption}
        />
        <DiscountCashflowConfigurationLineChart
          data={peData}
          selectedPosition={selectedPosition}
          linechartFilter={barChartFilter}
          percentValueOption={percentValueOption}
        />
      </GeneralComponentErrorShield>
    </Grid>
  );
};

export default DiscountCashflowConfiguration;
