// This file contains custom sorting functions

import { Status } from '../types/redux/data/dataTypes';
import { specifyStatus } from './generalMappings';

export function statusSort<T>(
  a: T,
  b: T,
  key: keyof T,
  // additional key is needed for value at risk
  additionalKey?: keyof T,
): any {
  const definer: { [key: string]: number } = {
    [`${Status.Pass}`]: 1,
    [`${Status.Alert}`]: 2,
    [`${Status.Fail}`]: 3,
  };

  if (additionalKey) {
    // which status comes first
    if (
      definer[specifyStatus(a[key] as unknown as string)] ===
      definer[specifyStatus(b[key] as unknown as string)]
    ) {
      if (a[additionalKey] > b[additionalKey]) return -1;
      if (a[additionalKey] < b[additionalKey]) return 1;
    }
  }

  return definer[specifyStatus(a[key] as unknown as string)] >
    definer[specifyStatus(b[key] as unknown as string)]
    ? -1
    : 1;
}
