import React from 'react';
import { CustomColumn } from '../../../../types/components/tables/tableTypes';
import { Status } from '../../../../types/redux/data/dataTypes';
import {
  addCommasToNumbersAndRound,
  percentageToTwoDecimalPlaces,
  percentageToTwoDecimalPlacesNoHundred,
  toFourDecimalPlaces,
} from '../../../../utilities/numberFormatters';
import RaptorStatusBox from '../../../feedback/RaptorStatusBox.component';
import { specifyStatus } from '../../../../utilities/generalMappings';

export interface ShareClassHedgeTableData {
  shareClass: string;
  fxRate: number; // formatted to 4dp
  exposureUSD: number;
  hedgePL: number;
  exposurePcNav: number;
  fxHedge: string;
  exposureUsdFx: number; // this is the second exposure column
  exposurePcNavFx: number; // this is the second % nav column
  difference: number;
  differenceScNav: number;
  differenceScNavStatus: Status;
  monthlyRoll: string;
}

export const generateShareClassTableColumns = (
  baseCurrency?: string,
): CustomColumn<ShareClassHedgeTableData>[] => [
  {
    field: 'shareClass',
    title: 'Share Class',
    headerStyle: {
      textAlign: 'center',
    },
    cellStyle: {
      textAlign: 'center',
    },
  },
  {
    field: 'fxRate',
    title: 'FX Rate',
    render: (rowData) => toFourDecimalPlaces(rowData.fxRate),
    headerStyle: {
      textAlign: 'center',
    },
    cellStyle: {
      textAlign: 'center',
    },
  },
  {
    title: `S/C Exposure (${baseCurrency})`,
    field: 'exposureUSD',
    render: (rowData) => addCommasToNumbersAndRound(rowData.exposureUSD),
    headerStyle: {
      textAlign: 'center',
    },
    cellStyle: {
      textAlign: 'center',
    },
  },
  {
    title: 'Hedge P/L',
    field: 'hedgePL',
    render: (rowData) => addCommasToNumbersAndRound(rowData.hedgePL),
    headerStyle: {
      textAlign: 'center',
    },
    cellStyle: {
      textAlign: 'center',
    },
  },
  {
    title: 'S/C Exposure (% NAV)',
    field: 'exposurePcNav',
    render: (rowData) =>
      percentageToTwoDecimalPlacesNoHundred(rowData.exposurePcNav),
    width: '15rem',
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: {
      textAlign: 'center',
    },
  },
  {
    field: 'fxHedge',
    title: 'FX Hedge',
    headerStyle: {
      textAlign: 'center',
    },
    cellStyle: {
      textAlign: 'center',
    },
  },
  {
    field: 'exposureUsdFx', // this is the second exposure column
    title: 'FX Exposure (USD)',
    render: (rowData) => addCommasToNumbersAndRound(rowData.exposureUsdFx),

    headerStyle: {
      textAlign: 'center',
    },
    cellStyle: {
      textAlign: 'center',
    },
  },
  {
    field: 'exposurePcNavFx', // this is the second % nav column
    title: 'FX Exposure (% NAV)',
    render: (rowData) =>
      percentageToTwoDecimalPlacesNoHundred(rowData.exposurePcNavFx),

    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: {
      textAlign: 'center',
    },
  },
  {
    field: 'difference',
    title: 'Difference',
    render: (rowData) => addCommasToNumbersAndRound(rowData.difference),

    headerStyle: {
      textAlign: 'center',
    },
    cellStyle: {
      textAlign: 'center',
    },
  },
  {
    field: 'differenceScNav',
    title: 'Difference (% S/C NAV)',
    headerStyle: {
      textAlign: 'center',
    },
    cellStyle: {
      textAlign: 'center',
    },
    render: (rowData) => (
      <div style={{ margin: 'auto', width: '15rem', height: '4rem' }}>
        <RaptorStatusBox
          message={percentageToTwoDecimalPlaces(rowData.differenceScNav)}
          status={rowData.differenceScNavStatus}
        />
      </div>
    ),
  },
  {
    field: 'monthlyRoll',
    title: 'Monthly Roll',
  },
];

export function getNavDate(data: any): any {
  if (!data) {
    return [];
  }
  if (!('data' in data)) return [];
  if (!data.data.length) return [];
  return data.data[0].official_NAV_date;
}

export function generateShareClassHedgeData(data: any) {
  if (!data) {
    return [];
  }
  try {
    const { share_class_data, nav } = data;
    // share class data is an array
    /**
     *
     * shareClass is index 0,
     * fxRate is index 7
     * exposureUSD is index 4
     * hedgePL is index 8,
     * exposurePcNav is index 4 / nav probably
     * fxHedge is just look at the shareClass
     * exposureUsdFx is index 5
     * exposurePcNavFx is index 5 / nav probably
     * difference is index 1
     * differenceScNav is index 2 and color is
     * differenceScNavStatus is index 3
     * monthlyRoll is "no Action"
     */
    return share_class_data.map((datum: any) => {
      const [
        shareClass,
        difference,
        differenceScNav,
        differenceScNavStatus,
        exposureUSD,
        exposureUsdFx,
        unused,
        fxRate,
        hedgePL,
      ] = datum;
      return {
        shareClass,
        fxRate,
        exposureUSD,
        hedgePL,
        exposurePcNav: (exposureUSD / nav) * 100,
        fxHedge: shareClass.split('-')[0],
        exposureUsdFx,
        exposurePcNavFx: (exposureUsdFx / nav) * 100,
        difference,
        differenceScNav,
        differenceScNavStatus: specifyStatus(differenceScNavStatus),
        monthlyRoll: 'No Action',
      };
    });
  } catch (err) {
    console.error(err);
    throw err;
  }
}
