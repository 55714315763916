import React, { CSSProperties, Dispatch, FC } from 'react';
import GridItem from '../../../../../layout/GridComponents/GridItem';
import { mainColors } from '../../../../../../styling/theme';
import CustomTable from '../../../../../tables/CustomTable';
import {
  SrriStatusData,
  srriStatusColumns,
} from '../../../../ucits/synthetic-risk/SyntheticRisk.data';
import { CustomColumn } from '../../../../../../types/components/tables/tableTypes';
import { volatilityMap } from '../../../../ucits/syntheticRiskShareClass/SyntheticRiskShareClass.data';
import CommentEditorButton from '../Buttons/CommentEditorButton.component';

interface TableEditorTileProps {
  tileType: string;
  fundId: string | null;
  content: string;
  textSpan?: 'column' | 'full';
  formatOptions?: string;
  index: number;
  setKiidsContent: Dispatch<any>;
  kiidsContent: any[];
  shareClass: string;
  commentId: string;
  hasUnresolvedComments: boolean;
  setChangesMade: Dispatch<any>;
  setWasFieldTypeChanged: Dispatch<any>;
  setEditorTileComponents: Dispatch<any>;
  editorTileComponents: any[];
  setWasNewFieldAdded: Dispatch<any>;
}

// interface TableData {
//   [key: string]: TableRowData;
// }
// interface TableRowData {
//   [key: string]: EditorState;
// }

// function buildTableData(data: any): TableData {
//   const returnData: TableData = {};
//   if (!data.length) return {};
//   // Account for cases where the data is a flat array (one column table)
//   if (data[0].constructor === Array) {
//     // Loop over each row of the table
//     data.forEach((row: any, rowIndex: number) => {
//       // create an object for storing the current row's data.
//       const currentRow: TableRowData = {};
//       // Now Loop through each column in the row.
//       row.forEach((column: any, columnIndex: number) => {
//         // Create an editor state object from the cell contents.
//         const blocksFromHTML = convertFromHTML(column.toString());
//         const contentState = ContentState.createFromBlockArray(
//           blocksFromHTML.contentBlocks
//         );
//         const initialState = EditorState.createWithContent(contentState);
//         // Add each column to the row.
//         currentRow[`col_${columnIndex}`] = initialState;
//       });
//       // Add the row to the table.
//       returnData[`row_${rowIndex}`] = currentRow;
//     });
//   } else {
//     // This is the flat array case
//     // Loop through each row.
//     data.forEach((row: any, rowIndex: number) => {
//       // Create an editor state object from the cell contents.
//       const blocksFromHTML = convertFromHTML(row);
//       const contentState = ContentState.createFromBlockArray(
//         blocksFromHTML.contentBlocks
//       );
//       const initialState = EditorState.createWithContent(contentState);
//       returnData[`row_${rowIndex}`] = { col_1: initialState };
//     });
//   }
//   return returnData;
// }

const KiidsSrriChartEditor: FC<TableEditorTileProps> = (props) => {
  /*
    NOTE: For now the srri editor will be used to display the srri of the fund only.
    No editing options will be given for the time being.
    The funcitonality will eventually be extended to allow more editing options.
    */

  const { fundId, content, setKiidsContent, kiidsContent, index } = props;

  return (
    <GridItem
      xs={12}
      card
      style={{
        marginBottom: '1rem',
      }}
    >
      <div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'right',
            marginTop: '0.5rem',
            marginRight: '1rem',
            height: '5rem',
          }}
        >
          {/* <ChangeFieldTypeButton
            fieldType={props.tileType}
            setKiidsContent={props.setKiidsContent}
            kiidsContent={props.kiidsContent}
            index={props.index}
            setChangesMade={props.setChangesMade}
            setWasFieldTypeChanged={props.setWasFieldTypeChanged}
          /> */}
          <CommentEditorButton
            fundId={props.fundId}
            shareClass={props.shareClass}
            commentId={props.commentId}
            kidIndex={props.index}
            hasUnresolvedComments={props.hasUnresolvedComments}
            setKiidsContent={props.setKiidsContent}
            kiidsContent={props.kiidsContent}
          />
          {/* <RemoveFieldButton
            index={index}
            setKiidsContent={props.setKiidsContent}
            kiidsContent={props.kiidsContent}
            setChangesMade={props.setChangesMade}
            setWasFieldTypeChanged={props.setWasFieldTypeChanged}
            setWasNewFieldAdded={props.setWasNewFieldAdded}
            setEditorTileComponents={props.setEditorTileComponents}
            editorTileComponents={props.editorTileComponents}
          /> */}
        </div>
        <div
          style={{
            padding: '1rem',
            fontSize: '1.5rem',
            minHeight: '10rem',
            // display: 'flex',
            justifyContent: 'space-around',
          }}
        >
          <SrriStatusBox srri={content ? parseInt(content) : 4} />
        </div>
      </div>
    </GridItem>
  );
};

function generateStatusBoxData(
  srri: number,
): [SrriStatusData[], CustomColumn<SrriStatusData>[]] {
  const returnObj: any = {};
  Object.keys(volatilityMap).forEach((volatilityKey) => {
    returnObj[volatilityKey as keyof SrriStatusData] =
      volatilityMap[volatilityKey as keyof SrriStatusData].label;
  });
  const columns = srriStatusColumns.map((col) => {
    return {
      ...col,
      cellStyle: {
        textAlign: 'center' as CSSProperties['textAlign'],
        backgroundColor:
          srri.toString() === col.title ? mainColors.mainBlue : undefined,
        color: srri.toString() === col.title ? 'white' : '#8f8f8f',
        // borderTop: '1px solid #DFDFDF',
        borderBottom:
          srri.toString() === col.title
            ? `1px solid ${mainColors.mainBlue}`
            : '1px solid #8f8f8f',
        borderRight:
          srri.toString() === col.title
            ? `1px solid ${mainColors.mainBlue}`
            : '1px solid #8f8f8f',
        borderLeft:
          srri.toString() === col.title
            ? `1px solid ${mainColors.mainBlue}`
            : '1px solid #8f8f8f',
      },
      headerStyle: {
        textAlign: 'center' as CSSProperties['textAlign'],
        backgroundColor:
          srri.toString() === col.title ? mainColors.mainBlue : undefined,
        color: srri.toString() === col.title ? 'white' : '#8f8f8f',
        borderTop:
          srri.toString() === col.title
            ? `1px solid ${mainColors.mainBlue}`
            : '1px solid #8f8f8f',
        borderRight:
          srri.toString() === col.title
            ? `1px solid ${mainColors.mainBlue}`
            : '1px solid #8f8f8f',
        borderLeft:
          srri.toString() === col.title
            ? `1px solid ${mainColors.mainBlue}`
            : '1px solid #8f8f8f',
        borderBottom:
          srri.toString() === col.title
            ? `1px solid ${mainColors.mainBlue}`
            : 'none',
        fontSize: '1.5rem',
      },
    };
  });

  return [[returnObj], columns];
}

const SrriStatusBox: React.FC<{ srri: number }> = ({ srri }) => {
  const [tableData, columns] = generateStatusBoxData(srri);
  // console.log('SRRI TABLE DATA RAW', data);
  // console.log('SRRI TABLE DATA EDITED', columns);
  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <div>
          <span>Lower Risk</span>
          <br />
          <span>Typically Lower Rewards</span>
        </div>
        <div>
          <span>Higher Risk</span>
          <br />
          <span>Typically Higher Rewards</span>
        </div>
      </div>
      <CustomTable<SrriStatusData>
        toolbarComponents={{
          titleStyle: {
            fontWeight: 300,
          },
        }}
        data={tableData || []}
        columns={columns}
        options={{ paging: false, header: true }}
        showToolbar
        title=""
      />
    </div>
  );
};

export default KiidsSrriChartEditor;
