import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import MaterialTable, {
  MTableBody,
  MTableHeader,
  MaterialTableProps,
  Options,
} from 'rs-material-table';
// unfortunatley Raphael for some reason built the customtable code dependent on his own fork of the material-table library
// we had to make this package, 'rs-material-table', to prevent direct dependency to his github repo
// this is a temporary solution until we can refactor the custom table code to not be dependent on his fork
import React, {
  CSSProperties,
  ReactElement,
  forwardRef,
  useEffect,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { UseFundNoteProps } from '../../hooks/useFundNotes';
import { addComponentToPdfExport } from '../../redux/pdfExport/actions';
import { RaptorTheme, mainColors } from '../../styling/theme';
import { CustomColumn } from '../../types/components/tables/tableTypes';
import { Status } from '../../types/redux/data/dataTypes';
import {
  AvailableWidths,
  PdfComponentType,
} from '../../types/redux/pdfExports/pdfExportsStore';
import DisplayAreaCenteredWrapper from '../layout/utilities/displayAreaWrapper';
import './customTable.css';
import CustomTableToolbar from './CustomTableToolbar';
import TableLoadingCover from './TableLoadingCover';
import { ColumnIdToHeaderMapType } from '../feedback/ExportButton';
import { clientNameSelector } from '../../redux/auth/selectors';
import usePngFromComponent from '../../hooks/usePngFromComponent';
import { useTheme } from '@mui/material';
import { ArrowDropDown, SaveAlt } from '@mui/icons-material';

const CustomDropdownIcon = (props: any) => (
  <ArrowDropDown style={{ color: 'red', fontSize: 30 }} {...props} />
);

const useStyles = makeStyles({
  customTableRoot: {
    height: '100%',
    width: '100%',
    overflow: 'hidden',
  },
  toolbarContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '1rem',
    alignItems: 'flex-end',
    marginRight: 0,
  },
});

interface CustomOptions extends Options {
  excludeHeader?: boolean;
  raptorExport?: boolean;
}

export interface ToolbarComponents {
  toolbarTitle?: React.ReactNode;
  titleStyle?: CSSProperties;
  selects?: React.ReactNode[];
  status?: Status | null;
  otherComponents?: React.ReactNode[];
  tabs?: boolean;
  toolbarStyle?: CSSProperties;
}

export type MultipleTablesInRowParameters = {
  specifiedWidth: AvailableWidths;
  tablesInRow: number;
  // These Prameters are included to ensure that the tables are added to the pdf one after another.
  // This avoids issues of tables ending up on different pages or overlapping other components
  groupName?: string;
  groupOrder?: number;
};

export interface CustomTableProps<T extends object> {
  loading?: boolean;
  detailPanel?: MaterialTableProps<T>['detailPanel'];
  onRowClick?: MaterialTableProps<T>['onRowClick'];
  columns: CustomColumn<T>[];
  csvFields?: string[];
  fieldsMap?: ColumnIdToHeaderMapType;
  // Optionally provide different columns to be exported to the pdf tnan what is displayed in raptor.
  pdfColumns?: CustomColumn<T>[];
  options: CustomOptions;
  toolbarComponents?: ToolbarComponents;
  fundSelect?: React.ReactNode;
  loadingHeight?: string;
  afterBodyComponents?: React.ReactNode[]; // any components that we might want to render after the body
  showToolbar: boolean;
  exportFileName?: string;
  data: T[] | Error;
  exportData?: T[] | Error;
  id?: string;
  title?: any;
  additionalDetailForPdf?: string;
  multipleTablePdfParameters?: MultipleTablesInRowParameters;
  noPdfExport?: boolean;
  noteExport?: UseFundNoteProps;
  // if this is set to true, then the PDF will try to include all of the columns, rather than
  // removing ones that have bad data types (e.g not numbers or strings)
  pdfNoClearFirstRow?: boolean;
  selectedPositionDate?: string;
  // you can pass a useStyles string to manipulate the container
  tableRootStyles?: string;
  emptyDataSourceMessage?: string;
  pdfTitle?: string; // Alows us to give thge chart a title in pdf but not on page
  pdfSpecificYPosition?: number;
  pdfSpecificXPosition?: number;
  pdfDontMoveToNewPage?: boolean;
  imageToPdf?: boolean; // this takes a screengrab of the table to export to pdf, rather than buikding the table with autoTable. This allows for custom cell styling to be seen.
}

const CustomTable = <T extends object>(
  props: CustomTableProps<T>,
): ReactElement => {
  const classes = useStyles();
  const theme: RaptorTheme = useTheme();
  const tableRef = React.useRef<any>(null);
  const dispatch = useDispatch();

  const { data, loading, columns } = props;

  // Need to check if the user is Adepa as updated column names breaks their excel sheets
  const clientName = useSelector(clientNameSelector) || 'mersenne';

  const searchHandler = (input: string) => {
    // FIX - Hack - https://github.com/mbrn/material-table/issues/2302
    // tableRef.current.onSearchChange deprecated and bug wontfix in material table: Old package
    // This is a manual search handler that works with the new package

    tableRef.current.dataManager.changeSearchText(input);
    tableRef.current.setState({ searchText: input });
    tableRef.current.setState(tableRef.current.dataManager.getRenderState());
  };
  // QUESTION: does this need to be async?
  const tablePdfExportHandler = async () => {
    /*
     * We have to go over
     *
     */
    const columnsForPdf = props.pdfColumns ? props.pdfColumns : columns;
    const renderColumns = columnsForPdf.map((col) => ({
      header: col.title,
      dataKey: col.field,
      headerStyle: col.headerStyle,
      cellStyle: col.cellStyle,
      render: col.render,
      pdfRenderType: col.pdfRenderType,
      pdfWidth: col.pdfWidth,
    }));

    return {
      startY: 50,
      columns: renderColumns,
      body: data,
      dontCropHeaderRow: props.pdfNoClearFirstRow,
    };
  };
  let pdfIdentifier: string;
  if (props.exportFileName) {
    pdfIdentifier = props.exportFileName;
  } else if (props.id) {
    pdfIdentifier = props.id;
  } else if (props.title && typeof props.title === 'string') {
    pdfIdentifier = props.title.split(' ').join('_').toLowerCase();
  } else {
    pdfIdentifier = 'exported_table_data';
  }

  const { ref, handleDownload } = usePngFromComponent();

  // We need to first check the type of title to ensurte it is a string, to avoid errors
  // As hierarchy, we take the pdfTitle, else then the title, else then an empty string
  const tableTitle = () => {
    if (props.pdfTitle && typeof props.pdfTitle === 'string') {
      return props.pdfTitle;
    } else if (props.title && typeof props.title === 'string') {
      return props.title;
    } else {
      return '';
    }
  };

  useEffect(() => {
    if (!props.noPdfExport) {
      if (props.imageToPdf === true) {
        dispatch(
          addComponentToPdfExport({
            identifier: pdfIdentifier,
            handler: handleDownload,
            type: PdfComponentType.GENERAL_COMPONENT_IMAGE,
            title: tableTitle(),
            dontMoveToNewPage: true,
            specificYPosition: undefined,
            specificXPosition: undefined,
            alignment: undefined,
            dontIncrementYPosition: false,
          }),
        );
      } else {
        dispatch(
          addComponentToPdfExport({
            identifier: pdfIdentifier,
            handler: tablePdfExportHandler,
            type: PdfComponentType.TABLE,
            title: tableTitle(),
            specifiedWidth: props.multipleTablePdfParameters?.specifiedWidth,
            tablesInRow: props.multipleTablePdfParameters?.tablesInRow,
            additionalComponentDetail: props.additionalDetailForPdf,
            positionDate: props.selectedPositionDate,
            specificYPosition: props.pdfSpecificYPosition
              ? props.pdfSpecificYPosition
              : undefined,
            specificXPosition: props.pdfSpecificXPosition
              ? props.pdfSpecificXPosition
              : undefined,
            groupName: props.multipleTablePdfParameters?.groupName
              ? props.multipleTablePdfParameters?.groupName
              : undefined,
            groupOrder: props.multipleTablePdfParameters?.groupOrder
              ? props.multipleTablePdfParameters?.groupOrder
              : undefined,
            dontMoveToNewPage: props.pdfDontMoveToNewPage ?? true,
          }),
        );
      }
    }
  }, [data, ref]);

  const createCsvFieldsFromColumns = (columns: CustomColumn<T>[]): string[] => {
    const fields: string[] = [];
    columns.forEach((col: CustomColumn<T>) => {
      if (col.field) {
        fields.push(col.field as string);
      }
    });
    return fields;
  };

  type MapType = {
    label: string;
    key: string;
  }[];

  const createCsvMapFromColumns = (
    clientName: string,
    columns: CustomColumn<T>[],
  ): MapType | undefined => {
    // Adepa have automation scripts built using the old col.field keys, so they don't want them changed
    if (clientName !== 'adepa') {
      const map: MapType = [];
      columns.forEach((col: CustomColumn<T>) => {
        if (col.field && col.title) {
          map.push({
            key: col.field as string,
            label: col.title as string,
          });
        }
      });

      return map;
    } else {
      return undefined;
    }
  };

  return Array.isArray(data) ? (
    <div ref={ref}>
      <MaterialTable<T>
        localization={{
          body: {
            emptyDataSourceMessage: props.emptyDataSourceMessage
              ? props.emptyDataSourceMessage
              : 'No Records to Display',
          },
        }}
        icons={{
          Export: forwardRef(() => <SaveAlt fontSize="large" />),
          ViewColumn: forwardRef(() => <CustomDropdownIcon />),
        }}
        tableRef={tableRef}
        components={{
          Container: (innerProps) => (
            <div
              className={clsx(classes.customTableRoot, props.tableRootStyles)}
            >
              {innerProps.children}
            </div>
          ),
          Header: (mtProps) => {
            return !loading ? <MTableHeader {...mtProps} /> : null;
          },
          Body: (mtProps) => {
            return !loading ? (
              <>
                <MTableBody {...mtProps} data={mtProps.data as T[]} />
                {props.afterBodyComponents}
              </>
            ) : (
              <TableLoadingCover
                style={{
                  minHeight: loading
                    ? props.loadingHeight
                      ? props.loadingHeight
                      : '30vh'
                    : 0,
                  width: '100%',
                }}
                loading={loading || false}
              />
            );
          },
          Toolbar: () => {
            return props.showToolbar ? (
              <CustomTableToolbar
                tableId={pdfIdentifier}
                {...props}
                searchHandler={searchHandler}
                csvFields={
                  props.csvFields || createCsvFieldsFromColumns(props.columns)
                }
                fieldsMap={
                  props.fieldsMap ||
                  createCsvMapFromColumns(clientName, props.columns)
                }
                data={Array.isArray(props.exportData) ? props.exportData : data}
                title={
                  props.title ||
                  (props.toolbarComponents?.toolbarTitle as string)
                }
                allowNoteExport={props.noteExport}
                exportFileName={props.exportFileName}
                toolbarComponents={props.toolbarComponents || null}
                options={props.options}
                pdfExport={props.noPdfExport ? false : true}
                columns={props.columns}
                selectedPositionDate={props.selectedPositionDate}
              />
            ) : (
              <></>
            );
          },
        }}
        {...props}
        data={data as T[]}
        columns={props.columns}
        options={{
          ...props.options,
          debounceInterval: 0,
          headerStyle: {
            ...props.options.headerStyle,
            fontSize: '1.2rem',
            textTransform: 'uppercase',
            color: mainColors.mainBlue,
            fontWeight: 700,
            padding: '0.8rem',
            zIndex: 1,
            borderBottom: theme.borders!.main,
          },
          rowStyle: (rowData, index, level) => {
            // Determine the base style. If rowStyle is a function, call it with rowData, index, and level.
            // Otherwise, use rowStyle as an object or default to an empty object - passing as rowdata to wrapper
            // function broke perhaps in the migration so we do it manually in the root component hence spreading
            // doesnt yield any styles
            const baseStyle =
              typeof props.options?.rowStyle === 'function'
                ? props.options.rowStyle(rowData, index, level)
                : props.options?.rowStyle || {};

            return {
              ...baseStyle,
              fontSize: '1.4rem',
              fontWeight: 400,
              padding: '0.8rem',
              borderBottom: theme.borders!.main,
            };
          },
          searchFieldStyle: {
            display: 'none',
          },
          showTitle: false,
        }}
      />
    </div>
  ) : data ? (
    <DisplayAreaCenteredWrapper noMinHeight>
      <h2 style={{ color: mainColors.mainBlue, margin: 0 }}>
        Unable to process table data
      </h2>
    </DisplayAreaCenteredWrapper>
  ) : (
    <DisplayAreaCenteredWrapper>
      <h2 style={{ color: mainColors.mainBlue, margin: 0 }}>
        No data provided to table
      </h2>
    </DisplayAreaCenteredWrapper>
  );
};
export default CustomTable;
