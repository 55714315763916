import {
  addCommasToNumbersAndRound,
  percentageToTwoDecimalPlaces,
} from '../../../../../utilities/numberFormatters';
import { CustomColumn } from '../../../../../types/components/tables/tableTypes';

export interface CurrencyData {
  number: number;
  fxCurrency: string;
  cash: number;
  forex: number;
  asset: number;
  unrealisedPl: number;
  exposure: number;
  exposurePc: number;
}

export interface CurrencyServerData {
  base_currency: string;
  currency_exposure: Array<string | number>[];
  nav: number;
  selected_position_date: string;
  sorted_currency_exposure: {
    // keys are currencies
    [key: string]: {
      short: number;
      long: number;
    };
  };
}

export const generateCurrencyColumns = (): CustomColumn<CurrencyData>[] => {
  return [
    {
      title: '#',
      field: 'number',
      width: 1,
    },
    {
      title: 'FX Currency',
      field: 'fxCurrency',
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
      },
    },
    {
      title: 'Cash',
      field: 'cash',
      render: (rowData) => addCommasToNumbersAndRound(rowData.cash),
    },
    {
      title: 'Foreign Exchange',
      field: 'forex',
      render: (rowData) => addCommasToNumbersAndRound(rowData.forex),
    },
    {
      title: 'Asset',
      field: 'asset',
      render: (rowData) => addCommasToNumbersAndRound(rowData.asset),
    },
    {
      title: 'Unrealised P/L',
      field: 'unrealisedPl',
    },
    {
      title: 'Exposure',
      field: 'exposure',
      render: (rowData) => addCommasToNumbersAndRound(rowData.exposure),
    },
    {
      title: 'Exposure (%)',
      field: 'exposurePc',
      render: (rowData) => percentageToTwoDecimalPlaces(rowData.exposurePc),
    },
  ];
};

export function generateCurrencyData(
  inputData: CurrencyServerData[],
): CurrencyData[] {
  if (!inputData.length) return [];
  try {
    const dataForUse = [...inputData[0].currency_exposure];
    const nav = inputData[0].nav;
    // remove the top row
    dataForUse.shift();
    return dataForUse.map((el, index) => {
      return {
        number: (index + 1) as number,
        fxCurrency: el[0] as string,
        cash: el[1] as number,
        forex: el[2] as number,
        asset: el[3] as number,
        unrealisedPl: el[4] as number,
        exposure: el[5] as number,
        exposurePc: (el[5] as number) / nav,
      };
    });
  } catch (error) {
    return [];
  }
}
