import {
  ColumnData,
  ColumnFields,
  PresetExposureType,
} from '../types/column.types';

// 200px estimated padding and index - This is still hardcoded at 12 column minsize which is not ideal
const SIZE = (window.innerWidth - 200) / 12;
const MIN_SIZE = 100;

// All Preset Columns and their visibility/order
export const allPresetColumns: Record<PresetExposureType, string[]> = {
  alternativeExposure: [
    ColumnFields.INDEX,
    ColumnFields.NAME,
    ColumnFields.ISIN,
    ColumnFields.ASSET_TYPE,
    ColumnFields.CURRENCY,
    ColumnFields.FX,
    ColumnFields.BC_EXPOSURE,
    ColumnFields.BC_EXPOSURE_PCT,
    ColumnFields.GROSS_EXPOSURE,
    ColumnFields.GROSS_EXPOSURE_PCT,
    ColumnFields.COMMITMENT,
    ColumnFields.COMMITMENT_PCT,
  ],
  generalExposure: [
    ColumnFields.INDEX,
    ColumnFields.NAME,
    ColumnFields.ISIN,
    ColumnFields.INSTRUMENT_TITLE,
    ColumnFields.ASSET_TYPE,
    ColumnFields.CURRENCY,
    ColumnFields.FX,
    ColumnFields.BC_EXPOSURE,
    ColumnFields.BC_EXPOSURE_PCT,
    ColumnFields.GROSS_EXPOSURE,
    ColumnFields.GROSS_EXPOSURE_PCT,
    ColumnFields.COMMITMENT,
    ColumnFields.COMMITMENT_PCT,
  ],
  amlExposure: [
    ColumnFields.INDEX,
    ColumnFields.NAME,
    ColumnFields.ISIN,
    ColumnFields.ASSET_TYPE,
    ColumnFields.CURRENCY,
    ColumnFields.FX,
    ColumnFields.LC_EXPOSURE_PCT,
    ColumnFields.BC_EXPOSURE,
    ColumnFields.BC_EXPOSURE_PCT,
    ColumnFields.GROSS_EXPOSURE,
    ColumnFields.GROSS_EXPOSURE_PCT,
    ColumnFields.COUNTRY_CODE,
    ColumnFields.SECTOR_NAME,
    ColumnFields.IS_LISTED,
    ColumnFields.AML_EXPOSURE,
  ],
  countryExposure: [
    ColumnFields.INDEX,
    ColumnFields.NAME,
    ColumnFields.ISIN,
    ColumnFields.ASSET_TYPE,
    ColumnFields.CURRENCY,
    ColumnFields.FX,
    ColumnFields.BC_EXPOSURE,
    ColumnFields.GROSS_EXPOSURE,
    ColumnFields.GROSS_EXPOSURE_PCT,
    ColumnFields.COUNTRY_CODE_RISK_LEVEL,
    ColumnFields.COUNTRY_CODE,
  ],
  cashExposure: [
    ColumnFields.INDEX,
    ColumnFields.NAME,
    ColumnFields.ISIN,
    ColumnFields.ASSET_TYPE,
    ColumnFields.CURRENCY,
    ColumnFields.FX,
    ColumnFields.BC_EXPOSURE,
    ColumnFields.BC_EXPOSURE_PCT,
    ColumnFields.GROSS_EXPOSURE,
    ColumnFields.GROSS_EXPOSURE_PCT,
    ColumnFields.COMMITMENT,
    ColumnFields.COMMITMENT_PCT,
  ],
  commodityExposure: [
    ColumnFields.INDEX,
    ColumnFields.NAME,
    ColumnFields.ISIN,
    ColumnFields.ASSET_TYPE,
    ColumnFields.CURRENCY,
    ColumnFields.FX,
    ColumnFields.BC_EXPOSURE,
    ColumnFields.BC_EXPOSURE_PCT,
    ColumnFields.GROSS_EXPOSURE,
    ColumnFields.GROSS_EXPOSURE_PCT,
    ColumnFields.COMMITMENT,
    ColumnFields.COMMITMENT_PCT,
  ],
  equityExposure: [
    ColumnFields.INDEX,
    ColumnFields.NAME,
    ColumnFields.ISIN,
    ColumnFields.ASSET_TYPE,
    ColumnFields.SECTOR_NAME,
    ColumnFields.YIELD,
    ColumnFields.CURRENCY,
    ColumnFields.COUNTRY_CODE,
    ColumnFields.FX,
    ColumnFields.PRICE,
    ColumnFields.RISK_FACTOR,
    ColumnFields.NOTIONAL_AMOUNT,
    ColumnFields.OUTSTANDING_SECURITIES,
    ColumnFields.GROSS_EXPOSURE,
    ColumnFields.GROSS_EXPOSURE_PCT,
    ColumnFields.COMMITMENT,
    ColumnFields.COMMITMENT_PCT,
  ],
  fixedIncomeExposure: [
    ColumnFields.INDEX,
    ColumnFields.NAME,
    ColumnFields.ISIN,
    ColumnFields.ASSET_TYPE,
    ColumnFields.COUPON,
    ColumnFields.MATURITY_DATE,
    ColumnFields.CURRENCY,
    ColumnFields.COUNTRY_CODE,
    ColumnFields.TIME_TO_MATURITY,
    ColumnFields.YIELD,
    ColumnFields.PRICE,
    ColumnFields.NOTIONAL_AMOUNT,
    ColumnFields.OUTSTANDING_SECURITIES,
    ColumnFields.GROSS_EXPOSURE,
    ColumnFields.GROSS_EXPOSURE_PCT,
    ColumnFields.DURATION,
    ColumnFields.DV01,
    ColumnFields.CREDIT_RATING,
    ColumnFields.IS_CB,
  ],
  foreginExchangeExposure: [
    ColumnFields.INDEX,
    ColumnFields.NAME,
    ColumnFields.ISIN,
    ColumnFields.ASSET_TYPE,
    ColumnFields.CURRENCY,
    ColumnFields.FX,
    ColumnFields.BC_EXPOSURE,
    ColumnFields.BC_EXPOSURE_PCT,
    ColumnFields.GROSS_EXPOSURE,
    ColumnFields.GROSS_EXPOSURE_PCT,
    ColumnFields.COMMITMENT,
    ColumnFields.COMMITMENT_PCT,
  ],
};

export const ALL_EXPOSURE_COLUMNS: ColumnData[] = [
  // {
  //   field: 'all',
  //   title: '',
  //   show: false,
  //   minSize: 60,
  //   size: 60,
  //   enableResizing: false,
  //   enableColumnFilter: false,
  // },
  {
    id: 'name',
    title: 'Asset Name',
    filterVariant: 'select',
    filterFn: 'arrIncludesSome',
    size: 300,
    minSize: MIN_SIZE,
  },
  {
    id: 'isin',
    title: 'ISIN',
    filterVariant: 'select',
    filterFn: 'arrIncludesSome',
    size: SIZE,
    minSize: MIN_SIZE,
  },
  {
    id: 'instrument_title',
    title: 'Instrument Title',
    filterVariant: 'select',
    filterFn: 'arrIncludesSome',
    size: SIZE,
    minSize: MIN_SIZE,
  },
  {
    id: 'instrument_description_title',
    title: 'Instrument Description',
    filterVariant: 'select',
    filterFn: 'arrIncludesSome',
    size: SIZE,
    minSize: MIN_SIZE,
  },
  {
    id: 'asset_class',
    title: 'Asset Class',
    filterVariant: 'select',
    filterFn: 'arrIncludesSome',
    size: SIZE,
    minSize: MIN_SIZE,
  },
  {
    id: 'asset_type',
    title: 'Asset Type',
    filterVariant: 'select',
    filterFn: 'arrIncludesSome',
    size: SIZE,
    minSize: MIN_SIZE,
  },
  {
    id: 'currency',
    title: 'Currency',
    filterVariant: 'select',
    filterFn: 'arrIncludesSome',
    size: SIZE,
    minSize: MIN_SIZE,
  },

  {
    id: 'fx',
    title: 'FX Rate',
    filterVariant: 'range',
    size: SIZE,
    minSize: MIN_SIZE,
  },
  {
    id: 'lc_exposure',
    title: 'Local Exposure',
    filterVariant: 'range',
    size: SIZE,
    minSize: MIN_SIZE,
  },
  {
    id: 'lc_exposure_pct',
    title: 'Local Exposure %',
    filterVariant: 'range',
    size: SIZE,
    minSize: MIN_SIZE,
  },
  {
    id: 'bc_exposure',
    title: 'Base Exposure',
    filterVariant: 'range',
    size: SIZE,
    minSize: MIN_SIZE,
  },
  {
    id: 'bc_exposure_pct',
    title: 'Base Exposure %',
    filterVariant: 'range',
    size: SIZE,
    minSize: MIN_SIZE,
  },
  {
    id: 'gross_exposure',
    title: 'Gross Exposure',
    filterVariant: 'range',
    size: SIZE,
    minSize: MIN_SIZE,
  },
  {
    id: 'gross_exposure_pct',
    title: 'Gross Exposure %',
    filterVariant: 'range',
    size: SIZE,
    minSize: MIN_SIZE,
  },
  {
    id: 'commitment',
    title: 'Commitment',
    filterVariant: 'range',
    size: SIZE,
    minSize: MIN_SIZE,
  },
  {
    id: 'commitment_pct',
    title: 'Commitment %',
    filterVariant: 'range',
    size: SIZE,
    minSize: MIN_SIZE,
  },
  // {
  //   title: 'Notes',
  //   id: 'notes',
  //   show: false,
  // },
  {
    id: 'aml_exposure',
    title: 'AML Exposure',
    filterVariant: 'range',
    size: SIZE,
    minSize: MIN_SIZE,
  },
  {
    id: 'country_code_risk_level',
    title: 'Country Code Risk Level',
    filterVariant: 'range',
    size: SIZE,
    minSize: MIN_SIZE,
  },
  {
    id: 'country_code',
    title: 'Country Code',
    filterVariant: 'select',
    filterFn: 'arrIncludesSome',
    size: SIZE,
    minSize: MIN_SIZE,
  },
  {
    id: 'client_price',
    title: 'Client Price',
    filterVariant: 'range',
    size: SIZE,
    minSize: MIN_SIZE,
  },
  {
    id: 'credit_rating',
    title: 'Credit Rating',
    filterVariant: 'range',
    size: SIZE,
    minSize: MIN_SIZE,
  },
  {
    id: 'credit_type',
    title: 'Credit Type',
    filterVariant: 'select',
    filterFn: 'arrIncludesSome',
    size: SIZE,
    minSize: MIN_SIZE,
  },
  {
    id: 'da_son_exposure',
    title: 'DA SON Exposure',
    filterVariant: 'range',
    size: SIZE,
    minSize: MIN_SIZE,
  },
  {
    id: 'days_to_maturity',
    title: 'Days To Maturity',
    filterVariant: 'range',
    size: SIZE,
    minSize: MIN_SIZE,
  },
  // {
  //   id: 'g_name',
  //   title: 'G Name',
  //   show: false,
  //   filterVariant: 'range',
  // },
  {
    id: 'icb_sector_name',
    title: 'ICB Sector Name',
    filterVariant: 'select',
    filterFn: 'arrIncludesSome',
    size: SIZE,
    minSize: MIN_SIZE,
  },
  {
    id: 'is_listed',
    title: 'Is Listed',
    filterVariant: 'select',
    filterFn: 'arrIncludesSome',
    size: SIZE,
    minSize: MIN_SIZE,
  },
  {
    id: 'asset_form',
    title: 'Asset Form',
    filterVariant: 'select',
    filterFn: 'arrIncludesSome',
    size: SIZE,
    minSize: MIN_SIZE,
  },
  {
    id: 'cis',
    title: 'Is Collective Investment Scheme (CIS)',
    short_title: 'CIS',
    filterVariant: 'range',
    size: SIZE,
    minSize: MIN_SIZE,
  },
  // {
  //   title: 'Currency Code',
  //   id: 'currency_code',
  //   show: false
  // },
  // {
  //   title: 'G Key',
  //   id: 'g_key',
  //   show: false
  // },
  // {
  //   title: 'GM Id New',
  //   id: 'gm_id_new',
  //   show: false
  // },
  {
    id: 'icb_sector_id',
    title: 'ICB Sector ID',
    filterVariant: 'range',
    size: SIZE,
    minSize: MIN_SIZE,
  },
  {
    id: 'is_cb',
    title: 'Is Convertible Bond',
    short_title: 'CB',
    filterVariant: 'select',
    filterFn: 'arrIncludesSome',
    size: SIZE,
    minSize: MIN_SIZE,
  },
  {
    id: 'is_cds',
    title: 'Is Credit Default Swap (CDS)',
    short_title: 'CDS',
    filterVariant: 'select',
    filterFn: 'arrIncludesSome',
    size: SIZE,
    minSize: MIN_SIZE,
  },
  {
    id: 'is_etf',
    title: 'Is Exchange Traded Fund (ETF)',
    short_title: 'ETF',
    filterVariant: 'select',
    filterFn: 'arrIncludesSome',
    size: SIZE,
    minSize: MIN_SIZE,
  },
  {
    id: 'is_loan',
    title: 'Is Loan',
    filterVariant: 'select',
    filterFn: 'arrIncludesSome',
    size: SIZE,
    minSize: MIN_SIZE,
  },
  {
    id: 'is_sfi',
    title: 'Is Structured Financial Instrument (SFI)',
    short_title: 'SFI',
    filterVariant: 'select',
    filterFn: 'arrIncludesSome',
    size: SIZE,
    minSize: MIN_SIZE,
  },
  {
    id: 'maturity_date',
    title: 'Maturity Date',
    filterVariant: 'select',
    filterFn: 'arrIncludesSome',
    size: SIZE,
    minSize: MIN_SIZE,
  },
  {
    id: 'msci_sector_code',
    title: 'MSCI Sector Code',
    filterVariant: 'range',
    size: SIZE,
    minSize: MIN_SIZE,
  },
  {
    id: 'msci_sector_name',
    title: 'MSCI Sector Name',
    filterVariant: 'select',
    filterFn: 'arrIncludesSome',
    size: SIZE,
    minSize: MIN_SIZE,
  },
  {
    id: 'msci_sector_id',
    title: 'MSCI Sector ID',
    filterVariant: 'range',
    size: SIZE,
    minSize: MIN_SIZE,
  },

  {
    id: 'notional_amount',
    title: 'Notional Amount',
    filterVariant: 'range',
    size: SIZE,
    minSize: MIN_SIZE,
  },
  {
    id: 'portfolio_id_name',
    title: 'Portfolio ID Name',
    filterVariant: 'select',
    filterFn: 'arrIncludesSome',
    size: SIZE,
    minSize: MIN_SIZE,
  },
  {
    id: 'price',
    title: 'Price',
    filterVariant: 'range',
    size: SIZE,
    minSize: MIN_SIZE,
  },
  {
    id: 'sum_of_notionals',
    title: 'Sum Of Notionals',
    filterVariant: 'range',
    size: SIZE,
    minSize: MIN_SIZE,
  },
  {
    id: 'sector_name',
    title: 'Sector Name',
    filterVariant: 'select',
    filterFn: 'arrIncludesSome',
    size: SIZE,
    minSize: MIN_SIZE,
  },
  {
    id: 'sector_id',
    title: 'Sector ID',
    filterVariant: 'range',
    size: SIZE,
    minSize: MIN_SIZE,
  },
  {
    id: 'total_issue_size',
    title: 'Total Issue Size',
    filterVariant: 'select',
    filterFn: 'arrIncludesSome',
    size: SIZE,
    minSize: MIN_SIZE,
  },
  {
    id: 'unrealised_pl',
    title: 'Unrealised P/L',
    filterVariant: 'select',
    filterFn: 'arrIncludesSome',
    size: SIZE,
    minSize: MIN_SIZE,
  },
  {
    id: 'interest_rate',
    title: 'Interest Rate',
    filterVariant: 'range',
    size: SIZE,
    minSize: MIN_SIZE,
  },
  // {
  //   id: 'interest_type',
  //   title: 'Interest Type',
  //   show: false,
  //   filterVariant: 'select',
  //   filterFn: 'arrIncludesSome',
  // },
  {
    id: 'time_to_maturity',
    title: 'Time To Maturity',
    filterVariant: 'range',
    size: SIZE,
    minSize: MIN_SIZE,
  },
  // {
  //   id: 'asset_cs01',
  //   title: 'Asset CS01',
  //   show: false,
  //   filterVariant: 'range',
  // },
  // {
  //   id: 'asset_dv01',
  //   title: 'Asset DV01',
  //   show: false,
  //   filterVariant: 'range',
  // },
  {
    id: 'cs01',
    title: 'CS01',
    filterVariant: 'range',
    size: SIZE,
    minSize: MIN_SIZE,
  },
  {
    id: 'dv01',
    title: 'DV01',
    filterVariant: 'range',
    size: SIZE,
    minSize: MIN_SIZE,
  },
  {
    id: 'yield',
    title: 'Yield',
    filterVariant: 'range',
    size: SIZE,
    minSize: MIN_SIZE,
  },
  {
    id: 'risk_factor',
    title: 'Risk Factor',
    filterVariant: 'range',
    size: SIZE,
    minSize: MIN_SIZE,
  },
  {
    id: 'outstanding_securities',
    title: 'Outstanding Securities',
    filterVariant: 'range',
    size: SIZE,
    minSize: MIN_SIZE,
  },
  {
    id: 'coupon',
    title: 'Coupon',
    filterVariant: 'range',
    size: SIZE,
    minSize: MIN_SIZE,
  },
  {
    id: 'duration',
    title: 'Duration',
    filterVariant: 'range',
    size: SIZE,
    minSize: MIN_SIZE,
  },
  {
    id: 'counterparty_names',
    title: 'Counterparties',
    filterVariant: 'select',
    filterFn: 'arrIncludesSome',
    size: SIZE,
    minSize: MIN_SIZE,
  },
  {
    id: 'is_coco',
    title: 'Is Contingent Convertible',
    short_title: 'CoCo',
    filterVariant: 'select',
    filterFn: 'arrIncludesSome',
    size: SIZE,
    minSize: MIN_SIZE,
  },
  {
    id: 'is_at1',
    title: 'Is Additional Tier 1',
    short_title: 'AT1',
    filterVariant: 'select',
    filterFn: 'arrIncludesSome',
    size: SIZE,
    minSize: MIN_SIZE,
  },
];
