import { FC, useEffect } from 'react';
import useFetchData from '../../../../hooks/useFetchData';
import { formatDateForCheckingState } from '../../../../utilities/dateFormatters';
import { makeUniqueDataKey } from '../../../../utilities/urlBuilder';
import GeneralComponentErrorShield from '../../../general/GeneralComponentErrorShield';
import { FundInfoComponentProps } from '../../../general/GeneralFundInfoPage';
import GridItem from '../../../layout/GridComponents/GridItem';
import FundCorrelationMatrix from './FundCorrelationMatrix.component';
import HistoricalPerformanceChart from './HistoricalPerformanceChart.component';
import PerformanceReportTables from './PerformanceReportTables.component';
import { Select } from '../../../../types/redux/pages/PagesStore';
import { useDispatch } from 'react-redux';
import {
  addAdditionalTitleBarComponents,
  removeTitleBarComponent,
} from '../../../../redux/pages/actions';
import {
  APPEND_NEW_SELECT_TO_TITLEBAR,
  REMOVE_SELECT_FROM_TITLEBAR,
} from '../../../../types/redux/pages/pageTypes';
import useTitleBarSelect from '../../../../hooks/useTitleBarSelect';
import { Grid } from '@mui/material';

const PerformanceReport: FC<FundInfoComponentProps> = (props) => {
  const activeDate = props.positionDate;
  const dispatch = useDispatch();
  const lookBackPeriod = useTitleBarSelect('lookBackPeriod') || '1';

  useEffect(() => {
    const selectsToAdd: Select[] = [
      {
        titleBarTitle: 'Look Back Period:',
        titleBarKey: 'lookBackPeriod',
        values: [
          {
            label: 'One Year',
            value: '1',
          },
          {
            label: 'Three Year',
            value: '3',
          },
        ],
        displayOnOverviewPage: false,
        aValueIsAlwaysSelected: true,
        currentValue: '1',
      },
    ];
    dispatch(
      addAdditionalTitleBarComponents(
        selectsToAdd,
        APPEND_NEW_SELECT_TO_TITLEBAR,
      ),
    );

    return () => {
      dispatch(
        removeTitleBarComponent('lookBackPeriod', REMOVE_SELECT_FROM_TITLEBAR),
      );
    };
  }, []);

  const urlForFetch = `fund_performance_analysis/${
    activeDate ? activeDate : formatDateForCheckingState(new Date())
  }/${lookBackPeriod}/${props.fundId}`;

  const performanceReportData = useFetchData({
    keyName: makeUniqueDataKey(
      props.fundId,
      `performance_report_${lookBackPeriod}`,
      activeDate,
    ),
    url: urlForFetch,
    makeFetch: true,
  });

  return (
    <GeneralComponentErrorShield
      dataObjects={[performanceReportData]}
      customLoadingMessages={['Loading Performance Report Data...']}
      customErrorMessage="No Data Available."
    >
      <Grid container spacing={2} style={{ padding: 8 }}>
        <GridItem
          xs={7}
          style={{
            alignSelf: 'stretch',
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
          }}
        >
          <HistoricalPerformanceChart dataForRender={performanceReportData} />
          <FundCorrelationMatrix
            dataForRender={performanceReportData}
            fundId={props.fundId}
          />
        </GridItem>
        <GridItem
          xs={5}
          style={{
            alignSelf: 'stretch',
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
          }}
        >
          <PerformanceReportTables
            dataForRender={performanceReportData}
            lookBackPeriod={lookBackPeriod}
          />
        </GridItem>
      </Grid>
    </GeneralComponentErrorShield>
  );
};

export default PerformanceReport;
