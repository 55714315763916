import {
  addCommasToNumbersAndRound,
  percentageToTwoDecimalPlacesNoHundred,
  toTwoDecimalPlaces,
} from '../../../../utilities/numberFormatters';
import { ExposureType } from './table/NonCisTable.component';
import { CustomColumn } from '../../../../types/components/tables/tableTypes';

export interface NonCisData {
  number: number;
  name: string;
  isin: string;
  pcYield: number;
  currency: string;
  countryCode: string;
  forex: number;
  price: number;
  position: number;
  exposure: number;
}

export interface NonCisServerData {
  noncis_data: {
    base_currency: string;
    gm_name: string;
    isin: string;
    client_price: number;
    mr_position: number;
    country_code: string;
    forex_factor: number;
    position_exposure: number;
  }[];
  nav: number;
}

export const generateNonCisColumns = (
  exposureType: ExposureType,
): CustomColumn<NonCisData>[] => {
  return [
    {
      title: '#',
      field: 'number',
      width: 1,
    },
    {
      title: 'Name',
      field: 'name',
    },
    {
      title: 'ISIN',
      field: 'isin',
      headerStyle: {
        textAlign: 'center',
      },
      cellStyle: {
        textAlign: 'center',
      },
    },
    {
      title: 'Currency',
      field: 'currency',
      headerStyle: {
        textAlign: 'center',
      },
      cellStyle: {
        textAlign: 'center',
      },
    },
    {
      title: 'Country Code',
      field: 'countryCode',
      headerStyle: {
        textAlign: 'center',
      },
      cellStyle: {
        textAlign: 'center',
      },
    },
    {
      title: 'Forex',
      field: 'forex',
      headerStyle: {
        textAlign: 'center',
      },
      cellStyle: {
        textAlign: 'center',
      },
      render: (rowData) => toTwoDecimalPlaces(rowData.forex),
    },
    {
      title: 'Price',
      field: 'price',
      render: (rowData) => addCommasToNumbersAndRound(rowData.price),
      headerStyle: {
        textAlign: 'center',
      },
      cellStyle: {
        textAlign: 'center',
      },
    },
    {
      title: 'Position',
      field: 'position',
      render: (rowData) => addCommasToNumbersAndRound(rowData.position),
      headerStyle: {
        textAlign: 'center',
      },
      cellStyle: {
        textAlign: 'center',
      },
    },
    {
      title: 'Exposure',
      field: 'exposure',
      render: (rowData) =>
        exposureType === 'netExposureBaseCurrency'
          ? addCommasToNumbersAndRound(rowData.exposure)
          : percentageToTwoDecimalPlacesNoHundred(rowData.exposure),

      headerStyle: {
        textAlign: 'center',
      },
      cellStyle: {
        textAlign: 'center',
      },
    },
  ];
};

export function generateNonCisTableData(
  inputData: NonCisServerData[],
  exposureType: ExposureType,
): NonCisData[] {
  if (!inputData.length) return [];
  const nav = inputData[0].nav;
  try {
    return inputData[0].noncis_data.map((cisDatum, index) => {
      return {
        number: index + 1,
        name: cisDatum.gm_name,
        isin: cisDatum.isin,
        pcYield: 0,
        currency: cisDatum.base_currency,
        countryCode: cisDatum.country_code,
        forex: cisDatum.forex_factor,
        price: cisDatum.client_price,
        position: cisDatum.mr_position,
        exposure:
          exposureType === 'netExposureBaseCurrency'
            ? cisDatum.position_exposure
            : (cisDatum.position_exposure / nav) * 100,
      };
    });
  } catch (error) {
    return [];
  }
}
