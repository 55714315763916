import makeStyles from '@mui/styles/makeStyles';
import React, { useEffect } from 'react';
import GridItem from '../../../../layout/GridComponents/GridItem';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Label,
  Rectangle,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { greys, mainColors } from '../../../../../styling/theme';
import { percentageToNdecialPlaces } from '../../../../../utilities/numberFormatters';
import clsx from 'clsx';
import { useDispatch } from 'react-redux';
import usePngFromRecharts from '../../../../../hooks/usePngFromRecharts';
import { addComponentToPdfExport } from '../../../../../redux/pdfExport/actions';
import { PdfComponentType } from '../../../../../types/redux/pdfExports/pdfExportsStore';
import FileSaver from 'file-saver';
import ExportButton from '../../../../feedback/ExportButton';
import ChartDownloadButton from '../../../../buttons/ChartDownloadButton';
import { countryCodeToName } from '../../../../../utilities/generalMappings';

interface SubFundPerformanceChartProps {
  data: any;
}

interface SubFundPerformanceChartData {
  asset_class: string;
  value1: number;
  value2: number;
  value3: number | null;
  value4: number | null;
}

const useStyles = makeStyles(() => ({
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '1rem 2.5rem 1rem 1.5rem',
  },
  cardTitle: {
    color: mainColors.mainBlue,
    fontSize: '2.2rem',
    fontWeight: 400,
    margin: '1rem 1.5rem',
  },
  customTooltipContainer: {
    backgroundColor: 'white',
    padding: '1rem 1.5rem',
    borderRadius: '0.5rem',
    border: `1px solid ${mainColors.mainBlue}`,
    width: '30rem',
    color: mainColors.Pass,
  },
  tooltipName: {
    fontSize: '2rem',
    fontWeight: 500,
  },
  tooltipValue: {
    fontSize: '1.6rem',
    fontWeight: 600,
    display: 'flex',
    justifyContent: 'space-between',
  },
  tooltipValueBelowZero: {
    color: mainColors.mainBlue,
  },
  exportButtons: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const CustomTooltip = ({ active, payload, label }: any) => {
  const classes = useStyles();
  if (active && payload && payload.length) {
    if (payload[0].payload.asset_class !== 'Total') {
      return (
        <div className={classes.customTooltipContainer}>
          <div>
            <div className={classes.tooltipName}>{label}</div>
            <hr />
            {payload[0].payload.value1 >= 0 ? (
              <div className={classes.tooltipValue}>
                <div>Asset P/L %: </div>
                <div>
                  {percentageToNdecialPlaces(
                    payload[0].payload.value1 / 100,
                    2,
                  )}
                </div>
              </div>
            ) : (
              <div
                className={clsx(
                  classes.tooltipValue,
                  classes.tooltipValueBelowZero,
                )}
              >
                <div>Asset P/L %: </div>
                <div>
                  {percentageToNdecialPlaces(
                    payload[0].payload.value1 / 100,
                    2,
                  )}
                </div>
              </div>
            )}
            {payload[0].payload.value2 >= 0 ? (
              <div className={classes.tooltipValue}>
                <div>Currency P/L %: </div>
                <div>
                  {percentageToNdecialPlaces(
                    payload[0].payload.value2 / 100,
                    2,
                  )}
                </div>
              </div>
            ) : (
              <div
                className={clsx(
                  classes.tooltipValue,
                  classes.tooltipValueBelowZero,
                )}
              >
                <div>Currency P/L %: </div>
                <div>
                  {percentageToNdecialPlaces(
                    payload[0].payload.value2 / 100,
                    2,
                  )}
                </div>
              </div>
            )}
            {payload[0].payload.value4 >= 0 ? (
              <div className={classes.tooltipValue}>
                <div>Total P/L %: </div>
                <div>
                  {percentageToNdecialPlaces(
                    payload[0].payload.value4 / 100,
                    2,
                  )}
                </div>
              </div>
            ) : (
              <div
                className={clsx(
                  classes.tooltipValue,
                  classes.tooltipValueBelowZero,
                )}
              >
                <div>Total P/L %: </div>
                <div>
                  {percentageToNdecialPlaces(
                    payload[0].payload.value4 / 100,
                    2,
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      );
    } else {
      return (
        <div className={classes.customTooltipContainer}>
          <div>
            <div className={classes.tooltipName}>{label}</div>
            <hr />
            {payload[0].payload.value1 >= 0 ? (
              <div className={classes.tooltipValue}>
                <div>Total Asset P/L %: </div>
                <div>
                  {percentageToNdecialPlaces(
                    payload[0].payload.value1 / 100,
                    2,
                  )}
                </div>
              </div>
            ) : (
              <div
                className={clsx(
                  classes.tooltipValue,
                  classes.tooltipValueBelowZero,
                )}
              >
                <div>Total Asset P/L %: </div>
                <div>
                  {percentageToNdecialPlaces(
                    payload[0].payload.value1 / 100,
                    2,
                  )}
                </div>
              </div>
            )}
            {payload[0].payload.value2 >= 0 ? (
              <div className={classes.tooltipValue}>
                <div>Total Currency P/L %: </div>
                <div>
                  {percentageToNdecialPlaces(
                    payload[0].payload.value2 / 100,
                    2,
                  )}
                </div>
              </div>
            ) : (
              <div
                className={clsx(
                  classes.tooltipValue,
                  classes.tooltipValueBelowZero,
                )}
              >
                <div>Total Currency P/L %: </div>
                <div>
                  {percentageToNdecialPlaces(
                    payload[0].payload.value2 / 100,
                    2,
                  )}
                </div>
              </div>
            )}
            {payload[0].payload.value3 >= 0 ? (
              <div className={classes.tooltipValue}>
                <div>Total FX P/L %: </div>
                <div>
                  {percentageToNdecialPlaces(
                    payload[0].payload.value3 / 100,
                    2,
                  )}
                </div>
              </div>
            ) : (
              <div
                className={clsx(
                  classes.tooltipValue,
                  classes.tooltipValueBelowZero,
                )}
              >
                <div>Total FX P/L %: </div>
                <div>
                  {percentageToNdecialPlaces(
                    payload[0].payload.value3 / 100,
                    2,
                  )}
                </div>
              </div>
            )}
            {payload[0].payload.value4 >= 0 ? (
              <div className={classes.tooltipValue}>
                <div>Total P/L %: </div>
                <div>
                  {percentageToNdecialPlaces(
                    payload[0].payload.value4 / 100,
                    2,
                  )}
                </div>
              </div>
            ) : (
              <div
                className={clsx(
                  classes.tooltipValue,
                  classes.tooltipValueBelowZero,
                )}
              >
                <div>Total P/L %: </div>
                <div>
                  {percentageToNdecialPlaces(
                    payload[0].payload.value4 / 100,
                    2,
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      );
    }
  } else {
    return <></>;
  }
};

const ConditionalCustomBar = (props: any) => {
  let fill = props.fill;
  let widthToSet = props.width;

  if (props.value >= 0) {
    fill = mainColors.Pass;
  } else {
    fill = mainColors.mainBlue;
  }

  if (props.payload.asset_class !== 'Total') {
    widthToSet = 0;
  }

  return <Rectangle {...props} fill={fill} width={widthToSet} />;
};

const CustomBar = (props: any) => {
  let fill = props.fill;
  let xToSet = props.x;
  if (props.payload.asset_class !== 'Total') {
    xToSet = props.x + props.width - 15;
  }

  if (props.value >= 0) {
    fill = mainColors.Pass;
  } else {
    fill = mainColors.mainBlue;
  }

  return <Rectangle {...props} fill={fill} x={xToSet} />;
};

const CustomTotalBar = (props: any) => {
  let fill = props.fill;
  let xToSet = props.x;
  if (props.payload.asset_class !== 'Total') {
    xToSet = props.x - 18;
  }

  if (props.value >= 0) {
    fill = mainColors.Pass;
  } else {
    fill = mainColors.mainBlue;
  }

  return <Rectangle {...props} fill={fill} x={xToSet} />;
};

const buildChartData = (inputData: any): SubFundPerformanceChartData[] => {
  if (!inputData.data || !inputData.data.length) {
    return [];
  } else {
    const returnData: SubFundPerformanceChartData[] = [];

    let calculatedCurrencyPLPc = 0;
    let calculatedFXPLPc = 0;

    Object.keys(inputData.data[0].position_level).map((asset) => {
      if (
        inputData.data[0].position_level[asset].asset_class ===
        'Foreign Exchange'
      ) {
        calculatedFXPLPc +=
          inputData.data[0].position_level[asset].aggregate_currency_pl_pct;
      } else {
        calculatedCurrencyPLPc +=
          inputData.data[0].position_level[asset].aggregate_currency_pl_pct;
      }
    });

    inputData.data[0].drop_down_breakdown.asset_class.map(
      (asset_class: any) => {
        returnData.push({
          asset_class: asset_class.asset_class,
          value1: asset_class.aggregate_asset_pl_pct,
          value2: asset_class.aggregate_currency_pl_pct,
          value3: null,
          value4: asset_class.aggregate_pct_pl,
        });
      },
    );

    returnData.push({
      asset_class: 'Total',
      value1: inputData.data[0].fund_level.overview['Asset P/L (%)'],
      value2: calculatedCurrencyPLPc,
      value3: calculatedFXPLPc,
      value4: inputData.data[0].fund_level.overview['Total P/L (%)'],
    });

    return returnData;
  }
};

const SubFundPerformanceChart: React.FC<SubFundPerformanceChartProps> = ({
  data,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const builtChartData = buildChartData(data);

  const id = `pl-by-asset-class`;
  const title = `P/L By Asset Class`;

  const { ref, handleDownload } = usePngFromRecharts();

  useEffect(() => {
    dispatch(
      addComponentToPdfExport({
        identifier: id,
        handler: handleDownload,
        type: PdfComponentType.LINE_CHART,
        title: title,
      }),
    );
  }, [ref]);

  const saveImage = async () => {
    const imageData = await handleDownload();
    if (imageData) {
      FileSaver.saveAs(imageData.data, `${id}.png`);
    }
  };

  return (
    <GridItem xs={9} card cardStyle={{ height: '100%' }}>
      <div className={classes.toolbar}>
        <h2 className={classes.cardTitle}>{title}</h2>
        <div className={classes.exportButtons}>
          <ExportButton
            fileName={`${id}.csv`}
            exportData={builtChartData}
            fields={Object.keys(builtChartData[0])}
            fieldsMap={[
              { key: 'asset_class', label: 'Asset Class' },
              { key: 'value1', label: 'Asset P/L %' },
              { key: 'value2', label: 'Currency P/L %' },
              { key: 'value3', label: 'FX P/L %' },
              { key: 'value4', label: 'Total P/L %' },
            ]}
          />
          <ChartDownloadButton handler={saveImage} />
        </div>
      </div>
      <ResponsiveContainer width="100%" height={550}>
        <BarChart
          width={500}
          height={400}
          data={builtChartData}
          margin={{
            top: 20,
            right: 50,
            left: 30,
            bottom: 20,
          }}
          ref={ref}
        >
          <CartesianGrid strokeDasharray="5 5" />
          <XAxis
            dataKey="asset_class"
            interval={0}
            angle={0}
            textAnchor="middle"
            height={70}
            tick={{
              stroke: mainColors.mainBlue,
              strokeWidth: 1,
              fontSize: '1.3rem',
            }}
            tickLine={true}
            tickFormatter={(tickItem: string) => countryCodeToName(tickItem)}
          >
            <Label
              style={{
                textAnchor: 'middle',
                fontSize: '200%',
                fontWeight: 400,
                color: mainColors.mainBlue,
              }}
              value={'Asset Class'}
              position={'insideBottom'}
            />
          </XAxis>
          <YAxis
            width={70}
            tickFormatter={(tickItem) => {
              return percentageToNdecialPlaces(tickItem / 100, 1);
            }}
            tick={{
              stroke: mainColors.mainBlue,
              strokeWidth: 1,
              fontSize: '1.3rem',
            }}
            tickLine={true}
          >
            <Label
              style={{
                textAnchor: 'middle',
                fontSize: '200%',
                fontWeight: 400,
                color: mainColors.mainBlue,
              }}
              angle={270}
              value={'P/L %'}
              position={'insideLeft'}
            />
          </YAxis>
          <Tooltip content={<CustomTooltip />} />
          <ReferenceLine y={0} stroke={greys.grey600} />
          <Bar dataKey="value1" shape={CustomBar} />
          <Bar dataKey="value2" shape={CustomBar} />
          <Bar dataKey="value3" shape={ConditionalCustomBar} />
          <Bar dataKey="value4" shape={CustomTotalBar} />
        </BarChart>
      </ResponsiveContainer>
    </GridItem>
  );
};

export default SubFundPerformanceChart;
