import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import useFetchData from '../../../../hooks/useFetchData';
import useTitleBarSelect from '../../../../hooks/useTitleBarSelect';
import {
  addAdditionalTitleBarComponents,
  toggleTitleBarLoading,
} from '../../../../redux/pages/actions';
import { GeneralOverviewPageRenderedComponentProps } from '../../../../types/components/componentTypes';
import { Select } from '../../../../types/redux/pages/PagesStore';
import { APPEND_NEW_SELECT_TO_TITLEBAR } from '../../../../types/redux/pages/pageTypes';
import GeneralComponentErrorShield from '../../../general/GeneralComponentErrorShield';
import GridItem from '../../../layout/GridComponents/GridItem';
import RestrictedCompaniesTable from './RestricedCompaniesTable.component';
import { Grid, Typography } from '@mui/material';

const RestrictedCompanies: React.FC<
  GeneralOverviewPageRenderedComponentProps
> = () => {
  // No functionality currently set up for changing dates so for now just use todays date each time
  const todaysDate = new Date();

  const optionsData = useFetchData({
    url: 'get_restricted_lists_options',
    keyName: 'restricted_companies_options',
    makeFetch: true,
  });
  const [listOptions, setListOptions] = useState<any>(null);

  // Only display the dropdown when the data has been returned
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(toggleTitleBarLoading(true));
    if (optionsData && !optionsData.isFetching && optionsData.data.length) {
      const dataKeys = optionsData.data[0];

      const mappedOptions = dataKeys.map((key: any) => {
        return {
          label: key,
          value: key,
        };
      });

      const fulloptions = [
        { label: 'New this month', value: 'new-this-month' },
        ...mappedOptions,
      ];

      setListOptions(fulloptions);
    } else if (optionsData && optionsData.error) {
      // If there is an error then stop the title bar loading
      dispatch(toggleTitleBarLoading(false));
    }
    return;
  }, [optionsData]);

  useEffect(() => {
    if (listOptions) {
      const selectsToAdd: Select[] = [
        {
          titleBarTitle: 'Select Sanctions List: ',
          titleBarKey: 'optionSelect',
          values: listOptions,
          displayOnOverviewPage: true,
          aValueIsAlwaysSelected: false,
          currentValue: 'new-this-month',
        },
      ];
      dispatch(
        addAdditionalTitleBarComponents(
          selectsToAdd,
          APPEND_NEW_SELECT_TO_TITLEBAR,
        ),
      );
      dispatch(toggleTitleBarLoading(false));
    }
  }, [listOptions]);

  // Get the currently selected view
  const optionSelected = useTitleBarSelect('optionSelect');

  // Get the list of restricted companies
  const companiesList = useFetchData({
    url: `get_restricted_companies_list/${optionSelected}/`,
    keyName: `get_restricted_companies_list_${optionSelected}`,
    makeFetch: optionSelected !== null && optionSelected !== 'new-this-month',
  });

  const previousMonthCompaniesList = useFetchData({
    url: `/sanctions_scanner/get_new_sanctions`,
    keyName: `sanctions_scanner_get_new_sanctions`,
    makeFetch: optionSelected === 'new-this-month',
  });

  return (
    <Grid container spacing={2} style={{ padding: 8 }}>
      {optionSelected && optionSelected !== 'Choose List' ? (
        <GeneralComponentErrorShield
          dataObjects={
            optionSelected === 'new-this-month'
              ? [previousMonthCompaniesList]
              : [companiesList]
          }
          customErrorMessage="Error: No Companies Data Found"
          customLoadingMessages={['Loading Restricted Companies Data...']}
        >
          <RestrictedCompaniesTable
            restrictedCompanies={
              optionSelected === 'new-this-month'
                ? previousMonthCompaniesList
                : companiesList
            }
            selectedOption={optionSelected}
          />
        </GeneralComponentErrorShield>
      ) : (
        <GridItem xs={12} card>
          <Typography variant="h2" align="center" style={{ padding: '2rem' }}>
            Please Select a Restriced Companies List to Display.
          </Typography>
        </GridItem>
      )}
    </Grid>
  );
};

export default RestrictedCompanies;
