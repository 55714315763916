import {
  AnyAction,
  applyMiddleware,
  combineReducers,
  compose,
  createStore,
  Dispatch,
  MiddlewareAPI,
} from 'redux';
import thunk from 'redux-thunk';
import authReducer from './auth/reducer';
import { AppState } from '../types/redux/store';
import pagesReducer from './pages/reducer';
import dataReducer from './data/reducer';
import pdfExportReducer from './pdfExport/reducer';
import uiReducer from './ui/reducer';
import kidsReducer from './kids/reducer';

const appReducer = combineReducers({
  auth: authReducer,
  pages: pagesReducer,
  data: dataReducer,
  ui: uiReducer,
  pdfExport: pdfExportReducer,
  kids: kidsReducer,
});

// Logger middleware for logging actions and state changes. This is useful for debugging. Add it to the applyMiddleware function in the createStore function as an extra argument.
// Please note that this is only for development and should not be left active in production.
/*eslint-disable */
const loggerMiddleware =
  (store: MiddlewareAPI<Dispatch<AnyAction>, AppState>) =>
  (next: Dispatch<AnyAction>) =>
  (action: AnyAction) => {
    console.log('Dispatching action:', action);
    console.log('State before action:', store.getState());

    const result = next(action);

    console.log('State after action:', store.getState());

    return result;
  };
/*eslint-enable */

const rootReducer = (state: AppState | undefined, action: any) => {
  if (action.type === 'USER_LOGOUT') {
    // eslint-disable-next-line no-param-reassign
    state = undefined;
  }
  return appReducer(state, action);
};

let composeEnhancers: any;
if (
  process.env.NODE_ENV !== 'production' &&
  (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
) {
  composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
} else {
  composeEnhancers = compose;
}

// Adding in a vim comment for jokes
export default createStore(
  rootReducer,
  undefined,
  composeEnhancers(applyMiddleware(thunk)),
);
