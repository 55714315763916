export interface IOutSampleChartData {
  name: string;
  dailypl: string | number;
  dailyvar: string | number;
  dailyplpercentage: string | number;
  dailyvarpercentage: string | number;
}

// export function buildOutSampleBarChart(inputData: any) {
//   if (!inputData || !inputData.length) return [];
//   return inputData[0].diff_data.map((data: any, index: number) => {
//     if (index > 0) {
//       return {
//         name: data[0],
//         dailypl: data[1],
//         dailyvar: data[2],
//       };
//     }
//   });
// }

export function buildOutSampleBarChart(inputData: any) {
  const output = inputData[0].raptor_diff_data.map(
    (data: any, index: number) => {
      return {
        dailypl: data[1],
        dailyvar: data[2],
        dailyplpercentage: data[4],
        dailyvarpercentage: data[5],
        //   tick: index % 30 === 0 ? data[0] : null,
        tick: data[0],
      };
    },
  );

  output.shift();

  return output;
}
