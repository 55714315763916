import React, { FC, useEffect } from 'react';
import { FundInfoComponentProps } from '../../../general/GeneralFundInfoPage';
import EquityTable from './components/table/table.component';
import { formatDateForCheckingState } from '../../../../utilities/dateFormatters';
import useTitleBarSelect from '../../../../hooks/useTitleBarSelect';
import useFetchData from '../../../../hooks/useFetchData';
import GeneralComponentErrorShield from '../../../general/GeneralComponentErrorShield';
import ExposureGraphics from './components/ExposureGraphics.component';
import { useDispatch } from 'react-redux';
import { Select } from '../../../../types/redux/pages/PagesStore';
import {
  addAdditionalTitleBarComponents,
  removeTitleBarComponent,
} from '../../../../redux/pages/actions';
import {
  APPEND_NEW_SELECT_TO_TITLEBAR,
  REMOVE_SELECT_FROM_TITLEBAR,
} from '../../../../types/redux/pages/pageTypes';

export const assetClassUrls = {
  FETCH_EQUITY_DATA_FIRST_HALF: 'most_recently_stored_report_data_agg',
  FETCH_EQUITY_DATA_SECOND_HALF: 'equity_specific_data',
  FETCH_FIXED_INCOME_FIRST_HALF: 'most_recently_stored_report_data_agg',
  FETCH_FIXED_INCOM_SECOND_HALF: 'bond_specific_data',
};

const Equity: FC<FundInfoComponentProps> = ({ fundId, positionDate }) => {
  const dispatch = useDispatch();

  // Add the select components to the title bar
  useEffect(() => {
    const selectsToAdd: Select[] = [
      {
        titleBarTitle: 'Visualisation: ',
        titleBarKey: 'selectVisualisation',
        values: [
          {
            label: 'Position Table',
            value: 'table',
          },
          {
            label: 'Exposure Graphics',
            value: 'exposure',
          },
        ],
        displayOnOverviewPage: false,
        aValueIsAlwaysSelected: true,
        currentValue: 'table',
      },
    ];

    dispatch(
      addAdditionalTitleBarComponents(
        selectsToAdd,
        APPEND_NEW_SELECT_TO_TITLEBAR,
      ),
    );

    return () => {
      dispatch(
        removeTitleBarComponent(
          'selectVisualisation',
          REMOVE_SELECT_FROM_TITLEBAR,
        ),
      );
    };
  }, []);

  const selectedPage = useTitleBarSelect('selectVisualisation');
  const SelectedComponent =
    selectedPage === 'exposure' ? ExposureGraphics : EquityTable; // use the correct url depending on whether the date is set

  const mainUrl = `${
    positionDate
      ? 'stored_report_data_agg'
      : assetClassUrls.FETCH_EQUITY_DATA_FIRST_HALF
  }/${fundId}/${assetClassUrls.FETCH_EQUITY_DATA_SECOND_HALF}`;
  const urlForUse = positionDate ? mainUrl + '/' + positionDate : mainUrl;

  const equityData = useFetchData({
    // urlForUse will be falsy if empty string, in which case we block the fetch
    keyName: `assetClassEquityData_${fundId}_${
      positionDate || formatDateForCheckingState(new Date())
    }`,
    url: urlForUse,
    makeFetch: true,
  });

  return (
    <GeneralComponentErrorShield
      dataObjects={[equityData]}
      customErrorMessage="No In-Scope or Available Data"
    >
      <SelectedComponent
        selectedEquityPage={selectedPage || 'table'}
        inputData={equityData}
      />
    </GeneralComponentErrorShield>
  );
};

export default Equity;
