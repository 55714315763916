import React from 'react';
import { GeneralOverviewPageRenderedComponentProps } from '../../../../types/components/componentTypes';
import RaptorExportTiles from './RaptorExportTiles.component';

const RaptorExport: React.FC<GeneralOverviewPageRenderedComponentProps> = (
  props,
) => {
  return (
    <RaptorExportTiles
      positionDate={
        props.positionDate ?? new Date().toISOString().split('T')[0]
      }
    />
  );
};

export default RaptorExport;
