import { Options } from 'rs-material-table';
import React, { ReactElement } from 'react';
import { CustomColumn } from '../../../../../types/components/tables/tableTypes';
import { Status } from '../../../../../types/redux/data/dataTypes';
import {
  hexToRGBA,
  mapStatusToColor,
} from '../../../../../utilities/colorUtilities';
import {
  addCommasToNumbersAndRound,
  percentageToTwoDecimalPlaces,
} from '../../../../../utilities/numberFormatters';
import CustomTable from '../../../../tables/CustomTable';
import {
  ILawFirstLevelTableData,
  RestrictionLevelType,
} from './LawFirstLevelTable';
import { generalThirdLevelDetail } from './GeneralThirdLevelTable';
import { rule17ThirdLevelDetail } from './Rule17ThirdLevelTable';
import { rule8ThirdLevelDetail } from './Rule8ThirdLevelTable';

interface Props {
  version?: number;
  data: ILawFirstLevelTableData;
  fundDetails: {
    fundId: string;
    fundName: string;
  };
  ruleNumber: number;
  positionDate: string;
}

export interface LawSecondLevelData {
  issuer: string;
  exposure: number;
  ruleNumber: number;
  limit: number;
  outstandingSecurities?: number;
  passOrFail: Status;
  position_g_data: any;
  headerRow: boolean;
  positionDate: string;
  restrictionLevel: RestrictionLevelType;
  assets?: any[];
}

export const secondaryColumns: CustomColumn<LawSecondLevelData>[] = [
  {
    title: 'Issuer',
    field: 'issuer',
  },
  {
    title: 'Exposure',
    field: 'exposure',
    render: (rowData: LawSecondLevelData) => {
      if (
        rowData.restrictionLevel === 'aggregate_level_integer' &&
        rowData.headerRow === true
      ) {
        return rowData.exposure;
      } else {
        return percentageToTwoDecimalPlaces(rowData.exposure);
      }
    },
    renderMethod: {
      methodName: 'percentageToTwoDecimalPlaces',
      params: ['exposure'],
    },
    headerStyle: {
      textAlign: 'center',
    },
    cellStyle: {
      textAlign: 'center',
    },
    customSort: (data1, data2) => data2.exposure - data1.exposure,
  },
  {
    title: 'Limit',
    field: 'limit',
    render: (rowData: LawSecondLevelData) => {
      if (
        rowData.restrictionLevel === 'aggregate_level_integer' &&
        rowData.headerRow === true
      ) {
        return rowData.limit;
      } else {
        return percentageToTwoDecimalPlaces(rowData.limit);
      }
    },
    headerStyle: {
      textAlign: 'center',
    },
    cellStyle: {
      textAlign: 'center',
    },
  },
  {
    title: 'Status',
    field: 'passOrFail',
    headerStyle: {
      textAlign: 'center',
    },
    cellStyle: {
      textAlign: 'center',
    },
  },
];

export const rule22Columns: CustomColumn<LawSecondLevelData>[] = [
  {
    title: 'Issuer',
    field: 'issuer',
  },
  {
    title: 'Outstanding Securities',
    field: 'outstandingSecurities',
    render: (rowData: LawSecondLevelData) => {
      return addCommasToNumbersAndRound(rowData.outstandingSecurities || 0);
    },
    headerStyle: {
      textAlign: 'center',
    },
    cellStyle: {
      textAlign: 'center',
    },
  },
  {
    title: 'Exposure',
    field: 'exposure',
    render: (rowData: LawSecondLevelData) => {
      if (
        rowData.restrictionLevel === 'aggregate_level_integer' &&
        rowData.headerRow === true
      ) {
        return rowData.exposure;
      } else {
        return percentageToTwoDecimalPlaces(rowData.exposure);
      }
    },
    renderMethod: {
      methodName: 'percentageToTwoDecimalPlaces',
      params: ['exposure'],
    },
    headerStyle: {
      textAlign: 'center',
    },
    cellStyle: {
      textAlign: 'center',
    },
    customSort: (data1, data2) => data2.exposure - data1.exposure,
  },
  {
    title: 'Limit',
    field: 'limit',
    render: (rowData: LawSecondLevelData) => {
      if (
        rowData.restrictionLevel === 'aggregate_level_integer' &&
        rowData.headerRow === true
      ) {
        return rowData.limit;
      } else {
        return percentageToTwoDecimalPlaces(rowData.limit);
      }
    },
    headerStyle: {
      textAlign: 'center',
    },
    cellStyle: {
      textAlign: 'center',
    },
  },
  {
    title: 'Status',
    field: 'passOrFail',
    headerStyle: {
      textAlign: 'center',
    },
    cellStyle: {
      textAlign: 'center',
    },
  },
];

export const rule21Columns: CustomColumn<LawSecondLevelData>[] = [
  {
    title: 'Issuer',
    field: 'issuer',
  },
  {
    title: 'Exposure',
    field: 'exposure',
    render: (rowData: LawSecondLevelData) => {
      if (
        rowData.restrictionLevel === 'aggregate_level_integer' &&
        rowData.headerRow === true
      ) {
        return rowData.exposure;
      } else {
        return percentageToTwoDecimalPlaces(rowData.exposure);
      }
    },
    renderMethod: {
      methodName: 'percentageToTwoDecimalPlaces',
      params: ['exposure'],
    },
    headerStyle: {
      textAlign: 'center',
    },
    cellStyle: {
      textAlign: 'center',
    },
    customSort: (data1, data2) => data2.exposure - data1.exposure,
  },
  {
    title: 'Limit',
    field: 'limit',
    render: (rowData: LawSecondLevelData) => {
      if (
        rowData.restrictionLevel === 'aggregate_level_integer' &&
        rowData.headerRow === true
      ) {
        return rowData.limit;
      } else {
        return percentageToTwoDecimalPlaces(rowData.limit);
      }
    },
    headerStyle: {
      textAlign: 'center',
    },
    cellStyle: {
      textAlign: 'center',
    },
  },
  {
    title: 'Eligibility Check',
    field: 'eligibilityCheck',
    headerStyle: {
      textAlign: 'center',
    },
    cellStyle: {
      textAlign: 'center',
    },
  },
  {
    title: 'Status',
    field: 'passOrFail',
    headerStyle: {
      textAlign: 'center',
    },
    cellStyle: {
      textAlign: 'center',
    },
  },
];

export const secondLevelOptions: Options = {
  paging: false,
  showTitle: false,
  exportButton: true,
  search: false,
  toolbar: true,
  rowStyle: (rowData: LawSecondLevelData) => ({
    backgroundColor: hexToRGBA(mapStatusToColor(rowData.passOrFail), 0.3),
    fontWeight: rowData.issuer === 'Aggregate Information' ? 'bold' : 'normal',
  }),
};

// This is legacy code, it is still required for backwards compatibility with old data (before 'restriction_level' keys were added to the rules data)
// This can be seen as anything with version 1 or no version key at all in the data
export function buildAssetContainerDataVersion1(
  data: any,
  ruleNumber: number,
): LawSecondLevelData[] {
  const { limit, position_g_data, rule_number, positionDate, status } = data;

  function checkPositionStatusVersion1(exposure: number, limit: number) {
    if (limit < 0) {
      if (exposure <= limit) return 'Fail';
      else if (exposure <= limit * 0.9) return 'Alert';
      else return 'Pass';
    }
    if (exposure >= limit) return 'Fail';
    else if (exposure >= limit * 0.9) return 'Alert';
    else return 'Pass';
  }

  const newData = data.assets.map((datum: any) => {
    const [issuer, exposure] = datum;
    const passOrFailValue =
      data.limit.type === 'aggregate'
        ? status
        : checkPositionStatusVersion1(exposure, data.limit.position);

    let limitSource;
    if (rule_number === 26 && datum[3]) {
      limitSource = {
        aggregate: datum[3][0],
        position: null,
        type: 'aggregate',
      };
    } else if (typeof limit === 'object' && limit !== null) {
      limitSource = limit;
    } else if (limit.constructor === Array) {
      limitSource = limit[0];
    } else if (typeof limit === 'string') {
      // Try to remove any % signs.

      limitSource = parseFloat(limit.replace('%', '')) / 100;
    } else {
      limitSource = limit;
    }
    // }
    return {
      positionDate,
      issuer,
      ruleNumber,
      exposure,
      limit: limitSource,
      passOrFail: passOrFailValue,
      position_g_data,
      headerRow: false,
    };
  });
  if (
    limit.length > 1 &&
    rule_number !== 4 &&
    rule_number !== 12 &&
    rule_number !== 29
  ) {
    const aggRow: any = {};
    aggRow.issuer = 'Aggregate Information';
    // TODO need some type definitions here
    // Add in special case for rule number 5 to tage absolute values
    if (ruleNumber === 5) {
      aggRow.exposure = newData.reduce(
        (prev: any, current: any) =>
          Math.abs(prev) + Math.abs(current.exposure),
        0,
      );
    } else {
      aggRow.exposure = newData.reduce(
        (prev: any, current: any) => prev + current.exposure,
        0,
      );
    }
    let limitSource;
    if (limit.constructor === Array && rule_number !== 5) {
      limitSource = limit[0];
    } else if (rule_number === 5) {
      limitSource = 0.4;
    } else if (rule_number === 7) {
      limitSource = 0.8;
    } else if (typeof limit === 'string') {
      // Try to remove any % signs.
      limitSource = parseFloat(limit.replace('%', '')) / 100;
    } else {
      limitSource = limit;
    }
    aggRow.limit = limitSource;
    aggRow.ruleNumber = ruleNumber;
    aggRow.passOrFail =
      aggRow.exposure > aggRow.limit ? Status.Fail : Status.Pass;
    aggRow.position_g_data = null;
    aggRow.headerRow = true;
    aggRow.positionDate = '';

    if (ruleNumber !== 8) {
      newData.forEach((el: any) => {
        el.passOrFail = aggRow.passOrFail;
      });

      newData.unshift(aggRow);
    }
  }

  return newData;
}

function checkPositionStatus(
  assetValue: number,
  assetLimit: number[],
  restriction_level: RestrictionLevelType,
  eligibilityCheck?:
    | 'Not Checked'
    | 'Checked - Eligible'
    | 'Checked - Ineligible',
): Status {
  if (eligibilityCheck === 'Not Checked') {
    return Status.PassAlert;
  } else if (eligibilityCheck === 'Checked - Eligible') {
    return Status.Pass;
  } else if (eligibilityCheck === 'Checked - Ineligible') {
    return Status.Fail;
  } else if (
    restriction_level === 'aggregate_level' ||
    restriction_level === 'aggregate_level_integer'
  ) {
    return Status.NA;
  } else if (restriction_level === 'position_level') {
    const limit = assetLimit[0];
    if (assetValue >= limit) {
      return Status.Fail;
    } else if (assetValue >= limit * 0.9) {
      return Status.Alert;
    } else {
      return Status.Pass;
    }
  } else if (restriction_level === 'position_level_negative') {
    const limit = assetLimit[0];
    if (assetValue <= limit) {
      return Status.Fail;
    } else if (assetValue <= limit * 0.9) {
      return Status.Alert;
    } else {
      return Status.Pass;
    }
  } else if (restriction_level === 'position_level_binary') {
    const limit = assetLimit[0];
    if (assetValue >= limit) {
      return Status.Fail;
    } else {
      return Status.Pass;
    }
  } else {
    return Status.NA;
  }
}

function handlePassOrFailSort(status: Status) {
  if (status === Status.Pass) {
    return 1;
  } else {
    return 0;
  }
}

export function buildAssetContainerDataVersion2(
  data: ILawFirstLevelTableData,
  ruleNumber: number,
): LawSecondLevelData[] {
  // loop through all assets in rule and build an output
  const processedAssets = data.assets.map((asset: any) => {
    // deconstruct the assets info
    const [issuer, assetValue, outstandingSecurities, assetLevelimit] = asset;
    // Check if a further breakdown is available in the asset
    const furtherBreakdown = asset.length > 4 ? asset[4] : null;

    const passOrFailValue = checkPositionStatus(
      assetValue,
      assetLevelimit ?? data.limit,
      data.restriction_level,
    );

    let chosenLimit = data.limit[0];
    // for some reason the data for these rules has an asset level limit
    if (ruleNumber === 17 || ruleNumber === 26) {
      chosenLimit = assetLevelimit[0];
    }

    return {
      positionDate: data.positionDate,
      issuer,
      ruleNumber,
      exposure: assetValue,
      limit: chosenLimit,
      outstandingSecurities: outstandingSecurities,
      passOrFail: passOrFailValue,
      position_g_data: data.position_g_data,
      headerRow: false,
      restrictionLevel: data.restriction_level,
      assets: furtherBreakdown ? furtherBreakdown : undefined,
    };
  });

  processedAssets.sort(
    (a, b) =>
      handlePassOrFailSort(a.passOrFail) - handlePassOrFailSort(b.passOrFail),
  );

  // here we create an aggregate row
  if (
    data.restriction_level === 'aggregate_level' ||
    data.restriction_level === 'aggregate_level_integer'
  ) {
    const aggRow: any = {};
    aggRow.issuer = 'Aggregate Information';

    // calculate aggregate value
    // we have to handle for rule 9 with the 'aggregate_level_integer' key
    if (data.restriction_level === 'aggregate_level_integer') {
      let minimumIssues = 0;
      processedAssets.forEach((asset) => {
        let issuesByAsset = 0;
        asset.position_g_data.filter((issue: any) => {
          if (issue.g_name === asset.issuer) {
            issuesByAsset += 1;
          }
        });
        if (minimumIssues === 0) {
          minimumIssues = issuesByAsset;
        } else {
          minimumIssues = Math.min(minimumIssues, issuesByAsset);
        }
      });
      aggRow.exposure = minimumIssues;
    } else {
      aggRow.exposure = processedAssets.reduce(
        (prev: any, current: any) => prev + current.exposure,
        0,
      );
    }

    // calculate aggregate limit
    if (data.limit.length > 1) {
      aggRow.limit = data.limit[1];
    } else {
      aggRow.limit = data.limit[0];
    }

    // finish building row
    aggRow.ruleNumber = ruleNumber;
    aggRow.passOrFail = data.status;
    aggRow.restrictionLevel = data.restriction_level;
    aggRow.position_g_data = null;
    aggRow.headerRow = true;
    aggRow.positionDate = '';
    processedAssets.unshift(aggRow);
  }

  return processedAssets;
}

export function buildAssetContainerDataVersion3(
  data: ILawFirstLevelTableData,
  ruleNumber: number,
): LawSecondLevelData[] {
  // loop through all assets in rule and build an output
  const processedAssets = data.assets.map((asset: any) => {
    // deconstruct the assets info
    let issuer,
      assetValue,
      outstandingSecurities,
      assetLevelimit,
      eligibilityCheck;
    if (ruleNumber === 21) {
      [issuer, assetValue, eligibilityCheck] = asset;
    } else {
      [issuer, assetValue, outstandingSecurities, assetLevelimit] = asset;
    }
    // Check if a further breakdown is available in the asset
    const furtherBreakdown = asset.length > 4 ? asset[4] : null;

    const passOrFailValue = checkPositionStatus(
      assetValue,
      assetLevelimit ?? data.limit,
      data.restriction_level,
      eligibilityCheck,
    );

    let chosenLimit = data.limit[0];
    // for some reason the data for these rules has an asset level limit
    if (ruleNumber === 17 || ruleNumber === 26) {
      chosenLimit = assetLevelimit[0];
    }

    return {
      positionDate: data.positionDate,
      issuer,
      ruleNumber,
      exposure: assetValue,
      limit: chosenLimit,
      outstandingSecurities: outstandingSecurities,
      passOrFail: passOrFailValue,
      position_g_data: data.position_g_data,
      headerRow: false,
      restrictionLevel: data.restriction_level,
      assets: furtherBreakdown ? furtherBreakdown : undefined,
      eligibilityCheck: eligibilityCheck,
    };
  });

  processedAssets.sort(
    (a, b) =>
      handlePassOrFailSort(a.passOrFail) - handlePassOrFailSort(b.passOrFail),
  );

  // here we create an aggregate row
  if (
    data.restriction_level === 'aggregate_level' ||
    data.restriction_level === 'aggregate_level_integer' ||
    data.restriction_level === 'aggregate_level_eligibility'
  ) {
    const aggRow: any = {};
    aggRow.issuer = 'Aggregate Information';

    // calculate aggregate value
    // we have to handle for rule 9 with the 'aggregate_level_integer' key
    if (data.restriction_level === 'aggregate_level_integer') {
      let minimumIssues = 0;
      processedAssets.forEach((asset) => {
        let issuesByAsset = 0;
        asset.position_g_data.filter((issue: any) => {
          if (issue.g_name === asset.issuer) {
            issuesByAsset += 1;
          }
        });
        if (minimumIssues === 0) {
          minimumIssues = issuesByAsset;
        } else {
          minimumIssues = Math.min(minimumIssues, issuesByAsset);
        }
      });
      aggRow.exposure = minimumIssues;
    } else {
      aggRow.exposure = processedAssets.reduce(
        (prev: any, current: any) => prev + current.exposure,
        0,
      );
    }

    // calculate aggregate limit
    if (data.limit.length > 1) {
      aggRow.limit = data.limit[1];
    } else {
      aggRow.limit = data.limit[0];
    }

    // finish building row
    aggRow.ruleNumber = ruleNumber;
    aggRow.passOrFail = data.status;
    aggRow.restrictionLevel = data.restriction_level;
    aggRow.position_g_data = null;
    aggRow.headerRow = true;
    aggRow.positionDate = '';
    processedAssets.unshift(aggRow);
  }

  return processedAssets;
}

const getV2Columns = (ruleNumber: number, data: LawSecondLevelData[]) => {
  if (
    ruleNumber === 22 &&
    data.length &&
    data[0].outstandingSecurities !== undefined
  ) {
    return rule22Columns;
  } else {
    return secondaryColumns;
  }
};

const getV3Columns = (ruleNumber: number, data: LawSecondLevelData[]) => {
  if (
    ruleNumber === 22 &&
    data.length &&
    data[0].outstandingSecurities !== undefined
  ) {
    return rule22Columns;
  } else if (ruleNumber === 21) {
    return rule21Columns;
  } else {
    return secondaryColumns;
  }
};

// Unfortunatley we have to do this to handle for legacy data
// It is saved in a database and we don't yet have a way to adjust it retroactively
// version 1 is the original data
// version 2 was created in early 2023
// version 3 was created January 2024 (specifically for a change to rule 21)
const getCorrectVersionOfData = (
  version: number | undefined,
  data: any,
  ruleNumber: number,
) => {
  if (version === 3) {
    return buildAssetContainerDataVersion3(data, ruleNumber);
  } else if (version === 2) {
    return buildAssetContainerDataVersion2(data, ruleNumber);
  } else {
    return buildAssetContainerDataVersion1(data, ruleNumber);
  }
};
const getCorrectVersionOfColumns = (
  version: number | undefined,
  data: any,
  ruleNumber: number,
) => {
  if (version === 3) {
    return getV3Columns(ruleNumber, data);
  } else if (version === 2) {
    return getV2Columns(ruleNumber, data);
  } else {
    return secondaryColumns;
  }
};

// This is a function that returns the correct third level table based on the rule number
const getThirdLevelTable = (ruleNumber: number) => {
  switch (ruleNumber) {
    case 8:
      return rule8ThirdLevelDetail;
    case 17:
    case 26:
      return rule17ThirdLevelDetail;
    default:
      return generalThirdLevelDetail;
  }
};

function SecondLevelTable({
  version,
  data,
  fundDetails,
  ruleNumber,
  positionDate,
}: Props): ReactElement {
  const secondLevelTableData = getCorrectVersionOfData(
    version,
    data,
    ruleNumber,
  );
  const columns = getCorrectVersionOfColumns(version, data, ruleNumber);

  return (
    <CustomTable<LawSecondLevelData>
      selectedPositionDate={positionDate}
      columns={columns}
      csvFields={Object.keys(secondLevelTableData[0])}
      exportFileName="ucits-law"
      showToolbar={true}
      title={`${fundDetails.fundName} - Rule Number: ${ruleNumber}`}
      id={`${fundDetails.fundId}_rule${ruleNumber}_${positionDate}`}
      data={secondLevelTableData}
      options={secondLevelOptions}
      detailPanel={getThirdLevelTable(ruleNumber)}
      noteExport={{
        fundId: fundDetails.fundId,
        positionDate,
        subject: 'ucits_ucits-law',
        topic: `rule_${ruleNumber}`,
        shouldCall: false,
      }}
    />
  );
}
export default SecondLevelTable;
