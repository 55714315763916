import React, { FC } from 'react';
import { DataObject } from '../../../../types/redux/data/dataTypes';
import GridItem from '../../../layout/GridComponents/GridItem';
import CustomTable from '../../../tables/CustomTable';
import {
  LiquidityOverviewData,
  generateLiquidityOverviewColumns,
  generateLiquidityOverviewData,
} from './data';

export interface LiquidityOverviewTableProps {
  overviewData: DataObject;
  reportsList: DataObject;
  clientName: string;
  section: string;
}

const LiquidityOverviewTable: FC<LiquidityOverviewTableProps> = ({
  overviewData,
  reportsList,
  clientName,
  section,
}) => {
  const columns = generateLiquidityOverviewColumns(clientName, section);
  const data = generateLiquidityOverviewData(
    overviewData.data,
    reportsList.data,
    (section as 'ucits' | 'sif-raif' | 'aifmd' | 'nurs' | 'act40') || 'ucits',
  );

  return (
    <GridItem xs={12} card>
      <CustomTable<LiquidityOverviewData>
        options={{ paging: false, search: true, exportButton: true }}
        columns={columns}
        data={data}
        showToolbar={true}
        title="Liquidity Overview Data"
      />
    </GridItem>
  );
};

export default LiquidityOverviewTable;
