import React, { CSSProperties } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import ReportsSelect from '../../../selects/ReportsSelect';
import { CustomColumn } from '../../../../types/components/tables/tableTypes';
import {
  addCommasToNumbersAndRound,
  percentageToTwoDecimalPlaces,
} from '../../../../utilities/numberFormatters';
import PageLinkButton from '../../../buttons/PageLinkButton';
import { RaptorTheme, mainColors } from '../../../../styling/theme';
import { Status } from '../../../../types/redux/data/dataTypes';
import { Link } from 'react-router-dom';
import CalendarPopup from '../../sharedComponents/overview/components/Calendar.component';
import GeneralReportDatePicker from '../../../general/GeneralReportDatePicker';
import { formatDateForCheckingState } from '../../../../utilities/dateFormatters';
import { Button, Tooltip, useTheme } from '@mui/material';
import { specifyStatus } from '../../../../utilities/generalMappings';
import NotesComp from '../../sharedComponents/notes/Notes';

export interface OverviewTableData {
  internalFundName: string;
  fundName: string;
  reports: string[];
  subFundRiskReports: string[];
  positionDate: string;
  calculationDate: string;
  currency: string;
  netAssetValue: number;
  commitmentValue: number;
  commitmentStatus: Status;
  valueAtRiskStatus: Status;
  valueAtRiskValue: number;
  varFund: string;
  varDay: string;
  ucitsLaw: Status;
  prospectusStatus: Status;
  countryExposure: any;
  positionExposure: any;
  index: number;
  derogationMessage?: string;
  timeSeriesData: any | null;
  timeSeriesStatus: any | null;
  notes: boolean;
  leverage: Status;
  fundType: FundType;
  act40Law?: Status;
}
// ? How about this?
export const ucitsOverviewCsvFields = [
  'internalFundName',
  'fundName',
  'positionDate',
  'currency',
  'netAssetValue',
  'commitmentStatus',
  'valueAtRisk',
  'ucitsLaw',
  'prospectusStatus',
];

export function statusSort<T>(
  a: T,
  b: T,
  key: keyof T,
  // additional key is needed for value at risk
  additionalKey?: keyof T,
): any {
  const definer: { [key: string]: number } = {
    [`${Status.Pass}`]: 1,
    [`${Status.Alert}`]: 2,
    [`${Status.Fail}`]: 3,
  };

  if (additionalKey) {
    // which status comes first
    if (
      definer[specifyStatus(a[key] as unknown as string)] ===
      definer[specifyStatus(b[key] as unknown as string)]
    ) {
      if (a[additionalKey] > b[additionalKey]) return -1;
      if (a[additionalKey] < b[additionalKey]) return 1;
    }
  }

  return definer[specifyStatus(a[key] as unknown as string)] >
    definer[specifyStatus(b[key] as unknown as string)]
    ? -1
    : 1;
}
export const reportsSelect = (rowData: any) => (
  <ReportsSelect
    reports={rowData.reports}
    fundName={rowData.internalFundName}
  />
);
export const DetailLine = (props: {
  initialText: string;
  detailLine?: string;
  detailStyle: CSSProperties;
}) => {
  return (
    <>
      <p style={{ margin: 0 }}>{props.initialText}</p>
      {props.detailLine && <p style={props.detailStyle}>{props.detailLine}</p>}
    </>
  );
};

type AdditionalPropsForColumns = {
  // the first string is the pageUrl that you're going to,
  // the array of strings is all of the fundIds that should clikc through to that page
  clickThroughPages?: {
    path: string;
    fundId: string;
    tooltipValue: string;
  }[];
};

const useClickThroughStyles = makeStyles<RaptorTheme>((theme) => ({
  root: {
    textTransform: 'none',
    transition: 'all .1s',
    border: `1px solid ${theme.palette.grey[200]}`,
    '&:hover': {
      border: `none`,
      color: 'white',
      backgroundColor: theme.palette.primary.main,
    },
  },
}));
const ClickThroughPageButton = ({
  name,
  path,
  tooltipVal,
}: {
  name: string;
  path: string;
  tooltipVal: string;
}) => {
  const classes = useClickThroughStyles();
  return (
    <Tooltip title={tooltipVal}>
      <Link to={path}>
        <Button classes={classes}>{name}</Button>
      </Link>
    </Tooltip>
  );
};

export const useOverviewColumns = (
  clientName: string | null,
  section: string | null,
  additionalProps?: AdditionalPropsForColumns,
): CustomColumn<OverviewTableData>[] => {
  switch (section) {
    case 'act40':
      return useOverviewColumnsAct40(clientName, additionalProps);
    default:
      return useOverviewColumnsDefault(clientName, additionalProps);
  }
};
const useOverviewColumnsAct40 = (
  clientName: string | null,
  additionalProps?: AdditionalPropsForColumns,
): CustomColumn<OverviewTableData>[] => {
  const theme = useTheme();

  if (!clientName) return [];

  const generalCellStyles:
    | React.CSSProperties
    | ((data: object[], rowData: object) => React.CSSProperties) = {
    fontSize: '1.4rem',
    color: theme.palette.grey[900],
  };

  const generalHeaderStyles: React.CSSProperties = {};

  const ucitsOverviewTableColumns: CustomColumn<OverviewTableData>[] = [
    {
      title: '#',
      field: 'index',
      cellStyle: {
        ...generalCellStyles,
        textAlign: 'center',
        width: '2rem',
      },
      headerStyle: {
        textAlign: 'center',
        ...generalHeaderStyles,
        width: '2rem',
      },
    },
    {
      title: 'Internal Fund Name',
      field: 'internalFundName',
      render: (rowData) =>
        clientName === 'mersenne' || clientName === 'mersenne_prime'
          ? 'Fund' + rowData.index
          : additionalProps?.clickThroughPages
            ? (() => {
                const clickThroughFunds = additionalProps.clickThroughPages.map(
                  (el) => el.fundId,
                );
                if (clickThroughFunds.includes(rowData.internalFundName)) {
                  const clickThroughVals =
                    additionalProps.clickThroughPages.find(
                      (el) => el.fundId === rowData.internalFundName,
                    );
                  return (
                    <ClickThroughPageButton
                      tooltipVal={clickThroughVals?.tooltipValue || ''}
                      name={clickThroughVals?.fundId || ''}
                      path={`${clickThroughVals?.path}?fundId=${clickThroughVals?.fundId}`}
                    />
                  );
                }
                return rowData.internalFundName;
              })()
            : rowData.internalFundName,
      cellStyle: {
        ...generalCellStyles,
      },
      headerStyle: { ...generalHeaderStyles },
    },
    {
      title: 'Fund Name',
      field: 'fundName',
      pdfRenderType: 'DetailLine',
      headerStyle: {
        ...generalHeaderStyles,
        width: '18%',
      },
      cellStyle: {
        ...generalCellStyles,
        fontSize: '1.4rem',
        width: '18%',
      },
      render: (rowData) => {
        return (
          <DetailLine
            initialText={rowData.fundName}
            detailLine={rowData.derogationMessage}
            detailStyle={{ margin: 0, color: mainColors.Fail }}
          />
        );
      },
    },
    {
      title: 'Reports',
      field: 'reports',
      sorting: false,
      // render: reportsSelect,
      render: (rowData: OverviewTableData) => (
        <GeneralReportDatePicker
          reportNames={rowData.reports}
          reportUrl="ucits_historical_report"
          fundId={rowData.internalFundName}
        />
      ),
      cellStyle: {
        ...generalCellStyles,
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
        ...generalHeaderStyles,
      },
    },
    {
      title: 'Position Date',
      field: 'positionDate',
      cellStyle: {
        ...generalCellStyles,
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
        ...generalHeaderStyles,
      },
      // Comment out for now until ready to go live
      render: (rowData: OverviewTableData) => (
        <CalendarPopup
          date={rowData.positionDate}
          fundId={rowData.internalFundName}
        />
      ),
      customSort: (a, b) => {
        const aDate = new Date(a.positionDate);
        const bDate = new Date(b.positionDate);
        return aDate.getTime() - bDate.getTime();
      },
    },
    {
      title: 'Latest Calculation Timestamp',
      field: 'calculationDate',
      cellStyle: {
        ...generalCellStyles,
        textAlign: 'center',
        width: '8%',
      },
      headerStyle: {
        textAlign: 'center',
        width: '8%',
        ...generalHeaderStyles,
      },
    },
    {
      hidden: true,
      field: 'derogationMessage',
      searchable: true,
    },
    {
      title: 'Currency',
      field: 'currency',
      cellStyle: {
        textAlign: 'center',
        width: '4%',
        ...generalCellStyles,
      },
      headerStyle: {
        textAlign: 'center',
        width: '4%',
      },
    },
    {
      title: 'Net Asset Value',
      field: 'netAssetValue',
      render: (rowData: OverviewTableData) =>
        addCommasToNumbersAndRound(rowData.netAssetValue),
      cellStyle: {
        ...generalCellStyles,
        textAlign: 'right',
        width: '15rem',
      },
      headerStyle: {
        textAlign: 'center',
        width: '15rem',
      },
      customSort: (a, b) => {
        return b.netAssetValue - a.netAssetValue;
      },
    },

    // STATUSES =====================================================
    clientName === 'greshamhouse'
      ? {
          title: 'Leverage',
          field: 'leverage',
          pdfRenderType: 'PageLinkButton',
          cellStyle: {
            ...generalCellStyles,
            textAlign: 'center',
            width: '10%',
          },
          headerStyle: {
            textAlign: 'center',
            width: '10%',
          },
          pdfWidth: 10,
          render: (rowData: OverviewTableData) => (
            <PageLinkButton
              status={rowData.leverage}
              fundName={rowData.internalFundName}
              text={rowData.leverage}
              page={'exposure'}
              section={'qiaif'}
            />
          ),
          customSort: (a, b) =>
            statusSort(a, b, 'commitmentStatus', 'commitmentValue'),
        }
      : {
          title: 'Commitment',
          field: 'commitmentValue',
          pdfRenderType: 'PageLinkButton',
          cellStyle: {
            ...generalCellStyles,
            textAlign: 'center',
            width: '10%',
          },
          headerStyle: {
            textAlign: 'center',
            width: '10%',
          },
          pdfWidth: 10,
          render: (rowData: OverviewTableData) => (
            <PageLinkButton
              status={rowData.commitmentStatus}
              fundName={rowData.internalFundName}
              text={
                rowData.fundType === 'commitment' || rowData.fundType === 'both'
                  ? percentageToTwoDecimalPlaces(rowData.commitmentValue)
                  : 'N/R'
              }
              page={'exposure'}
              section={'act40'}
            />
          ),
          customSort: (a, b) =>
            statusSort(a, b, 'commitmentStatus', 'commitmentValue'),
        },
    {
      pdfWidth: 10,
      title: 'Value at Risk',
      field: 'valueAtRiskValue',
      cellStyle: {
        ...generalCellStyles,
        textAlign: 'center',
        width: '10%',
      },
      pdfRenderType: 'PageLinkButton',
      headerStyle: {
        textAlign: 'center',
        width: '10%',
      },
      render: (rowData: OverviewTableData) => (
        <PageLinkButton
          status={specifyStatus(rowData.valueAtRiskStatus)}
          fundName={rowData.internalFundName}
          text={
            rowData.fundType === 'var' || rowData.fundType === 'both'
              ? percentageToTwoDecimalPlaces(rowData.valueAtRiskValue)
              : 'N/R'
          }
          page={'value-at-risk'}
          section={'act40'}
          textColor={rowData.varDay === 'yes' ? mainColors.secondaryRed : null}
        />
      ),
      customSort: (a, b) =>
        statusSort(a, b, 'valueAtRiskStatus', 'valueAtRiskValue'),
    },
    clientName !== 'greshamhouse_funds'
      ? {
          pdfWidth: 10,
          title: '40 Act Law',
          field: 'act40Law',
          pdfRenderType: 'PageLinkButton',
          cellStyle: {
            ...generalCellStyles,
            textAlign: 'center',
            width: '10%',
          },
          headerStyle: {
            textAlign: 'center',
            width: '10%',
          },
          render: (rowData: OverviewTableData) => (
            <PageLinkButton
              status={specifyStatus(rowData.act40Law || 'N/A')}
              fundName={rowData.internalFundName}
              page={'act40-law'}
              section={'act40'}
            />
          ),
          customSort: (a, b) => statusSort(a, b, 'act40Law'),
        }
      : {
          width: 0,
        },
    {
      pdfWidth: 10,
      title: 'Prospectus Restrictions',
      field: 'prospectusStatus',
      pdfRenderType: 'PageLinkButton',
      cellStyle: {
        ...generalCellStyles,
        textAlign: 'center',
        width: '10%',
      },
      headerStyle: {
        ...generalHeaderStyles,
        textAlign: 'center',
        width: '10%',
      },
      render: (rowData: OverviewTableData) => (
        <PageLinkButton
          status={specifyStatus(rowData.prospectusStatus)}
          fundName={rowData.internalFundName}
          page={'prospectus-restrictions'}
          section={'act40'}
        />
      ),
      customSort: (a, b) => statusSort(a, b, 'prospectusStatus'),
    },
    {
      title: 'Notes',
      field: 'notes',
      render: (rowData: OverviewTableData) => (
        <NotesComp
          fundId={rowData.internalFundName}
          fundName={rowData.fundName}
          subject="ucits_ucits-overview"
          topic={rowData.positionDate}
          positionDate={rowData.positionDate}
        />
      ),
      cellStyle: {
        padding: '1.6rem',
        textAlign: 'center',
        paddingRight: '2.4rem',
        paddingLeft: '0.8rem',
        width: '5rem',
      },
      headerStyle: {
        textAlign: 'center',
        padding: 'auto 1.6rem',
        paddingRight: '2.4rem',
        paddingLeft: '0.8rem',
        width: '5rem',
      },
    },
  ];

  return ucitsOverviewTableColumns;
};

function getDatesFromSubFundRiskReportNames(reportNames: string[]): Date[] {
  if (!reportNames) return [];
  if (!reportNames.length) return [];
  const dates: Date[] = [];
  reportNames.forEach((reportName: string) => {
    // Get the last 14 characters from the report name.
    const formattedDate = reportName.slice(-14).replace('.pdf', '');
    dates.push(new Date(formattedDate));
  });
  const sortedDates = dates.sort();
  return sortedDates;
}

function getDatesFromSubFundRiskReportNamesAsStrings(
  reportNames: string[],
): string[] {
  if (!reportNames) return [];
  if (!reportNames.length) return [];
  const dates: string[] = [];
  reportNames.forEach((reportName: string) => {
    const formattedDate = reportName.slice(-14).replace('.pdf', '');
    dates.push(formattedDate);
  });
  return dates;
}

function findSubFundReportnameFromDate(
  date: Date | null,
  reportNames: string[],
): string | null {
  if (!date) return null;
  // Format the date as a string
  const dateString = formatDateForCheckingState(date);
  let foundReportName = '';
  reportNames.forEach((reportName: string) => {
    const formattedDate = reportName.slice(-14).replace('.pdf', '');
    if (dateString === formattedDate) {
      foundReportName = reportName;
      return;
    }
  });
  return foundReportName;
}

export const useOverviewColumnsPure = (
  clientName: string | null,
  additionalProps?: AdditionalPropsForColumns,
): CustomColumn<OverviewTableData>[] => {
  const theme = useTheme();

  if (!clientName) return [];

  const generalCellStyles:
    | React.CSSProperties
    | ((data: object[], rowData: object) => React.CSSProperties) = {
    fontSize: '1.4rem',
    color: theme.palette.grey[900],
  };

  const generalHeaderStyles: React.CSSProperties = {};

  const alignCenter:
    | React.CSSProperties
    | ((data: object[], rowData: object) => React.CSSProperties) = {
    textAlign: 'center',
  };

  const ucitsOverviewTableColumns: CustomColumn<OverviewTableData>[] = [
    {
      title: '#',
      field: 'index',
      width: '2%',
      cellStyle: {
        ...generalCellStyles,
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
        ...generalHeaderStyles,
      },
    },
    {
      title: 'Internal Fund Name',
      field: 'internalFundName',
      render: (rowData) =>
        clientName === 'mersenne'
          ? 'Fund' + rowData.index
          : additionalProps?.clickThroughPages
            ? (() => {
                const clickThroughFunds = additionalProps.clickThroughPages.map(
                  (el) => el.fundId,
                );
                if (clickThroughFunds.includes(rowData.internalFundName)) {
                  const clickThroughVals =
                    additionalProps.clickThroughPages.find(
                      (el) => el.fundId === rowData.internalFundName,
                    );
                  return (
                    <ClickThroughPageButton
                      tooltipVal={clickThroughVals?.tooltipValue || ''}
                      name={clickThroughVals?.fundId || ''}
                      path={`${clickThroughVals?.path}?fundId=${clickThroughVals?.fundId}`}
                    />
                  );
                }
                return rowData.internalFundName;
              })()
            : rowData.internalFundName,
      cellStyle: {
        ...generalCellStyles,
      },
      headerStyle: { ...generalHeaderStyles },
    },
    {
      title: 'Fund Name',
      field: 'fundName',
      pdfRenderType: 'DetailLine',
      headerStyle: { ...generalHeaderStyles },
      cellStyle: {
        ...generalCellStyles,
        fontSize: '1.4rem',
      },
      width: '18%',
      render: (rowData) => {
        return (
          <DetailLine
            initialText={rowData.fundName}
            detailLine={rowData.derogationMessage}
            detailStyle={{ margin: 0, color: mainColors.Fail }}
          />
        );
      },
    },
    {
      title: 'Reports',
      field: 'reports',
      sorting: false,
      render: (rowData: OverviewTableData) => (
        <GeneralReportDatePicker
          reportNames={rowData.reports}
          reportUrl="ucits_historical_report"
          fundId={rowData.internalFundName}
        />
      ),
      cellStyle: {
        ...generalCellStyles,
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
        ...generalHeaderStyles,
      },
    },
    {
      title: 'Sub Fund Risk Reports',
      field: 'subFundRiskReports',
      sorting: false,
      // render: reportsSelect,
      render: (rowData: OverviewTableData) => (
        <GeneralReportDatePicker
          reportNames={rowData.subFundRiskReports}
          reportUrl="ucits_historical_report"
          fundId={rowData.internalFundName}
          customGetDatesFromReportNamesFunction={
            getDatesFromSubFundRiskReportNames
          }
          customGetDatesFromReportNamesAsStringsFunction={
            getDatesFromSubFundRiskReportNamesAsStrings
          }
          customFindReportnameFromDate={findSubFundReportnameFromDate}
        />
      ),
      // width: '18%',
      cellStyle: {
        ...generalCellStyles,
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
        ...generalHeaderStyles,
      },
    },
    {
      title: 'Position Date',
      field: 'positionDate',
      cellStyle: {
        ...generalCellStyles,
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
        ...generalHeaderStyles,
      },
      render: (rowData: OverviewTableData) => (
        <CalendarPopup
          date={rowData.positionDate}
          fundId={rowData.internalFundName}
        />
      ),
      customSort: (a, b) => {
        const aDate = new Date(a.positionDate);
        const bDate = new Date(b.positionDate);
        return aDate.getTime() - bDate.getTime();
      },
    },
    {
      title: 'Latest Calculation Timestamp',
      field: 'calculationDate',
      width: '8%',
      cellStyle: {
        ...generalCellStyles,
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
        ...generalHeaderStyles,
      },
    },
    {
      hidden: true,
      field: 'derogationMessage',
      searchable: true,
    },
    {
      title: 'Currency',
      field: 'currency',
      cellStyle: {
        textAlign: 'center',
        ...generalCellStyles,
      },
      headerStyle: {
        textAlign: 'center',
      },
      width: '4%',
    },
    {
      title: 'Net Asset Value',
      field: 'netAssetValue',
      width: '15rem',
      render: (rowData: OverviewTableData) =>
        addCommasToNumbersAndRound(rowData.netAssetValue),
      cellStyle: {
        ...generalCellStyles,
        textAlign: 'right',
      },
      headerStyle: {
        textAlign: 'center',
      },
      customSort: (a, b) => {
        return b.netAssetValue - a.netAssetValue;
      },
    },

    // STATUSES =====================================================
    clientName === 'greshamhouse'
      ? {
          title: 'Leverage',
          field: 'leverage',
          pdfRenderType: 'PageLinkButton',
          cellStyle: {
            ...generalCellStyles,
            ...alignCenter,
          },
          headerStyle: {
            ...alignCenter,
          },
          width: '10%',
          pdfWidth: 10,
          render: (rowData: OverviewTableData) => (
            <PageLinkButton
              status={rowData.leverage}
              fundName={rowData.internalFundName}
              text={rowData.leverage}
              page={'exposure'}
              section={'partii'}
            />
          ),
          customSort: (a, b) =>
            statusSort(a, b, 'commitmentStatus', 'commitmentValue'),
        }
      : {
          title: 'Commitment',
          field: 'commitmentValue',
          pdfRenderType: 'PageLinkButton',
          cellStyle: {
            ...generalCellStyles,
            ...alignCenter,
          },
          headerStyle: {
            ...alignCenter,
          },
          width: '10%',
          pdfWidth: 10,
          render: (rowData: OverviewTableData) => (
            <PageLinkButton
              status={rowData.commitmentStatus}
              fundName={rowData.internalFundName}
              text={
                rowData.fundType === 'commitment' || rowData.fundType === 'both'
                  ? percentageToTwoDecimalPlaces(rowData.commitmentValue)
                  : 'N/R'
              }
              page={'exposure'}
              section={'partii'}
            />
          ),
          customSort: (a, b) =>
            statusSort(a, b, 'commitmentStatus', 'commitmentValue'),
        },
    {
      pdfWidth: 10,
      title: 'Value at Risk',
      field: 'valueAtRiskValue',
      width: '10%',
      cellStyle: {
        ...generalCellStyles,
        ...alignCenter,
      },
      pdfRenderType: 'PageLinkButton',
      headerStyle: {
        ...alignCenter,
      },
      render: (rowData: OverviewTableData) => (
        <PageLinkButton
          status={specifyStatus(rowData.valueAtRiskStatus)}
          fundName={rowData.internalFundName}
          text={
            rowData.fundType === 'var' || rowData.fundType === 'both'
              ? percentageToTwoDecimalPlaces(rowData.valueAtRiskValue)
              : 'N/R'
          }
          page={'value-at-risk'}
          section={'partii'}
          textColor={rowData.varDay === 'yes' ? mainColors.secondaryRed : null}
        />
      ),
      customSort: (a, b) =>
        statusSort(a, b, 'valueAtRiskStatus', 'valueAtRiskValue'),
    },
    {
      pdfWidth: 10,
      title: 'Prospectus Restrictions',
      field: 'prospectusStatus',
      pdfRenderType: 'PageLinkButton',
      width: '10%',
      cellStyle: {
        ...generalCellStyles,
        ...alignCenter,
      },
      headerStyle: {
        ...generalHeaderStyles,
        ...alignCenter,
      },
      render: (rowData: OverviewTableData) => (
        <PageLinkButton
          status={specifyStatus(rowData.prospectusStatus)}
          fundName={rowData.internalFundName}
          page={'prospectus-restrictions'}
          section={'partii'}
        />
      ),
      customSort: (a, b) => statusSort(a, b, 'prospectusStatus'),
    },
    {
      title: 'Notes',
      field: 'notes',
      width: '5rem',
      render: (rowData: OverviewTableData) => (
        <NotesComp
          fundId={rowData.internalFundName}
          fundName={rowData.fundName}
          subject="partii_partii-overview"
          topic={rowData.positionDate}
          positionDate={rowData.positionDate}
        />
      ),
      cellStyle: {
        padding: '1.6rem',
        textAlign: 'center',
        paddingRight: '2.4rem',
        paddingLeft: '0.8rem',
      },
      headerStyle: {
        textAlign: 'center',
        padding: 'auto 1.6rem',
        paddingRight: '2.4rem',
        paddingLeft: '0.8rem',
      },
    },
  ];

  return ucitsOverviewTableColumns;
};

export const useOverviewColumnsDefault = (
  clientName: string | null,
  additionalProps?: AdditionalPropsForColumns,
): CustomColumn<OverviewTableData>[] => {
  const theme = useTheme();

  if (!clientName) return [];

  const generalCellStyles:
    | React.CSSProperties
    | ((data: object[], rowData: object) => React.CSSProperties) = {
    fontSize: '1.4rem',
    color: theme.palette.grey[900],
  };

  const overviewTableColumns: CustomColumn<OverviewTableData>[] = [
    {
      title: '#',
      field: 'index',
      cellStyle: {
        ...generalCellStyles,
        textAlign: 'center',
        width: '2rem',
      },
      headerStyle: {
        textAlign: 'center',
        width: '2rem',
      },
    },
    {
      title: 'Internal Fund Name',
      field: 'internalFundName',
      render: (rowData) =>
        clientName === 'mersenne' || clientName === 'mersenne_prime'
          ? 'Fund' + rowData.index
          : additionalProps?.clickThroughPages
            ? (() => {
                const clickThroughFunds = additionalProps.clickThroughPages.map(
                  (el) => el.fundId,
                );
                if (clickThroughFunds.includes(rowData.internalFundName)) {
                  const clickThroughVals =
                    additionalProps.clickThroughPages.find(
                      (el) => el.fundId === rowData.internalFundName,
                    );
                  return (
                    <ClickThroughPageButton
                      tooltipVal={clickThroughVals?.tooltipValue || ''}
                      name={clickThroughVals?.fundId || ''}
                      path={`${clickThroughVals?.path}?fundId=${clickThroughVals?.fundId}`}
                    />
                  );
                }
                return rowData.internalFundName;
              })()
            : rowData.internalFundName,
      cellStyle: {
        ...generalCellStyles,
      },
    },
    {
      title: 'Fund Name',
      field: 'fundName',
      pdfRenderType: 'DetailLine',
      cellStyle: {
        ...generalCellStyles,
        fontSize: '1.4rem',
        whiteSpace: 'nowrap',
      },
      render: (rowData) => {
        return (
          <DetailLine
            initialText={rowData.fundName}
            detailLine={rowData.derogationMessage}
            detailStyle={{ margin: 0, color: mainColors.Fail }}
          />
        );
      },
    },
    {
      title: 'Reports',
      field: 'reports',
      sorting: false,
      // render: reportsSelect,
      render: (rowData: OverviewTableData) => (
        <GeneralReportDatePicker
          reportNames={rowData.reports}
          reportUrl="ucits_historical_report"
          fundId={rowData.internalFundName}
        />
      ),
      cellStyle: {
        ...generalCellStyles,
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
      },
    },
    clientName === 'pure'
      ? {
          title: 'Sub Fund Risk Reports',
          field: 'subFundRiskReports',
          sorting: false,
          // render: reportsSelect,
          render: (rowData: OverviewTableData) => (
            <GeneralReportDatePicker
              reportNames={rowData.subFundRiskReports}
              reportUrl="ucits_historical_report"
              fundId={rowData.internalFundName}
              customGetDatesFromReportNamesFunction={
                getDatesFromSubFundRiskReportNames
              }
              customGetDatesFromReportNamesAsStringsFunction={
                getDatesFromSubFundRiskReportNamesAsStrings
              }
              customFindReportnameFromDate={findSubFundReportnameFromDate}
            />
          ),
          cellStyle: {
            ...generalCellStyles,
            textAlign: 'center',
          },
          headerStyle: {
            textAlign: 'center',
          },
        }
      : {},
    {
      title: 'Position Date',
      field: 'positionDate',
      cellStyle: {
        ...generalCellStyles,
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
      },
      // Comment out for now until ready to go live
      render: (rowData: OverviewTableData) => (
        <CalendarPopup
          date={rowData.positionDate}
          fundId={rowData.internalFundName}
        />
      ),
      customSort: (a, b) => {
        const aDate = new Date(a.positionDate);
        const bDate = new Date(b.positionDate);
        return aDate.getTime() - bDate.getTime();
      },
    },
    {
      title: 'Latest Calculation Timestamp',
      field: 'calculationDate',
      cellStyle: {
        ...generalCellStyles,
        textAlign: 'center',
        width: '8%',
      },
      headerStyle: {
        textAlign: 'center',
        width: '8%',
      },
    },
    {
      hidden: true,
      field: 'derogationMessage',
      searchable: true,
    },
    {
      title: 'Currency',
      field: 'currency',
      cellStyle: {
        textAlign: 'center',
        ...generalCellStyles,
        width: '4%',
      },
      headerStyle: {
        textAlign: 'center',
        width: '4%',
      },
    },
    {
      title: 'Net Asset Value',
      field: 'netAssetValue',
      render: (rowData: OverviewTableData) =>
        addCommasToNumbersAndRound(rowData.netAssetValue),
      cellStyle: {
        ...generalCellStyles,
        textAlign: 'right',
        width: '15rem',
      },
      headerStyle: {
        textAlign: 'center',
        width: '15rem',
      },
      customSort: (a, b) => {
        return b.netAssetValue - a.netAssetValue;
      },
    },

    // STATUSES =====================================================
    clientName === 'greshamhouse'
      ? {
          title: 'Leverage',
          field: 'leverage',
          pdfRenderType: 'PageLinkButton',
          cellStyle: {
            ...generalCellStyles,
            textAlign: 'center',
            width: '10%',
          },
          headerStyle: {
            textAlign: 'center',
            width: '10%',
          },
          pdfWidth: 10,
          render: (rowData: OverviewTableData) => (
            <PageLinkButton
              status={rowData.leverage}
              fundName={rowData.internalFundName}
              text={rowData.leverage}
              page={'exposure'}
              section={'qiaif'}
            />
          ),
          customSort: (a, b) =>
            statusSort(a, b, 'commitmentStatus', 'commitmentValue'),
        }
      : {
          title: 'Commitment',
          field: 'commitmentValue',
          pdfRenderType: 'PageLinkButton',
          cellStyle: {
            ...generalCellStyles,
            textAlign: 'center',
            width: '10%',
          },
          headerStyle: {
            textAlign: 'center',
            width: '10%',
          },
          pdfWidth: 10,
          render: (rowData: OverviewTableData) => (
            <PageLinkButton
              status={rowData.commitmentStatus}
              fundName={rowData.internalFundName}
              text={
                rowData.fundType === 'commitment' || rowData.fundType === 'both'
                  ? percentageToTwoDecimalPlaces(rowData.commitmentValue)
                  : 'N/R'
              }
              page={'exposure'}
              section={'ucits'}
            />
          ),
          customSort: (a, b) =>
            statusSort(a, b, 'commitmentStatus', 'commitmentValue'),
        },
    {
      pdfWidth: 10,
      title: 'Value at Risk',
      field: 'valueAtRiskValue',
      cellStyle: {
        ...generalCellStyles,
        textAlign: 'center',
        width: '10%',
      },
      pdfRenderType: 'PageLinkButton',
      headerStyle: {
        textAlign: 'center',
        width: '10%',
      },
      render: (rowData: OverviewTableData) => (
        <PageLinkButton
          status={specifyStatus(rowData.valueAtRiskStatus)}
          fundName={rowData.internalFundName}
          text={
            rowData.fundType === 'var' || rowData.fundType === 'both'
              ? percentageToTwoDecimalPlaces(rowData.valueAtRiskValue)
              : 'N/R'
          }
          page={'value-at-risk'}
          section={'ucits'}
          textColor={rowData.varDay === 'yes' ? mainColors.secondaryRed : null}
        />
      ),
      customSort: (a, b) =>
        statusSort(a, b, 'valueAtRiskStatus', 'valueAtRiskValue'),
    },
    clientName !== 'greshamhouse_funds'
      ? {
          pdfWidth: 10,
          title: 'UCITS Law',
          field: 'ucitsLaw',
          pdfRenderType: 'PageLinkButton',
          cellStyle: {
            ...generalCellStyles,
            textAlign: 'center',
            width: '10%',
          },
          headerStyle: {
            textAlign: 'center',
            width: '10%',
          },
          render: (rowData: OverviewTableData) => (
            <PageLinkButton
              status={specifyStatus(rowData.ucitsLaw)}
              fundName={rowData.internalFundName}
              page={'ucits-law'}
              section={'ucits'}
            />
          ),
          customSort: (a, b) => statusSort(a, b, 'ucitsLaw'),
        }
      : {
          width: 0,
        },
    {
      pdfWidth: 10,
      title: 'Prospectus Restrictions',
      field: 'prospectusStatus',
      pdfRenderType: 'PageLinkButton',
      cellStyle: {
        ...generalCellStyles,
        textAlign: 'center',
        width: '10%',
      },
      headerStyle: {
        textAlign: 'center',
        width: '10%',
      },
      render: (rowData: OverviewTableData) => (
        <PageLinkButton
          status={specifyStatus(rowData.prospectusStatus)}
          fundName={rowData.internalFundName}
          page={'prospectus-restrictions'}
          section={'ucits'}
        />
      ),
      customSort: (a, b) => statusSort(a, b, 'prospectusStatus'),
    },
    {
      title: 'Notes',
      field: 'notes',
      render: (rowData: OverviewTableData) => (
        <NotesComp
          fundId={rowData.internalFundName}
          fundName={rowData.fundName}
          subject="ucits_ucits-overview"
          topic={rowData.positionDate}
          positionDate={rowData.positionDate}
        />
      ),
      cellStyle: {
        padding: '1.6rem',
        textAlign: 'center',
        paddingRight: '2.4rem',
        paddingLeft: '0.8rem',
        width: '5rem',
      },
      headerStyle: {
        textAlign: 'center',
        padding: 'auto 1.6rem',
        paddingRight: '2.4rem',
        paddingLeft: '0.8rem',
        width: '5rem',
      },
    },
  ];

  return overviewTableColumns;
};

type FundType = 'var' | 'commitment' | 'both';

export function createMainTableData(
  inputData: any[],
  reportsList: any,
  subFundRiskReportsList: any,
  clientName: string | null,
  section: string | null,
): OverviewTableData[] {
  const finalData: OverviewTableData[] = [];

  if (inputData.length && reportsList) {
    const reports = reportsList[0];
    const subFundRiskReports = subFundRiskReportsList
      ? subFundRiskReportsList[0]
      : null;

    inputData.forEach((fund: any, index: number) => {
      // calculate fund type
      let fundType: FundType =
        fund.monitor_data.var_fund === 'yes' ? 'var' : 'commitment';

      // some clients request to have both calculations shown
      if (clientName?.includes('pure')) {
        fundType = 'both';
      }

      const toAdd: OverviewTableData = {
        index: index + 1,
        internalFundName: fund.fund_name,
        fundName: fund.fund_name_full,
        reports: reports[fund.fund_name],
        subFundRiskReports: subFundRiskReports
          ? subFundRiskReports[fund.fund_name]
          : [],
        positionDate: fund.monitor_data.selected_position_date,
        calculationDate: fund.monitor_data.latest_calculation_timestamp,
        currency: fund.monitor_data.base_currency,
        netAssetValue: fund.monitor_data.nav,
        commitmentStatus: specifyStatus(fund.monitor_data.Commitment),
        valueAtRiskStatus: specifyStatus(fund.monitor_data.Value_at_Risk),
        commitmentValue:
          fundType === 'commitment' || fundType === 'both'
            ? fund.monitor_data.commitment_value
            : -1, // var_value: Math.round(data.var_value * 100),
        valueAtRiskValue:
          fundType === 'var' || fundType === 'both'
            ? fund.monitor_data.var_value
            : -1,
        varDay: fund.monitor_data.var_day,
        varFund: fund.monitor_data.var_fund,
        ucitsLaw: specifyStatus(fund.monitor_data.UCITS),
        prospectusStatus: specifyStatus(fund.monitor_data.Risk_Compliance),
        countryExposure: fund.monitor_data.country_exposure,
        positionExposure: fund.monitor_data.position_exposure,
        derogationMessage:
          fund.monitor_data.six_month_derogation?.result === 'live'
            ? `Derogation Expiry Date: ${fund.monitor_data.six_month_derogation.derogation_date}`
            : undefined,
        timeSeriesData: null,
        timeSeriesStatus: null,
        notes: true,
        leverage: specifyStatus(fund.monitor_data.Leverage),
        fundType: fundType,
      };

      if (fund.monitor_data.historical_time_series) {
        // if the historical time series is there
        const timeSeries = fund.monitor_data.historical_time_series;
        timeSeries.shift();
        toAdd['timeSeriesData'] = timeSeries;
      }

      if (fund.monitor_data.historical_time_series_status)
        toAdd['timeSeriesStatus'] =
          fund.monitor_data.historical_time_series_status;

      if (section === 'act40') {
        toAdd['act40Law'] = specifyStatus(fund.monitor_data.ACT40);
      }

      finalData.push(toAdd);
    });
  }
  return finalData;
}
