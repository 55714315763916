import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import NavSection from './NavSection.component';
import { Section } from '../../../types/server-data/ClientConfig';
import { clientSectionsSelector } from '../../../redux/pages/selectors';
import makeStyles from '@mui/styles/makeStyles';
import { clientNameSelector } from '../../../redux/auth/selectors';

const useStyles = makeStyles({
  navContainerRoot: {
    display: 'flex',
    width: '100%',
    height: '100%',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
});

const NavContainer: React.FC = (props): ReactElement => {
  const classes = useStyles();
  const clientName = useSelector(clientNameSelector);

  const clientSections = useSelector(clientSectionsSelector) || [];

  // Quick hack for Peter to remove the performance diagnostics page from the PA section for Trium while keeping the titlebar options
  const updatedClientSections =
    clientName === 'trium'
      ? clientSections.map((section) => {
          if (section.sectionId === 'pa') {
            return {
              ...section,
              pages: section.pages.filter(
                (page) => page !== 'performance-diagnostics',
              ),
            };
          }
          return section;
        })
      : clientSections;

  return (
    <div className={classes.navContainerRoot}>
      {updatedClientSections.map((section: Section) => {
        return <NavSection key={section.sectionId} section={section} />;
      })}
    </div>
  );
};

export default NavContainer;
