import React, { Dispatch, ReactElement, SetStateAction } from 'react';
import { DataObject } from '../../../../types/redux/data/dataTypes';
import { CustomColumn } from '../../../../types/components/tables/tableTypes';
import GridItem from '../../../layout/GridComponents/GridItem';
import CustomTable from '../../../tables/CustomTable';
import FillCell from '../../../tables/FillCell';
import { mainColors } from '../../../../styling/theme';
import { addCommasToNumbersAndRound } from '../../../../utilities/numberFormatters';
import { useSelector } from 'react-redux';
import { createSectionFundsSelector } from '../../../../redux/pages/selectors';
import makeStyles from '@mui/styles/makeStyles';
import { Typography } from '@mui/material';

interface Props {
  activeFund: string;
  stressLevel: string;
  fundAssetData: DataObject;
  stressLevelOptions: any;
}
interface StressSelectProps {
  stressLevel: string;
  setStressLevel: Dispatch<SetStateAction<string>>;
}
export interface BasicLiquidityData {
  zeroToOne: number;
  twoToSeven: number;
  eightToThirty: number;
  thirtyOneToNinety: number;
  ninetyOneToOneEighty: number;
  OneEightOneToThreeSixFour: number;
  moreThanAYear: number;
}
export type StressLevel =
  | 'standard'
  | 'stressed'
  | 'superStressed'
  | 'euSovDebtCrisisJul11'
  | 'creditCrisisSept2008';
export interface AggregateLiquidityData extends BasicLiquidityData {
  assetName: string;
  [key: string]: any;
  isin?: string;
  grossExposure: string | number;
  itemNumber?: number;
  stressLevel: string;
  LiquidationDays90Percent?: number;
  LiquidationDays100Percent?: number;
}
export const stressLevelMap = {
  standard: 'Standard',
  stressed: 'Stressed',
  superStressed: 'Super Stressed',
  euSovDebtCrisisJul11: 'EU Sov Debt Crisis Jul11',
  creditCrisisSept2008: 'Credit Crisis Sept08',
};
export interface IFundAssetLiquidityData extends AggregateLiquidityData {
  position: string | number;
  headerRow: boolean;
  scenario: string;
}

export const csvFundAssetLiquidationTime = [
  'assetName',
  'position',
  'grossExposure',
  'isin',
  'zeroToOne',
  'twoToSeven',
  'eightToThirty',
  'thirtyOneToNinety',
  'ninetyOneToOneEighty',
  'OneEightOneToThreeSixFour',
  'moreThanAYear',
];

export const fundAssetSummaryColumnsBasic: CustomColumn<IFundAssetLiquidityData>[] =
  [
    {
      title: 'Gross Exposure',
      field: 'grossExposure',
      cellStyle: {
        textAlign: 'center',
        padding: 0,
        paddingRight: '1rem',
        minWidth: '44rem',
      },
    },
    {
      title: '0 - 1',
      field: 'zeroToOne',
      width: '15rem',
      cellStyle: {
        padding: 0,
      },
    },
    {
      title: '2 - 7',
      field: 'twoToSeven',
      width: '15rem',
      cellStyle: {
        padding: 0,
      },
    },
    {
      title: '8 - 30',
      field: 'eightToThirty',
      width: '15rem',
      cellStyle: {
        padding: 0,
      },
    },
    {
      title: '31 - 90',
      field: 'thirtyOneToNinety',
      width: '15rem',
      cellStyle: {
        padding: 0,
      },
    },
    {
      title: '91 - 180',
      field: 'ninetyOneToOneEighty',
      width: '15rem',
      cellStyle: {
        padding: 0,
      },
    },
    {
      title: '180 - 364',
      field: 'OneEightOneToThreeSixFour',
      width: '15rem',
      cellStyle: {
        padding: 0,
      },
    },
    {
      title: '> 365',
      field: 'moreThanAYear',
      width: '15rem',
      cellStyle: {
        padding: 0,
      },
    },
  ];
export const fundAssetSummaryColumnsBusinessDays: CustomColumn<IFundAssetLiquidityData>[] =
  [
    {
      title: 'Gross Exposure',
      field: 'grossExposure',
      cellStyle: {
        textAlign: 'center',
        padding: 0,
        paddingRight: '1rem',
        minWidth: '30rem',
      },
    },
    {
      title: 'Business Days to Liquidate 90%',
      field: 'LiquidationDays90Percent',
      cellStyle: {
        textAlign: 'center',
        padding: 0,
        paddingRight: '1rem',
        minWidth: '7rem',
      },
    },
    {
      title: 'Business Days to Liquidate 100%',
      field: 'LiquidationDays100Percent',
      cellStyle: {
        textAlign: 'center',
        padding: 0,
        paddingRight: '1rem',
        minWidth: '7rem',
      },
    },
    {
      title: '0 - 1',
      field: 'zeroToOne',
      width: '15rem',
      cellStyle: {
        padding: 0,
      },
    },
    {
      title: '2 - 7',
      field: 'twoToSeven',
      width: '15rem',
      cellStyle: {
        padding: 0,
      },
    },
    {
      title: '8 - 30',
      field: 'eightToThirty',
      width: '15rem',
      cellStyle: {
        padding: 0,
      },
    },
    {
      title: '31 - 90',
      field: 'thirtyOneToNinety',
      width: '15rem',
      cellStyle: {
        padding: 0,
      },
    },
    {
      title: '91 - 180',
      field: 'ninetyOneToOneEighty',
      width: '15rem',
      cellStyle: {
        padding: 0,
      },
    },
    {
      title: '180 - 364',
      field: 'OneEightOneToThreeSixFour',
      width: '15rem',
      cellStyle: {
        padding: 0,
      },
    },
    {
      title: '> 365',
      field: 'moreThanAYear',
      width: '15rem',
      cellStyle: {
        padding: 0,
      },
    },
  ];

export const fundAssetColumns: CustomColumn<IFundAssetLiquidityData>[] = [
  {
    title: 'Asset Name',
    field: 'assetName',
    cellStyle: {
      padding: 0,
      paddingLeft: '1rem',
    },
  },
  {
    title: 'ISIN',
    field: 'isin',
    cellStyle: {
      textAlign: 'center',
      padding: 0,
      paddingRight: '1rem',
    },
  },
  {
    title: 'Position',
    field: 'position',
    cellStyle: {
      textAlign: 'center',
      padding: 0,
      paddingRight: '1rem',
    },
  },

  {
    title: 'Gross Exposure',
    field: 'grossExposure',
    cellStyle: {
      textAlign: 'center',
      padding: 0,
      paddingRight: '1rem',
    },
  },
  {
    title: '0 - 1',
    field: 'zeroToOne',
    width: '15rem',
    cellStyle: {
      padding: 0,
      textAlign: 'center',
    },
  },
  {
    title: '2 - 7',
    field: 'twoToSeven',
    width: '15rem',
    cellStyle: {
      padding: 0,
      textAlign: 'center',
    },
  },
  {
    title: '8 - 30',
    field: 'eightToThirty',
    width: '15rem',
    cellStyle: {
      padding: 0,
      textAlign: 'center',
    },
  },
  {
    title: '31 - 90',
    field: 'thirtyOneToNinety',
    width: '15rem',
    cellStyle: {
      padding: 0,
      textAlign: 'center',
    },
  },
  {
    title: '91 - 180',
    field: 'ninetyOneToOneEighty',
    width: '15rem',
    cellStyle: {
      padding: 0,
      textAlign: 'center',
    },
  },
  {
    title: '180 - 364',
    field: 'OneEightOneToThreeSixFour',
    width: '15rem',
    cellStyle: {
      padding: 0,
      textAlign: 'center',
    },
  },
  {
    title: '> 365',
    field: 'moreThanAYear',
    width: '15rem',
    cellStyle: {
      padding: 0,
      textAlign: 'center',
    },
  },
];

export const mapStressToColor = (stressLevel: string) => {
  switch (stressLevel) {
    case 'data':
      return mainColors.mainBlue;
    case 'data_s':
      return mainColors.Fail;
    case 'data_ss':
      return mainColors.Fail_even_darker;
    default:
      return mainColors.mainBlue;
  }
};
export const createColumn: <T extends AggregateLiquidityData>(
  col: CustomColumn<T>,
  stressLevel: string,
) => CustomColumn<T> = (col, stressLevel) => {
  const isFillBoxColumn =
    col.field !== 'assetName' &&
    col.field !== 'grossExposure' &&
    col.field !== 'position' &&
    col.field !== 'LiquidationDays90Percent' &&
    col.field !== 'LiquidationDays100Percent' &&
    col.field !== 'isin';
  return {
    ...col,
    render: (rowData) =>
      isFillBoxColumn ? (
        <FillCell
          delayMultiplier={rowData.itemNumber}
          cellColor={mapStressToColor(stressLevel)}
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          value={rowData[col.field!]}
        />
      ) : col.field !== 'assetName' && col.field !== 'isin' ? (
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        addCommasToNumbersAndRound(rowData[col.field as string] as number)
      ) : (
        rowData[col.field]
      ),
    pdfRenderType: isFillBoxColumn ? 'FillBox' : undefined,
    renderMethod: isFillBoxColumn
      ? {
          methodName: 'statusBox',
          params: ['itemNumber', 'stressLevel', col.field as string],
        }
      : undefined,
    headerStyle: {
      textAlign: col.field === 'assetName' ? 'left' : 'center',
    },
  };
};

export const createFundAssetColumns: (
  stressLevel: string,
) => CustomColumn<IFundAssetLiquidityData>[] = (stressLevel: string) => {
  return fundAssetColumns.map((col: CustomColumn<IFundAssetLiquidityData>) =>
    createColumn(col, stressLevel),
  );
};

export const createOverviewColumns: (
  stressLevel: string,
) => CustomColumn<IFundAssetLiquidityData>[] = (stressLevel: string) => {
  const columnsToUse = ['data', 'data_s', 'data_ss'].includes(stressLevel)
    ? fundAssetSummaryColumnsBusinessDays
    : fundAssetSummaryColumnsBasic;
  return columnsToUse.map((col: CustomColumn<IFundAssetLiquidityData>) =>
    createColumn(col, stressLevel),
  );
};

const useStyles = makeStyles((theme) => ({
  liqduiationTimeDaysLabel: {
    justifyContent: 'center',
    marginLeft: '29.7%',
    marginTop: '1rem',
  },
}));

export function buildFundAssetTableData(inputData: any, scenario: string) {
  if (!inputData.length) return [];
  const data = inputData[0];
  const key = scenario;

  let businessDays90: any = null;
  let businessDats100: any = null;
  if (scenario === 'data') {
    businessDays90 = data['90% Liquidation Business Days'];
    businessDats100 = data['100% Liquidation Business Days'];
  } else if (scenario === 'data_s') {
    businessDays90 = data['90% Liquidation Business Days Stressed'];
    businessDats100 = data['100% Liquidation Business Days Stressed'];
  } else if (scenario === 'data_ss') {
    businessDays90 = data['90% Liquidation Business Days SuperStressed'];
    businessDats100 = data['100% Liquidation Business Days SuperStressed'];
  }

  const areaWithData = data[key];
  if (!areaWithData) {
    return [];
  }
  const dataKeys = Object.keys(areaWithData);

  const returnedData = dataKeys.map((dataKey) => {
    if (dataKey !== 'Total') {
      const { position_size, gross_exposure, Scenario2, isin } =
        areaWithData[dataKey];
      const [
        zero_one,
        two_seven,
        eight_thirty,
        thirtyone,
        ninetyone,
        oneeightone,
        gt365,
      ] = Scenario2;
      return {
        scenario,
        stressLevel: scenario,
        headerRow: false,
        assetName: dataKey,
        isin: isin !== 'None' ? isin : '',
        position: position_size,
        grossExposure: Math.round(gross_exposure),
        zeroToOne: zero_one,
        twoToSeven: two_seven,
        eightToThirty: eight_thirty,
        thirtyOneToNinety: thirtyone,
        ninetyOneToOneEighty: ninetyone,
        OneEightOneToThreeSixFour: oneeightone,
        moreThanAYear: gt365,
      };
    }

    const [zero, two, eight, thirty, ninety, oneeight, gt] =
      areaWithData.Total.Scenario2;

    return {
      scenario,
      stressLevel: scenario,
      headerRow: true,
      assetName: 'Total',
      position: '',
      grossExposure: data.gross_exposure,
      zeroToOne: zero,
      twoToSeven: two,
      eightToThirty: eight,
      thirtyOneToNinety: thirty,
      ninetyOneToOneEighty: ninety,
      OneEightOneToThreeSixFour: oneeight,
      moreThanAYear: gt,
      LiquidationDays90Percent: businessDays90,
      LiquidationDays100Percent: businessDats100,
    };
  });

  returnedData.forEach((el: any, index) => {
    if (returnedData[index].headerRow) {
      const header = returnedData.splice(index, 1);
      returnedData.unshift(header[0]);
    }
  });

  return returnedData;
}

function FundAssetLiquidationTable({
  stressLevel,
  activeFund,
  fundAssetData,
  stressLevelOptions,
}: Props): ReactElement | null {
  const classes = useStyles();

  const tableData = buildFundAssetTableData(fundAssetData.data, stressLevel);
  const overviewColumns = createOverviewColumns(stressLevel);
  const columns = createFundAssetColumns(stressLevel);
  const fundsSelector = createSectionFundsSelector('liquidity');
  const funds = useSelector(fundsSelector);

  const fundTitle =
    funds && funds.length
      ? funds.find((fund: any) => fund.id === activeFund)?.name + ' - Breakdown'
      : '';

  const getScenarioName = (value: string) => {
    stressLevelOptions.forEach((item: any) => {
      if (item.value === value) {
        return item.label;
      }
    });
    return '';
  };

  return tableData.length ? (
    <>
      <GridItem xs={12} card>
        <Typography variant="h3" className={classes.liqduiationTimeDaysLabel}>
          {'Liquidation Time (Days)'}
        </Typography>
        <CustomTable<IFundAssetLiquidityData>
          showToolbar={false}
          loading={fundAssetData.isFetching}
          csvFields={csvFundAssetLiquidationTime}
          options={{
            paging: false,
            sorting: false,
          }}
          data={[tableData[0]]}
          columns={overviewColumns}
          toolbarComponents={{
            toolbarTitle: fundTitle,
          }}
        />
      </GridItem>
      <GridItem xs={12} card>
        <CustomTable<IFundAssetLiquidityData>
          title="Liquidation Time (Days)"
          showToolbar={true}
          noteExport={{
            fundId: activeFund,
            subject: 'ucits_fund-asset-liquidation-time',
            topic: 'Notes',
            positionDate: fundAssetData.data[0].selected_position_date,
            shouldCall: false,
          }}
          loading={fundAssetData.isFetching}
          csvFields={csvFundAssetLiquidationTime}
          options={{
            exportButton: true,
            paging: tableData.length > 50,
            pageSize: 50,
            pageSizeOptions: [50, 100, 200, 500],
            search: true,
            emptyRowsWhenPaging: false,
          }}
          data={tableData.filter((el, index) => index !== 0)}
          toolbarComponents={{
            toolbarTitle: fundTitle,
            titleStyle: {
              marginLeft: '32%',
              fontSize: '1.6rem',
              fontWeight: 400,
            },
          }}
          additionalDetailForPdf={
            stressLevel === 'data'
              ? 'Stress Level: BAU'
              : `Stress Level: ${getScenarioName(stressLevel)}`
          }
          columns={columns}
        />
      </GridItem>
    </>
  ) : null;
}

export default FundAssetLiquidationTable;
