import React from 'react';
import { DataObject } from '../../../../../types/redux/data/dataTypes';
import GenericLineChart from '../../../../charts/GenericLineChart';
import { ESGScoreKey } from './OverviewPanel.component';
import { mainColors } from '../../../../../styling/theme';
import { roundNumberToTwoDecimalPlaces } from '../../../../../utilities/numberFormatters';

interface HistoricalScoresLineChartProps {
  data: DataObject;
  scoreType: ESGScoreKey;
}

interface OverviewTimeSeriesData {
  name: string;
  value: number;
}
function mapScoreKeyToFullName(scoreKey: ESGScoreKey): string {
  switch (scoreKey) {
    case 'ESG_Score':
      return 'ESG';
    case 'E_Score':
      return 'Environmental';
    case 'S_Score':
      return 'Social';
    case 'G_Score':
      return 'Corporate Governance';
    default:
      return '';
  }
}

function mapScoreToColor(scoreKey: ESGScoreKey) {
  switch (scoreKey) {
    case 'E_Score':
      return mainColors.Pass_darker;
    case 'S_Score':
      return mainColors.pumpkin;
    case 'G_Score':
      return mainColors.fourthPurple;
    default:
      return mainColors.mainBlue;
  }
}

function buildOverviewTimeseriesData(
  data: any,
  scoreKey: ESGScoreKey,
): OverviewTimeSeriesData[] {
  if (!data.data.length) return [];
  if (!(scoreKey in data.data[0])) return [];
  return data.data[0][scoreKey].slice().reverse();
}

const HistoricalScoresLineChart: React.FC<HistoricalScoresLineChartProps> = ({
  data,
  scoreType,
}) => {
  const timeSeriesData = buildOverviewTimeseriesData(data, scoreType);

  return (
    <>
      <GenericLineChart
        title={`Historical ${mapScoreKeyToFullName(scoreType)} Score`}
        data={timeSeriesData}
        showTitle
        export={false}
        id={'esg_historical_chart'}
        xAxisDataKey="date"
        yAxisTickFormatter={(value: number) =>
          roundNumberToTwoDecimalPlaces(value)
        }
        tooltipFormatter={(value: number) =>
          roundNumberToTwoDecimalPlaces(value)
        }
        lines={[
          {
            dataKey: 'value',
            color: mapScoreToColor(scoreType),
          },
        ]}
        width={'100%'}
        height={400}
        showDot={false}
        margin={{
          top: 10,
          bottom: 10,
          left: 10,
          right: 40,
        }}
        error={'No Historical ESG Data available'}
      />
    </>
  );
};

export default HistoricalScoresLineChart;
