import { FC } from 'react';
import { mainColors } from '../../styling/theme';
import { formatDateForCheckingState } from '../../utilities/dateFormatters';
import { BASE_URL } from '../../utilities/requestClient';
import ReportDatePicker from '../selects/ReportDatePicker.component';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import { activeDateSelector } from '../../redux/pages/selectors';
import { Typography } from '@mui/material';

interface GeneralReportDatePickerProps {
  reportNames: string[];
  reportUrl: string;
  fundId: string;
  customGetDatesFromReportNamesFunction?: (reportNames: string[]) => Date[];
  customGetDatesFromReportNamesAsStringsFunction?: (
    reportNames: string[],
  ) => string[];
  customFindReportnameFromDate?: (
    date: Date | null,
    reportNames: string[],
  ) => string | null;
}

function openInNewTab(url: string) {
  return async () => {
    const win = window.open(url, '_blank');
    if (win) {
      win.focus();
      return true;
    } else {
      return false;
    }
  };
}

function getDatesFromReportNames(reportNames: string[]): Date[] {
  if (!reportNames) return [];
  if (!reportNames.length) return [];
  const dates: Date[] = [];
  reportNames.forEach((reportName: string) => {
    const formattedDate = reportName
      .replace('risksystem_daily_risk_report_', '')
      .replace('risksystem_daily_liquidity_risk_report_', '') // Add this in for the liquidity overview.
      .replace('.pdf', '')
      .replace(/_/g, '-');
    dates.push(new Date(formattedDate));
  });
  const sortedDates = dates.sort();
  return sortedDates;
}

function getDatesFromReportNamesAsStrings(reportNames: string[]): string[] {
  if (!reportNames) return [];
  if (!reportNames.length) return [];
  const dates: string[] = [];
  reportNames.forEach((reportName: string) => {
    const formattedDate = reportName
      .replace('risksystem_daily_risk_report_', '')
      .replace('risksystem_daily_liquidity_risk_report_', '') // Add this in for the liquidity overview.
      .replace('.pdf', '')
      .replace(/_/g, '-');
    dates.push(formattedDate);
  });
  return dates;
}

function findReportnameFromDate(
  date: Date | null,
  reportNames: string[],
): string | null {
  if (!date) return null;
  // Format the date as a string
  const dateString = formatDateForCheckingState(date);
  let foundReportName = '';
  reportNames.forEach((reportName: string) => {
    const formattedDate = reportName
      .replace('risksystem_daily_risk_report_', '')
      .replace('risksystem_daily_liquidity_risk_report_', '') // Add this in for the liquidity overview.
      .replace('.pdf', '')
      .replace(/_/g, '-');
    if (dateString === formattedDate) {
      foundReportName = reportName;
      return;
    }
  });
  return foundReportName;
}

function findRecentDate(dateList: string[], date: string) {
  const recentDate = dateList.find(
    (d: string) => new Date(d) <= new Date(date),
  );
  if (recentDate) {
    return recentDate;
  } else if (dateList.length) {
    return dateList[0];
  }
}

const GeneralReportDatePicker: FC<GeneralReportDatePickerProps> = (props) => {
  const activeDate = useSelector(activeDateSelector);

  const { reportNames, reportUrl, fundId } = props;
  const dates = props.customGetDatesFromReportNamesFunction
    ? props.customGetDatesFromReportNamesFunction(reportNames)
    : getDatesFromReportNames(reportNames);
  const datesStrings = props.customGetDatesFromReportNamesAsStringsFunction
    ? props.customGetDatesFromReportNamesAsStringsFunction(reportNames)
    : getDatesFromReportNamesAsStrings(reportNames);
  const maxDate = dates.length
    ? dates.reduce(function (a, b) {
        return a > b ? a : b;
      })
    : null;

  const dateHandler = (date: Date | null) => {
    if (!date) return;
    const reportName = props.customFindReportnameFromDate
      ? props.customFindReportnameFromDate(date, reportNames)
      : findReportnameFromDate(date, reportNames);
    if (reportName) {
      const openReport = openInNewTab(
        `${BASE_URL}${reportUrl}/${reportName}/${fundId}`,
      );
      openReport();
    }
  };

  return dates.length ? (
    <div>
      <ReportDatePicker
        key={'date-picker'}
        title={'Select Report:'}
        handler={dateHandler}
        datesToInclude={datesStrings}
        selectedDate={dayjs(findRecentDate(datesStrings, activeDate))}
      />
    </div>
  ) : (
    <Typography
      style={{ fontSize: '1.4rem', color: mainColors.mainBlue }}
      variant="h5"
    >
      No reports available
    </Typography>
  );
};

export default GeneralReportDatePicker;
