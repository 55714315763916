import axios, { AxiosError, AxiosResponse } from 'axios';
import client, { BASE_URL } from '../../../../utilities/requestClient';
import RaptorLoading from '../../../feedback/RaptorLoading';
import NoDataMessage from '../../../feedback/NoDataMessage.component';
import { Button, Grid } from '@mui/material';
import { FundInfoComponentProps } from '../../../general/GeneralFundInfoPage';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import {
  buildHedgeDerivativeColumns,
  buildPotentialHedgePositionData,
  portalUpdateColumns,
  PortalUpdatePosition,
  updateColumnTitles,
} from './utils/buildFns';
import UltraTable, { TableData } from '../../../tables/ultraTable/UltraTable';
import {
  ColumnData,
  ColumnFields,
} from '../../../tables/ultraTable/types/column.types';
import { toast } from '../../../toast/Toast';
import { queryClient } from '../../../../App';
import { UltraTablePreset } from '../../../tables/ultraTable/types/presets.types';
import { useEffect, useState } from 'react';
import useConfirmation from '../../../../hooks/useConfirmation';
import { useHistory } from 'react-router-dom';
import { Location, Action } from 'history';
import RouteLeavingGuard from '../../../ui/RouteLeavingGuard';
import {
  AuthorisedFundConfigType,
  DefaultColumn,
} from './PositionsUpdatePortal.types';
import { formatDateForCheckingState } from '../../../../utilities/dateFormatters';
import PortalWarning from './components/PortalWarning';
import {
  getPositionUpdateFundURL,
  loadPositionUpdateConfigsURL,
} from '../../../../routes/endpoints/manco.routes';
import { PortalFetchWrapper } from './PortalFetchWrapper';

export async function positionLoadConfigs(): Promise<
  AxiosResponse<AuthorisedFundConfigType>
> {
  const requestClient = client();
  return requestClient.get(loadPositionUpdateConfigsURL());
}

async function fetchEditablePositionData(
  fundName: string,
): Promise<AxiosResponse<any> | AxiosError> {
  return await axios.get(BASE_URL + getPositionUpdateFundURL(fundName), {
    withCredentials: true,
  });
}

async function loadConfigsAndFetchData(fundName: string): Promise<{
  dataResponse: AxiosResponse<any> | null;
  error: AxiosError | null;
}> {
  try {
    const dataResponse = await fetchEditablePositionData(fundName);
    if (axios.isAxiosError(dataResponse)) {
      return {
        dataResponse: null,
        error: dataResponse,
      };
    }

    const obj = {
      dataResponse: dataResponse,
      error: null,
    };
    return obj;
  } catch (error) {
    return {
      dataResponse: null,
      error: error as AxiosError,
    };
  }
}

interface ExtendedFundInfoComponentProps extends FundInfoComponentProps {
  authConfig?: AuthorisedFundConfigType; // This is optional as I dont know how to tell typescript that the data will be there as it is handled in the FetchPortalWrapper
}

const PositionUpdatePortalComponent: React.FC<
  ExtendedFundInfoComponentProps
> = (props) => {
  const { handleConfirmation } = useConfirmation();
  const history = useHistory();

  const [updateAttempted, setUpdateAttempted] = useState(false);
  const [isDataEqual, setIsDataEqual] = useState(true);

  const handleDataComparison = (isEqual: boolean) => {
    setIsDataEqual(isEqual);
  };

  const fundId = props?.fundId;

  const { data, isPending, error } = useQuery({
    queryKey: ['loadConfigsAndFetchData', fundId],
    queryFn: async () => {
      return await loadConfigsAndFetchData(fundId);
    },
    enabled: !!fundId && !!props.authConfig,
  }) as UseQueryResult<
    {
      dataResponse: AxiosResponse<any> | null;
      error: AxiosError | null;
    },
    AxiosError
  >;

  const positionDate = data?.dataResponse?.data.returned_position_date;

  // Handles the beforeunload event to prompt the user if they want to leave the page with unsaved changes
  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      // We are not handling on updateAttempted as we cannot change the browser message and as such dont want to confuse the user
      if (!isDataEqual) {
        event.preventDefault();
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isDataEqual]);

  if (isPending) {
    return (
      <RaptorLoading
        centerWrap
        messages={[
          'Loading Position Update Portal...',
          'This may take a few seconds...',
        ]}
      />
    );
  }

  if (!data) {
    return <NoDataMessage message={'No Data Available'} />;
  }

  const { dataResponse } = data;

  if (!dataResponse?.data) {
    return <NoDataMessage message={'No Data Available'} />;
  }

  const { authorised_keys, default_columns } = props.authConfig!; // I know this will be seeded as errors are handled in the wrapper

  const responseData = dataResponse.data;

  // This can also be fetched from the auth_keys endpoint - this can be used if ! authorised_keys. Auth keys will render all the auth keys as presets
  const editableFields = responseData.positions.reduce(
    (max: any[], item: any) => {
      return item.editable_fields && item.editable_fields.length > max.length
        ? item.editable_fields
        : max;
    },
    [],
  );

  const result = isAuthorisedEditFundsNotEmpty(authorised_keys);

  const mutatedColumns = MutateColumnsToIncludeEditableFields(
    portalUpdateColumns,
    authorised_keys,
  );

  const originalData = {
    data: responseData,
    columns: result ? mutatedColumns : portalUpdateColumns,
  };

  const mutatedData = buildPotentialHedgePositionData(originalData.data);

  const handleUpdate = async (
    originalRow: any,
    columnId: any,
    valueToUpdate: any,
  ) => {
    if (!fundId) {
      toast({
        title: 'Error updating field',
        message: `No Fund Id or Position Date`,
        type: 'error',
      });
      return;
    }

    return await updateChosenCellData(
      fundId,
      positionDate,
      originalRow,
      columnId,
      valueToUpdate,
      setUpdateAttempted, /// temp solution
    );
  };

  const handleRecalculation = async () => {
    if (!fundId) {
      toast({
        title: 'Error finding Fund',
        message: `No Fund Id available`,
        type: 'error',
      });
      return;
    }

    try {
      await axios.get(`${BASE_URL}request_recalculation/${fundId}`, {
        withCredentials: true,
      });

      toast({
        title: 'Recalculation Requested',
        message: `Recalculation requested for ${fundId}, please be patient`,
        type: 'success',
      });
    } catch (error) {
      toast({
        title: 'Error requesting recalculation',
        message: `Error requesting recalculation: ${error}`,
        type: 'error',
      });
    }

    setUpdateAttempted(false);
  };

  const columnTitlesInfo: DefaultColumn[] = default_columns.map((col) => {
    return {
      column_name: col.column_name,
      column_key: col.column_key,
    };
  });

  const authorisedKeysInfo: DefaultColumn[] = authorised_keys.map((key) => {
    return {
      column_name: key.column_name,
      column_key: key.key_name,
    };
  });

  const combinedColumnTitlesInfo: DefaultColumn[] = [
    ...columnTitlesInfo,
    ...authorisedKeysInfo,
  ];

  // Remove duplicates based on column_key
  const uniqueColumnTitlesInfo: DefaultColumn[] = Array.from(
    new Map(
      combinedColumnTitlesInfo.map((item) => [item.column_key, item]),
    ).values(),
  );

  const columns = buildHedgeDerivativeColumns(
    updateColumnTitles(uniqueColumnTitlesInfo, originalData.columns),
    handleUpdate,
  );

  const tableData: TableData<PortalUpdatePosition> = {
    data: mutatedData,
    columns,
  };

  const presets = createHedgeFundPresets(
    authorised_keys,
    default_columns,
    // hedgeDerivativesColumns,
  ); // The auth keys are the presets in this instance

  const onConfirmation = () => {
    handleConfirmation(
      'Recalculation Request',
      <>
        <strong>
          Are you sure you want to alert Risksystem to recalculate the fund?
        </strong>
        <p>
          Please make sure you have submitted all changes before requesting a
          recalculation.
        </p>
      </>,
      () => {
        handleRecalculation();
      },
    );
  };

  const RequestRecalculationBtn = () => {
    return (
      <Button
        onClick={onConfirmation}
        disabled={!updateAttempted}
        variant="contained"
      >
        Request Recalculation
      </Button>
    );
  };

  // Modified onConfirmation function to return a string message
  // TODO: Figure handling the useConfirmation hook with  React Router Prompt - stable for v5
  const onPromptConfirmation = (location: Location, action: Action) => {
    return 'Are you sure you want to leave this page? Unsaved changes will be lost?';
  };

  const defaultHedgePositionPreset: UltraTablePreset = {
    id: 'default',
    name: 'default',
    columns: [
      ColumnFields.INDEX,

      ...default_columns.map((col) => col.column_key),
    ],
  };

  // This is for always having non filterable columns
  // TODO: a better solution will be setting the ColumnDef to not filterable in tanstack
  const exclusions = [
    'index',
    ...default_columns.map((col) => col?.column_key),
  ];

  return (
    <Grid container spacing={2} style={{ padding: 8 }}>
      <RouteLeavingGuard
        when={!isDataEqual || updateAttempted}
        navigate={(path) => {
          history.push(path);
        }}
        message={
          updateAttempted
            ? 'All local changes have been submitted, but the values will only affect calculations in the next run of the fund. If you would like them to be used in the calculation of the currently selected fund, please click the "request calculation" button. Otherwise, you can navigate away.'
            : ''
        }
        shouldBlockNavigation={(location) => {
          if (!isDataEqual || updateAttempted) {
            return true;
          }
          return false;
        }}
      />

      <PortalWarning />

      <UltraTable<PortalUpdatePosition>
        title={`Position Update Portal - ${positionDate}`}
        tableData={tableData}
        getRowCanExpand={() => true}
        exclusions={exclusions}
        columnSelector={{ select: true, group: false }} // hacky solution for grouping on manco
        actionBtns={[<RequestRecalculationBtn />]}
        {...(presets &&
          presets.length > 0 && {
            presets: {
              default: presets[0],
              allPresets: presets,
            },
          })}
        isEditable
        onDataComparison={handleDataComparison}
        positionDate={positionDate}
        pdfConfig={{
          pdfIdentifier: `${fundId}_position_portal`,
          pdfTitle: `Portal Data - ${fundId}`,
          pdfExportGroupName: 'portal_page',
          pdfExportGroupOrder: 5,
          pdfFileName: `exposure-${fundId}-${
            positionDate || formatDateForCheckingState(new Date())
          }`,
        }}
      />
    </Grid>
  );
};

const PositionUpdatePortal: React.FC<FundInfoComponentProps> = (props) => {
  return (
    <PortalFetchWrapper
      {...props}
      component={<PositionUpdatePortalComponent {...props} />}
    />
  );
};

export default PositionUpdatePortal;

type PositionUpdateData = {
  fund_name: string;
  selected_position_date: string;
  position_id: string;
  targeted_position_key: string;
  targeted_position_value: any;
};

async function updateChosenCellData(
  fundId: string,
  positionDate: string,
  originalRow: any,
  columnId: string,
  valueToUpdate: any,
  setUpdateAttempted: any,
) {
  // Data in this
  const data: PositionUpdateData = {
    fund_name: fundId, // Currently this is the fund_name - but should be the fund_id
    selected_position_date: positionDate,
    position_id: originalRow.position_id,
    targeted_position_key: columnId,
    targeted_position_value: valueToUpdate,
  };

  // We must post form data to the backend as it expects it in this form not JSON body
  const formData = new FormData();

  for (const key in data) {
    if (Object.prototype.hasOwnProperty.call(data, key)) {
      formData.append(
        key,
        data[key as keyof PositionUpdateData] as string | Blob,
      );
    }
  }

  const response = await axios.post(
    BASE_URL + 'position_update-update_positions',
    formData,
    {
      withCredentials: true,
    },
  );
  // This is not ideal due to batch updates happening in a loop. Ideally BE can handle multiple updates at once
  queryClient.invalidateQueries({
    queryKey: ['loadConfigsAndFetchData', fundId],
  });

  setUpdateAttempted(true);
  return response;
}

// Create data for the UltraTable
export function MutateDataToIncludeEditableFields(
  data: any,
  authorised_funds: any,
  authorised_keys: any,
) {
  // Filter data to only include entries whose keys are in the authorised_funds array
  // This code must be relayed to BE on how to better structure the responses to avoid massive filtering on FE

  // Here we are filtering the data to only include the funds that are authorised
  const authorisedEditFunds = Object.keys(data)
    .filter((key) => {
      return authorised_funds.includes(key);
    })
    .reduce((obj: any, key: any) => {
      obj[key] = data[key];
      return obj;
    }, {});

  // Here we are mapping the authorised funds to include the editable fields by adding it as a property to the positions
  Object.keys(authorisedEditFunds).filter((key) => {
    const fundData = authorisedEditFunds[key];

    const mutatedFundData = fundData.map((position: any) => {
      return {
        ...position,
        editable_fields: authorised_keys,
      };
    });

    // THIS IS A MUTATION OF THE ORIGINAL DATA -- NOT IDEAL SOLUTION BUT HACK IN FOR NOW
    data[key] = mutatedFundData;
  });

  return data;
}

// This function adds the editable fields to the data
export function MutateColumnsToIncludeEditableFields(
  columns: ColumnData[],
  authorised_keys: any,
) {
  return columns.map((column) => {
    authorised_keys.forEach((auth: any) => {
      if (auth.key_name === column.id) {
        column = {
          ...column,
          editable: auth,
        };
      }
    });
    return column;
  });
}

function createHedgeFundPresets(
  data: any[],
  defaultColumns: any[],
): UltraTablePreset[] {
  const map = new Map<string, string[]>();

  data.forEach((item) => {
    if (!map.has(item.raptor_tab_title)) {
      map.set(item.raptor_tab_title, []);
    }
    map.get(item.raptor_tab_title)?.push(item.key_name);
  });

  const presets: UltraTablePreset[] = [];
  map.forEach((columns, title) => {
    const combinedColumns = [
      ColumnFields.INDEX,
      ...defaultColumns.map((col) => col.column_key),
      ...columns,
    ];

    const uniqueColumns = Array.from(new Set(combinedColumns));

    presets.push({
      id: title,
      name: title,
      columns: uniqueColumns,
    });
  });

  return presets;
}

const isAuthorisedEditFundsNotEmpty = (authorisedEditFunds: any) => {
  return Object.keys(authorisedEditFunds).length > 0;
};
