import makeStyles from '@mui/styles/makeStyles';
import { FC, useState } from 'react';
import { RaptorTheme, greys, mainColors } from '../../../../../styling/theme';
import {
  percentageToTwoDecimalPlacesNoHundred,
  roundNumberToTwoDecimalPlaces,
} from '../../../../../utilities/numberFormatters';
import GenericMapChart from '../../../../charts/map/GenericMapChart';
import GridItem from '../../../../layout/GridComponents/GridItem';
import GeneralSelect from '../../../../selects/GeneralSelect';
import { Typography } from '@mui/material';

const useStyles = makeStyles<RaptorTheme>((theme) => ({
  visualisationsContainer: {
    display: 'flex',
    position: 'relative',
    // top: '4rem',
    // height: 500,
    width: '100%',
  },

  genericBarChartContainer: {
    position: 'relative',
    backgroundColor: 'white',
    width: '100%',
    borderRadius: 8,
    boxShadow: theme.shadows[5],
    marginRight: '1.2rem',
    paddingTop: '1.2rem',
    paddingLeft: '1.2rem',
  },
  header: {
    position: 'relative',
    // top: '3rem',
  },
  tableContainer: {
    position: 'relative',
    backgroundColor: 'white',
    width: '100%',
    borderRadius: 8,
    boxShadow: theme.shadows[5],
    marginRight: '1.2rem',
  },
  generalSelectContainer: {
    position: 'relative',
    // height: 500,
    width: '15%',
  },
  pickerRoot: {
    backgroundColor: 'white',
    borderRadius: 8,
    boxShadow: theme.shadows[5],
    marginRight: '1.2rem',
    height: '5rem',
    width: '100%',
    marginBottom: '1.2rem',
  },
  selectRoot: {
    width: '100%',
  },
  outerDiv: {
    textAlign: 'center',
    // height: "40rem",
    width: '100%',
    // maxWidth: '60rem',
    height: '100%',
  },
}));

interface geographicalExposureData {
  country: string;
  Environmental: number;
  Social: number;
  'Corporate Governance': number;
  ESG: number;
}

function prepareGeographicalExposureData(
  data: any,
  sortBy: string,
): Array<geographicalExposureData> {
  if (!data.data.length) return [];
  if ('no data' in data.data[0]) return [];
  const countryExposureData: geographicalExposureData[] = [];
  const countryData = data.data[0].geographical_scores;
  // Get the ESG Version Number.
  const version = data.data[0].version;
  // case for version 2.
  if (version === 3) {
    // cse for version 3
    Object.keys(countryData).forEach((country: any) => {
      countryExposureData.push({
        country: country,
        Environmental: countryData[country].E_Score,
        Social: countryData[country].S_Score,
        'Corporate Governance': countryData[country].G_Score,
        ESG: countryData[country].ESG_Score,
      });
    });
  } else {
    countryData.forEach((value: any) => {
      countryExposureData.push({
        country: value.country_code,
        Environmental: value.scores.gross.e,
        Social: value.scores.gross.s,
        'Corporate Governance': value.scores.gross.g,
        ESG: value.scores.gross.esg,
      });
    });
  }
  countryExposureData.sort((a: any, b: any) =>
    a[sortBy] < b[sortBy] ? 1 : -1,
  );
  return countryExposureData;
}

function prepareGeographicalDataForMap(data: any, scoreType: string) {
  if (!data.data.length) return [];
  if ('no data' in data.data[0]) return [];
  const geographicalData: any[] = [];
  let scoreKey = 'esg';
  // Get the ESG Version Number.
  const version = data.data[0].version;
  // Case for version 2.
  if (version === 3) {
    // Case for Version 3
    if (scoreType === 'Environmental') scoreKey = 'E_Score';
    else if (scoreType === 'Social') scoreKey = 'S_Score';
    else if (scoreType === 'Corporate Governance') scoreKey = 'G_Score';
    else scoreKey = 'ESG_Score';
    const countryData = data.data[0].geographical_scores;

    Object.keys(countryData).forEach((country: any) => {
      geographicalData.push([
        country,
        roundNumberToTwoDecimalPlaces(countryData[country][scoreKey]),
      ]);
    });
  } else {
    if (scoreType === 'Environmental') scoreKey = 'e';
    else if (scoreType === 'Social') scoreKey = 's';
    else if (scoreType === 'Corporate Governance') scoreKey = 'g';
    const countryData = data.data[0].geographical_scores;

    countryData.forEach((value: any) => {
      geographicalData.push([
        value.country_code,
        roundNumberToTwoDecimalPlaces(value.scores.gross[scoreKey]),
      ]);
    });
  }
  return geographicalData;
}

interface GeographicalExposureProps {
  esgData: any;
}

interface GeographicalExposureChartProps {
  scoreType: string;
  data: any;
  title: string;
}

function createBars(selection: string) {
  switch (selection) {
    case 'Environmental':
      return [
        { dataKey: 'Environmental', key: 'e', fill: mainColors.Pass_darker },
      ];
    case 'Social':
      return [{ dataKey: 'Social', key: 's', fill: mainColors.pumpkin }];
    case 'Corporate Governance':
      return [
        {
          dataKey: 'Corporate Governance',
          key: 'g',
          fill: mainColors.fourthPurple,
        },
      ];
    default:
      return [{ dataKey: 'ESG', key: 'ESG', fill: mainColors.mainBlue }];
  }
}

function getMapColor(selection: string) {
  switch (selection) {
    case 'Environmental':
      return mainColors.Pass_darker;
    case 'Social':
      return mainColors.pumpkin;
    case 'Corporate Governance':
      return mainColors.fourthPurple;
    default:
      return mainColors.mainBlue;
  }
}

const EsgGeographicalExposureChart: FC<GeographicalExposureChartProps> = (
  props,
) => {
  const { scoreType, data, title } = props;
  const geographicalData = prepareGeographicalExposureData(data, scoreType);
  const mapData = prepareGeographicalDataForMap(data, scoreType);
  const mapColor = getMapColor(scoreType);
  const classes = useStyles();

  // Set the x axis props
  const xAxis = [
    {
      dataKey: 'country',
      label: { value: 'Country', dy: 10 },
    },
  ];
  const yAxis = { value: '%' };
  // Set the bars and colors
  const bars = createBars(scoreType);

  const legendFormatter = (value: string) => {
    switch (value) {
      case 'environmental':
        return 'Environmental';
      case 'social':
        return 'Social';
      case 'corporateGovernance':
        return 'Corporate Governance';
      default:
        return value;
    }
  };
  const tooltipFormatter = (value: number) => {
    return percentageToTwoDecimalPlacesNoHundred(value);
  };
  const valueFormatter = (val: string | number) => `${val}`;
  const labelFormatter = (val: string | number) => `${val}`;
  return (
    <GenericMapChart
      data={mapData}
      title={title}
      tooltipValueFormatter={valueFormatter}
      tooltipLabelFormatter={labelFormatter}
      color={mapColor}
    />
  );
};

const GeographicalExposurePanel: FC<GeographicalExposureProps> = (props) => {
  const classes = useStyles();
  const { esgData } = props;
  const [scoreType, setScoreType] = useState('ESG');
  // Set up the select for chosing net or gross exposure
  const setSelected = (value: any) => {
    setScoreType(value);
  };
  return (
    <GridItem
      card
      lg={12}
      // height="100%"
      cardStyle={{
        padding: '1rem',
        height: '60%',
        overflow: 'hidden',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        backgroundColor: 'white',
        minHeight: '30rem',
      }}
    >
      <div>
        <div className={classes.visualisationsContainer}>
          <Typography
            variant="h2"
            style={{
              fontSize: '2.4rem',
              width: '100%',
              fontWeight: 500,
              textAlign: 'left',
            }}
            className={classes.header}
          >
            {'ESG Geographical Exposure'}
          </Typography>
          <div className={classes.generalSelectContainer}>
            <GeneralSelect
              labels={[
                'ESG',
                'Environmental',
                'Social',
                'Corporate Governance',
              ]}
              values={[
                'ESG',
                'Environmental',
                'Social',
                'Corporate Governance',
              ]}
              selected={scoreType}
              setSelected={setSelected}
              placeholderValue=""
              placeholderColor={greys.grey900}
              selectTitle={'Score Type'}
              customStyles={{
                pickerRoot: classes.pickerRoot,
                selectRoot: classes.selectRoot,
              }}
            />
          </div>
        </div>
        <div style={{ height: '50%' }}>
          <EsgGeographicalExposureChart
            data={esgData}
            scoreType={scoreType}
            title={`${scoreType} Score by Country`}
          />
        </div>
      </div>
    </GridItem>
  );
};

export default GeographicalExposurePanel;
