// This is a list of all possible General routes. These functions return the URLs required for fetching data.

import useFetchData from '../../hooks/useFetchData';
import { Section } from '../../types/server-data/ClientConfig';

/**
 * Returns the URL for fetching dashboard data.
 * This URL is used by MancoDashboard and Dashboard components.
 *
 * returns {string} The URL for fetching dashboard data.
 *
 * Used by MancoDashboard and Dashboard
 */
export function getDashboardDataURL() {
  return 'dashboard_data';
}

/**
 * Constructs the URL for fetching funds based on the provided fund types and resources.
 *
 * param {string} [fundTypes] - A comma-separated string of fund types.
 * param {string} [rsResources] - A comma-separated string of resource identifiers.
 * returns {string} The constructed URL for fetching funds.
 *
 * Used by useAppendOverviewFunds
 */

export const getFundsRoute = (fundTypes?: string, rsResources?: string) => {
  const opening = fundTypes?.length || rsResources?.length ? '?' : '';
  const schemas = fundTypes?.length ? `schemas=${fundTypes}` : '';
  const middle = fundTypes?.length && rsResources?.length ? '&' : '';
  const resource = rsResources?.length ? `rs_resources=${rsResources}` : '';
  return `get_funds` + opening + schemas + middle + resource;
};

/**
 * Constructs the URL for fetching position dates based on the provided section details.
 * This function uses the `useFetchData` hook to fetch data from the constructed URL.
 *
 *  {Section | null} sectionDetails - The details of the section, including fund types and resource identifiers.
 *  {ReturnType<typeof useFetchData>} The result of the `useFetchData` hook, which includes the fetched data and related metadata.
 *
 * Used by Functionality and CombinedReports TitleBar
 */

export const getPositionsDates = (sectionDetails: Section | null) => {
  const opening =
    sectionDetails?.fundTypes.length || sectionDetails?.rsResources.length
      ? '?'
      : '';
  const schemas = sectionDetails?.fundTypes.length
    ? `schemas=${sectionDetails?.fundTypes}`
    : '';
  const middle =
    sectionDetails?.fundTypes.length && sectionDetails?.rsResources.length
      ? '&'
      : '';
  const resource = sectionDetails?.rsResources.length
    ? `rs_resources=${sectionDetails?.rsResources}`
    : '';

  return useFetchData({
    keyName: `get_position_dates_${sectionDetails?.fundTypes}_${sectionDetails?.rsResources}}`,
    url: `/get_position_dates` + opening + schemas + middle + resource,
    makeFetch: sectionDetails ? true : false,
  });
};
