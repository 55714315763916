import './loader.css';
import DisplayAreaCenteredWrapper from '../layout/utilities/displayAreaWrapper';
import { useWindowSize } from '../../hooks/useWindowSize';
import RaptorLoadingIcon from './RaptorLoadingIcon';
import useFeedbackMessages from '../../hooks/useFeedbackMessages';
import { Typography, useTheme } from '@mui/material';
interface Props {
  centerWrap?: boolean;
  setWidth?: number | string;
  setHeight?: number | string;
  messages?: string[];
  noMargin?: boolean;
  noMinHeightOnError?: boolean;
}

function RaptorLoading(props: Props) {
  const { width } = useWindowSize();
  const theme = useTheme();
  const messages = props.messages;
  const [message] = useFeedbackMessages({
    messages: messages || [],
    timeDelay: 5000,
  });

  const heightAndWidth = props.setWidth
    ? props.setWidth
    : width >= 1920
      ? '15rem'
      : '10rem';
  const loader = (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <div
        style={{
          height: props.noMinHeightOnError ? '7rem' : heightAndWidth,
          width: props.noMinHeightOnError ? '7rem' : heightAndWidth,
          margin: props.noMargin ? 0 : '2rem',
        }}
      >
        <RaptorLoadingIcon />
      </div>
      {message && (
        <Typography variant="body1" style={{ color: theme.palette.grey[800] }}>
          {message}
        </Typography>
      )}
    </div>
  );

  return props.centerWrap ? (
    <DisplayAreaCenteredWrapper
      noMinHeight={props.noMinHeightOnError}
      setWidth={props.setWidth}
      setHeight={props.setHeight}
    >
      {loader}
    </DisplayAreaCenteredWrapper>
  ) : (
    loader
  );
}

export default RaptorLoading;
