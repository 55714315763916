import { CustomColumn } from '../../../../../types/components/tables/tableTypes';
import { DataObject } from '../../../../../types/redux/data/dataTypes';
import { categoryProviderByNumber } from '../../../../../utilities/categoryProvider';
import {
  addCommasToNumbersAndRound,
  percentageToTwoDecimalPlacesNoHundred,
  roundNumberToNDecimalPlaces,
  roundNumberToTwoDecimalPlaces,
} from '../../../../../utilities/numberFormatters';

export interface CssfDetailDataDefault {
  number: number;
  genericMarket: string;
  isin: string;
  info: string;
  riskFactor: string;
  position: number;
  market: number;
  risk: number;
  exposure: number;
  countryCode: string;
  currency: string;
  delta: number;
  fxRate: number;
}

export const generateCssfDetailColumns =
  (): CustomColumn<CssfDetailDataDefault>[] => [
    {
      field: 'number',
      title: '#',
      width: '1%',
    },
    {
      field: 'genericMarket',
      title: 'Generic Market',
      headerStyle: {
        textAlign: 'left',
      },
      cellStyle: {
        textAlign: 'left',
      },
    },
    {
      field: 'isin',
      title: 'ISIN',
      headerStyle: {
        textAlign: 'center',
      },
      cellStyle: {
        textAlign: 'center',
      },
    },
    {
      field: 'info',
      title: 'Info',
      headerStyle: {
        textAlign: 'center',
      },
      cellStyle: {
        textAlign: 'center',
      },
    },
    {
      field: 'riskFactor',
      title: 'Risk Factor',
      headerStyle: {
        textAlign: 'center',
      },
      cellStyle: {
        textAlign: 'center',
      },
    },
    {
      field: 'delta',
      title: 'Delta',
      headerStyle: {
        textAlign: 'center',
      },
      cellStyle: {
        textAlign: 'center',
      },
      render: (rowData: any) => roundNumberToNDecimalPlaces(rowData.delta, 4),
    },
    {
      field: 'fxRate',
      title: 'FX Rate',
      headerStyle: {
        textAlign: 'center',
      },
      cellStyle: {
        textAlign: 'center',
      },
      render: (rowData: any) => roundNumberToNDecimalPlaces(rowData.fxRate, 4),
    },
    // {
    //   field: 'maturityDate',
    //   title: 'Maturity Date',
    //   headerStyle: {
    //     textAlign: 'center',
    //   },
    //   cellStyle: {
    //     textAlign: 'center',
    //   },
    // },
    {
      field: 'position',
      title: 'Position',
      headerStyle: {
        textAlign: 'center',
      },
      cellStyle: {
        textAlign: 'right',
      },
      width: '10rem',
      render: (rowData) => addCommasToNumbersAndRound(rowData.position),
    },
    {
      field: 'market',
      title: 'Market',
      headerStyle: {
        textAlign: 'center',
      },
      cellStyle: {
        textAlign: 'right',
      },
      render: (rowData) => roundNumberToTwoDecimalPlaces(rowData.market),
    },
    {
      field: 'risk',
      title: 'Risk',
      headerStyle: {
        textAlign: 'center',
      },
      cellStyle: {
        textAlign: 'right',
      },
      render: (rowData) => addCommasToNumbersAndRound(rowData.risk),
    },
    {
      field: 'exposure',
      title: 'Exposure %',
      headerStyle: {
        textAlign: 'center',
      },
      cellStyle: {
        textAlign: 'center',
      },
      render: (rowData) =>
        percentageToTwoDecimalPlacesNoHundred(rowData.exposure),
    },
    {
      field: 'countryCode',
      title: 'Country Code',
      headerStyle: {
        textAlign: 'center',
      },
      cellStyle: {
        textAlign: 'center',
      },
    },
    {
      field: 'currency',
      title: 'Currency',
      headerStyle: {
        textAlign: 'center',
      },
      cellStyle: {
        textAlign: 'center',
      },
    },
  ];

export const generateCssfDetailData = (
  inputData: DataObject,
  client: string,
) => {
  try {
    if (!inputData || inputData.isFetching || !inputData.data) {
      return [];
    }
    if (inputData.error) {
      throw inputData.error;
    }
    const dataForUse = [...inputData.data[0]];
    return dataForUse.map((datum: any, index: number) => {
      return {
        number: index + 1,
        genericMarket: datum.gm_name,
        isin: datum.isin,
        info: categoryProviderByNumber(
          datum.gm_id,
          datum.instrument_id,
          client.includes('adepa'),
        )[1], // where does this come from?
        riskFactor: datum.risk_factor, // where does this come from?
        delta: datum.delta,
        fxRate: datum.fx,
        position: datum.mr_position,
        market: datum.market_price,
        risk: datum.risk_metric_value,
        exposure: datum.exposure,
        countryCode: datum.country_code,
        currency: datum.base_currency,
      };
    });
  } catch (err) {
    return err;
  }
};

// ========= COUNTERPARTY ================

export interface CounterpartyDetailData {
  counterparty: string;
  value: number;
  limit: number;
}

export const generateCounterpartyDetailColumns =
  (): CustomColumn<CounterpartyDetailData>[] => [
    {
      field: 'counterparty',
      title: 'Counterparty',
      cellStyle: {
        textAlign: 'left',
      },
      headerStyle: {
        textAlign: 'left',
      },
    },
    {
      field: 'value',
      title: 'Value %',
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
      },
      render: (rowData) => percentageToTwoDecimalPlacesNoHundred(rowData.value),
    },
    {
      field: 'limit',
      title: 'Limit',
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
      },
      render: (rowData) => percentageToTwoDecimalPlacesNoHundred(rowData.limit),
    },
  ];
// https://www.adepa.risksystem.com/cssf_risk_spreading_inscope_positions/adepa_aif3098_02/4/

export const generateCounterpartyDetailData = (
  data: DataObject | null,
): CounterpartyDetailData[] => {
  try {
    if (!data || data.isFetching) return [];
    if (data.error) {
      throw data.error;
    }
    // ;
    // /**
    //  * TODO: This is not possible to fix until Peter changes the jsons */
    const dataForUse = [...data.data[0]];
    dataForUse.shift(); // remove header

    return dataForUse.map((el) => ({
      counterparty: el[0],
      value: el[1],
      limit: el[2],
    }));
  } catch (err) {
    console.error(
      'There was a problem generating the counterparty details',
      err,
    );
    throw err;
  }
};
