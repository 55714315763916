import { Options } from 'rs-material-table';
import React, { ReactElement } from 'react';
import { CustomColumn } from '../../../../../types/components/tables/tableTypes';
import { Status } from '../../../../../types/redux/data/dataTypes';
import {
  hexToRGBA,
  mapStatusToColor,
} from '../../../../../utilities/colorUtilities';
import { percentageToTwoDecimalPlaces } from '../../../../../utilities/numberFormatters';
import CustomTable from '../../../../tables/CustomTable';
import { ILawFirstLevelTableData, Limit } from './LawFirstLevelTable';
import { thirdLevelDetail } from './ThirdLevelDetail';

interface Props {
  data: ILawFirstLevelTableData;
  fundDetails: {
    fundId: string;
    fundName: string;
  };
  ruleNumber: number;
  positionDate: string;
}

export interface LawSecondLevelData {
  issuer: string;
  exposure: number;
  ruleNumber: number;
  limit: Limit;
  passOrFail: Status;
  position_g_data: any;
  headerRow: boolean;
  positionDate: string;
}

export const secondaryColumns: CustomColumn<LawSecondLevelData>[] = [
  {
    title: 'Issuer',
    field: 'issuer',
  },
  {
    title: 'Exposure',
    field: 'exposure',
    render: (rawData: LawSecondLevelData) => (
      <span>{percentageToTwoDecimalPlaces(rawData.exposure)}</span>
    ),
    // you still need to add in the correct PDF render type here
    renderMethod: {
      methodName: 'percentageToTwoDecimalPlaces',
      params: ['exposure'],
    },
    headerStyle: {
      textAlign: 'center',
    },
    cellStyle: {
      textAlign: 'center',
    },
    customSort: (data1, data2) => data2.exposure - data1.exposure,
  },
  {
    title: 'Limit',
    field: 'limit',
    // render: (rawData: LawSecondLevelData) => (
    //   <span>{percentageToTwoDecimalPlaces(rawData.limit)}</span>
    // ),
    render: (rowData: LawSecondLevelData) =>
      rowData.limit.type === 'position'
        ? percentageToTwoDecimalPlaces(rowData.limit.position || 0)
        : percentageToTwoDecimalPlaces(rowData.limit.aggregate || 0),
    headerStyle: {
      textAlign: 'center',
    },
    cellStyle: {
      textAlign: 'center',
    },
  },
  {
    title: 'Status',
    field: 'passOrFail',
    headerStyle: {
      textAlign: 'center',
    },
    cellStyle: {
      textAlign: 'center',
    },
  },
];

export const secondLevelOptions: Options = {
  paging: false,
  showTitle: false,
  exportButton: true,
  search: false,
  toolbar: true,
  rowStyle: (rowData: LawSecondLevelData) => ({
    backgroundColor: hexToRGBA(mapStatusToColor(rowData.passOrFail), 0.3),
    fontWeight: rowData.issuer === 'Aggregate Information' ? 'bold' : 'normal',
  }),
};

function checkPositionStatus(exposure: number, limit: number) {
  // Accounts for rule 29 which has a negative limit (exposure values are positive though)
  // const absLimit = Math.abs(limit);
  // Acount for a negative limit
  if (limit < 0) {
    if (exposure <= limit) return 'Fail';
    else if (exposure <= limit * 0.9) return 'Alert';
    else return 'Pass';
  }
  if (exposure >= limit) return 'Fail';
  else if (exposure >= limit * 0.9) return 'Alert';
  else return 'Pass';
}

export function buildAssetContainerData(
  data: any,
  ruleNumber: number,
): LawSecondLevelData[] {
  const { limit, position_g_data, rule_number, positionDate, status } = data;

  const newData = data.assets.map((datum: any) => {
    const [issuer, exposure] = datum;
    const passOrFailValue =
      data.limit.type === 'aggregate'
        ? status
        : checkPositionStatus(exposure, data.limit.position);

    let limitSource;

    if (typeof limit === 'object' && limit !== null) {
      limitSource = limit;
    } else if (limit.constructor === Array) {
      limitSource = limit[0];
    } else if (typeof limit === 'string') {
      // Try to remove any % signs.

      limitSource = parseFloat(limit.replace('%', '')) / 100;
    } else if (datum[3]) {
      limitSource = datum[3][0];
    } else {
      limitSource = limit;
    }
    // }
    return {
      positionDate,
      issuer,
      ruleNumber,
      exposure,
      limit: limitSource,
      passOrFail: passOrFailValue,
      position_g_data,
      headerRow: false,
    };
  });
  if (
    limit.length > 1 &&
    rule_number !== 4 &&
    rule_number !== 12 &&
    rule_number !== 29
  ) {
    const aggRow: any = {};
    aggRow.issuer = 'Aggregate Information';
    // TODO need some type definitions here
    // Add in special case for rule number 5 to tage absolute values
    if (ruleNumber === 5) {
      aggRow.exposure = newData.reduce(
        (prev: any, current: any) =>
          Math.abs(prev) + Math.abs(current.exposure),
        0,
      );
    } else {
      aggRow.exposure = newData.reduce(
        (prev: any, current: any) => prev + current.exposure,
        0,
      );
    }
    let limitSource;
    if (limit.constructor === Array && rule_number !== 5) {
      limitSource = limit[0];
    } else if (rule_number === 5) {
      limitSource = 0.4;
    } else if (rule_number === 7) {
      limitSource = 0.8;
    } else if (typeof limit === 'string') {
      // Try to remove any % signs.
      limitSource = parseFloat(limit.replace('%', '')) / 100;
    } else {
      limitSource = limit;
    }
    aggRow.limit = limitSource;
    aggRow.ruleNumber = ruleNumber;
    aggRow.passOrFail =
      aggRow.exposure > aggRow.limit ? Status.Fail : Status.Pass;
    aggRow.position_g_data = null;
    aggRow.headerRow = true;
    aggRow.positionDate = '';

    if (ruleNumber !== 8) {
      newData.forEach((el: any) => {
        el.passOrFail = aggRow.passOrFail;
      });

      newData.unshift(aggRow);
    }
  }

  return newData;
}

function handlePassOrFail(status: Status) {
  if (status === Status.Pass) {
    return 1;
  } else {
    return 0;
  }
}
function SecondLevelTable({
  data,
  fundDetails,
  ruleNumber,
  positionDate,
}: Props): ReactElement {
  const secondLevelTableData = buildAssetContainerData(data, ruleNumber);
  secondLevelTableData.sort(
    (a, b) => handlePassOrFail(a.passOrFail) - handlePassOrFail(b.passOrFail),
  );
  return (
    <CustomTable<LawSecondLevelData>
      selectedPositionDate={positionDate}
      columns={secondaryColumns.map(
        (col: CustomColumn<LawSecondLevelData>) => ({
          ...col,
          cellStyle: { fontWeight: 'inherit', ...col.cellStyle },
        }),
      )}
      csvFields={Object.keys(secondLevelTableData[0])}
      exportFileName="ucits-law"
      showToolbar={true}
      title={`${fundDetails.fundName} - Rule Number: ${ruleNumber}`}
      id={`${fundDetails.fundId}_rule${ruleNumber}_${positionDate}`}
      data={secondLevelTableData}
      options={secondLevelOptions}
      detailPanel={thirdLevelDetail}
      noteExport={{
        fundId: fundDetails.fundId,
        positionDate,
        subject: 'ucits_ucits-law',
        topic: `rule_${ruleNumber}`,
        shouldCall: false,
      }}
    />
  );
}
export default SecondLevelTable;
