import React from 'react';
import { GeneralOverviewPageRenderedComponentProps } from '../../../../types/components/componentTypes';
import useFetchData from '../../../../hooks/useFetchData';
import GeneralComponentErrorShield from '../../../general/GeneralComponentErrorShield';
import GlobalExposureTable from './GlobalExposureTable';
import GridItem from '../../../layout/GridComponents/GridItem';

const GlobalExposure: React.FC<GeneralOverviewPageRenderedComponentProps> = (
  props,
) => {
  const { positionDate } = props;
  const globalExposureData = useFetchData({
    keyName: `${positionDate || 'today'}_global_exposure`,
    url: `ucits_dashboard_monitor${positionDate ? `/${positionDate}` : ''}`,
    makeFetch: true,
  });

  return (
    <GeneralComponentErrorShield dataObjects={[globalExposureData]}>
      <GridItem xs={12} card>
        <GlobalExposureTable globalExposureData={globalExposureData} />
      </GridItem>
    </GeneralComponentErrorShield>
  );
};

export default GlobalExposure;
