export const TOGGLE_SNACKBAR_LOADING_ACTION = 'TOGGLE_SNACKBAR_LOADING_ACTION';
export type TOGGLE_SNACKBAR_LOADING_ACTION =
  typeof TOGGLE_SNACKBAR_LOADING_ACTION;
export const UPDATE_SNACKBAR_MESSAGE_ACTION = 'UPDATE_SNACKBAR_MESSAGE_ACTION';
export type UPDATE_SNACKBAR_MESSAGE_ACTION =
  typeof UPDATE_SNACKBAR_MESSAGE_ACTION;

export const SnackbarActionType =
  TOGGLE_SNACKBAR_LOADING_ACTION || UPDATE_SNACKBAR_MESSAGE_ACTION;

export type ToggleSnackbarLoadingAction = {
  type: TOGGLE_SNACKBAR_LOADING_ACTION;
  payload: boolean;
};

export type updateSnackbarMessageAction = {
  type: UPDATE_SNACKBAR_MESSAGE_ACTION;
  payload: null | string;
};

// ACTION CREATOR FUNCTIONS
export const toggleSnackbarLoading = (
  shouldSnackbarBeLoading: boolean,
): ToggleSnackbarLoadingAction => {
  return {
    type: TOGGLE_SNACKBAR_LOADING_ACTION,
    payload: shouldSnackbarBeLoading,
  };
};
export const updateSnackbarMessage = (
  snackbarMessage: null | string,
): updateSnackbarMessageAction => {
  return {
    type: UPDATE_SNACKBAR_MESSAGE_ACTION,
    payload: snackbarMessage,
  };
};

export type SnackbarAction =
  | ToggleSnackbarLoadingAction
  | updateSnackbarMessageAction;
