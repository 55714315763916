import { PdfExportStore } from '../../types/redux/pdfExports/pdfExportsStore';
import {
  ADD_COMPONENT_TO_PDF_EXPORT,
  CLEAR_PDF_EXPORT,
  PdfExportsAction,
} from '../../types/redux/pdfExports/exportTypes';

export default function pdfExportReducer(
  state: PdfExportStore = {
    componentsToExport: [],
    loadingPagePdf: false,
  },
  action: PdfExportsAction,
) {
  switch (action.type) {
    case CLEAR_PDF_EXPORT:
      return {
        ...state,
        componentsToExport: [],
      };
    case ADD_COMPONENT_TO_PDF_EXPORT:
      const currentComponents = state.componentsToExport.filter(
        (comp) => comp.identifier !== action.payload.identifier,
      );

      return {
        ...state,
        componentsToExport: [...currentComponents, action.payload],
      };
    default:
      return state;
  }
}
