export interface PortfolioLiquidationData {
  name: string;
  bau: number;
  stressed: number;
  superStressed: number;
}

export interface MaximumOneDayData {
  name: string;
  moabZero: number;
  moabOne: number;
}

export interface LiquidationCost {
  name: string;
  bau: number;
  stressed: number;
}

export interface HistoricalNavData {
  name: string;
  nav: number;
  unitPx: number;
}

type Q1 = [string, number, number, number];
type EvolutionOthers = [string, number, number];
export interface EvolutionServerData {
  q1: Q1[];
  q2: EvolutionOthers[];
  q3: EvolutionOthers[];
  q4: EvolutionOthers[];
}

export function buildEvolutionChart(
  data: EvolutionServerData[],
  key: 'q1' | 'q2' | 'q3' | 'q4',
) {
  if (!data.length) return [];
  const dataForUse = data[0][key];
  dataForUse.shift();

  let returnData: any;

  switch (key) {
    case 'q1':
      returnData = (dataForUse as Q1[]).map((el: Q1) => {
        const [name, bau, stressed, superStressed] = el;
        return {
          name,
          bau,
          stressed,
          superStressed,
        };
      });
      return returnData;
    case 'q2':
      returnData = (dataForUse as EvolutionOthers[]).map(
        (el: EvolutionOthers) => {
          const [name, moabZero, moabOne] = el;
          return {
            name,
            moabZero,
            moabOne,
          };
        },
      );
      return returnData;
    case 'q3':
      returnData = (dataForUse as EvolutionOthers[]).map(
        (el: EvolutionOthers) => {
          const [name, bau, stressed] = el;
          return {
            name,
            bau,
            stressed,
          };
        },
      );
      return returnData;
    case 'q4':
      returnData = (dataForUse as EvolutionOthers[]).map(
        (el: EvolutionOthers) => {
          const [name, nav, unitPx] = el;
          return {
            name,
            nav,
            unitPx,
          };
        },
      );
      return returnData;
    default:
      return [];
  }
}
