import React, { FC } from 'react';
import { AxisInterval } from 'recharts/types/util/types';
import { mainColors } from '../../../../styling/theme';
import { roundNumberToNDecimalPlaces } from '../../../../utilities/numberFormatters';
import GenericBarChart from '../../../charts/GenericBarChart';
import GridItem from '../../../layout/GridComponents/GridItem';

interface MonteCarloHistogramProps {
  data: any;
  chartKey: string;
  position: string | null;
}

function buildMonteCarloTHistogramChartData(
  data: any,
  position: string | null,
  chartKey: string,
): any[] {
  if (!data.data.length) return [];
  if (!position) return [];
  if (!(position in data.data[0])) return [];
  if (!(chartKey in data.data[0][position])) return [];

  const positionData = data.data[0][position][chartKey].histogram;
  const chartData: any[] = [];

  positionData.forEach((value: any) => {
    chartData.push({
      name: roundNumberToNDecimalPlaces(value[0] * 100, 1),
      value: value[1],
    });
  });
  // Add one more value to complete the range.
  chartData.push({
    name: roundNumberToNDecimalPlaces(
      (positionData[positionData.length - 1][0] + 0.2) * 100,
      1,
    ),
    value: 0,
  });

  return chartData;

  // const bars = [
  //     {
  //         dataKey: 'concerningExposureValue',
  //         key:  'value',
  //         fill: mainColors.mainBlue,
  //         children: (
  //             barChartData.map((value, index) => (
  //                 <Cell key={`cell-${index}`} fill={mapStatusToColor(value.qualitativeIndicatorScan as Status)} />
  //             ))
  //         )
  //     }]
}

const MonteCarloHistogramChart: FC<MonteCarloHistogramProps> = (props) => {
  const { data, chartKey, position } = props;
  const barChartData = buildMonteCarloTHistogramChartData(
    data,
    position,
    chartKey,
  );
  // const minValue = getMinYAxisValue(barChartData)
  // let minvalue = lineChartData.length ? lineChartData[0].value : 0;
  // let maxvalue = lineChartData.length ? lineChartData[0].value : 0;
  // lineChartData.forEach((value: any) => {
  //     if (minvalue > value.value) minvalue = value.value
  //     if (maxvalue < value.value) maxvalue = value.value

  const bars = [
    {
      dataKey: 'value',
      key: 'value',
      fill: mainColors.mainBlue,
      // children: (
      //     barChartData.map((value, index) => (
      //         <Cell key={`cell-${index}`} fill={mapStatusToColor(value.qualitativeIndicatorScan as Status)} />
      //     ))
      // )
    },
  ];

  const xAxis = [
    {
      dataKey: 'name',
      label: { value: 'P/L (% of NAV)', dy: 15 },
      interval: 'preserveStartEnd' as AxisInterval,
      tick: { dx: -8 },
      tickLine: false,
      // tickFormatter: (val: number) => roundNumberToNDecimalPlaces(val, 1)
    },
  ];
  return (
    <GridItem xs={4} card cardStyle={{ minHeight: 560 }}>
      <div style={{ marginLeft: '-15%' }}>
        <GenericBarChart
          barCategoryGap={0}
          xAxes={xAxis}
          height={500}
          width={'100%'}
          bars={bars}
          data={barChartData}
          title={
            chartKey === 'pl_dist'
              ? 'Monte Carlo Probability Distribution'
              : 'L-Adjusted Monte Carlo Distribution'
          }
          id={
            chartKey === 'pl_dist'
              ? 'Monte Carlo Probability Distribution'
              : 'L-Adjusted Monte Carlo Distribution'
          }
          loading={false}
          legend={false}
          // yAxisLabel={{
          //     angle: -90,
          //     position: 'left',
          //     value: 'Exposure',
          //     style: {
          //         textAnchor: 'middle',
          //         // fill: theme.palette.grey[500],
          //     },
          // }}
          // yAxisFormatter={(value: string) => percentValueOption === 'percent' ? percentageToTwoDecimalPlaces(value) : formatMillions(value)}
          tooltipFormatter={(value: number, name: string) => [
            // `${value} - ${value + 0.2}`,
            value,
            'Frequency',
          ]}
          legendLayout="horizontal"
          legendPositionHorizontal="center"
          legendPositionVertical="top"
          // legendFormatter={legendFormatter}
          // tooltipFormatter={tooltipFormatter}
        />
      </div>
    </GridItem>
  );
};

export default MonteCarloHistogramChart;
