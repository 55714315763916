import React, { CSSProperties } from 'react';
import { CustomColumn } from '../../../../../../types/components/tables/tableTypes';
import GenericStatusCell from '../../../../../tables/GenericStatusCell';
import { specifyStatus } from '../../../../../../utilities/generalMappings';

export interface IOutSampleTableData {
  risk_params: string;
  value: string | number;
}

export const outSampleColumns: CustomColumn<IOutSampleTableData>[] = [
  {
    headerStyle: {
      fontWeight: 'bold',
    },
    title: 'Risk Parameters',
    field: 'risk_params',
    cellStyle: {
      padding: '.5rem',
      paddingLeft: '1rem',
    },
  },
  {
    title: 'Value',
    field: 'value',
    headerStyle: {
      textAlign: 'center',
      fontWeight: 'bold',
    },
    cellStyle: (
      data: IOutSampleTableData[],
      rowData: IOutSampleTableData,
    ): CSSProperties => {
      if (rowData.risk_params === 'Pass / Fail') {
        return {
          padding: 0,
          textAlign: 'center',
        };
      }
      return {
        textAlign: 'center',
      };
    },
    render: (rowData: IOutSampleTableData) => {
      if (rowData.risk_params === 'Pass / Fail') {
        return (
          <GenericStatusCell
            status={specifyStatus(rowData.value as string)}
            height="5rem"
          />
        );
      } else {
        return rowData.value;
      }
    },
  },
];

export function buildOutSampleStatistics(inputData: any) {
  if (!inputData || !inputData.length) return [];
  const data = inputData[0];
  return [
    {
      risk_params: 'Total days in sample',
      value: data.cis_time_series.length - 1,
      // the -1 is to account for the header row in the json
    },
    {
      risk_params: 'Confidence Interval',
      value: data['confidence interval'] + '%',
    },
    {
      risk_params: 'Holding Period (Days)',
      value: data['holding period'],
    },
    {
      risk_params: 'Holding Period Factor',
      value: data['holding period factor'].toFixed(2),
    },
    {
      risk_params: 'Single / Double Sided',
      value: data.sides,
    },
    {
      risk_params: 'Minimum Exceedances',
      value: 0,
    },
    {
      risk_params: 'Maximmum Exceedances',
      value: 4,
    },
    {
      risk_params: 'Actual Exceedances',
      value: data.fails,
    },
    {
      risk_params: 'Pass / Fail',
      value: data.fails > 4 ? 'Fail' : 'Pass', // if actual exceendances is greater than max exceedances this should fail
    },
  ];
}
