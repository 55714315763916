import React, { FC } from 'react';
import GridItem from '../../../layout/GridComponents/GridItem';
import CustomTable from '../../../tables/CustomTable';
import { CustomColumn } from '../../../../types/components/tables/tableTypes';
import { addCommasToNumbersAndRound } from '../../../../utilities/numberFormatters';

interface DCFOverviewTableProps {
  data: any;
  selectedPosition: string | null;
}

interface DCFOverviewTableData {
  name: string;
  value: string;
}

const columns: CustomColumn<DCFOverviewTableData>[] = [
  {
    title: 'name',
    field: 'name',
    cellStyle: { textAlign: 'center' },
    headerStyle: { textAlign: 'center' },
    // customSort: (rowData: any) => 1,
  },
  {
    title: 'Value',
    field: 'value',
    cellStyle: { textAlign: 'center' },
    headerStyle: { textAlign: 'center' },
  },
];

function buildTableData(data: any, selectedPosition: string | null) {
  if (!data || !data.data || !data.data[0]) return [];
  if (!data.data.length) return [];
  if (!selectedPosition) return [];
  // Get just the data for the selected position.
  const selectedPositionData = data.data[0][selectedPosition];
  // Add the table data.
  if (selectedPosition === 'Portfolio') {
    const tableData: DCFOverviewTableData[] = [
      {
        name: 'NAV',
        value: addCommasToNumbersAndRound(selectedPositionData['NAV']),
      },
      {
        name: 'Currency',
        value: selectedPositionData['Currency'],
      },
      {
        name: 'Portfolio EBIT',
        value: addCommasToNumbersAndRound(
          selectedPositionData['Portfolio EBIT'],
        ),
      },
      {
        name: 'Portfolio EBIT (% of NAV)',
        value: selectedPositionData['Portfolio EBIT (% of NAV)'],
      },
      {
        name: 'Portfolio Enterprise Value',
        value: addCommasToNumbersAndRound(
          selectedPositionData['Portfolio Enterprise Value'],
        ),
      },
      {
        name: 'Portfolio Enterprise Value (% of NAV)',
        value: selectedPositionData['Portfolio Enterprise Value (% of NAV)'],
      },
      {
        name: 'Total Portfolio DCF Value',
        value: addCommasToNumbersAndRound(
          selectedPositionData['Total Portfolio Present Valuation'],
        ),
      },
      {
        name: 'Total Portfolio DCF Value (% of NAV)',
        value:
          selectedPositionData['Total Portfolio Present Valuation (% of NAV)'],
      },
      {
        name: 'Weighted Average Cost of Capital',
        value: selectedPositionData['Weighted Average Cost of Capital'],
      },
    ];
    return tableData;
  } else {
    const tableData: DCFOverviewTableData[] = [
      {
        name: 'Asset',
        value: selectedPositionData['Asset'],
      },
      {
        name: 'Industry',
        value: selectedPositionData['Industry'],
      },
      {
        name: 'Country',
        value: selectedPositionData['Country'],
      },
      {
        name: 'Enterprise Value',
        value: addCommasToNumbersAndRound(
          selectedPositionData['Enterprise Value'],
        ),
      },
      {
        name: 'Currency',
        value: selectedPositionData['Currency'],
      },
      {
        name: 'EBIT',
        value: addCommasToNumbersAndRound(selectedPositionData['EBIT']),
      },
      {
        name: 'Maturity Date',
        value: selectedPositionData['Maturity Date'],
      },
      {
        name: 'DCF Value',
        value: addCommasToNumbersAndRound(
          selectedPositionData['Present Valuation'],
        ),
      },
    ];
    return tableData;
  }
}

const DiscountCashflowOverviewTable: FC<DCFOverviewTableProps> = (props) => {
  // const theme = useTheme();
  const { data, selectedPosition } = props;
  // const tableData = isDefaultScenario ? buildSubscriptionsRedemptionData(data, selectedPosition, percentValueOption, nav) : buildDCFDataForComparison(data, selectedPosition, percentValueOption, nav, defaultData);
  // Allow user to switch between scenrio, change and both
  // const detailColumns = buildDetailColumns(
  //   props.setBarChartFilter,
  //   props.barChartValue,
  //   props.percentValueOption
  // );
  // const pdfColumns = buildDetailColumnsPDF(props.percentValueOption);
  // const nav = getNav(data);
  // const tableData = buildDCFConfigurationData(
  //   data,
  //   selectedPosition,
  //   props.percentValueOption,
  //   nav
  // );
  const tableData = buildTableData(data, selectedPosition);

  return (
    <GridItem
      xs={3}
      card
      style={{
        padding: 8,
        alignSelf: 'stretch',
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        // minHeight: 600
      }}
      cardStyle={{ minHeight: 700 }}
    >
      {/* <Typography variant='h2' style={{ marginLeft: '2rem', marginTop: '1rem', textAlign: 'center' }}>
                  {title}
              </Typography> */}

      {/* <div
          style={{
            float: 'left',
            marginLeft: '2rem',
            textAlign: 'center',
            marginTop: '2rem',
          }}
        > */}
      {/* <PercentValueToggleButton
            percentValueOption={props.percentValueOption}
            updatePercentValueOption={props.setPercentValueOption}
          />
        </div> */}
      <CustomTable<DCFOverviewTableData>
        pdfTitle={selectedPosition || ''}
        id={selectedPosition || ''}
        title={selectedPosition || ''}
        columns={columns}
        showToolbar
        data={tableData}
        // toolbarComponents={{
        //     toolbarTitle: formattedNames[tableType],
        // }}
        options={{
          paging: false,
          search: false,
          exportButton: false,
          sorting: false,
          header: false,
        }}
      />
    </GridItem>
  );
};

export default DiscountCashflowOverviewTable;
