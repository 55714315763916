import { ContentState, Editor, EditorState } from 'draft-js';
import React, { Dispatch, FC } from 'react';
import GridItem from '../../../../../layout/GridComponents/GridItem';
import CustomTable from '../../../../../tables/CustomTable';
import { CustomColumn } from '../../../../../../types/components/tables/tableTypes';
import { stateToHTML } from 'draft-js-export-html';
import { greys, mainColors } from '../../../../../../styling/theme';
import RaptorLogo from '../../../../../../images/Raptor_logo_new.png';
import Icon from '@mui/material/Icon';
import { Button, Typography } from '@mui/material';

interface AnnexInvestmentTableProps {
  delegatedActsElement: string;
  delegatedActsData: any[];
  setDelegatedActsData: Dispatch<any>;
  setHaveChangesBeenMade: Dispatch<any>;
  index: number;
  risksystemData: any;
}

function buildTableData(data: any): InvestmentTableData[] {
  const tableData: InvestmentTableData[] = [];
  data.forEach((value: any, index: number) => {
    tableData.push({
      name: value.name,
      sector: value.sector,
      exposure: value.exposure,
      country: value.country,
      index: index,
    });
  });

  return tableData;
}

interface InvestmentTableData {
  name: string;
  sector: string;
  exposure: string;
  country: string;
  index: number;
}

interface TableCellEditorBoxProps {
  initialText: string;
  onTextChange: (text: string) => void;
}

function TableCellEditorBox({
  initialText,
  onTextChange,
}: TableCellEditorBoxProps): React.ReactElement {
  const [editorState, setEditorState] = React.useState(
    EditorState.createWithContent(ContentState.createFromText(initialText)),
  );

  const handleTextChange = (updatedEditorState: EditorState) => {
    setEditorState(updatedEditorState);
    let text = stateToHTML(updatedEditorState.getCurrentContent());

    text = text
      .replaceAll('<p>', '')
      .replaceAll('</p>', '')
      .replaceAll('<strong>', '<b>')
      .replaceAll('</strong>', '</b>')
      .replaceAll('<em>', '<i>')
      .replaceAll('</em>', '</i>');
    onTextChange(text);
  };

  return (
    <div
      style={{
        border: '0.5px solid',
        minHeight: '2rem',
        backgroundColor: 'white',
        width: '18rem',
      }}
    >
      <Editor
        editorState={editorState}
        onChange={(updatedEditorState: EditorState) => {
          handleTextChange(updatedEditorState);
        }}
        stripPastedStyles={true}
      />
    </div>
  );
}

function buildTableColumns(
  handleUpdateFunction: (
    rowIndex: number,
    columnName: string,
    text: string,
  ) => void,
  dataJson: any,
): CustomColumn<InvestmentTableData>[] {
  const columns: CustomColumn<InvestmentTableData>[] = [
    {
      title: '#',
      field: 'index',
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: { textAlign: 'center' },
      render: (rowData: InvestmentTableData) => rowData.index + 1,
    },
    {
      title: 'Largest Investments',
      field: 'name',
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: { textAlign: 'center' },
      render: (rowData: InvestmentTableData) => (
        <TableCellEditorBox
          initialText={
            dataJson.value.length > rowData.index
              ? dataJson.value[rowData.index].name
              : ''
          }
          onTextChange={(text: string) =>
            handleUpdateFunction(rowData.index, 'name', text)
          }
        />
      ),
    },
    {
      title: 'Sector',
      field: 'sector',
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: { textAlign: 'center' },
      render: (rowData: InvestmentTableData) => (
        <TableCellEditorBox
          initialText={
            dataJson.value.length > rowData.index
              ? dataJson.value[rowData.index].sector
              : ''
          }
          onTextChange={(text: string) =>
            handleUpdateFunction(rowData.index, 'sector', text)
          }
        />
      ),
    },
    {
      title: '% Assets',
      field: 'exposure',
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: { textAlign: 'center' },
      render: (rowData: InvestmentTableData) => (
        <TableCellEditorBox
          initialText={
            dataJson.value.length > rowData.index
              ? dataJson.value[rowData.index].exposure
              : ''
          }
          onTextChange={(text: string) =>
            handleUpdateFunction(rowData.index, 'exposure', text)
          }
        />
      ),
    },
    {
      title: 'Country',
      field: 'country',
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: { textAlign: 'center' },
      render: (rowData: InvestmentTableData) => (
        <TableCellEditorBox
          initialText={
            dataJson.value.length > rowData.index
              ? dataJson.value[rowData.index].country
              : ''
          }
          onTextChange={(text: string) =>
            handleUpdateFunction(rowData.index, 'country', text)
          }
        />
      ),
    },
  ];
  return columns;
}

const AnnexInvestmentTableTile: FC<AnnexInvestmentTableProps> = (props) => {
  const {
    delegatedActsElement,
    delegatedActsData,
    setDelegatedActsData,
    setHaveChangesBeenMade,
    index,
    risksystemData,
  } = props;

  // Convert the data into an object.
  const [dataJson, setDataJson] = React.useState(
    JSON.parse(delegatedActsElement),
  );

  const contentStatePeriod = ContentState.createFromText(dataJson.period);
  const initialStatePeriod = EditorState.createWithContent(contentStatePeriod);
  const [editorStatePeriod, setEditorStatePeriod] =
    React.useState<EditorState>(initialStatePeriod);

  // Create a table data object from the array.
  // const tableData = buildTableData(contentState);
  // Create an object for storing the state of each cell in the table.
  const [tableData, setTableData] = React.useState<InvestmentTableData[]>(
    buildTableData(dataJson.value),
  );

  const handleTextChange = (
    rowIndex: number,
    columnName: string,
    text: string,
  ) => {
    // Now update the element in the overall data
    const allContent = delegatedActsData;
    const dataJsonCopy = dataJson;
    // Update the value at the correct index in the list
    // Check if the text has changes and if so indicate it
    if (dataJsonCopy.value[rowIndex][columnName] !== text)
      setHaveChangesBeenMade(true);
    dataJsonCopy.value[rowIndex][columnName] = text;
    setDataJson(dataJsonCopy);
    // Now replace in the overall array
    allContent[index].content = JSON.stringify(dataJsonCopy);
    // Update the overall data
    setDelegatedActsData(allContent);
  };

  const columns = buildTableColumns(handleTextChange, dataJson);

  const refreshFromRisksystem = () => {
    const data = risksystemData.top_investments;
    // Now refresh the text with the data from risksystem.
    const allContent = delegatedActsData;
    const dataJsonCopy = dataJson;
    // Check if the text has changes and if so indicate it
    if (dataJsonCopy.value !== data) setHaveChangesBeenMade(true);
    dataJsonCopy.value = data;
    setDataJson(dataJsonCopy);
    allContent[index].content = JSON.stringify(dataJsonCopy);

    // Update the overall data
    setDelegatedActsData(allContent);
    // Update the editors in the table
    setTableData(buildTableData(dataJson.value));
  };

  const modifyTableRows = (addRemove: 'add' | 'remove') => {
    // Add another row to the table.
    if (addRemove === 'add') {
      // Get the keys that we need to populate in order to add a row.
      const dataKeys = ['sector', 'country', 'name', 'exposure'];
      // Create a new row to add to the table.
      const newRow: any = {};
      dataKeys.forEach((key: string) => {
        newRow[key] = '';
      });
      // Now Get the overvall data for the document
      const allContent = delegatedActsData;
      // Indicate that some data has been changed
      setHaveChangesBeenMade(true);
      const dataJsonCopy = dataJson;
      const dataValue = JSON.parse(JSON.stringify(dataJsonCopy.value));
      dataValue.push(newRow);
      // Add the new row to the array
      dataJsonCopy.value = dataValue;
      setDataJson(dataJsonCopy);
      // Now update the overall data
      allContent[index].content = JSON.stringify(dataJsonCopy);

      // Update the overall data
      setDelegatedActsData(allContent);
      // Rebuild the table
      setTableData(buildTableData(dataJsonCopy.value));
    } else if (addRemove === 'remove') {
      // Remove a row from the table
      // Now Get the overvall data for the document
      const allContent = delegatedActsData;
      // Indicate that some data has been changed
      setHaveChangesBeenMade(true);
      const dataJsonCopy = dataJson;
      // Remove the last element from the array
      dataJsonCopy.value.pop();
      setDataJson(dataJsonCopy);
      // Now update the overall data
      allContent[index].content = JSON.stringify(dataJsonCopy);

      // Update the overall data
      setDelegatedActsData(allContent);
      // Rebuild the table
      setTableData(buildTableData(dataJsonCopy.value));
    }
  };

  const handlePeriodTextChange = (updatedEditorState: EditorState) => {
    setEditorStatePeriod(updatedEditorState);
    // Get The text from the editor state
    let text = stateToHTML(updatedEditorState.getCurrentContent());

    text = text
      .replaceAll('<p>', '')
      .replaceAll('</p>', '')
      .replaceAll('<strong>', '<b>')
      .replaceAll('</strong>', '</b>')
      .replaceAll('<em>', '<i>')
      .replaceAll('</em>', '</i>');
    // Now update the element in the overall data
    const allContent = delegatedActsData;
    // Check which data point of data needs to be updated dataJson
    const dataJsonCopy = dataJson;
    dataJsonCopy.value.period = text;
    setDataJson(dataJsonCopy);
    // Check if the text has changes and if so indicate it
    if (allContent[index].content !== delegatedActsElement)
      setHaveChangesBeenMade(true);
    // Now replace in the overall array
    allContent[index].content = JSON.stringify(dataJsonCopy);
    // Update the overall data
    setDelegatedActsData(allContent);
  };

  return (
    <GridItem xs={12} card style={{ marginBottom: '1rem' }}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Typography
          variant="h3"
          style={{ fontSize: '1.5rem', color: greys.grey400 }}
        >
          Annex 4
        </Typography>
        <Button
          // className={classes.button}
          variant="contained"
          onClick={refreshFromRisksystem}
          endIcon={
            <img
              style={{ height: '1.5rem', width: '1.5rem' }}
              src={RaptorLogo}
            />
          }
          style={{
            height: '3rem',
            marginTop: '0.5rem',
            marginRight: '1rem',
            marginBottom: '1rem',
            // width: '12rem',
            backgroundColor: mainColors.controlButtonBlue,
            color: 'white',
          }}
        >
          <div style={{ color: 'white' }}>Refresh from RiskSystem</div>
        </Button>
      </div>
      <Typography
        variant="h3"
        style={{ marginLeft: '1rem', marginTop: '1rem' }}
      >
        Period
      </Typography>
      <div
        style={{
          border: '0.5px solid',
          minHeight: '2rem',
          marginTop: '0.5rem',
          marginLeft: '1rem',
          backgroundColor: 'white',
          minWidth: '10rem',
          width: '50%',
        }}
      >
        <Editor
          editorState={editorStatePeriod}
          onChange={handlePeriodTextChange}
          stripPastedStyles={true}
          //   handleKeyCommand={handleKeyCommand}
          //   customStyleMap={styleMap}
          //   readOnly={disabled}
          // blockRendererFn={mediaBlockRenderer}
        />
      </div>
      <CustomTable<InvestmentTableData>
        title="What were the top investments of this financial product?"
        showToolbar={true}
        id={`annex_investment_table_table_${index}`}
        data={tableData}
        options={{
          paging: false,
          search: false,
          exportButton: false,
          exportAllData: false,
          emptyRowsWhenPaging: false,
        }}
        columns={columns}
      />
      <div
        style={{
          marginTop: '1rem',
          marginLeft: '1rem',
          marginBottom: '1rem',
        }}
      >
        <Icon
          sx={{
            fontSize: 25,
            color:
              dataJson.value.length === 1 ? greys.grey500 : mainColors.Fail,
            opacity: 0.6,
            '&:hover': {
              cursor: 'pointer',
              opacity: 1,
            },
          }}
          onClick={
            dataJson.value.length === 1
              ? undefined
              : () => {
                  modifyTableRows('remove');
                }
          }
        >
          remove_circle
        </Icon>
        <Icon
          sx={{
            fontSize: 25,
            color:
              dataJson.value.length >= 16
                ? greys.grey500
                : mainColors.controlButtonBlue,
            opacity: 0.6,
            '&:hover': {
              cursor: 'pointer',
              opacity: 1,
            },
          }}
          onClick={
            dataJson.value.length >= 16
              ? undefined
              : () => {
                  modifyTableRows('add');
                }
          }
        >
          add_circle
        </Icon>
      </div>
    </GridItem>
  );
};

export default AnnexInvestmentTableTile;
