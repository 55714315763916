import dayjs from 'dayjs';

export const DATE_FORMAT = 'YYYY-MM-DD';

// function to get all weekdays between today and the start of 2011 (we will use this as the default dates)
export const getAllRelevantWeekdays = (
  startDate: Date = new Date(2011, 0, 1),
  endDate: Date = new Date(),
) => {
  const weekdays: string[] = [];
  for (let d = endDate; d >= startDate; d.setDate(d.getDate() - 1)) {
    const djs = dayjs(d);
    if (djs.day() !== 0 && djs.day() !== 6) {
      weekdays.push(djs.format(DATE_FORMAT));
    }
  }
  return weekdays;
};

// function to get all dates between today and the start of 2011 (we will use this as the default dates)
export const getAllRelevantDates = () => {
  const dates: string[] = [];
  for (
    let d = new Date();
    d >= new Date(2012, 0, 1);
    d.setDate(d.getDate() - 1)
  ) {
    dates.push(dayjs(d).format(DATE_FORMAT));
  }
  return dates;
};

export function getMostRecentAvailableDate(availableDates: string[]) {
  return availableDates[0];
}

export function getAvailableDateXMonthsAgo(
  X: number,
  availableDates: string[],
): string {
  const XMonthsAgo = dayjs().subtract(X, 'months');
  let nearestDate = '';
  for (let i = 0; i < availableDates.length; i++) {
    const date = dayjs(availableDates[i]);
    nearestDate = availableDates[i];
    if (date.isBefore(XMonthsAgo)) {
      break;
    }
  }
  return nearestDate;
}

export function getMostRecentWeekday() {
  const today = dayjs();

  if (today.day() === 0) {
    return today.subtract(2, 'day').format('YYYY-MM-DD');
  } else if (today.day() === 6) {
    return today.subtract(1, 'day').format('YYYY-MM-DD');
  } else {
    return today.format('YYYY-MM-DD');
  }
}

export function getWeekdayXMonthsAgo(X: number) {
  const XMonthsAgo = dayjs().subtract(X, 'months');

  if (XMonthsAgo.day() === 0) {
    return XMonthsAgo.add(1, 'day').format('YYYY-MM-DD');
  } else if (XMonthsAgo.day() === 6) {
    return XMonthsAgo.add(2, 'day').format('YYYY-MM-DD');
  } else {
    return XMonthsAgo.format('YYYY-MM-DD');
  }
}

export function getWeekdayXDaysAgo(X: number) {
  const XDaysAgo = dayjs().subtract(X, 'days');

  if (XDaysAgo.day() === 0) {
    return XDaysAgo.add(1, 'day').format('YYYY-MM-DD');
  } else if (XDaysAgo.day() === 6) {
    return XDaysAgo.add(2, 'day').format('YYYY-MM-DD');
  } else {
    return XDaysAgo.format('YYYY-MM-DD');
  }
}

export function getWeekdayXDaysSinceGivenDate(date: string, X: number) {
  const XDaysAgo = dayjs(date).subtract(X, 'days');

  if (XDaysAgo.day() === 0) {
    return XDaysAgo.add(1, 'day').format('YYYY-MM-DD');
  } else if (XDaysAgo.day() === 6) {
    return XDaysAgo.add(2, 'day').format('YYYY-MM-DD');
  } else {
    return XDaysAgo.format('YYYY-MM-DD');
  }
}

export const calculateQuarter = (date: string) => {
  const djs = dayjs(date);
  const month = djs.month();

  if (month < 3) {
    return 'Q1';
  } else if (month < 6) {
    return 'Q2';
  } else if (month < 9) {
    return 'Q3';
  } else {
    return 'Q4';
  }
};
