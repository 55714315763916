import React from 'react';
import SelectAFundPage from './selectAFundPage/component';
import { GeneralOverviewPageRenderedComponentProps } from '../../../types/components/componentTypes';
import { createSectionByIdSelector } from '../../../redux/pages/selectors';
import { useSelector } from 'react-redux';
import { Section } from '../../../types/server-data/ClientConfig';
import { useParams } from 'react-router-dom';
import { PortalFetchWrapper } from '../manco/positionUpdatePortal/PortalFetchWrapper';

const GeneralSelectAFundOverview: React.FC<
  GeneralOverviewPageRenderedComponentProps
> = (props) => {
  const { pageId } = useParams<any>();

  const sectionDetailsSelector = createSectionByIdSelector(props.section);
  const sectionDetails: Section | null = useSelector(sectionDetailsSelector);

  // Handles the fundselection for the position update portal
  if (pageId === 'position-update-portal') {
    return (
      <PortalFetchWrapper
        {...props}
        component={
          <SelectAFundPage
            section={props.section}
            fundsForUse={sectionDetails?.fundTypes || 'UCITS'}
          />
        }
      />
    );
  }
  return (
    <SelectAFundPage
      section={props.section}
      fundsForUse={sectionDetails?.fundTypes || 'UCITS'}
    />
  );
};

export default GeneralSelectAFundOverview;
