import React, { useEffect } from 'react';
import useFetchData from '../../../../hooks/useFetchData';
import GeneralComponentErrorShield from '../../../general/GeneralComponentErrorShield';
import { FundInfoComponentProps } from '../../../general/GeneralFundInfoPage';
import SubFundCumulativeBreakdownChart from './subComponents/SubFundCumulativeBreakdownChart.component';
import SubFundCumulativeBreakdownTable from './subComponents/SubFundCumulativeBreakdownTable.component';
import { useDispatch, useSelector } from 'react-redux';
import {
  addAdditionalTitleBarComponents,
  toggleTitleBarLoading,
} from '../../../../redux/pages/actions';
import { Select } from '../../../../types/redux/pages/PagesStore';
import { APPEND_NEW_SELECT_TO_TITLEBAR } from '../../../../types/redux/pages/pageTypes';
import useTitleBarSelect from '../../../../hooks/useTitleBarSelect';
import SubFundCumulativeBreakdownMap from './subComponents/SubFundCumulativeBreakdownMap.component';
import {
  activeDateRangeEndSelector,
  activeDateRangeStartSelector,
} from '../../../../redux/pages/selectors';

const SubFundCumulativeBreakdown: React.FC<FundInfoComponentProps> = ({
  fundId,
}) => {
  const fromDate = useSelector(activeDateRangeStartSelector);
  const toDate = useSelector(activeDateRangeEndSelector);

  const PerformanceAttributionData = useFetchData({
    url: `/performance_attribution/${fundId}/${fromDate}/${toDate}`,
    keyName: `performance_attribution_${fundId}_${fromDate}_${toDate}`,
    makeFetch:
      fundId !== undefined &&
      fromDate !== null &&
      fromDate !== '' &&
      toDate !== null &&
      toDate !== '',
  });

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(toggleTitleBarLoading(true));
    if (PerformanceAttributionData && !PerformanceAttributionData.isFetching) {
      const selectsToAdd: Select[] = [
        {
          titleBarTitle: 'Breakdown: ',
          titleBarKey: 'breakdown',
          values: [
            {
              label: 'Sector',
              value: 'sector',
            },
            {
              label: 'Currency',
              value: 'currency',
            },
            {
              label: 'Country',
              value: 'country',
            },
            {
              label: 'Asset Class',
              value: 'asset_class',
            },
            {
              label: 'Region',
              value: 'region',
            },
            {
              label: 'Market Cap',
              value: 'market_capitalization',
            },
            {
              label: 'Credit Rating',
              value: 'credit_rating',
            },
            {
              label: 'Maturity Dates',
              value: 'maturity_dates',
            },
            {
              label: 'Issuer Type',
              value: 'issuer_type',
            },
          ],
          displayOnOverviewPage: false,
          aValueIsAlwaysSelected: true,
          currentValue: 'sector',
        },
      ];
      dispatch(
        addAdditionalTitleBarComponents(
          selectsToAdd,
          APPEND_NEW_SELECT_TO_TITLEBAR,
        ),
      );
      dispatch(toggleTitleBarLoading(false));
    }
  }, [PerformanceAttributionData]);

  const selectedBreakdown = useTitleBarSelect('breakdown') ?? 'sector';

  return (
    <GeneralComponentErrorShield
      dataObjects={[PerformanceAttributionData]}
      customLoadingMessages={[
        'Loading Performance Attribution Data',
        `${fromDate} to ${toDate}`,
        'Just a few more seconds...',
      ]}
      customErrorMessage="No Data Available."
    >
      <SubFundCumulativeBreakdownChart
        data={PerformanceAttributionData}
        selectedBreakdown={selectedBreakdown}
      />
      {selectedBreakdown === 'country' && (
        <SubFundCumulativeBreakdownMap
          data={PerformanceAttributionData}
          selectedBreakdown={selectedBreakdown}
        />
      )}
      <SubFundCumulativeBreakdownTable
        data={PerformanceAttributionData}
        selectedBreakdown={selectedBreakdown}
      />
    </GeneralComponentErrorShield>
  );
};

export default SubFundCumulativeBreakdown;
