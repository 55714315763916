import { Dispatch, FC, useEffect, useRef, useState } from 'react';
import Icon from '@mui/material/Icon';
import { greys, mainColors } from '../../../../../../styling/theme';
import makeStyles from '@mui/styles/makeStyles';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Button from '@mui/material/Button';
import MapsUgcIcon from '@mui/icons-material/MapsUgc';
import client from '../../../../../../utilities/requestClient';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Tooltip } from '@mui/material';

interface CommentEditorButtonProps {
  fundId: string | null;
  shareClass: string;
  commentId: string | null;
  kidIndex: number;
  hasUnresolvedComments: boolean;
  setKiidsContent: Dispatch<any>;
  kiidsContent: any[];
}

const useStyles = makeStyles((props) => ({
  editorContainer: {
    width: '100rem',
    maxHeight: '15rem',
    minHeight: '10rem',
    // marginRight: '3rem'
    zIndex: 10,
    overflowY: 'auto',
  },
  addCommentButton: {
    display: 'flex',
    justifyContent: 'space-around',
    marginBottom: '0.5rem',
  },
  commentsContainer: {
    display: 'flex',
    marginBottom: '0.5rem',
  },
  arrow: {
    '&:before': {
      border: '1px solid black',
    },
    color: 'white',
    zIndex: 10,
  },
  topOfCommentsBox: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  userNameContainer: {
    marginRight: '2rem',
  },
  tooltip: {
    // backgroundColor: mainColors.mainBlue_lightAlt,
    backgroundColor: 'white',
    border: '1px solid black',
    color: 'black',
    maxWidth: 'none',
    pointerEvents: 'auto',
    zIndex: 10,
  },
  commentBox: {
    width: '50%',
    height: '2.5rem',
  },
  userNameBox: {
    width: '100%',
    height: '2.5rem',
  },
  fieldName: {
    fontSize: '1.5rem',
    fontWeight: 500,
    color: mainColors.mainBlue,
    display: 'flex',
    marginTop: '0.5rem',
  },
  fieldWithLabel: {
    display: 'flex',
  },
  commentDisplayBox: {
    width: '95%',
    border: `1px solid ${mainColors.controlButtonBlue}`,
    borderRadius: '5px',
    // marginLeft: '3rem',
    justifyContent: 'center',
  },
  commentDisplay: {
    marginLeft: '2rem',
    fontSize: '1.5rem',
    color: mainColors.mainBlue,
  },
  timestampDisplay: {
    marginLeft: '2rem',
  },
  noCommentsMessage: {
    fontSize: '1.5rem',
    marginLeft: '2rem',
    color: mainColors.mainBlue,
  },
}));

const CommentEditorButton: FC<CommentEditorButtonProps> = (props) => {
  const {
    fundId,
    shareClass,
    commentId,
    kidIndex,
    hasUnresolvedComments,
    setKiidsContent,
    kiidsContent,
  } = props;
  const classes = useStyles();
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [distanceFromTop, setDistanceFromTop] = useState<number>(0);
  const [aCommentWasAdded, setACommentWasAdded] = useState(false);
  const [allCommentsWereRemoved, setAllCommentsWereRemoved] = useState(false);
  // this piece of state is used to ensure that if comments are added then and the tooltip is reopened then it will call comments.
  const [currentCommentId, setCurrentCommentId] = useState(commentId);

  const ref = useRef();

  const handleTooltipClose = () => {
    setTooltipOpen(false);
  };
  const requestClient = client();

  const handleTooltipOpen = () => {
    // if(ref !== null) console.log("HERE IS REF NOW", ref.measure());
    setTooltipOpen(true);
  };
  return (
    <ClickAwayListener
      // onClickAway={handleTooltipClose}
      onClickAway={() => {
        return;
      }}
    >
      <Tooltip
        PopperProps={{
          disablePortal: true,
        }}
        classes={{ arrow: classes.arrow, tooltip: classes.tooltip }}
        title={
          <CommentEditor
            fundId={fundId}
            shareClass={shareClass}
            commentId={currentCommentId}
            setCurrentCommentId={setCurrentCommentId}
            kidIndex={kidIndex}
            handleTooltipClose={handleTooltipClose}
            setACommentWasAdded={setACommentWasAdded}
            setAllCommentsWereRemoved={setAllCommentsWereRemoved}
            setKiidsContent={setKiidsContent}
            kiidsContent={kiidsContent}
          />
        }
        placement={distanceFromTop < 150 ? 'bottom' : 'top'}
        arrow
        onClose={handleTooltipClose}
        open={tooltipOpen}
        disableFocusListener
        disableHoverListener
        disableTouchListener
      >
        {(hasUnresolvedComments || aCommentWasAdded) &&
        !allCommentsWereRemoved ? (
          <Icon
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            sx={{
              fontSize: 30,
              cursor: isHovered ? 'pointer' : null,
              color: mainColors.pumpkin,
              opacity: isHovered ? 1 : 0.9,
            }}
            onClick={handleTooltipOpen}
            ref={(el) => {
              if (!el) return;
              setDistanceFromTop(el.getBoundingClientRect().y);
            }}
          >
            feedback
          </Icon>
        ) : (
          <Icon
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            sx={{
              fontSize: 30,

              color: isHovered ? greys.grey400 : greys.grey200,

              cursor: isHovered ? 'pointer' : null,
            }}
            onClick={handleTooltipOpen}
            ref={(el) => {
              if (!el) return;
              setDistanceFromTop(el.getBoundingClientRect().y);
            }}
          >
            chat_bubble
          </Icon>
        )}
      </Tooltip>
    </ClickAwayListener>
  );
};

interface CommentEditorProps {
  fundId: string | null;
  shareClass: string;
  commentId: string | null;
  setCurrentCommentId: Dispatch<any>;
  kidIndex: number;
  handleTooltipClose: () => void;
  setACommentWasAdded: Dispatch<any>;
  setAllCommentsWereRemoved: Dispatch<any>;
  setKiidsContent: Dispatch<any>;
  kiidsContent: any[];
}

const CommentEditor: FC<CommentEditorProps> = (props) => {
  const {
    fundId,
    shareClass,
    commentId,
    setCurrentCommentId,
    kidIndex,
    handleTooltipClose,
    setACommentWasAdded,
    setAllCommentsWereRemoved,
    setKiidsContent,
    kiidsContent,
  } = props;
  const classes = useStyles();
  const [comments, setComments] = useState<any[]>([]);
  // This is used to force a re-render when there are multiple comments present and one is removed
  const [numberComments, setNumberComments] = useState<number>(0);
  const [isCloseHovered, setIsCloseHovered] = useState<boolean>(false);
  const [addingComment, setAddingComment] = useState<boolean>(false);
  const [newComment, setNewComment] = useState<string>('');
  const [userName, setUserName] = useState<string>('');
  const requestClient = client();
  // const requestKiidsOverviewData = async () => {
  //     requestClient.post('get_kid_comments').then((response) => {
  //         setComments(response.data);
  //     });
  // }

  const onNewCommentChange = (event: any) => {
    setNewComment(event.target.value);
  };
  const onUserNameChange = (event: any) => {
    setUserName(event.target.value);
  };
  // useEffect(() => {
  //     if (commentId !== null) {
  //         requestKiidsOverviewData();
  //     }
  // }, []);

  // const updateKiidsDataWithComment = (commentId: string) => {
  //     const allContent = kiidsContent;
  //     const currentContentAtIndex = kiidsContent[kidIndex];
  //     // Update the comment id at the specific index.
  //     currentContentAtIndex.comment_id = commentId;
  //     // Now replace in the overall array
  //     allContent[kidIndex] = currentContentAtIndex;
  //     setKiidsContent(allContent);
  // }

  const addComment = () => {
    // Check if the comment and username have been set.
    if (
      newComment.replace(/\s+/g, '') === '' ||
      userName.replace(/s+/g, '') === ''
    ) {
      return;
    }
    // Create an object of formData
    const formData = new FormData();

    // Add comment_id to form data if one exists.
    if (commentId !== null || numberComments > 0) {
      formData.append(
        'comment_id',
        commentId ? commentId : comments[0].comment_id,
      );
    }

    // Add fund id
    formData.append('fund_id', fundId || '');
    // Add share class
    formData.append('share_class', shareClass);
    // Add kiid index.
    formData.append('kiid_index', kidIndex.toString());
    // Add the comment contents.
    formData.append('content', newComment);
    // Add the user name.
    formData.append('user_name', userName);

    // Add the comment
    requestClient
      .post('add_kid_comment', formData, {
        headers: {
          'content-type': 'multipart/form-data',
        },
      })
      .then((response) => {
        setAddingComment(false);
        // Add the comment to the list so that it shows up straight away.
        const commentsList = comments;
        commentsList.push(response.data);
        setCurrentCommentId(response.data.comment_id);
        setComments(commentsList);
        setNumberComments(commentsList.length);
        setACommentWasAdded(true);
        setNewComment('');
        setUserName('');
      });
  };
  const markCommentResolved = (commentIndex: number) => {
    // Confirm that the user wants to resolve the comment
    const confirmation = confirm('Mark comment as resolved?');

    // console.log(isExecuted); // OK = true, Cancel = false
    if (!confirmation) {
      return;
    }
    // Create an object of formData
    const formData = new FormData();

    // Add comment_id to form data

    // Account for case where a comment is added and resolved without a refesh.
    const commentIdToUse = commentId
      ? commentId
      : comments[commentIndex].comment_id;
    formData.append('comment_id', commentIdToUse || '');

    // Add fund id
    formData.append('fund_id', fundId || '');
    // Add share class
    formData.append('share_class', shareClass);
    // Add kiid index.
    formData.append('kiid_index', kidIndex.toString());
    // Add the user name.
    formData.append('time_stamp', comments[commentIndex].time_stamp);

    // Add the comment
    requestClient
      .post('mark_kid_comment_resolved', formData, {
        headers: {
          'content-type': 'multipart/form-data',
        },
      })
      .then((response) => {
        // setAddingComment(false);
      });
    // Add to the comments list so that it shows up straight away.
    const commentsList = comments;
    commentsList.splice(commentIndex, 1);
    setComments(commentsList);
    setNumberComments(commentsList.length);
    if (commentsList.length === 0) {
      // Need to do this for the case where a user adds a comment and then marks it resolved straight away
      setACommentWasAdded(false);
      setAllCommentsWereRemoved(true);
    }
  };
  const deleteComment = (commentIndex: number) => {
    // Confirm that the user wants to resolve the comment
    const confirmation = confirm(
      'Are you sure you want to delete this comment?',
    );

    // console.log(isExecuted); // OK = true, Cancel = false
    if (!confirmation) {
      return;
    }
    // Create an object of formData
    const formData = new FormData();

    // Add comment_id to form data

    // Account for case where a comment is added and deleted without a refesh.
    const commentIdToUse = commentId
      ? commentId
      : comments[commentIndex].comment_id;
    formData.append('comment_id', commentIdToUse || '');

    // Add fund id
    formData.append('fund_id', fundId || '');
    // Add share class
    formData.append('share_class', shareClass);
    // Add kiid index.
    formData.append('kiid_index', kidIndex.toString());
    // Add the user name.
    formData.append('time_stamp', comments[commentIndex].time_stamp);

    // Add the comment
    requestClient
      .post('delete_kid_comment', formData, {
        headers: {
          'content-type': 'multipart/form-data',
        },
      })
      .then((response) => {
        // setAddingComment(false);
      });
    // Add to the comments list so that it shows up straight away.
    const commentsList = comments;
    commentsList.splice(commentIndex, 1);
    setComments(commentsList);
    setNumberComments(commentsList.length);
    if (commentsList.length === 0) {
      // Need to do this for the case where a user adds a comment and then marks it resolved straight away
      setACommentWasAdded(false);
      setAllCommentsWereRemoved(true);
    }
  };

  useEffect(() => {
    // If a comment ID is present then pull any comments.
    if (commentId !== null && commentId !== '') {
      // Create an object of formData
      const formData = new FormData();
      // Add the comment id.
      formData.append('comment_id', commentId);
      // Add the comment
      requestClient
        .post('get_kid_comments', formData, {
          headers: {
            'content-type': 'multipart/form-data',
          },
        })
        .then((response) => {
          setComments(response.data);
          setNumberComments(response.data.length);
        });
    }
  }, []);

  return (
    <div className={classes.editorContainer}>
      <Icon
        sx={{
          fontSize: 15,

          // color: isHovered ? mainColors.controlButtonBlue : greys.grey900,
          color: mainColors.secondaryRed,
          opacity: isCloseHovered ? 1 : 0.8,
          // cursor: isHovered ? 'pointer' : null
          '&:hover': {
            cursor: 'pointer',
          },
          // right: '0.2rem',
          // position: 'fixed',
          marginLeft: '98%',
        }}
        onMouseEnter={() => setIsCloseHovered(true)}
        onMouseLeave={() => setIsCloseHovered(false)}
        onClick={handleTooltipClose}
      >
        cancel
      </Icon>

      <div className={classes.addCommentButton}>
        {addingComment ? (
          <>
            <input
              className={classes.commentBox}
              type="text"
              onChange={onNewCommentChange}
            />
            <div className={classes.fieldWithLabel}>
              <div className={classes.fieldName}>User:</div>
              <input
                className={classes.userNameBox}
                type="text"
                onChange={onUserNameChange}
              />
            </div>
            <Button
              size="small"
              variant="contained"
              onClick={() => {
                addComment();
              }}
              startIcon={<AddCircleIcon fontSize="small" />}
            >
              Add
            </Button>
          </>
        ) : (
          <Button
            variant="contained"
            onClick={() => {
              setAddingComment(true);
            }}
            startIcon={<MapsUgcIcon fontSize="small" />}
          >
            Add New Comment
          </Button>
        )}
      </div>
      {numberComments > 0 ? (
        comments.map((comment, index) => {
          return (
            <div key={`comment_${index}`} className={classes.commentsContainer}>
              <div className={classes.commentDisplayBox}>
                <div className={classes.topOfCommentsBox}>
                  <div className={classes.timestampDisplay}>
                    {comment.time_stamp.substring(0, 16).replace('T', ' ')}
                  </div>
                  <div className={classes.userNameContainer}>
                    {comment.user_name}
                  </div>
                </div>
                <div className={classes.commentDisplay}>{comment.content}</div>
              </div>
              <Icon
                sx={{
                  fontSize: 20,

                  // color: isHovered ? mainColors.controlButtonBlue : greys.grey900,
                  color: mainColors.Pass_darker,
                  opacity: 1,
                  // opacity: isCloseHovered ? 1 : 0.8,
                  // cursor: isHovered ? 'pointer' : null
                  '&:hover': {
                    cursor: 'pointer',
                    opacity: 0.7,
                  },
                  // right: '0.2rem',
                  // position: 'fixed',
                  // marginLeft: '99%'
                }}
                onClick={() => markCommentResolved(index)}
              >
                check_box
              </Icon>
              <Icon
                sx={{
                  fontSize: 20,

                  // color: isHovered ? mainColors.controlButtonBlue : greys.grey900,
                  color: mainColors.Fail_darker,
                  opacity: 1,
                  // opacity: isCloseHovered ? 1 : 0.8,
                  // cursor: isHovered ? 'pointer' : null
                  '&:hover': {
                    cursor: 'pointer',
                    opacity: 0.7,
                  },

                  // right: '0.2rem',
                  // position: 'fixed',
                  // marginLeft: '99%'
                }}
                onClick={() => deleteComment(index)}
              >
                delete
              </Icon>
            </div>
          );
        })
      ) : (
        <div className={classes.noCommentsMessage}>No Comments Added Here.</div>
      )}
    </div>
  );
};

export default CommentEditorButton;
