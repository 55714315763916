import { formatDateForCheckingState } from './dateFormatters';

export const formatFundUrl = (
  url: string,
  fundId: string,
  positionDate?: string | undefined,
) => {
  return positionDate
    ? url + '/' + fundId + '/' + positionDate
    : url + '/' + fundId;
};

export const makeUniqueDataKey = (
  fundName: string,
  keyName: string,
  positionDate: string | undefined = undefined,
) => {
  return positionDate
    ? `${positionDate}_${fundName}_${keyName}`
    : `${fundName}_${formatDateForCheckingState(new Date())}_${keyName}`;
};
