import React, { FC, useState } from 'react';
import { DataObject } from '../../../../../types/redux/data/dataTypes';
import GridItem from '../../../../layout/GridComponents/GridItem';
import ByPositionTable, {
  ByPositioUnderlyingRulesData,
  ByPositionData,
} from './byPosition/ByPositionTable.component';
import makeStyles from '@mui/styles/makeStyles';
import { greys, mainColors } from '../../../../../styling/theme';
import { hexToRGBA } from '../../../../../utilities/colorUtilities';
import clsx from 'clsx';
import {
  formatESGWithUnit,
  roundToNDecimalPlacesReturnNumber,
} from '../../../../../utilities/numberFormatters';
import { esgRuleSortValue } from '../ESGExposure.component';
import ByRuleTable, {
  ByRuleData,
  ByRuleUnderlyingPositionsData,
} from './byRule/ByRuleTable.component';
import dayjs from 'dayjs';
import { Button, Theme } from '@mui/material';

interface EsgExposureContainerProps {
  fundId: string;
  fundName: string;
  positionDate: string | undefined;
  esgData: DataObject;
  rulesData: DataObject;
}

export function getAssetClassFromGmid(gmid: number) {
  // get the first digit of the gimd
  const firstDigit = String(gmid)[0];
  switch (firstDigit) {
    case '1':
      return 'Equity';
    case '2':
      return 'Commodity';
    case '3':
      return 'FX';
    case '4':
      return 'Fixed Income';
    case '5':
      return 'Cash';
    case '6':
      return 'Hybrid/Other';
    case '9':
      return 'Property';
    default:
      return '';
  }
}

function buildEsgRuleDataForPositions(
  data: any,
  rulesDescriptions: any,
): ByPositioUnderlyingRulesData[] {
  if (!rulesDescriptions.data.length) return [];
  const tableData: ByPositioUnderlyingRulesData[] = [];
  const rulesData = rulesDescriptions.data[0];

  for (const key in data) {
    if (key in rulesData) {
      tableData.push({
        id: key,
        indicator: rulesData[key].indicator,
        description: rulesData[key].description,
        value: formatESGWithUnit(data[key], rulesData[key].unit),
      });
    }
  }

  //Sort the data
  const sortedTableData = tableData.sort(
    (a: ByPositioUnderlyingRulesData, b: ByPositioUnderlyingRulesData) =>
      esgRuleSortValue(a.id) > esgRuleSortValue(b.id) ? 1 : -1,
  );

  return sortedTableData;
}

const buildByPositionData = (
  esgData: DataObject,
  rulesData: DataObject,
): ByPositionData[] => {
  if (!esgData.data.length) return [];
  if (!('positions' in esgData.data[0])) return [];
  const exposureData = esgData.data[0].positions;
  const nav = esgData.data[0].nav;
  const tableData: ByPositionData[] = [];
  interface exposureType {
    [key: string]: ByPositionData[];
  }
  // get esg version.
  const version = esgData.data[0].version;

  // Get all of the asset classes in the data.
  const assetClasses: exposureType = {};
  exposureData.forEach((element: any) => {
    const currentAssetClass = getAssetClassFromGmid(
      element.gm_id ? element.gm_id : element.gm_id_new,
    );
    if (!(currentAssetClass in assetClasses)) {
      // The first element in each asset class array will be the aggregation
      assetClasses[currentAssetClass] = [
        {
          asset: `${currentAssetClass} (Aggregation)`,
          isin: '',
          sector: '',
          asset_class: currentAssetClass,
          currency: '',
          position: 0,
          market_price: 0,
          exposure: 0,
          gross_exposure: 0,
          gross_exposure_pc: 0,
          proxified: '',
          environmental: 0,
          social: 0,
          governance: 0,
          esg: 0,
          underlying_rules: [],
        },
      ];
    }

    // Now append the asset to the corresponding list for that asset type.
    assetClasses[currentAssetClass].push({
      asset: element.gm_name ?? '',
      isin: element.isin ?? '',
      sector: element.sector_name ?? '',
      asset_class: currentAssetClass ?? '',
      currency: element.base_currency ?? 0,
      position:
        (element.raw_position ? element.raw_position : element.mr_position) ??
        0,
      market_price: element.market_price ?? 0,
      exposure:
        roundToNDecimalPlacesReturnNumber(element.exposure * nav, 0) ?? 0,
      gross_exposure:
        roundToNDecimalPlacesReturnNumber(element.gross_exposure * nav, 0) ?? 0,
      gross_exposure_pc: element.gross_exposure ?? 0,
      proxified: element.uses_proxy ?? '',
      environmental:
        (version === 3 ? element.E_Score : element.esg_scores.e) ?? 0,
      social: (version === 3 ? element.S_Score : element.esg_scores.s) ?? 0,
      governance: (version === 3 ? element.G_Score : element.esg_scores.g) ?? 0,
      esg: (version === 3 ? element.ESG_Score : element.esg_scores.esg) ?? 0,
      underlying_rules: buildEsgRuleDataForPositions(element, rulesData),
    });

    // Finally increment values in the Aggregate row.
    assetClasses[currentAssetClass][0].exposure =
      roundToNDecimalPlacesReturnNumber(
        assetClasses[currentAssetClass][0].exposure + element.exposure * nav,
        0,
      );
    assetClasses[currentAssetClass][0].gross_exposure =
      roundToNDecimalPlacesReturnNumber(
        assetClasses[currentAssetClass][0].gross_exposure +
          element.gross_exposure * nav,
        0,
      );
    assetClasses[currentAssetClass][0].gross_exposure_pc =
      assetClasses[currentAssetClass][0].gross_exposure_pc +
      element.gross_exposure;
  });

  // Combine all the asset classes into one list.
  for (const key of Object.keys(assetClasses)) {
    tableData.push(...assetClasses[key]);
  }

  return tableData;
};

const getPositionsByRules = (
  esgData: DataObject,
  rule: string,
): ByRuleUnderlyingPositionsData[] => {
  if (!esgData.data.length) return [];
  if (!('positions_by_rule' in esgData.data[0])) return [];
  return esgData.data[0].positions_by_rule[rule] ?? [];
};

function buildByRuleData(
  esgData: DataObject,
  rulesDescriptions: DataObject,
): ByRuleData[] {
  if (!esgData.data.length) return [];
  if (!('portfolio_scores' in esgData.data[0])) return [];
  if (!rulesDescriptions.data.length) return [];
  const tableData: ByRuleData[] = [];
  const rowData = esgData.data[0].portfolio_scores;
  const rulesData = rulesDescriptions.data[0];
  const nav = esgData.data[0].nav;

  for (const key in rowData) {
    if (key in rulesData) {
      tableData.push({
        id: key ?? '',
        indicator: rulesData[key].indicator ?? '',
        description: rulesData[key].description ?? '',
        value: formatESGWithUnit(rowData[key], rulesData[key].unit) ?? '',
        underlying_positions: getPositionsByRules(esgData, key).map(
          (position: any) => {
            return {
              issuer: position.issuer,
              isin: position.isin,
              sector_name: position.sector_name,
              asset_class: getAssetClassFromGmid(
                position.gm_id ? position.gm_id : position.gm_id_new,
              ),
              exposure: position.exposure * nav,
              gross_exposure: position.gross_exposure * nav,
              gross_exposure_pc: position.gross_exposure,
              value: formatESGWithUnit(position.value, rulesData[key].unit),
            };
          },
        ),
      });
    }
  }
  return tableData;
}

const getPositionDate = (
  esgData: DataObject,
  positionDate: string | undefined,
): string => {
  if (!esgData.data.length) return positionDate ?? dayjs().format('YYYY-MM-DD');
  if (!('selected_position_date' in esgData.data[0]))
    return positionDate ?? dayjs().format('YYYY-MM-DD');
  return esgData.data[0].selected_position_date;
};

const useStyles = makeStyles<Theme>(() => ({
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '3rem 3rem 1rem 3rem',
  },
  header: {
    width: '22rem',
    fontSize: 'clamp(2rem, 2vw, 3rem)',
    fontWeight: 400,
    color: mainColors.mainBlue,
  },
  assetClassButtonsContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    gap: '1rem 0.2rem',
  },
  assetClassButton: {
    transition: 'width .2s',
    borderRadius: '.8rem',
    padding: '0.5 3rem',
    margin: '0 0.5rem',
    fontSize: 'clamp(1rem, 0.9vw, 1.5rem)',
    backgroundColor: greys.grey400,
    height: '3rem',
    color: 'white',
    fontWeight: 500,
    blockSize: 'fit-content',
    filter: `drop-shadow(0.1rem 0.1rem 0.1rem ${greys.grey400})`,
    '&:hover': {
      backgroundColor: hexToRGBA(mainColors.mainBlue, 0.5),
    },
  },
  activeAssetClassButton: {
    backgroundColor: mainColors.mainBlue,
  },
}));

// this component collects the different esg data types and allows user to switch between them
const EsgExposureContainer: FC<EsgExposureContainerProps> = ({
  fundId,
  fundName,
  positionDate,
  esgData,
  rulesData,
}) => {
  const classes = useStyles();

  const [tableType, setTableType] = useState<'by-position' | 'by-rule'>(
    'by-position',
  );

  const byPositionData = buildByPositionData(esgData, rulesData);
  const byRuleData = buildByRuleData(esgData, rulesData);

  return (
    <GridItem xs={12} card>
      <div className={classes.toolbar}>
        <h2 className={classes.header}>Exposure Data</h2>
        <div className={classes.assetClassButtonsContainer}>
          <Button
            aria-describedby={'by-position'}
            variant="text"
            disableElevation
            onClick={() => setTableType('by-position')}
            className={
              tableType === 'by-position'
                ? clsx(classes.assetClassButton, classes.activeAssetClassButton)
                : classes.assetClassButton
            }
          >
            By Position
          </Button>
          <Button
            aria-describedby={'by-rule'}
            variant="text"
            disableElevation
            onClick={() => setTableType('by-rule')}
            className={
              tableType === 'by-rule'
                ? clsx(classes.assetClassButton, classes.activeAssetClassButton)
                : classes.assetClassButton
            }
          >
            By Rule
          </Button>
        </div>
      </div>
      {tableType === 'by-position' ? (
        <ByPositionTable
          dataForRender={byPositionData}
          positionDate={getPositionDate(esgData, positionDate)}
          getRowCanExpand={() => true}
          fundId={fundId}
          fundName={fundName}
        />
      ) : null}
      {tableType === 'by-rule' ? (
        <ByRuleTable
          dataForRender={byRuleData}
          positionDate={getPositionDate(esgData, positionDate)}
          getRowCanExpand={() => true}
          fundId={fundId}
          fundName={fundName}
        />
      ) : null}
    </GridItem>
  );
};

export default EsgExposureContainer;
