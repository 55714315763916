import React, { useState } from 'react';
import useFetchData from '../../../../hooks/useFetchData';
import { GeneralOverviewPageRenderedComponentProps } from '../../../../types/components/componentTypes';
import GeneralComponentErrorShield from '../../../general/GeneralComponentErrorShield';
import FundTable from './CoverageRatioFundsTable.component';
import SummaryTable from './SummaryTable.component';
import { Grid } from '@mui/material';

const CoverageRatio: React.FC<GeneralOverviewPageRenderedComponentProps> = (
  props,
) => {
  const [issuer, setIssuer] = useState<string>('');

  const coverageRatioData = useFetchData({
    url: 'article48CoverageRules',
    keyName: 'coverage_ratio',
    makeFetch: true,
  });

  return (
    <GeneralComponentErrorShield
      dataObjects={[coverageRatioData]}
      customLoadingMessages={['Loading Coverage Ratio Data']}
    >
      <Grid container spacing={2} style={{ padding: 8 }}>
        <SummaryTable
          data={coverageRatioData}
          issuer={issuer}
          setIssuer={setIssuer}
        />
        <FundTable data={coverageRatioData} issuer={issuer} />
      </Grid>
    </GeneralComponentErrorShield>
  );
};

export default CoverageRatio;
