import React from 'react';
import { CustomColumn } from '../../../../types/components/tables/tableTypes';
import { DataObject, Status } from '../../../../types/redux/data/dataTypes';
import CustomTable from '../../../tables/CustomTable';
import {
  addCommasToNumbersAndRound,
  toTwoDecimalPlaces,
} from '../../../../utilities/numberFormatters';
import { mapStatusToColor } from '../../../../utilities/colorUtilities';
import { CheckCircle } from '@mui/icons-material';

interface GlobalExposureTableProps {
  globalExposureData: DataObject;
}

interface GlobalExposureData {
  fund: string;
  navDate: string;
  nav: number;
  currency: string;
  globalExpMethod: string;
  fundVar: number;
  // if it's commitment its blank, if var
  varApproach: boolean;
  commitment: number;
  maxHundred: boolean;
  sumOfNotionals: number;
  sept8: number;
  oct8: number;
  apr9: number;
  july11: number;
  aug11: number;
}

export function generateGlobalExposureColumns(): CustomColumn<GlobalExposureData>[] {
  return [
    {
      title: 'Fund',
      field: 'fund',
      cellStyle: {
        textAlign: 'left',
      },
      headerStyle: {
        textAlign: 'left',
      },
    },
    {
      title: 'NAV Date',
      field: 'navDate',
      headerStyle: {
        backgroundColor: '#65ff66',
      },
    },
    {
      title: 'NAV',
      field: 'nav',
      render: (rowData: any) => addCommasToNumbersAndRound(rowData.nav),
      headerStyle: {
        backgroundColor: '#65ff66',
      },
      cellStyle: {
        textAlign: 'right',
      },
    },
    {
      title: 'Currency',
      field: 'currency',
      headerStyle: {
        backgroundColor: '#65ff66',
      },
    },
    {
      title: 'Global Exp Method',
      field: 'globalExpMethod',
      headerStyle: {
        backgroundColor: 'rgb(255,153,0)',
      },
      cellStyle: (rowData: any) => {
        if (rowData !== 'Commitment') {
          return {
            fontWeight: 'bold',
          };
        } else {
          return {
            fontWeight: 'normal',
          };
        }
      },
    },
    {
      title: 'Fund VaR (% NAV)',
      field: 'fundVar',
      render: (rowData: any) => toTwoDecimalPlaces(rowData.fundVar),
      headerStyle: {
        backgroundColor: 'rgb(255,153,0)',
        textAlign: 'center',
      },
      cellStyle: (rowData: any, totalRowData: any) => {
        if (totalRowData.globalExpMethod !== 'Commitment') {
          return {
            fontWeight: 'bold',
            backgroundColor: '#FF9966',
          };
        } else {
          return {
            fontWeight: 'normal',
            backgroundColor: '#FF9966',
          };
        }
      },
    },
    {
      title: 'VaR Approach',
      field: 'varApproach',
      headerStyle: {
        backgroundColor: 'rgb(255,153,0)',
        textAlign: 'center',
      },
      cellStyle: {
        textAlign: 'center',
      },
      render: (rowData: any) =>
        rowData.varApproach ? (
          <CheckCircle style={{ color: mapStatusToColor(Status.Pass) }} />
        ) : (
          ''
        ),
    },
    {
      title: 'Commitment (% NAV)',
      field: 'commitment',
      render: (rowData: any) => toTwoDecimalPlaces(rowData.commitment),
      headerStyle: {
        backgroundColor: 'rgb(255,153,0)',
      },
      cellStyle: {
        backgrondColor: '#FFBB99',
      },
    },
    {
      title: 'Max 100%',
      field: 'maxHundred',
      headerStyle: {
        backgroundColor: 'rgb(255,153,0)',
      },
      render: (rowData: any) =>
        rowData.maxHundred ? (
          <CheckCircle style={{ color: mapStatusToColor(Status.Pass) }} />
        ) : (
          ''
        ),
    },
    {
      title: 'Sum of Notionals (% NAV)',
      field: 'sumOfNotionals',
      headerStyle: {
        backgroundColor: 'rgb(255,153,0)',
      },
    },
    {
      title: 'Sept 2008',
      field: 'sept8',
      headerStyle: {
        backgroundColor: '#B3B3FF',
      },
    },
    {
      title: 'Oct 2008',
      field: 'oct8',
      headerStyle: {
        backgroundColor: '#B3B3FF',
      },
    },
    {
      title: 'Apr 2009',
      field: 'apr9',
      headerStyle: {
        backgroundColor: '#B3B3FF',
      },
    },
    {
      title: 'July 2011',
      field: 'july11',
      headerStyle: {
        backgroundColor: '#B3B3FF',
      },
    },
    {
      title: 'Aug 2011',
      field: 'aug11',
      headerStyle: {
        backgroundColor: '#B3B3FF',
      },
    },
  ].map((col: any) => {
    return {
      ...col,
      cellStyle:
        typeof col.cellStyle === 'function'
          ? (rowData, totalRowData) => ({
              textAlign: 'center',
              ...col.cellStyle(rowData, totalRowData),
            })
          : {
              textAlign: 'center',
              ...col.cellStyle,
            },
      headerStyle: {
        textAlign: 'center',
        ...col.headerStyle,
      },
    };
  });
}

function handleVarGlobalExp(globalExpRule: string, fundVarVal: number) {
  if (globalExpRule === 'VaR 8.0% max') {
    return fundVarVal < 8;
  } else if (globalExpRule === 'VaR 15.0% max') {
    return fundVarVal < 15;
  } else if (globalExpRule === 'VaR 18.0% max') {
    return fundVarVal < 18;
  } else if (
    globalExpRule === 'VaR 20.0% max' ||
    globalExpRule === 'VaR 20% max'
  ) {
    return fundVarVal < 20;
  } else {
    throw new Error('Unspecified Global Exp Value: ' + globalExpRule);
  }
}

function generateGlobalExposureData(inputData: any) {
  if (!inputData || !inputData.length) return [];
  return inputData.map((datum: any[]) => {
    const varApproachIsTrue =
      datum[2]['var_fund'] === 'yes'
        ? handleVarGlobalExp(datum[2]['gl_exp_method'], datum[2]['fund_var'])
        : false;
    return {
      fund: datum[1],
      navDate: datum[2]['selected_position_date'],
      nav: datum[2]['nav'],
      currency: datum[2]['base_currency'],
      globalExpMethod: datum[2]['gl_exp_method'],
      fundVar: datum[2]['fund_var'],
      varApproach: varApproachIsTrue,
      commitment: datum[2]['commitment'],
      maxHundred:
        datum[2]['var_fund'] === 'no'
          ? datum[2]['commitment'] < 100
          : varApproachIsTrue,
      sumOfNotionals: datum[2]['sum_of_notionals'],
      sept8: datum[2]['hst'][0],
      oct8: datum[2]['hst'][1],
      apr9: datum[2]['hst'][2],
      july11: datum[2]['hst'][3],
      aug11: datum[2]['hst'][4],
    };
  });
}
/*
 * commitment fund and commitment < 100%
 * or var fund and commitment tick is green
 */
const GlobalExposureTable: React.FC<GlobalExposureTableProps> = (props) => {
  const data = generateGlobalExposureData(
    props.globalExposureData.data[0].data,
  ).sort((a: GlobalExposureData, b: GlobalExposureData) => {
    if (a.fund.toUpperCase() < b.fund.toUpperCase()) return -1;
    else return 1;
  });
  return (
    <CustomTable<GlobalExposureData>
      options={{
        paging: false,
        search: true,
        exportButton: true,
      }}
      title="Overview"
      csvFields={generateGlobalExposureColumns().map((col: any) => col.field)}
      data={data}
      columns={generateGlobalExposureColumns()}
      showToolbar
    />
  );
};

export default GlobalExposureTable;
