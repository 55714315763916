import React, { FC } from 'react';
import { useLocation } from 'react-router-dom';
import ManageAccounts from './pages/manageAccounts/ManageAccounts.component';
import PageNotFound from './pages/PageNotFound.component';
import { useSelector } from 'react-redux';
import {
  activeDateSelector,
  availableDatesSelector,
} from '../../redux/pages/selectors';
import MyAccount from './pages/myAccount/MyAccount.component';
import UserDetailsService from '../services/user-details-service';
import SecuritySettings from './pages/securitySettings/SecuritySettings.component';

export interface SettingsComponentProps {
  positionDate?: string;
  availableDates?: string[];
}

const generateComponentFromKey = (
  AdminOnlyRoutes: string[],
  pageId: string | null,
  userType: string | null,
): React.FC<SettingsComponentProps> => {
  if (
    userType !== 'external_administrator' &&
    AdminOnlyRoutes.includes(pageId as string)
  ) {
    return PageNotFound;
  } else {
    switch (pageId) {
      case 'my-account':
        return MyAccount;
      case 'manage-accounts':
        return ManageAccounts;
      case 'security-settings':
        return SecuritySettings;
      default:
        return PageNotFound;
    }
  }
};

const AdminOnlyRoutes: string[] = ['manage-accounts'];

const SettingsRouter: FC = () => {
  const { pathname } = useLocation();
  const [_, __, page] = pathname.split('/');

  const userDetails = UserDetailsService.getUserDetails();

  const activeDate = useSelector(activeDateSelector);
  const availableDates = useSelector(availableDatesSelector);

  const ComponentForRender = generateComponentFromKey(
    AdminOnlyRoutes,
    page,
    userDetails?.user_type,
  );

  return (
    <ComponentForRender
      positionDate={activeDate || undefined}
      availableDates={availableDates || undefined}
    />
  );
};

export default SettingsRouter;
