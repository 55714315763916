import { ReactElement } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { RaptorTheme, mainColors } from '../../../../../styling/theme';
import GridItem from '../../../../layout/GridComponents/GridItem';
import GenericGaugeChart from '../../../../charts/GenericGaugeChart';
import { useWindowSize } from 'react-use';
import { percentageToTwoDecimalPlaces } from '../../../../../utilities/numberFormatters';
import { Typography } from '@mui/material';

const useMancoSummaryContainerStyles = makeStyles((theme: RaptorTheme) => ({
  regulationContainerRoot: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '1rem 3rem',
    paddingRight: 0,
    transition: 'all .3s',
    height: '100%',
    width: '100%',
    zIndex: 1,
    position: 'relative',
    cursor: 'pointer',
  },
  textSection: {
    display: 'flex',
    borderRadius: 4,
    flexDirection: 'column',
    justifyContent: 'space-around',
    width: '20%',
  },
  subTitle: {
    [theme.breakpoints.down('lg')]: {
      fontSize: '1.8rem',
    },
  },
  subTitle_info: {
    [theme.breakpoints.down('lg')]: {
      fontSize: '1.8rem',
    },
    fontSize: '2.4rem',
    fontWeight: 'bold',
    marginBottom: '1.5rem',
    color: theme.palette.primary.main,
  },
  pieSection: {
    cursor: 'pointer',
    display: 'flex',
    borderRadius: 4,
    width: '100%',
    justifyContent: 'space-around',
  },
}));

interface MancoSummaryProps {
  data: any;
}
export interface StyleProps {
  title: string;
}

function getNumberSubFunds(data: any): number {
  if (typeof data.data[0].funds !== 'object') return 0;
  if (Object.keys(data.data[0].funds).length)
    return Object.keys(data.data[0].funds).length;
  return 0;
}

function getMancoLevelData(data: any): any {
  if (!data.data.length) return undefined;
  if (!('manco' in data.data[0])) return undefined;
  return data.data[0].manco;
}

const getGuageHeight = (width: number) => {
  const calcHeight = width / 6 - 100;
  if (calcHeight < 100) {
    return 100;
  } else if (calcHeight > 250) {
    return 250;
  } else {
    return calcHeight;
  }
};

function MancoSummaryContainer({ data }: MancoSummaryProps): ReactElement {
  const classes = useMancoSummaryContainerStyles();
  // Account for summary row
  const numberSubFunds = getNumberSubFunds(data);
  const totalReports = data.data[0].manco.total_reports;
  const { height, width } = useWindowSize();
  const mancoData = getMancoLevelData(data);
  // console.log("HERE IS PROCESSING FUNDS", mancoData['processing_funds'].length);
  // Calculate the number of funds yet to be processed
  const stillToBeProcessed =
    mancoData['total_reports'] -
    mancoData['processing_reports'].length -
    mancoData['complete_reports'].length;
  return (
    <GridItem
      card
      key="manco-overview"
      item
      cardStyle={{ maxHeight: '40rem' }}
      xs={12}
    >
      <Typography
        variant="h1"
        style={{
          borderBottom: '1rem',
          marginBottom: '4rem',
          marginLeft: '2rem',
          marginTop: '2rem',
        }}
      >
        {'Manco Summary'}
      </Typography>
      {/* <div className={classes.regulationContainerRoot}> */}
      {/* <div>
          <Typography
            variant="h1"
            style={{
              borderBottom: '1rem',
              marginBottom: '4rem',
            }}
          >
            {'Manco Summary'}
          </Typography>
          <div>
            <Typography variant="h2" className={classes.subTitle}>
              Number of Sub-Funds:
            </Typography>
            <Typography variant="h3" className={classes.subTitle_info}>
              {numberSubFunds}
            </Typography>
            <Typography variant="h2" className={classes.subTitle}>
              {`Total Reports Generated:`}
            </Typography>
            <Typography variant="h3" className={classes.subTitle_info}>
              {totalReports}
            </Typography>
          </div>
        </div> */}
      <div className={classes.pieSection}>
        <GenericGaugeChart
          width={width / 6}
          height={getGuageHeight(width)}
          fillValue={mancoData ? mancoData['complete_reports'] : 0}
          fullValue={mancoData ? mancoData['total_reports'] : 0}
          title={'Complete Reports'}
          chartColor={mainColors.Pass_darker}
          centerText={
            mancoData
              ? percentageToTwoDecimalPlaces(
                  mancoData['complete_reports'] / mancoData['total_reports'],
                )
              : '0.00%'
          }
          startLabel="0%"
          endLabel="100%"
          textBelow={
            mancoData
              ? `${mancoData['complete_reports']}/${mancoData['total_reports']} Reports`
              : ''
          }
        />
        <GenericGaugeChart
          width={width / 6}
          height={getGuageHeight(width)}
          fillValue={mancoData ? mancoData['processing_reports'] : 0}
          fullValue={mancoData ? mancoData['total_reports'] : 0}
          title={'Currently Processing'}
          chartColor={mainColors.mainBlue}
          centerText={
            mancoData
              ? percentageToTwoDecimalPlaces(
                  mancoData['processing_reports'] / mancoData['total_reports'],
                )
              : '0.00%'
          }
          startLabel="0%"
          endLabel="100%"
          textBelow={
            mancoData
              ? `${mancoData['processing_reports']}/${mancoData['total_reports']} Reports`
              : ''
          }
        />
        <GenericGaugeChart
          width={width / 6}
          height={getGuageHeight(width)}
          fillValue={mancoData ? mancoData['analyst_input_reports'] : 0}
          fullValue={mancoData ? mancoData['total_reports'] : 0}
          title={'Analyst Input Required'}
          chartColor={mainColors.actionRequiredYellow}
          centerText={
            mancoData
              ? percentageToTwoDecimalPlaces(
                  mancoData['analyst_input_reports'] /
                    mancoData['total_reports'],
                )
              : '0.00%'
          }
          startLabel="0%"
          endLabel="100%"
          textBelow={
            mancoData
              ? `${mancoData['analyst_input_reports']}/${mancoData['total_reports']} Reports`
              : ''
          }
        />
        <GenericGaugeChart
          width={width / 6}
          height={getGuageHeight(width)}
          fillValue={mancoData ? mancoData['pending_reports'] : 0}
          fullValue={mancoData ? mancoData['total_reports'] : 0}
          title={'To Be Processed'}
          chartColor={mainColors.Alert}
          centerText={
            mancoData
              ? percentageToTwoDecimalPlaces(
                  mancoData['pending_reports'] / mancoData['total_reports'],
                )
              : '0.00%'
          }
          startLabel="0%"
          endLabel="100%"
          textBelow={
            mancoData
              ? `${mancoData['pending_reports']}/${mancoData['total_reports']} Reports`
              : ''
          }
        />
        <GenericGaugeChart
          width={width / 6}
          height={getGuageHeight(width)}
          fillValue={mancoData ? mancoData['no_data_reports'] : 0}
          fullValue={mancoData ? mancoData['total_reports'] : 0}
          title={'Awaiting Data'}
          chartColor={mainColors.Fail}
          centerText={
            mancoData
              ? percentageToTwoDecimalPlaces(
                  mancoData['no_data_reports'] / mancoData['total_reports'],
                )
              : '0.00%'
          }
          startLabel="0%"
          endLabel="100%"
          textBelow={
            mancoData
              ? `${mancoData['no_data_reports']}/${mancoData['total_reports']} Reports`
              : ''
          }
        />
      </div>
      {/* </div> */}
    </GridItem>
  );
}

export default MancoSummaryContainer;
