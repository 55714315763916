import { FC } from 'react';
import { DataObject } from '../../../../types/redux/data/dataTypes';
import GenericBarChart from '../../../charts/GenericBarChart';
import {
  formatMillions,
  percentageToTwoDecimalPlacesNoHundred,
} from '../../../../utilities/numberFormatters';
import CustomTick from '../../../charts/chartComponents/CustomTick';
import DisplayAreaCenteredWrapper from '../../../layout/utilities/displayAreaWrapper';
import { Typography, useTheme } from '@mui/material';

export interface AssetClassBcExposure {
  alternative: number;
  cash: number;
  commodity: number;
  equity: number;
  fixed_income: number;
  foreign_exchange: number;
  other: number;
  property: number;
}

export const ASSET_CLASS_KEYS = [
  'Alternative/CIS',
  'Cash',
  'Commodity',
  'Equity',
  'Fixed Income',
  'Foreign Exchange',
];
export function formatKeyForAssetClassesInChart(
  key: keyof AssetClassBcExposure,
): string {
  const keyMap: {
    [key: string]: string;
  } = {
    alternative: ASSET_CLASS_KEYS[0],
    cash: ASSET_CLASS_KEYS[1],
    commodity: ASSET_CLASS_KEYS[2],
    equity: ASSET_CLASS_KEYS[3],
    fixed_income: ASSET_CLASS_KEYS[4],
    foreign_exchange: ASSET_CLASS_KEYS[5],
  };

  return keyMap[key];
}
export function buildAssetClassChartData(inputData: any[]) {
  if (!inputData.length) return [];

  const data = inputData[0];
  const assetClassGrossExposure = data.asset_class_gross_exposure;
  const keys = Object.keys(assetClassGrossExposure);
  const nav = data.nav;
  // return keys.filter(key => key !== 'property' && key !== 'other').map((key: string, index: number) => {
  //   return {
  //     name: formatKeyForAssetClassesInChart(key as keyof AssetClassBcExposure),
  //     Exposure: ((data.asset_class_bc_exposure[key] / data.nav) * 100).toFixed(
  //       2
  //     ),
  //   };
  // });
  //
  const formatter = (val: number) => (val / nav) * 100;
  return [
    {
      name: 'Equity',
      Exposure: formatter(assetClassGrossExposure.equity),
    },
    {
      name: 'Commodity',
      Exposure: formatter(assetClassGrossExposure.commodity),
    },
    {
      name: 'Foreign Exchange',
      Exposure: formatter(assetClassGrossExposure.foreign_exchange),
    },
    {
      name: 'Fixed Income',
      Exposure: formatter(assetClassGrossExposure.fixed_income),
    },
    {
      name: 'Cash',
      Exposure: formatter(assetClassGrossExposure.cash),
    },
    {
      name: 'Alternative/CIS',
      Exposure: formatter(assetClassGrossExposure.alternative),
    },
  ];
}

export function buildCountryCodeChartData(inputData: any[]) {
  if (!inputData.length) return [];

  const data = inputData[0];
  // ignore firs value in array as this should be a header
  const riskLevelGrossExposure =
    'aggregate' in data.country_code_exposure
      ? data.country_code_exposure.aggregate.slice(1)
      : [];
  return riskLevelGrossExposure.map((el: any[]) => {
    return {
      name: el[0],
      Exposure: el[1] * 100,
    };
  });
}

export function buildOldAmlLevelData(inputData: any[]) {
  if (!inputData.length) return [];

  const data = inputData[0];
  if ('gross' in data.aml_exposure) return [];
  // Get the gross AML exposure data
  // const grossAmlExposure = data.aml_exposure.gross.slice(1, data.aml_exposure.gross.length);
  // Get the headers from the first list emelemt
  // const values = data.aml_exposure.slice(1);

  // Create a list for storing the data.
  // const returnData: any[] = [];
  // loop through the data and populate returnData.
  // values.forEach((item: any) => {
  //   if (item[0] !== 'Total') {
  //     returnData.push({
  //       name: item[0],
  //       Exposure: item[1],
  //       riskPoints: item[3]
  //     });
  //   }
  // })
  // return returnData;

  const names = data.aml_exposure[0];
  const values = data.aml_exposure[1];
  // Create a list for storing the data.
  const returnData = [];
  // Loop through each element in the lists and add to the data.
  for (let i = 0; i < names.length; i++) {
    returnData.push({
      name: names[i],
      Exposure: values[i],
    });
  }
  return returnData;
  // return grossAmlExposure.map((el: any[]) => {
  //   return {
  //     name: el[0],
  //     Exposure: el[1],
  //   };
  // });
}

export function buildAmlLevelData(inputData: any[]) {
  if (!inputData.length) return [];

  const data = inputData[0];
  if (!('aml_exposure' in data)) return [];
  if ('gross' in data.aml_exposure) return [];
  if (data.aml_exposure[0].includes('Country'))
    return buildOldAmlLevelData(inputData);
  // Get the gross AML exposure data
  // const grossAmlExposure = data.aml_exposure.gross.slice(1, data.aml_exposure.gross.length);
  // Get the headers from the first list emelemt
  const values = data.aml_exposure.slice(1);

  // Create a list for storing the data.
  const returnData: any[] = [];
  // loop through the data and populate returnData.
  values.forEach((item: any) => {
    if (item[0] !== 'Total') {
      returnData.push({
        name: item[0],
        Exposure: item[1],
        riskPoints: item[3],
      });
    }
  });
  return returnData;

  // const names = data.aml_exposure[0];
  // const values = data.aml_exposure[1];
  // // Create a list for storing the data.
  // const returnData = [];
  // // Loop through each element in the lists and add to the data.
  // for (let i = 0; i < names.length; i++) {
  //   returnData.push({
  //     name: names[i],
  //     Exposure: values[i]
  //   });
  // };
  // return returnData;
  // return grossAmlExposure.map((el: any[]) => {
  //   return {
  //     name: el[0],
  //     Exposure: el[1],
  //   };
  // });
}

export function buildCatData(inputData: any[]) {
  if (!inputData.length) return [];

  const data = inputData[0];
  if (!('cat_exposure' in data)) return [];
  // Get the gross AML exposure data
  // const grossAmlExposure = data.aml_exposure.gross.slice(1, data.aml_exposure.gross.length);
  // Get the headers from the first list emelemt
  const names = data.cat_exposure[0];
  const values = data.cat_exposure[1];
  // Create a list for storing the data.
  const returnData = [];
  // Loop through each element in the lists and add to the data.
  for (let i = 0; i < names.length; i++) {
    returnData.push({
      name: names[i],
      Exposure: values[i],
    });
  }
  return returnData;
  // return grossAmlExposure.map((el: any[]) => {
  //   return {
  //     name: el[0],
  //     Exposure: el[1],
  //   };
  // });
}

export interface ExposureTimeSeriesProps {
  inputData: DataObject;
  windowWidth: number;
  windowHeight: number;
  exportButton?: boolean;
  exposureType: 'assetClass' | 'countryCode' | 'amlLevel' | 'cat';
}

function getChartTitle(exposureType: string): string {
  switch (exposureType) {
    case 'assetClass':
      return 'Asset Class Gross Exposure';
    case 'countryCode':
      return 'Country Code Risk Level Gross Exposure';
    case 'amlLevel':
      return 'AML Gross Exposure';
    case 'cat':
      return 'Catastrophe Type Exposure';
    default:
      return 'Asset Class Gross Exposure';
  }
}

export function buildChartData(exposureType: string, data: any): any {
  switch (exposureType) {
    case 'assetClass':
      return buildAssetClassChartData(data);
    case 'countryCode':
      return buildCountryCodeChartData(data);
    case 'amlLevel':
      return buildAmlLevelData(data);
    case 'cat':
      return buildCatData(data);
    default:
      return buildAssetClassChartData(data);
  }
}

function getxAxisLabel(exposureType: string): string {
  switch (exposureType) {
    case 'assetClass':
      return 'Asset Class';
    case 'countryCode':
      return 'Risk Level';
    case 'amlLevel':
      return 'AML Exposure Type';
    case 'cat':
      return 'Catastrophe Type';
    default:
      return 'Asset';
  }
}

const ExposureBarChart: FC<ExposureTimeSeriesProps> = (props) => {
  const chartData = buildChartData(props.exposureType, props.inputData.data);
  const title = getChartTitle(props.exposureType);
  const xAxisLabel = getxAxisLabel(props.exposureType);
  const theme = useTheme();
  return chartData.length ? (
    <GenericBarChart
      title={title}
      id="country_code_risk_level_gross_exposure"
      loading={props.inputData.isFetching}
      bars={
        props.exposureType === 'amlLevel' && 'riskPoints' in chartData[0]
          ? [
              {
                dataKey: 'Exposure',
                fill: theme.palette.primary.main,
                key: 'exposurebarkey',
                // yAxisId: 'left'
              },
              {
                dataKey: 'riskPoints',
                fill: theme.palette.secondary.main,
                key: 'riskPoints',
                yAxisId: 'right',
                // yAxis: 'right'
              },
            ]
          : [
              {
                dataKey: 'Exposure',
                fill: theme.palette.primary.main,
                key: 'exposurebarkey',
              },
            ]
      }
      loadingHeight={400}
      height={400}
      margin={{ top: 0, bottom: 24, left: 24, right: 24 }}
      width={'100%'}
      legend={false}
      yAxisLabel={{
        position: 'left',
        angle: -90,
        offset: 0,
        fontSize: '1.4rem',
        fill: theme.palette.grey[700],
        value: 'Exposure',
        style: {
          textAnchor: 'middle',
        },
      }}
      yAxisFormatter={(val) => formatMillions(val) + '%'}
      tooltipFormatter={
        props.exposureType === 'amlLevel' && 'riskPoints' in chartData[0]
          ? (value: any, name: any) => [
              name === 'riskPoints'
                ? value
                : percentageToTwoDecimalPlacesNoHundred(value as number),
              name === 'Exposure' ? name : 'Risk Points',
            ]
          : (val: any) => percentageToTwoDecimalPlacesNoHundred(val as number)
      }
      additionalYAxis={
        props.exposureType === 'amlLevel' && 'riskPoints' in chartData[0]
          ? [
              {
                dataKey: 'riskPoints',
                leftDataKey: 'riskPoints',
                type: 'number',
                domain: [0, 'dataMax'],
                yAxisId: 'right',
                orientation: 'right',
                label: {
                  position: 'right',
                  angle: -90,
                  offset: 0,
                  fontSize: '1.4rem',
                  fill: theme.palette.grey[700],
                  value: 'Risk Points',
                  style: {
                    textAnchor: 'middle',
                  },
                },
                // tickFormatter: (value: number) => value as string,
              },
            ]
          : undefined
      }
      xAxes={[
        {
          tick: (
            <CustomTick
              rotationValue={props.exposureType === 'cat' ? -15 : 0}
              textAnchor={props.exposureType === 'cat' ? 'end' : 'middle'}
            />
          ),
          label: {
            value: xAxisLabel,
            offset: 10,
            style: {
              fill: theme.palette.grey[700],
              fontSize: '1.4rem',
            },
            position: 'bottom',
          },
          dataKey: 'name',
        },
      ]}
      data={chartData}
      exportButton={props.exportButton}
    />
  ) : (
    <DisplayAreaCenteredWrapper noMinHeight={true}>
      <Typography variant="h1">{'No data Available.'}</Typography>
    </DisplayAreaCenteredWrapper>
  );
};

export default ExposureBarChart;
