import React, { FC } from 'react';
import { CustomColumn } from '../../../../types/components/tables/tableTypes';
import {
  addCommasToNumbersAndRound,
  percentageToTwoDecimalPlaces,
  roundNumberToNDecimalPlaces,
  roundNumberToTwoDecimalPlaces,
} from '../../../../utilities/numberFormatters';
import GridItem from '../../../layout/GridComponents/GridItem';
import CustomTable from '../../../tables/CustomTable';

interface CarbonContributersPanelProps {
  data: any;
}

interface ConcerningPositionsData {
  isin: string;
  asset: string;
  exposure: number;
  grossExposure: number;
  grossExposurePc: number;
  marketPrice: number;
  environmental: number;
  esg: number;
  currency: string;
  totalCarbon: number;
  scope1: number;
  scope2: string;
}

const detailColumns: CustomColumn<ConcerningPositionsData>[] = [
  {
    title: 'Asset',
    field: 'asset',
    width: '20%',
    cellStyle: { textAlign: 'center' },
    headerStyle: { textAlign: 'center' },
  },
  {
    title: 'Isin',
    field: 'isin',
    cellStyle: { textAlign: 'center' },
    headerStyle: { textAlign: 'center' },
    // render: (rowData: ArticleScannerTableData) => percentageToTwoDecimalPlaces(rowData.unalignedGrossExposure)
  },
  {
    title: 'Scope 1',
    field: 'scope1',
    cellStyle: { textAlign: 'center' },
    headerStyle: { textAlign: 'center' },
    render: (rowData: ConcerningPositionsData) =>
      `${roundNumberToTwoDecimalPlaces(rowData.scope1)}`,
  },
  {
    title: 'Scope 2',
    field: 'scope2',
    cellStyle: { textAlign: 'center' },
    headerStyle: { textAlign: 'center' },
    render: (rowData: ConcerningPositionsData) => rowData.scope2,
  },
  {
    title: 'Exposure',
    field: 'exposure',
    cellStyle: { textAlign: 'center' },
    headerStyle: { textAlign: 'center' },
    render: (rowData: ConcerningPositionsData) =>
      addCommasToNumbersAndRound(rowData.exposure),
  },
  {
    title: 'Gross Exposure',
    field: 'grossExposure',
    cellStyle: { textAlign: 'center' },
    headerStyle: { textAlign: 'center' },
    render: (rowData: ConcerningPositionsData) =>
      addCommasToNumbersAndRound(rowData.grossExposure),
  },
  {
    title: 'Gross Exposure %',
    field: 'grossExposurePc',
    cellStyle: { textAlign: 'center' },
    headerStyle: { textAlign: 'center' },
    render: (rowData: ConcerningPositionsData) =>
      percentageToTwoDecimalPlaces(rowData.grossExposurePc),
  },
  {
    title: 'Market Price',
    field: 'marketPrice',
    cellStyle: { textAlign: 'center' },
    headerStyle: { textAlign: 'center' },
    render: (rowData: ConcerningPositionsData) =>
      roundNumberToNDecimalPlaces(rowData.marketPrice, 4),
  },
  {
    title: 'Currency',
    field: 'currency',
    cellStyle: { textAlign: 'center' },
    headerStyle: { textAlign: 'center' },
  },
  {
    title: 'Environmental',
    field: 'environmental',
    cellStyle: { textAlign: 'center' },
    headerStyle: { textAlign: 'center' },
    render: (rowData: ConcerningPositionsData) =>
      roundNumberToTwoDecimalPlaces(rowData.environmental),
  },
  {
    title: 'ESG',
    field: 'esg',
    cellStyle: { textAlign: 'center' },
    headerStyle: { textAlign: 'center' },
    render: (rowData: ConcerningPositionsData) =>
      roundNumberToTwoDecimalPlaces(rowData.esg),
  },
  {
    title: 'Total Carbon',
    field: 'totalCarbon',
    cellStyle: { textAlign: 'center' },
    headerStyle: { textAlign: 'center' },
    render: (rowData: ConcerningPositionsData) =>
      `${roundNumberToTwoDecimalPlaces(rowData.totalCarbon)} tCO2`,
  },
];

function buildTableData(data: any): ConcerningPositionsData[] {
  if (!data.data.length) return [];
  if (!('raptor_carbon_page' in data.data[0])) return [];
  const contributors = data.data[0].raptor_carbon_page.contributers;
  const positions = data.data[0].positions;
  const tableData: ConcerningPositionsData[] = [];

  const nav = data.data[0].nav;

  // Check ESG Version.
  const version = data.data[0].version;
  // CAse for version 2.
  if (version === 3) {
    contributors.forEach((contributor: any) => {
      const positionData = positions.filter((obj: any) => {
        return obj.position_id === contributor.position_id;
      });

      let scope2 = '--';
      let totalCarbon = 0;
      if (typeof positionData[0]['M-EE21-5'] == 'number') {
        totalCarbon += positionData[0]['M-EE21-5'];
      }
      if (
        typeof positionData[0]['M-EE21-6'] === 'number' &&
        typeof positionData[0]['M-EE21-7'] === 'number'
      ) {
        const scope2Number = Math.min(
          positionData[0]['M-EE21-6'],
          positionData[0]['M-EE21-7'],
        );
        scope2 = roundNumberToTwoDecimalPlaces(scope2Number);
        totalCarbon += scope2Number;
      }

      tableData.push({
        isin: positionData[0].isin,
        asset: positionData[0].gm_name_long,
        exposure: positionData[0].exposure * nav,
        grossExposure: positionData[0].gross_exposure * nav,
        grossExposurePc: positionData[0].gross_exposure,
        marketPrice: positionData[0].client_price,
        environmental: positionData[0].E_Score,
        esg: positionData[0].ESG_Score,
        currency: positionData[0].base_currency,
        totalCarbon: totalCarbon,
        scope1: positionData[0]['M-EE21-5'],
        scope2: scope2,
      });
    });
  } else {
    contributors.forEach((value: any) => {
      tableData.push({
        isin: value.isin,
        asset: value.g_name,
        exposure: value.exposure * nav,
        grossExposure: value.gross_exposure * nav,
        grossExposurePc: value.gross_exposure,
        marketPrice: value.market_price,
        environmental: 'e' in value.esg_scores ? value.esg_scores.e : 0,
        esg: 'esg' in value.esg_scores ? value.esg_scores.esg : 0,
        currency: value.base_currency,
        totalCarbon: value.total_carbon,
        scope1: 0,
        scope2: '0',
      });
    });
  }

  const sortedData = tableData.sort((a, b) =>
    a.totalCarbon > b.totalCarbon ? -1 : 1,
  );

  return sortedData;
}

const CarbonContributersPanel: FC<CarbonContributersPanelProps> = (props) => {
  const { data } = props;
  // const classes = useArticelOverviewPanelStyles();
  // const { height } = useWindowSize();
  // const coverageData = buildCoverageData(data);
  // const chartData = buildPieChartData(data);
  const tableData = buildTableData(data);

  // const parisAlignedData = buildParisAlignedData(data);
  // const timSeriesData = buildOverviewTimeseriesData(data);

  return (
    <GridItem card xs={12}>
      <CustomTable<ConcerningPositionsData>
        columns={detailColumns}
        showToolbar
        data={tableData}
        title={'Carbon Contributers (sorted)'}
        options={{
          paging: false,
          search: false,
          sorting: false,
          exportButton: true,
        }}

        // detailPanel={esgSecondLevelTableWrapper()}
        // // options={{
        // //   headerStyle: {
        // //     color: 'white',
        // //     textAlign: 'left',
        // //     backgroundColor: theme.palette.grey[100],
        // //   },

        //   paging: false,
        //   exportButton: false,
        // }}
      />
    </GridItem>
  );
};

export default CarbonContributersPanel;
