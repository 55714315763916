import { Status } from '../../../../../types/redux/data/dataTypes';
import { CustomColumn } from '../../../../../types/components/tables/tableTypes';
import {
  addCommasToNumbersAndRound,
  percentageToTwoDecimalPlaces,
} from '../../../../../utilities/numberFormatters';
import GeneralReportDatePicker from '../../../../general/GeneralReportDatePicker';
import PageLinkButton from '../../../../buttons/PageLinkButton';
import { specifyStatus } from '../../../../../utilities/generalMappings';

export interface AifmdOverviewTableData {
  [key: string]: unknown;
  internalFundName: string;
  externalFundName: string;
  positionDate: string;
  currency: string;
  netAssetValue: number;
  reports: string[];
  market: Status;
  counterparty: Status;
  credit: Status;
  liquidity: Status;
  totalCommitmentPercent: number;
  fundVar: number;
  totalGrossExposurePercent: number;
  riskComplianceStatus: Status;
  tableData?: { [index: string]: number };
}

type AifmdReports = {
  [key: string]: string[];
};

export function generateAifmdOverviewColumns(): CustomColumn<AifmdOverviewTableData>[] {
  return [
    {
      title: 'Internal Fund Name',
      field: 'internalFundName',
      render: (rowData: AifmdOverviewTableData) =>
        rowData.externalFundName.includes('Mersenne')
          ? `Fund ${(rowData.tableData?.id || 0) + 1}`
          : rowData.internalFundName,
      width: '16rem',
    },
    {
      title: 'External Fund Name',
      field: 'externalFundName',
    },
    {
      title: 'Position Date',
      field: 'positionDate',
      width: '12rem',
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
      },
    },
    {
      title: 'Currency',
      field: 'currency',
    },
    {
      title: 'Net Asset Value',
      field: 'netAssetValue',
      render: (rowData: AifmdOverviewTableData) =>
        addCommasToNumbersAndRound(rowData.netAssetValue),
      width: '15rem',
      cellStyle: {
        textAlign: 'center',
      },
    },
    {
      title: 'Reports',
      field: 'reports',
      width: '10%',
      render: (rowData: AifmdOverviewTableData) => (
        <GeneralReportDatePicker
          reportNames={rowData.reports}
          reportUrl="aifmd_historical_report"
          fundId={rowData.internalFundName}
        />
      ),
      headerStyle: {
        textAlign: 'center',
      },
      cellStyle: {
        textAlign: 'center',
      },
    },

    {
      title: 'Total Commitment %',
      field: 'totalCommitmentPercent',
      width: '20rem',
      render: (rowData: AifmdOverviewTableData) => (
        <PageLinkButton
          status={specifyStatus('Pass')}
          fundName={rowData.internalFundName}
          text={percentageToTwoDecimalPlaces(rowData.totalCommitmentPercent)}
          page={'exposure'}
          section={'aifmd'}
          neutralColor
        />
      ),
      headerStyle: {
        textAlign: 'center',
      },
    },
    {
      title: 'Fund VAR %',
      field: 'fundVar',
      width: '20rem',
      render: (rowData: AifmdOverviewTableData) => (
        <PageLinkButton
          status={specifyStatus('Pass')}
          fundName={rowData.internalFundName}
          text={percentageToTwoDecimalPlaces(rowData.fundVar)}
          page={'stress-scenario-tests'}
          section={'aifmd'}
          neutralColor
        />
      ),
      headerStyle: {
        textAlign: 'center',
      },
    },
    {
      title: 'Total Gross Exposure %',
      field: 'totalGrossExposurePercent',
      width: '20rem',
      render: (rowData: AifmdOverviewTableData) => (
        <PageLinkButton
          status={specifyStatus('Pass')}
          fundName={rowData.internalFundName}
          text={percentageToTwoDecimalPlaces(rowData.totalGrossExposurePercent)}
          page={'exposure'}
          section={'aifmd'}
          neutralColor
        />
      ),
      headerStyle: {
        textAlign: 'center',
      },
    },
    {
      title: 'Prospectus Restrictions',
      field: 'riskComplianceStatus',
      width: '20rem',
      render: (rowData: AifmdOverviewTableData) => (
        <PageLinkButton
          status={specifyStatus(rowData.riskComplianceStatus)}
          fundName={rowData.internalFundName}
          page={'prospectus-restrictions'}
          section={'aifmd'}
        />
      ),
      headerStyle: {
        textAlign: 'center',
      },
    },
  ];
}

export function generateAifmdOverviewData(
  inputData: any[],
  reports: AifmdReports[],
) {
  const reportsArr = reports[0];
  return inputData.map((fund: any, index) => {
    const {
      selected_position_date,
      Counterparty,
      Credit,
      Market,
      Liquidity,
      base_currency,
      nav,
      total_commitment_pct,
      fund_var,
      total_gross_exposure_pct,
      risk_compliance_status,
    } = fund.monitor_data;
    return {
      internalFundName: fund.fund_name,
      externalFundName: fund.fund_name_full,
      positionDate: selected_position_date,
      currency: base_currency,
      netAssetValue: nav,
      market: Market,
      counterparty: Counterparty,
      credit: Credit,
      reports: reportsArr[fund.fund_name],
      liquidity: Liquidity,
      totalCommitmentPercent: total_commitment_pct,
      fundVar: fund_var,
      totalGrossExposurePercent: total_gross_exposure_pct,
      riskComplianceStatus: risk_compliance_status,
    };
  });
}
