import React, { FC } from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { TransitionProps } from '@mui/material/transitions';
import Slide from '@mui/material/Slide';
import makeStyles from '@mui/styles/makeStyles';
import client from '../../../../../utilities/requestClient';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toggleControlBarShowing } from '../../../../../redux/ui/controlbar/actions';
import RaptorSelect from '../../../../selects/RaptorSelect.component';
import axios from 'axios';

interface SaveConfirmationDialogBoxProps {
  dialogIsShowing: boolean;
  setDialogIsShowing: (value: boolean) => void;
  fundId: string;
  shareClass: string;
  documentLanguage: string;
  filteredOverviewData: any[];
  documentOptions: string[];
  setDocumentOptions: (value: string[]) => void;
  setFilteredOverviewData: (value: any[]) => void;
  fundName: string;
}

const useStyles = makeStyles(() => ({
  switch: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  selectContainer: {
    width: '120rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ReviewConfirmationDialogBox: FC<SaveConfirmationDialogBoxProps> = (
  props,
) => {
  // const classes = useStyles();
  const {
    dialogIsShowing,
    setDialogIsShowing,
    fundId,
    shareClass,
    documentLanguage,
    documentOptions,
    filteredOverviewData,
    fundName,
    setDocumentOptions,
    setFilteredOverviewData,
  } = props;
  const classes = useStyles();
  // INdicates that a review has been completed
  const [reviwComplete, setReviewComplete] = React.useState<boolean>(false);
  // USed to store the next document that is to be reviewed.
  const [nextDocument, setNextDocument] = React.useState<string>('');
  const [selectOptions, setSelectOptions] = React.useState<string[]>([]);
  const requestClient = client();

  // Function for marking the current document as reviewed
  const markDocumentReviewed = () => {
    // Create an object of formData
    const formData = new FormData();
    // Update the formData object.
    formData.append('fund_id', fundId);
    formData.append('share_class', shareClass);
    formData.append('document_language', documentLanguage);
    requestClient
      .post('kids_generator_mark_kid_reviewed', formData, {
        headers: {
          'content-type': 'multipart/form-data',
        },
      })
      .then((response) => {
        if (
          'Status' in response.data &&
          response.data['Status'] === 'success'
        ) {
          setReviewComplete(true);
          // Find the index of the selection in the options array
          const index = documentOptions.indexOf(`${fundName} - ${shareClass}`);
          // make a copy of the options and filtered overview data
          const documentOptionsCopy = [...documentOptions];
          const filteredOverviewDataCopy = [...filteredOverviewData];
          // Remove the option from the array
          documentOptionsCopy.splice(index, 1);
          setSelectOptions(documentOptionsCopy);
          // Remove the same index from the filtered overview data
          filteredOverviewDataCopy.splice(index, 1);
          setDocumentOptions(documentOptionsCopy);
          setFilteredOverviewData(filteredOverviewDataCopy);
        }
      });
  };
  const dispatch = useDispatch();
  const history = useHistory();
  // Function for returning to the overview page
  const backToOverview = () => {
    dispatch(toggleControlBarShowing(false));
    history.push('priips-kids-overview');
  };

  // Function for hadling the close of the dialog box
  const handleClose = (event: any, reason: string) => {
    if (reason !== 'backdropClick') {
      setDialogIsShowing(false);
    }
  };

  // Function for reviewing the next document
  function reviewNextDocument() {
    // Find the index of the selection in the options array
    const index = documentOptions.indexOf(nextDocument);
    // Find the corresponding object in the filtered overview data
    const nextDocumentData = filteredOverviewData[index];
    setDialogIsShowing(false);
    dispatch(toggleControlBarShowing(false));
    // Reroute to the review page for the next document
    history.push(
      `review?fundId=${nextDocumentData.fund_id_string}&isin=${
        nextDocumentData.share_class
      }&documentLanguage=${nextDocumentData.document_language}&currentVersion=${
        nextDocumentData.version
      }&publishedVersion=${nextDocumentData.version.split('.')[0] + '.0.0'}`,
    );
  }

  // Function for updating the next document to be reviewed
  function onSeletionChange(choice: string) {
    setNextDocument(choice);
  }
  return (
    <Dialog
      open={dialogIsShowing}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
      maxWidth="lg"
      fullWidth={true}
      PaperProps={{ style: { overflowY: 'visible' } }}
    >
      {!reviwComplete ? (
        <>
          <DialogTitle id="review-header-text" textAlign="center">
            Confirm Review?
          </DialogTitle>
          <DialogContentText id="review-subheader-text" textAlign="center">
            Update the version number of the document to indicate that a review
            has been completed.
          </DialogContentText>
          <DialogActions>
            <Button
              onClick={() =>
                history.push(
                  `view?fundId=${fundId}&isin=${shareClass}&documentLanguage=${documentLanguage}`,
                )
              }
            >
              Go To Edit Page
            </Button>
            <Button onClick={() => setDialogIsShowing(false)}>Cancel</Button>
            <Button onClick={markDocumentReviewed}>Confirm</Button>
          </DialogActions>
        </>
      ) : (
        <>
          <DialogTitle id="review-complete-header-text" textAlign="center">
            Review Complete
          </DialogTitle>
          {documentOptions.length > 0 ? (
            <>
              <DialogContentText id="review-select-text" textAlign="center">
                Would you like to review another Document? Select the Document
                from the dropdown below
              </DialogContentText>
              <DialogContent style={{ overflowY: 'visible' }}>
                <div className={classes.selectContainer}>
                  <RaptorSelect
                    options={selectOptions}
                    handleChoice={onSeletionChange}
                    maxWidth="80rem"
                    // defaultOption={documentOptions[0]}
                  />
                </div>
              </DialogContent>
            </>
          ) : (
            <DialogContentText id="review-select-text" textAlign="center">
              There Are no more documents to review
            </DialogContentText>
          )}
          <DialogActions>
            <Button onClick={backToOverview}>Return To Overview</Button>
            {documentOptions.length > 0 ? (
              <Button
                onClick={reviewNextDocument}
                disabled={nextDocument === ''}
              >
                Review Next Document
              </Button>
            ) : null}
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

export default ReviewConfirmationDialogBox;
