import { CustomColumn } from '../../../../types/components/tables/tableTypes';
import { Status } from '../../../../types/redux/data/dataTypes';
import {
  roundNumberToNDecimalPlaces,
  roundNumberToTwoDecimalPlaces,
} from '../../../../utilities/numberFormatters';
import GridItem from '../../../layout/GridComponents/GridItem';
import CustomTable from '../../../tables/CustomTable';
import { mapStatusToColor } from '../../../../utilities/colorUtilities';
import { Typography } from '@mui/material';
import { UnfoldLess, UnfoldMore } from '@mui/icons-material';

export interface HistoricalFailsTableData {
  metricId: number;
  description: string;
  reportType: string;
  riskCategory: string;
  secondLevelTableData: HistoricalFailsSecondLevelTableData[];
}

export interface HistoricalFailsSecondLevelTableData {
  date: string;
  limitValue: number;
  currentValue: number;
  status: Status;
}

interface HistoricalFailsTableProps {
  data: any;
  // title: string;
}

export function buildHistoricalFailsTableData(
  data: any,
): HistoricalFailsTableData[] {
  if (!data) return [];
  if (!data.length) return [];
  // Create an object for storing the data based on the metric_id
  const dataMap: { [key: number]: HistoricalFailsTableData } = {};
  // Loop over the data
  data.forEach((item: any) => {
    // If the metric_id is not in the dataMap, add it
    if (!dataMap[item.metric_id]) {
      dataMap[item.metric_id] = {
        metricId: item.metric_id,
        description: item.description,
        reportType: item.report_type,
        riskCategory: item.risk_category,
        secondLevelTableData: [
          {
            date: item.date,
            limitValue: item.metric_limit,
            currentValue: item.metric_value,
            status: item.status,
          },
        ],
      };
    }
    // If the metric_id is in the dataMap, add the second level data
    else {
      dataMap[item.metric_id].secondLevelTableData.push({
        date: item.date,
        limitValue: item.metric_limit,
        currentValue: item.metric_value,
        status: item.status,
      });
    }
  });
  // Convert the dataMap to an array
  return Object.values(dataMap);
}

const columns: CustomColumn<HistoricalFailsTableData>[] = [
  {
    field: 'metricId',
    title: 'Metric ID',
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: {
      textAlign: 'center',
    },
  },
  {
    field: 'description',
    title: 'Description',
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: {
      textAlign: 'center',
    },
  },
  {
    field: 'reportType',
    title: 'Report Type',
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: {
      textAlign: 'center',
    },
  },
  {
    field: 'riskCategory',
    title: 'Risk Category',
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: {
      textAlign: 'center',
    },
  },
];

export const HistoricalFailsTables: React.FC<HistoricalFailsTableProps> = ({
  data,
}) => {
  return (
    <GridItem xs={12} card>
      {Object.keys(data).length ? (
        Object.keys(data).map((key: string) => {
          const dataForTable = buildHistoricalFailsTableData(data[key]);
          return (
            <CustomTable<HistoricalFailsTableData>
              emptyDataSourceMessage="No Fails Found for Specified Period."
              options={{ paging: false, exportButton: true }}
              data={dataForTable}
              columns={columns}
              showToolbar
              detailPanel={[
                (rowData: HistoricalFailsTableData) => {
                  const icon = () =>
                    rowData.secondLevelTableData ? <UnfoldMore /> : <></>;
                  const render = () => (
                    <HistoricalFailsSecondLevelTable
                      data={rowData.secondLevelTableData}
                    />
                  );
                  return {
                    render,
                    disabled: !rowData.secondLevelTableData.length,
                    icon,
                    openIcon: UnfoldLess,
                  };
                },
              ]}
              title={key}
            />
          );
        })
      ) : (
        <div
          style={{
            padding: '2rem',
          }}
        >
          <Typography variant="h3">
            No Fails Found for Specified Period.
          </Typography>
        </div>
      )}
    </GridItem>
  );
};

interface HistoricalFailsSecondLevelTableProps {
  data: HistoricalFailsSecondLevelTableData[];
}
const secondLevelTableColumns: CustomColumn<HistoricalFailsSecondLevelTableData>[] =
  [
    {
      field: 'date',
      title: 'Date',
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
      },
    },
    {
      field: 'limitValue',
      title: 'Limit Value',
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
      },
      render: (rowData: HistoricalFailsSecondLevelTableData) =>
        roundNumberToTwoDecimalPlaces(rowData.limitValue),
    },
    {
      field: 'currentValue',
      title: 'Current Value',
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
      },
      render: (rowData: HistoricalFailsSecondLevelTableData) =>
        roundNumberToNDecimalPlaces(rowData.currentValue, 4),
    },
    {
      field: 'status',
      title: 'Status',
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
      },
      render: (rowData: HistoricalFailsSecondLevelTableData) => (
        <div
          style={{
            padding: '.8rem',
            width: '11rem',
            borderRadius: 8,
            textAlign: 'center',
            margin: '0 auto',
            border: `1px solid ${mapStatusToColor(rowData.status)}`,
          }}
        >
          <Typography
            variant="h3"
            style={{
              transform: 'translateY(1px)',
              color: mapStatusToColor(rowData.status),
              fontSize: '1.8rem',
            }}
          >
            Status: {rowData.status}
          </Typography>
        </div>
      ),
    },
  ];

const HistoricalFailsSecondLevelTable: React.FC<
  HistoricalFailsSecondLevelTableProps
> = ({ data }) => {
  return (
    <CustomTable<HistoricalFailsSecondLevelTableData>
      options={{ paging: false, exportButton: true }}
      data={data}
      columns={secondLevelTableColumns}
      showToolbar={false}
    />
  );
};
