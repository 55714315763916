import React, { useEffect, useState } from 'react';
import useFetchData from '../../../../hooks/useFetchData';
import GeneralComponentErrorShield from '../../../general/GeneralComponentErrorShield';
import { FundInfoComponentProps } from '../../../general/GeneralFundInfoPage';
import { useAdditionalTitleBarSelect } from '../../../../hooks/useAdditionalTitleBarComponents';
import useTitleBarSelect, {
  useTitleBarSelectValues,
} from '../../../../hooks/useTitleBarSelect';
import SubFundAnalysisGraph from './subComponents/subFundAnalysis/SubFundAnalysisGraph.component';
import SectorGraph from './subComponents/sector/SectorGraph.component';
import CurrencyVsFxGraph from './subComponents/currencyVsFx/CurrencyVsFxGraph.component';
import { TitleBarSelectValue } from '../../../../types/redux/pages/PagesStore';
import SubFundAnalysisTable from './subComponents/subFundAnalysis/SubFundAnalysisTable.component';
import SectorTable from './subComponents/sector/SectorTable.component';
import CurrencyVsFxTable from './subComponents/currencyVsFx/CurrencyVsFxTable.component';
import {
  activeDateRangeEndSelector,
  activeDateRangeStartSelector,
} from '../../../../redux/pages/selectors';
import { useSelector } from 'react-redux';
import FundVsBenchmarkGraph from './subComponents/fundVsBenchmark/FundVsBenchmarkGraph.component';
import FundVsBenchmarkTable from './subComponents/fundVsBenchmark/FundVsBenchmarkTable.component';

const SubFundHistorical: React.FC<FundInfoComponentProps> = ({ fundId }) => {
  const fromDate = useSelector(activeDateRangeStartSelector);
  const toDate = useSelector(activeDateRangeEndSelector);

  const PerformanceAttributionData = useFetchData({
    url: `/performance_attribution/${fundId}/${fromDate}/${toDate}`,
    keyName: `performance_attribution_${fundId}_${fromDate}_${toDate}`,
    makeFetch:
      fundId !== undefined &&
      fromDate !== null &&
      fromDate !== '' &&
      toDate !== null &&
      toDate !== '',
  });

  const [currencyOptions, setCurrencyOptions] = useState<TitleBarSelectValue[]>(
    [],
  );

  useEffect(() => {
    if (PerformanceAttributionData && !PerformanceAttributionData.isFetching) {
      if (
        PerformanceAttributionData.data &&
        PerformanceAttributionData.data.length &&
        PerformanceAttributionData.data[0].currency_wise_daily_returns &&
        PerformanceAttributionData.data[0].currency_wise_daily_returns
          .currency_pair
      ) {
        setCurrencyOptions(
          PerformanceAttributionData.data[0].currency_wise_daily_returns.currency_pair.map(
            (currency: any) => ({
              label: currency,
              value: currency,
            }),
          ),
        );
      }
    }
  }, [PerformanceAttributionData, fundId]);

  // Data Type Selector
  useAdditionalTitleBarSelect(
    {
      displayOnOverviewPage: true,
      titleBarKey: 'dataType',
      titleBarTitle: 'Type: ',
      values: [
        {
          label: 'Sub Fund Analysis',
          value: 'subFundAnalysis',
        },
        {
          label: 'Sector',
          value: 'sector',
        },
        {
          label: 'Currency Vs FX',
          value: 'currencyVsFx',
        },
        {
          label: 'Fund Vs Benchmark',
          value: 'fundVsBenchmark',
        },
      ],
      aValueIsAlwaysSelected: true,
      currentValue: 'subFundAnalysis',
    },
    true,
    true,
  );

  const dataTypeSelection = useTitleBarSelect('dataType') || 'subFundAnalysis';

  const currencySelection = useTitleBarSelect('currency');
  const currencySelectionValues = useTitleBarSelectValues('currency');

  // Currency Selector
  useAdditionalTitleBarSelect(
    {
      displayOnOverviewPage: true,
      titleBarKey: 'currency',
      titleBarTitle: 'Currency: ',
      values: currencyOptions,
      aValueIsAlwaysSelected: true,
      currentValue: currencyOptions.length
        ? currencyOptions.find((e) => e.value === 'ALL')
          ? 'ALL'
          : currencyOptions[0].value
        : 'none',
      show: dataTypeSelection === 'currencyVsFx',
    },
    true,
    currencySelectionValues !== currencyOptions &&
      dataTypeSelection === 'currencyVsFx',
  );

  const [dataType, setDataType] = useState<string>('subFundAnalysis');
  const [currency, setCurrency] = useState<string>('');

  const [benchmark] = useState<string>('iShares STOXX Europe 600');

  useEffect(() => {
    setDataType(dataTypeSelection);
    if (currencySelection) {
      setCurrency(currencySelection);
    }
  }, [dataTypeSelection, currencySelection]);

  return (
    <GeneralComponentErrorShield
      dataObjects={[PerformanceAttributionData]}
      customLoadingMessages={[
        'Loading Performance Attribution Data',
        `${fromDate} to ${toDate}`,
        'Just a few more seconds...',
      ]}
      customErrorMessage="No Data Available."
    >
      {dataType === 'subFundAnalysis' ? (
        <>
          <SubFundAnalysisGraph data={PerformanceAttributionData} />
          <SubFundAnalysisTable data={PerformanceAttributionData} />
        </>
      ) : null}
      {dataType === 'sector' ? (
        <>
          <SectorGraph data={PerformanceAttributionData} />
          <SectorTable data={PerformanceAttributionData} />
        </>
      ) : null}
      {dataType === 'currencyVsFx' ? (
        <>
          <CurrencyVsFxGraph
            data={PerformanceAttributionData}
            currency={currency}
          />
          <CurrencyVsFxTable data={PerformanceAttributionData} />
        </>
      ) : null}
      {dataType === 'fundVsBenchmark' ? (
        <>
          <FundVsBenchmarkGraph
            data={PerformanceAttributionData}
            benchmark={benchmark}
          />
          <FundVsBenchmarkTable
            data={PerformanceAttributionData}
            benchmark={benchmark}
          />
        </>
      ) : null}
    </GeneralComponentErrorShield>
  );
};

export default SubFundHistorical;
