import makeStyles from '@mui/styles/makeStyles';
import { RaptorTheme, mainColors } from '../../../../../../styling/theme';
import { Dispatch, FC, useEffect, useState } from 'react';
import axios from 'axios';
import { useWindowSize } from 'react-use';
import useSnackbar from '../../../../../../hooks/useSnackbar';
import client, { BASE_URL } from '../../../../../../utilities/requestClient';
import GridItem from '../../../../../layout/GridComponents/GridItem';
import SaveIcon from '@mui/icons-material/Save';
import CachedIcon from '@mui/icons-material/Cached';
import RaptorLoading from '../../../../../feedback/RaptorLoading';
import { Button } from '@mui/material';
import { useSelector } from 'react-redux';
import { clientNameSelector } from '../../../../../../redux/auth/selectors';

interface PdfViewerProps {
  factSheetsData: any[] | null;
  haveChangesBeenMade: boolean;
  setHaveChangesBeenMade: Dispatch<any>;
  fundId: string | null;
  fundName: string | null;
}

const useStyles = makeStyles<RaptorTheme>(() => ({
  parentContainer: {
    minHeight: '20rem',
    display: 'flex',
    margin: '2rem',
    gap: '2rem',
  },
  buttonsContainer: {
    gap: '1rem',
    display: 'flex',
    flexDirection: 'column',
    // justifyContent: 'center',
  },
  iframeContainer: {
    border: '2px solid',
    borderColor: mainColors.mainBlue,
    width: '100%',
    height: '80vh',
    display: 'flex',
    justifyContent: 'space-around',
    backgroundColor: 'white',
  },
  button: {
    height: '3rem',
    marginTop: '0.5rem',
    marginRight: '1rem',
    width: '12rem',
    backgroundColor: mainColors.controlButtonBlue,
    textColor: 'white',
  },
  navigationButton: {
    height: '3rem',
    marginRight: '1rem',
    width: '6rem',
    backgroundColor: mainColors.controlButtonBlue,
    textColor: 'white',
  },
  viewer: {
    display: 'flex',
    justifyContent: 'space-around',
  },
}));

const PdfViewer: FC<PdfViewerProps> = ({
  factSheetsData,
  haveChangesBeenMade,
  setHaveChangesBeenMade,
  fundId,
  fundName,
}) => {
  const classes = useStyles();
  const requestClient = client();

  const clientName = useSelector(clientNameSelector);

  const [pdfDocLoading, setPdfDocLoading] = useState(false);

  const refreshPdf = () => {
    // Check if the required fields have been filled in.
    if (!factSheetsData || !factSheetsData.length) {
      return;
    }

    const content: any[] = [];
    factSheetsData.forEach((element: any) => {
      content.push(element);
      // content.push(element.content);
    });
    const factSheetsJson = JSON.stringify(content);

    // Create an object of formData
    const formData = new FormData();
    formData.append('contents', factSheetsJson);
    formData.append('client', clientName || '');
    formData.append('fund_id', fundId || '');
    formData.append('fund_name', fundName || '');
    formData.append('month', 'December 2023');
    formData.append('period_covered', 'All Data as of 31 December 2023');
    formData.append(
      'params',
      JSON.stringify([
        'contents',
        'client',
        'fund_id',
        'fund_name',
        'month',
        'period_covered',
      ]),
    );

    setPdfDocLoading(true);

    requestClient
      .post('risksystem_pdf_generator/generate_fact_sheet_document', formData, {
        headers: {
          accept: 'application/pdf',
        },
        responseType: 'blob',
      })
      .then((response) => {
        const blob = new Blob([response.data], { type: 'application/pdf' });
        const file = window.URL.createObjectURL(blob);
        const iframe = document.querySelector('iframe');
        if (iframe?.src) {
          iframe.src = file;
          iframe.title = 'fact_sheet.pdf';
        }
        setPdfDocLoading(false);
      });
  };

  useEffect(() => {
    refreshPdf();
  }, [factSheetsData]);

  const { showAsyncSnackbar } = useSnackbar();

  async function handleSaveChanges() {
    // Check if the required fields have been filled in.
    if (!factSheetsData || !factSheetsData.length) {
      return;
    }

    const raidrJson = JSON.stringify(factSheetsData);
    // Create an object of formData
    const formData = new FormData();

    // Update the formData objet.
    formData.append('raidr_data', raidrJson);

    // Save the data to the DB
    await showAsyncSnackbar({
      startMessage: 'Saving Data.',
      successMessage: 'Data Saved.',
      failureMessage: 'An Error occured while saving.',
      promiseToResolve: requestClient.post('write_raidr_data', formData, {
        headers: {
          'content-type': 'multipart/form-data',
        },
      }),
    });

    // Update the save button to be disabled
    setHaveChangesBeenMade(false);
    // Refresh the PDF
    // refreshPdf();
  }

  function downloadFile() {
    const mapForm = document.createElement('form');
    mapForm.target = '_self';
    mapForm.method = 'POST';
    mapForm.action =
      BASE_URL +
      `risksystem_pdf_generator/generate_fact_sheet_document/${fundId}_January 2023.pdf`;

    // Create an input for the form
    const contentsInput = document.createElement('input');

    if (!factSheetsData || !factSheetsData.length) {
      return;
    }
    const content: any[] = [];
    factSheetsData.forEach((element: any) => {
      content.push(element);
      // content.push(element.content);
    });
    // add the contents
    const factSheetsJson = JSON.stringify(content);
    contentsInput.type = 'text';
    contentsInput.name = 'contents';
    contentsInput.value = factSheetsJson;
    // Add to the form
    mapForm.appendChild(contentsInput);
    // add the client
    const clientNameInput = document.createElement('input');
    clientNameInput.type = 'text';
    clientNameInput.name = 'client';
    clientNameInput.value = clientName || '';
    // Add to the form
    mapForm.appendChild(clientNameInput);
    // add the fund_id
    const fundIdInput = document.createElement('input');
    fundIdInput.type = 'text';
    fundIdInput.name = 'fund_id';
    fundIdInput.value = fundId || '';
    // Add to the form
    mapForm.appendChild(fundIdInput);
    // add the fund_name
    const fundNameInput = document.createElement('input');
    fundNameInput.type = 'text';
    fundNameInput.name = 'fund_name';
    fundNameInput.value = fundName || '';
    // Add to the form
    mapForm.appendChild(fundNameInput);
    // add the month
    const monthInput = document.createElement('input');
    monthInput.type = 'text';
    monthInput.name = 'month';
    monthInput.value = 'January 2023';
    // Add to the form
    mapForm.appendChild(monthInput);
    // add the period_covered
    const periodCoveredInput = document.createElement('input');
    periodCoveredInput.type = 'text';
    periodCoveredInput.name = 'period_covered';
    periodCoveredInput.value = 'All Data as of 30 December 2022';
    // Add to the form
    mapForm.appendChild(periodCoveredInput);
    // add the params
    const paramsInput = document.createElement('input');
    paramsInput.type = 'text';
    paramsInput.name = 'params';
    paramsInput.value = JSON.stringify([
      'contents',
      'client',
      'fund_id',
      'fund_name',
      'month',
      'period_covered',
    ]);
    // Add to the form
    mapForm.appendChild(paramsInput);
    // Add the form to the dom
    document.body.appendChild(mapForm);
    // submit the form
    mapForm.submit();
    // remove the form
    document.body.removeChild(mapForm);
  }

  return (
    <GridItem xs={12} card>
      <div className={classes.parentContainer}>
        <div className={classes.buttonsContainer}>
          <Button
            className={classes.button}
            variant="contained"
            onClick={() => refreshPdf()}
            endIcon={<CachedIcon style={{ color: 'white' }} />}
          >
            <div style={{ color: 'white' }}>Refresh</div>
          </Button>
          <Button
            className={classes.button}
            variant="contained"
            onClick={() => handleSaveChanges()}
            endIcon={<SaveIcon style={{ color: 'white' }} />}
            disabled={!haveChangesBeenMade}
          >
            <div style={{ color: 'white' }}>Save</div>
          </Button>
          <Button
            className={classes.button}
            variant="contained"
            onClick={() => downloadFile()}
            endIcon={<SaveIcon style={{ color: 'white' }} />}
            // disabled={!haveChangesBeenMade}
          >
            <div style={{ color: 'white' }}>Download</div>
          </Button>
        </div>
        <div className={classes.iframeContainer}>
          <iframe
            src=""
            width={pdfDocLoading ? '0%' : '100%'}
            height="100%"
            title={'fact_sheet.pdf'}
          ></iframe>
          {pdfDocLoading ? (
            <RaptorLoading centerWrap messages={['Generating Document...']} />
          ) : null}
        </div>
      </div>
    </GridItem>
  );
};

export default PdfViewer;
