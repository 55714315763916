import {
  addCommasToNumbersAndRound,
  numberToXDecimalPlaces,
  percentageToTwoDecimalPlaces,
  percentageToTwoDecimalPlacesNoHundred,
  toTwoDecimalPlaces,
} from '../../../../../utilities/numberFormatters';
import { CustomColumn } from '../../../../../types/components/tables/tableTypes';
import { MapLike } from 'typescript';

export interface BondDataServer {
  bond_data: any[];
  credit_data: [string, string, string][] | [string, number, number][];
  sector_data: [string, string, string][] | [string, number, number][];
  dv01_maturity_data: [string, string, string][] | [string, number, number][];
  [key: string]: any;
}
export interface IFixedIncomePositionData {
  number: number;
  name: string;
  coupon: number;
  maturity: string;
  currency: string;
  countryCode: string;
  yearsToMaturity: number;
  yield: number;
  isin: string;
  price: number;
  position: number;
  outstandingSecurities: number;
  exposure: number;
  duration: number;
  dv: number;
  positionDv: number;
  creditRating: string;
  convertible: string;
  isAT1: string;
}

export interface IFixedIncomeAggregateDataMinimised {
  parameter: string;
  value: number | string;
}

export interface IFixedIncomeAggregateDataMaximised {
  positionDate: string;
  nav: number;
  exposure: number;
  exposurePc: number;
  dv01: number;
  wam: number;
  wac: number;
  duration: number;
}
function renderAggParameter(rowData: IFixedIncomeAggregateDataMinimised) {
  switch (rowData.parameter) {
    case 'positionDate':
      return 'Position Date';
    case 'nav':
      return 'NAV';
    case 'exposure':
      return 'Exposure';
    case 'exposurePc':
      return 'Exposure %';
    case 'dv01':
      return 'DV01';
    case 'wam':
      return 'WAM';
    case 'wac':
      return 'WAC';
    case 'duration':
      return 'Duration';
    default:
      return '';
  }
}
function handleMaximisedTable(
  param: string,
  rowData: IFixedIncomeAggregateDataMaximised,
) {
  switch (param) {
    case 'positionDate':
      return rowData.positionDate;
    case 'nav':
      return addCommasToNumbersAndRound(rowData.nav);
    case 'exposure':
      return addCommasToNumbersAndRound(rowData.exposure);
    case 'exposurePc':
      return percentageToTwoDecimalPlaces(rowData.exposurePc);
    case 'dv01':
      return Math.round(rowData.dv01);
    case 'wam':
      return rowData.wam;
    case 'wac':
      return rowData.wac;
    case 'duration':
      return toTwoDecimalPlaces(rowData.duration);
    default:
      return addCommasToNumbersAndRound(rowData.nav);
  }
}

function renderAggValue(rowData: IFixedIncomeAggregateDataMinimised) {
  switch (rowData.parameter) {
    case 'positionDate':
      return rowData.value;
    case 'nav':
      return addCommasToNumbersAndRound(rowData.value);
    case 'exposure':
      return addCommasToNumbersAndRound(rowData.value);
    case 'exposurePc':
      return percentageToTwoDecimalPlaces(rowData.value as number);
    case 'dv01':
      return Math.round(rowData.value as number);
    case 'wam':
      return rowData.value;
    case 'wac':
      return rowData.value;
    case 'duration':
      return toTwoDecimalPlaces(rowData.value as number);
    default:
      return '';
  }
}
export const generateAggregateColumns = (
  width: number,
): CustomColumn<
  IFixedIncomeAggregateDataMinimised | IFixedIncomeAggregateDataMaximised
>[] => {
  if (width > 1919) {
    return [
      {
        title: 'Parameter',
        field: 'parameter',
        render: (rowData) =>
          renderAggParameter(rowData as IFixedIncomeAggregateDataMinimised),
      },
      {
        title: 'Value',
        field: 'value',
        headerStyle: {
          textAlign: 'center',
        },
        cellStyle: {
          textAlign: 'center',
        },
        render: (rowData) =>
          renderAggValue(rowData as IFixedIncomeAggregateDataMinimised),
      },
    ];
  }
  return [
    {
      field: 'positionDate',
      title: 'Position Date',
    },
    {
      title: 'NAV',
      field: 'nav',
      render: (rowData) =>
        handleMaximisedTable(
          'nav',
          rowData as IFixedIncomeAggregateDataMaximised,
        ),
    },
    {
      title: 'Exposure',
      field: 'exposure',
      render: (rowData) =>
        handleMaximisedTable(
          'exposure',
          rowData as IFixedIncomeAggregateDataMaximised,
        ),
    },
    {
      title: 'Exposure (%)',
      field: 'exposurePc',
      render: (rowData) =>
        handleMaximisedTable(
          'exposurePc',
          rowData as IFixedIncomeAggregateDataMaximised,
        ),
    },
    {
      title: 'DV01',
      field: 'dv01',
      render: (rowData) =>
        handleMaximisedTable(
          'dv01',
          rowData as IFixedIncomeAggregateDataMaximised,
        ),
    },
    {
      title: 'WAM',
      field: 'wam',
      render: (rowData) =>
        handleMaximisedTable(
          'wam',
          rowData as IFixedIncomeAggregateDataMaximised,
        ),
    },
    {
      title: 'WAC',
      field: 'wac',
      render: (rowData) =>
        handleMaximisedTable(
          'wac',
          rowData as IFixedIncomeAggregateDataMaximised,
        ),
    },
    {
      title: 'Duration',
      field: 'duration',
      render: (rowData) =>
        handleMaximisedTable(
          'duration',
          rowData as IFixedIncomeAggregateDataMaximised,
        ),
    },
  ];
};

// map for checinking sort index
// These only include S&P credit ratings at the moment
const ordering: MapLike<number> = {
  AAA: 1,
  'AA+': 2,
  AA: 3,
  'AA-': 4,
  'A+': 5,
  A: 6,
  'A-': 7,
  'BBB+': 8,
  BBB: 9,
  'BBB-': 10,
  'BB+': 11,
  BB: 12,
  'BB-': 13,
  'B+': 14,
  B: 15,
  'B-': 16,
  'CCC+': 17,
  CCC: 18,
  'CCC-': 19,
  CC: 20,
  C: 21,
  D: 22,
  NR: 23,
};
// Custom sorting for credit ratings
function creditRatingSort(
  a: IFixedIncomePositionData,
  b: IFixedIncomePositionData,
) {
  const mappedAValue = ordering[a.creditRating]
    ? ordering[a.creditRating]
    : ordering.length + 1;
  const mappedBValue = ordering[b.creditRating]
    ? ordering[b.creditRating]
    : ordering.length + 1;
  return mappedAValue > mappedBValue ? 1 : -1;
}
// export const aggregateColumns: CustomColumn<IFixedIncomeAggregateData>[] =
export const positionColumns: CustomColumn<IFixedIncomePositionData>[] = [
  {
    title: '#',
    field: 'number',
    width: 1,
  },
  {
    title: 'Name',
    field: 'name',
    width: 150,
    pdfWidth: 15,
  },
  {
    title: 'ISIN',
    field: 'isin',
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: {
      textAlign: 'center',
    },
  },
  {
    title: 'Coupon',
    field: 'coupon',
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: {
      textAlign: 'center',
    },
  },
  {
    title: 'Maturity',
    field: 'maturity',
    headerStyle: {
      textAlign: 'center',
    },
    cellStyle: {
      textAlign: 'center',
    },
  },
  {
    title: 'Currency',
    field: 'currency',
    width: 1,
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: {
      textAlign: 'center',
    },
  },
  {
    title: 'Country Code',
    field: 'countryCode',
    width: 1,
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: {
      textAlign: 'center',
    },
  },
  {
    title: 'Years to Maturity',
    field: 'yearsToMaturity',
    render: (rowData) => toTwoDecimalPlaces(rowData.yearsToMaturity),
    headerStyle: {
      textAlign: 'center',
    },
    cellStyle: {
      textAlign: 'center',
    },
  },
  {
    title: 'Yield',
    field: 'yield',
    render: (rowData) => percentageToTwoDecimalPlacesNoHundred(rowData.yield),
  },
  {
    title: 'Price',
    field: 'price',
    render: (rowData) => toTwoDecimalPlaces(rowData.price),
    headerStyle: {
      textAlign: 'center',
    },
    cellStyle: {
      textAlign: 'center',
    },
  },
  {
    title: 'Position',
    field: 'position',
    render: (rowData) => addCommasToNumbersAndRound(rowData.position),
    headerStyle: {
      textAlign: 'center',
    },
    cellStyle: {
      textAlign: 'right',
    },
  },
  {
    title: 'Outstanding Securities',
    field: 'outstandingSecurities',
    render: (rowData) =>
      addCommasToNumbersAndRound(rowData.outstandingSecurities),
    headerStyle: {
      textAlign: 'center',
    },
    cellStyle: {
      textAlign: 'right',
    },
  },
  {
    title: 'Exposure',
    field: 'exposure',
    headerStyle: {
      textAlign: 'center',
    },
    cellStyle: {
      textAlign: 'center',
    },
    render: (rowData) =>
      percentageToTwoDecimalPlacesNoHundred(rowData.exposure),
  },
  {
    title: 'Duration (Years)',
    field: 'duration',
    headerStyle: {
      textAlign: 'center',
    },
    cellStyle: {
      textAlign: 'center',
    },
    render: (rowData) => toTwoDecimalPlaces(rowData.duration),
  },
  {
    title: 'DV01/MM',
    field: 'dv',
    headerStyle: {
      textAlign: 'center',
    },
    cellStyle: {
      textAlign: 'center',
    },

    render: (rowData) => numberToXDecimalPlaces(rowData.dv * 100, 4),
  },
  {
    title: 'Position DV01',
    field: 'positionDv',
    pdfRenderType: 'Round',
    render: (rowData) => Math.round(rowData.positionDv),
    headerStyle: {
      textAlign: 'center',
    },
    cellStyle: {
      textAlign: 'center',
    },
  },
  {
    title: 'Credit',
    field: 'creditRating',

    customSort: (a, b) => creditRatingSort(a, b),
    headerStyle: {
      textAlign: 'center',
    },
    cellStyle: {
      textAlign: 'center',
    },
  },
  {
    title: 'Convertible',
    field: 'convertible',

    headerStyle: {
      textAlign: 'center',
    },
    cellStyle: {
      textAlign: 'center',
    },
  },
  {
    title: 'AT1',
    field: 'isAT1',

    headerStyle: {
      textAlign: 'center',
    },
    cellStyle: {
      textAlign: 'center',
    },
  },
];

export function generatePositionTableData(
  data: BondDataServer[],
): IFixedIncomePositionData[] {
  if (!data.length) return [];
  const bondData = data[0].bond_data;
  const nav = data[0].nav;
  return bondData.map((item: any, index: number) => {
    return {
      number: index + 1,
      name: item.gm_name,
      isin: item.isin,
      coupon: item.interest_rate,
      maturity: item.maturity_date,
      currency: item.base_currency,
      countryCode: item.country_code,
      yearsToMaturity: item.time_to_maturity,
      yield: item.yield,
      price: item.used_underlying_price,
      position: item.raw_position,
      outstandingSecurities: item.outstanding_capital,
      exposure: (item.position_exposure * 100) / nav,
      duration: item.duration,
      dv: item.dv01,
      positionDv: item.position_dv01,
      creditRating: item.credit_rating,
      convertible: item.is_cb,
      isAT1: item.is_at1,
    };
  });
}

// export function generateAggregateData(data: BondDataServer[]): IFixedIncomeAggregateData[] {
//   if (!data.length) return []
//   const dataForUse = data[0]
//   return [
//     {
//       nav: dataForUse.nav,
//       exposure: dataForUse.corporate_exposure,
//       exposurePc: dataForUse.corporate_exposure / dataForUse.nav,
//       dv01: generateDvScore(dataForUse.dv01_maturity_data),
//       wam: 0,
//       wac: 0,
//       duration: dataForUse.duration
//     }
//   ]
// }

function generateDvScore(maturityData: any) {
  const dataForUse = [...maturityData];
  dataForUse.shift();
  return dataForUse.reduce((prev, current) => {
    return current[2] + prev;
  }, 0);
}

export function generateAggregateData(
  data: BondDataServer[],
  windowWidth: number,
): IFixedIncomeAggregateDataMinimised[] | IFixedIncomeAggregateDataMaximised[] {
  if (!data.length) return [];
  const dataForUse = data[0];
  if (windowWidth >= 1920) {
    const details: {
      [key: string]: string | number;
    } = {
      positionDate: dataForUse.selected_position_date,
      nav: dataForUse.nav,
      exposure: dataForUse.corporate_exposure,
      exposurePc: dataForUse.corporate_exposure / dataForUse.nav,
      dv01: generateDvScore(dataForUse.dv01_maturity_data),
      wam: dataForUse.portfolio_data[2],
      wac: dataForUse.portfolio_data[3],
      duration: dataForUse.duration,
    };

    return Object.keys(details).map((detail) => {
      return {
        parameter: detail,
        value: details[detail],
      };
    });
  }
  return [
    {
      positionDate: dataForUse.selected_position_date,
      nav: dataForUse.nav,
      exposure: dataForUse.corporate_exposure,
      exposurePc: dataForUse.corporate_exposure / dataForUse.nav,
      dv01: generateDvScore(dataForUse.dv01_maturity_data),
      wam: dataForUse.portfolio_data[2],
      wac: dataForUse.portfolio_data[3],
      duration: dataForUse.duration,
    },
  ];
}
