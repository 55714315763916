import {
  addCommasToNumbersAndRound,
  percentageToTwoDecimalPlacesNoHundred,
  toTwoDecimalPlaces,
} from '../../../../utilities/numberFormatters';
import { ExposureType } from './table/cisTable.component';
import { CustomColumn } from '../../../../types/components/tables/tableTypes';

export interface CisData {
  number: number;
  name: string;
  isin: string;
  sector: string;
  pcYield: number;
  currency: string;
  countryCode: string;
  forex: number;
  price: number;
  position: number;
  exposure: number;
  cashYield: number;
}

export interface CisServerData {
  cis_data: {
    local_currency: string;
    gm_name: string;
    isin: string;
    client_price: number;
    mr_position: number;
    cis_sector: string;
    country_code: string;
    forex_factor: number;
    raw_position: number;
    var: number;
    position_exposure: number;
  }[];
  nav: number;
}

export const generateCisColumns = (
  exposureType: ExposureType,
): CustomColumn<CisData>[] => {
  return [
    {
      title: '#',
      field: 'number',
      width: 1,
    },
    {
      title: 'Name',
      field: 'name',
    },
    {
      title: 'ISIN',
      field: 'isin',
      headerStyle: {
        textAlign: 'center',
      },
      cellStyle: {
        textAlign: 'center',
      },
    },
    {
      title: 'Sector',
      field: 'sector',
      headerStyle: {
        textAlign: 'center',
      },
      cellStyle: {
        textAlign: 'center',
      },
    },
    {
      title: '% Yield',
      field: 'pcYield',
      headerStyle: {
        textAlign: 'center',
      },
      cellStyle: {
        textAlign: 'center',
      },
      render: (rowData) => rowData.pcYield + '%',
    },
    {
      title: 'Currency',
      field: 'currency',
      headerStyle: {
        textAlign: 'center',
      },
      cellStyle: {
        textAlign: 'center',
      },
    },
    {
      title: 'Country Code',
      field: 'countryCode',
      headerStyle: {
        textAlign: 'center',
      },
      cellStyle: {
        textAlign: 'center',
      },
    },
    {
      title: 'Forex',
      field: 'forex',
      headerStyle: {
        textAlign: 'center',
      },
      cellStyle: {
        textAlign: 'center',
      },
      render: (rowData) => toTwoDecimalPlaces(rowData.forex),
    },
    {
      title: 'Price',
      field: 'price',
      headerStyle: {
        textAlign: 'center',
      },
      cellStyle: {
        textAlign: 'right',
      },
    },
    {
      title: 'Position',
      field: 'position',
      render: (rowData) => addCommasToNumbersAndRound(rowData.position),
      headerStyle: {
        textAlign: 'center',
      },
      cellStyle: {
        textAlign: 'right',
      },
    },
    {
      title: 'Exposure',
      field: 'exposure',
      render: (rowData) =>
        exposureType === 'netExposureBaseCurrency'
          ? addCommasToNumbersAndRound(rowData.exposure)
          : percentageToTwoDecimalPlacesNoHundred(rowData.exposure),

      headerStyle: {
        textAlign: 'center',
      },
      cellStyle: {
        textAlign: 'right',
      },
    },
    {
      title: 'Cash Yield',
      field: 'cashYield',
      headerStyle: {
        textAlign: 'center',
      },
      cellStyle: {
        textAlign: 'right',
      },
    },
  ];
};

export function generateCisTableData(
  inputData: CisServerData[],
  exposureType: ExposureType,
): CisData[] {
  if (!inputData.length) return [];
  const nav = inputData[0].nav;
  try {
    return inputData[0].cis_data.map((cisDatum, index) => {
      return {
        number: index + 1,
        name: cisDatum.gm_name,
        isin: cisDatum.isin,
        sector: cisDatum.cis_sector,
        pcYield: 0,
        currency: cisDatum.local_currency,
        countryCode: cisDatum.country_code,
        forex: cisDatum.forex_factor,
        price: cisDatum.client_price,
        position: cisDatum.raw_position,
        exposure:
          exposureType === 'netExposureBaseCurrency'
            ? cisDatum.position_exposure
            : (cisDatum.position_exposure / nav) * 100,
        cashYield: 0,
      };
    });
  } catch (error) {
    return [];
  }
}
