import { ReactElement } from 'react';
import { DataObject } from '../../../../../types/redux/data/dataTypes';
import { Dimensions } from '../../riskEvolution/charts/portfolioLiquidation/portfolioLiquidation.component';
import { buildRstData } from './rstChart.data';
import GenericBarChart from '../../../../charts/GenericBarChart';
import { mainColors } from '../../../../../styling/theme';
import withChartWrapper from '../../riskEvolution/charts/withChartWrapper';
import { useHistory } from 'react-router-dom';
import { useTheme } from '@mui/material';
import { specifyStatus } from '../../../../../utilities/generalMappings';

interface Props extends Dimensions {
  inputData: DataObject;
  title: string;
  heightAndWidth: { height: number; width: number | string };
  fundId: string;
}

function RstChart({
  heightAndWidth,
  inputData,
  title,
  fundId,
}: Props): ReactElement {
  const { data, isFetching, error } = inputData;
  const chartData = buildRstData(data);
  const formatter = (
    value: string | number | Array<string | number>,
    name: string,
  ) => {
    return [value, specifyStatus(name)];
  };
  const theme = useTheme();
  const history = useHistory();

  return (
    <GenericBarChart
      height={heightAndWidth.height}
      title={title}
      error={error}
      width={heightAndWidth.width}
      tooltipFormatter={formatter}
      loading={isFetching}
      margin={{ top: 0, bottom: 30, left: 30, right: 30 }}
      bars={[
        {
          dataKey: 'fail',
          fill: mainColors.Fail,
          key: `${title}dataKeyFail`,
          stackId: 'a',
        },
        {
          dataKey: 'alert',
          fill: mainColors.Alert,
          key: `${title}dataKeyAlert`,
          stackId: 'a',
        },

        {
          dataKey: 'pass',
          fill: mainColors.Pass,
          key: `${title}dataKeyPass`,
          stackId: 'a',
        },
      ].map((bar) => ({
        ...bar,
        onClick: (e) => {
          history.push(`rst-fixed?fundId=${fundId}&positionDate=${e.name}`);
        },
      }))}
      yAxes={[
        {
          label: {
            angle: -90,
            offset: -20,
            position: 'left',
            value: '# Redemptions',
            style: {
              fill: theme.palette.grey[700],
              textAnchor: 'middle',
            },
          },
        },
      ]}
      dontVaryBarColor
      data={chartData}
      xAxes={[
        {
          dataKey: 'name',
          interval: Math.round(chartData.length / 15),
          label: {
            value: 'Date',
            style: {
              fill: theme.palette.grey[700],
            },
            position: 'bottom',
          },
        },
      ]}
    />
  );
}

export default withChartWrapper(RstChart);
