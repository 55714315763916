import { useState } from 'react';
import html2canvas from 'html2canvas';

const usePngFromRecharts = () => {
  const [refNode, setRefNode] = useState<any>(null);
  const ref = (node: any) => {
    setRefNode(node);
  };

  const handleDownload = async () => {
    if (refNode !== null && refNode?.container) {
      const data = await html2canvas(refNode.container as HTMLElement).then(
        (canvas) => canvas.toDataURL('image/png', 1.0),
      );
      return {
        data,
        width: refNode.container.getBoundingClientRect().width,
        height: refNode.container.getBoundingClientRect().height,
      };
    }
    return null;
  };

  return { ref, handleDownload };
};

export default usePngFromRecharts;
