import { ColumnDef, Row, RowData, Table } from '@tanstack/react-table';
import { KeyboardArrowDown, KeyboardArrowRight } from '@mui/icons-material';
import { cloneDeep } from 'lodash';
import {
  ifNumberAddCommasAndRound,
  roundToTwoDecimalPlacesReturnNumber,
} from '../../../../utilities/numberFormatters';
import categoryProvider from '../../../../utilities/categoryProvider';
import { ColumnData } from '../types/column.types';
import { Position } from '../types/position.types';
import { EditablePosition } from '../../../pages/sharedComponents/ExposureFund';
import { EditableFieldsProperties } from '../components/EditableCell';

declare module '@tanstack/react-table' {
  //allows us to define custom properties for our columns
  interface ColumnMeta<TData extends RowData, TValue> {
    filterVariant?: 'text' | 'range' | 'select';
    short_title?: string;
    description?: string;
    editable?: EditableFieldsProperties;
  }
}

export const roundToXDecimalPlacesReturnNumber = (
  number: number | string,
  X: number,
): number => {
  if (number === '' || !number) return 0;
  return typeof number === 'string'
    ? Number(parseFloat(number).toFixed(X))
    : Number(number.toFixed(X));
};

// I hate this code
// I have had to do these checks because data from the backend is both type inconsistent and inconsistently present
const hardcodedEnsureCorrectTypes = (
  positionsData: EditablePosition[],
  nav: number,
) => {
  positionsData.map((position) => {
    if (!position.yield || typeof position.yield !== 'number') {
      position.yield = 0;
    }
    if (!position.coupon || typeof position.coupon !== 'number') {
      position.coupon = 0;
    }
    if (
      !position.outstanding_securities ||
      typeof position.outstanding_securities !== 'number'
    ) {
      position.outstanding_securities = 0;
    }
    if (!position.duration || typeof position.duration !== 'number') {
      position.duration = 0;
    }
    if (!position.risk_factor || typeof position.risk_factor !== 'number') {
      position.risk_factor = 0;
    }
    if (!position.interest_rate || typeof position.interest_rate !== 'number') {
      position.interest_rate = 0;
    }
    if (
      !position.time_to_maturity ||
      typeof position.time_to_maturity !== 'number'
    ) {
      position.time_to_maturity = 0;
    }
    if (
      !position.days_to_maturity ||
      typeof position.days_to_maturity !== 'string'
    ) {
      position.days_to_maturity = 'N/A';
    }
    if (!position.cs01 || typeof position.cs01 !== 'number') {
      position.cs01 = 0;
    }
    if (!position.dv01 || typeof position.dv01 !== 'number') {
      position.dv01 = 0;
    }
    if (
      !position.sector_name ||
      typeof position.sector_name !== 'string' ||
      position.sector_name === 'null'
    ) {
      position.sector_name = 'N/A';
    }
    if (
      !position.bc_exposure_pct ||
      typeof position.bc_exposure_pct !== 'number'
    ) {
      if (position.bc_exposure) {
        position.bc_exposure_pct = (position.bc_exposure / nav) * 100;
      } else {
        position.bc_exposure_pct = 0;
      }
    }
    if (
      !position.lc_exposure_pct ||
      typeof position.lc_exposure_pct !== 'number'
    ) {
      if (position.lc_exposure) {
        position.lc_exposure_pct = (position.lc_exposure / nav) * 100;
      } else {
        position.lc_exposure_pct = 0;
      }
    }
    if (
      !position.gross_exposure_pct ||
      typeof position.gross_exposure_pct !== 'number'
    ) {
      if (position.gross_exposure) {
        position.gross_exposure_pct = (position.gross_exposure / nav) * 100;
      } else {
        position.gross_exposure_pct = 0;
      }
    }
    if (
      !position.commitment_pct ||
      typeof position.commitment_pct !== 'number'
    ) {
      if (position.commitment) {
        position.commitment_pct = (position.commitment / nav) * 100;
      } else {
        position.commitment_pct = 0;
      }
    }
    if (
      !position.msci_sector_name ||
      typeof position.msci_sector_name !== 'string'
    ) {
      position.msci_sector_name = 'null';
    }
    if (!position.msci_sector_code) {
      position.msci_sector_code = 'N/A';
    } else if (typeof position.msci_sector_code !== 'string') {
      position.msci_sector_code = String(position.msci_sector_code);
    }
    if (!position.sector_id) {
      position.sector_id = 'N/A';
    } else if (typeof position.sector_id !== 'string') {
      position.sector_id = String(position.sector_id);
    }
    if (
      !position.counterparty_names ||
      typeof position.counterparty_names !== 'string'
    ) {
      position.counterparty_names = 'N/A';
    }
    if (!position.maturity_date || typeof position.maturity_date !== 'string') {
      position.maturity_date = 'N/A';
    }
    if (!position.isin || typeof position.isin !== 'string') {
      position.isin = 'N/A';
    }
    if (!position.is_coco || typeof position.is_coco !== 'string') {
      position.is_coco = '';
    }
    if (!position.is_at1 || typeof position.is_at1 !== 'string') {
      position.is_at1 = '';
    }
    if (
      !position.instrument_title ||
      typeof position.instrument_title !== 'string'
    ) {
      position.instrument_title = 'N/A';
    }
    if (
      !position.instrument_description_title ||
      typeof position.instrument_description_title !== 'string'
    ) {
      position.instrument_description_title = 'N/A';
    }
  });
  return positionsData;
};

// this function is used to build position data
function buildPositionObject(obj: any, fundKey: string, nav: number) {
  const newObj: Position = {
    ...obj,
    instrument_title: obj['instrument_title'],
    instrument_description_title: obj['instrument_description_title'],
    asset_class: obj['asset class'],
    assetInformation: obj['underlying_assets'],
    asset_type: obj['asset_type'],
    currency: obj['currency'],
    fx: roundToXDecimalPlacesReturnNumber(obj['fx'], 3),
    lc_exposure: roundToTwoDecimalPlacesReturnNumber(obj['lc_exposure']),
    lc_exposure_pct: roundToTwoDecimalPlacesReturnNumber(
      obj['lc_exposure_pct'],
    ),
    bc_exposure: roundToTwoDecimalPlacesReturnNumber(obj['bc_exposure']),
    bc_exposure_pct: roundToTwoDecimalPlacesReturnNumber(
      obj['bc_exposure_pct'],
    ),
    gross_exposure: roundToTwoDecimalPlacesReturnNumber(obj['gross_exposure']),
    gross_exposure_pct: roundToTwoDecimalPlacesReturnNumber(
      obj['gross_exposure_pct'],
    ),
    commitment: roundToTwoDecimalPlacesReturnNumber(obj['commitment']),
    commitment_pct: roundToTwoDecimalPlacesReturnNumber(obj['commitment_pct']),
    country_code: obj['country_code'],
    country_code_risk_level: obj['country_code_risk_level'],
    sector_name: obj['sector_name'],
    is_listed: obj['is_listed'],
    cat_type_name: 'cat_type_name' in obj ? obj['cat_type_name'] : '',
    portfolio_id_name:
      'portfolio_id_name' in obj ? obj['portfolio_id_name'] : '',
    client_price: roundToTwoDecimalPlacesReturnNumber(obj['client_price']),
    da_son_exposure: roundToTwoDecimalPlacesReturnNumber(
      obj['da_son_exposure'],
    ),
    notional_amount: roundToTwoDecimalPlacesReturnNumber(
      obj['notional_amount'],
    ),
    price: roundToTwoDecimalPlacesReturnNumber(obj['price']),
    sector_id: obj['sector_id'],
    sum_of_notionals: roundToTwoDecimalPlacesReturnNumber(
      obj['sum_of_notionals'],
    ),
    time_to_maturity: roundToTwoDecimalPlacesReturnNumber(
      obj['time_to_maturity'],
    ),
    cs01: roundToTwoDecimalPlacesReturnNumber(obj['cs01']),
    dv01: roundToTwoDecimalPlacesReturnNumber(obj['dv01']),
    unrealised_pl: roundToTwoDecimalPlacesReturnNumber(obj['unrealised_pl']),
    counterparty_names:
      'counterparty_names' in obj ? obj['counterparty_names'].toString() : '',
    // the positon level key is new and has changed many times since creating it, so we have to do checks to ensure backwards compatibility
    // this is not ideal and should be something we look to resolve in the future, by creating data models and a system for easily re-running
    // old dates to retrospectively add new keys
    position_level: handlePositionLevelBackwardsCompatibility(
      obj.position_level,
      obj.underlying_assets,
    ),
  };

  return newObj;
}

const handlePositionLevelBackwardsCompatibility = (
  position_level: any,
  underlying_assets: any,
): EditablePosition[] => {
  // the premise for this function is;
  // if there is no required key inside the position_level key
  // then get the item from the underlying_assets key, using position_id to match
  const handleFindMatchingFallback = (
    underlying_assets: any[][],
    position_id: string | undefined,
    index: number,
  ) => {
    const match = underlying_assets.find((el) => {
      return el[13] === position_id;
    });
    return match ? match[index] : '';
  };

  if (position_level) {
    return position_level.map((asset: EditablePosition) => {
      return {
        ...asset,
        name: asset.name
          ? asset.name
          : handleFindMatchingFallback(underlying_assets, asset.position_id, 0),
        instrument_title: asset.instrument_title || 'N/A',
        client_price: asset.client_price
          ? asset.client_price
          : handleFindMatchingFallback(underlying_assets, asset.position_id, 1),
        delta: asset.delta
          ? asset.delta
          : handleFindMatchingFallback(underlying_assets, asset.position_id, 2),
        position_size: asset.position_size
          ? asset.position_size
          : handleFindMatchingFallback(underlying_assets, asset.position_id, 3),
        risk_factor: asset.risk_factor
          ? asset.risk_factor
          : handleFindMatchingFallback(underlying_assets, asset.position_id, 4),
        bc_exposure: asset.bc_exposure
          ? asset.bc_exposure
          : handleFindMatchingFallback(underlying_assets, asset.position_id, 6),
        commitment: asset.commitment
          ? asset.commitment
          : handleFindMatchingFallback(underlying_assets, asset.position_id, 8),
        fund_id: asset.fund_id
          ? asset.fund_id
          : handleFindMatchingFallback(
              underlying_assets,
              asset.position_id,
              17,
            ),
        fund_name_long: asset.fund_name_long
          ? asset.fund_name_long
          : handleFindMatchingFallback(
              underlying_assets,
              asset.position_id,
              19,
            ),
      };
    });
  } else if (underlying_assets) {
    return underlying_assets.map((asset: any[]) => {
      return {
        name: asset[0],
        client_price: asset[1],
        delta: asset[2],
        position_size: asset[3],
        risk_factor: asset[4],
        bc_exposure: asset[6],
        commitment: asset[8],
        fund_id: asset[17],
        fund_name_long: asset[19],
      };
    });
  } else {
    return [];
  }
};

const buildAggregateAssetTypePosition = (
  category: 1 | 2 | 3 | 4 | 5 | 6,
  totalExposure: number,
  totalGrossExposure_pc: number,
  totalCommitment: number,
  totalCommitment_pc: number,
  totalLocalExposure: number,
  totalLocalExposure_pc: number,
  totalBaseExposure: number,
  totalBaseExposure_pc: number,
) => {
  const topLevelObj: Position = {};
  topLevelObj.name = categoryProvider(category)[1] + ' (Aggregation)';
  topLevelObj.asset_type = 'aggregate';
  topLevelObj.asset_class = categoryProvider(category)[1];
  topLevelObj.gross_exposure =
    roundToTwoDecimalPlacesReturnNumber(totalExposure);
  topLevelObj.gross_exposure_pct = roundToTwoDecimalPlacesReturnNumber(
    totalGrossExposure_pc,
  );
  topLevelObj.commitment = roundToTwoDecimalPlacesReturnNumber(totalCommitment);
  topLevelObj.commitment_pct =
    roundToTwoDecimalPlacesReturnNumber(totalCommitment_pc);
  topLevelObj.lc_exposure =
    roundToTwoDecimalPlacesReturnNumber(totalLocalExposure);
  topLevelObj.lc_exposure_pct = roundToTwoDecimalPlacesReturnNumber(
    totalLocalExposure_pc,
  );
  topLevelObj.bc_exposure =
    roundToTwoDecimalPlacesReturnNumber(totalBaseExposure);
  topLevelObj.bc_exposure_pct =
    roundToTwoDecimalPlacesReturnNumber(totalBaseExposure_pc);
  topLevelObj.assetInformation = [];
  // topLevelObj.pricing_assetsubclass = '';
  topLevelObj.isin = '';
  topLevelObj.country_code = '';
  topLevelObj.country_code_risk_level = 0;
  topLevelObj.sector_name = '';
  topLevelObj.is_listed = '';
  topLevelObj.aml_exposure = 0;
  topLevelObj.cat_type_name = '';
  topLevelObj.portfolio_id_name = '';
  topLevelObj.currency = '';
  topLevelObj.fx = 0;
  topLevelObj.asset_form = '';
  topLevelObj.cis = '';
  topLevelObj.client_price = 0;
  topLevelObj.credit_rating = '';
  topLevelObj.credit_type = '';
  topLevelObj.currency_code = '';
  topLevelObj.da_son_exposure = 0;
  topLevelObj.days_to_maturity = '';
  topLevelObj.g_key = '';
  topLevelObj.g_name = '';
  topLevelObj.gm_id_new = 0;
  topLevelObj.icb_sector_id = 0;
  topLevelObj.icb_sector_name = '';
  topLevelObj.is_cb = '';
  topLevelObj.is_cds = '';
  topLevelObj.is_etf = '';
  topLevelObj.is_loan = '';
  topLevelObj.is_sfi = '';
  topLevelObj.is_coco = '';
  topLevelObj.is_at1 = '';
  topLevelObj.maturity_date = '';
  topLevelObj.msci_sector_code = '';
  topLevelObj.msci_sector_id = 0;
  topLevelObj.msci_sector_name = '';
  topLevelObj.notional_amount = 0;
  topLevelObj.portfolio_id = 0;
  topLevelObj.price = 0;
  topLevelObj.sector_id = '';
  topLevelObj.sum_of_notionals = 0;
  topLevelObj.total_issue_size = '';
  // topLevelObj.interest_type = '';
  topLevelObj.unrealised_pl = 0;
  topLevelObj.interest_rate = 0;
  topLevelObj.time_to_maturity = 0;
  topLevelObj.yield = 0;
  topLevelObj.time_to_maturity = 0;
  topLevelObj.dv01 = 0;
  topLevelObj.cs01 = 0;
  topLevelObj.risk_factor = 0;
  topLevelObj.risk_factor = 0;
  topLevelObj.outstanding_securities = 0;
  topLevelObj.counterparty_names = '';
  return topLevelObj;
};

// this function is used to build all positions data for a given category
// can be aggregate level or individual level
function createCategoryData(
  inputArrayOfCategoryPositions: any,
  category: 1 | 2 | 3 | 4 | 5 | 6,
  nav: number,
  tableAggregate: boolean,
) {
  const collectionOfCategoryObjs: any = [];

  // define the aggregate level values for the category
  let totalExposure = 0;
  let totalExposure_pc = 0;
  let totalCommitment = 0;
  let totalCommitment_pc = 0;
  let totalLocalExposure = 0;
  let totalLocalExposure_pc = 0;
  let totalBaseExposure = 0;
  let totalBaseExposure_pc = 0;

  // for each position, build the required data
  inputArrayOfCategoryPositions.forEach((fund: any, index: number) => {
    totalExposure += fund[1]['gross_exposure'];
    totalExposure_pc += (fund[1]['gross_exposure'] / nav) * 100;
    totalCommitment += fund[1]['commitment'];
    totalCommitment_pc += (fund[1]['commitment'] / nav) * 100;
    totalLocalExposure += fund[1]['lc_exposure'];
    totalLocalExposure_pc += (fund[1]['lc_exposure'] / nav) * 100;
    totalBaseExposure += fund[1]['bc_exposure'];
    totalBaseExposure_pc += (fund[1]['bc_exposure'] / nav) * 100;
    const builtObj = buildPositionObject(fund[1], fund[0], nav);

    // This is just for debugging the issue of child keys not being in the parent
    const childKeysNotFoundInParent: string[] = [];
    //

    if (!tableAggregate) {
      // if individual level
      if (builtObj.position_level?.length) {
        builtObj.position_level.forEach((underlyingPosition: any) => {
          const newIndividualObject: any = cloneDeep(builtObj);
          Object.keys(underlyingPosition).forEach((key) => {
            // this here is the problematic part, as if the key isn't in the parent level then it isn't filled from the child

            // howeveer removing it causes really bad lag for some reason
            if (Object.keys(builtObj).includes(key)) {
              newIndividualObject[`${key}`] = underlyingPosition[`${key}`];
              if (typeof newIndividualObject[`${key}`] === 'number') {
                newIndividualObject[`${key}`] =
                  roundToTwoDecimalPlacesReturnNumber(
                    newIndividualObject[`${key}`],
                  );
              }
            } else if (!childKeysNotFoundInParent.includes(key)) {
              childKeysNotFoundInParent.push(key);
            }
          });
          collectionOfCategoryObjs.push(newIndividualObject);
        });
      } else {
        collectionOfCategoryObjs.push(builtObj);
      }
    } else {
      // if aggregate level
      collectionOfCategoryObjs.push(builtObj);
    }
  });

  if (tableAggregate) {
    // build the aggregate row for the asset class category
    collectionOfCategoryObjs.unshift(
      buildAggregateAssetTypePosition(
        category,
        totalExposure,
        totalExposure_pc,
        totalCommitment,
        totalCommitment_pc,
        totalLocalExposure,
        totalLocalExposure_pc,
        totalBaseExposure,
        totalBaseExposure_pc,
      ),
    );
  }

  return collectionOfCategoryObjs;
}

// this function is used to format the input data ready for the table
// there is a lot of legacy code here, and could benefit from having alot of the logic moved to the backend
export function buildData(data: any, tableAggregate: boolean) {
  let mainData: any = [];

  // this is a hacky change in order to allow for both manco and fund level exposure data
  const exposure_data: any = data.exposure_data ?? data.manco_exposure_data;

  const keys = Object.keys(exposure_data);

  // get the overall nav of the fund
  const nav = data.nav ?? data.manco_nav;

  // create the asset class categories, this relates to the RiskSystem identification numbers codes
  type categories = 1 | 2 | 3 | 4 | 5 | 6;
  const arraysOfCategories: any = {
    1: [],
    2: [],
    3: [],
    4: [],
    5: [],
    6: [],
  };
  keys.forEach((key) => {
    const elementToPush = [key, exposure_data[key]];
    arraysOfCategories[key[0]].push(elementToPush);
  });

  // for each category in fund, create category data
  Object.keys(arraysOfCategories).forEach((category) => {
    if (arraysOfCategories[category].length) {
      mainData = [
        ...mainData,
        ...createCategoryData(
          arraysOfCategories[category],
          category as unknown as categories,
          nav,
          tableAggregate,
        ),
      ];
    }
  });

  return hardcodedEnsureCorrectTypes(mainData, nav);
}

export const buildColumns = (
  tableAggregate: boolean,
  columns: ColumnData[],
  classes: any,
) => {
  const cols: ColumnDef<EditablePosition>[] = [];

  const expandAllNonAggregatePositions = (
    table: Table<EditablePosition>,
  ): void => {
    table.getRowModel().rows.map((row) => {
      if (row.original.asset_type !== 'aggregate' && row.getCanExpand()) {
        row.toggleExpanded();
      }
    });
  };

  const getIsAllNonAggregateRowsExpanded = (
    table: Table<EditablePosition>,
  ): boolean => {
    const allNonAggregateRows: Row<EditablePosition>[] = [];
    table.getRowModel().rows.map((row) => {
      if (row.original.asset_type !== 'aggregate' && row.getCanExpand()) {
        allNonAggregateRows.push(row);
      }
    });
    let numberExpanded = 0;
    allNonAggregateRows.forEach((nonAggregateRow) => {
      if (nonAggregateRow.getIsExpanded()) {
        numberExpanded += 1;
      }
    });
    return numberExpanded === allNonAggregateRows.length;
  };

  if (tableAggregate) {
    cols.push({
      id: 'index',
      size: 60,
      enableResizing: false,
      enablePinning: true,
      header: ({ table }) => (
        <span style={{ fontSize: '1.4rem' }}>{table.getRowCount()}</span>
      ),
      cell: ({ table, row }) => {
        const filteredRowIndex =
          table.getFilteredRowModel().rows.findIndex((r) => r.id === row.id) +
          1;

        return row.original.asset_type !== 'aggregate' && row.getCanExpand() ? (
          <div
            {...{
              onClick: row.getToggleExpandedHandler(),
              style: {
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
              },
            }}
          >
            {row.getIsExpanded() ? (
              <KeyboardArrowDown
                className={classes.expanderIcon}
                style={{ fontWeight: 800 }}
              />
            ) : (
              <KeyboardArrowRight
                className={classes.expanderIcon}
                style={{ fontWeight: 800 }}
              />
            )}
            <span style={{ fontWeight: 600 }}>{filteredRowIndex}</span>
          </div>
        ) : (
          <span style={{ fontWeight: 600 }}>{filteredRowIndex}</span>
        );
      },
    });
  } else {
    cols.push({
      id: 'index',
      size: 60,
      enableResizing: false,
      enableColumnFilter: false,
      enablePinning: true,
      header: ({ table }) => (
        <span style={{ fontSize: '1.4rem' }}>{table.getRowCount()}</span>
      ),
      cell: ({ table, row }) => {
        const filteredRowIndex =
          table.getFilteredRowModel().rows.findIndex((r) => r.id === row.id) +
          1;

        return <span style={{ fontWeight: 600 }}>{filteredRowIndex}</span>;
      },
    });
  }

  columns.forEach((dc: ColumnData) => {
    cols.push({
      id: dc.id,
      accessorKey: dc.id,
      header: dc.title,
      footer: dc.id,
      ...(dc.enableResizing && { enableResizing: dc.enableResizing }),
      ...(dc.enableColumnFilter && {
        enableColumnFilter: dc.enableColumnFilter,
      }),

      minSize: dc.minSize,
      ...(dc.filterFn && { filterFn: dc.filterFn }),
      meta: {
        filterVariant: dc.filterVariant,
        short_title: dc.short_title ? dc.short_title : dc.title,
      },
      ...(dc.size && { size: dc.size }),
      cell: (props) => {
        // handle for id columns (they shouldn't receive commas)
        if (
          dc.id === 'icb_sector_id' ||
          dc.id === 'msci_sector_id' ||
          dc.id === 'sector_id'
        ) {
          return props.getValue();
        } else {
          return ifNumberAddCommasAndRound(props.getValue());
        }
      },
    });
  });

  return cols;
};

type BooleanObject = {
  [key: string]: boolean;
};

export function countTrueValues(obj: BooleanObject): number {
  // Initialize a counter for true values
  let count = 0;
  // Iterate over each property in the object using Object.keys
  // We are skipping the index property as its max size is always 60px
  Object.keys(obj).forEach((key) => {
    if (obj[key] === true && key !== 'index') {
      count++;
    }
  });

  return count;
}
