import React from 'react';
import CustomTable from '../../../../tables/CustomTable';
import { CustomColumn } from '../../../../../types/components/tables/tableTypes';
import GridItem from '../../../../layout/GridComponents/GridItem';
import { useSelector } from 'react-redux';
import {
  activeSectionSelector,
  createActiveFundSelectorBySection,
} from '../../../../../redux/pages/selectors';
import { GridSize } from '@mui/material';

interface FixedRstTableProps<T extends object> {
  columns: CustomColumn<T>[];
  title: string;
  data?: T[];
  fixed?: GridSize;
  positionDate: string;
  scenario?: string;
}
const FixedRstTable: <T extends object>(
  props: FixedRstTableProps<T>,
) => React.ReactElement = (props) => {
  const activeSection = useSelector(activeSectionSelector);
  const fundDetails = useSelector(
    createActiveFundSelectorBySection(activeSection),
  );
  const { columns, title } = props;
  return (
    <GridItem
      xs={12}
      lg={props.fixed || 4}
      style={{ alignSelf: 'flex-start' }}
      card
      cardStyle={{ height: '100%' }}
    >
      <CustomTable
        columns={columns}
        showToolbar={true}
        noteExport={{
          prependWithDetail: props.scenario,
          fundId: fundDetails?.id || '',
          subject: 'ucits_rst-fixed',
          topic: title,
          positionDate: props.positionDate,
          shouldCall: false,
        }}
        toolbarComponents={{
          toolbarTitle: title,
        }}
        csvFields={columns.map((col: any) => col.field)}
        options={{
          rowStyle: {
            height: '8rem',
          },
          exportButton: true,
          paging: false,
        }}
        data={props.data || []}
      />
    </GridItem>
  );
};

export default FixedRstTable;
