import React from 'react';
import { GeneralOverviewPageRenderedComponentProps } from '../../../../types/components/componentTypes';
import OverallStatusPage from '../../sharedComponents/overallStatusPage/component';

const UcitsLawOverview: React.FC<GeneralOverviewPageRenderedComponentProps> = (
  props,
) => {
  return (
    <OverallStatusPage
      overviewDataPageKey="UCITS"
      positionDate={props.positionDate}
    />
  );
};

export default UcitsLawOverview;
