import React, { useEffect } from 'react';
import {
  Redirect,
  Route,
  RouteComponentProps,
  RouteProps,
} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { isLoggedInSelector } from '../redux/auth/selectors';
import { updateSection } from '../redux/pages/actions';

export interface LoggedInRouteProps<P> extends RouteProps {
  Component: React.ComponentType<RouteComponentProps<P>>;
}

interface WrappedComponentProps<P> extends LoggedInRouteProps<P> {
  otherProps: any;
}

function WrappedComponent<P>(props: WrappedComponentProps<P>) {
  const { Component, otherProps } = props;

  // console.log(props);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(updateSection(props.otherProps.match.params.section));
  }, []);

  return <Component {...otherProps} />;
}

function LoggedInRoute<P>(props: LoggedInRouteProps<P>) {
  const { Component } = props;
  //will check auth state
  const isAuthenticated = useSelector(isLoggedInSelector);

  return (
    <Route
      {...props}
      render={(otherProps) =>
        isAuthenticated ? (
          <WrappedComponent otherProps={otherProps} Component={Component} />
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
}
export default LoggedInRoute;
