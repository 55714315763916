import makeStyles from '@mui/styles/makeStyles';
import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { RaptorTheme, greys, mainColors } from '../../../../styling/theme';
import { GeneralOverviewPageRenderedComponentProps } from '../../../../types/components/componentTypes';
import { CustomColumn } from '../../../../types/components/tables/tableTypes';
import { Status } from '../../../../types/redux/data/dataTypes';
import {
  addCommasToNumbersAndRound,
  percentageToTwoDecimalPlaces,
} from '../../../../utilities/numberFormatters';
import GeneralComponentErrorShield from '../../../general/GeneralComponentErrorShield';
import GridItem from '../../../layout/GridComponents/GridItem';
import CustomTable from '../../../tables/CustomTable';
import useFetchData from '../../../../hooks/useFetchData';
import { createSectionByIdSelector } from '../../../../redux/pages/selectors';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { BASE_URL } from '../../../../utilities/requestClient';
import { Button } from '@mui/material';
import { specifyStatus } from '../../../../utilities/generalMappings';

interface PageLinkButtonProps {
  status: number | Status;
  text?: string;
  section?: string;
  page?: string;
  fundId?: string;
}

interface ReportButtonProps {
  fundId: string;
  positionDate: string;
  fundName: string;
}

function openInNewTab(url: string) {
  return async () => {
    const win = window.open(url, '_blank');
    if (win) {
      win.focus();
      return true;
    } else {
      return false;
    }
  };
}

function getGradinetColor(score: number) {
  if (score < 900)
    return { main: mainColors.Pass, hover: mainColors.Pass_darker };
  else if (score < 1000)
    return { main: mainColors.Alert, hover: mainColors.Alert_darker };
  else return { main: mainColors.Fail, hover: mainColors.Fail_darker };
}

function getStatusColors(value: Status) {
  switch (value) {
    case Status.Pass:
      return { main: mainColors.Pass, hover: mainColors.Pass_darker };
    case Status.Alert:
    case Status.PassAlert:
      return { main: mainColors.Alert, hover: mainColors.Alert_darker };
    case Status.Fail:
      return { main: mainColors.Fail, hover: mainColors.Fail_darker };
    case Status.NA:
    default:
      return { main: greys.grey400, hover: greys.grey400 };
  }
}

interface StyleProps {
  mainColor: string;
  mainColorHover: string;
}

const useStyles = makeStyles<RaptorTheme, StyleProps>((theme) => ({
  PageLinkButtonRoot: {
    backgroundColor: (props) => props.mainColor,
    color: 'white',
    width: '100%',
    maxWidth: '20rem',
    '&:hover': {
      backgroundColor: (props: any) => props.mainColorHover,
    },
  },
  PageLinkButtonRootDisabled: {
    backgroundColor: (props) => greys.grey400,
    color: 'black',
    width: '100%',
    maxWidth: '20rem',
  },
  statusContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  nonLinkStatusBox: {
    backgroundColor: (props) => props.mainColor,
    color: 'white',
    width: '100%',
    maxWidth: '20rem',
    borderRadius: '0.4rem',
    padding: '0.8rem',
    userSelect: 'none',
  },
}));

export function PEPageLinkButton({
  status,
  section,
  page,
  fundId,
}: React.PropsWithChildren<PageLinkButtonProps>): ReactElement {
  const handleClick = () => {
    //TODO manage what clicking on this button does
    //   if (props.additionalHandler) props.additionalHandler();
  };
  const color =
    typeof status === 'number'
      ? getGradinetColor(status)
      : getStatusColors(status);
  const classes = useStyles({
    mainColor: color.main,
    mainColorHover: color.hover,
  });

  return section && page ? (
    <Link to={`/${section}/${page}${fundId ? `?fundId=${fundId}` : ``}`}>
      <Button onClick={handleClick} className={classes.PageLinkButtonRoot}>
        {typeof status === 'number'
          ? percentageToTwoDecimalPlaces(status)
          : status}
      </Button>
    </Link>
  ) : (
    <div className={classes.statusContainer}>
      <div className={classes.nonLinkStatusBox}>
        {typeof status === 'number'
          ? percentageToTwoDecimalPlaces(status)
          : status.toUpperCase()}
      </div>
    </div>
  );
}

export function PEReportButton({
  fundId,
  positionDate,
  fundName,
}: React.PropsWithChildren<ReportButtonProps>): ReactElement {
  const [buttonColour, setButtonColour] = React.useState(
    mainColors.controlButtonBlue,
  );
  const [isButtonDisabled, setIsButtonDisabled] = React.useState(false);

  const requestClient = axios.create({
    withCredentials: true,
    baseURL: BASE_URL,
  });

  // For the moment we include all available pages
  // In future we may be able to include or exclude any pages
  const pagesToInclude = [
    'summary',
    'exposure',
    'prospectus',
    'discount_cashflow_overview',
    'cashflow_stress_tests',
    'monte_carlo',
    'proxy_model',
    'stress_tests',
    'quantile_analysis',
    'liquidity_discount_analysis',
    'lvar',
  ];

  function generateReport() {
    setButtonColour(mainColors.inactiveGrey);
    setIsButtonDisabled(true);

    const openReport = openInNewTab(
      `${BASE_URL}generate_private_equity_report/${fundId}/${positionDate}`,
    );
    openReport();
    setButtonColour(mainColors.controlButtonBlue);
  }

  return (
    <Button
      onClick={generateReport}
      disabled={isButtonDisabled}
      style={{
        backgroundColor: buttonColour,
        color: 'white',
        width: '100%',
        maxWidth: '20rem',
      }}
    >
      {isButtonDisabled
        ? 'Generating Report...'
        : `Generate Report: ${positionDate}`}
    </Button>
  );
}

export interface PeOverviewTableData {
  internalFundName: string;
  fundName: string;
  positionDate: string;
  currency: string;
  netAssetValue: number;
  leverage: number;
  prospectusRestrictions: Status;
  liquidity: Status;
  grossExposure: number;
  commitment: number;
}

const detailColumns: CustomColumn<PeOverviewTableData>[] = [
  {
    title: 'Internal Fund Name',
    field: 'internalFundName',
    cellStyle: { textAlign: 'center' },
    headerStyle: { textAlign: 'center' },
    render: (rowData) => rowData.internalFundName,
  },
  {
    title: 'Fund Name',
    field: 'fundName',
    cellStyle: { textAlign: 'center' },
    headerStyle: { textAlign: 'center' },
  },
  {
    title: 'Position Date',
    field: 'positionDate',
    cellStyle: { textAlign: 'center' },
    headerStyle: { textAlign: 'center' },
  },
  {
    title: 'Report',
    field: 'positionDate',
    cellStyle: { textAlign: 'center' },
    headerStyle: { textAlign: 'center' },
    render: (rowData: PeOverviewTableData) => (
      <PEReportButton
        fundId={rowData.internalFundName}
        positionDate={rowData.positionDate}
        fundName={rowData.fundName}
      />
    ),
  },
  {
    title: 'Currency',
    field: 'currency',
    cellStyle: { textAlign: 'center' },
    headerStyle: { textAlign: 'center' },
  },
  {
    title: 'Net Asset Value',
    field: 'netAssetValue',
    cellStyle: { textAlign: 'center' },
    headerStyle: { textAlign: 'center' },
    render: (rowData) => addCommasToNumbersAndRound(rowData.netAssetValue),
  },
  {
    title: 'Gross Exposure',
    field: 'grossExposure',
    width: '10%',
    cellStyle: { textAlign: 'center' },
    headerStyle: { textAlign: 'center' },
    render: (rowData: PeOverviewTableData) => (
      <PEPageLinkButton
        status={rowData.grossExposure}
        section="pe"
        page="pe-exposure"
        fundId={rowData.internalFundName}
      />
    ),
  },
  {
    title: 'Commitment',
    field: 'commitment',
    width: '10%',
    cellStyle: { textAlign: 'center' },
    headerStyle: { textAlign: 'center' },
    render: (rowData: PeOverviewTableData) => (
      <PEPageLinkButton
        status={rowData.commitment}
        section="pe"
        page="pe-exposure"
        fundId={rowData.internalFundName}
      />
    ),
  },
  {
    title: 'Prospectus Restrictions',
    field: 'prospectusRestrictions',
    pdfRenderType: 'PageLinkButton',
    width: '10%',
    cellStyle: { textAlign: 'center' },
    headerStyle: { textAlign: 'center' },
    render: (rowData: PeOverviewTableData) => (
      <PEPageLinkButton
        status={rowData.prospectusRestrictions}
        section="pe"
        page="prospectus-restrictions"
        fundId={rowData.internalFundName}
      />
    ),
  },
  {
    title: 'Liquidity',
    field: 'liquidity',
    pdfRenderType: 'PageLinkButton',
    width: '10%',
    cellStyle: { textAlign: 'center' },
    headerStyle: { textAlign: 'center' },
    render: (rowData: PeOverviewTableData) => (
      <PEPageLinkButton
        status={specifyStatus(rowData.liquidity)}
        section="pe"
        page="liquidity-pe-value-at-risk"
        fundId={rowData.internalFundName}
      />
    ),
  },
];

function buildPeOverviewTableData(data: any): PeOverviewTableData[] {
  if (!data) return [];
  if (!('data' in data)) return [];
  if (!data.data.length) return [];
  const peData = data.data[0];
  const tableData: PeOverviewTableData[] = [];
  peData.forEach((fund: any) => {
    tableData.push({
      internalFundName: fund.fund_name,
      fundName: fund.fund_name_full,
      positionDate: fund.monitor_data.selected_position_date,
      currency: fund.monitor_data.base_currency,
      netAssetValue: fund.monitor_data.nav,
      leverage: fund.monitor_data.gross_exposure_value,
      prospectusRestrictions: fund.monitor_data['Risk_Compliance'],
      liquidity: fund.monitor_data['Liquidation Time'],
      grossExposure: fund.monitor_data.gross_exposure_value,
      commitment: fund.monitor_data.commitment_value,
    });
  });

  return tableData;
}

const PeOverview: React.FC<GeneralOverviewPageRenderedComponentProps> = (
  props,
) => {
  const sectionDetails = useSelector(createSectionByIdSelector(props.section));
  const fundTypes = sectionDetails?.fundTypes;

  const schemas = sectionDetails?.fundTypes.length
    ? `?schemas=${sectionDetails?.fundTypes}`
    : '';
  const overviewData = useFetchData({
    url: `schema_overview_monitor/${props.positionDate}` + schemas,
    keyName: `${fundTypes}_overview_data_${props.positionDate}`,
    makeFetch: props.positionDate ? true : false,
  });

  const tableHeight = 'calc(100vh - 25rem)';
  const dataPreparedForTable = buildPeOverviewTableData(overviewData);

  return (
    <GeneralComponentErrorShield dataObjects={[overviewData]}>
      <GridItem card xs={12}>
        <CustomTable<PeOverviewTableData>
          showToolbar
          loadingHeight={tableHeight}
          title="Private Equity Overview Data"
          columns={detailColumns}
          data={dataPreparedForTable}
          options={{
            paging: false,
            search: true,
            exportButton: true,
            rowStyle: {
              padding: '1rem',
            },
          }}
        />
      </GridItem>
    </GeneralComponentErrorShield>
  );
};

export default PeOverview;
