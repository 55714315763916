// This context is used to show a confirmation modal when a user tries to do an action that requires confirmation.
// It is used to confirm deletion of a record, for example. It allows a simple hook to render consistent confirmation modals.

import React, {
  createContext,
  useState,
  ReactNode,
  FunctionComponent,
  useContext,
} from 'react';
import ConfirmationModel from '../ui/ConfirmationModel';

interface Confirmation {
  title: string;
  message: ReactNode;
  onConfirm: () => void;
}

interface ConfirmationContextType {
  confirm: (title: string, message: ReactNode, onConfirm: () => void) => void;
  cancel: () => void;
  confirmation?: Confirmation | null;
}

export const ConfirmationContext = createContext<ConfirmationContextType>({
  confirm: (title: string, message: ReactNode, onConfirm: () => void) =>
    undefined,
  cancel: () => undefined,
  confirmation: null,
});

interface ConfirmationProviderProps {
  children: ReactNode;
}

export const ConfirmationProvider: React.FC<ConfirmationProviderProps> = ({
  children,
}) => {
  const [confirmation, setConfirmation] = useState<Confirmation | null>(null);

  const confirm = (
    title: string,
    message: ReactNode,
    onConfirm: () => void,
  ) => {
    setConfirmation({ title, message, onConfirm });
  };

  const cancel = () => {
    setConfirmation(null);
  };

  const value: ConfirmationContextType = {
    confirm,
    cancel,
    confirmation,
  };

  return (
    <ConfirmationContext.Provider value={value}>
      {children}
    </ConfirmationContext.Provider>
  );
};

export const ConfirmationWrapper: FunctionComponent = () => {
  const { confirmation } = useContext(ConfirmationContext);
  return <>{confirmation && <ConfirmationModel />}</>;
};
