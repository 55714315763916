import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import React, { Dispatch, useState } from 'react';
import requestClient, {
  BASE_URL,
} from '../../../../../utilities/requestClient';
import { mainColors } from '../../../../../styling/theme';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    width: '100%',
  },
  input: {
    width: '100%',
    padding: '0.5rem 1rem',
    borderRadius: '0.4rem',
    border: `1px solid ${mainColors.mainBlue}`,
  },
  buttonContainer: {
    display: 'flex',
    gap: '1rem',
    flexWrap: 'wrap',
  },
  button: {
    padding: '0.5rem 1rem',
    borderRadius: '0.4rem',
    cursor: 'pointer',
    textAlign: 'center',
    fontSize: '1rem',
    fontWeight: 400,
    userSelect: 'none',
  },
  addButton: {
    backgroundColor: mainColors.Pass,
    color: 'white',
  },
  removeButton: {
    backgroundColor: mainColors.Fail,
    color: 'white',
  },
  commentButton: {
    backgroundColor: mainColors.mainBlue,
    color: 'white',
  },
  userMessage: {
    fontSize: '1rem',
    fontWeight: 400,
    display: 'flex',
    alignItems: 'center',
  },
}));

interface ActionsProps {
  fundId: string;
  positionDate: string;
  isin: string;
  setExcludedIsins: Dispatch<any>;
}

type Status = 'idle' | 'loading' | 'success' | 'error';

const colorMap = (status: Status) => {
  switch (status) {
    case 'idle':
      return mainColors.NA;
    case 'loading':
      return mainColors.mainBlue;
    case 'success':
      return mainColors.Pass;
    case 'error':
      return mainColors.Fail;
  }
};

const Actions: React.FC<ActionsProps> = ({
  fundId,
  positionDate,
  isin,
  setExcludedIsins,
}) => {
  const classes = useStyles();
  const client = requestClient();

  const [comment, setComment] = useState<string>('');

  const [requestStatus, setRequestStatus] = useState<
    'idle' | 'loading' | 'success' | 'error'
  >('idle');
  const [requestMessage, setRequestMessage] = useState<string>('');

  const handleSubmit = (action: 'add' | 'remove' | 'add_comment') => {
    if (comment === '') {
      setRequestStatus('error');
      setRequestMessage('All requests must include a comment');
      return;
    } else {
      setRequestStatus('loading');
      setRequestMessage('Loading...');
      // Create an object of formData
      const formData = new FormData();
      // Update the formData object
      formData.append('isin', isin);
      formData.append('action', action);
      formData.append('comment', comment);
      client
        .post(
          BASE_URL + `/sanctions_scanner/save_record/${fundId}/${positionDate}`,
          formData,
          {
            headers: {
              'content-type': 'multipart/form-data',
            },
          },
        )
        .then((response) => {
          if (response.status === 200) {
            setRequestStatus('success');
            setRequestMessage('submitted');
          } else {
            setRequestStatus('error');
            setRequestMessage('Error');
          }
        })
        .catch((err) => {
          setRequestStatus('error');
          setRequestMessage('Error');
        })
        .finally(() => {
          setTimeout(() => {
            setRequestStatus('idle');
            setRequestMessage('');
          }, 5000);
        });
      // If the action is 'add' then update the excludedIsins list
      if (action === 'add') {
        setExcludedIsins((prev: string[]) => [...prev, isin]);
      }
      // if the action is 'remove' then remove the isin from the excludedIsins list (if it is there)
      if (action === 'remove') {
        setExcludedIsins((prev: string[]) => prev.filter((i) => i !== isin));
      }
    }
  };

  // const handleSubmit = (
  //   action: 'add' | 'remove' | 'add_comment'
  // ) => {
  //   setRequestStatus('loading');
  //   client.put('receive_indicator_data', {
  //     fund_name: activeFund?.id,
  //     date: activeDate,
  //     gmid: gmid,
  //     indicator: indicator,
  //     value: newValue === "N/A" ? "N/A" : String(parseFloat(newValue) / 100),
  //   })
  //   .then((res) => {
  //     if (res.status !== 200) throw new Error('Error submitting value');
  //     setRequestStatus('success');
  //     onChange();
  //     setTimeout(() => {
  //       setIsEditing(false);
  //     }, 1000);
  //   })
  //   .catch((err) => {
  //     setRequestStatus('error');
  //   })
  //   .finally(() => {
  //     setTimeout(() => {
  //       setRequestStatus('idle');
  //     }, 5000);
  //   });
  // };

  return (
    <div className={classes.root}>
      <input
        type="text"
        placeholder="Please include a comment here..."
        className={classes.input}
        value={comment}
        onChange={(e) => setComment(e.target.value)}
      />
      <div className={classes.buttonContainer}>
        <div
          className={clsx(classes.button, classes.addButton)}
          onClick={() => handleSubmit('add')}
        >
          Exclude
        </div>
        <div
          className={clsx(classes.button, classes.removeButton)}
          onClick={() => handleSubmit('remove')}
        >
          Include
        </div>
        <div
          className={clsx(classes.button, classes.commentButton)}
          onClick={() => handleSubmit('add_comment')}
        >
          Comment
        </div>
        <div
          className={classes.userMessage}
          style={{ color: colorMap(requestStatus) }}
        >
          {requestMessage}
        </div>
      </div>
    </div>
  );
};

export default Actions;
