import makeStyles from '@mui/styles/makeStyles';
import { useState } from 'react';
import { RaptorTheme, mainColors } from '../../../../styling/theme';
import { hexToRGBA } from '../../../../utilities/colorUtilities';
import RaptorSnackbar from '../../../feedback/raptorSnackbar/Snackbar.component';
import { Button, Typography } from '@mui/material';

interface StyleProps {
  clicked: boolean;
  height?: string;
}

const useDiscountCashflowButtonStyles = makeStyles<RaptorTheme, StyleProps>(
  (theme) => ({
    container: {
      lineHeight: 25,
    },
    contained: {
      height: (props) => props.height || '5rem',
      background: 'white',
      '&:hover': {
        backgroundColor: hexToRGBA(mainColors.mainBlue, 0.5),
      },
      borderRadius: 8,
      // paddingBottom: (props) => (props.clicked ? 0 : '2rem'),
      // paddingTop: (props) => (props.clicked ? 0 : '2rem'),
    },
    root: {
      marginRight: '1.6rem',
      display: 'inline-flex',
      backgroundColor: theme.palette.primary.main,
    },
    label: {
      flexDirection: 'column',

      alignItems: 'start',
    },
    buttonText: {
      colorPrimary: theme.palette.common.white,
    },
  }),
);

export interface DiscountCashflowButtonProps {
  handler: () => void;
  title: string;
  height?: string;
}

const DiscountCashflowButton = (props: DiscountCashflowButtonProps) => {
  const { handler, title } = props;
  const [loading, setLoading] = useState(false);
  const [sent, setSent] = useState(false);
  const classes = useDiscountCashflowButtonStyles({
    clicked: loading || sent,
    height: props.height,
  });
  const buttonHandler = async () => {
    setLoading(true);
    const resp = await handler();
    setLoading(false);
    setSent(true);
  };

  return (
    <Button
      variant="contained"
      onClick={buttonHandler}
      classes={{
        contained: classes.contained,
        root: classes.root,
        // label: classes.label,
      }}
    >
      <Typography
        variant="h3"
        color="primary"
        style={{
          color: 'white',
        }}
      >
        {title}
      </Typography>
      {(loading || sent) && <RaptorSnackbar />}
    </Button>
  );
};

export default DiscountCashflowButton;
