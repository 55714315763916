import React from 'react';
import { IAssetLevelTableData } from './FundSummaryContainer.component';
import CustomTable from '../../../../../tables/CustomTable';
import { CustomColumn } from '../../../../../../types/components/tables/tableTypes';
import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material';
import { mainColors } from '../../../../../../styling/theme';
import { addCommasToNumbersAndRound } from '../../../../../../utilities/numberFormatters';

interface AssetLevelTableProps {
  data: IAssetLevelTableData[];
}

const assetLevelTableColumns = (
  hasCounterparty: boolean,
): CustomColumn<IAssetLevelTableData>[] => [
  {
    title: 'Date',
    field: 'date',
    cellStyle: { textAlign: 'center' },
    headerStyle: { textAlign: 'center' },
  },
  {
    title: 'Asset Name',
    field: 'assetName',
    cellStyle: { textAlign: 'center' },
    headerStyle: { textAlign: 'center' },
  },
  {
    title: 'Asset Type',
    field: 'assetType',
    cellStyle: { textAlign: 'center' },
    headerStyle: { textAlign: 'center' },
  },
  {
    title: 'ISIN',
    field: 'isin',
    cellStyle: { textAlign: 'center' },
    headerStyle: { textAlign: 'center' },
  },
  {
    title: 'Counterparty',
    field: 'counterparty',
    cellStyle: { textAlign: 'center' },
    headerStyle: { textAlign: 'center' },
    hidden: !hasCounterparty,
  },
  {
    title: 'Gross Exposure (EUR)',
    field: 'grossExposureEUR',
    cellStyle: { textAlign: 'center' },
    headerStyle: { textAlign: 'center' },
    render: (rowData) => addCommasToNumbersAndRound(rowData.grossExposureEUR),
  },
  {
    title: 'EMIR Contribution (EUR)',
    field: 'emirContribution',
    cellStyle: { textAlign: 'center' },
    headerStyle: { textAlign: 'center' },
    render: (rowData) => addCommasToNumbersAndRound(rowData.emirContribution),
  },
];

const useStyles = makeStyles<Theme>(() => ({
  root: {
    border: `1px solid ${mainColors.lightGrey}`,
    borderRadius: '0.4rem',
    margin: '1rem',
  },
}));

const AssetLevelTable: React.FC<AssetLevelTableProps> = ({ data }) => {
  const classes = useStyles();

  const hasCounterparty = (data: IAssetLevelTableData[]): boolean => {
    return data.some((row) => row.counterparty !== undefined);
  };

  return (
    <div className={classes.root}>
      <CustomTable<IAssetLevelTableData>
        columns={assetLevelTableColumns(hasCounterparty(data))}
        showToolbar
        data={data}
        title="Asset Level Data"
        options={{
          paging: false,
          search: false,
          exportButton: true,
        }}
        pdfDontMoveToNewPage={true}
      />
    </div>
  );
};

export default AssetLevelTable;
