import { CSSProperties } from 'react';
import { greys, mainColors } from '../../../../styling/theme';
import { CustomColumn } from '../../../../types/components/tables/tableTypes';
import GeneralReportDatePicker from '../../../general/GeneralReportDatePicker';
import {
  addCommasToNumbersAndRound,
  percentageToTwoDecimalPlaces,
} from '../../../../utilities/numberFormatters';
import CalendarPopup from '../overview/components/Calendar.component';
import PageLinkButton from '../../../buttons/PageLinkButton';
import { formatDateForCheckingState } from '../../../../utilities/dateFormatters';
import ReportsLinkButton from '../../aifmd/overviewLegacy/table/components/ReportsLinkButton/reportsLinkButton.component';
import { GeneralOverviewTableData } from './Overview';
import { isPrimitive } from '../../../../utilities/parserUtilities';
import { statusSort } from '../../../../utilities/customSorting';
import { specifyStatus } from '../../../../utilities/generalMappings';
import NotesComp from '../notes/Notes';

export type OverviewColumnFields =
  | 'index'
  | 'internalFundName'
  | 'fundName'
  | 'reports'
  | 'positionDate'
  | 'calculationDate'
  | 'currency'
  | 'netAssetValue'
  | 'commitmentValue'
  | 'valueAtRiskValue'
  | 'ucitsLaw'
  | 'prospectusStatus'
  | 'notes'
  | 'subFundRiskReports'
  | 'leverage'
  | 'riaifLaw'
  | 'cssfRiskSpreading'
  | 'act40Law'
  | 'nursLaw'
  | 'fivgLaw'
  | 'partiiLaw';
// | (string & {}); // Allow any other strings so BE can dynamically add columns

const generalCellStyles:
  | React.CSSProperties
  | ((data: object[], rowData: object) => React.CSSProperties) = {
  fontSize: '1.4rem',
  color: greys.grey900,
};

const generalHeaderStyles: React.CSSProperties = {};

// These are the column styles of all available columns on the overview page
export const getGeneralOverviewColumns = (
  clientName: string | null,
  fields: string[] | null,
  section: string,
): CustomColumn<GeneralOverviewTableData>[] => {
  // All the columns that are available in the general overview table
  const generalOverviewColumns: CustomColumn<GeneralOverviewTableData>[] = [
    {
      title: '#',
      field: 'index',
      cellStyle: {
        ...generalCellStyles,
        textAlign: 'center',
        width: '1rem',
      },
      headerStyle: {
        textAlign: 'center',
        ...generalHeaderStyles,
        width: '1rem',
      },
    },
    {
      title: 'Internal Fund Name',
      field: 'internalFundName',
      render: (rowData) =>
        clientName === 'mersenne'
          ? 'fund' + rowData.index
          : rowData.internalFundName,
      cellStyle: {
        ...generalCellStyles,
      },
      headerStyle: { ...generalHeaderStyles },
    },
    {
      title: 'Fund Name',
      field: 'fundName',
      pdfRenderType: 'DetailLine',
      headerStyle: { ...generalHeaderStyles },
      cellStyle: {
        ...generalCellStyles,
        fontSize: '1.4rem',
      },
      render: (rowData) => {
        return (
          <DetailLine
            initialText={rowData.fundName}
            detailLine={rowData.derogationMessage}
            detailStyle={{ margin: 0, color: mainColors.Fail }}
          />
        );
      },
    },
    {
      title: 'Reports',
      field: 'reports',
      sorting: false,
      render: (rowData: GeneralOverviewTableData) => (
        <GeneralReportDatePicker
          reportNames={rowData.reports}
          reportUrl="ucits_historical_report"
          fundId={rowData.internalFundName}
        />
      ),
      cellStyle: {
        ...generalCellStyles,
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
        ...generalHeaderStyles,
      },
    },
    {
      title: 'Sub Fund Risk Reports',
      field: 'subFundRiskReports',
      sorting: false,
      render: (rowData: GeneralOverviewTableData) => (
        <GeneralReportDatePicker
          reportNames={rowData.subFundRiskReports}
          reportUrl="ucits_historical_report"
          fundId={rowData.internalFundName}
          customGetDatesFromReportNamesFunction={
            getDatesFromSubFundRiskReportNames
          }
          customGetDatesFromReportNamesAsStringsFunction={
            getDatesFromSubFundRiskReportNamesAsStrings
          }
          customFindReportnameFromDate={findSubFundReportnameFromDate}
        />
      ),
      cellStyle: {
        ...generalCellStyles,
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
        ...generalHeaderStyles,
      },
    },
    {
      title: 'Position Date',
      field: 'positionDate',
      cellStyle: {
        ...generalCellStyles,
        textAlign: 'center',
        whiteSpace: 'nowrap',
      },
      headerStyle: {
        textAlign: 'center',
        ...generalHeaderStyles,
      },

      render: (rowData: GeneralOverviewTableData) => (
        <CalendarPopup
          date={rowData.positionDate}
          fundId={rowData.internalFundName}
        />
      ),
      customSort: (a, b) => {
        const aDate = new Date(a.positionDate);
        const bDate = new Date(b.positionDate);
        return aDate.getTime() - bDate.getTime();
      },
    },
    {
      title: 'Latest Calculation Timestamp',
      field: 'calculationDate',
      cellStyle: {
        ...generalCellStyles,
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
        ...generalHeaderStyles,
      },
    },
    {
      hidden: true,
      field: 'derogationMessage',
      searchable: true,
    },
    {
      title: 'Currency',
      field: 'currency',
      cellStyle: {
        textAlign: 'center',
        ...generalCellStyles,
      },
      headerStyle: {
        textAlign: 'center',
      },
      width: '4%',
    },
    {
      title: 'Net Asset Value',
      field: 'netAssetValue',
      render: (rowData: GeneralOverviewTableData) =>
        addCommasToNumbersAndRound(rowData.netAssetValue),
      cellStyle: {
        ...generalCellStyles,
        textAlign: 'right',
      },
      headerStyle: {
        textAlign: 'center',
      },
      customSort: (a, b) => {
        return b.netAssetValue - a.netAssetValue;
      },
    },
    {
      field: 'cssfRiskSpreading',
      title: 'CSSF Risk Spreading',
      width: '20rem',
      pdfRenderType: 'PageLinkButton',
      cellStyle: {
        ...generalCellStyles,
        textAlign: 'center',
        minWidth: '12rem',
        width: '8%',
      },
      headerStyle: {
        textAlign: 'center',
      },
      render: (rowData: GeneralOverviewTableData) => {
        return (
          <ReportsLinkButton
            section={section}
            page="cssf-risk-spreading"
            status={specifyStatus(rowData.cssfRiskSpreading || '')}
            fundName={rowData.internalFundName}
          />
        );
      },
    },
    {
      title: 'Leverage',
      field: 'leverage',
      pdfRenderType: 'PageLinkButton',
      cellStyle: {
        ...generalCellStyles,
        textAlign: 'center',
        minWidth: '12rem',
        width: '8%',
      },
      headerStyle: {
        textAlign: 'center',
      },
      pdfWidth: 10,
      render: (rowData: GeneralOverviewTableData) => (
        <PageLinkButton
          status={rowData.leverage}
          fundName={rowData.internalFundName}
          text={rowData.leverage}
          page={'exposure'}
          section={section}
        />
      ),
      customSort: (a, b) =>
        statusSort(a, b, 'commitmentStatus', 'commitmentValue'),
    },
    {
      title: 'Commitment',
      field: 'commitmentValue',
      pdfRenderType: 'PageLinkButton',
      cellStyle: {
        ...generalCellStyles,
        textAlign: 'center',
        minWidth: '12rem',
        width: '8%',
      },
      headerStyle: {
        textAlign: 'center',
      },
      pdfWidth: 10,
      render: (rowData: GeneralOverviewTableData) => (
        <PageLinkButton
          status={rowData.commitmentStatus}
          fundName={rowData.internalFundName}
          text={
            rowData.fundType === 'commitment' || rowData.fundType === 'both'
              ? percentageToTwoDecimalPlaces(rowData.commitmentValue)
              : 'N/R'
          }
          page={'exposure'} // TODO: This potentially needs to be dynamic
          section={section} // TODO: This must be dynamic based on the section
        />
      ),
      customSort: (a, b) =>
        statusSort(a, b, 'commitmentStatus', 'commitmentValue'),
    },
    {
      pdfWidth: 10,
      title: 'Value at Risk',
      field: 'valueAtRiskValue',
      cellStyle: {
        ...generalCellStyles,
        textAlign: 'center',
        minWidth: '12rem',
        width: '8%',
      },
      pdfRenderType: 'PageLinkButton',
      headerStyle: {
        textAlign: 'center',
      },
      render: (rowData: GeneralOverviewTableData) => (
        <PageLinkButton
          status={specifyStatus(rowData.valueAtRiskStatus)}
          fundName={rowData.internalFundName}
          text={
            rowData.fundType === 'var' || rowData.fundType === 'both'
              ? percentageToTwoDecimalPlaces(rowData.valueAtRiskValue)
              : 'N/R'
          }
          page={'value-at-risk'}
          section={section} // TODO: This must be dynamic based on the section
          textColor={rowData.varDay === 'yes' ? mainColors.secondaryRed : null}
        />
      ),
      customSort: (a, b) =>
        statusSort(a, b, 'valueAtRiskStatus', 'valueAtRiskValue'),
    },
    {
      pdfWidth: 10,
      title: 'UCITS Law',
      field: 'ucitsLaw',
      pdfRenderType: 'PageLinkButton',
      cellStyle: {
        ...generalCellStyles,
        textAlign: 'center',
        minWidth: '12rem',
        width: '8%',
      },
      headerStyle: {
        textAlign: 'center',
      },
      render: (rowData: GeneralOverviewTableData) => (
        <PageLinkButton
          status={specifyStatus(rowData.ucitsLaw)}
          fundName={rowData.internalFundName}
          page={'ucits-law'}
          section={'ucits'}
        />
      ),
      customSort: (a, b) => statusSort(a, b, 'ucitsLaw'),
    },
    {
      pdfWidth: 10,
      title: 'RIAIF Law',
      field: 'riaifLaw',
      pdfRenderType: 'PageLinkButton',
      cellStyle: {
        ...generalCellStyles,
        textAlign: 'center',
        minWidth: '12rem',
        width: '8%',
      },
      headerStyle: {
        textAlign: 'center',
      },
      render: (rowData: GeneralOverviewTableData) => (
        <PageLinkButton
          status={specifyStatus(rowData.riaifLaw || '')}
          fundName={rowData.internalFundName}
          page={'riaif-law'}
          section={section}
        />
      ),
      customSort: (a, b) => statusSort(a, b, 'riaifLaw'),
    },
    {
      pdfWidth: 10,
      title: 'NURS Law',
      field: 'nursLaw',
      pdfRenderType: 'PageLinkButton',
      cellStyle: {
        ...generalCellStyles,
        textAlign: 'center',
        minWidth: '12rem',
        width: '8%',
      },
      headerStyle: {
        textAlign: 'center',
      },
      render: (rowData: GeneralOverviewTableData) => (
        <PageLinkButton
          status={specifyStatus(rowData.nursLaw || '')}
          fundName={rowData.internalFundName}
          page={'nurs-law'}
          section={'nurs'}
        />
      ),
      customSort: (a, b) => statusSort(a, b, 'nursLaw'),
    },
    {
      pdfWidth: 10,
      title: 'Fivg Law',
      field: 'fivgLaw',
      pdfRenderType: 'PageLinkButton',
      cellStyle: {
        ...generalCellStyles,
        textAlign: 'center',
        minWidth: '12rem',
        width: '8%',
      },
      headerStyle: {
        textAlign: 'center',
      },
      render: (rowData: GeneralOverviewTableData) => (
        <PageLinkButton
          status={specifyStatus(rowData.fivgLaw || '')}
          fundName={rowData.internalFundName}
          page={'fivg-law'}
          section={section}
        />
      ),
      customSort: (a, b) => statusSort(a, b, 'fivgLaw'),
    },
    {
      pdfWidth: 10,
      title: 'Part II Law',
      field: 'partiiLaw',
      pdfRenderType: 'PageLinkButton',
      cellStyle: {
        ...generalCellStyles,
        textAlign: 'center',
        minWidth: '12rem',
        width: '8%',
      },
      headerStyle: {
        textAlign: 'center',
      },
      render: (rowData: GeneralOverviewTableData) => (
        <PageLinkButton
          status={specifyStatus(rowData.partiiLaw || '')}
          fundName={rowData.internalFundName}
          page={'partii-law'}
          section={section}
        />
      ),
      customSort: (a, b) => statusSort(a, b, 'partiiLaw'),
    },
    {
      pdfWidth: 10,
      title: '40 Act Law',
      field: 'act40Law',
      pdfRenderType: 'PageLinkButton',
      cellStyle: {
        ...generalCellStyles,
        textAlign: 'center',
        minWidth: '12rem',
        width: '8%',
      },
      headerStyle: {
        textAlign: 'center',
      },
      render: (rowData: GeneralOverviewTableData) => (
        <PageLinkButton
          status={specifyStatus(rowData.act40Law || '')}
          fundName={rowData.internalFundName}
          page={'act40-law'}
          section={'act40'}
        />
      ),
      customSort: (a, b) => statusSort(a, b, 'act40Law'),
    },
    {
      pdfWidth: 10,
      title: 'Prospectus Restrictions',
      field: 'prospectusStatus',
      pdfRenderType: 'PageLinkButton',
      cellStyle: {
        ...generalCellStyles,
        textAlign: 'center',
        minWidth: '12rem',
        width: '8%',
      },
      headerStyle: {
        ...generalHeaderStyles,
        textAlign: 'center',
      },
      render: (rowData: GeneralOverviewTableData) => (
        <PageLinkButton
          status={specifyStatus(rowData.prospectusStatus)}
          fundName={rowData.internalFundName}
          page={'prospectus-restrictions'}
          section={section}
        />
      ),
      customSort: (a, b) => statusSort(a, b, 'prospectusStatus'),
    },
    {
      title: 'Notes',
      field: 'notes',
      width: '5rem',
      render: (rowData: GeneralOverviewTableData) => (
        <NotesComp
          fundId={rowData.internalFundName}
          fundName={rowData.fundName}
          subject={`${section}_${section}-overview`} // Subject is hardcoded for now
          topic={rowData.positionDate}
          positionDate={rowData.positionDate}
        />
      ),
      cellStyle: {
        padding: '1.6rem',
        textAlign: 'center',
        paddingRight: '2.4rem',
        paddingLeft: '0.8rem',
      },
      headerStyle: {
        textAlign: 'center',
        padding: 'auto 1.6rem',
        paddingRight: '2.4rem',
        paddingLeft: '0.8rem',
      },
    },
  ];

  if (!fields) {
    return [];
  } else {
    // Filter columns by fields array, if not found in the styles file then create a new column if the value is primative
    const filteredColumns = fields.map((field) => {
      const columns = generalOverviewColumns.filter(
        (column) => column.field === field,
      );
      if (columns.length > 0) {
        return columns[0];
      } else {
        return {
          title: field,
          field: field,
          cellStyle: {
            ...generalCellStyles,
            textAlign: 'center',
          },
          render: (rowData: any) => {
            const value = rowData[field];
            if (value !== null && value !== undefined && isPrimitive(value)) {
              // Value is a primitive
              return value;
            } else {
              // Value is not a primitive
              return null;
            }
          },
          headerStyle: {
            textAlign: 'center',
            ...generalHeaderStyles,
          },
        };
      }
    });

    // Sort the columns in the order specified by the fields array
    const sortedColumns = fields
      .map((field) => filteredColumns.find((column) => column.field === field))
      .filter(
        (column) => column !== undefined,
      ) as CustomColumn<GeneralOverviewTableData>[];

    return sortedColumns;
  }
};

// COMPONENTS =====================================================
export const DetailLine = (props: {
  initialText: string;
  detailLine?: string;
  detailStyle: CSSProperties;
}) => {
  return (
    <>
      <p style={{ margin: 0 }}>{props.initialText}</p>
      {props.detailLine && <p style={props.detailStyle}>{props.detailLine}</p>}
    </>
  );
};

// UTILITIES =====================================================

function getDatesFromSubFundRiskReportNames(reportNames: string[]): Date[] {
  if (!reportNames) return [];
  if (!reportNames.length) return [];
  const dates: Date[] = [];
  reportNames.forEach((reportName: string) => {
    // Get the last 14 characters from the report name.
    const formattedDate = reportName.slice(-14).replace('.pdf', '');
    dates.push(new Date(formattedDate));
  });
  const sortedDates = dates.sort();
  return sortedDates;
}

function getDatesFromSubFundRiskReportNamesAsStrings(
  reportNames: string[],
): string[] {
  if (!reportNames) return [];
  if (!reportNames.length) return [];
  const dates: string[] = [];
  reportNames.forEach((reportName: string) => {
    const formattedDate = reportName.slice(-14).replace('.pdf', '');
    dates.push(formattedDate);
  });
  return dates;
}

function findSubFundReportnameFromDate(
  date: Date | null,
  reportNames: string[],
): string | null {
  if (!date) return null;
  // Format the date as a string
  const dateString = formatDateForCheckingState(date);
  let foundReportName = '';
  reportNames.forEach((reportName: string) => {
    const formattedDate = reportName.slice(-14).replace('.pdf', '');
    if (dateString === formattedDate) {
      foundReportName = reportName;
      return;
    }
  });
  return foundReportName;
}

type FundType = 'var' | 'commitment' | 'both';

export function createGeneralTableData(
  inputData: any[],
  reportsList: any,
  subFundRiskReportsList: any,
  clientName: string | null,
  section: string | null,
): GeneralOverviewTableData[] {
  const finalData: GeneralOverviewTableData[] = [];

  if (inputData.length && reportsList) {
    const reports = reportsList[0];
    const subFundRiskReports = subFundRiskReportsList
      ? subFundRiskReportsList[0]
      : null;

    inputData.forEach((fund: any, index: number) => {
      // calculate fund type
      let fundType: FundType =
        fund.monitor_data.var_fund === 'yes' ? 'var' : 'commitment';

      // some clients request to have both calculations shown
      if (clientName?.includes('pure')) {
        fundType = 'both';
      }

      const toAdd: GeneralOverviewTableData = {
        index: index + 1,
        internalFundName: fund.fund_name,
        fundName: fund.fund_name_full,
        reports: reports[fund.fund_name],
        subFundRiskReports: subFundRiskReports
          ? subFundRiskReports[fund.fund_name]
          : [],
        positionDate: fund.monitor_data.selected_position_date,
        calculationDate: fund.monitor_data.latest_calculation_timestamp,
        currency: fund.monitor_data.base_currency,
        netAssetValue: fund.monitor_data.nav,
        commitmentStatus: specifyStatus(fund.monitor_data.Commitment),
        valueAtRiskStatus: specifyStatus(fund.monitor_data.Value_at_Risk),
        commitmentValue:
          fundType === 'commitment' || fundType === 'both'
            ? fund.monitor_data.commitment_value
            : -1, // var_value: Math.round(data.var_value * 100),
        valueAtRiskValue:
          fundType === 'var' || fundType === 'both'
            ? fund.monitor_data.var_value
            : -1,
        varDay: fund.monitor_data.var_day,
        varFund: fund.monitor_data.var_fund,
        ucitsLaw: specifyStatus(fund.monitor_data.UCITS),
        riaifLaw: specifyStatus(fund.monitor_data.RIAIF),
        act40Law: specifyStatus(fund.monitor_data.ACT40),
        nursLaw: specifyStatus(fund.monitor_data.NURS),
        fivgLaw: specifyStatus(fund.monitor_data.FIVG),
        partiiLaw: specifyStatus(fund.monitor_data.PARTII),
        prospectusStatus: specifyStatus(fund.monitor_data.Risk_Compliance),
        countryExposure: fund.monitor_data.country_exposure,
        positionExposure: fund.monitor_data.position_exposure,
        derogationMessage:
          fund.monitor_data.six_month_derogation?.result === 'live'
            ? `Derogation Expiry Date: ${fund.monitor_data.six_month_derogation.derogation_date}`
            : undefined,
        timeSeriesData: null,
        timeSeriesStatus: null,
        notes: true,
        leverage: specifyStatus(fund.monitor_data.Leverage),
        fundType: fundType,
        cssfRiskSpreading: specifyStatus(fund.monitor_data['Risk Spreading']),
        ...fund.monitor_data,
      };

      if (fund.monitor_data.historical_time_series) {
        // if the historical time series is there
        const timeSeries = fund.monitor_data.historical_time_series;
        timeSeries.shift();
        toAdd['timeSeriesData'] = timeSeries;
      }

      if (fund.monitor_data.historical_time_series_status)
        toAdd['timeSeriesStatus'] =
          fund.monitor_data.historical_time_series_status;

      finalData.push(toAdd);
    });
  }
  return finalData;
}
