import React from 'react';
import GridItem from '../../../../layout/GridComponents/GridItem';
import CustomTable from '../../../../tables/CustomTable';
import { CustomColumn } from '../../../../../types/components/tables/tableTypes';
import {
  addCommasToNumbersAndRound,
  percentageToNdecialPlaces,
} from '../../../../../utilities/numberFormatters';
import { DataObject } from '../../../../../types/redux/data/dataTypes';

interface SectorReturnAnalysisTableProps {
  data: DataObject;
}

interface SectorReturnAnalysisTableData {
  sector_name: string;
  gross_exposure: number;
  gross_exp_pct: number;
  sector_exposure: number;
  sector_pct_exposure: number;
  fund_sector_returns: number;
  benchmark_sector_returns: number;
  excess_returns: number;
}

const buildColumns = (): CustomColumn<SectorReturnAnalysisTableData>[] => [
  {
    title: 'Sector Name',
    field: 'sector_name',
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: {
      textAlign: 'center',
    },
  },
  {
    title: 'Gross Exposure',
    field: 'gross_exposure',
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: {
      textAlign: 'center',
    },
    render: (rowData: SectorReturnAnalysisTableData) =>
      addCommasToNumbersAndRound(rowData.gross_exposure),
  },
  {
    title: `Gross Exposure %`,
    field: 'gross_exp_pct',
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: {
      textAlign: 'center',
    },
    render: (rowData: SectorReturnAnalysisTableData) =>
      percentageToNdecialPlaces(rowData.gross_exp_pct / 100, 2),
  },
  {
    title: 'Sector P/L',
    field: 'sector_exposure',
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: {
      textAlign: 'center',
    },
    render: (rowData: SectorReturnAnalysisTableData) =>
      addCommasToNumbersAndRound(rowData.sector_exposure),
  },
  {
    title: 'Sector P/L %',
    field: 'sector_pct_exposure',
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: {
      textAlign: 'center',
    },
    render: (rowData: SectorReturnAnalysisTableData) =>
      percentageToNdecialPlaces(rowData.sector_pct_exposure / 100, 2),
  },
  {
    title: 'Calculated Sector Returns %',
    field: 'fund_sector_returns',
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: {
      textAlign: 'center',
    },
    render: (rowData: SectorReturnAnalysisTableData) =>
      percentageToNdecialPlaces(rowData.fund_sector_returns / 100, 2),
  },
  {
    title: 'Benchmark Returns %',
    field: 'benchmark_sector_returns',
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: {
      textAlign: 'center',
    },
    render: (rowData: SectorReturnAnalysisTableData) =>
      percentageToNdecialPlaces(rowData.benchmark_sector_returns / 100, 2),
  },
  {
    title: 'Active Returns %',
    field: 'excess_returns',
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: {
      textAlign: 'center',
    },
    render: (rowData: SectorReturnAnalysisTableData) =>
      percentageToNdecialPlaces(rowData.excess_returns / 100, 2),
  },
];

const buildTableData = (
  inputData: DataObject,
): SectorReturnAnalysisTableData[] => {
  if (!inputData.data || !inputData.data.length) {
    return [];
  } else {
    return inputData.data[0].sector_wise_benchmarkvsfund;
  }
};

const SectorReturnAnalysisTable: React.FC<SectorReturnAnalysisTableProps> = ({
  data,
}) => {
  const builtTableData = buildTableData(data);

  const columns = buildColumns();

  return (
    <GridItem xs={12} card>
      <CustomTable<SectorReturnAnalysisTableData>
        columns={columns}
        showToolbar
        data={builtTableData}
        title={`Sub-Fund / Benchmark Cumulative Comparison - Sector Analysis`}
        options={{
          paging: false,
          search: true,
          exportButton: true,
        }}
      />
    </GridItem>
  );
};

export default SectorReturnAnalysisTable;
