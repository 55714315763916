import React, { ReactElement } from 'react';
import IndividualPie from '../IndividualPie/individualPie.component';

interface PieContainerProps {
  data: any;
  selectedKey: string;
  setSelectedKeyFunction: (key: string) => void;
}

function keyTransform(key: string): string {
  // Capitalise each word
  const seperatedWords = key.split('_');
  for (let i = 0; i < seperatedWords.length; i++) {
    seperatedWords[i] =
      seperatedWords[i].charAt(0).toUpperCase() +
      seperatedWords[i].substring(1);
  }
  return seperatedWords.join(' ');
}

function PieContainer({
  data,
  selectedKey,
  setSelectedKeyFunction,
}: PieContainerProps): ReactElement {
  return (
    <>
      {data
        .filter((item: any) => item && true)
        .map((item: any, index: number) => {
          // const pieRoute = mapPieToRoute(overallSection, item[0]);
          // Sum the data values to ensure that they are greater than 0, if not then do not create chart
          const dataSum = item[1]
            .slice(1)
            .reduce(
              (acucumulator: number, currentValue: Array<string | number>) =>
                acucumulator + Number(currentValue[1]),
              0,
            );
          const handlePieClick = () => {
            setSelectedKeyFunction(item[0]);
          };
          return dataSum > 0 ? (
            <IndividualPie
              handlePieClick={handlePieClick}
              isSelectedKey={selectedKey === item[0]}
              key={item[0]}
              dataTitle={keyTransform(item[0])}
              data={item[1]}
            />
          ) : (
            <></>
          );
        })}
    </>
  );
}

export default PieContainer;
