import { FunctionComponent } from 'react';
import { SearchProvider, useSearch } from '../context/SearchInputContext';
import { DebouncedInput } from './DebouncedInput';
import { useSearchStyles } from './FilterColumn';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import { Box } from '@mui/material';
import clsx from 'clsx';
import { useFormContext } from 'react-hook-form';
import { formatInitialValue } from '../utils/ultratable.utils';

interface SearchSelectComponentProps {
  items: any[];
  name: string;
  setStateValue: (value: string) => void;
}

const SearchSelectComponent: FunctionComponent<SearchSelectComponentProps> = ({
  items,
  name,
  setStateValue,
}) => {
  return (
    <SearchProvider>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          padding: '1rem',
        }}
      >
        <SearchFilterColumnItems />
        <SelectItems items={items} name={name} setStateValue={setStateValue} />
      </div>
    </SearchProvider>
  );
};

export default SearchSelectComponent;

function SearchFilterColumnItems() {
  const classes = useSearchStyles();

  const { searchTerm, setSearchTerm } = useSearch();

  function handleChange(value: string | number) {
    setSearchTerm(value as string);
  }

  return (
    <DebouncedInput
      className={classes.input}
      onChange={handleChange}
      placeholder={`Search...`}
      type="text"
      value={searchTerm}
      onClick={(e) => e.stopPropagation()}
    />
  );
}

function SelectItems({
  items,
  name,
  setStateValue,
}: {
  items: any[];
  name: string;
  setStateValue: (value: string) => void;
}) {
  const { searchTerm } = useSearch();
  const classes = useSearchStyles();

  const lowerCaseSearchTerm = searchTerm.toLowerCase();

  const filteredAndSortedData = items
    .filter((item) => item.toLowerCase().includes(lowerCaseSearchTerm))
    .sort((a, b) => a.localeCompare(b));

  return (
    <ul className={classes.optionsList}>
      {filteredAndSortedData.map((item, index) => (
        <OptionItem
          key={index}
          item={item}
          name={name}
          setStateValue={setStateValue}
        />
      ))}
    </ul>
  );
}

interface OptionItemProps {
  item: string;
  name: string;
  setStateValue: (value: string) => void;
}

const OptionItem: React.FC<OptionItemProps> = ({
  item,
  name,
  setStateValue,
}) => {
  const classes = useSearchStyles();
  const { setValue, watch } = useFormContext();

  const selectedValue = watch(name);

  const selected = selectedValue === item;

  const handleClick = () => {
    setValue(name, item);
    setStateValue(formatInitialValue(item));
  };

  return (
    <li
      className={clsx(classes.listItem, selected ? classes.selected : null)}
      onClick={handleClick}
    >
      <DoneIcon style={{ opacity: selected ? 1 : 0, marginRight: 2 }} />
      <Box
        sx={{
          flexGrow: 1,
          '& span': {
            color: '#586069',
          },
        }}
      >
        {item}
      </Box>
      {selected && <CloseIcon />}
    </li>
  );
};
