import { useDispatch } from 'react-redux';
import {
  removeTitleBarComponent,
  removeTitleBarComponentType,
  toggleTitleBarLoading,
} from '../redux/pages/actions';
import { REMOVE_SELECT_FROM_TITLEBAR } from '../types/redux/pages/pageTypes';

function useRemoveTitleBarComponent(
  componentId: string,
  removeType: removeTitleBarComponentType,
) {
  const dispatch = useDispatch();
  return () => {
    dispatch(toggleTitleBarLoading(true));
    dispatch(removeTitleBarComponent(componentId, removeType));
    dispatch(toggleTitleBarLoading(false));
  };
}

export const useRemoveTitleBarSelect = (componentId: string) =>
  useRemoveTitleBarComponent(componentId, REMOVE_SELECT_FROM_TITLEBAR);
