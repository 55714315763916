import React from 'react';
import GridItem from '../../../layout/GridComponents/GridItem';
import { DataObject } from '../../../../types/redux/data/dataTypes';
import useTitleBarSelect from '../../../../hooks/useTitleBarSelect';

export interface VarTableData {
  number: number;
  portfolio: string;
  equity: number;
  commodity: number;
  forex: number;
  fixedIncome: number;
  cash: number;
  alternative: number;
  total: number;
}
export interface VarComponentProps {
  dataObjects: {
    basicDataAgg: DataObject;
    portfolioNameAgg: DataObject;
    fundVarData: DataObject;
  };
  fundName: string;
}

export type RiskMetric = 'var' | 'cvar' | 'dvar';
export type RiskOutput = 'Cash' | 'relative' | 'absolute';
export interface VarWrapperProps {
  riskOutput: RiskOutput | null;
  riskMetric: RiskMetric | null;
  renderData: VarTableData[];
}

interface GenerateVarTableProps {
  fundVarData: DataObject;
  basicDataAgg: DataObject;
  portfolioNameAgg: DataObject;
  riskOutput: RiskOutput | null;
  riskMetric: RiskMetric | null;
}

/**
 * The risk output adjusts which data from the json we choose.
 * If the risk output is absolute or relative we provide the value, divided by nav
 * if Cash - it should just be the value
 */
function generateVarValueBasedOnRiskOutput(
  riskOutput: RiskOutput,
  value: number,
  nav: number,
) {
  try {
    switch (riskOutput) {
      case 'Cash':
        return value;
      case 'absolute':
      case 'relative':
        return value / nav;
      default:
        throw new Error('Unknown risk output: ' + riskOutput);
    }
  } catch (err) {
    console.error(err);
    return value / nav;
  }
}

function generateTableData({
  fundVarData,
  basicDataAgg,
  portfolioNameAgg,
  riskOutput,
  riskMetric,
}: GenerateVarTableProps): VarTableData[] {
  if (
    !fundVarData ||
    !basicDataAgg ||
    !portfolioNameAgg ||
    !riskOutput ||
    !riskMetric
  )
    return [];

  try {
    // this is the actual data from fundvarData we're interested in
    const data = fundVarData.data[0];
    // the nav
    const { nav } = data;
    // if the risk metric is var we need to use risk data
    const keyToUse = riskMetric === 'var' ? 'risk data' : riskMetric;
    // the object containing the data, cvar, dvar etc.
    const dataObjectByKey = data[keyToUse];
    // the keys are integers
    return Object.keys(dataObjectByKey)
      .map((key: string) => ({
        number: Number(key),
        portfolio: portfolioNameAgg.data[0][key],
        equity: generateVarValueBasedOnRiskOutput(
          riskOutput,
          dataObjectByKey[key]['1'],
          nav,
        ),
        commodity: generateVarValueBasedOnRiskOutput(
          riskOutput,
          dataObjectByKey[key]['2'],
          nav,
        ),
        forex: generateVarValueBasedOnRiskOutput(
          riskOutput,
          dataObjectByKey[key]['3'],
          nav,
        ),
        fixedIncome: generateVarValueBasedOnRiskOutput(
          riskOutput,
          dataObjectByKey[key]['4'],
          nav,
        ),
        cash: generateVarValueBasedOnRiskOutput(
          riskOutput,
          dataObjectByKey[key]['5'],
          nav,
        ),
        alternative: generateVarValueBasedOnRiskOutput(
          riskOutput,
          dataObjectByKey[key]['6'],
          nav,
        ),
        total: generateVarValueBasedOnRiskOutput(
          riskOutput,
          dataObjectByKey[key]['Total'],
          nav,
        ),
        // occasionally the server returns NaN so we need to do a check for that)
      }))
      .filter((datum) => !isNaN(datum.number));
  } catch (err) {
    return err;
  }
}

function varComponentWrapper(
  Component: React.ComponentType<VarComponentProps & VarWrapperProps>,
) {
  const WrappedComponent: React.FC<VarComponentProps> = (props) => {
    const riskOutput = useTitleBarSelect('riskOutput') as RiskOutput;
    const riskMetric = useTitleBarSelect('riskMetric') as RiskMetric;

    const data = generateTableData({
      ...props.dataObjects,
      riskOutput,
      riskMetric,
    });
    return (
      <GridItem
        xs={12}
        xl={6}
        card
        cardStyle={{ height: '100%' }}
        style={{ alignSelf: 'stretch' }}
      >
        <Component
          {...props}
          riskOutput={riskOutput}
          riskMetric={riskMetric}
          renderData={data}
        />
      </GridItem>
    );
  };

  return WrappedComponent;
}

export default varComponentWrapper;
