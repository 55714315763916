import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import { GeneralOverviewPageRenderedComponentProps } from '../../types/components/componentTypes';

import AifmdOverview from '../pages/aifmd/overview/AifmdOverview.component';
import LiquidityOverview from '../pages/liquidity/overview/Overview';
import AggregateLiquidation from '../pages/liquidity/aggLiquidation/component';
import ExposureOverview from '../pages/sharedComponents/ExposureOverview';
import ValueAtRiskOverview from '../pages/ucits/var/ValueAtRiskOverview';
import UcitsLawOverview from '../pages/ucits/law/UcitsLawOverview';
import ProspectusRestrictionsOverview from '../pages/risk/prospectusRestrictions/ProspectusRestrictionsOverview.component';
import GeneralSelectAFundOverview from '../pages/sharedComponents/GeneralUcitsOverview';
import StatusChange from '../pages/ucits/statusChange/StatusChange.page';
import Deceased from '../pages/ucits/deceased/Deceased';
import GlobalExposure from '../pages/ucits/globalExposure/GlobalExposure';
import Functionality from '../pages/ucits/functionality/Functionality';
import { FailsPageWithoutFund } from '../pages/utilities/Fails/FailsPage.component';
import ShareClassHedgeDataOverview from '../pages/reportsPages/shareClassHedge/ShareClassHedgeData.overview';
import SrriShareClassOverview from '../pages/ucits/srriShareClassOverview/SrriShareClassOverview';
import EsgOverview from '../pages/esg/esgOverview/EsgOverview.component';
import RestrictedCompanies from '../pages/utilities/RestrictedCompanies/RestrictedCompanies.component';
import EMIR from '../pages/manco/emir/emir.component';
import PeOverview from '../pages/privateEquity/peOverview/PeOverview.component';
import CoverageRatio from '../pages/manco/coverageRatio/CoverageRatio.component';
import MancoDashboard from '../pages/manco/mancoDashboard/MancoDashboard.component';
import RaptorExport from '../pages/utilities/RaptorExport/RaptorExport.component';
import KPIMonitoring from '../pages/utilities/KPIMonitoring/KPIMonitoring.component';
import MancoExposure from '../pages/manco/mancoExposure/MancoExposure.component';
import HedgeDerivativesSelector from '../pages/manco/hedgeDerivativesSelector/HedgeDerivativesSelector.component';
import SifRaifDeceased from '../pages/sif-raif/deceased/Deceased.component';
import ShareClassHedgeSummary from '../pages/sharedComponents/shareClassHedgeSummary/ShareClassHedgeSummary.component';
import KiidEditor from '../pages/raidr/kiids/kiidsEditor/KiidsEditor.component';
import ReportDeliveryOversight from '../pages/manco/reportDeliveryOversight/ReportDeliveryOversight.component';
import RaptorLibrary from '../pages/utilities/RaptorLibrary/RaptorLibrary.component';
import MancoCounterpartyExposure from '../pages/manco/mancoCounterpartyExposure/MancoCounterpartyExposure.component';
import NurDeceased from '../pages/nurs/deceased/Deceased';
import PositionOversight from '../pages/manco/positionOversight/PositionOversight.component';
import { useSelector } from 'react-redux';
import {
  activeDateSelector,
  availableDatesSelector,
} from '../../redux/pages/selectors';
import LiquidationTime from '../pages/manco/liquidationTime/LiquidationTime.component';
import MancoEquity from '../pages/manco/asset_class/equity/MancoEquity';
import MancoFixedIncome from '../pages/manco/asset_class/fixed_income/FixedIncome';
import MancoCis from '../pages/manco/asset_class/cis/Cis';
import DelegatedActs from '../pages/raidr/delegatedActs/DelegatedActs.component';
import KidsFigures from '../pages/raidr/kidsFigures/KidsFigures.component';
import RaptorExportToday from '../pages/utilities/RaptorExport/RaptorExportToday.component';
import QuaysideShareClassHedge from '../pages/reportsPages/quaysideShareClassHedge/QuaysideShareClassHedge.component';
import MarketOverview from '../pages/manco/marketOverview/MarketOverview.component';
import ReportGenerator from '../pages/performanceAttribution/reportGenerator/ReportGenerator.component';
import PaOverview from '../pages/performanceAttribution/overview/PAOverview.component';
import PaOverviewAlternative from '../pages/performanceAttribution/overviewAlternative/PAOverviewAlternative.component';
import FixedIncomeReportGenerator from '../pages/utilities/FixedIncomeReprotGenerator/FixedIncomeReportGenerator.component';
import AdepaEMIR from '../pages/manco/adepaEmir/adepaEmir.component';
import KiidEditorViewOnly from '../pages/raidr/kiids/kiidsViewOnly/KiidsEditorViewOnly.component';
import SanctionsScanner from '../pages/manco/sanctionsScanner/SanctionsScanner.component';
import AifmdOverviewLegacy from '../pages/aifmd/overviewLegacy/AifmdOverviewLegacy.component';
import HistoricalDataExport from '../pages/manco/historicalDataExport/HistoricalDataExport.component';
import HybridBondsSelector from '../pages/manco/hybridBondsSelector/HybridBondsSelector.component';
import Markets from '../pages/markets/MarketsMain';
import YieldMain from '../pages/yieldCurve/YieldMain';
import Overview from '../pages/sharedComponents/overview/Overview';
import FactSheets from '../pages/raidr/factSheets/FactSheets.component';
import PositionUpdatePortal from '../pages/manco/positionUpdatePortal/PositionUpdatePortal.page';

type GeneralOverviewPageProps = {
  section: string;
  pageName?: string;
};

/**
 * The GeneralOverviewPage is for anything at the route /:section/:pageArea
 * For example, at /ucits/exposure you would have an exposure general overview page.
 *
 * You can specify what is rendered based on the url that is passed
 */

const generateComponentFromKey = (
  pageId: string | null,
): React.FC<GeneralOverviewPageRenderedComponentProps> => {
  switch (pageId) {
    case 'overview':
      return Overview;
    case 'pa-overview':
      return PaOverview;
    case 'aifmd-overview':
      return AifmdOverview;
    // This is to handle legacy version of AIFMD code for Quayside and WCapital
    // Quayside and WCapital are the only clients using this, all future AIFMD clients will use the new version (eg. bflexion)
    case 'aifmd-overview-legacy':
      return AifmdOverviewLegacy;
    // ---------------------
    case 'liquidity-overview':
      return LiquidityOverview;
    case 'pa-overview-alternative':
      return PaOverviewAlternative;
    case 'manco-dashboard':
      return MancoDashboard;
    case 'coverage-ratio':
      return CoverageRatio;
    case 'library':
      return RaptorLibrary;
    case 'deceased':
      return Deceased;
    case 'nurs-deceased':
      return NurDeceased;
    case 'sif-raif-deceased':
      return SifRaifDeceased;
    case 'global-exposure':
      return GlobalExposure;
    case 'fails':
      return FailsPageWithoutFund;
    case 'share-class-hedge':
      return ShareClassHedgeDataOverview;
    case 'status-change':
      return StatusChange;
    case 'aggregate-liquidation-time':
      return AggregateLiquidation;
    case 'functionality':
      return Functionality;
    case 'srri-share-class-overview':
      return SrriShareClassOverview;
    case 'esg-overview':
      return EsgOverview;
    case 'restricted-companies':
      return RestrictedCompanies;
    case 'emir':
      return EMIR;
    case 'adepa-emir':
      return AdepaEMIR;
    case 'pe-overview':
      return PeOverview;
    case 'raptor-export':
      return RaptorExport;
    case 'raptor-export-today':
      return RaptorExportToday;
    case 'kpi-monitoring':
      return KPIMonitoring;
    case 'manco-exposure':
      return MancoExposure;
    case 'share-class-hedge-summary':
      return ShareClassHedgeSummary;
    case 'kiids-editor':
    case 'priips-kid':
      return KiidEditor;
    case 'kiids-editor-viewer':
      return KiidEditorViewOnly;
    case 'report-delivery-oversight':
      return ReportDeliveryOversight;
    case 'hedge-derivatives-selector':
      return HedgeDerivativesSelector;
    case 'hybrid-bonds-selector':
      return HybridBondsSelector;
    case 'manco-counterparty-exposure':
      return MancoCounterpartyExposure;
    case 'manco-position-oversight':
      return PositionOversight;
    case 'manco-liquidation-time':
      return LiquidationTime;
    case 'manco-equity':
      return MancoEquity;
    case 'manco-fixed-income':
      return MancoFixedIncome;
    case 'manco-cis':
      return MancoCis;
    case 'delegated-acts':
      return DelegatedActs;
    case 'fact-sheets':
      return FactSheets;
    case 'kids-figures':
      return KidsFigures;
    case 'markets':
      return Markets;
    case 'yield-curve':
      return YieldMain;
    case 'quayside-share-class-hedge':
      return QuaysideShareClassHedge;
    case 'market-overview':
      return MarketOverview;
    case 'pa-report-generator':
      return ReportGenerator;
    case 'fixed-income-report-generator':
      return FixedIncomeReportGenerator;
    case 'sanctions-scanner':
      return SanctionsScanner;
    case 'historical-data-export':
      return HistoricalDataExport;
    // select a fund pages ( !!! add all other overview pages above this line !!! ):
    // Pickers with status:
    case 'ucits-law':
      return UcitsLawOverview;
    case 'prospectus-restrictions':
      return ProspectusRestrictionsOverview;
    case 'value-at-risk':
      return ValueAtRiskOverview;
    // Pickers with exposure info:
    case 'exposure':
      return ExposureOverview;
    // All other general pickers:

    default:
      return GeneralSelectAFundOverview;
  }
};

const GeneralOverviewPage: FC<GeneralOverviewPageProps> = () => {
  const { section, pageId } = useParams<any>();

  const activeDate = useSelector(activeDateSelector);
  const availableDates = useSelector(availableDatesSelector);

  const ComponentForRender = generateComponentFromKey(pageId);

  return (
    <ComponentForRender
      section={section}
      positionDate={activeDate || undefined}
      availableDates={availableDates || undefined}
    />
  );
};

export default GeneralOverviewPage;
