import React, { ReactElement } from 'react';
import {
  addCommasToNumbersAndRound,
  formatMillions,
  percentageToTwoDecimalPlaces,
  toTwoDecimalPlaces,
} from '../../../../../utilities/numberFormatters';
import GenericBarChart from '../../../../charts/GenericBarChart';
import varComponentWrapper, {
  VarComponentProps,
  VarTableData,
  VarWrapperProps,
} from '../VarComponentWrapper.component';
import { useTheme } from '@mui/material';

const titleMap = {
  var: 'VaR Chart Data',
  cvar: 'C-VaR Chart Data',
  dvar: 'D-VaR Chart Data',
};

const VarNewMainChart: React.FC<VarComponentProps & VarWrapperProps> = (
  props,
): ReactElement => {
  const { renderData } = props;
  const transformedForChartData = renderData.reduce(
    (prev: { name: string; value: number }[], current: VarTableData) => {
      prev.push({
        name: current.portfolio,
        value: current.total,
      });
      return prev;
    },
    [],
  );
  const theme = useTheme();
  return (
    <GenericBarChart
      loading={false}
      yAxisLabel={{
        value: 'Exposure',
        angle: -90,
        position: 'left',
        offset: 0,
        style: {
          fill: theme.palette.grey[700],
          textAnchor: 'middle',
          fontSize: 14,
        },
      }}
      yAxisFormatter={(val) => {
        switch (props.riskOutput) {
          case 'Cash':
            return formatMillions(val);
          case 'absolute':
            // return roundNumberToNDecimalPlaces(Number(val) * 100, 0) + '%';
            return percentageToTwoDecimalPlaces(val);
          default:
            return val;
        }
      }}
      title={titleMap[props.riskMetric as 'var' | 'cvar' | 'dvar']}
      xAxes={[
        {
          dataKey: 'name',
          label: {
            value: 'Portfolio',
            position: 'bottom',
            offset: 5,
            style: {
              fill: theme.palette.grey[700],
              fontSize: 14,
            },
          },
          tickFormatter: (val) =>
            val.length > 12 ? val.substr(0, 12) + '...' : val,
        },
      ]}
      margin={{ bottom: 48, left: 32, right: 32, top: 0 }}
      tooltipFormatter={(value: any, name: any, tooltipProps: any) => {
        switch (props.riskOutput) {
          case 'Cash':
            return addCommasToNumbersAndRound(Number(value));
          case 'absolute':
            return percentageToTwoDecimalPlaces(value as number);
          case 'relative':
            return toTwoDecimalPlaces(value as number);
          default:
            return value;
        }
      }}
      data={transformedForChartData}
      width={'100%'}
      height={400}
      bars={[
        {
          dataKey: 'value',
          fill: theme.palette.primary.main,
          key: 'varchart_bars_value',
        },
      ]}
    />
  );
};

export default varComponentWrapper(VarNewMainChart);
