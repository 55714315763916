// This is a list of all possible SuperManco routes. These functions return the URLs required for fetching data.

import { formatDateForCheckingState } from '../../utilities/dateFormatters';

/**
 * Returns the URL for fetching EMIR breakdown data.
 */
export function getEMIRBreakdownURL() {
  return 'get_EMIR_breakdown';
}

/**
 * Returns the URL for fetching detailed Adepa EMIR breakdown data.
 */
export function getAdepaEMIRBreakdownDetailedURL() {
  return 'get_EMIR_breakdown_detailed';
}

/**
 * Returns the URL for fetching Manco GEM exposure data for a given position date.
 */
export function getMancoGemExposureURL(positionDate: string | undefined) {
  return `manco_gem/${positionDate}`;
}

/**
 * Returns the URL for fetching Manco counterparty dropdown options.
 */
export function getMancoCounterPartyDropdownOptionsURL() {
  return 'manco_counterparty_gem_data/manco_cp_key_data/';
}

/**
 * Returns the URL for fetching Manco counterparty exposure data for a given counterparty and position date.
 */
export function getMancoCounterPartyExposureURL(
  counterparty: string,
  positionDate: string | undefined,
) {
  return `manco_counterparty_gem_data/counterparty/${counterparty}/${
    positionDate ? positionDate : ''
  }`;
}

/**
 * Returns the URL for fetching date required for the historical data export.
 */
export function getRiskMetricsURL() {
  return 'get_risk_metrics';
}

/**
 * Returns the URL for fetching Manco asset liquidity data for BAU (Business As Usual) scenario.
 */
export function getMancoAssetLiquidityBAUURL(positionDate: string | undefined) {
  return `/manco_level_asset_liquidity/BAU/${positionDate}`;
}

/**
 * Returns the URL for fetching Manco asset liquidity data for Stressed scenario.
 */
export function getMancoAssetLiquidityStressedURL(
  positionDate: string | undefined,
) {
  return `/manco_level_asset_liquidity/Stressed/${positionDate}`;
}

/**
 * Returns the URL for fetching Manco asset liquidity data for Super-Stressed scenario.
 */
export function getMancoAssetLiquiditySuperStressedURL(
  positionDate: string | undefined,
) {
  return `/manco_level_asset_liquidity/Super-Stressed/${positionDate}`;
}

/**
 * Returns the URL for fetching Manco asset class fixed income data.
 */
export function getMancoAssetClassFixedIncomeURL(
  positionDate: string | undefined,
) {
  return `manco_level_asset_class_data/fixed_income${positionDate ? '/' + positionDate : ''}`;
}

/**
 * Returns the URL for fetching Manco asset class CIS data.
 */
export function getMancoAssetClassCISURL(positionDate: string | undefined) {
  return `manco_level_asset_class_data/cis${positionDate ? '/' + positionDate : ''}`;
}

/**
 * Returns the URL for fetching Manco asset class equity data.
 */
export function getMancoAssetClassEquityURL(positionDate: string | undefined) {
  return `manco_level_asset_class_data/equity${positionDate ? '/' + positionDate : ''}`;
}

export function loadPositionUpdateConfigsURL() {
  return 'position_update-load_configs';
}

export function getPositionUpdateFundURL(fundName: string) {
  return 'position_update-load_positions/' + fundName;
}

export function getPotentialHybridBondsURL() {
  return 'get_all_potential_hybrid_bonds';
}

/**
 * Returns the URL for fetching Manco report delivery status.
 */
export function getReportDeliveryOversightURL(
  weekStartDate: Date,
  today: Date,
): string {
  const formattedWeekStartDate = formatDateForCheckingState(weekStartDate);
  const formattedToday = formatDateForCheckingState(today);

  return formattedWeekStartDate === formattedToday
    ? 'getMancoReportDeliveryStatus'
    : `getMancoReportDeliveryStatus/${formattedWeekStartDate}`;
}

/**
 * Returns the URL for fetching Manco position comparison data.
 */
export function getMancoPositionComparisonURL(
  lookbackDays: string | number | undefined,
  positionDate: string | undefined,
): string {
  return `/manco_position_comparison/${lookbackDays}/${positionDate ? positionDate : ''}`;
}

export function getRestrictedCompaniesURL() {
  return 'get_restricted_lists_options';
}

/**
 * Returns the URL for fetching the restricted companies list based on the selected option.
 *
 */
export function getRestrictedCompaniesListURL(optionSelected: string): string {
  return `get_restricted_companies_list/${optionSelected}/`;
}

export function getSanctionScannerURL() {
  return '/sanctions_scanner/get_new_sanctions';
}

export function getRequestRecalculationURL(fundId: string) {
  return `request_recalculation/${fundId}`;
}
