import { FC } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { RaptorTheme, mainColors } from '../../styling/theme';
import { useDispatch } from 'react-redux';
import { updateTitleBarSelectValue } from '../../redux/pages/actions';
import GeneralSelect from './GeneralSelect';
import { TitleBarSelectValue } from '../../types/redux/pages/PagesStore';
export const useTitleBarSelectStyles = makeStyles<
  RaptorTheme,
  ITitleBarOptionsSelectStyles
>((theme) => ({
  pickerRoot: {
    display: 'flex',
    height: '4rem',
    overflow: 'hidden',
    marginRight: '1rem',
    borderRadius: 8,
    backgroundColor: theme.palette.grey[100],
    alignItems: 'center',
    color: theme.palette.grey[600],
  },
  selectRoot: {
    paddingLeft: '.8rem',
    paddingRight: '3rem',
    paddingTop: '1.1rem',
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    paddingBottom: '1.1rem',
    color: (props) =>
      props.currentlySelected === 'none_selected'
        ? mainColors.mainGold
        : theme.palette.primary.main,
  },
  formControlRoot: {
    borderRadius: 4,
    color: theme.palette.grey[600],
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    '& > div': {
      height: '100%',
    },
  },
}));

export interface TitleBarSelectProps {
  selectKeyName: string;
  selectTitle: string;
  selectedValue: string;
  labelsAndValues: TitleBarSelectValue[];
  placeholderValue?: string;
}

export interface ITitleBarOptionsSelectStyles {
  currentlySelected?: string;
}

const TitleBarSelect: FC<TitleBarSelectProps> = (props) => {
  const dispatch = useDispatch();
  const handleSelectChange = (selected: string) => {
    dispatch(updateTitleBarSelectValue(props.selectKeyName, selected));
  };
  const classes = useTitleBarSelectStyles({
    currentlySelected: props.selectedValue,
  });

  return (
    <GeneralSelect
      labelTitle="View Type: "
      labels={props.labelsAndValues.map(
        (val: TitleBarSelectValue) => val.label,
      )}
      values={props.labelsAndValues.map(
        (val: TitleBarSelectValue) => val.value,
      )}
      setSelected={handleSelectChange}
      selected={props.selectedValue}
      placeholderValue={props.placeholderValue || null}
      middleLabelValue={props.selectTitle}
      disableUnderline
      placeholderColor={mainColors.mainGold}
      customStyles={{
        pickerRoot: classes.pickerRoot,
        selectRoot: classes.selectRoot,
        formControl: classes.formControlRoot,
      }}
    />
  );
};

export default TitleBarSelect;
