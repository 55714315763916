import React, { FC, useEffect } from 'react';
import useTitleBarSelect from '../../../../../hooks/useTitleBarSelect';
import CisTable, { ExposureType } from './table/cisTable.component';
import useFetchData from '../../../../../hooks/useFetchData';
import { formatDateForCheckingState } from '../../../../../utilities/dateFormatters';
import GeneralComponentErrorShield from '../../../../general/GeneralComponentErrorShield';
import CisMaps from './maps/CisMaps';
import { GeneralOverviewPageRenderedComponentProps } from '../../../../../types/components/componentTypes';
import { useDispatch } from 'react-redux';
import { Select } from '../../../../../types/redux/pages/PagesStore';
import {
  addAdditionalTitleBarComponents,
  removeTitleBarComponent,
} from '../../../../../redux/pages/actions';
import {
  APPEND_NEW_SELECT_TO_TITLEBAR,
  REMOVE_SELECT_FROM_TITLEBAR,
} from '../../../../../types/redux/pages/pageTypes';

const MancoCis: FC<GeneralOverviewPageRenderedComponentProps> = ({
  positionDate,
}) => {
  const dispatch = useDispatch();

  // Add the select components to the title bar
  useEffect(() => {
    const selectsToAdd: Select[] = [
      {
        titleBarTitle: 'Exposure Type: ',
        titleBarKey: 'exposureType',
        values: [
          {
            label: 'Net Exposure, Base Currency',
            value: 'netExposureBaseCurrency',
          },
          {
            label: 'Net Exposure, % NAV',
            value: 'netExposurePcNav',
          },
        ],
        displayOnOverviewPage: true,
        aValueIsAlwaysSelected: true,
        currentValue: 'netExposureBaseCurrency',
      },
      {
        titleBarTitle: 'Visualisation: ',
        titleBarKey: 'selectVisualisation',
        values: [
          {
            label: 'Position Table',
            value: 'positionTable',
          },
          {
            label: 'Exposure Graphics',
            value: 'exposureGraphics',
          },
        ],
        displayOnOverviewPage: true,
        aValueIsAlwaysSelected: true,
        currentValue: 'positionTable',
      },
    ];

    dispatch(
      addAdditionalTitleBarComponents(
        selectsToAdd,
        APPEND_NEW_SELECT_TO_TITLEBAR,
      ),
    );

    return () => {
      dispatch(
        removeTitleBarComponent('exposureType', REMOVE_SELECT_FROM_TITLEBAR),
      );
      dispatch(
        removeTitleBarComponent(
          'selectVisualisation',
          REMOVE_SELECT_FROM_TITLEBAR,
        ),
      );
    };
  }, []);

  const exposureType = useTitleBarSelect('exposureType');
  const selectedVisualisation = useTitleBarSelect('selectVisualisation');

  const cisData = useFetchData({
    keyName: `manco_asset_class_cis_data_${
      positionDate || formatDateForCheckingState(new Date())
    }`,
    url: `manco_level_asset_class_data/cis${
      positionDate ? '/' + positionDate : ''
    }`,
    makeFetch: true,
  });

  return (
    <GeneralComponentErrorShield
      dataObjects={[cisData]}
      customErrorMessage="No In-Scope or Available Data"
    >
      {selectedVisualisation === 'positionTable' ? (
        <CisTable
          inputData={cisData}
          selectedExposureType={
            (exposureType as ExposureType) || 'netExposureBaseCurrency'
          }
        />
      ) : (
        <CisMaps
          inputData={cisData}
          selectedExposureType={
            (exposureType as ExposureType) || 'netExposureBaseCurrency'
          }
        />
      )}
    </GeneralComponentErrorShield>
  );
};

export default MancoCis;
