import { FC } from 'react';
import { CustomColumn } from '../../../../types/components/tables/tableTypes';
import {
  numberToXDecimalPlaces,
  percentageToNdecialPlaces,
  roundToTwoDecimalPlacesReturnNumber,
  toFourDecimalPlacesIfNumber,
} from '../../../../utilities/numberFormatters';
import GridItem from '../../../layout/GridComponents/GridItem';
import CustomTable from '../../../tables/CustomTable';
import NoDataMessage from '../../../feedback/NoDataMessage.component';
import { RaptorTheme, mainColors } from '../../../../styling/theme';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ReferenceArea,
  XAxis,
  YAxis,
} from 'recharts';
import makeStyles from '@mui/styles/makeStyles';
import { hexToRGBA } from '../../../../utilities/colorUtilities';

interface VariableMarketVolumeLiquidationCostsTablesProps {
  data: any;
  selectedScenario: string | null;
}

interface IndividualTableProps {
  data: any;
  title: any;
  scenario: string | null;
}

interface IIndividualTableData {
  navChange: number;
  bau: number;
  bauElevated: number;
  bauStressed: number;
  elevated: number;
  elevatedStressed: number;
  stressed: number;
}

function getScenarioNavChange(scenario: string | null) {
  switch (scenario) {
    case 'redemption_liquidation_cost_4_5':
      return 0.05;
    case 'redemption_liquidation_cost_20_1':
      return 0.2;
    case 'redemption_liquidation_cost_20w_5':
      return 0.2;
    default:
      return 0;
  }
}

function buildIndividualTableData(
  data: any,
  key: string,
  scenario: string | null,
): IIndividualTableData[] {
  if (!data.data.length) return [];
  if (!scenario) return [];
  if (!('liquidation_cost_data' in data.data[0])) return [];
  if (!(key in data.data[0].liquidation_cost_data)) return [];
  const scenarioData = data.data[0].liquidation_cost_data[key];
  const scenarioDataNoHeader = scenarioData.slice(1);
  const tableData: IIndividualTableData[] = [];

  scenarioDataNoHeader.forEach((value: any, index: number) => {
    tableData.push({
      navChange: getScenarioNavChange(scenario) * (index + 1),
      bau: value[0],
      bauElevated: value[1],
      bauStressed: value[2],
      elevated: value[3],
      elevatedStressed: value[4],
      stressed: value[5],
    });
  });
  return tableData;
}

type GraphChartType = { [index: string]: number };

const buildChartData = (
  data: any,
  dataKeys: string[][],
  scenario: string | null,
) => {
  const chartData: { [index: string]: GraphChartType[] } = {};
  if (!data.data.length) return chartData;
  if (!scenario) return chartData;
  if (!('liquidation_cost_data' in data.data[0])) return chartData;
  dataKeys.forEach((key) => {
    if (!(key[1] in data.data[0].liquidation_cost_data)) return [];
    const scenarioData = data.data[0].liquidation_cost_data[key[1]];
    const scenarioDataNoHeader = scenarioData.slice(1);
    const newChart: GraphChartType[] = [];
    scenarioDataNoHeader.forEach((group: number[], index: number) => {
      const newGroup: GraphChartType = {
        group: roundToTwoDecimalPlacesReturnNumber(
          getScenarioNavChange(scenario) * (index + 1),
        ),
      };
      group.forEach((item: number, index: number) => {
        newGroup[scenarioData[0][index]] = numberToXDecimalPlaces(item, 3);
      });
      newChart.push(newGroup);
    });
    chartData[key[1]] = newChart;
  });
  return chartData;
};

// [2022-12-13] - Tom Walsh
// I had tried to build a new table with react-table so as to allow for highlighting of corresponding values between table and chart
// However I began to run into unforseen issues, one of which was extremely slow load times, so I have commented out these changes
// I may return to this at some point with a fresh set of eyes

// export type DataGroup = {
//   'NAV': string;
//   'BAU / BAU': number;
//   'BAU / Elevated': number;
//   'BAU / Stressed': number;
//   'Elevated / Elevated': number;
//   'Elevated / Stressed': number;
//   'Stressed / Stressed': number;
// };

// const indexes = [ '5%', '10%', '15%', '20%', '25%' ];

// const columns: ColumnDef<DataGroup>[] = [
//   {
//     id: 'NAV',
//     accessorKey: 'NAV',
//     header: () => 'NAV',
//   },
//   {
//     id: 'BAU / BAU',
//     accessorKey: 'BAU / BAU',
//     header: () => 'BAU / BAU',
//   },
//   {
//     id: 'BAU / Elevated',
//     accessorKey: 'BAU / Elevated',
//     header: () => 'BAU / Elevated',
//   },
//   // {
//   //   id: 'BAU / Stressed',
//   //   accessorKey: 'BAU / Stressed',
//   //   header: () => 'BAU / Stressed',
//   // },
//   {
//     id: 'Elevated / Elevated',
//     accessorKey: 'Elevated / Elevated',
//     header: () => 'Elevated / Elevated',
//   },
//   {
//     id: 'Elevated / Stressed',
//     accessorKey: 'Elevated / Stressed',
//     header: () => 'Elevated / Stressed',
//   },
//   {
//     id: 'Stressed / Stressed',
//     accessorKey: 'Stressed / Stressed',
//     header: () => 'Stressed / Stressed',
//   },
// ];

// const buildTableData = (data: any, dataKeys: string[][], scenario: string | null) => {
//   const tableData: { [index: string]: Table<DataGroup>} = {};
//   if (!data.data.length) return tableData;
//   if (!scenario) return tableData;
//   if (!('liquidation_cost_data' in data.data[0])) return tableData;
//   dataKeys.forEach((key) => {
//     if (!(key[1] in data.data[0].liquidation_cost_data)) return [];
//     const scenarioData = data.data[0].liquidation_cost_data[key[1]];
//     const scenarioDataNoHeader = scenarioData.slice(1);
//     const newTable: DataGroup[] = [];
//     scenarioDataNoHeader.forEach((group: number[], index: number) => {
//       const newRow: any = {
//         'NAV': indexes[index],
//         'BAU / BAU': 0,
//         'BAU / Elevated': 0,
//         'BAU / Stressed': 0,
//         'Elevated / Elevated': 0,
//         'Elevated / Stressed': 0,
//         'Stressed / Stressed': 0,
//       }
//       group.forEach((item: number, index: number) => {
//         if (scenarioData[0][index] as keyof DataGroup) {
//           newRow[scenarioData[0][index] as keyof DataGroup] = item;
//         }
//       })
//       newTable.push(newRow as DataGroup);
//     })
//     tableData[key[1]] = useReactTable<DataGroup>({
//       data: newTable,
//       columns,
//       getCoreRowModel: getCoreRowModel(),
//       debugTable: true,
//       debugHeaders: true,
//       debugColumns: true,
//     });
//   })

//   return tableData;
// }

const detailColumns: CustomColumn<IIndividualTableData>[] = [
  {
    title: 'Nav Change',
    field: 'navChange',
    cellStyle: {
      textAlign: 'center',
      borderRight: `1px solid ${mainColors.mainBlue}`,
    },
    headerStyle: { textAlign: 'center' },
    render: (rowData) => '-' + percentageToNdecialPlaces(rowData.navChange, 0),
  },
  {
    title: 'BAU/BAU',
    field: 'bau',
    cellStyle: { textAlign: 'center' },
    headerStyle: { textAlign: 'center' },
    render: (rowData) => percentageToNdecialPlaces(rowData.bau, 3),
  },
  {
    title: 'BAU/Elevated',
    field: 'bauElevated',
    cellStyle: { textAlign: 'center' },
    headerStyle: { textAlign: 'center' },
    render: (rowData) => percentageToNdecialPlaces(rowData.bauElevated, 3),
  },
  {
    title: 'Elevated/Elevated',
    field: 'elevated',
    cellStyle: { textAlign: 'center' },
    headerStyle: { textAlign: 'center' },
    render: (rowData) => percentageToNdecialPlaces(rowData.elevated, 3),
  },
  {
    title: 'Elevated/Stressed',
    field: 'elevatedStressed',
    cellStyle: { textAlign: 'center' },
    headerStyle: { textAlign: 'center' },
    render: (rowData) => percentageToNdecialPlaces(rowData.elevatedStressed, 3),
  },
  {
    title: 'Stressed/Stressed',
    field: 'stressed',
    cellStyle: { textAlign: 'center' },
    headerStyle: { textAlign: 'center' },
    render: (rowData) => percentageToNdecialPlaces(rowData.stressed, 3),
  },
];

const IndividualTable: FC<IndividualTableProps> = (props) => {
  // const theme = useTheme();
  const { data, title, scenario } = props;
  const tableData = buildIndividualTableData(data, title[1], scenario);

  return (
    <>
      <CustomTable<IIndividualTableData>
        pdfTitle={title[0]}
        id={title[0]}
        columns={detailColumns}
        showToolbar
        data={tableData}
        // toolbarComponents={{
        //     toolbarTitle: formattedNames[tableType],
        // }}
        title={title[0]}
        options={{
          paging: false,
          search: false,
          exportButton: true,
        }}
      />
    </>
  );
};

const useStyles = makeStyles<RaptorTheme>((theme) => ({
  dataArea: {
    width: '100%',
    display: 'flex',
    margin: '2rem 0',
    '@media only screen and (max-width: 1600px)': {
      flexDirection: 'column',
    },
  },
  card: {
    flex: 1,
    padding: '1rem',
    minHeight: '40rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  group: {
    height: '5rem',
    width: '5rem',
    backgroundColor: 'red',
  },
  customLabelContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  customLabelTop: {
    fontSize: '1.3rem',
    fontWeight: 500,
    color: mainColors.mainBlue,
  },
  customLabelBottom: {
    fontSize: '1.3rem',
    fontWeight: 700,
    color: mainColors.mainBlue,
  },
  // tableContainer: {
  //   maxHeight: '80rem',
  //   overflow: 'auto',
  //   marginBottom: '2rem',
  //   width: '100%',
  // },
  // table: {
  //   tableLayout: 'fixed',
  //   paddingBottom: '2rem',
  //   borderCollapse: 'collapse',
  //   minWidth: '100%',
  // },
  // row: {
  //   display: 'flex',
  //   borderTop: `1px solid ${mainColors.lightGrey}`,
  // },
  // headerRow: {
  //   display: 'flex',
  //   borderBottom: '2px solid grey',
  // },
  // cell: {
  //   ...theme.typography.h3,
  //   fontWeight: 500,
  //   textAlign: 'center',
  //   fontSize: '1.3rem',
  //   width: '100%',
  //   minWidth: '5rem',
  //   minHeight: '5rem',
  //   padding: '1rem',
  //   display: 'flex',
  //   justifyContent: 'center',
  //   alignItems: 'center',
  //   color: 'black',
  // },
}));

const barChartColours: string[] = [
  '#c5d7ec',
  '#9fbde0',
  '#79a2d3',
  '#5288c6',
  '#396ead',
  '#2c5686',
  '#1f3d60',
];

function getDataKeys(data: any) {
  if (!data.data.length) return [];
  if (!('liquidation_cost_data' in data.data[0])) return [];
  if (!('data_keys' in data.data[0].liquidation_cost_data)) return [];
  return data.data[0].liquidation_cost_data.data_keys;
}

interface CustomizedLabelProps {
  title: string | number;
  x: number;
}

const CustomizedLabel: FC<CustomizedLabelProps> = (props) => {
  const classes = useStyles();
  return (
    <g>
      <foreignObject x={props.x} y={100} width={'8rem'} height={'5rem'}>
        <div className={classes.customLabelContainer}>
          <div className={classes.customLabelTop}>NaV Change:</div>
          <div className={classes.customLabelBottom}>
            -{percentageToNdecialPlaces(props.title, 0)}
          </div>
        </div>
      </foreignObject>
    </g>
  );
};

// class CustomizedLabel extends React.Component{
//   render() {
//         return (
//           <g>
//             <foreignObject x={0} y={0} width={100} height={100}>
//               <div>Label</div>
//             </foreignObject>
//           </g>
//         );
//   }
// };

const VariableMarketVolumeLiquidationCostsTables: FC<
  VariableMarketVolumeLiquidationCostsTablesProps
> = (props) => {
  const classes = useStyles();

  const { data, selectedScenario } = props;
  const dataKeys: string[][] = getDataKeys(data);

  const chartData = buildChartData(data, dataKeys, selectedScenario);
  // const tableData = buildTableData(data, dataKeys, selectedScenario);

  // const handleHoverBar = (bar: string, index: number, state: any) => {
  //   // console.log(state)
  //   // console.log(state[bar])
  // }

  return dataKeys.length ? (
    <>
      {dataKeys.map((key: string[]) => (
        <div className={classes.dataArea}>
          <GridItem card className={classes.card}>
            {/* <table className={classes.table}>
              <thead className={classes.tableHead}>
                {tableData[key[1]].getHeaderGroups().map((headerGroup) => (
                  <tr key={headerGroup.id} className={classes.headerRow}>
                    {headerGroup.headers.map((header) => {
                      <th
                        key={header.id}
                      >
                        <>
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                        </>
                      </th>
                    })}
                  </tr>
                ))}
              </thead>
              <tbody>
                {tableData[key[1]].getRowModel().rows.map((row) => {
                  return (
                    <Fragment key={row.id}>
                      <tr
                        className={classes.row}
                      >
                        {row.getAllCells().map((cell) => {
                          return (
                            <td className={classes.cell}>
                              {flexRender(
                                cell.column.columnDef.cell,
                                cell.getContext()
                              )}
                            </td>
                          )
                        })}
                      </tr>
                    </Fragment>
                  )
                })}
              </tbody>
            </table> */}
            <IndividualTable
              data={data}
              title={key}
              scenario={selectedScenario}
              key={'table_${index'}
            />
          </GridItem>
          <GridItem card className={classes.card}>
            <BarChart
              width={800}
              height={400}
              data={chartData[key[1]]}
              margin={{ top: 25, right: 25, left: 0, bottom: 25 }}
            >
              <XAxis dataKey="group" hide={true} />
              <YAxis
                type="number"
                domain={[
                  0,
                  (dataMax: number) => toFourDecimalPlacesIfNumber(dataMax),
                ]}
              />
              <CartesianGrid strokeDasharray="3 3" vertical={false} />
              <Legend verticalAlign={'top'} height={40} />
              {Object.keys(chartData[key[1]][0]).map(
                (bar: string, index: number) => {
                  if (bar !== 'group') {
                    return (
                      <Bar
                        key={`${index}_${bar}`}
                        dataKey={bar}
                        fill={barChartColours[index]}
                        // onMouseEnter={(state) => handleHoverBar(bar, index, state)}
                        // onMouseLeave={() => console.log('goodbye')}
                      />
                    );
                  }
                },
              )}
              {chartData[key[1]].map((data: GraphChartType, index: number) => {
                return (
                  <ReferenceArea
                    x1={data.group}
                    x2={data.group}
                    stroke={hexToRGBA(mainColors.mainBlue, 0.3)}
                    fill={hexToRGBA(mainColors.mainBlue, 0.1)}
                    label={
                      <CustomizedLabel
                        title={data.group}
                        x={
                          80 + (800 / (chartData[key[1]].length + 0.6)) * index
                        }
                      />
                    }
                  />
                );
              })}
            </BarChart>
          </GridItem>
        </div>
      ))}
    </>
  ) : (
    <NoDataMessage />
  );
};

export default VariableMarketVolumeLiquidationCostsTables;
