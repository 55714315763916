import React from 'react';
import {
  NonCisData,
  generateNonCisColumns,
  generateNonCisTableData,
} from '../noncis.data';
import CustomTable from '../../../../tables/CustomTable';
import DisplayAreaCenteredWrapper from '../../../../layout/utilities/displayAreaWrapper';
import { DataObject } from '../../../../../types/redux/data/dataTypes';
import GridItem from '../../../../layout/GridComponents/GridItem';
import { CustomColumn } from '../../../../../types/components/tables/tableTypes';
import { useTheme } from '@mui/material';
export type ExposureType = 'netExposureBaseCurrency' | 'netExposurePcNav';
export interface NonCisComponentProps {
  inputData: DataObject;
  selectedExposureType: ExposureType;
}
const NonCisTable = (props: NonCisComponentProps) => {
  const dataForUse = generateNonCisTableData(
    props.inputData.data,
    props.selectedExposureType,
  );
  const columns = generateNonCisColumns(props.selectedExposureType);

  const theme = useTheme();
  return !props.inputData.isFetching ? (
    dataForUse.length ? (
      <GridItem xs={12} card>
        <CustomTable<NonCisData>
          id="assetClassNonCisTable"
          title="Non CIS"
          options={{
            paging: false,
            exportButton: true,
          }}
          csvFields={columns.map(
            (col: CustomColumn<NonCisData>) => col.field as string,
          )}
          data={dataForUse}
          columns={columns}
          showToolbar
        />
      </GridItem>
    ) : (
      <DisplayAreaCenteredWrapper>
        <h1 style={{ color: theme.palette.primary.main }}>
          No relevant records to display
        </h1>
      </DisplayAreaCenteredWrapper>
    )
  ) : null;
};

export default NonCisTable;
