import { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { clientNameSelector } from '../../../../../redux/auth/selectors';
import { mainColors } from '../../../../../styling/theme';
import { CustomColumn } from '../../../../../types/components/tables/tableTypes';
import { Status } from '../../../../../types/redux/data/dataTypes';
import { mapStatusToColor } from '../../../../../utilities/colorUtilities';
import { percentageToTwoDecimalPlaces } from '../../../../../utilities/numberFormatters';
import GridItem from '../../../../layout/GridComponents/GridItem';
import CustomTable from '../../../../tables/CustomTable';
import { Typography } from '@mui/material';

interface FundBreakdownContainerProps {
  data: any;
}

interface IFundBreakdownTableData {
  fundId: string;
  fundName: string;
  averageTime: number;
  T: number;
  TPercentage: number;
  TPlus1: number;
  TPlus1Percentage: number;
  TPlus2: number;
  TPlus2Percentage: number;
  totalReports: number;
  index: number;
}

function buildFundBreakdownColumns(
  clientName: string | null,
): CustomColumn<IFundBreakdownTableData>[] {
  const fundBreakdownTableColumns: CustomColumn<IFundBreakdownTableData>[] = [
    {
      title: 'Fund Id',
      field: 'fundId',
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: { textAlign: 'center' },
      render: (rowData: IFundBreakdownTableData) =>
        clientName && clientName.includes('mersenne')
          ? `Fund${rowData.index + 1}`
          : rowData.fundId,
    },
    {
      title: 'Fund Name',
      field: 'fundName',
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: { textAlign: 'center' },
      render: (rowData: IFundBreakdownTableData) =>
        clientName && clientName.includes('mersenne')
          ? `Mersenne Fund${rowData.index + 1}`
          : rowData.fundName,
    },
    {
      title: 'Total Reports in period',
      field: 'totalReports',
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: { textAlign: 'center' },
    },
    {
      title: 'T Delivery',
      field: 'T',
      cellStyle: {
        textAlign: 'center',
        color: mainColors.Pass_darker,
        fontWeight: 800,
      },
      headerStyle: {
        textAlign: 'center',
      },
    },
    {
      title: 'T Delivery %',
      field: 'TPercentage',
      cellStyle: {
        textAlign: 'center',
        color: mainColors.Pass_darker,
        fontWeight: 800,
      },
      headerStyle: {
        textAlign: 'center',
      },
      render: (rowData: IFundBreakdownTableData) =>
        percentageToTwoDecimalPlaces(rowData.TPercentage),
    },
    {
      title: 'T+1 Delivery',
      field: 'TPlus1',
      cellStyle: {
        textAlign: 'center',
        color: mainColors.Alert_darker,
        fontWeight: 800,
      },
      headerStyle: { textAlign: 'center' },
    },
    {
      title: 'T+1 Delivery %',
      field: 'TPlus1Percentage',
      cellStyle: {
        textAlign: 'center',
        color: mainColors.Alert_darker,
        fontWeight: 800,
      },
      headerStyle: { textAlign: 'center' },
      render: (rowData: IFundBreakdownTableData) =>
        percentageToTwoDecimalPlaces(rowData.TPlus1Percentage),
    },
    {
      title: 'T+2 Delivery',
      field: 'TPlus2',
      cellStyle: {
        textAlign: 'center',
        color: mainColors.Fail_darker,
        fontWeight: 800,
      },
      headerStyle: { textAlign: 'center' },
    },
    {
      title: 'T+2 Delivery %',
      field: 'TPlus2Percentage',
      cellStyle: {
        textAlign: 'center',
        color: mainColors.Fail_darker,
        fontWeight: 800,
      },
      headerStyle: { textAlign: 'center' },
      render: (rowData: IFundBreakdownTableData) =>
        percentageToTwoDecimalPlaces(rowData.TPlus2Percentage),
    },
  ];

  return fundBreakdownTableColumns;
}

function buildFundBreakdownData(data: any): IFundBreakdownTableData[] {
  if (!data.data.length) return [];
  if (!('funds' in data.data[0])) return [];
  const fundsData = data.data[0].funds;
  const tableData: IFundBreakdownTableData[] = [];

  fundsData.forEach((value: any, index: number) => {
    tableData.push({
      fundId: value['Fund ID'] || 'Aggregate',
      fundName: value['Fund Name'] || 'Aggregate',
      averageTime: value['Average delivery time (Minutes)'],
      T: value['T Delivery (Reports)'],
      TPercentage: value['T Delivery (Percentage)'],
      TPlus1: value['T+1 Delivery (Reports)'],
      TPlus1Percentage: value['T+1 Delivery (Percentage)'],
      TPlus2: value['T+2 Delivery (Reports)'],
      TPlus2Percentage: value['T+2 Delivery (Percentage)'],
      totalReports: value['Total Reports'],
      index: index,
    });
  });

  return tableData.sort(
    (a: IFundBreakdownTableData, b: IFundBreakdownTableData) =>
      a.fundId.localeCompare(b.fundId, undefined, {
        numeric: true,
        sensitivity: 'base',
      }),
  );
}

const StatusBox = ({ status, text }: { status: Status; text: string }) => (
  <div
    style={{
      padding: '.8rem',
      borderRadius: 8,
      border: `1px solid ${mapStatusToColor(status)}`,
      backgroundColor: mapStatusToColor(status),
      // maxWidth: '40rem'
    }}
  >
    <Typography
      variant="h3"
      style={{
        transform: 'translateY(1px)',
        color: 'white',
        fontSize: '1.8rem',
      }}
    >
      {text}
    </Typography>
  </div>
);

function FundBreakdownContainer({
  data,
}: FundBreakdownContainerProps): ReactElement {
  const clientName = useSelector(clientNameSelector);

  return (
    <GridItem card key="manco-overview" item xs={12}>
      <CustomTable<IFundBreakdownTableData>
        columns={buildFundBreakdownColumns(clientName)}
        showToolbar
        data={buildFundBreakdownData(data)}
        title="Aggregated Data"
        options={{
          paging: false,
          search: true,
          exportButton: true,
        }}
        pdfDontMoveToNewPage={true}
      />
    </GridItem>
  );
}

export default FundBreakdownContainer;
