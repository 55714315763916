import { Dispatch, FC, SetStateAction, useState } from 'react';
import DisplayToggle from './components/displayToggle/displayToggle.component';
import KeySelect from './components/key-select/key-select.component';
import { ShowChart } from '@mui/icons-material';
import { List } from '@mui/material';
import useControlBarStyles from './controlBar.styles';

export type ValuesType = {
  key1: string;
  key2: string;
  fromDate: string | null;
  toDate: string | null;
};
export interface ControlBarProps {
  showFour: boolean;
  displaySelect: boolean;
  showTable?: boolean;
  setShowTable?: Dispatch<SetStateAction<boolean>>;
  external?: boolean;
  values: ValuesType;
  handleChoice: (choice: ValuesType) => void;
}

const ControlBar: FC<ControlBarProps> = (props) => {
  const classes = useControlBarStyles({
    fourShowing: props.showFour && !props.displaySelect,
    external: props.external || false,
  });

  const [currentValues, setCurrentValues] = useState<ValuesType>(props.values);

  const handleUpdateValues = (
    choice: string | null,
    type: 'key1' | 'key2' | 'fromDate' | 'toDate',
  ) => {
    const newValues: ValuesType = {
      ...currentValues,
      [type]: choice,
    };
    setCurrentValues(newValues);
    props.handleChoice(newValues);
  };

  return (
    <div className={classes.controlBarContainer}>
      <KeySelect
        fourShowing={props.showFour}
        inputKey={props.values.key1}
        setInputKey={(choice: string | null) =>
          handleUpdateValues(choice, 'key1')
        }
        title="Set historical data key 1"
        external={props.external}
      />
      <KeySelect
        fourShowing={props.showFour}
        inputKey={props.values.key2}
        setInputKey={(choice: string | null) =>
          handleUpdateValues(choice, 'key2')
        }
        title="Set historical data key 2"
        external={props.external}
      />
      {props.setShowTable && (
        <DisplayToggle
          choice={props.showTable!}
          setChoice={props.setShowTable}
          Icon1={ShowChart}
          iconRootStyles={classes.showTableToggle}
          tooltip={
            !props.external
              ? {
                  tooltipPlacement: 'right',
                  choice1Text: 'show table?',
                  choice2Text: 'show time series?',
                }
              : undefined
          }
          Icon2={List}
        />
      )}
    </div>
  );
};

export default ControlBar;
