import RaptorLoading from '../../../../../../feedback/RaptorLoading';
import useExceedanceSecondaryData from './fetchSecondaryHook';
import {
  ExceedanceSecondaryData,
  generateExceedanceSecondaryColumns,
  generateExceedanceSecondaryData,
} from './secondaryTable.data';
import CustomTable from '../../../../../../tables/CustomTable';
import { hexToRGBA } from '../../../../../../../utilities/colorUtilities';
import { mainColors } from '../../../../../../../styling/theme';
import { useTheme } from '@mui/material';

interface ExceedanceSecondaryProps {
  tValue: string;
  tPlusOneValue: string;
}

const ExceedanceSecondaryTable = (
  props: ExceedanceSecondaryProps,
): JSX.Element | null => {
  const { tValue, tPlusOneValue } = props;
  const theme = useTheme();
  const dataForUse = useExceedanceSecondaryData({
    fetchString: `market_historical_analysis/${tValue}/${tPlusOneValue}/data/market`,
  });
  const { data, isFetching, error } = dataForUse;

  const tableData = data.length
    ? generateExceedanceSecondaryData(data[0].data)
    : [];
  const columns = generateExceedanceSecondaryColumns();
  //
  //
  const csvFields = ['name', 'start', 'end', 'change', 'returnPc'];

  return dataForUse ? (
    !isFetching ? (
      !error ? (
        <CustomTable<ExceedanceSecondaryData>
          columns={columns}
          data={tableData}
          showToolbar
          toolbarComponents={{
            toolbarTitle: `${tValue} - ${tPlusOneValue}`,
            toolbarStyle: {
              backgroundColor: hexToRGBA(mainColors.mainBlue, 0.1),
            },
            titleStyle: {
              fontSize: '1.8rem',
            },
          }}
          csvFields={csvFields}
          options={{
            paging: false,
            exportButton: true,
            search: true,
            rowStyle: {
              backgroundColor: theme.palette.grey[100],
            },
          }}
        />
      ) : (
        <h1>There was an error</h1>
      )
    ) : (
      <RaptorLoading setWidth="8rem" />
    )
  ) : null;
};

export default ExceedanceSecondaryTable;
