import React, { useEffect } from 'react';

import {
  allPagesSelector,
  clientConfigurationSelector,
  clientSectionsSelector,
} from '../redux/pages/selectors';
import { Section } from '../types/server-data/ClientConfig';
import { useDispatch, useSelector } from 'react-redux';
import {
  configNameSelector,
  isLoggedInSelector,
} from '../redux/auth/selectors';
import { Redirect, Switch } from 'react-router-dom';
import { fetchClientConfig } from '../redux/pages/actions';
import LoggedInRoute from './LoggedInRoute';
import NotFound from './NotFound';
import LoggedOutRoute from './LoggedOutRoute';
import Dashboard from '../components/pages/dashboard/Dashboard';
import GeneralPage from '../components/general/GeneralPage';
import GeneralComponentErrorShield from '../components/general/GeneralComponentErrorShield';
import axios from 'axios';
// import QrCodeReset from '../components/pages/qrCodeReset/QrCodeReset.component';
import DocumentationViewer from '../components/documentation/DocumentationViewer.component';
import SettingsRouter from '../components/settings/SettingsRouter.component';
import Signup from '../components/pages/authentication_pages/signup/Signup.component';
import ResetPassword from '../components/pages/authentication_pages/resetPassword/ResetPassword.component';
import Login from '../components/pages/authentication_pages/login/Login';
import RaidrRouter from './RaidrRouter.component';

const Pages = () => {
  const dispatch = useDispatch();

  // in case the first page in a section isn't the overview
  const isAuthenticated = useSelector(isLoggedInSelector);
  const clientSections = useSelector(clientSectionsSelector);
  const allPages = useSelector(allPagesSelector);

  const configName = useSelector(configNameSelector);

  const clientConfig = useSelector(clientConfigurationSelector);

  const unconventionalRedirects =
    clientSections && allPages
      ? clientSections
          .map((section: Section) => {
            return (
              <Redirect
                key={section.sectionId}
                exact
                from={'/' + section.sectionId}
                to={'/' + section.sectionId + '/' + section.pages[0]}
              />
            );
          })
          // filter out any that just go to overview
          .filter((el) => el && true)
      : [];

  const cancelToken = axios.CancelToken;
  const source = cancelToken.source();
  useEffect(() => {
    if (isAuthenticated && configName) {
      dispatch(fetchClientConfig(configName, source));
    }

    return () => {
      // source.cancel('cancelling ');
    };
  }, [isAuthenticated]);

  const configError = `No client configuration for ${configName} was detected. Please contact the RiskSystem administrator.`;

  return (
    <GeneralComponentErrorShield
      dataObjects={clientConfig}
      customErrorMessage={configError}
      customLoadingMessages={['Loading your Raptor configuration...']}
    >
      <Switch>
        <Redirect exact from="/" to="/dashboard" />
        <LoggedInRoute exact path="/dashboard" Component={Dashboard} />
        {/* <LoggedOutRoute exact path="/reset-qr" component={QrCodeReset} /> */}
        <LoggedOutRoute exact path="/signup" component={Signup} />
        <LoggedOutRoute exact path="/login" component={Login} />
        <LoggedOutRoute
          exact
          path="/reset-password"
          component={ResetPassword}
        />
        {unconventionalRedirects}

        <LoggedInRoute path="/raidr/:pagePart1" Component={RaidrRouter} />

        <LoggedInRoute path="/settings" Component={SettingsRouter} />
        <LoggedInRoute path="/documentation" Component={DocumentationViewer} />
        <LoggedInRoute exact path="/:section/:pageId" Component={GeneralPage} />
        <LoggedInRoute Component={NotFound} />
      </Switch>
    </GeneralComponentErrorShield>
  );
};
export default React.memo(Pages);
