import React, { Dispatch, FC, useEffect, useState } from 'react';
import GridItem from '../../../../../layout/GridComponents/GridItem';
import { pdfjs } from 'react-pdf';
import Button from '@mui/material/Button';
import { RaptorTheme, mainColors } from '../../../../../../styling/theme';
import client, { BASE_URL } from '../../../../../../utilities/requestClient';
import CachedIcon from '@mui/icons-material/Cached';
import ReplyIcon from '@mui/icons-material/Reply';
import RaptorLoading from '../../../../../feedback/RaptorLoading';
import { useWindowSize } from 'react-use';
import fileDownload from 'js-file-download';
import SaveIcon from '@mui/icons-material/Save';
import useSnackbar from '../../../../../../hooks/useSnackbar';
import makeStyles from '@mui/styles/makeStyles';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

interface KiidsViewerProps {
  fundId: string | null;
  fundName: string | null;
  lastEditedDate: string | null;
  isin: string | null;
  documentLanguage: string | null;
  kiidsData: any[];
  changesMade: boolean;
  setChangesMade: Dispatch<any>;
  canBePublished: boolean;
  setCanBePublished: Dispatch<any>;
  templateUpdates: any;
  version: string | null;
}

const useStyles = makeStyles<RaptorTheme>(() => ({
  parentContainer: {
    minHeight: '20rem',
    display: 'flex',
    flexDirection: 'column',
    margin: '2rem',
    gap: '2rem',
    width: '100%',
  },
  buttonsContainer: {
    display: 'flex',
    gap: '1rem',
  },
  button: {
    height: '3rem',
    // marginTop: '0.2rem',
    marginRight: '1rem',
    width: '10rem',
  },
  viewerContainer: {
    width: '95%',
    height: '80%',
  },
}));
const KiidsViewer: FC<KiidsViewerProps> = (props) => {
  const classes = useStyles();
  const requestClient = client();

  const [pdfDoc, setPdfDoc] = useState(null);
  const [pdfDocLoading, setPdfDocLoading] = useState(false);
  const [lastEditedDate, setLastEditedDate] = useState(props.lastEditedDate);

  const { height } = useWindowSize();

  const refreshPdf = () => {
    // Check if the required fields have been filled in.
    if (!props.kiidsData || !props.kiidsData.length) {
      return;
    }
    const content: any[] = [];
    props.kiidsData.forEach((element: any) => {
      content.push(element);
      // content.push(element.content);
    });

    // Create an object of formData
    const formData = new FormData();

    const kiidsJson = JSON.stringify(props.kiidsData);
    // Update the formData object.
    formData.append('contents', kiidsJson);
    formData.append('fund_id', props.fundId || '');
    formData.append('fund_name', props.fundName || '');
    formData.append('isin', props.isin || '');
    formData.append('document_language', props.documentLanguage || '');
    formData.append('dev_live_version', 'live');
    formData.append(
      'params',
      JSON.stringify([
        'contents',
        'fund_id',
        'fund_name',
        'isin',
        'document_language',
        'dev_live_version',
      ]),
    );

    setPdfDocLoading(true);

    requestClient
      .post('risksystem_pdf_generator/generate_new_kiid', formData, {
        headers: {
          accept: 'application/pdf',
        },
        responseType: 'blob',
      })
      .then((response: { data: BlobPart }) => {
        const blob = new Blob([response.data], { type: 'application/pdf' });
        const file = window.URL.createObjectURL(blob);
        const iframe = document.querySelector('iframe');
        if (iframe?.src) {
          iframe.src = file;
          iframe.title = 'fact_sheet.pdf';
        }
        setPdfDocLoading(false);
      });
  };

  useEffect(() => {
    refreshPdf();
  }, [props.kiidsData]);

  function downloadFile() {
    const mapForm = document.createElement('form');
    mapForm.target = '_self';
    mapForm.method = 'POST';

    mapForm.action =
      BASE_URL +
      `risksystem_pdf_generator/generate_new_kiid/${props.fundName}_${props.isin}_${props.version}.pdf`;

    // Create an input for the form
    const contentsInput = document.createElement('input');
    if (!props.kiidsData || !props.kiidsData.length) {
      return;
    }
    const content: any[] = [];
    props.kiidsData.forEach((element: any) => {
      content.push(element);
    });
    // add the contents
    const factSheetsJson = JSON.stringify(content);
    contentsInput.type = 'text';
    contentsInput.name = 'contents';
    contentsInput.value = factSheetsJson;
    // Add to the form
    mapForm.appendChild(contentsInput);
    // add the fund_id
    const fundIdInput = document.createElement('input');
    fundIdInput.type = 'text';
    fundIdInput.name = 'fund_id';
    fundIdInput.value = props.fundId || '';
    // Add to the form
    mapForm.appendChild(fundIdInput);
    // add the fund_name
    const fundNameInput = document.createElement('input');
    fundNameInput.type = 'text';
    fundNameInput.name = 'fund_name';
    fundNameInput.value = props.fundName || '';
    // Add to the form
    mapForm.appendChild(fundNameInput);
    // add the month
    const isinInput = document.createElement('input');
    isinInput.type = 'text';
    isinInput.name = 'isin';
    isinInput.value = props.isin || '';
    // Add to the form
    mapForm.appendChild(isinInput);
    // add the document language
    const documentLanguageInput = document.createElement('input');
    documentLanguageInput.type = 'text';
    documentLanguageInput.name = 'document_language';
    documentLanguageInput.value = props.documentLanguage || '';
    // Add to the form
    mapForm.appendChild(documentLanguageInput);
    // add the dev/live version option
    const devLiveInput = document.createElement('input');
    devLiveInput.type = 'text';
    devLiveInput.name = 'dev_live_version';
    devLiveInput.value = 'live';
    // Add to the form
    mapForm.appendChild(devLiveInput);
    // add the params
    const paramsInput = document.createElement('input');
    paramsInput.type = 'text';
    paramsInput.name = 'params';
    paramsInput.value = JSON.stringify([
      'contents',
      'fund_id',
      'fund_name',
      'isin',
      'document_language',
      'dev_live_version',
    ]);
    // Add to the form
    mapForm.appendChild(paramsInput);

    // Add the form to the dom
    document.body.appendChild(mapForm);
    // submit the form
    mapForm.submit();
    // remove the form
    document.body.removeChild(mapForm);
  }

  const handleFileDownload = () => {
    if (pdfDoc) {
      fileDownload(
        pdfDoc || '',
        props.fundName + '_' + props.isin + '_' + props.version + '.pdf',
      );
    }
  };

  const { showAsyncSnackbar } = useSnackbar();

  async function handleSaveChanges() {
    // Check if the required fields have been filled in.
    if (!props.kiidsData.length) {
      return;
    }
    // Give a confirmation message if template changes are going to be made.
    if (props.templateUpdates) {
      const confirmation = confirm(
        'Template changes have been made, would you like to apply to all relevant documents?',
      );

      // console.log(isExecuted); // OK = true, Cancel = false
      if (!confirmation) {
        return;
      }
    }

    const timeStamp = new Date();
    setLastEditedDate(timeStamp.toLocaleString());
    const kiidsJson = JSON.stringify(props.kiidsData);
    // Create an object of formData
    const formData = new FormData();

    // Update the formData objet.
    formData.append('kiid_data', kiidsJson);
    // if template changes are included in then append them.
    if (props.templateUpdates && props.templateUpdates.length) {
      const changes: any[] = [];
      // Keep track of the template_ids that have been added to the list
      // Due to the way the text box changes are hanldled, each character change is a new entry in the templateUpdates object.
      // We only want the latest entry as this contains all the changes made in this session.
      // All others can be ignored
      const templateIds = new Set();
      // Loop over each of the changes in reverse order so that the last change is applied first.
      props.templateUpdates.reverse().forEach((item: any) => {
        // If the template_id has already been added to the list then skip it.
        if (templateIds.has(item.id)) {
          return;
        }
        // Add the template_id to the list
        templateIds.add(item.id);
        changes.push({
          template_id: item.id,
          text: item.text,
        });
      });
      formData.append('template_updates', JSON.stringify(changes));
    }
    // Save the data to the DB
    await showAsyncSnackbar({
      startMessage: 'Saving Data.',
      successMessage: 'Data Saved.',
      failureMessage: 'An Error occured while saving.',
      promiseToResolve: requestClient
        .post('kiid_generator_write_kiid_data', formData, {
          headers: {
            'content-type': 'multipart/form-data',
          },
        })
        .catch((error) => {
          alert(
            'There was an error while saving changes. A report has been sent to Risksystem.',
          );
          throw error;
        }),
    });

    // Update the save button to be disabled
    props.setChangesMade(false);
    // Update the publish button to be available.
    props.setCanBePublished(true);
    // Refresh the PDF
    refreshPdf();
  }

  async function handlePublishKiid() {
    // Check if the required fields have been filled in.
    if (!props.kiidsData.length) {
      return;
    }
    const timeStamp = new Date();
    setLastEditedDate(timeStamp.toLocaleString());
    const kiidsJson = JSON.stringify(props.kiidsData);
    // Create an object of formData
    const formData = new FormData();

    // Update the formData objet.
    formData.append('kiid_data', kiidsJson);

    // Save the data to the DB
    await showAsyncSnackbar({
      startMessage: 'Publishing Latest KIID.',
      successMessage: 'KIID Published.',
      failureMessage: 'An Error occured while publishing.',
      promiseToResolve: requestClient.post(
        'kiid_generator_publish_kiid',
        formData,
        {
          headers: {
            'content-type': 'multipart/form-data',
          },
        },
      ),
    });

    // Update the save button to be disabled
    props.setChangesMade(false);
    // Update the publish button to be disabled.
    props.setCanBePublished(false);
    // Refresh the PDF
    refreshPdf();
  }

  return (
    <GridItem xs={12} card>
      <div className={classes.parentContainer}>
        <div className={classes.buttonsContainer}>
          <Button
            className={classes.button}
            variant="contained"
            onClick={() => refreshPdf()}
            endIcon={<CachedIcon />}
          >
            Refresh
          </Button>
          <Button
            className={classes.button}
            variant="contained"
            onClick={() => downloadFile()}
            endIcon={<SaveIcon style={{ color: 'white' }} />}
            // disabled={!haveChangesBeenMade}
          >
            <div style={{ color: 'white' }}>Download</div>
          </Button>
          <Button
            className={classes.button}
            variant="contained"
            onClick={() => handleSaveChanges()}
            endIcon={<SaveIcon />}
            disabled={!props.changesMade}
          >
            <div>Save</div>
          </Button>
          <Button
            className={classes.button}
            variant="contained"
            onClick={() => window.location.reload()}
            endIcon={<ReplyIcon />}
          >
            <div>Back To Overview</div>
          </Button>
        </div>
        <div>
          <div
            style={{
              border: '1px solid',
              borderColor: mainColors.mainBlue,
              width: '100%',
              height: height * 0.8,
              display: 'flex',
              justifyContent: 'space-around',
            }}
          >
            <iframe
              src=""
              width={pdfDocLoading ? '0%' : '100%'}
              height="100%"
              title={'kid.pdf'}
            ></iframe>
            {pdfDocLoading ? (
              <RaptorLoading centerWrap messages={['Generating Document...']} />
            ) : null}
          </div>
        </div>
      </div>
    </GridItem>
  );
};

export default KiidsViewer;
