import { FC } from 'react';
import BusinessInvolvementChart from './esgExposureComponents/BusinessInvolvementChart.component';
import RisksystemEsgStars from './esgExposureComponents/RisksystemEsgStars.component';
import { Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

interface EsgBusinessInvolvementPanelProps {
  data: any;
}

const useStyles = makeStyles(() => ({
  imageGrid: {
    // width: '66%',
    display: 'flex-column',
    justifyContent: 'space-around',
    marginTop: '1rem',
  },
  individualImage: {
    // width: '33%',
    // display: 'flex-column',
    justifyContent: 'center',
  },
  businessInvolvementCharts: {
    display: 'flex',
    justifyContent: 'space-around',
    width: '66%',
  },
  chartContainer: {
    display: 'flex',
    width: '100%',
  },
  starsContainer: {
    verticalAlign: 'center',
  },
}));

const EsgBusinessInvolvementPanel: FC<EsgBusinessInvolvementPanelProps> = (
  props,
) => {
  // const theme = useTheme();
  const { data } = props;
  const classes = useStyles();
  return (
    <div>
      <Typography
        variant="h1"
        style={{
          fontSize: '2.4rem',
          width: '100%',
          fontWeight: 500,
          textAlign: 'left',
        }}
      >
        Business Involvement
      </Typography>
      <div className={classes.chartContainer}>
        <div className={classes.businessInvolvementCharts}>
          <div className={classes.imageGrid}>
            <div className={classes.individualImage}>
              <BusinessInvolvementChart
                chartType="Adult Entertainment"
                value={data.risksystem_leaves.policies['Adult Entertainment']}
              />
            </div>

            <div className={classes.individualImage}>
              <BusinessInvolvementChart
                chartType="Labour Standards"
                value={
                  data.risksystem_leaves.policies[
                    'Labour Standards and Human Rights'
                  ]
                }
              />
            </div>
          </div>
          <div className={classes.imageGrid}>
            <div className={classes.individualImage}>
              <BusinessInvolvementChart
                chartType="Fossil Fuels"
                value={data.risksystem_leaves.policies['Fossil Fuels']}
              />
            </div>

            <div className={classes.individualImage}>
              <BusinessInvolvementChart
                chartType="Tobacco"
                value={data.risksystem_leaves.policies['Tobacco']}
              />
            </div>
          </div>
          <div className={classes.imageGrid}>
            <div className={classes.individualImage}>
              <BusinessInvolvementChart
                chartType="Gambling"
                value={data.risksystem_leaves.policies['Gambling']}
              />
            </div>

            <div className={classes.individualImage}>
              <BusinessInvolvementChart
                chartType="Weapons & Firearms"
                value={data.risksystem_leaves.policies['Weapons & Firearms']}
              />
            </div>
          </div>
        </div>
        <div className={classes.starsContainer}>
          <RisksystemEsgStars value={data.risksystem_leaves.leafs} />
        </div>
      </div>
    </div>
  );
};

export default EsgBusinessInvolvementPanel;
