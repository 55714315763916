import {
  ComponentIndex,
  PdfChartItem,
  PdfTableItem,
} from '../../types/redux/pdfExports/pdfExportsStore';
import {
  ADD_CHART_TO_PDF_EXPORT,
  ADD_COMPONENT_TO_PDF_EXPORT,
  ADD_TABLE_TO_PDF_EXPORT,
  AddChartToPdfExportAction,
  AddComponentToPdfExportAction,
  AddTableToPdfExportAction,
  CLEAR_PDF_EXPORT,
  ClearPdfExportAction,
} from '../../types/redux/pdfExports/exportTypes';

export function addTableToPdfExport<T extends object>(
  tableItem: PdfTableItem<T>,
): AddTableToPdfExportAction<T> {
  return {
    type: ADD_TABLE_TO_PDF_EXPORT,
    payload: tableItem,
  };
}

export function addChartToPdfExport(
  chart: PdfChartItem,
): AddChartToPdfExportAction {
  return {
    type: ADD_CHART_TO_PDF_EXPORT,
    payload: chart,
  };
}

export function clearPdfExports(): ClearPdfExportAction {
  return {
    type: CLEAR_PDF_EXPORT,
  };
}

export function addComponentToPdfExport(
  index: ComponentIndex,
): AddComponentToPdfExportAction {
  return {
    type: ADD_COMPONENT_TO_PDF_EXPORT,
    payload: index,
  };
}
