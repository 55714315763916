import React, { FC, useMemo } from 'react';
import { useWindowSize } from '../../../../hooks/useWindowSize';
import { CustomColumn } from '../../../../types/components/tables/tableTypes';
import { DataObject } from '../../../../types/redux/data/dataTypes';
import {
  addCommasToNumbers,
  percentageToTwoDecimalPlaces,
} from '../../../../utilities/numberFormatters';
import GridItem from '../../../layout/GridComponents/GridItem';
import CustomTable from '../../../tables/CustomTable';

export interface AgTableData {
  data: string;
  value: string | number;
}

const dataKeys = [
  'Position Date',
  'Base Currency',
  'NAV',
  // 'Sum of Notionals',
  // 'Sum of Notionals Leverage',
  'Net Exposure',
  'Net Exposure Leverage',
  'Gross Exposure',
  'Gross Exposure Leverage',
  'Commitment',
  'Commitment Leverage',
  'Asset Exposure', //: 59,281,945
  'Debt Exposure', //: 40,000,000
  'Asset/Debt ratio', //: 1.48
];

export const useAgColumns = (): CustomColumn<AgTableData>[] => {
  const generalStyle = {
    fontSize: '1.6rem',
  };

  return [
    {
      title: 'DAta',
      field: 'data',
      width: '60%',
      cellStyle: { ...generalStyle },
    },
    {
      title: 'Field',
      field: 'value',

      cellStyle: {
        ...generalStyle,
        textAlign: 'right',
        // borderRight: theme.borders!.light,
      },
    },
  ];
};
export const aggregateExposureCsvFields = ['data', 'value'];
export interface AggregateExposureData {
  selected_position_date: string;
  base_currency: string;
  nav: number;
  sum_of_notionals: number;
  total_net_exposure: number;
  total_gross_exposure: number;
  total_commitment: number;
}

export type IBuildAggregateData = AggregateExposureData | [];

export function buildAggregateData(inputData: any) {
  if (!inputData.length) return [];
  const data = inputData[0];
  const dataValues = [
    data.selected_position_date,
    data.base_currency,
    addCommasToNumbers(Math.round(data.nav)),
    // addCommasToNumbers(Math.round(data.sum_of_notionals)),
    // `${((100 * data.sum_of_notionals) / data.nav).toFixed(2)}%`,
    addCommasToNumbers(Math.round(data.total_net_exposure)),
    `${((100 * data.total_net_exposure) / data.nav).toFixed(2)}%`,
    addCommasToNumbers(Math.round(data.total_gross_exposure)),
    `${((100 * data.total_gross_exposure) / data.nav).toFixed(2)}%`,
    addCommasToNumbers(Math.round(data.total_commitment)),
    percentageToTwoDecimalPlaces(data.total_commitment, data.nav),
    '59,281,945',
    '40,000,000',
    '1.48',
  ];
  return dataKeys.map((key, index) => {
    return {
      data: key,
      value: dataValues[index],
    };
  });
}
type AggregateExposureTableProps = {
  dataForRender: DataObject;
  fundId: string;
};

const AggregateExposureTable: FC<AggregateExposureTableProps> = (props) => {
  const columns = useAgColumns();
  const { isFetching, error, data } = props.dataForRender;
  const { height, width } = useWindowSize();

  // const activeFund = useActivefund()?.name;
  const tableData = useMemo(
    () => buildAggregateData(data as IBuildAggregateData),
    [data],
  );
  return (
    <GridItem
      card
      xs={3}
      cardStyle={{
        flexGrow: 1,
      }}
    >
      <CustomTable<AgTableData>
        selectedPositionDate={data[0].selected_position_date}
        id={`agg_exposure_table_${props.fundId}`}
        title={`Exposure Aggregate Data`}
        csvFields={aggregateExposureCsvFields}
        showToolbar={true}
        // multipleTablePdfParameters={{
        //     specifiedWidth: 4,
        //     tablesInRow: 3,
        // }}
        loading={isFetching}
        columns={columns}
        data={tableData}
        options={{
          paging: false,
          search: false,
          header: false,
          exportButton: true,
        }}
      />
    </GridItem>
  );
};

export default AggregateExposureTable;
