import React, { ReactElement, SyntheticEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateSnackbarMessage } from '../../../redux/ui/snackbar/actions';
import {
  getSnackbarMessage,
  isSnackbarLoadingSelector,
  isSnackbarShowingSelector,
} from '../../../redux/ui/snackbar/selectors';
import { RaptorTheme, mainColors } from '../../../styling/theme';
import makeStyles from '@mui/styles/makeStyles';
import { CircularProgress, IconButton, Snackbar } from '@mui/material';
import { Close } from '@mui/icons-material';

const useStyles = makeStyles({
  root: {
    backgroundColor: mainColors.mainGold,
  },
  loadingCircle: {
    stroke: 'white',
    opacity: 1,
  },
  snackBarAction: {
    marginRight: 0,
  },
});

function RaptorSnackbar(): ReactElement {
  const classes = useStyles();
  const dispatch = useDispatch();
  const snackbarIsOpen = useSelector(isSnackbarShowingSelector);
  const handleClose = (
    event: Event | SyntheticEvent<any, Event>,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch(updateSnackbarMessage(null));
  };
  const message = useSelector(getSnackbarMessage);
  const isLoading = useSelector(isSnackbarLoadingSelector);
  const timeoutDelay = isLoading === true ? null : 3000;
  const loadingAction = (
    <>
      {isLoading ? (
        <CircularProgress
          classes={{ circle: classes.loadingCircle }}
          size={20}
        />
      ) : (
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={handleClose}
        >
          <Close fontSize="small" />
        </IconButton>
      )}
    </>
  );

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      ContentProps={{
        classes: {
          root: classes.root,
          action: classes.snackBarAction,
        },
      }}
      open={snackbarIsOpen}
      autoHideDuration={timeoutDelay}
      onClose={handleClose}
      // message="Note archived"
      message={message}
      action={loadingAction}
    />
  );
}

export default RaptorSnackbar;
