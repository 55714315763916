import React, { FormEvent, useState } from 'react';
import { createPortal } from 'react-dom';
import makeStyles from '@mui/styles/makeStyles';
import { greys, mainColors } from '../../../../styling/theme';
import {
  RequestStatusType,
  getAuthMessageColor,
} from '../../../../utilities/colorUtilities';
import { formatESGWithUnit } from '../../../../utilities/numberFormatters';
import clsx from 'clsx';
import requestClient from '../../../../utilities/requestClient';
import { isValidNumber } from '../../../../utilities/parserUtilities';
import { useSelector } from 'react-redux';
import {
  activeSectionSelector,
  availableDatesSelector,
  createActiveFundSelectorBySection,
} from '../../../../redux/pages/selectors';
import dayjs from 'dayjs';
import { Tooltip } from '@mui/material';
import { Edit } from '@mui/icons-material';

// Styling for the component
const useStyles = makeStyles(() => ({
  background: {
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: 1000,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  editValueButton: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '0.5rem',
    padding: '0.5rem 1rem',
    borderRadius: '0.4rem',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: mainColors.hoverOverWhite,
    },
  },
  editValueButton_edited: {
    backgroundColor: mainColors.veryFaintBlue,
    '&:hover': {
      backgroundColor: mainColors.hoverOverVeryFaintBlue,
    },
  },
  editValueIconContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',
    padding: '0.3rem',
    fontSize: '0.8rem',
    fontWeight: 600,
    backgroundColor: greys.grey200,
    color: 'white',
    borderRadius: '50%',
    cursor: 'pointer',
  },
  editValueIconContainer_hovered: {
    backgroundColor: mainColors.hoverOverMainBlue,
  },
  editValueIcon: {
    fontSize: '0.8rem',
  },
  modal: {
    backgroundColor: 'white',
    padding: '1rem',
    borderRadius: '0.5rem',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.5)',
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    width: '50rem',
    maxHeight: '80vh',
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: '0.4rem',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: mainColors.lightGrey,
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: mainColors.mainBlue,
      borderRadius: '1rem',
    },
  },
  title: {
    fontSize: '2.5rem',
    fontWeight: 600,
    color: mainColors.mainBlue,
  },
  subTitle: {
    fontSize: '1.5rem',
    fontWeight: 500,
    color: mainColors.Pass,
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.5rem',
    paddingBottom: '0.5rem',
    fontSize: '1.5rem',
  },
  inputGroup: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '1rem',
    fontSize: '1.6rem',
    fontWeight: 500,
    padding: '1rem 0',
  },
  input: {
    all: 'unset',
    width: '10rem',
    height: '2rem',
    fontSize: '1.6rem',
    fontWeight: 500,
    padding: '0.5rem 0.5rem',
    borderRadius: '0.4rem',
    border: `1px solid ${mainColors.mainBlue}`,
  },
  inputUnit: {
    fontSize: '2rem',
    fontWeight: 500,
  },
  inputError: {
    fontSize: '1.2rem',
    color: mainColors.Fail,
  },
  submitContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '1rem',
    width: '100%',
  },
  actionContainer: {
    display: 'flex',
    gap: '1rem',
    alignItems: 'center',
  },
  closeButton: {
    all: 'unset',
    fontSize: '1.5rem',
    fontWeight: 500,
    padding: '0.5rem 1rem',
    backgroundColor: mainColors.Fail,
    color: 'white',
    borderRadius: '0.2rem',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '1.5rem',
    '&:hover': {
      backgroundColor: mainColors.Fail_darker,
    },
  },
  submitButton: {
    all: 'unset',
    fontSize: '1.5rem',
    fontWeight: 500,
    padding: '0.5rem 1rem',
    backgroundColor: mainColors.Pass,
    color: 'white',
    borderRadius: '0.2rem',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '1.5rem',
    '&:hover': {
      backgroundColor: mainColors.Pass_darker,
    },
  },
  authMessage: {
    fontSize: '1.2rem',
  },
}));

interface EditValueModalProps {
  gmid: string;
  indicator: string;
  value: number | string;
  unit: string;
  onChange: () => void;
  isEdited: boolean;
}

const EditValueModal: React.FC<EditValueModalProps> = ({
  gmid,
  indicator,
  value,
  unit,
  onChange,
  isEdited,
}) => {
  const classes = useStyles();
  const client = requestClient();

  const activeSection = useSelector(activeSectionSelector);
  const activeFundSelector = createActiveFundSelectorBySection(activeSection);
  const activeFund = useSelector(activeFundSelector);
  const availableDates = useSelector(availableDatesSelector);

  const [isHovered, setIsHovered] = useState<boolean>(false);

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const [internalValue, setInternalValue] = useState<string>(String(value));
  const [internalValueError, setInternalValueError] = useState<string>('');

  const [requestMessage, setRequestMessage] = useState<string>('');
  const [requestStatus, setRequestStatus] = useState<RequestStatusType>('idle');

  // Function to force the input to be rither a number or 'N/A'
  const handleType = (value: string) => {
    value = value.toUpperCase();
    if (value === '') {
      setInternalValue('');
    } else if (value === 'N') {
      setInternalValue('N');
    } else if (value === 'N/') {
      setInternalValue('N/');
    } else if (value === 'N/A') {
      setInternalValue('N/A');
    } else if (isValidNumber(value)) {
      // If the value is less than two decimal places, allow it
      if (value.indexOf('.') === -1) {
        setInternalValue(value);
      } else {
        // If the value is more than two decimal places, don't allow it
        const decimalIndex = value.indexOf('.');
        if (value.length - decimalIndex > 3) {
          setInternalValue(value.slice(0, decimalIndex + 3));
        } else {
          setInternalValue(value);
        }
      }
    } else {
      if (internalValueError === '') {
        // here we inform the user that the input they have attempted is invalid
        setInternalValueError(
          'Only numbers to 2 decimal places and "N/A" are allowed.',
        );
        setTimeout(() => {
          setInternalValueError('');
        }, 5000);
      }
    }
  };

  // Funciton to format value before submitting change
  // If the value is 'N/A' then it is left as 'N/A'
  // Else it is converted to a number
  // If the unit is a percentage then the value is divided by 100
  const formatValue = (value: string, unit: string): number | 'N/A' => {
    if (isValidNumber(value)) {
      return unit === '%' ? parseFloat(value) / 100 : parseFloat(value);
    } else {
      return 'N/A';
    }
  };

  // set all state variables to their initial values
  const resetState = () => {
    setInternalValue(String(value));
    setRequestMessage('');
    setRequestStatus('idle');
  };

  // Handle form submission
  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    setRequestMessage('Submitting edit...');
    setRequestStatus('loading');
    // Submit change to the server
    client
      .put('receive_indicator_data', {
        fund_name: activeFund?.id,
        date:
          availableDates.length >= 1
            ? availableDates[0]
            : dayjs().format('YYYY-MM-DD'), // If there are no available dates, use today's date
        gmid: gmid,
        indicator: indicator,
        value: formatValue(internalValue, unit),
      })
      .then((res) => {
        if (res.status !== 200) throw new Error('Error submitting edit');
        setRequestMessage('Edit submitted successfully');
        setRequestStatus('success');
        setTimeout(() => {
          setIsOpen(false);
          resetState();
          onChange();
        }, 1000);
      })
      .catch(() => {
        setRequestMessage('Error submitting edit');
        setRequestStatus('fail');
      })
      .finally(() => {
        setTimeout(() => {
          setRequestMessage('');
          setRequestStatus('idle');
        }, 5000);
      });
  };

  return (
    <>
      <Tooltip title="Click to edit">
        <div
          className={
            isEdited
              ? clsx(classes.editValueButton, classes.editValueButton_edited)
              : classes.editValueButton
          }
          onClick={() => setIsOpen(true)}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          <div>{formatESGWithUnit(value, unit)}</div>
          <div
            className={
              isHovered
                ? clsx(
                    classes.editValueIconContainer,
                    classes.editValueIconContainer_hovered,
                  )
                : classes.editValueIconContainer
            }
          >
            <Edit className={classes.editValueIcon} />
          </div>
        </div>
      </Tooltip>
      {isOpen
        ? createPortal(
            <div className={classes.background}>
              <form
                className={classes.modal}
                onSubmit={(e: FormEvent) => handleSubmit(e)}
              >
                <div className={classes.title}>Edit Future Value</div>
                {isEdited ? (
                  <div className={classes.subTitle}>
                    - A change is currently recorded for the next calculation. -
                  </div>
                ) : null}
                <div className={classes.formContainer}>
                  <div>
                    The changes will not be visible immediately and only be
                    applied from the next calculation of the fund.
                  </div>
                  <div>
                    If you would like to apply the changes to the current
                    position date, please submit the change and contact
                    risksystem with a recalculation request.
                  </div>
                  <div className={classes.inputGroup}>
                    <input
                      type="text"
                      value={internalValue}
                      onChange={(e) => {
                        handleType(e.target.value);
                      }}
                      className={classes.input}
                    />
                    <div className={classes.inputUnit}>{unit}</div>
                    <div className={classes.inputError}>
                      {internalValueError}
                    </div>
                  </div>
                </div>
                <div className={classes.submitContainer}>
                  <div className={classes.actionContainer}>
                    <button
                      onClick={() => {
                        setIsOpen(false);
                        resetState();
                      }}
                      className={classes.closeButton}
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      onClick={handleSubmit}
                      className={classes.submitButton}
                    >
                      Submit Change
                    </button>
                  </div>
                  <div
                    className={classes.authMessage}
                    style={{ color: getAuthMessageColor(requestStatus) }}
                  >
                    {requestMessage}
                  </div>
                </div>
              </form>
            </div>,
            document.body, // Render the modal into the document body
          )
        : null}
    </>
  );
};

export default EditValueModal;
