import { Status } from '../../../../../types/redux/data/dataTypes';
import { CustomColumn } from '../../../../../types/components/tables/tableTypes';
import { addCommasToNumbersAndRound } from '../../../../../utilities/numberFormatters';
import ReportsLinkButton from './components/ReportsLinkButton/reportsLinkButton.component';
import GeneralReportDatePicker from '../../../../general/GeneralReportDatePicker';
import { specifyStatus } from '../../../../../utilities/generalMappings';

export interface AifmdOverviewTableData {
  [key: string]: unknown;
  internalFundName: string;
  externalFundName: string;
  positionDate: string;
  currency: string;
  netAssetValue: number;
  reports: string[];
  market: Status;
  counterparty: Status;
  credit: Status;
  liquidity: Status;
  totalCommitmentPercent: number;
  fundVar: number;
  totalGrossExposurePercent: number;
  riskComplianceStatus: Status;
  tableData?: { [index: string]: number };
}

type AifmdReports = {
  [key: string]: string[];
};

export function generateAifmdOverviewColumns(): CustomColumn<AifmdOverviewTableData>[] {
  return [
    {
      title: 'Internal Fund Name',
      field: 'internalFundName',
      render: (rowData: AifmdOverviewTableData) =>
        rowData.externalFundName.includes('Mersenne')
          ? `Fund ${(rowData.tableData?.id || 0) + 1}`
          : rowData.internalFundName,
      width: '16rem',
    },
    {
      title: 'External Fund Name',
      field: 'externalFundName',
    },
    {
      title: 'Position Date',
      field: 'positionDate',
      width: '12rem',
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
      },
    },
    {
      title: 'Currency',
      field: 'currency',
    },
    {
      title: 'Net Asset Value',
      field: 'netAssetValue',
      render: (rowData: AifmdOverviewTableData) =>
        addCommasToNumbersAndRound(rowData.netAssetValue),
      width: '15rem',
      cellStyle: {
        textAlign: 'center',
      },
    },
    {
      title: 'Reports',
      field: 'reports',
      width: '10%',
      render: (rowData: AifmdOverviewTableData) => (
        <GeneralReportDatePicker
          reportNames={rowData.reports}
          reportUrl="aifmd_historical_report"
          fundId={rowData.internalFundName}
        />
      ),
      headerStyle: {
        textAlign: 'center',
      },
      cellStyle: {
        textAlign: 'center',
      },
    },

    {
      title: 'Market',
      field: 'market',
      width: '20rem',
      render: (rowData: AifmdOverviewTableData) => {
        return (
          <ReportsLinkButton
            section="aifmd"
            page="aifmd-reports"
            reportType="market"
            status={specifyStatus(rowData.market)}
            fundName={rowData.internalFundName}
            setTitleBarSelect={{
              selectedValue: 'Market Limit Monitor',
            }}
          />
        );
      },
      headerStyle: {
        textAlign: 'center',
      },
    },
    {
      title: 'Counterparty',
      field: 'counterparty',
      width: '20rem',
      render: (rowData: AifmdOverviewTableData) => {
        return (
          <ReportsLinkButton
            section="aifmd"
            page="aifmd-reports"
            reportType="counterparty"
            status={specifyStatus(rowData.counterparty)}
            fundName={rowData.internalFundName}
            setTitleBarSelect={{
              selectedValue: 'Counterparty Limit Monitor',
            }}
          />
        );
      },
      headerStyle: {
        textAlign: 'center',
      },
    },
    {
      title: 'Credit',
      field: 'credit',
      width: '20rem',
      render: (rowData: AifmdOverviewTableData) => {
        return (
          <ReportsLinkButton
            section="aifmd"
            page="aifmd-reports"
            reportType="credit"
            status={specifyStatus(rowData.credit)}
            fundName={rowData.internalFundName}
            setTitleBarSelect={{
              selectedValue: 'Credit Limit Monitor',
            }}
          />
        );
      },
      headerStyle: {
        textAlign: 'center',
      },
    },
    {
      title: 'Liquidity',
      field: 'liquidity',
      width: '20rem',
      render: (rowData: AifmdOverviewTableData) => {
        return (
          <ReportsLinkButton
            section="aifmd"
            page="aifmd-reports"
            reportType="liquidity"
            status={specifyStatus(rowData.liquidity)}
            fundName={rowData.internalFundName}
            setTitleBarSelect={{
              selectedValue: 'Liquidity Limit Monitor',
            }}
          />
        );
      },
      headerStyle: {
        textAlign: 'center',
      },
    },
  ];
}

export function generateAifmdOverviewData(
  inputData: any[],
  reports: AifmdReports[],
) {
  const reportsArr = reports[0];
  return inputData.map((fund: any, index) => {
    const {
      selected_position_date,
      Counterparty,
      Credit,
      Market,
      Liquidity,
      base_currency,
      nav,
      total_commitment_pct,
      fund_var,
      total_gross_exposure_pct,
      risk_compliance_status,
    } = fund.monitor_data;
    return {
      internalFundName: fund.fund_name,
      externalFundName: fund.fund_name_full,
      positionDate: selected_position_date,
      currency: base_currency,
      netAssetValue: nav,
      market: Market,
      counterparty: Counterparty,
      credit: Credit,
      reports: reportsArr[fund.fund_name],
      liquidity: Liquidity,
      totalCommitmentPercent: total_commitment_pct,
      fundVar: fund_var,
      totalGrossExposurePercent: total_gross_exposure_pct,
      riskComplianceStatus: risk_compliance_status,
    };
  });
}
