import React, { ReactElement } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { greys } from '../../../../../styling/theme';
import { Grid } from '@mui/material';

interface PriipsKidsEditorGridItemProps {
  xs: any;
}

const useStyles = makeStyles(() => ({
  gridItem: {
    marginBottom: '1rem',
    color: 'black',
  },
  generalCardRoot: {
    // background: mainColors.pastelGreen,
    background: greys.grey900,
    borderRadius: 2,
    // boxShadow: '0px 2px 5px rgba(0,0,0,0.1)',
    overflow: 'hidden',
    width: '100%',
    marginBottom: '1rem',
    // borderColor: 'black',
    // borderStyle: 'solid',
    // borderWidth: '0.5px',
    // boxSizing: 'border-box',
    // padding: '1rem',
  },
}));

function PriipsKidsEditorGridItem(
  props: React.PropsWithChildren<PriipsKidsEditorGridItemProps>,
): ReactElement {
  const classes = useStyles();

  return (
    <Grid item>
      <div className={classes.generalCardRoot}>{props.children}</div>
    </Grid>
  );
}

export default PriipsKidsEditorGridItem;
