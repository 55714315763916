import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import useTitleBarSelect from '../../../hooks/useTitleBarSelect';
import { Select } from '../../../types/redux/pages/PagesStore';
import { addAdditionalTitleBarComponents } from '../../../redux/pages/actions';
import { APPEND_NEW_SELECT_TO_TITLEBAR } from '../../../types/redux/pages/pageTypes';
import { GeneralOverviewPageRenderedComponentProps } from '../../../types/components/componentTypes';
import GeneralComponentErrorShield from '../../general/GeneralComponentErrorShield';
import useFetchData from '../../../hooks/useFetchData';
import YieldPage from './YieldPage';

const YieldMain: React.FC<GeneralOverviewPageRenderedComponentProps> = (
  props,
) => {
  const dispatch = useDispatch();
  const curve_selected = useTitleBarSelect('Curve') || 'Govt';
  const currency_selected = useTitleBarSelect('Currency') || 'EUR';

  useEffect(() => {
    const selectsToAdd_curve: Select[] = [
      {
        titleBarTitle: 'Curve:',
        titleBarKey: 'Curve',
        values: [
          {
            label: 'Swap',
            value: 'swap',
          },
        ],
        displayOnOverviewPage: true,
        aValueIsAlwaysSelected: true,
        currentValue: 'swap',
      },
    ];
    const selectsToAdd_currency: Select[] = [
      {
        titleBarTitle: 'Currency:',
        titleBarKey: 'Currency',
        values: [
          {
            label: 'EUR',
            value: 'EUR',
          },
          {
            label: 'GBP',
            value: 'GBP',
          },
          {
            label: 'USD',
            value: 'USD',
          },
          {
            label: 'CHF',
            value: 'CHF',
          },
          {
            label: 'JPY',
            value: 'JPY',
          },
          {
            label: 'CNY',
            value: 'CNY',
          },
        ],
        displayOnOverviewPage: true,
        aValueIsAlwaysSelected: true,
        currentValue: 'EUR',
      },
    ];
    dispatch(
      addAdditionalTitleBarComponents(
        selectsToAdd_curve,
        APPEND_NEW_SELECT_TO_TITLEBAR,
      ),
    );
    dispatch(
      addAdditionalTitleBarComponents(
        selectsToAdd_currency,
        APPEND_NEW_SELECT_TO_TITLEBAR,
      ),
    );
  }, []);

  const response = useFetchData({
    url: `yield_curve_data/${currency_selected}/${curve_selected}/${props.positionDate}`,
    keyName: `yield_curve_data/${currency_selected}/${curve_selected}/${props.positionDate}`,
    makeFetch: true,
  });

  return (
    <GeneralComponentErrorShield
      dataObjects={[response]}
      customLoadingMessages={['Loading Yield Data...', '...']}
      customErrorMessage={'No Data Found'}
    >
      <YieldPage response={response} />
    </GeneralComponentErrorShield>
  );
};

export default YieldMain;
