import { FunctionComponent } from 'react';
import '../styles/ultratable.css';

export const Legend: FunctionComponent<{ values: string[] }> = ({ values }) => {
  return (
    <ul id="key-list-items">
      {values.map((value, index) => (
        <li key={index}>{value}</li>
      ))}
    </ul>
  );
};
