import React, { Dispatch, FC } from 'react';
import { PriipsKidsOverviewSecondLevelTableData } from './PriipsKidsOverviewAllFunctionality.component';
import CustomTable from '../../../../tables/CustomTable';
import { CustomColumn } from '../../../../../types/components/tables/tableTypes';
import makeStyles from '@mui/styles/makeStyles';
import VisibilityIcon from '@mui/icons-material/Visibility';
import useSnackbar from '../../../../../hooks/useSnackbar';
import TrackedChangesButton from '../buttons/TrackedChangesButton.component';
import { useHistory } from 'react-router-dom';
import { greys, mainColors } from '../../../../../styling/theme';
import PublishDocumentDialogBox from '../buttons/PublishDocumentDialogBox.component';
import { Tooltip } from '@mui/material';
import client from '../../../../../utilities/requestClient';

const useStyles = makeStyles(() => ({
  arrow: {
    '&:before': {
      border: '1px solid #8e8e8e',
    },
    color: greys.grey600,
  },
  tooltip: {
    backgroundColor: greys.grey600,
    border: '1px solid #8e8e8e',
    color: 'white',
  },
}));

interface PriipsKidsOverviewAllFunctionalitySecondLevelTableProps {
  data: PriipsKidsOverviewSecondLevelTableData[];
}

function buildTableColumns(
  setTableData: Dispatch<any>,
  setDialogIsShowing: Dispatch<boolean>,
  setPublishingDocument: Dispatch<{
    fundId: string;
    shareClass: string;
    documentLanguage: string;
    fundName: string;
  }>,
): CustomColumn<PriipsKidsOverviewSecondLevelTableData>[] {
  const history = useHistory();
  const classes = useStyles();
  const detailColumns: CustomColumn<PriipsKidsOverviewSecondLevelTableData>[] =
    [
      {
        title: 'Share Class',
        field: 'shareClassName',
        cellStyle: {
          textAlign: 'center',
        },
        headerStyle: { textAlign: 'center' },
      },
      {
        title: 'ISIN',
        field: 'isin',
        cellStyle: {
          textAlign: 'center',
        },
        headerStyle: { textAlign: 'center' },
      },
      {
        title: 'Version',
        field: 'version',
        cellStyle: {
          textAlign: 'center',
        },
        headerStyle: { textAlign: 'center' },
      },
      {
        title: 'Publication Timestamp',
        field: 'publicationDate',
        cellStyle: {
          textAlign: 'center',
        },
        headerStyle: { textAlign: 'center' },
        render: (rowData: PriipsKidsOverviewSecondLevelTableData) =>
          rowData.publicationDate.substring(0, 16).replace('T', ' '),
      },
      {
        title: 'Last Edited Timestamp',
        field: 'lasteEditedDate',
        cellStyle: {
          textAlign: 'center',
        },
        headerStyle: { textAlign: 'center' },
        render: (rowData: PriipsKidsOverviewSecondLevelTableData) =>
          rowData.lasteEditedDate.substring(0, 16).replace('T', ' '),
      },
      {
        title: 'Last Edited By',
        field: 'lastEditedBy',
        cellStyle: {
          textAlign: 'center',
        },
        headerStyle: { textAlign: 'center' },
      },
      {
        title: 'Compare Versions',
        field: '',
        render: (rowData: PriipsKidsOverviewSecondLevelTableData) => (
          <TrackedChangesButton
            fundId={rowData.fundId}
            shareClass={rowData.isin}
            documentLanguage={rowData.documentLanguage}
            versionNumbers={rowData.versionNumbers}
            initialComparisonVersion={`${rowData.version.split('.')[0]}.0.0`}
            initialTargetVersion={rowData.version}
          />
        ),
        cellStyle: {
          textAlign: 'center',
        },
        headerStyle: { textAlign: 'center' },
      },
      {
        title: 'View',
        field: '',
        render: (rowData: PriipsKidsOverviewSecondLevelTableData) => {
          return (
            <Tooltip
              classes={{ arrow: classes.arrow, tooltip: classes.tooltip }}
              title="View Document"
              placement={'top'}
              arrow
              enterNextDelay={2000}
              enterDelay={2000}
            >
              <VisibilityIcon
                sx={{
                  fontSize: '3rem',
                  color: 'white',
                  backgroundColor: mainColors.mainBlue,
                  borderRadius: 1,
                  padding: '0.2rem',
                  cursor: 'pointer',
                  '&:hover': {
                    backgroundColor: mainColors.mainBlue_lighter,
                  },
                }}
                onClick={() => {
                  history.push(
                    `priips-kids-view?fundId=${rowData.fundId}&isin=${rowData.isin}&documentLanguage=${rowData.documentLanguage}&version=${rowData.version}&latestVersion=${rowData.versionNumbers[0]}`,
                  );
                }}
              />
            </Tooltip>
          );
        },
        cellStyle: {
          textAlign: 'center',
        },
        headerStyle: { textAlign: 'center' },
      },
    ];
  return detailColumns;
}

const PriipsKidsOverviewViewOnlySecondLevelTable: FC<
  PriipsKidsOverviewAllFunctionalitySecondLevelTableProps
> = (props) => {
  const { data } = props;
  // Create a state object for the table data so that it can be updated as needed
  const [tableData, setTableData] =
    React.useState<PriipsKidsOverviewSecondLevelTableData[]>(data);
  // Create an object for handling opening the publish dialog box
  const [publishDialogIsShowing, setPublishDialogIsShowing] =
    React.useState<boolean>(false);
  // Create a state object that will store the fundId and shareClass of the documentLangauge that is being published
  const [publishingDocument, setPublishingDocument] = React.useState<{
    fundId: string;
    shareClass: string;
    documentLanguage: string;
    fundName: string;
  }>({ fundId: '', shareClass: '', documentLanguage: '', fundName: '' });
  // Use the snackbar for feedback when publishing
  const { showAsyncSnackbar } = useSnackbar();
  // Create a request client for publishing
  const requestClient = client();

  // Define a function for updating the version number in the table when a document has been published
  function updateVersionNumber(
    fundId: string,
    shareClass: string,
    documentLanguage: string,
  ) {
    // Find the index of the document in the table data
    const index = tableData.findIndex(
      (document) =>
        document.fundId === fundId &&
        document.isin === shareClass &&
        document.documentLanguage === documentLanguage,
    );
    // Create a copy of the table data
    const tableDataCopy = [...tableData];
    // Update the version number in the copy
    tableDataCopy[index].version = `${
      parseInt(tableDataCopy[index].version.split('.')[0]) + 1
    }.0.0`;
    // Update the table data
    setTableData(tableDataCopy);
  }

  // Define a function for publishing changes
  async function publishChanges(fundId: string, shareClass: string) {
    // Save the data to the DB
    await showAsyncSnackbar({
      startMessage: 'Publishing Latest KID.',
      successMessage: 'KID Published.',
      failureMessage: 'An Error occured while publishing.',
      promiseToResolve: requestClient.get(
        `kiid_generator_publish_latest_kiid/${fundId}/${shareClass}`,
      ),
    });
  }

  const columns = buildTableColumns(
    setTableData,
    setPublishDialogIsShowing,
    setPublishingDocument,
  );

  return (
    <>
      <CustomTable<PriipsKidsOverviewSecondLevelTableData>
        //   selectedPositionDate={data[0].selected_position_date}

        showToolbar={true}
        id={`kiids_editor_overview_table`}
        // title={''}
        //   csvFields={exposureMainTableCsvFields}
        loading={data && data.length ? false : true}
        data={tableData}
        options={{
          paging: false,
          search: true,
          exportButton: false,
          exportAllData: false,
          emptyRowsWhenPaging: false,
        }}
        columns={columns}
      />
      {publishDialogIsShowing && (
        <PublishDocumentDialogBox
          setDialogIsShowing={setPublishDialogIsShowing}
          fundId={publishingDocument.fundId}
          shareClass={publishingDocument.shareClass}
          documentLanguage={publishingDocument.documentLanguage}
          fundName={publishingDocument.fundName}
          updateVersionNumber={updateVersionNumber}
        />
      )}
    </>
  );
};

export default PriipsKidsOverviewViewOnlySecondLevelTable;
