import { ReactElement } from 'react';

import { Line } from 'recharts';

import { IFundSharePrice, buildFundSharePrice } from './sharePrice.data';
import {
  addCommasToNumbersAndRound,
  roundNumberToTwoDecimalPlaces,
  toTwoDecimalPlaces,
} from '../../../../../../utilities/numberFormatters';
import GridItem from '../../../../../layout/GridComponents/GridItem';
import GenericComposedChart from '../../../../../charts/GenericComposedChart';
import { DataObject } from '../../../../../../types/redux/data/dataTypes';
import { useWindowSize } from '../../../../../../hooks/useWindowSize';
import { useTheme } from '@mui/material';

interface Props {
  varData: DataObject;
}

function SharePrice({ varData }: Props): ReactElement {
  const chartData = varData.data.length
    ? buildFundSharePrice(varData.data)
    : [];
  const theme = useTheme();
  const { height } = useWindowSize();
  // Store just the prices as an array
  const sharePrices = chartData.map((x: any) => x.price);
  // get the min and max share prices
  const minPrice = sharePrices.length
    ? sharePrices.reduce((a: number, b: number) => Math.min(a, b))
    : 0;
  const maxPrice = sharePrices.length
    ? sharePrices.reduce((a: number, b: number) => Math.max(a, b))
    : 0;
  // If the difference between min and max is less than 1 then don't round the y axis values
  const isRounded = maxPrice - minPrice >= 6 ? true : false;
  return (
    <>
      {chartData.length ? (
        <GridItem
          xs={12}
          xl={6}
          card
          cardStyle={{ position: 'relative', minHeight: '40rem' }}
        >
          <GenericComposedChart<IFundSharePrice>
            loading={varData.isFetching}
            margin={{ top: 50, bottom: 50, left: 20, right: 50 }}
            data={chartData}
            title="Fund Share Price"
            height={height * 0.45}
            tooltipFormatter={(
              value: string | number | Array<string | number>,
            ) => [`${toTwoDecimalPlaces(value as number)}`]}
            width={'95%'}
            xAxes={[
              {
                dataKey: 'name',
                interval: 'preserveStartEnd',
                type: 'category',
                minTickGap: 70,
              },
            ]}
            yAxes={[
              {
                label: {
                  value: varData.data[0]?.base_currency || 'EUR',
                  angle: -90,
                  position: 'insideLeft',
                  offset: 0,
                },
                domain: [
                  (dataMin: any) => dataMin - 0.1 * dataMin,
                  (dataMax: any) => dataMax + 0.1 * dataMax,
                ],
                tickFormatter: isRounded
                  ? (value: any) => addCommasToNumbersAndRound(value)
                  : (value: any) => roundNumberToTwoDecimalPlaces(value),
              },
            ]}
            components={[
              {
                ComponentType: Line,
                props: {
                  type: 'monotone',
                  dataKey: 'price',
                  stroke: theme.palette.primary.main,
                },
              },
            ]}
          />{' '}
        </GridItem>
      ) : null}
    </>
  );
}

export default SharePrice;
