import React, { FC } from 'react';
import { FundInfoComponentProps } from '../../../general/GeneralFundInfoPage';
import useFetchData from '../../../../hooks/useFetchData';
import { makeUniqueDataKey } from '../../../../utilities/urlBuilder';
import GeneralComponentErrorShield from '../../../general/GeneralComponentErrorShield';
import CounterpartyTableDisplay from './tableDisplay/tableDisplay.component';

const CounterpartyMarketLiquidityRisk: FC<FundInfoComponentProps> = (props) => {
  const counterpartyData = useFetchData({
    makeFetch: true,
    keyName: makeUniqueDataKey(
      props.fundId,
      'counterparty_liquidity',
      props.positionDate,
    ),
    url: !props.positionDate
      ? `most_recently_stored_report_data_agg/${props.fundId}/cpl_risk`
      : `stored_report_data_agg/${props.fundId}/cpl_risk/${props.positionDate}`,
  });
  return (
    <GeneralComponentErrorShield dataObjects={[counterpartyData]}>
      <CounterpartyTableDisplay data={counterpartyData} />
    </GeneralComponentErrorShield>
  );
};

export default CounterpartyMarketLiquidityRisk;
