import { ReactElement } from 'react';
import { mainColors } from '../../../../styling/theme';
import { CustomColumn } from '../../../../types/components/tables/tableTypes';
import { addCommasToNumbersAndRound } from '../../../../utilities/numberFormatters';
import CustomTable from '../../../tables/CustomTable';
import { Typography } from '@mui/material';

interface MancoExposureSecondLevelTableProps {
  underlyingAssets: any;
}

interface MancoExposureSecondLevelTableData {
  name: string;
  price: number;
  delta: number;
  size: number;
  riskFactor: number;
  localExposure: number;
  baseExposure: number;
  grossExposure: number;
  commitment: number;
  fundId: string;
}

const secondlevelMancoExposureColumns: CustomColumn<MancoExposureSecondLevelTableData>[] =
  [
    {
      title: 'Name',
      field: 'name',
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
        color: 'white',
      },
      // render: (rowData: PeExposureDCFTableData) => addCommasToNumbersAndRound(rowData.ebit),
    },
    {
      title: 'Price',
      field: 'price',
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
        color: 'white',
      },
      // render: (rowData: PeExposureDCFTableData) => addCommasToNumbersAndRound(rowData.ebit),
    },
    {
      title: 'Delta',
      field: 'delta',
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
        color: 'white',
      },
      // render: (rowData: PeExposureDCFTableData) => addCommasToNumbersAndRound(rowData.ebit),
    },
    {
      title: 'Size',
      field: 'size',
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
        color: 'white',
      },
      // render: (rowData: PeExposureDCFTableData) => addCommasToNumbersAndRound(rowData.ebit),
    },
    {
      title: 'Risk Factor',
      field: 'riskFactor',
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
        color: 'white',
      },
      // render: (rowData: PeExposureDCFTableData) => addCommasToNumbersAndRound(rowData.ebit),
    },
    {
      title: 'Local Exposure',
      field: 'localExposure',
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
        color: 'white',
      },
      render: (rowData: MancoExposureSecondLevelTableData) =>
        addCommasToNumbersAndRound(rowData.localExposure),
    },
    {
      title: 'Base Exposure',
      field: 'baseExposure',
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
        color: 'white',
      },
      render: (rowData: MancoExposureSecondLevelTableData) =>
        addCommasToNumbersAndRound(rowData.baseExposure),
    },
    {
      title: 'Gross Exposure',
      field: 'grossExposure',
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
        color: 'white',
      },
      render: (rowData: MancoExposureSecondLevelTableData) =>
        addCommasToNumbersAndRound(rowData.grossExposure),
    },
    // {
    //     title: 'Commitment',
    //     field: 'commitment',
    //     cellStyle: {
    //         textAlign: 'center',
    //     },
    //     headerStyle: {
    //         textAlign: 'center',
    //         color: 'white',
    //     },
    //     render: (rowData: MancoExposureSecondLevelTableData) => addCommasToNumbersAndRound(rowData.commitment),
    // },
    {
      title: 'Fund Name',
      field: 'fundId',
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
        color: 'white',
      },
      // render: (rowData: PeExposureDCFTableData) => addCommasToNumbersAndRound(rowData.ebit),
    },
  ];

function buildSecondLevelTableData(
  underlyingAssets: any,
): MancoExposureSecondLevelTableData[] {
  if (!underlyingAssets.length) return [];
  // const underlyingAssets = data.underlyingAssets;
  const tableData: MancoExposureSecondLevelTableData[] = [];
  underlyingAssets.forEach((value: any) => {
    tableData.push({
      name: value[0],
      price: value[1],
      delta: value[2],
      size: value[3],
      riskFactor: value[4],
      localExposure: value[5],
      baseExposure: value[6],
      grossExposure: value[7],
      commitment: value[8],
      fundId: value[17],
    });
  });

  return tableData;
}

function MancoExposureSecondLevelTable({
  underlyingAssets,
}: MancoExposureSecondLevelTableProps): ReactElement {
  const secondLevelTableData = buildSecondLevelTableData(underlyingAssets);
  return secondLevelTableData.length ? (
    // !navTimeSeriesData.isFetching ? (
    <div
      style={{
        marginTop: '1rem',
        marginBottom: '1rem',
        marginLeft: '1rem',
        marginRight: '1rem',
      }}
    >
      <CustomTable<MancoExposureSecondLevelTableData>
        columns={secondlevelMancoExposureColumns}
        exportFileName="position-exposure"
        showToolbar={false}
        title={'Positon Exposure'}
        id={`positionExposure`}
        data={secondLevelTableData}
        options={{
          draggable: false,
          sorting: false,
          paging: false,
          search: false,
          exportButton: false,
          emptyRowsWhenPaging: false,
          headerStyle: {
            color: 'white',
            textAlign: 'left',
            backgroundColor: mainColors.pineGreen,
            opacity: 0.7,
          },
        }}
      />
    </div>
  ) : (
    // : <RaptorLoading centerWrap />)
    <div
      style={{
        marginTop: '1rem',
        marginBottom: '1rem',
        marginLeft: '1rem',
        marginRight: '1rem',
      }}
    >
      <Typography variant="h3">No Data Available</Typography>
    </div>
  );
}
export default MancoExposureSecondLevelTable;
