// A centralised confirmation modal that can be used anywhere in the app. It uses the ConfirmationContext to manage the state of the modal.

import React, { useContext } from 'react';
import { ConfirmationContext } from '../context/ConfirmationContext';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ConfirmationModel = () => {
  const { cancel, confirmation } = useContext(ConfirmationContext);

  return (
    <React.Fragment>
      <Dialog
        open={Boolean(confirmation)}
        TransitionComponent={Transition}
        keepMounted
        onClose={cancel}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{confirmation?.title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {confirmation?.message ||
              'You will lose all of your data by deleting this. This action cannot be undone.'}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => {
              confirmation?.onConfirm();
              cancel();
            }}
          >
            Confirm
          </Button>
          <Button onClick={cancel}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default ConfirmationModel;
