import React, { ReactElement, useEffect, useState } from 'react';
import useFetchData from '../../../../hooks/useFetchData';
import { GeneralOverviewPageRenderedComponentProps } from '../../../../types/components/componentTypes';
import GeneralComponentErrorShield from '../../../general/GeneralComponentErrorShield';
import { useDispatch, useSelector } from 'react-redux';
import {
  addAdditionalTitleBarComponents,
  toggleTitleBarLoading,
} from '../../../../redux/pages/actions';
import { Select } from '../../../../types/redux/pages/PagesStore';
import { APPEND_NEW_SELECT_TO_TITLEBAR } from '../../../../types/redux/pages/pageTypes';
import useTitleBarSelect from '../../../../hooks/useTitleBarSelect';
import DisplayAreaCenteredWrapper from '../../../layout/utilities/displayAreaWrapper';
import MancoCounterpartySummaryTable from './MancoCounterpartySummaryTable.component';
import MancoCounterpartyBaseCurrencyExposureChart from './MancoCounterpartyBaseCurrencyExposureChart.component';
import MancoExposureTable from './MancoExposureTable.component';
import testData from './testData.json';
import { clientNameSelector } from '../../../../redux/auth/selectors';
import { Grid, Typography } from '@mui/material';

interface MancoCounterpartyExposureContainerProps {
  selectedCounterparty: string;
  selectedDate: string | undefined;
}

function MancoCounterpartyExposureContainer(
  props: MancoCounterpartyExposureContainerProps,
): ReactElement {
  const { selectedCounterparty, selectedDate } = props;
  const clientName = useSelector(clientNameSelector) || 'mersenne';

  const url = `manco_counterparty_gem_data/counterparty/${selectedCounterparty}/${
    selectedDate ? selectedDate : ''
  }`;

  const counterpartyExposureData =
    clientName !== 'mersenne'
      ? useFetchData({
          url: url,
          keyName: `manco_counterparty_gem_data_${selectedCounterparty}${
            selectedDate ? '_' + selectedDate : ''
          }`,
          makeFetch: true,
        })
      : {
          data: [testData],
          isFetching: false,
          error: null,
        };

  return (
    <GeneralComponentErrorShield dataObjects={[counterpartyExposureData]}>
      <MancoCounterpartySummaryTable dataForRender={counterpartyExposureData} />
      <MancoCounterpartyBaseCurrencyExposureChart
        data={counterpartyExposureData}
      />
      <MancoExposureTable
        data={counterpartyExposureData}
        isCounterpartyExposure
      />
    </GeneralComponentErrorShield>
  );
}

const MancoCounterpartyExposure: React.FC<
  GeneralOverviewPageRenderedComponentProps
> = (props) => {
  const activeDate = props.positionDate;
  const [counterpartyOptions, setCounterpartyOptions] = useState<any[] | null>(
    null,
  );

  const dropDownOptionsData = useFetchData({
    url: 'manco_counterparty_gem_data/manco_cp_key_data/',
    keyName: 'manco_counterparty_exposure_dropdown',
    makeFetch: true,
  });

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(toggleTitleBarLoading(true));
    if (dropDownOptionsData && !dropDownOptionsData.isFetching) {
      const dropdownOptions: any[] = [];
      for (const [key, value] of Object.entries(dropDownOptionsData.data[0])) {
        dropdownOptions.push({
          label: value,
          value: key,
        });
      }
      setCounterpartyOptions(dropdownOptions);
    }
  }, [dropDownOptionsData]);
  // add a dropdown for the scenarios
  useEffect(() => {
    if (counterpartyOptions) {
      const selectsToAdd: Select[] = [
        {
          titleBarTitle: 'Select Counterparty',
          titleBarKey: 'counterpartySelection',
          values: counterpartyOptions,
          displayOnOverviewPage: true,
          aValueIsAlwaysSelected: true,
          // currentValue: counterpartyOptions[0].value,
          currentValue: 'None Selected',
        },
      ];
      dispatch(
        addAdditionalTitleBarComponents(
          selectsToAdd,
          APPEND_NEW_SELECT_TO_TITLEBAR,
        ),
      );
      dispatch(toggleTitleBarLoading(false));
    }
  }, [counterpartyOptions]);

  const selectedCounterparty = useTitleBarSelect('counterpartySelection');
  return (
    <GeneralComponentErrorShield
      dataObjects={[dropDownOptionsData]}
      customLoadingMessages={[
        'Loading Counterparties...',
        'This May Take Few Moments',
      ]}
      customErrorMessage="No positions available for this date"
    >
      <Grid container spacing={2} style={{ padding: 8 }}>
        {selectedCounterparty === null ||
        selectedCounterparty === 'None Selected' ? (
          <DisplayAreaCenteredWrapper>
            <Typography variant="h2">Please Select a Counterparty</Typography>
          </DisplayAreaCenteredWrapper>
        ) : (
          <MancoCounterpartyExposureContainer
            selectedCounterparty={selectedCounterparty}
            selectedDate={activeDate}
          />
        )}
      </Grid>
    </GeneralComponentErrorShield>
  );
};

export default MancoCounterpartyExposure;
