import { createSelector } from 'reselect';
import { ComponentIndex } from '../../types/redux/pdfExports/pdfExportsStore';
import { AppState } from '../../types/redux/store';

export const getPdfExportState = (state: AppState) => state.pdfExport;

export const pdfStateSelector = createSelector(
  getPdfExportState,
  (state) => state,
);

export const loadingPdfSelector = createSelector(
  pdfStateSelector,
  (state) => state.loadingPagePdf,
);

export const componentsForExportSelector = createSelector(
  pdfStateSelector,
  (state) => state.componentsToExport,
);

export const createSpecificPdfComponentSelector = (componentId: string) =>
  createSelector(pdfStateSelector, (state) => {
    // we use filter to return an array, thereby using the same api as the overall page pdf export
    return state.componentsToExport.filter(
      (component: ComponentIndex) => component.identifier === componentId,
    );
  });
