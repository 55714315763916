import axios from 'axios';
import { BASE_URL } from '../../../../utilities/requestClient';
import RaptorLoading from '../../../feedback/RaptorLoading';
import NoDataMessage from '../../../feedback/NoDataMessage.component';
import { Grid } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { GeneralOverviewPageRenderedComponentProps } from '../../../../types/components/componentTypes';
import PotentialHedgePositionsTable from './subComponents/PotentialHedgePositionsTable.component';
import { getPotentialHybridBondsURL } from '../../../../routes/endpoints/manco.routes';

async function getAllPotentialHedgePositions() {
  const response = await axios.get(
    BASE_URL + 'get_all_potential_hedge_positions',
    {
      withCredentials: true,
    },
  );
  return response.data;
}

const HedgeDerivativesSelector: React.FC<
  GeneralOverviewPageRenderedComponentProps
> = (props) => {
  const { data, isPending, error } = useQuery({
    queryKey: ['getAllPotentialHedgePositions'],
    queryFn: getAllPotentialHedgePositions,
  });

  if (isPending) {
    return (
      <RaptorLoading
        centerWrap
        messages={[
          'Loading Potential Hedge Positions Data',
          'This may take a few seconds...',
        ]}
      />
    );
  }
  if (error) {
    return <NoDataMessage message={'Error Retrieving Data'} />;
  }

  return (
    <Grid container spacing={2} style={{ padding: 8 }}>
      <PotentialHedgePositionsTable originalData={data} />
    </Grid>
  );
};

export default HedgeDerivativesSelector;
