import fileDownload from 'js-file-download';
import { FC, useEffect, useState } from 'react';
import { useWindowSize } from 'react-use';
import { mainColors } from '../../../../../../styling/theme';
import RaptorLoading from '../../../../../feedback/RaptorLoading';
import GridItem from '../../../../../layout/GridComponents/GridItem';
import { Document, Page } from 'react-pdf';
import CheckIcon from '@mui/icons-material/Check';
import { useSelector } from 'react-redux';
import { activePageSelector } from '../../../../../../redux/pages/selectors';
import axios from 'axios';
import { Button, Typography } from '@mui/material';
import client from '../../../../../../utilities/requestClient';

interface ReviewProps {
  fundId: string | null;
  fundName: string | null;
  lastEditedDate: string | null;
  isin: string | null;
  kiidsData: any[];
  numPages: number;
  viewTrackedChanges: boolean;
}

interface ReviewDocumentsProps {
  document: any | null;
  pageNumber: number;
}

const ReviewDouments: FC<ReviewDocumentsProps> = (props) => {
  const { document, pageNumber } = props;
  const { width, height } = useWindowSize();

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-around',
        }}
      >
        <Typography>Previous Version</Typography>
        <Typography>Current Version</Typography>
      </div>
      <div
        style={{
          width: '100%',
          height: 'calc(100vh - 15rem)',
          display: 'flex',
          // padding: '2rem'
          justifyContent: 'space-around',
        }}
      >
        <div
          style={{
            marginBottom: '0.5rem',
            marginTop: '0.5rem',
            marginLeft: '2rem',
            marginRight: '1rem',
            // width: 300,
            display: 'flex',
            justifyContent: 'space-around',
          }}
        >
          <div
            style={{
              border: '1px solid',
              borderColor: mainColors.mainBlue,
              width: height * 0.56,
              // display: 'flex', justifyContent: 'space-around',
              marginRight: '1rem',
            }}
          >
            <Document
              // /get_pai_report
              // file={'https://dev2david.risksystem.com/get_kiids_sample'}
              // file={pdfDoc ? { data: pdfDoc } : BASE_URL + 'kiids_generator_read/' + props.fundId}
              file={document ? { data: document } : null}
              // onLoadSuccess={onDocumentLoadSuccess}
              onLoadError={console.error}
              loading={<RaptorLoading />}
            >
              <Page pageNumber={pageNumber} width={height * 0.55}></Page>
            </Document>
          </div>
          <div
            style={{
              border: '1px solid',
              borderColor: mainColors.mainBlue,
              width: height * 0.56,
              // display: 'flex', justifyContent: 'space-around'
            }}
          >
            <Document
              // /get_pai_report
              // file={'https://dev2david.risksystem.com/get_kiids_sample'}
              // file={pdfDoc ? { data: pdfDoc } : BASE_URL + 'kiids_generator_read/' + props.fundId}
              file={document ? { data: document } : null}
              // onLoadSuccess={onDocumentLoadSuccess}
              onLoadError={console.error}
              loading={<RaptorLoading />}
            >
              <Page pageNumber={pageNumber + 1} width={height * 0.55}></Page>
            </Document>
          </div>
        </div>
      </div>
    </>
  );
};

const Review: FC<ReviewProps> = (props) => {
  const [numPages, setNumPages] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [numComparisonPages, setNumComparisonPages] = useState<number>(0);
  const [comparisonPageNumbers, setComparisonPageNumbers] = useState<
    number[][]
  >([]);
  const [pdfDoc, setPdfDoc] = useState(null);
  const [lastEditedDate, setLastEditedDate] = useState(props.lastEditedDate);
  const [numPagesinEachKid, setNumPagesInEachKid] = useState<number[]>([]);
  const [reviewButtonText, setReviewButtonText] = useState('Confirm Review');

  const requestClient = client();

  const markKiidReviewed = (
    fundId: string,
    shareClass: string,
    documentLanguag: string,
  ) => {
    // Ensure that the user defintely wants to complete a review
    const confirmation = confirm('Complete Review of Document?');
    if (!confirmation) {
      return;
    }
    // Create an object of formData
    const formData = new FormData();
    // Update the formData object.
    formData.append('fund_id', fundId);
    formData.append('share_class', shareClass);
    formData.append('document_language', documentLanguag);

    requestClient
      .post('kids_generator_mark_kid_reviewed', formData, {
        headers: {
          'content-type': 'multipart/form-data',
        },
      })
      .then((response) => {
        if (
          'Status' in response.data &&
          response.data['Status'] === 'success'
        ) {
          setReviewButtonText('Review Comlete');
        }
      });
  };
  const currentPage = useSelector(activePageSelector);

  const refreshKiid = () => {
    // Check if the required fields have been filled in.
    if (!props.kiidsData.length) {
      return;
    }
    const kiidsJson = JSON.stringify(props.kiidsData);
    // Create an object of formData
    const formData = new FormData();

    // Update the formData objet.
    formData.append('contents', kiidsJson);
    formData.append('fund_id', props.fundId || '');
    formData.append('fund_name', props.fundName || '');
    formData.append('isin', props.isin || '');
    formData.append('version', '1');
    formData.append('params', JSON.stringify(['contents']));

    requestClient
      .post('risksystem_pdf_generator/generate_new_review_kiid', formData, {
        headers: {
          'content-type': 'multipart/form-data',
        },
      })
      .then((response) => {
        setNumPages(props.numPages);
        setNumComparisonPages(props.numPages / 2);

        let docNum = 0;
        let docNumPages = currentPage?.includes('kiids')
          ? props.kiidsData[docNum].num_pages - 2
          : props.kiidsData[docNum].num_pages;
        const target: number[][] = [[]];
        const numPagesForLoop = currentPage?.includes('kiids')
          ? props.numPages - 2 * props.kiidsData.length
          : props.numPages;
        for (let i = 1; i < numPagesForLoop; i = i + 2) {
          if (i <= docNumPages) {
            target[docNum].push(i);
          } else {
            docNumPages += props.kiidsData[docNum].num_pages;
            docNum += 1;
            target.push([i]);
          }
        }

        const compPages: number[] = [];
        for (let i = 1; i <= props.numPages; i = i + 2) {
          compPages.push(i);
        }
        setComparisonPageNumbers((oldArray: number[][]) => target);
        // Store the number of pages in the document related to each kid.
        const tempPages: number[] = [];
        props.kiidsData.forEach((doc: any) => {
          tempPages.push(
            currentPage?.includes('kiids') ? doc.num_pages - 2 : doc.num_pages,
          );
        });
        setNumPagesInEachKid(tempPages);
        setPdfDoc(response.data);
      });
  };

  useEffect(() => {
    refreshKiid();
  }, [props.kiidsData]);

  const handleFileDownload = () => {
    if (pdfDoc) {
      fileDownload(
        pdfDoc || '',
        props.fundName + '_' + props.isin + '_' + lastEditedDate + '.pdf',
      );
    }
  };

  return pdfDoc ? (
    <>
      {comparisonPageNumbers.map((numPages: number[], index: number) => {
        return (
          <GridItem
            xs={12}
            card
            style={{
              minHeight: '20rem',
              overflow: 'hidden',
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginRight: '1rem',
              }}
            >
              <Typography
                style={{ marginTop: '2rem', marginLeft: '3rem' }}
              >{`${props.kiidsData[index].comparison[0].fund_name} - ${props.kiidsData[index].comparison[0].share_class}`}</Typography>
              {!props.viewTrackedChanges && (
                <Button
                  variant="contained"
                  startIcon={<CheckIcon />}
                  style={{
                    backgroundColor:
                      reviewButtonText === 'Confirm Review'
                        ? mainColors.mainBlue
                        : mainColors.Pass_darker,
                    color: 'white',
                    marginTop: '1rem',
                  }}
                  disabled={reviewButtonText !== 'Confirm Review'}
                  onClick={() =>
                    markKiidReviewed(
                      props.kiidsData[index].comparison[0].fund_id_string,
                      props.kiidsData[index].comparison[0].share_class,
                      props.kiidsData[index].comparison[0].document_language,
                    )
                  }
                >
                  {reviewButtonText}
                </Button>
              )}
            </div>

            {numPages.map((num: number) => {
              return (
                <ReviewDouments
                  document={pdfDoc}
                  pageNumber={num}
                  key={`page_num_${num}`}
                />
              );
            })}
          </GridItem>
        );
      })}
    </>
  ) : (
    <RaptorLoading
      noMinHeightOnError={true}
      centerWrap
      messages={['Loading Review Data']}
    />
  );
};

export default Review;
