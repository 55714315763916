import React, { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import useFetchData from '../../../../hooks/useFetchData';
import useTitleBarSelect from '../../../../hooks/useTitleBarSelect';
import {
  addAdditionalTitleBarComponents,
  toggleTitleBarLoading,
} from '../../../../redux/pages/actions';
import { InfoToggle, Select } from '../../../../types/redux/pages/PagesStore';
import {
  APPEND_NEW_INFOTOGGLE_TO_TITLEBAR,
  APPEND_NEW_SELECT_TO_TITLEBAR,
} from '../../../../types/redux/pages/pageTypes';
import GeneralComponentErrorShield from '../../../general/GeneralComponentErrorShield';
import { FundInfoComponentProps } from '../../../general/GeneralFundInfoPage';
import FundAssetLiquidationTable from './table';

export const LiquidityUrls = {
  FETCH_FUND_LIQUIDATION_TIME_COST: 'aggregate_liquidity_risk_report_data/2',
  FETCH_FUND_LIQUIDITY_RISK_OVERVIEW: 'aggregate_liquidity_risk_report_data/3',
  FETCH_EVOLUTION: 'aggregate_lqdty_review_data',
  FETCH_RST: 'historical_liquidity_reverse_stress_tests_agg',
};

const defaultDropDownValues = [
  { label: 'BAU', value: 'data' },
  { label: 'Stressed', value: 'data_s' },
  { label: 'Super-Stressed', value: 'data_ss' },
];

const FundAssetLiquidationTime: FC<FundInfoComponentProps> = ({
  positionDate,
  fundId,
}) => {
  const dispatch = useDispatch();

  const [scenariosOptions, setScenariosOptions] = useState<any>(null);

  const fundLiquidityData = useFetchData({
    url: `agg_liquidation_zone_inc_cash/${fundId}/${positionDate}`,
    keyName: `fund_asset_liquidation_time_data_${fundId}_${positionDate}`,
    makeFetch: positionDate && fundId ? true : false,
  });

  useEffect(() => {
    dispatch(toggleTitleBarLoading(true));
    if (fundLiquidityData && !fundLiquidityData.isFetching) {
      if ('liquidation_time_data_keys' in fundLiquidityData.data[0]) {
        setScenariosOptions(
          fundLiquidityData.data[0].liquidation_time_data_keys.map(
            (item: any) => ({
              label: item[0],
              value: item[1],
            }),
          ),
        );
      } else {
        setScenariosOptions(defaultDropDownValues);
      }
    }
  }, [fundLiquidityData]);

  // Add the select.
  useEffect(() => {
    if (scenariosOptions) {
      const selectsToAdd: Select[] = [
        {
          titleBarTitle: 'Select Scenario',
          titleBarKey: 'scenarioSelection',
          values: scenariosOptions,
          displayOnOverviewPage: false,
          aValueIsAlwaysSelected: true,
          currentValue: scenariosOptions[0].value,
        },
      ];
      dispatch(
        addAdditionalTitleBarComponents(
          selectsToAdd,
          APPEND_NEW_SELECT_TO_TITLEBAR,
        ),
      );
      const infoTogglesToAdd: InfoToggle[] = [
        {
          titleBarTitle: 'Select Scenario',
          titleBarKey: 'scenarioSelection',
          displayOnOverviewPage: false,
          currentValue: '',
          message: (
            <p>
              This page shows the liquidation time of the fund and each
              individual position based on a fraction of the average market
              volume.
              <br />
              The volume used for the calculation is defined below:
              <br />
              <br />
              BAU: 10% of the Average Market Volume
              <br />
              Stressed: 7.5% of the Average Market Volume
              <br />
              Super Stressed: 5% of the Average Market Volume
            </p>
          ),
        },
      ];
      dispatch(
        addAdditionalTitleBarComponents(
          infoTogglesToAdd,
          APPEND_NEW_INFOTOGGLE_TO_TITLEBAR,
        ),
      );
      dispatch(toggleTitleBarLoading(false));
    }
  }, [scenariosOptions]);

  const chosenStressLevel = useTitleBarSelect('scenarioSelection');

  return (
    <GeneralComponentErrorShield
      dataObjects={[fundLiquidityData]}
      customErrorMessage="No Data Available."
    >
      <FundAssetLiquidationTable
        activeFund={fundId}
        stressLevel={chosenStressLevel || 'standard'}
        fundAssetData={fundLiquidityData}
        stressLevelOptions={scenariosOptions}
      />
    </GeneralComponentErrorShield>
  );
};

export default FundAssetLiquidationTime;
