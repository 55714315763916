import { ContentState, Editor, EditorState } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import React, { Dispatch, FC } from 'react';
import GridItem from '../../../../../layout/GridComponents/GridItem';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';
import { greys, mainColors } from '../../../../../../styling/theme';
import { Typography } from '@mui/material';

interface AnnexObjectivesEditorTileProps {
  delegatedActsElement: string;
  delegatedActsData: any[];
  setDelegatedActsData: Dispatch<any>;
  setHaveChangesBeenMade: Dispatch<any>;
  index: number;
}

interface CustomeCheckboxProps {
  checkIndicator: boolean;
  onClick: () => void;
}

interface CustomeEditorboxProps {
  initialText: string;
  onTextChange: (text: string) => void;
}

interface ObjectivesYesNoCheckboxesProps {
  yesNoValue: boolean;
  onChange: (yesNo: 'yes' | 'no') => void;
}

function ObjectiveYesNoCheckboxes({
  yesNoValue,
  onChange,
}: ObjectivesYesNoCheckboxesProps): React.ReactElement {
  const [yesNoChecked, setYesNoChecked] = React.useState(yesNoValue);

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-around',
      }}
    >
      <div
        style={{
          display: 'flex',
        }}
      >
        <Typography variant="h3">Yes</Typography>
        {yesNoChecked ? (
          <DisabledByDefaultIcon
            style={{
              fontSize: '1.8rem',
              cursor: 'pointer',
              color: mainColors.controlButtonBlue,
            }}
            onClick={() => {
              setYesNoChecked(false);
              onChange('yes');
            }}
          />
        ) : (
          <CheckBoxOutlineBlankIcon
            style={{ fontSize: '1.8rem', cursor: 'pointer', color: 'black' }}
            onClick={() => {
              setYesNoChecked(true);
              onChange('yes');
            }}
          />
        )}
      </div>
      <div
        style={{
          display: 'flex',
        }}
      >
        <Typography variant="h3">No</Typography>
        {!yesNoChecked ? (
          <DisabledByDefaultIcon
            style={{
              fontSize: '1.8rem',
              cursor: 'pointer',
              color: mainColors.controlButtonBlue,
            }}
            onClick={() => {
              setYesNoChecked(true);
              onChange('no');
            }}
          />
        ) : (
          <CheckBoxOutlineBlankIcon
            style={{ fontSize: '1.8rem', cursor: 'pointer', color: 'black' }}
            onClick={() => {
              setYesNoChecked(false);
              onChange('no');
            }}
          />
        )}
      </div>
    </div>
  );
}

function CustomCheckBox({
  checkIndicator,
  onClick,
}: CustomeCheckboxProps): React.ReactElement {
  const [isChecked, setIsChecked] = React.useState(checkIndicator);

  return isChecked ? (
    <DisabledByDefaultIcon
      style={{
        fontSize: '1.8rem',
        cursor: 'pointer',
        color: mainColors.controlButtonBlue,
      }}
      onClick={() => {
        setIsChecked(false);
        onClick();
      }}
    />
  ) : (
    <CheckBoxOutlineBlankIcon
      style={{ fontSize: '1.8rem', cursor: 'pointer', color: 'black' }}
      onClick={() => {
        setIsChecked(true);
        onClick();
      }}
    />
  );
}

function CustomEditorBox({
  initialText,
  onTextChange,
}: CustomeEditorboxProps): React.ReactElement {
  const [editorState, setEditorState] = React.useState(
    EditorState.createWithContent(ContentState.createFromText(initialText)),
  );

  const handleTextChange = (updatedEditorState: EditorState) => {
    setEditorState(updatedEditorState);
    let text = stateToHTML(updatedEditorState.getCurrentContent());

    text = text
      .replaceAll('<p>', '')
      .replaceAll('</p>', '')
      .replaceAll('<strong>', '<b>')
      .replaceAll('</strong>', '</b>')
      .replaceAll('<em>', '<i>')
      .replaceAll('</em>', '</i>');
    onTextChange(text);
  };

  return (
    <Editor
      editorState={editorState}
      onChange={(updatedEditorState: EditorState) => {
        handleTextChange(updatedEditorState);
      }}
      stripPastedStyles={true}
    />
  );
}

const AnnexObjectivesEditorTile: FC<AnnexObjectivesEditorTileProps> = (
  props,
) => {
  // const data = localStorage.getItem(TEXT_EDITOR_ITEM);
  // const initialState = EditorState.createWithContent(ContentState.createFromText(props.text || ''));
  const {
    delegatedActsElement,
    delegatedActsData,
    setDelegatedActsData,
    index,
    setHaveChangesBeenMade,
  } = props;

  // Convert the data into an object.
  const dataJson = JSON.parse(delegatedActsElement);

  // // This will be used to indicate if the yes/no checkbox is ticked
  const [objectiveYesNoCheckbox, setObjectiveYesNoCheckbox] =
    React.useState<boolean>(dataJson.objective);

  // // Initalise each of these state objects iwth default values.
  const [objectives, setObjectives] = React.useState({
    objectiveYesPart1:
      'Objectives_yes_part1' in dataJson
        ? dataJson.Objectives_yes_part1
        : false,
    objectiveYesPart1Value:
      'Objectives_yes_part1_value' in dataJson
        ? dataJson.Objectives_yes_part1_value
        : '',
    objectiveYesPart2:
      'Objectives_yes_part2' in dataJson
        ? dataJson.Objectives_yes_part2
        : false,
    objectiveYesPart3:
      'Objectives_yes_part3' in dataJson
        ? dataJson.Objectives_yes_part3
        : false,
    objectiveYesPart4:
      'Objectives_yes_part4' in dataJson
        ? dataJson.Objectives_yes_part4
        : false,
    objectiveYesPart4Value:
      'Objectives_yes_part4_value' in dataJson
        ? dataJson.Objectives_yes_part4_value
        : '',
    objectiveNoPart1:
      'Objectives_no_part1' in dataJson ? dataJson.Objectives_no_part1 : false,
    objectiveNoPart1Value:
      'Objectives_no_part1_value' in dataJson
        ? dataJson.Objectives_no_part1_value
        : '',
    objectiveNoPart2:
      'Objectives_no_part2' in dataJson ? dataJson.Objectives_no_part2 : false,
    objectiveNoPart3:
      'Objectives_no_part3' in dataJson ? dataJson.Objectives_no_part3 : false,
    objectiveNoPart4:
      'Objectives_no_part4' in dataJson ? dataJson.Objectives_no_part4 : false,
    objectiveNoPart5:
      'Objectives_no_part5' in dataJson ? dataJson.Objectives_no_part5 : false,
  });

  const handleOverallObjectiveClick = (yesNo: 'yes' | 'no') => {
    // Important to note that we assume that one of the checkboxes must be toggles on at all times
    // This means we can only swithc which one is checked
    // Case where yes is clicked.
    if (yesNo === 'yes') setObjectiveYesNoCheckbox(true);
    else setObjectiveYesNoCheckbox(false); // case where no is clicked
  };

  // Will be used for looking up the exact key needed in the json data.
  const valueMap = {
    objectiveYesPart1: 'Objectives_yes_part1',
    objectiveYesPart1Value: 'Objectives_yes_part1_value',
    objectiveYesPart2: 'Objectives_yes_part2',
    objectiveYesPart3: 'Objectives_yes_part3',
    objectiveYesPart4: 'Objectives_yes_part4',
    objectiveYesPart4Value: 'Objectives_yes_part4_value',
    objectiveNoPart1: 'Objectives_no_part1',
    objectiveNoPart1Value: 'Objectives_no_part1_value',
    objectiveNoPart2: 'Objectives_no_part2',
    objectiveNoPart3: 'Objectives_no_part3',
    objectiveNoPart4: 'Objectives_no_part4',
    objectiveNoPart5: 'Objectives_no_part5',
  };

  const handleGeneralCheckboxClick = (
    boxClicked:
      | 'objectiveYesPart1'
      | 'objectiveYesPart2'
      | 'objectiveYesPart3'
      | 'objectiveYesPart4'
      | 'objectiveNoPart1'
      | 'objectiveNoPart2'
      | 'objectiveNoPart3'
      | 'objectiveNoPart4'
      | 'objectiveNoPart5',
  ) => {
    const objectivesCopy = objectives;
    objectivesCopy[boxClicked] = !objectivesCopy[boxClicked];
    setObjectives(objectivesCopy);
    // UPdate the overall data.
    const allContent = delegatedActsData;
    // Check the key we need to update.
    const dataKey = valueMap[boxClicked];
    // Update the datajson
    dataJson[dataKey] = objectivesCopy[boxClicked];
    // Indicate that a change was made.
    setHaveChangesBeenMade(true);
    // now replace in the overall array
    allContent[index].content = JSON.stringify(dataJson);
    // Update the overall data.
    setDelegatedActsData(allContent);
  };

  const handleTextChange = (
    text: string,
    valueEdited:
      | 'objectiveYesPart1Value'
      | 'objectiveYesPart4Value'
      | 'objectiveNoPart1Value',
  ) => {
    // Now update the element in the overall data
    const allContent = delegatedActsData;
    // Check the key we need to update.
    const dataKey = valueMap[valueEdited];
    // Check which data point of data needs to be updated dataJson
    dataJson[dataKey] = text;
    // Check if the text has changes and if so indicate it
    if (allContent[index].content !== delegatedActsElement)
      setHaveChangesBeenMade(true);
    // Now replace in the overall array
    allContent[index].content = JSON.stringify(dataJson);
    // Update the overall data
    setDelegatedActsData(allContent);
  };

  return (
    <GridItem
      xs={12}
      card
      style={{
        marginBottom: '1rem',
      }}
    >
      <div>
        <Typography
          variant="h3"
          style={{ fontSize: '1.5rem', color: greys.grey400 }}
        >
          Annex 4
        </Typography>
        <ObjectiveYesNoCheckboxes
          yesNoValue={objectiveYesNoCheckbox}
          onChange={handleOverallObjectiveClick}
        />

        {objectiveYesNoCheckbox ? (
          <>
            <div
              style={{
                display: 'flex',
                // justifyContent: 'space-around'
                marginLeft: '2rem',
                marginTop: '1rem',
              }}
            >
              <CustomCheckBox
                checkIndicator={objectives.objectiveYesPart1}
                onClick={() => {
                  handleGeneralCheckboxClick('objectiveYesPart1');
                }}
              />
              <Typography variant="h3" style={{ marginLeft: '1rem' }}>
                It made{' '}
                <b>sustainable investments with an environmental objective:</b>
              </Typography>
              <div
                style={{
                  marginLeft: '1rem',
                  border: '0.5px solid',
                  minHeight: '2rem',
                  minWidth: '2rem',
                  backgroundColor: 'white',
                }}
              >
                <CustomEditorBox
                  initialText={objectives.objectiveYesPart1Value}
                  onTextChange={(text: string) => {
                    handleTextChange(text, 'objectiveYesPart1Value');
                  }}
                />
              </div>
            </div>{' '}
            :
            <div
              style={{
                display: 'flex',
                // justifyContent: 'space-around'
                marginLeft: '4rem',
              }}
            >
              <CustomCheckBox
                checkIndicator={objectives.objectiveYesPart2}
                onClick={() => {
                  handleGeneralCheckboxClick('objectiveYesPart2');
                }}
              />
              <Typography variant="h3" style={{ marginLeft: '1rem' }}>
                in economic activities that qualify as environmentally
                sustainable under the EU Taxonomy
              </Typography>
            </div>
            <div
              style={{
                display: 'flex',
                // justifyContent: 'space-around'
                marginLeft: '4rem',
                marginTop: '1rem',
              }}
            >
              <CustomCheckBox
                checkIndicator={objectives.objectiveYesPart3}
                onClick={() => {
                  handleGeneralCheckboxClick('objectiveYesPart3');
                }}
              />
              <Typography variant="h3" style={{ marginLeft: '1rem' }}>
                in economic activities that do not qualify as environmentally
                sustainable under the EU Taxonomy
              </Typography>
            </div>
            <div
              style={{
                display: 'flex',
                // justifyContent: 'space-around'
                marginLeft: '2rem',
                marginTop: '2rem',
                marginBottom: '2rem',
              }}
            >
              <CustomCheckBox
                checkIndicator={objectives.objectiveYesPart4}
                onClick={() => {
                  handleGeneralCheckboxClick('objectiveYesPart4');
                }}
              />
              <Typography variant="h3" style={{ marginLeft: '1rem' }}>
                It made <b>sustainable investments with a social objective:</b>
              </Typography>
              <div
                style={{
                  marginLeft: '1rem',
                  border: '0.5px solid',
                  minHeight: '2rem',
                  minWidth: '2rem',
                  backgroundColor: 'white',
                }}
              >
                <CustomEditorBox
                  initialText={objectives.objectiveYesPart4Value}
                  onTextChange={(text: string) => {
                    handleTextChange(text, 'objectiveYesPart4Value');
                  }}
                />
              </div>
            </div>
          </>
        ) : (
          <>
            <div
              style={{
                display: 'flex',
                // justifyContent: 'space-around'
                marginLeft: '2rem',
                marginTop: '1rem',
              }}
            >
              <CustomCheckBox
                checkIndicator={objectives.objectiveNoPart1}
                onClick={() => {
                  handleGeneralCheckboxClick('objectiveNoPart1');
                }}
              />
              <div>
                <Typography variant="h3" style={{ marginLeft: '1rem' }}>
                  It <b>promoted Environmental/Social (E/S) characteristics:</b>{' '}
                  while it did not have as its objective a sustainable
                  investment, it had a proportion of
                </Typography>
                <div
                  style={{
                    marginLeft: '1rem',
                    border: '0.5px solid',
                    minHeight: '2rem',
                    minWidth: '2rem',
                    maxWidth: '5rem',
                    backgroundColor: 'white',
                  }}
                >
                  <CustomEditorBox
                    initialText={objectives.objectiveNoPart1Value}
                    onTextChange={(text: string) => {
                      handleTextChange(text, 'objectiveNoPart1Value');
                    }}
                  />
                </div>
                <Typography variant="h3" style={{ marginLeft: '1rem' }}>
                  of sustainable investments
                </Typography>
              </div>
            </div>{' '}
            :
            <div
              style={{
                display: 'flex',
                // justifyContent: 'space-around'
                marginLeft: '4rem',
              }}
            >
              <CustomCheckBox
                checkIndicator={objectives.objectiveNoPart2}
                onClick={() => {
                  handleGeneralCheckboxClick('objectiveNoPart2');
                }}
              />
              <Typography variant="h3" style={{ marginLeft: '1rem' }}>
                with an environmental objective in economic activaities that
                qualify as environmentally sustainable under the EU taxonomy
              </Typography>
            </div>
            <div
              style={{
                display: 'flex',
                // justifyContent: 'space-around'
                marginLeft: '4rem',
                marginTop: '1rem',
              }}
            >
              <CustomCheckBox
                checkIndicator={objectives.objectiveNoPart3}
                onClick={() => {
                  handleGeneralCheckboxClick('objectiveNoPart3');
                }}
              />
              <Typography variant="h3" style={{ marginLeft: '1rem' }}>
                with an environmental objective in economic activaities that do
                not qualify as environmentally sustainable under the EU taxonomy
              </Typography>
            </div>
            <div
              style={{
                display: 'flex',
                // justifyContent: 'space-around'
                marginLeft: '4rem',
                marginTop: '1rem',
              }}
            >
              <CustomCheckBox
                checkIndicator={objectives.objectiveNoPart4}
                onClick={() => {
                  handleGeneralCheckboxClick('objectiveNoPart4');
                }}
              />
              <Typography variant="h3" style={{ marginLeft: '1rem' }}>
                with a socal objective
              </Typography>
            </div>
            <div
              style={{
                display: 'flex',
                // justifyContent: 'space-around'
                marginLeft: '2rem',
                marginBottom: '2rem',
                marginTop: '1rem',
              }}
            >
              <CustomCheckBox
                checkIndicator={objectives.objectiveNoPart5}
                onClick={() => {
                  handleGeneralCheckboxClick('objectiveNoPart5');
                }}
              />
              <Typography variant="h3" style={{ marginLeft: '1rem' }}>
                It promoted E/S characteristics, but{' '}
                <b>did not make any sustainable inverstments</b>
              </Typography>
            </div>
          </>
        )}
      </div>
    </GridItem>
  );
};

export default AnnexObjectivesEditorTile;
