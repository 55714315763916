import React, { Dispatch, ReactElement, SetStateAction, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { RaptorTheme } from '../../styling/theme';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';

const useStyles = makeStyles<RaptorTheme, StyleProps>((theme: RaptorTheme) => ({
  formControl: {
    margin: (props) => (props.margin ? props.margin : theme.spacing(1)),
    marginBottom: 0,
    minWidth: 120,
    flexGrow: 1,
    '& label': {
      width: '15rem',
    },
  },
  selectRoot: {
    color: (props) => props.placeholderColor,
    fontSize: (props) => props.fontSize || '1.4rem',
    height: '100%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  notchedOutline: {
    backgroundColor: 'red',
    borderColor: 'yellow !important',
  },
  testInput: {
    // padding: "1rem",
  },
  pickerRoot: {
    marginRight: '.5rem',
  },
  noneSelectedRoot: {
    color: (props) => props.placeholderColor,
  },
}));

export interface MenuChoice {
  value: string;
  label: string;
}
interface Props<T> {
  labels: ReadonlyArray<string>;
  values: ReadonlyArray<T>;
  setSelected: (
    selected: T,
  ) => void | Dispatch<SetStateAction<T>> | Promise<void>;
  selected: T;
  placeholderValue?: string | null;
  selectTitle?: string | React.ReactNode;
  variant?: 'filled' | 'standard' | undefined | 'outlined';
  customMenuItem?: React.FunctionComponent<CustomMenuItemProps>;
  disableUnderline?: boolean;
  placeholderColor?: string;
  fundName?: string;
  margin?: string;
  disabled?: boolean;
  fontSize?: string | number;
  label?: string;
  // these custom styles allow us to define useStyles in the parent component, and then
  // pass the style specifier string down to the child component
  customStyles?: {
    formControl?: string;
    selectRoot?: string;
    selectOutlined?: string;
    pickerRoot?: string;
  };
  middleLabelValue?: string;
  // the labelTitle is if we want to give some information, e.g View Type in the case of asset class
  labelTitle?: string;
  truncateLabels?: boolean;
}

interface StyleProps {
  placeholderColor: string;
  margin?: string;
  fontSize: string | number | null;
}

export interface CustomMenuItemProps {
  label: string;
  value: string;
  fundName?: string | null;
}

export function labelTruncator(input: string) {
  let string = input;
  if (string.length > 25) {
    string = string.substring(0, 24) + '...';
  }
  return string;
}

function GeneralSelect<T extends string>({
  labels,
  values,
  selected,
  setSelected,
  placeholderValue,
  ...props
}: Props<T>): ReactElement {
  const classes = useStyles({
    placeholderColor: props.placeholderColor || 'red',
    margin: props.margin ? props.margin : null,
    fontSize: props.fontSize || null,
  } as StyleProps);

  const [open, setOpen] = useState(false);
  const handleChange = (event: SelectChangeEvent<T>) => {
    setSelected(event.target.value as T);
  };
  const selectChange = (event: React.MouseEvent) => {
    event.stopPropagation();
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const SelectMenuItem = props.customMenuItem ? props.customMenuItem : MenuItem;
  return (
    <div className={props.customStyles?.pickerRoot || classes.pickerRoot}>
      {props.middleLabelValue && (
        <Typography
          style={{ marginLeft: '.8rem', lineHeight: '1px' }}
          variant="subtitle1"
        >
          {props.middleLabelValue}
        </Typography>
      )}
      <FormControl
        variant={props.variant ? props.variant : 'standard'}
        className={props.customStyles?.formControl || classes.formControl}
      >
        {props.selectTitle && <InputLabel>{props.selectTitle}</InputLabel>}
        <Select
          onOpen={handleOpen}
          onClose={handleClose}
          disableUnderline
          onClick={selectChange}
          id="demo-simple-select-outlined"
          value={selected}
          onChange={handleChange}
          disabled={props.disabled}
          label={props.selectTitle ? props.selectTitle : ''}
          classes={{
            root: props.customStyles?.selectRoot || classes.selectRoot,
            outlined: props.customStyles?.selectOutlined || classes.testInput,
          }}
        >
          {placeholderValue && (
            <MenuItem
              value="none_selected"
              classes={{
                root: classes.noneSelectedRoot,
              }}
            >
              {placeholderValue}
            </MenuItem>
          )}
          {labels.map((label, index: number) => (
            <SelectMenuItem
              fundName={props.fundName}
              key={label + values[index]}
              label={label}
              value={values[index] as string}
            >
              {props.truncateLabels && label === selected && !open
                ? labelTruncator(label)
                : label}
            </SelectMenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}

export default GeneralSelect;
