import {
  addCommasToNumbers,
  addCommasToNumbersAndRound,
  nonStringPercentageToTwoDecimalPlaces,
  percentageToTwoDecimalPlaces,
  toTwoDecimalPlaces,
} from '../../../../../utilities/numberFormatters';
import { CustomColumn } from '../../../../../types/components/tables/tableTypes';

export interface CounterpartyVarExposureData {
  counterparty: string;
  equity: number;
  commodity: number;
  forex: number;
  fixedIncome: number;
  cash: number;
  alternative: number;
  total: number;
}

export interface CounterpartyLiquidationData {
  counterparty: string;
  assetValue: number;
  liquidationDistance: number;
}

export interface CounterpartyAvailableLiquidityData {
  counterparty: string;
  liquidity: number;
  var: number;
  cpRatio: number;
  fundRatio: number;
}

export const counterpartyVarExposureColumns: CustomColumn<CounterpartyVarExposureData>[] =
  [
    {
      title: 'Counterparty',
      field: 'counterparty',
      width: '30rem',
    },
    {
      title: 'Equity',
      field: 'equity',
      render: (rowData) => percentageToTwoDecimalPlaces(rowData.equity),
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
      },
    },
    {
      title: 'Commodity',
      field: 'commodity',
      render: (rowData) => percentageToTwoDecimalPlaces(rowData.commodity),
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
      },
    },
    {
      title: 'Forex',
      field: 'forex',
      render: (rowData) => percentageToTwoDecimalPlaces(rowData.forex),
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
      },
    },
    {
      title: 'Fixed Income',
      field: 'fixedIncome',
      render: (rowData) => percentageToTwoDecimalPlaces(rowData.fixedIncome),
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
      },
    },
    {
      title: 'Cash',
      field: 'cash',
      render: (rowData) => percentageToTwoDecimalPlaces(rowData.cash),
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
      },
    },
    {
      title: 'Alternative',
      field: 'alternative',
      render: (rowData) => percentageToTwoDecimalPlaces(rowData.alternative),
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
      },
    },
    {
      title: 'Total',
      field: 'total',
      render: (rowData) => percentageToTwoDecimalPlaces(rowData.total),
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
      },
    },
  ];

export const counterpartyLiquidationColumns: CustomColumn<CounterpartyLiquidationData>[] =
  [
    {
      title: 'Counterparty',
      field: 'counterparty',
      headerStyle: {
        textAlign: 'left',
      },
    },
    {
      title: 'Asset Value',
      field: 'assetValue',
      headerStyle: {
        textAlign: 'center',
      },
      cellStyle: {
        textAlign: 'center',
      },
      render: (rowData) => addCommasToNumbersAndRound(rowData.assetValue),
    },
    {
      title: 'Liquidation Distance',
      field: 'liquidationDistance',
      headerStyle: {
        textAlign: 'center',
      },
      cellStyle: {
        textAlign: 'center',
      },
      render: (rowData) => toTwoDecimalPlaces(rowData.liquidationDistance),
    },
  ];

export const counterpartyAvailableLiquidityColumns: CustomColumn<CounterpartyAvailableLiquidityData>[] =
  [
    {
      title: 'Counterparty',
      field: 'counterparty',
      headerStyle: {
        textAlign: 'left',
      },
    },
    {
      title: 'Liquidity',
      headerStyle: {
        textAlign: 'center',
      },
      cellStyle: {
        textAlign: 'center',
      },
      field: 'liquidity',
      render: (rowData) =>
        `${addCommasToNumbers(
          nonStringPercentageToTwoDecimalPlaces(rowData.liquidity),
        )}%`,
    },
    {
      title: 'VaR',
      field: 'var',
      headerStyle: {
        textAlign: 'center',
      },
      cellStyle: {
        textAlign: 'center',
      },
      render: (rowData) => percentageToTwoDecimalPlaces(rowData.var),
    },
    {
      title: 'C/P Ratio',
      field: 'cpRatio',
      headerStyle: {
        textAlign: 'center',
      },
      cellStyle: {
        textAlign: 'center',
      },
      render: (rowData) =>
        rowData.cpRatio && rowData.cpRatio !== Infinity
          ? addCommasToNumbers(toTwoDecimalPlaces(rowData.cpRatio))
          : '-',
    },
    {
      title: 'Fund Ratio',
      field: 'fundRatio',
      headerStyle: {
        textAlign: 'center',
      },
      cellStyle: {
        textAlign: 'center',
      },
      render: (rowData) =>
        rowData.fundRatio && rowData.fundRatio !== Infinity
          ? addCommasToNumbers(toTwoDecimalPlaces(rowData.fundRatio))
          : '-',
    },
  ];

export function buildPositionVarData(
  counterpartyIds: string[],
  counterpartyNames: string[],
  data: any,
): CounterpartyVarExposureData[] {
  if (!data) return [];
  try {
    return Object.keys(data.risk).map((key) => {
      const dataArray = data.risk[key];
      return {
        counterparty: counterpartyNames[counterpartyIds.indexOf(key)],
        equity: dataArray[0] / data.nav,
        commodity: dataArray[1] / data.nav,
        forex: dataArray[2] / data.nav,
        fixedIncome: dataArray[3] / data.nav,
        cash: dataArray[4] / data.nav,
        alternative: dataArray[5] / data.nav,
        total: dataArray[6] / data.nav,
      };
    });
  } catch (err) {
    console.error('Error rendering var exosure data', err);
    return [];
  }
}

export function buildDerivativeVarData(
  counterpartyIds: string[],
  counterpartyNames: string[],
  data: any,
): CounterpartyVarExposureData[] {
  if (!data) return [];
  try {
    const returnedArray = Object.keys(data.counterparty_var_data).map((key) => {
      const dataArray = data.counterparty_var_data[key][1];
      return {
        counterparty: counterpartyNames[counterpartyIds.indexOf(key)],
        equity: dataArray[0],
        commodity: dataArray[1],
        forex: dataArray[2],
        fixedIncome: dataArray[3],
        cash: dataArray[4],
        alternative: dataArray[5],
        total: dataArray[6],
      };
    });
    const aggData = data.aggregate[1];
    returnedArray.push({
      counterparty: 'Portfolio',
      equity: aggData[0],
      commodity: aggData[1],
      forex: aggData[2],
      fixedIncome: aggData[3],
      cash: aggData[4],
      alternative: aggData[5],
      total: aggData[6],
    });
    return returnedArray;
  } catch (err) {
    console.error(err);
    return [];
  }
}

export function buildLiquidationDistanceData(
  counterpartyIds: string[],
  counterpartyNames: string[],
  data: any,
): CounterpartyLiquidationData[] {
  if (!data) return [];
  try {
    return Object.keys(data).map((el: any) => {
      return {
        counterparty: counterpartyNames[counterpartyIds.indexOf(el)],
        assetValue: data[el][1],
        liquidationDistance: data[el][2],
      };
    });
  } catch (err) {
    console.error('could not build liquidation distance: ', err);
    return [];
  }
}

export function buildDerivativeExposureAvailableLiquidityRatio(
  data: any,
  counterpartyNames: string[],
  counterpartyIds: string[],
) {
  if (!data) return [];
  try {
    const returnArr = Object.keys(data.counterparty_var_data).map(
      (counterpartyKey: string, index: number) => {
        const liquidity = data.counterparty_var_data[counterpartyKey][0];
        const varValue = data.counterparty_var_data[counterpartyKey][1][6];
        return {
          counterparty:
            counterpartyNames[counterpartyIds.indexOf(counterpartyKey)],
          liquidity,
          var: varValue,
          cpRatio: liquidity / varValue,
          fundRatio: data.aggregate[0] / varValue,
        };
      },
    );
    returnArr.push({
      counterparty: 'Portfolio',
      liquidity: data.aggregate[0],
      var: data.aggregate[1][6],
      cpRatio: data.aggregate[0] / data.aggregate[1][6],
      fundRatio: data.aggregate[0] / data.aggregate[1][6],
    });

    return returnArr;
  } catch (err) {
    console.error(err);
    return [];
  }
}
