import makeStyles from '@mui/styles/makeStyles';
import React, { useEffect } from 'react';
import GridItem from '../../../../../layout/GridComponents/GridItem';
import {
  CartesianGrid,
  Label,
  Legend,
  Line,
  LineChart,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { greys, mainColors } from '../../../../../../styling/theme';
import { percentageToNdecialPlaces } from '../../../../../../utilities/numberFormatters';
import usePngFromRecharts from '../../../../../../hooks/usePngFromRecharts';
import { useDispatch } from 'react-redux';
import { addComponentToPdfExport } from '../../../../../../redux/pdfExport/actions';
import FileSaver from 'file-saver';
import { PdfComponentType } from '../../../../../../types/redux/pdfExports/pdfExportsStore';
import ExportButton from '../../../../../feedback/ExportButton';
import ChartDownloadButton from '../../../../../buttons/ChartDownloadButton';

interface SubFundAnalysisGraphProps {
  data: any;
}

interface SubFundAnalysisGraphData {
  date: string;
  runningTotalPL: number;
  runningCurrencyPL: number;
  runningAssetPL: number;
}

const useStyles = makeStyles(() => ({
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '1rem 2.5rem 1rem 1.5rem',
  },
  cardTitle: {
    color: mainColors.mainBlue,
    fontSize: '2.2rem',
    fontWeight: 400,
    margin: '1rem 1.5rem',
  },
  customTooltipContainer: {
    backgroundColor: 'white',
    padding: '1rem 1.5rem',
    borderRadius: '0.5rem',
    border: `1px solid ${mainColors.mainBlue}`,
    width: '25rem',
    color: mainColors.mainBlue,
  },
  tooltipDate: {
    fontSize: '2rem',
    fontWeight: 500,
  },
  tooltipItem: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  tooltipTotal: {
    fontSize: '1.6rem',
    fontWeight: 600,
    color: mainColors.mainBlue,
    display: 'flex',
  },
  tooltipCurrency: {
    fontSize: '1.6rem',
    fontWeight: 600,
    color: mainColors.Pass,
  },
  tooltipAsset: {
    fontSize: '1.6rem',
    fontWeight: 600,
    color: mainColors.Fail,
  },
  exportButtons: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const CustomTooltip = ({ active, payload, label }: any) => {
  const classes = useStyles();

  if (active && payload && payload.length) {
    type valueType = {
      name: string;
      value: number;
    };
    const values: valueType[] = [
      {
        name: 'Total P/L',
        value: payload.find((item: any) => item.name === 'runningTotalPL')
          .value,
      },
      {
        name: 'Currency P/L',
        value: payload.find((item: any) => item.name === 'runningCurrencyPL')
          .value,
      },
      {
        name: 'Asset P/L',
        value: payload.find((item: any) => item.name === 'runningAssetPL')
          .value,
      },
    ];
    const sortedValues = values.sort(
      (a: valueType, b: valueType) => b.value - a.value,
    );

    const getClass = (name: string) => {
      switch (name) {
        case 'Total P/L':
          return classes.tooltipTotal;
        case 'Currency P/L':
          return classes.tooltipCurrency;
        case 'Asset P/L':
          return classes.tooltipAsset;
        default:
          return '';
      }
    };

    return (
      <div className={classes.customTooltipContainer}>
        <div>
          <div className={classes.tooltipDate}>{label}</div>
          <hr />
          {sortedValues.map((item: valueType) => (
            <div key={item.name} className={classes.tooltipItem}>
              <div className={getClass(item.name)} key={item.name + '_name'}>
                {item.name}:
              </div>
              <div className={getClass(item.name)} key={item.name + '_value'}>
                {percentageToNdecialPlaces(item.value / 100, 2)}
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  } else {
    return <></>;
  }
};

const buildGraphData = (inputData: any): SubFundAnalysisGraphData[] => {
  if (!inputData.data || !inputData.data.length) {
    return [];
  } else {
    const returnData: SubFundAnalysisGraphData[] = [];

    inputData.data[0].total_asset_currency.map((date: any) => {
      returnData.push({
        date: date.Date,
        runningTotalPL: date['running_pl'],
        runningCurrencyPL: date['running_currency_pl'],
        runningAssetPL: date['running_asset_pl'],
      });
    });

    return returnData.sort(
      (a: SubFundAnalysisGraphData, b: SubFundAnalysisGraphData) =>
        a.date.localeCompare(b.date, undefined, {
          numeric: true,
          sensitivity: 'base',
        }),
    );
  }
};

const SubFundAnalysisGraph: React.FC<SubFundAnalysisGraphProps> = ({
  data,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const builtGraphData = buildGraphData(data);

  const id = 'Sub-Fund-Historical-Performance-Portfolio-Analysis';
  const title = 'Sub-Fund Historical Performance - Portfolio Analysis';

  const { ref, handleDownload } = usePngFromRecharts();

  useEffect(() => {
    dispatch(
      addComponentToPdfExport({
        identifier: id,
        handler: handleDownload,
        type: PdfComponentType.LINE_CHART,
        title: title,
      }),
    );
  }, [ref]);

  const saveImage = async () => {
    const imageData = await handleDownload();
    if (imageData) {
      FileSaver.saveAs(imageData.data, `${id}.png`);
    }
  };

  return (
    <GridItem xs={12} card cardStyle={{ height: '100%' }}>
      <div className={classes.toolbar}>
        <h2 className={classes.cardTitle}>{title}</h2>
        <div className={classes.exportButtons}>
          <ExportButton
            fileName={`${id}.csv`}
            exportData={builtGraphData}
            fields={Object.keys(builtGraphData[0])}
            fieldsMap={[
              { key: 'date', label: 'Date' },
              { key: 'runningTotalPL', label: 'Total P/L' },
              { key: 'runningCurrencyPL', label: 'Currency P/L' },
              { key: 'runningAssetPL', label: 'Asset P/L' },
            ]}
          />
          <ChartDownloadButton handler={saveImage} />
        </div>
      </div>
      <ResponsiveContainer width="100%" height={480}>
        <LineChart
          data={builtGraphData}
          margin={{ top: 20, right: 50, bottom: 20, left: 30 }}
          ref={ref}
        >
          <XAxis
            dataKey="date"
            angle={-45}
            textAnchor="end"
            height={60}
            tick={{
              stroke: mainColors.mainBlue,
              strokeWidth: 1,
              fontSize: '1.3rem',
            }}
            tickLine={true}
          />
          <YAxis
            width={70}
            tickFormatter={(tickItem) => {
              return percentageToNdecialPlaces(tickItem / 100, 1);
            }}
            tick={{
              stroke: mainColors.mainBlue,
              strokeWidth: 1,
              fontSize: '1.3rem',
            }}
            tickLine={true}
          >
            <Label
              style={{
                textAnchor: 'middle',
                fontSize: '200%',
                fontWeight: 400,
                color: mainColors.mainBlue,
              }}
              angle={270}
              value={'P/L'}
              position={'insideLeft'}
            />
          </YAxis>
          <Tooltip content={<CustomTooltip />} />
          <CartesianGrid stroke={greys.grey300} strokeDasharray="5 5" />
          <ReferenceLine y={0} stroke={greys.grey600} />
          <Line
            type="linear"
            dataKey="runningTotalPL"
            stroke={mainColors.mainBlue}
            strokeWidth={2}
          />
          <Line
            type="linear"
            dataKey="runningCurrencyPL"
            stroke={mainColors.Pass}
            strokeWidth={2}
          />
          <Line
            type="linear"
            dataKey="runningAssetPL"
            stroke={mainColors.Fail}
            strokeWidth={2}
          />
          <Legend
            verticalAlign="top"
            height={30}
            wrapperStyle={{ fontSize: '1.6rem' }}
            formatter={(item) => {
              switch (item) {
                case 'runningTotalPL':
                  return 'Total P/L';
                case 'runningCurrencyPL':
                  return 'Currency P/L';
                case 'runningAssetPL':
                  return 'Asset P/L';
                default:
                  return item;
              }
            }}
          />
        </LineChart>
      </ResponsiveContainer>
    </GridItem>
  );
};

export default SubFundAnalysisGraph;
