import React, { FC, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import {
  FormControl,
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
  Tooltip,
  TooltipProps,
} from '@mui/material';
import { RaptorTheme } from '../../../styling/theme';

const useButtonSelectStyles = makeStyles((theme: RaptorTheme) => ({
  formControlRoot: {
    visibility: 'hidden',
    width: 1,
    height: 1,
    // display: "none"
  },
  tooltip: {
    backgroundColor: theme.palette.primary.main,
    fontSize: '1.2rem',
  },
}));

interface ButtonSelectProps {
  ButtonIcon: React.FunctionComponent;
  ariaLabel: string;
  selectValue: string;
  setSelectValue: (choice: string | null) => void;
  labels: string[];
  values: string[];
  tooltip?: {
    tooltipTitle: string;
    tooltipLocation: TooltipProps['placement'];
  };
  iconButtonStyles?: string;
}

const ButtonSelect: FC<ButtonSelectProps> = (props) => {
  const [open, setOpen] = useState(false);
  const { ButtonIcon } = props;

  const classes = useButtonSelectStyles();
  const handleChangeValue = (event: SelectChangeEvent<string>) => {
    props.setSelectValue(event.target.value as string);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const renderButton = props.tooltip ? (
    <Tooltip
      classes={classes}
      title={props.selectValue}
      placement={props.tooltip.tooltipLocation}
    >
      <IconButton
        classes={{
          root: props.iconButtonStyles,
        }}
        onClick={handleOpen}
        aria-label={props.ariaLabel}
      >
        <ButtonIcon />
      </IconButton>
    </Tooltip>
  ) : (
    <IconButton onClick={handleOpen} aria-label={props.ariaLabel}>
      <ButtonIcon />
    </IconButton>
  );

  return (
    <>
      {renderButton}
      <FormControl className={classes.formControlRoot}>
        <Select
          open={open}
          value={props.selectValue}
          onChange={handleChangeValue}
          onOpen={handleOpen}
          onClose={handleClose}
        >
          {props.labels.map((label, index) => (
            <MenuItem key={label + props.ariaLabel} value={props.values[index]}>
              {label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
};

export default ButtonSelect;
