import React, { useEffect } from 'react';
import GeneralComponentErrorShield from '../../../general/GeneralComponentErrorShield';
import { GeneralOverviewPageRenderedComponentProps } from '../../../../types/components/componentTypes';
import useFetchData from '../../../../hooks/useFetchData';
import useTitleBarSelect from '../../../../hooks/useTitleBarSelect';
import { DataObject } from '../../../../types/redux/data/dataTypes';
import {
  ShareClassHedgeTableData,
  generateShareClassHedgeData,
  generateShareClassTableColumns,
} from '../shareClassHedge/ShareClassHedgeData.data';
import GridItem from '../../../layout/GridComponents/GridItem';
import CustomTable from '../../../tables/CustomTable';
import { Select } from '../../../../types/redux/pages/PagesStore';
import { useDispatch } from 'react-redux';
import {
  addAdditionalTitleBarComponents,
  removeTitleBarComponent,
} from '../../../../redux/pages/actions';
import {
  APPEND_NEW_SELECT_TO_TITLEBAR,
  REMOVE_SELECT_FROM_TITLEBAR,
} from '../../../../types/redux/pages/pageTypes';

export function getFundName(data: any): any {
  if (!data) {
    return [];
  }
  if (!('data' in data)) return [];
  if (!data.data.length) return [];
  return data.data[0].external_fund_name;
}

export function getNavDate(data: any): any {
  if (!data) {
    return [];
  }
  if (!('data' in data)) return [];
  if (!data.data.length) return [];
  return data.data[0].selected_position_date;
}

const ShareClassTable = ({ data }: { data: DataObject }) => {
  const generatedData = generateShareClassHedgeData(data.data[0]);
  const fundName = getFundName(data);
  const navDate = getNavDate(data);
  return (
    <GridItem xs={12} card>
      <CustomTable<ShareClassHedgeTableData>
        title={fundName + ' - NAV Date: ' + navDate}
        data={generatedData}
        columns={generateShareClassTableColumns()}
        options={{ paging: false, exportButton: true }}
        showToolbar
      />
    </GridItem>
  );
};

const QuaysideShareClassHedge: React.FC<
  GeneralOverviewPageRenderedComponentProps
> = (props) => {
  const dispatch = useDispatch();

  // Add the select components to the title bar
  useEffect(() => {
    const selectsToAdd: Select[] = [
      {
        titleBarTitle: '',
        titleBarKey: 'fxFunds',
        values: [
          {
            label: '(nanjia_001fx) - Nanjia XSE Fund (SCH)',
            value: 'nanjia_001fx',
          },
          {
            label: '(nanjia_002fx) - Cyrus Fund (SCH)',
            value: 'nanjia_002fx',
          },
          {
            label: '(nanjia_003fx) - Civetta Nanjia Fund (SCH)',
            value: 'nanjia_003fx',
          },
        ],
        displayOnOverviewPage: true,
        aValueIsAlwaysSelected: true,
        currentValue: 'nanjia_001fx',
      },
      {
        titleBarTitle: 'Nav Stress: ',
        titleBarKey: 'navStress',
        values: [
          {
            label: '10%',
            value: '10',
          },
          {
            label: '5%',
            value: '5',
          },
          {
            label: '2%',
            value: '2',
          },

          {
            label: '1%',
            value: '1',
          },
          {
            label: 'None applied',
            value: '0',
          },
          {
            label: '-1%',
            value: '-1',
          },
          {
            label: '-2%',
            value: '-2',
          },
          {
            label: '-5%',
            value: '-5',
          },
          {
            label: '-10%',
            value: '-10',
          },
        ],
        displayOnOverviewPage: true,
        aValueIsAlwaysSelected: true,
        currentValue: '0',
      },
      {
        titleBarTitle: 'FX Stress: ',
        titleBarKey: 'fxStress',
        values: [
          {
            label: '10%',
            value: '10',
          },
          {
            label: '5%',
            value: '5',
          },
          {
            label: '2%',
            value: '2',
          },

          {
            label: '1%',
            value: '1',
          },
          {
            label: 'None applied',
            value: '0',
          },
          {
            label: '-1%',
            value: '-1',
          },
          {
            label: '-2%',
            value: '-2',
          },
          {
            label: '-5%',
            value: '-5',
          },
          {
            label: '-10%',
            value: '-10',
          },
        ],
        displayOnOverviewPage: true,
        aValueIsAlwaysSelected: true,
        currentValue: '0',
      },
    ];

    dispatch(
      addAdditionalTitleBarComponents(
        selectsToAdd,
        APPEND_NEW_SELECT_TO_TITLEBAR,
      ),
    );

    return () => {
      dispatch(removeTitleBarComponent('fxFunds', REMOVE_SELECT_FROM_TITLEBAR));
      dispatch(
        removeTitleBarComponent('navStress', REMOVE_SELECT_FROM_TITLEBAR),
      );
      dispatch(
        removeTitleBarComponent('fxStress', REMOVE_SELECT_FROM_TITLEBAR),
      );
    };
  }, []);

  const fxFundId = useTitleBarSelect('fxFunds');
  const navStress = useTitleBarSelect('navStress');
  const fxStress = useTitleBarSelect('fxStress');

  const shareClassHedgeData = useFetchData({
    url: `/msc_share_class_fx_hedge_monitor_rt_agg/${fxFundId}/${navStress}/${fxStress}`,
    keyName: `share_class_hedge_data_${fxFundId}_${navStress}_${fxStress}`,
    makeFetch:
      fxFundId !== undefined &&
      navStress !== undefined &&
      fxStress !== undefined
        ? true
        : false,
  });

  return (
    <GeneralComponentErrorShield
      dataObjects={[shareClassHedgeData]}
      customLoadingMessages={[
        'Loading Share Class Hedge Data',
        'Just a few more seconds...',
      ]}
      customErrorMessage="No Data Available."
    >
      <ShareClassTable data={shareClassHedgeData} />
    </GeneralComponentErrorShield>
  );
};

export default QuaysideShareClassHedge;
