import React, { FC } from 'react';
import { CustomColumn } from '../../../../../types/components/tables/tableTypes';
import { DataObject } from '../../../../../types/redux/data/dataTypes';

import GridItem from '../../../../layout/GridComponents/GridItem';
import CustomTable from '../../../../tables/CustomTable';
import SecondLevelTableWrapper from './SecondLevelTableWrapper.component';
import Actions from './Actions.component';
import { mainColors } from '../../../../../styling/theme';

interface HitsTableProps {
  dataForRender: DataObject;
  excludedIsinsData: DataObject;
  fundId: string;
  positionDate: string | null;
}

export interface SourcesData {
  source: string;
  score: number;
  names: string;
}

export interface HitsData {
  gm_id: string;
  gm_name: string;
  gm_name_long: string;
  isin: string;
  raw_name: string;
  sources: SourcesData[];
}

const buildData = (dataForRender: DataObject): HitsData[] => {
  if (!dataForRender || !dataForRender.data || !dataForRender.data[0]) {
    return [];
  } else {
    return dataForRender.data[0];
  }
};

const columns = (
  fundId: string,
  positionDate: string,
  setExcludedIsins: (excludedIsins: string[]) => void,
): CustomColumn<HitsData>[] => [
  {
    title: 'GM Name',
    field: 'gm_name',
    width: '10%',

    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: {
      textAlign: 'center',
    },
  },
  {
    title: 'GM Name Long',
    field: 'gm_name_long',
    width: '10%',
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: {
      textAlign: 'center',
    },
  },
  {
    title: 'ISIN',
    field: 'isin',
    width: '10%',
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: {
      textAlign: 'center',
    },
  },
  {
    title: 'Raw Name',
    field: 'raw_name',
    width: '40%',
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: {
      textAlign: 'center',
    },
  },
  {
    title: 'Actions',
    field: 'actions',
    width: '30%',
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: {
      textAlign: 'center',
    },
    render: (rowData: HitsData) => {
      return (
        <Actions
          fundId={fundId}
          positionDate={positionDate}
          isin={rowData.isin}
          setExcludedIsins={setExcludedIsins}
        />
      );
    },
  },
];

const HitsTable: FC<HitsTableProps> = ({
  dataForRender,
  fundId,
  positionDate,
  excludedIsinsData,
}) => {
  const tableData = buildData(dataForRender);

  // Create a list for storing excluded ISINs
  const [excludedIsins, setExcludedIsins] = React.useState<string[]>(
    excludedIsinsData.data[0],
  );

  return (
    <GridItem card xs={12} cardStyle={{ height: '100%' }}>
      <CustomTable<HitsData>
        id="sanctions_scanner_hits"
        title={'Sanctions Scanner Hits'}
        showToolbar={true}
        columns={columns(fundId, positionDate || '', setExcludedIsins)}
        data={tableData}
        options={{
          paging: false,
          search: false,
          exportButton: true,
          rowStyle: (rowData: HitsData) => {
            if (excludedIsins.includes(rowData.isin)) {
              return { backgroundColor: mainColors.pastelGreen };
            }
            return {};
          },
        }}
        detailPanel={SecondLevelTableWrapper()}
      />
    </GridItem>
  );
};

export default HitsTable;
