import React, { CSSProperties } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { mainColors, RaptorTheme } from '../../styling/theme';
import { Input, Tooltip, Typography } from '@mui/material';

interface GeneralInputProps {
  setInput: (inputVal: string | number) => void;
  inputValue: string | number;
  inputKey: string;
  inputTitle: string;
  width?: CSSProperties['width'];
  defaultValue?: string | number;
  usePlaceholder?: boolean;
  allowedValues?: (string | number)[];
}

type GeneralInputStyleProps = {
  width: string | number;
};

const useStyles = makeStyles<RaptorTheme>((theme) => ({
  divContainer: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: 8,
    padding: '.4rem 1rem',
    backgroundColor: theme.palette.grey[100],
  },
  inputTitle: {
    color: theme.palette.grey[600],
    marginRight: '1rem',
    whiteSpace: 'nowrap',
  },
  inputRoot: {
    width: '6rem',
  },
  inputStyling: {
    width: '6rem',
    color: mainColors.mainBlue,
  },
  inputStylingInvalid: {
    width: '6rem',
    color: mainColors.Fail,
  },
  tooltipError: {
    backgroundColor: mainColors.Fail,
  },
}));

const GeneralInput: React.FC<GeneralInputProps> = (props) => {
  const classes = useStyles();

  const [shownValue, setShownValue] = React.useState<string | number>(
    props.inputValue || '',
  );
  const [lastValidValue, setLastValidValue] = React.useState<string | number>(
    props.inputValue || '',
  );

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setShownValue(e.target.value);
    if (props.allowedValues) {
      if (props.allowedValues.includes(e.target.value)) {
        setLastValidValue(e.target.value);
        props.setInput(e.target.value);
      }
    } else {
      setLastValidValue(e.target.value);
      props.setInput(e.target.value);
    }
  };

  return (
    <Tooltip
      title={
        (props.allowedValues && props.allowedValues.includes(shownValue)) ||
        !props.allowedValues
          ? ''
          : 'Invalid Selection'
      }
      classes={{ tooltip: classes.tooltipError }}
    >
      <div className={classes.divContainer}>
        {!props.usePlaceholder && (
          <Typography variant="subtitle1" className={classes.inputTitle}>
            {props.inputTitle}
          </Typography>
        )}
        <Input
          classes={{
            input:
              (props.allowedValues &&
                props.allowedValues.includes(shownValue)) ||
              !props.allowedValues
                ? classes.inputStyling
                : classes.inputStylingInvalid,
            root: classes.inputRoot,
          }}
          placeholder={props.usePlaceholder ? props.inputTitle : undefined}
          value={shownValue}
          onChange={handleChange}
          disableUnderline
          onBlur={() => {
            setShownValue(lastValidValue);
          }}
        />
      </div>
    </Tooltip>
  );
};

export default GeneralInput;
