import makeStyles from '@mui/styles/makeStyles';
import { FC, useEffect, useState } from 'react';
import client, { BASE_URL } from '../../../../../../utilities/requestClient';
import PriipsKidsEditorGridItem from '../../layout/PriipsKidsEditorGridItem.component';
import { greys, mainColors } from '../../../../../../styling/theme';
import { PriipsKidsData } from '../PriipsKidsEditor.component';
import RefreshIcon from '@mui/icons-material/Refresh';
import SaveIcon from '@mui/icons-material/Save';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import Raptor2Loading from '../../../../../feedback/Raptor2Loading';
import SaveConfirmationDialogBox from './SaveConfirmationDialogBox.component';
import useSnackbar from '../../../../../../hooks/useSnackbar';
import Button from '@mui/material/Button';
import { Tooltip } from '@mui/material';

interface EditorPdfViewerProps {
  priipsKidsData: PriipsKidsData[];
  isSaveEnabled: boolean;
  getTemplateChangesMade: () => string[];
  getCanUserSave: () => string[];
  resetContentAfterSave: () => void;
  figuresWereUpdated: boolean;
}

interface priipsKidsDataPdfVersion {
  format_options: string;
  manco_id: string;
  tag: string;
  content: string;
  version: string;
  edited_by_name: string;
  edit_timestamp: string;
  edited_by: string;
  fund_name: string;
  comment_id: string | null;
  field_id: string;
  published_by: string;
  kiid_id: string;
  kiid_index: number;
  share_class_name: string;
  share_class: string;
  fund_id_string: string;
  has_unresolved_comment: boolean;
  document_language: string;
  published_by_name: string;
  fund_id: string;
  is_published: boolean;
}

function convertToPdfVersion(data: PriipsKidsData): priipsKidsDataPdfVersion {
  // Check if the field is a template field
  let templateContent = null;
  if (data.templateId && data.templateType) {
    templateContent = {
      id: data.templateId,
      type: data.templateType,
      text: data.content,
    };
    templateContent = '{' + JSON.stringify(templateContent) + '}';
  }
  return {
    format_options: data.formatOptions,
    manco_id: data.mancoId,
    tag: data.tag,
    content: templateContent ? templateContent : data.content,
    version: data.version,
    edited_by_name: data.editedByName,
    edit_timestamp: data.editTimestamp,
    edited_by: data.editedBy,
    fund_name: data.fundName,
    comment_id: data.commentId,
    field_id: data.fieldId,
    published_by: data.publishedBy,
    kiid_id: data.kiidId,
    kiid_index: data.kiidIndex,
    share_class_name: data.shareClassName,
    share_class: data.shareClass,
    fund_id_string: data.fundIdString,
    has_unresolved_comment: data.hasUnresolvedComment,
    document_language: data.documentLanguage,
    published_by_name: data.publishedByName,
    fund_id: data.fundId,
    is_published: data.isPublished,
  };
}

const useStyles = makeStyles(() => ({
  parentContainer: {
    minHeight: '20rem',
    display: 'flex',
    flexDirection: 'column',
    margin: '2rem',
    gap: '2rem',
    width: '100%',
  },
  buttonsContainer: {
    display: 'flex',
    gap: '1rem',
    flexDirection: 'column',
  },
  button: {
    height: '3rem',
    // marginTop: '0.2rem',
    marginRight: '1rem',
    width: '10rem',
  },
  viewerContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: '1rem',
  },
  iframeContainer: {
    border: '2px solid',
    borderColor: mainColors.mainBlue,
    width: '100%',
    height: '80vh',
    display: 'flex',
    justifyContent: 'space-around',
    backgroundColor: 'white',
  },
  arrow: {
    '&:before': {
      border: '1px solid #8e8e8e',
    },
    color: greys.grey600,
  },
  tooltip: {
    backgroundColor: greys.grey600,
    border: '1px solid #8e8e8e',
    color: 'white',
  },
}));
const EditorPdfViewer: FC<EditorPdfViewerProps> = ({
  priipsKidsData,
  isSaveEnabled,
  getTemplateChangesMade,
  getCanUserSave,
  resetContentAfterSave,
  figuresWereUpdated,
}) => {
  const classes = useStyles();
  const requestClient = client();

  const [pdfDocLoading, setPdfDocLoading] = useState(false);
  const [saveDialogIsShowing, setSaveDialogIsShowing] = useState(false);

  const refreshPdf = () => {
    // Check if the required fields have been filled in.
    if (!priipsKidsData || !priipsKidsData.length) {
      return;
    }
    // Convert the data to the correct format needed for the pdf generator/ the database
    const content: priipsKidsDataPdfVersion[] = [];
    priipsKidsData.forEach((element: any) => {
      content.push(convertToPdfVersion(element));
    });

    // Create an object of formData
    const formData = new FormData();
    // Convert the content to json
    const priipsKidsJson = JSON.stringify(content);
    // Get required data from the first element of the content array
    const fundId = content[0].fund_id_string;
    const fundName = content[0].fund_name;
    const isin = content[0].share_class;
    const documentLanguage = content[0].document_language;
    // Update the formData object.
    formData.append('contents', priipsKidsJson);
    formData.append('fund_id', fundId);
    formData.append('fund_name', fundName);
    formData.append('isin', isin);
    formData.append('document_language', documentLanguage);
    formData.append(
      'params',
      JSON.stringify([
        'contents',
        'fund_id',
        'fund_name',
        'isin',
        'document_language',
      ]),
    );

    // Display the loading symbol
    setPdfDocLoading(true);

    // Create the request
    requestClient
      .post('risksystem_pdf_generator/generate_new_kiid', formData, {
        headers: {
          accept: 'application/pdf',
        },
        responseType: 'blob',
      })
      .then((response: { data: BlobPart }) => {
        const blob = new Blob([response.data], { type: 'application/pdf' });
        const file = window.URL.createObjectURL(blob);
        const iframe = document.querySelector('iframe');
        if (iframe?.src) {
          iframe.src = file;
          iframe.title = 'fact_sheet.pdf';
        }
        setPdfDocLoading(false);
      });
  };

  useEffect(() => {
    refreshPdf();
  }, []);

  function downloadFile() {
    const mapForm = document.createElement('form');
    mapForm.target = '_self';
    mapForm.method = 'POST';

    // Create an input for the form
    const contentsInput = document.createElement('input');
    if (!priipsKidsData || !priipsKidsData.length) {
      return;
    }
    const content: priipsKidsDataPdfVersion[] = [];
    priipsKidsData.forEach((element: any) => {
      content.push(convertToPdfVersion(element));
    });

    // Get required data from the first element of the content array
    const fundId = content[0].fund_id_string;
    const fundName = content[0].fund_name;
    const isin = content[0].share_class;
    const documentLanguage = content[0].document_language;
    const version = content[0].version;

    mapForm.action =
      BASE_URL +
      `risksystem_pdf_generator/generate_new_kiid/${fundName}_${isin}_${version}.pdf`;

    // add the contents
    const factSheetsJson = JSON.stringify(content);
    contentsInput.type = 'text';
    contentsInput.name = 'contents';
    contentsInput.value = factSheetsJson;
    // Add to the form
    mapForm.appendChild(contentsInput);
    // add the fund_id
    const fundIdInput = document.createElement('input');
    fundIdInput.type = 'text';
    fundIdInput.name = 'fund_id';
    fundIdInput.value = fundId;
    // Add to the form
    mapForm.appendChild(fundIdInput);
    // add the fund_name
    const fundNameInput = document.createElement('input');
    fundNameInput.type = 'text';
    fundNameInput.name = 'fund_name';
    fundNameInput.value = fundName;
    // Add to the form
    mapForm.appendChild(fundNameInput);
    // add the isin
    const isinInput = document.createElement('input');
    isinInput.type = 'text';
    isinInput.name = 'isin';
    isinInput.value = isin;
    // Add to the form
    mapForm.appendChild(isinInput);
    // add the document language
    const documentLanguageInput = document.createElement('input');
    documentLanguageInput.type = 'text';
    documentLanguageInput.name = 'document_language';
    documentLanguageInput.value = documentLanguage;
    // Add to the form
    mapForm.appendChild(documentLanguageInput);
    // add the params
    const paramsInput = document.createElement('input');
    paramsInput.type = 'text';
    paramsInput.name = 'params';
    paramsInput.value = JSON.stringify([
      'contents',
      'fund_id',
      'fund_name',
      'isin',
      'document_language',
    ]);
    // Add to the form
    mapForm.appendChild(paramsInput);

    // Add the form to the dom
    document.body.appendChild(mapForm);
    // submit the form
    mapForm.submit();
    // remove the form
    document.body.removeChild(mapForm);
  }

  // USe the snackbar to show the progress of teh data being saved.
  const { showAsyncSnackbar } = useSnackbar();

  // Create a function for checking what documents will be affected by template changes
  async function checkTemplateChanges() {
    // Create an object of formData
    const formData = new FormData();
    // Check if any template fields have been updated
    const fieldIds = getTemplateChangesMade();
    // if template changes are included in then append them.
    if (fieldIds.length) {
      const changes: any[] = [];
      fieldIds.forEach((id) => {
        const element = priipsKidsData.find(
          (element) => element.fieldId === id,
        );
        changes.push({
          template_id: element?.templateId,
          text: element?.content,
        });
      });
      formData.append('template_updates', JSON.stringify(changes));
    } else {
      return [];
    }

    // Monitor if an error occured to prevent the content from being reset.
    let errorOccured = false;
    // Create a variable for storing the affected documents
    let affectedDocuments: string[] = [];
    // Save the data to the DB
    await requestClient
      .post('get_documents_affected_by_template_changes', formData, {
        headers: {
          'content-type': 'multipart/form-data',
        },
      })
      .catch((error) => {
        alert('There was an error while retrieving affected documents.');
        errorOccured = true;
        throw error;
      })
      .then((response) => {
        affectedDocuments = response.data;
      });
    return affectedDocuments;
  }

  async function handleSaveChanges(
    applyToAllDocuments: boolean,
    applyToAllShareClasses?: boolean,
  ) {
    // Check if the required fields have been filled in.
    if (!priipsKidsData.length) {
      return;
    }

    // Convert the data to the correct format needed for the pdf generator/ the database
    const content: priipsKidsDataPdfVersion[] = [];
    priipsKidsData.forEach((element: any) => {
      content.push(convertToPdfVersion(element));
    });

    // Create an object of formData
    const formData = new FormData();

    // Update the formData objet.
    formData.append('kiid_data', JSON.stringify(content));
    // Check if any template fields have been updated
    const fieldIds = getTemplateChangesMade();
    // if template changes are included in then append them.
    if (fieldIds.length && applyToAllDocuments) {
      const changes: any[] = [];
      fieldIds.forEach((id) => {
        const element = priipsKidsData.find(
          (element) => element.fieldId === id,
        );
        changes.push({
          template_id: element?.templateId,
          text: element?.content,
        });
      });
      formData.append('template_updates', JSON.stringify(changes));
    }
    // Monitor if an error occured to prevent the content from being reset.
    let errorOccured = false;
    // Save the data to the DB
    await showAsyncSnackbar({
      startMessage: 'Saving Data.',
      successMessage: 'Data Saved.',
      failureMessage: 'An Error occured while saving.',
      promiseToResolve: requestClient
        .post('kiid_generator_write_kiid_data', formData, {
          headers: {
            'content-type': 'multipart/form-data',
          },
        })
        .catch((error) => {
          alert(
            'There was an error while saving changes. A report has been sent to Risksystem.',
          );
          errorOccured = true;
          throw error;
        })
        .finally(() => {
          // Reset the content after saving if no error occured.
          if (!errorOccured) resetContentAfterSave();
        }),
    });

    // Check if the figures were updated
    if (applyToAllShareClasses) {
      await handleUpdateFigures(applyToAllShareClasses);
    }
    // Refresh the PDF
    refreshPdf();
  }

  async function handleUpdateFigures(applyToAllShareClasses: boolean) {
    // Create an object of formData
    const formData = new FormData();

    // Update the formData objet.
    formData.append('fund_id', priipsKidsData[0].fundIdString);
    formData.append('fund_uuid', priipsKidsData[0].fundId);
    formData.append('excluded_share_class', priipsKidsData[0].shareClass);
    formData.append(
      'only_apply_to_sepcified_share_class',
      applyToAllShareClasses ? 'no' : 'yes',
    );

    // Monitor if an error occured to prevent the content from being reset.
    let errorOccured = false;
    // Save the data to the DB
    await showAsyncSnackbar({
      startMessage: 'Updating Figures.',
      successMessage: 'Figures Updated Successfully.',
      failureMessage: 'An Error occured while updating figures.',
      promiseToResolve: requestClient
        .post('kiid_generator_refresh_figures_data', formData, {
          headers: {
            'content-type': 'multipart/form-data',
          },
        })
        .catch((error) => {
          alert(
            'There was an error while updating figures. A report has been sent to Risksystem.',
          );
          errorOccured = true;
          throw error;
        })
        .finally(() => {
          // Reset the content after saving if no error occured.
          if (!errorOccured) resetContentAfterSave();
        }),
    });
    // Refresh the PDF
    refreshPdf();
  }

  return (
    <PriipsKidsEditorGridItem xs={12}>
      <div className={classes.viewerContainer}>
        <div className={classes.iframeContainer}>
          <iframe
            src=""
            width={pdfDocLoading ? '0%' : '100%'}
            height="100%"
            title={'kid.pdf'}
          ></iframe>
          {pdfDocLoading ? (
            <Raptor2Loading centerWrap messages={['Generating Document...']} />
          ) : null}
        </div>
        <div className={classes.buttonsContainer}>
          <Tooltip
            classes={{ arrow: classes.arrow, tooltip: classes.tooltip }}
            title="Reload PDF"
            placement="top"
            arrow
            enterNextDelay={2000}
            enterDelay={2000}
          >
            <Button
              variant="contained"
              sx={{
                fontSize: '1rem',
                width: '13rem',
                height: '3rem',
                color: 'white',
                backgroundColor: mainColors.mainBlue,
                borderRadius: '0.2rem',
                padding: '0.2rem',
                cursor: 'pointer',
                '&:hover': {
                  backgroundColor: mainColors.mainBlue_lighter,
                },
                fontWeight: 600,
                textTransform: 'none',
              }}
              disableElevation={true}
              onClick={refreshPdf}
              endIcon={
                <RefreshIcon
                  sx={{
                    padding: '0.2rem',
                    fontSize: '3rem',
                    width: '2.5rem',
                    height: '2.5rem',
                  }}
                />
              }
            >
              Reload Document
            </Button>
          </Tooltip>
          <Tooltip
            classes={{ arrow: classes.arrow, tooltip: classes.tooltip }}
            title="Save Changes"
            placement="top"
            arrow
            enterNextDelay={2000}
            enterDelay={2000}
          >
            <div>
              {saveDialogIsShowing && (
                <SaveConfirmationDialogBox
                  setDialogIsShowing={setSaveDialogIsShowing}
                  dialogIsShowing={saveDialogIsShowing}
                  getTemplateChangesMade={getTemplateChangesMade}
                  getCanUserSave={getCanUserSave}
                  handleSaveChanges={handleSaveChanges}
                  figuresWereUpdated={figuresWereUpdated}
                  handleUpdateFigures={handleUpdateFigures}
                  checkTemplateChanges={checkTemplateChanges}
                />
              )}
              <Button
                variant="contained"
                sx={{
                  fontSize: '1rem',
                  width: '13rem',
                  height: '3rem',
                  color: 'white',
                  backgroundColor: isSaveEnabled
                    ? mainColors.mainBlue
                    : greys.grey400,
                  borderRadius: '0.2rem',
                  padding: '0.2rem',
                  cursor: 'pointer',
                  '&:hover': {
                    backgroundColor: isSaveEnabled
                      ? mainColors.mainBlue_lighter
                      : greys.grey400,
                  },
                  fontWeight: 600,
                  textTransform: 'none',
                }}
                disableElevation={true}
                onClick={
                  isSaveEnabled ? () => setSaveDialogIsShowing(true) : undefined
                }
                endIcon={
                  <SaveIcon
                    sx={{
                      padding: '0.2rem',
                      fontSize: '3rem',
                      width: '2.5rem',
                      height: '2.5rem',
                    }}
                  />
                }
              >
                Save Changes
              </Button>
            </div>
          </Tooltip>
          <Tooltip
            classes={{ arrow: classes.arrow, tooltip: classes.tooltip }}
            title="Download PDF"
            placement="top"
            arrow
            enterNextDelay={2000}
            enterDelay={2000}
          >
            <Button
              variant="contained"
              sx={{
                fontSize: '0.9rem',
                width: '13rem',
                height: '3rem',
                color: 'white',
                backgroundColor: mainColors.mainBlue,
                borderRadius: '0.2rem',
                padding: '0.2rem',
                cursor: 'pointer',
                '&:hover': {
                  backgroundColor: mainColors.mainBlue_lighter,
                },
                fontWeight: 600,
                textTransform: 'none',
              }}
              disableElevation={true}
              onClick={downloadFile}
              endIcon={
                <DownloadForOfflineIcon
                  sx={{
                    padding: '0.2rem',
                    fontSize: '3rem',
                    width: '2.5rem',
                    height: '2.5rem',
                  }}
                />
              }
            >
              Download Document
            </Button>
          </Tooltip>
        </div>
      </div>
    </PriipsKidsEditorGridItem>
  );
};

export default EditorPdfViewer;
